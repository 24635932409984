import moment from 'moment';
import { Helpers } from './question-helpers';

export default class QuestionFeedback {

  constructor(questionId, givers) {
    this._questionId = questionId;
    this._givers = givers;
  }

  /**
   * Use "id" as this is the identifier for firebase collections
   * @return {*}
   */
  get id() {
    return this._questionId;
  }

  /**
   * Makes question id publicly accessible in addition to "id" for clarity
   * @return {*}
   */
  get questionId() {
    return this._questionId;
  }

  get givers() {
    return this._givers;
  }

  get giverTimes() {
    return this.mapGivers((g) => moment(g.t));
  }

  get giverNames() {
    if (!this._giverNames) {
      this._giverNames = this.mapGivers((g) => {
        return g.n;
      });
    }
    return this._giverNames;
  }

  mapGivers(f) {
    let result = [];

    angular.forEach(this._givers, (giver) => {
      result.push(f(giver));
    });

    return result;
  }

  get mostRecent() {
    if (!this._mostRecent) {
      this._mostRecent = this.giverTimes.sort((giverA, giverB) => {
        if (giverA.isBefore(giverB)) {
          return 1;
        }
        else {
          return -1;
        }
      })[0];
    }
    return this._mostRecent;
  }

  get mostRecentDisplay() {
    if (this.mostRecent) {
      return moment().to(this.mostRecent);
    } else {
      return '';
    }
  }

  get formatNames() {
    if (this.giverNames) {
      return Helpers.formatHelpersList(this.giverNames);
    } else {
      return '';
    }
  }

  merge(other) {
    this._givers = other.givers;
    this._giverNames = undefined;
    this._mostRecent = undefined;
  }

  /**
   * @param target {QuestionFeedback} The QuestionFeedback which will be modified in the merge
   * @param source {QuestionFeedback} The QuestionFeedback which will be rolled into the target
   */
  static merge(target, source) {
    target.merge(source);
  }


}
