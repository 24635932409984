'use strict';

import CollectionObserver from '../../model/firebase-mapping/collection-observer';
import ArchivedData from '../../model/ui/elements/archived-data';
import LineCapture from './line-capture';
import Textbox from '../../model/ui/elements/textbox';
import Link from '../../model/ui/elements/link';
import ElementMetadata from '../../model/domain/element-metadata';
import SvgSheet from '../../model/ui/svg-sheet';
import { Notifications } from '../../services/toolbar/toolbar.service';
import Point from '../../model/ui/point';
import LinkDialogController from '../link-dialog/link-dialog.controller';
import PanCapture from './pan-capture';
import ImportImageDialogController from '../import-image-dialog/import-image-dialog.controller';
import {ElementIntents} from '../../model/domain/element-metadata';
import LoadingDialog from '../loading-dialog/loading-dialog.controller';
import Sticker from '../../model/ui/elements/sticker';
import AudioClip from '../../model/ui/elements/audio-clip';
import ErrorDialog from '../error-dialog/error-dialog.controller';
import { ToolbarModes } from '../../services/toolbar/toolbar.service';
import MultipleChoiceParent from '../../model/ui/elements/multiple-choice-parent';
import Features from '../../model/domain/features';
import MultipleChoiceChild from '../../model/ui/elements/multiple-choice-child';
import ManipulativeImageParent from '../../model/ui/elements/manipulative-image-parent';
import CkImage from '../../model/ui/elements/ckimage';
import FillInTheBlankParent from '../../model/ui/elements/fill-in-the-blank-parent';
import TextboxBase from '../../model/ui/elements/textbox-base';
import StraightLine from '../../model/ui/elements/straight-line';
import PlacementCapture, { PlacementConfig } from './placement-capture';
import CanvasContextMenuController from '../canvas-context-menu/canvas-context-menu.controller';
import SlideBackground from '../../model/ui/elements/slide-background';
import CameraDialogController from '../camera-dialog/camera-dialog.controller';
import AddManipulativeImageDialogController
  from '../add-manipulative-image-dialog/add-manipulative-image-dialog.controller';
import Line from '../../model/ui/elements/line';
import Highlight from '../../model/ui/elements/highlight';
import AssignmentSheetDirectiveTemplate from './assignment-sheet.html';
import HexColors from '../../css-constants';
import Size from '../../model/ui/size';
import { ABTest } from '../../services/ab-test/ab-test-service';
import MimeTypes from '../../model/domain/mime-types';
import TextToSpeech from '../../model/ui/elements/text-to-speech';
import SlideForeground from '../../model/ui/elements/slide-foreground';
import { UserRoles } from '../../model/domain/user';
import moment from 'moment';
import StaticService from '../../services/static/static.service';
import { FitbAnswerTypes } from '../assignment-toolbar/assignment-toolbar.directive';

export class AssignmentSheetMetadata {
  /**
   * @param target {Assignment | AssignmentWork} the target assignment or work
   * @param questionId {string} the target question id
   * @param [readonly] {boolean} is the sheet read only. default true
   * @param [thumbnail] {boolean} is the sheet a thumbnail (no elements are interactive). default true
   * @param [userId] {string} The current user's ID
   * @param [userRole] {string} The current user's role (UserRoles)
   * @param [intent] {string} The intent for contained elements (ElementIntents)
   * @param [scrollViewSelector] {string} CSS selector representing the scroll view containing the assignment-sheet
   * @param [contentOpacity] {number} the opacity value for the content elements
   * @param [onInit] {Function} a callback to be run after the init method is called
   * @param [helpRequests] {HelpRequestSet} a help request set for the toolbars help request manager
   */
  constructor(target, questionId, readonly, thumbnail, userId, userRole, intent, scrollViewSelector, contentOpacity, onInit, helpRequests) {

    this._metadata = {
      userId: userId,
      role: userRole,
      intent: intent
    };

    this._readonly = angular.isDefined(readonly) ? readonly : true;
    this._thumbnail = angular.isDefined(thumbnail) ? thumbnail : true;
    this._contentOpacity = contentOpacity;
    this._onInit = onInit;
    this._hasOnInitFunc = angular.isFunction(onInit);

    this._target = target;
    this._questionId = questionId;
    this._questionIndex = target.indexForQuestionId(questionId);
    this._helpRequests = helpRequests;

    this._scrollViewSelector = scrollViewSelector;
  }

  /**
   * @returns {{userId: {string}, role: {string}, intent: {string}}}
   */
  get metadata() {
    return this._metadata;
  }

  /**
   * @returns {boolean}
   */
  get readonly() {
    return this._readonly;
  }

  /**
   * @returns {boolean}
   */
  get thumbnail() {
    return this._thumbnail;
  }

  /**
   * @returns {Assignment|AssignmentWork}
   */
  get target() {
    return this._target;
  }

  /**
   * @returns {string}
   */
  get questionId() {
    return this._questionId;
  }

  /**
   * @returns {int}
   */
  get index() {
    return this._questionIndex;
  }

  /**
   * @returns {string}
   */
  get scrollViewSelector() {
    return this._scrollViewSelector;
  }

  /**
   * @returns {number}
   */
  get contentOpacity() {
    return this._contentOpacity;
  }

  /**
   * @return {Function}
   */
  get onInit() {
    return this._onInit;
  }

  /**
   * @return {boolean}
   */
  get hasOnInitFunc() {
    return this._hasOnInitFunc;
  }

  /**
   * @returns {HelpRequestSet}
   */
  get helpRequests() {
    return this._helpRequests;
  }
}


export default function AssignmentSheetDirective() {
  'ngInject';

  return {
    restrict: 'E',
    scope: {
      config: '='
    },
    template: AssignmentSheetDirectiveTemplate,
    link: AssignmentSheetDirectiveController.link,
    controller: AssignmentSheetDirectiveController,
    controllerAs: 'ctrl'
  };
}

class Observer extends CollectionObserver {
  constructor(ctrl, editable, active) {
    super();
    this.editable = editable;
    this.active = active;
    this.ctrl = ctrl;
  }

  onAdded(value) {
    this.ctrl.renderOne(value, this.editable, this.active);
    this.ctrl._contributorHistoryService.canvasUpdated(value);
    if (this.ctrl.isFeedback(value)) {
      this.ctrl._toolbarService.feedback.given();
    }
    if (value.type === ArchivedData.type) {
      if (this.ctrl._isWork) {
        StaticService.get.$log.debug('HADES AssignmentSheetDirective ArchiveObserver: onAdded', value, this.ctrl._assignmentWork.id, this.ctrl._questionId);
        this.ctrl._isArchivedFlag = true;

        // NOTE: uncomment when auto-unarchival is ready
        // automatically un-archive question work
        // this.ctrl._archiveService.unarchiveAssignmentWorkQuestion(this.ctrl._assignmentWork.id, this.ctrl._questionId);
      }
    }
  }

  onRemoved(value) {
    this.ctrl.remove(value);
    this.ctrl._contributorHistoryService.canvasUpdated(value);
    if (this.ctrl.isFeedback(value)) {
      this.ctrl._toolbarService.feedback.given();
    }
  }

  onChanged(value) {
    this.ctrl.renderOne(value, this.editable, this.active);
    this.ctrl._contributorHistoryService.canvasUpdated(value);
    if (this.ctrl.isFeedback(value)) {
      this.ctrl._toolbarService.feedback.given();
    }
  }
}

class AssignmentSheetDirectiveController {
  constructor($q, $log, $scope, $document, $timeout, $window, $mdDialog, $mdPanel, $location, CacheService, FirebaseService, FocusManagerService,
              AssignmentTrackingService, ToolbarService, MediaService, LogRocketService, ContributorHistoryService,
              AnalyticsMetaService, AnalyticsService, AuthService, AssignmentWorkService, ArchiveService) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;
    this.$document = $document;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;
    this.$location = $location;

    $scope.$on('$destroy', () => this._destroy());

    /** @type {SvgSheet} */
    this._svgSheet = null;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {FocusManagerService} */
    this._focusManager = FocusManagerService;
    /** @type {AssignmentTrackingService} */
    this._assignmentTrackingService = AssignmentTrackingService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {MediaService} */
    this._mediaService = MediaService;
    /** @type {LogRocketService} */
    this._logRocketService = LogRocketService;
    /** @type {ContributorHistoryService} */
    this._contributorHistoryService = ContributorHistoryService;
    /** @type {AnalyticsMetaService} */
    this._analyticsMetaService = AnalyticsMetaService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {ArchiveService} */
    this._archiveService = ArchiveService;

    /** @type {AssignmentSheetMetadata} */
    this._config = null;
    /** @type {Promise[]} */
    this._elementsLoaded = 0;
    this._newMCSegment = false;

    this._initialElementPosition = new Point(50, 50);
    this._initialStickerPosition = Sticker.InitialStickerPosition;

    this._anonHandleNotification = (ev) => this._handleNotification(ev);
    this._anonHandleStateUpdated = (ev) => this._handleStateUpdated(ev.newState);

    this._importImageDialog = ImportImageDialogController.show;
    this._linkDialog = LinkDialogController.show;
    this._loadingDialog = LoadingDialog.show;
    this._errorDialog = ErrorDialog.show;
    this._cameraDialog = CameraDialogController.show;
    this._addManipulativeImageDialog = AddManipulativeImageDialogController.show;

    this._moreOptionsManager = new MoreOptionsManager(this.$q, this.$mdPanel, this.$mdDialog);

    this._textToSpeech = TextToSpeech.instance;

    this._isArchivedFlag = false;

    if (this.focusedElement) {
      this.focusedElement = undefined;
    }

    if (this._authService.isLoggedIn) {
      this._loadABSegments();
    }
  }

  _loadABSegments(){
    this._cacheService.getTestSegment(ABTest.MultipleChoice)
      .then((result) => {
        this._newMCSegment = result;
    });
  }

  /**
   * the link function is run after the constructor for the controller
   * @param scope {$scope}
   * @param element {object}
   * @param attrs {object}
   * @param ctrl {AssignmentSheetDirectiveController}
   */
  static link(scope, element, attrs, ctrl) {
    ctrl.element = element;

    scope.$watch('config', (value) => {
      if (angular.isDefined(value)) {
        ctrl.config = value;
      }
    });
  }

  get element() {
    return this._element;
  }

  set element(value) {
    this._element = value;
    this._svgSheet = new SvgSheet(this._element, this.$timeout, this.$window, this._focusManager, this._toolbarService);
  }

  /**
   * @param value {AssignmentSheetMetadata}
   */
  set config(value) {
    if (value === this._config) {
      return;
    }

    if (this._setup) {
      this.$timeout.cancel(this._setup);
    }

    this._setup = this.$timeout(() => {
      this._init(() => {
        this._config = value;
      });
      this._setup = undefined;
    }, 0, false);
  }

  /**
   * @returns {boolean}
   */
  get hasMetadata() {
    return this._config && this._config.metadata;
  }

  /**
   * @returns {string}
   */
  get _ownerId() {
    if (this.hasMetadata) {
      return this._config.metadata.userId;
    }
    return '';
  }

  /**
   * @returns {string}
   */
  get _role () {
    if (this.hasMetadata) {
      return this._config.metadata.role;
    }
    return '';
  }

  /**
   * @returns {string}
   */
  get _intent () {
    if (this.hasMetadata) {
      return this._config.metadata.intent;
    }
    return '';
  }

  /**
   * @returns {boolean}
   */
  get _readonly() {
    if (this._config) {
      return !!this._config.readonly;
    }
    return false;
  }

  /**
   * @returns {boolean}
   */
  get _active() {
    if (this._config) {
      return !this._config.thumbnail;
    }
    return false;
  }

  /**
   * @returns {number}
   */
  get _index() {
    if (this._config) {
      return this._config.index;
    }
    return '';
  }

  /**
   * @returns {string}
   * @private
   */
  get _scrollViewSelector() {
    return this._config ? this._config.scrollViewSelector : undefined;
  }

  /**
   * @returns {Assignment|AssignmentWork}
   */
  get _target() {
    return this._config && this._config.target;
  }


  get _assignment() {
    return this._isWork ? this._target.assignment : this._target;
  }


  get _assignmentWork() {
    return this._isWork ? this._target : null;
  }

  /**
   * @returns {boolean}
   */
  get _isWork() {
    return this._target && this._target.isWork;
  }

  /**
   * @returns {boolean}
   */
  get _readonlyContent() {
    return this._readonly || !!this._assignmentWork;
  }

  /**
   * @returns {boolean}
   */
  get _readonlyWork() {
    return this._readonly;
  }

  /**
   * @return {Function}
   */
  get _onInit() {
    return this._config && this._config.onInit;
  }

  /**
   * @return {boolean}
   */
  get _hasOnInitFunc() {
    return this._config && this._config.hasOnInitFunc;
  }

  /**
   * @returns {HelpRequestSet}
   */
  get _helpRequests() {
    return this._config && this._config.helpRequests;
  }

  /**
   * @return {string}
   */
  get _questionId() {
    return this._assignment &&
      angular.isNumber(this._index) &&
      this._assignment.questionIdForIndex(this._index);
  }

  /**
   * @param f {function} executes modifications to controller state to surround by subscription logic
   * @private
   */
  _init(f) {

    this._elementsLoaded = 0;

    if (this.content) {
      this.content.unsubscribe(this._assignmentObserver);
    }
    if (this.work) {
      this.work.unsubscribe(this._workObserver);
    }

    f();

    this._svgSheet.readonly = this._readonly;
    this._svgSheet.layers.clear();
    this._svgSheet.refreshLayout();

    if (angular.isNumber(this._index) && this._target && this._isInDom) {

      this._initContentOpacity();

      if (this._active) {
        this._svgSheet.scrollViewSelector = this._scrollViewSelector;
        this._toolbarService.zoomManager.svgSheet = this._svgSheet;
      }

      this._svgSheet.showSheet();

      if (this._assignment) {
        this._assignmentObserver = new Observer(this, !this._readonlyContent, this._active);
        this.content.subscribe(this._assignmentObserver);
        this.contentQuestion.start();
      }
      if (this._assignmentWork) {
        this._workObserver = new Observer(this, !this._readonlyWork, this._active);
        this.work.subscribe(this._workObserver);
        this.workQuestion.start();
      }

      // If this is not a thumbnail
      if (this._active) {

        this._toolbarService.notification.subscribe(this._anonHandleNotification, this);
        this._toolbarService.stateUpdated.subscribe(this._anonHandleStateUpdated, this);

        this._toolbarService.targetIndex = this._index;

        // If assignment or assignment work is loaded
        if (this._ready) {

          if (this._helpRequests) {
            this._toolbarService.helpCenter.helpRequestManager.parentHelpRequestSet = this._helpRequests;
          }

          if (this._isWork) {
            this._toolbarService.helpCenter.chatMessageManager.messages = this.workQuestion.messages;
          }

          if (!this._readonly) {
            this._focusManager.elementList = this._isWork ? this.work : this.content;

            if (this.lineCapture) {
              this.lineCapture.destroy();
            }

            this.lineCapture = new LineCapture(
              this._svgSheet,
              this._ownerId,
              this._role,
              this._intent,
              this._isWork,
              this._index,
              this._workOrContent,
              this._firebaseService,
              this._assignmentTrackingService,
              this._toolbarService,
              this.$window,
              this.$location
            );
            this.panCapture = new PanCapture(this._svgSheet.layers.panCapture, this._toolbarService);
            this.placementCapture = new PlacementCapture(this._svgSheet, this._toolbarService);

            this.lineCapture.render(this._svgSheet.layers.lineCapture);
            this.panCapture.render(this._svgSheet.layers.panCapture);
            this.placementCapture.render(this._svgSheet.layers.placementCapture);

            // Makes sure we do not start in placement mode
            let mode = this._toolbarService.state.mode;
            this._toolbarService.updateMode(ToolbarModes.isPlacementMode(mode) ? ToolbarModes.Selector : mode);
          }
        }
      }

      if (this._hasOnInitFunc) {
        this.initialElementCount.then((count) => {
          if (count === 0) {
            this._onInit();
          }
        });
      }
    }
  }

  get _isInDom() {
    return this.$document[0].body.contains(this._element[0]);
  }

  /**
   * @private
   */
  _destroy() {
    if (this.content) {
      this.content.unsubscribe(this._assignmentObserver);
      this.content.forEach((element) => {
        element.cleanUp();
      });
    }
    if (this.work) {
      this.work.unsubscribe(this._workObserver);
      this.work.forEach((element) => {
        if (element.format === FitbAnswerTypes.SCIENTIFIC.value && element.removeMathFieldEventListeners) {
          element.removeMathFieldEventListeners();
          if (element.resizeObservers && element.resizeObservers.length) {
            element.resizeObservers.forEach((observer) => observer.disconnect());
          }
        }
        element.cleanUp();
      });
    }

    this._toolbarService.notification.unsubscribe(this._anonHandleNotification, this);
    this._toolbarService.stateUpdated.unsubscribe(this._anonHandleStateUpdated, this);

    this._svgSheet.destroy();

    if (this.lineCapture) {
      this.lineCapture.destroy();
    }

    if (this.placementCapture) {
      this.placementCapture.clear();
    }
  }

  _handleNotification(event) {

    if (this._active) {

      if (!event.data.needsFocusedElement) {
        this.focusedElement = undefined;
      }

      if (event.type === Notifications.CREATE_TEXTBOX) {
        this.addTextbox(event.data.initialText, event.data.skipPlacement);
      }
      else if (event.type === Notifications.CREATE_LINK) {
        this._linkDialog(this.$mdDialog).then((result) => {
          this.addLink(result.title, result.url);
        });
      }
      else if (event.type === Notifications.CREATE_IMAGE_FROM_FILE) {
        this.addImage(event.data);
      }
      else if (event.type === Notifications.CREATE_IMAGE_FROM_CAMERA) {
        this.addCameraImage();
      }
      else if (event.type === Notifications.CREATE_AUDIO) {
        this.addAudio(event.data);
      }
      else if (event.type === Notifications.CREATE_EMOJI) {
        // if textbox is already focused then append chosen emoji to existing textbox
        if (this.hasFocusedElement) {
          let updatedTextBoxWithEmoji = `${this.focusedElement.text} ${event.data.value}`;
          this.focusedElement.setText(updatedTextBoxWithEmoji);
        } else {
            this.addTextbox(event.data.value, true);
        }
      }
      else if (event.type === Notifications.CREATE_AI_ASSISTANT) {
        if (event.data && event.data.type && event.data.type === 'textbox' && event.data.element) {
          this.addTextbox(event.data.element, true);
        }
      }
      else if (event.type === Notifications.CREATE_MANIPULATIVE_IMAGE) {
        this.addManipulativeImage();
      }
      else if (event.type === Notifications.CREATE_FILL_IN_THE_BLANK) {
        this.addFillInTheBlank();
      }
      else if (event.type === Notifications.CREATE_MULTIPLE_CHOICE) {
        this.addMultipleChoiceParent(event.data);
      }
      else if (event.type === Notifications.MOUSE_ENTER_DELETE && this.hasFocusedElement) {
        this.focusedElement.warnBeforeDeletion();
      }
      else if (event.type === Notifications.MOUSE_LEAVE_DELETE && this.hasFocusedElement) {
        this.focusedElement.tryUpdate();
      }
      else if (event.type === Notifications.DELETE && this.hasFocusedElement) {
        let focusedElement = this.focusedElement;
        this.focusedElement = undefined;
        this._assignmentTrackingService.deleteElement(focusedElement, this._index, this._isWork);
      }
      else if (event.type === Notifications.HIDE_FEEDBACK) {
        this.feedbackVisible = false;
      }
      else if (event.type === Notifications.SHOW_FEEDBACK) {
        this.feedbackVisible = true;
      }
      else if (event.type === Notifications.SHOW_ALL_CONTRIBUTORS) {
        this.showAllCollaborators();
      }
      else if (event.type === Notifications.HIDE_ALL_CONTRIBUTORS) {
        this.hideAllCollaborators();
      }
      else if (event.type === Notifications.HIGHLIGHT_CONTRIBUTOR) {
        this.highlightCollaboratorFeedback(event.data.userId);
      }
      else if (event.type === Notifications.UPDATE_TEXTBOX_SIZE && (this.textboxIsFocused || this.fillInTheBlankParentIsFocused)) {
        this.focusedElement.fontSize = event.data.fontSize;
      }
      else if (event.type === Notifications.UNDO) {
        if (event.data.change.deleted) {
          this.focusedElement = undefined;
        }
        else if (this.textboxIsFocused) {
          this._focusManager.refocusElement();
        }
      }
      else if (event.type === Notifications.REDO) {
        if (event.data.change.deleted) {
          this.focusedElement = undefined;
        }
        else if (this.textboxIsFocused) {
          this._focusManager.refocusElement();
        }
      }
      else if (event.type === Notifications.UPDATE_TEXTBOX_COLOR && this.textboxIsFocused) {
        if (event.data.hex) {
          this.focusedElement.textColor = event.data.hex;
        }
        this._focusManager.refocusInput();
      }
      else if (event.type === Notifications.UPDATE_TEXTBOX_BACKGROUND_COLOR && this.textboxIsFocused) {
        if (event.data.hex) {
          this.focusedElement.backgroundColor = event.data.hex;
        }
        this._focusManager.refocusInput();
      }
      else if (event.type === Notifications.UPDATE_TEXTBOX_BORDER_COLOR && this.textboxIsFocused) {
        if (event.data.hex) {
          this.focusedElement.borderColor = event.data.hex;
        }
        this._focusManager.refocusInput();
      }
      else if (event.type === Notifications.UPDATE_TEXT_FONT_FAMILY && this.textboxIsFocused) {
        if (event.data.value) {
          this.focusedElement.fontFamily = event.data.value;
        }
        this._focusManager.refocusInput();
      }
      else if (event.type === Notifications.ADD_STICKER) {
        this.addSticker(event.data.sticker, event.data.stickerImage, event.data.skipPlacement);
      }
      else if (event.type === Notifications.CLONE_ELEMENT) {
        this.cloneElement(event.data.element);
      }
      else if (event.type === Notifications.CREATE_STRAIGHT_LINE) {
        this.addStraightLine();
      }
      else if (event.type === Notifications.UPDATE_STRAIGHT_LINE_COLOR && this.straightLineIsFocused) {
        this.focusedElement.color = event.data.hex;
      }
      else if (event.type === Notifications.UPDATE_STRAIGHT_LINE_WIDTH && this.straightLineIsFocused) {
        this.focusedElement.width = event.data.width;
      }
      else if (event.type === Notifications.CLEAR_PLACEMENT) {
        this.placementCapture.clear();
      }
      else if (event.type === Notifications.UPDATE_SLIDE_BACKGROUND) {
        this.updateSlideBackground(event.data.hex, event.data.url);
      }
      else if (event.type === Notifications.TEXT_TO_SPEECH) {
        this.updateTextToSpeechVisibility();
      }
      else if (event.type === Notifications.COVER_SLIDE) {
        this.updateCoverSlide(event.data.coverSlide);
      }
      else if (event.type === Notifications.CLEAR_ALL) {
        this.clearAll();
      }
    }
    else {
      this.$log.warn('Attempted to handle event for inactive sheet', event);
    }
  }

  /**
   * @returns {Element|undefined}
   */
  get focusedElement() {
    return this._focusManager.focusedElement;
  }

  /**
   * @param value {Element|undefined}
   */
  set focusedElement(value) {
    this._focusManager.focusedElement = value;
  }

  /**
   * @returns {boolean}
   */
  get hasFocusedElement() {
    return angular.isDefined(this.focusedElement);
  }

  /**
   * @returns {boolean}
   */
  get textboxIsFocused() {
    return this.hasFocusedElement && this.focusedElement instanceof TextboxBase;
  }

  /**
   * @return {boolean}
   */
  get straightLineIsFocused() {
    return this.hasFocusedElement && this.focusedElement.type === StraightLine.type;
  }

  /**
   * @return {boolean}
   */
  get fillInTheBlankParentIsFocused() {
    return this.hasFocusedElement && this.focusedElement.type === FillInTheBlankParent.type;
  }

  _handleStateUpdated(newState) {
    if (this._ready && this.lineCapture) {
      this.lineCapture.state = newState;

      this.isPanning = newState.mode === ToolbarModes.Touch;

      if (ToolbarModes.Selector !== newState.mode) {
        this.focusedElement = undefined;
      }
    }
  }

  set isPanning(value) {
    if (this._ready && this.panCapture) {
      this._isPanning = value;
      this.panCapture.visibility = value ? 'visible' : 'hidden';
      this._svgSheet.readonly = value;
    }
  }

  get isPanning() {
    return this._isPanning;
  }

  cloneElement(element) {
    element.id = this._firebaseService.newId();
    element.metadata = this._newElementMetadata;

    let promise = this.$q.resolve(element);

    if (element.type === CkImage.type || element.type === AudioClip.type) {
      promise = this._copyMedia(element.id, element.url).then((mediaLink) => {
        element.url = mediaLink;
        return element;
      });
    }

    promise.then((element) => {
      element.location = this._clonedElementPosition(element.location);
      return this._assignmentTrackingService.createElement(element, this._index, this._isWork);
    });

    this._loadingDialog(this.$mdDialog, promise);
  }

  /**
   * @param location {Point}
   * @return {Point}
   */
  _clonedElementPosition(location) {
    if (!this._collidesWithExistingElement(location)) {
      return location;
    }
    else {
      return this._clonedElementPosition(location.plus(new Point(30, 30)));
    }
  }

  /**
   * @param location {Point}
   * @return {boolean}
   */
  _collidesWithExistingElement(location) {
    let result = this._isWork ? this.work : this.content;
    return result.some((elem) => {
      const xDiff = location.x - elem.location.x;
      const yDiff = location.y - elem.location.y;

      if (elem.location.equals(location)) {
        return true;
      }

      //if the position is very close, we don't want it to overlap so users can see the changes
      if (Math.abs(xDiff) < 1 || Math.abs(yDiff) < 1){
        return true;
      }
      return false;
    });
  }

  /**
   * @param newElementId {string}
   * @param originalMediaLink {string} the original media link
   * @return {Promise.<{mediaLink: string}>} a promise with the new media link
   */
  _copyMedia(newElementId, originalMediaLink) {
    return this._mediaService.copy(
      newElementId,
      originalMediaLink,
      this._isWork ? this.workQuestion.elementListId : this.contentQuestion.elementListId
    );
  }

  /**
   * @param initialText {string}
   * @param skipPlacement {boolean}
   */
  addTextbox(initialText, skipPlacement) {
    let textbox = new Textbox(
      this._firebaseService.newId(),
      this._newElementMetadata,
      initialText || '',
      undefined,
      null,
      this._toolbarService.state.text.size,
      null,
      null,
      this._toolbarService.state.text.color,
      this._toolbarService.state.text.backgroundColor,
      this._toolbarService.state.text.borderColor,
      this._toolbarService.state.text.fontFamily
    );

    if (skipPlacement) {
      textbox.location = this._svgSheet.elementOffset(this._nextElementPosition());
      this._addTextbox(textbox);
    }
    else {
      this.placementCapture.config = new PlacementConfig(
        textbox,
        (location, size) => {
          textbox.location = location;
          if (size.width > 0) {
            textbox.size = size;
          }
          this._addTextbox(textbox);
        }
      );

      this._toolbarService.updateMode(ToolbarModes.PlaceTextbox);
    }
  }

  /**
   * @param textbox {Textbox}
   */
  _addTextbox(textbox) {
    this.renderOne(textbox, true, true);
    this._assignmentTrackingService.createElement(textbox, this._index, this._isWork);
    textbox.setNewTextboxAttributes();
    textbox.focus();

    this._handleTextFeedback(textbox);
  }

  addStraightLine() {

    let straightLine = new StraightLine(
      this._firebaseService.newId(),
      this._newElementMetadata,
      undefined,
      undefined,
      this._toolbarService.state.straightLines.color.hex,
      this._toolbarService.state.straightLines.width
    );

    this.placementCapture.config = new PlacementConfig(
      straightLine,
      (location, size, initial, last) => {
        straightLine.start = initial;
        straightLine.end = initial.equals(last) ? initial.plus(new Point(100, 100)) : last;
        this.renderOne(straightLine, true, true);
        this._assignmentTrackingService.createElement(straightLine, this._index, this._isWork);
        straightLine.focus();
      }
    );

    this._toolbarService.updateMode(ToolbarModes.PlaceStraightLine);
  }

  addCameraImage() {
    this._cameraDialog(this.$mdDialog, this._index, this._questionId)
      .then((result) => {
        return this.addImage(result, true);
      });
  }

  addImage(data, webcam=false) {

    let promise;

    if (angular.isDefined(data.tempFile)) {
      promise = this._launchImportImageDialog(data.tempFile);
    }
    else if (!data.imageImport) {  // if there's no image data, launch a popup to gather from user
      promise = this._launchImportImageDialog();
    }
    else {
      promise = this.$q.resolve({
        newQuestions: [],
        images: [ data.imageImport ]
      });
    }

    promise.then((data) => {
      this._importImages(data);
      this._handleImageFeedback(webcam ? 'webcam' : 'upload');
    });
  }

  addManipulativeImage() {
    this._addManipulativeImageDialog(this.$q, this.$mdDialog, this._assignment.questions, this._questionId)
      .then((data) => {
        return this._importImages(data);
      });
  }

  _launchImportImageDialog(tempFile = undefined) {
    if (angular.isDefined(tempFile)) {
      return this._importImageDialog(
        this.$q,
        this.$mdDialog,
        this._assignment.questions,
        this._intent === ElementIntents.CONTENT, // Don't show the page selection view to Students (ElementIntents.WORK), or to anybody when giving feedback
        this._intent === ElementIntents.CONTENT,
        this._questionId,
        false,
        MimeTypes.ImageSupport,
        tempFile
      );
    }
    else {
      return this._importImageDialog(
        this.$q,
        this.$mdDialog,
        this._assignment.questions,
        this._intent === ElementIntents.CONTENT, // Don't show the page selection view to Students (ElementIntents.WORK), or to anybody when giving feedback
        this._intent === ElementIntents.CONTENT,
        this._questionId,
        false
      );
    }
  }

  /**
   * @param data {{newQuestions: QuestionDisplay[], images: ImageImport[]}}
   */
  _importImages(data) {
    const promise = this._assignmentTrackingService.importImages(
      data,
      this._target,
      this._newElementMetadata,
      this._svgSheet,
      this._questionId
    );

    this._loadingDialog(this.$mdDialog, promise);

    return promise;
  }

  addFillInTheBlank() {

    let newFillInTheBlank = new FillInTheBlankParent(
      this._firebaseService.newId(),
      this._newElementMetadata,
      new Point(0, 0),
      undefined,
      undefined,
      undefined
    );

    this.placementCapture.config = new PlacementConfig(
      newFillInTheBlank,
      (location, size) => {
        newFillInTheBlank.location = location;
        if (size.width > 0) {
          newFillInTheBlank.size = size;
        }
        this.renderOne(newFillInTheBlank, true, true);
        this._assignmentTrackingService.createElement(newFillInTheBlank, this._index, this._isWork);
        newFillInTheBlank.focus();
      }
    );

    this._toolbarService.updateMode(ToolbarModes.PlaceFillInTheBlank);
  }

  addAudio(blob) {
    let promise = this._mediaService.create(blob, this._intent, this._elementListId);

    this._loadingDialog(this.$mdDialog, promise);
    promise.then((uploadResult) => {
        let scaledPosition = this._svgSheet.elementOffset(this._nextElementPosition());

        const newAudio = new AudioClip(
          uploadResult.newElementId,
          this._newElementMetadata,
          scaledPosition,
          undefined,
          uploadResult.mediaLink
        );

        this.renderOne(newAudio, true, false);
        newAudio.focus();
        this._assignmentTrackingService.createElement(newAudio, this._index, this._isWork);

        this._handleGeneralFeedback('feedback:audio_added');
      })
      .catch((err) => {
        this.$log.error('#addAudio', err);
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
          this._logRocketService.handleError(err);
        }
        else {
          navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
              let d = devices.reduce((sum, x) => `${sum}, '${x.label}':${x.kind}`, 'devices-');
              this._logRocketService.handleError(new Error(`${err.message}: ${d}`));
            });
        }

        if (err.code === 'internal-server-error' && err.message === 'empty upload file') {
          this._errorDialog(
            this.$mdDialog,
            undefined,
            `<p>Classkick is having trouble recording from your microphone. This can often be fixed by quitting and reopening your browser, but sometimes requires you to restart your computer.</p>
             <p>We're working on a permanent fix, but in the meantime please try the above steps. If you continue to have problems send us an email at <b>support@classkick.com</b></p>`
          );
        }
        else {
          this._errorDialog(
            this.$mdDialog,
            'Oh no! Something went wrong! Please try again.',
            'If you continue to have problems, send us an email at <b>support@classkick.com</b>'
          );
        }
      });
  }

  addMultipleChoiceParent(options) {
    let scaledPosition = this._svgSheet.elementOffset(this._nextElementPosition());

    const size = this._newMCSegment ?
      new Size(40 + (options.length * 48), 84) : undefined;

    const newMultipleChoice = new MultipleChoiceParent(
      this._firebaseService.newId(),
      this._newElementMetadata,
      scaledPosition,
      size,
      options
    );

    this._assignmentTrackingService.createElement(newMultipleChoice, this._index, this._isWork);
  }

  deleteMultipleChoiceParent(element) {
    this._assignmentTrackingService.deleteElement(element, this._index, this._isWork);
  }

  addLink(title, url) {
    let scaledPosition = this._svgSheet.elementOffset(this._nextElementPosition());

    var link = new Link(
      this._firebaseService.newId(),
      this._newElementMetadata,
      scaledPosition,
      null,
      title,
      url
    );

    this.renderOne(link, true, false);
    link.focus();
    this._assignmentTrackingService.createElement(link, this._index, this._isWork);

    this._handleGeneralFeedback('feedback:link_added');
  }

  /**
   * @param coverSlide {SlideForeground}
   */
  updateCoverSlide(coverSlide){
    if (!coverSlide) {
      let slideForeground = new SlideForeground(
        this._firebaseService.newId(),
        new ElementMetadata(this._ownerId, UserRoles.TEACHER, ElementIntents.CONTENT),
        HexColors.CK_WARN,
        undefined
      );
      this.renderOne(slideForeground, false, false);
      if (this._isWork) {
        const question = this._assignmentWork.questionForIndex(this._index);
        //in order to have update show up in the thumbnail for inactive slides, question needs to have a start time
        if (!question.startedAt) {
          question.startedAt = moment();
          this._assignmentWorkService.updateQuestion(this._assignmentWork.assignmentId, this._assignmentWork.id, question);
        }
        question.saveElement(slideForeground);
      }
    } else {
      this._assignmentTrackingService.deleteElement(coverSlide, this._index, this._isWork);
    }
  }

  updateSlideBackground(hex, url) {
    const currentBackground = this.content.find((element) => element.type === SlideBackground.type);

    if (!currentBackground) {
      const slideBackground = new SlideBackground(
        this._firebaseService.newId(),
        this._newElementMetadata,
        hex,
        url
      );
      this.renderOne(slideBackground, true, false);
      this._assignmentTrackingService.createElement(slideBackground, this._index, this._isWork);
    }
    else if (hex) {
      currentBackground.hex = hex;
    }
    else if (url) {
      currentBackground.url = url;
    }
    else {
      this._assignmentTrackingService.deleteElement(currentBackground, this._index, this._isWork);
    }
  }

  updateTextToSpeechVisibility() {
    this._textToSpeech.isAvailable = !this._textToSpeech.isAvailable;
    let textElements = this._workOrContent.filter((element) => (element.type === 'textbox' || 'fitb_child') && this.hasText(element));
    textElements.push(this.contentQuestion.elements.filter((element) => element.type === 'textbox' && this.hasText(element)));
    textElements = textElements.flat();

    textElements.forEach((element) => element.update(element.root, element.editable));
  }

  hasText(element) {
    return element.text && element.text.length;
  }

  clearAll() {
    let elements = this._workOrContent.filter((element) => (element.type === Line.type || element.type === Highlight.type) && this._isSameUser(element));
    this._assignmentTrackingService.deleteElements(elements, this._index, this._isWork);
  }

  /**
   * @param userSticker {UserSticker}
   * @param stickerImage {Image}
   * @param skipPlacement {boolean}
   */
  addSticker(userSticker, stickerImage, skipPlacement) {

    let sticker = new Sticker(
      this._firebaseService.newId(),
      this._newElementMetadata,
      undefined,
      // If this is a sticker w/o an image, we want to use the default size
      userSticker.imageUrl && Sticker.createSizeFromImage(stickerImage),
      userSticker.text,
      userSticker.imageUrl,
      userSticker.score,
      false
    );

    if (skipPlacement) {
      sticker.location = this._svgSheet.elementOffset(this._nextStickerPosition());
      this._addSticker(sticker, userSticker);
      this.placementCapture.clear();
    }
    else {
      this.placementCapture.config = new PlacementConfig(
        sticker,
        (location, size, initial, last) => {
          sticker.location = last;
          this._addSticker(sticker, userSticker);
        },
        () => {
          if (this._toolbarService.state.stickers.selectedId === userSticker.id) {
            this._toolbarService.updateSelectedUserSticker('');
          }
        }
      );

      this._toolbarService.updateMode(ToolbarModes.PlaceSticker);
      this._toolbarService.updateSelectedUserSticker(userSticker.id);
    }
  }

  /**
   * @param sticker {Sticker}
   * @param userSticker {UserSticker}
   */
  _addSticker(sticker, userSticker) {
    this.renderOne(sticker, true, false);
    this._assignmentTrackingService.createElement(sticker, this._index, this._isWork);
    this._toolbarService.updateSelectedUserSticker('');
    this._toolbarService.addStickerScore(userSticker.score);

    this._handleStickerFeedback(userSticker);
  }

  _nextStickerPosition() {
    if (!this._lastStickerPosition) {
      this._lastStickerPosition = this._initialStickerPosition;
    }
    else if (this._lastStickerPosition.y > 450) {
      this._lastStickerPosition = this._initialStickerPosition.plus(new Point(80, 0));
    }
    else {
      this._lastStickerPosition = this._lastStickerPosition.plus(new Point(0, 80));
    }
    return this._lastStickerPosition;
  }

  /**
   * @returns {Point}
   */
  _nextElementPosition() {
    if (!this._lastElementPosition || this._lastElementPosition.x > 200 || this._lastElementPosition > 600) {
      this._lastElementPosition = this._initialElementPosition;
    }
    else {
      this._lastElementPosition = this._lastElementPosition.plus(new Point(60, 60));
    }
    return this._lastElementPosition;
  }

  /**
   * @return {ElementMetadata}
   */
  get _newElementMetadata() {
    return new ElementMetadata(this._ownerId, this._role, this._intent);
  }

  /**
   * @returns {AssignmentQuestion}
   */
  get contentQuestion() {
    if (this._assignment) {
      return this._assignment.questions[this._index];
    }
    return undefined;
  }

  /**
   * @returns {FirebaseCollection.<Element>}
   */
  get content() {
    if (this.contentQuestion) {
      return this.contentQuestion.elements;
    }
    return undefined;
  }

  /**
   * @returns {AssignmentQuestion}
   */
  get workQuestion() {
    if (this._assignmentWork) {
      return this._assignmentWork.questionForIndex(this._index);
    }
    return undefined;
  }

  /**
   * @returns {FirebaseCollection.<Element>}
   */
  get work() {
    if (this.workQuestion) {
      return this.workQuestion.elements;
    }
    return undefined;
  }

  /**
   * @returns {boolean} is all the data ready for the appropriate type of work
   */
  get _ready() {
    return (this._isWork && angular.isDefined(this.work)) || (!this._isWork && angular.isDefined(this.content));
  }

  get _workOrContent() {
    return this._isWork ? this.work : this.content;
  }

  get _elementListId() {
    return this._isWork ? this.workQuestion.elementListId : this.contentQuestion.elementListId;
  }

  /**
   * Renders an element to the SVG DOM
   * @param element {Element}
   * @param editable {boolean}
   * @param active {boolean}
   */
  renderOne(element, editable, active) {
    element.render(
      this._svgSheet.layers.pickLayer(element),
      this._isEditable(element, editable),
      active,
      this._svgSheet,
      this._target,
      this._config.metadata,
      this._config.thumbnail,
      this._moreOptionsManager
    );
    element.visibility = this._isVisible(element) ? 'inherit' : 'hidden';

    if (this._hasOnInitFunc) {
      element.loaded
        .catch((error) => {
          this.$log.error(error);
        })
        .then(() => {
          return this.initialElementCount;
        })
        .then((count) => {
          this._elementsLoaded++;

          if (count === this._elementsLoaded) {
            this._onInit();
          }
        });
    }
  }

  /**
   * @return {Promise.<Number>}
   */
  get initialElementCount() {
    if (this._isWork) {
      return this._assignmentWork.initialElementCountForIndex(this._index);
    }
    else {
      return this._assignment.initialElementCountForIndex(this._index);
    }
  }

  /**
   * @param element {Element}
   */
  remove(element) {
    if (element.remove) {
      element.remove();
    }
  }

  /**
   * @param element {Element}
   * @param isEditable {boolean}
   * @returns {boolean}
   * @private
   */
  _isEditable(element, isEditable) {
    return isEditable &&
      this._isSameIntent(element) &&
      this._isSameRole(element) &&
      this._isSameUser(element);
  }

  /**
   * @param element
   * @returns {boolean}
   */
  _isSameUser(element) {
    // Fix for editing own assignment when the ownerId is set as email instead of user id.
    return this._intent === ElementIntents.CONTENT || element.metadata.ownerId === this._ownerId;
  }

  /**
   * @param element
   * @returns {boolean}
   */
  _isSameIntent(element) {
    return element.metadata.intent === this._intent;
  }

  /**
   * @param element
   * @returns {boolean}
   */
  _isSameRole(element) {
    return element.metadata.role === this._role;
  }

  /**
   * @param value {Element}
   * @returns {boolean}
   */
  isFeedback(value) {
    return value.metadata.intent === ElementIntents.FEEDBACK;
  }

  /**
   * @param value {Element}
   * @returns {boolean}
   */
  isWork(value) {
    return value.metadata.intent === ElementIntents.WORK;
  }

  /**
   * @param element {Element}
   * @returns {boolean}
   */
  _isVisible(element) {
    return (this._isSameUser(element) || (!this.isFeedbackInvisible && !this.isFeedbackNotification)) && this._isManipulativeParentVisible(element);
  }

  /**
   * Ensures manipulative parents are only rendered in question content views
   * @param element {Element}
   * @return {boolean}
   */
  _isManipulativeParentVisible(element) {
    if (element.type === MultipleChoiceParent.type ||
      element.type === ManipulativeImageParent.type ||
      element.type === FillInTheBlankParent.type) {
      return !this._isWork;
    }
    return true;
  }

  /**
   * @returns {boolean}
   */
  get isFeedbackInvisible() {
    return this._toolbarService.isFeedbackInvisible;
  }

  /**
   * @returns {boolean}
   */
  get isFeedbackNotification() {
    return this._toolbarService.isFeedbackNotification;
  }

  /**
   * @param value {boolean}
   */
  set feedbackVisible(value) {
    if (this.work) {
      this.work.forEach((element) => {
        if (this.isFeedback(element)) {
          element.visibility = value ? 'inherit' : 'hidden';
        }
      });
    }
  }

  /**
   * @param userId {string}
   */
  highlightCollaboratorFeedback(userId) {
    if (this.work) {
      this.work.forEach((element) => {
        if (this.isFeedback(element) || this.isWork(element)) {
          if (element.metadata.ownerId !== userId) {
            element.opacity = 0.1;
          } else {
            element.opacity = 1;
          }
        }
      });
    }
  }

  showAllCollaborators() {
    if (this.work) {
      this.work.forEach((element) => {
        if (this.isFeedback(element) || this.isWork(element)) {
          element.opacity = 1;
        }
      });
    }
  }

  hideAllCollaborators() {
    if (this.work) {
      this.work.forEach((element) => {
        if (this.isFeedback(element) || this.isWork(element)) {
          element.opacity = 0.1;
        }
      });
    }
  }

  _initContentOpacity() {
    if (this._hasContentOpacity) {
      this._setContentOpacity();
    }
  }

  /**
   * @returns {boolean}
   */
  get _hasContentOpacity() {
    return angular.isNumber(this._config.contentOpacity);
  }

  /**
   * Sets content layer to the current content opacity configuration
   */
  _setContentOpacity() {
    this._svgSheet.layers.content.attr({
      opacity: this._config.contentOpacity
    });
  }

  _handleGeneralFeedback(eventType) {
    if (this._analyticsMetaService.isTeacherFeedbackPage()) {
      this._analyticsService.sendEvent({
        eventTag: eventType,
        properties: {
          ...this._analyticsMetaService.buildFeedbackAddedMeta()
        }
      });
    }
  }

  _handleImageFeedback(source) {
    if (this._analyticsMetaService.isTeacherFeedbackPage()) {
      this._analyticsService.sendEvent(
        {
          eventTag: 'feedback:image_added',
          properties: {
            ...this._analyticsMetaService.buildFeedbackAddedMeta(),
            imageType: source
          }
        }
      );
    }
  }

  /**
   * @param userSticker {UserSticker}
   */
  _handleStickerFeedback(userSticker) {
    if (this._analyticsMetaService.isTeacherFeedbackPage()) {
      this._analyticsService.sendEvent({
          eventTag: 'feedback:sticker_added',
          properties: {
            ...this._analyticsMetaService.buildFeedbackAddedMeta(),
            stickerText: userSticker.text,
            stickerImage: userSticker.imageUrl,
            stickerScore: userSticker.score
          }
        }
      );
    }
  }

  /**
   * @param textbox {Textbox}
   */
  _handleTextFeedback(textbox) {
    if (this._analyticsMetaService.isTeacherFeedbackPage()) {
      textbox.blurred.once(() => {
        this._analyticsService.sendEvent({
            eventTag: 'feedback:text_added',
            properties: {
              ...this._analyticsMetaService.buildFeedbackAddedMeta(),
              text: textbox.text
            }
          }
        );
      });
    }
  }
}

export class MoreOptionsManager {

  constructor($q, $mdPanel, $mdDialog) {
    this.$q = $q;
    this.$mdPanel = $mdPanel;
    this.$mdDialog = $mdDialog;

    this._canvasContextMenu = CanvasContextMenuController.show;
    this._linkDialog = LinkDialogController.show;
  }

  openMenu(event) {
    if (!event) {
      throw new Error('Event object must be passed to open more options menu');
    }

    return this._canvasContextMenu(this.$mdPanel, this.$q, event);
  }

  /**
   * Opens dialog to edit an existing Link element. Called in link.js
   * @param title {string}
   * @param url
   * @return {Promise}
   */
  editLink(title, url) {
    return this._linkDialog(this.$mdDialog, title, url);
  }

}
