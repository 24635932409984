'use strict';

import moment from 'moment';
import AssignmentQuestion from './assignment-question';

export default class AssignmentWorkQuestion extends AssignmentQuestion {

  /**
   * @param id {string} the assignment question id
   * @param elementList {ElementList}
   * @param chatMessageList {ChatMessageList} the chat message list
   * @param points {number} the number of points scored for this question
   * @param answer {{value: number, correct: boolean}}
   * @param startedAt {string} when the student started working on this question
   */
  constructor(id, elementList, chatMessageList, points, answer, startedAt) {
    super(id, elementList, points, null);
    this._messages = chatMessageList;
    this._answer = answer;
    this._startedAt = startedAt ? moment(startedAt) : undefined;
  }

  static get UNGRADED(){
    return 'ungraded';
  }

  /**
   * @returns {boolean}
   */
  get isWork() {
    return true;
  }

  /**
   * Chat id for the assignment work question
   * @returns {string}
   */
  get chatId() {
    return this._messages.id;
  }

  /**
   * Score of the assignment work question
   * @returns {number|string}
   */
  get points() {
    if (angular.isUndefined(this._points) || this._points === null || isNaN(this._points))  {
      return AssignmentWorkQuestion.UNGRADED;
    }
    else {
      return this._points;
    }
  }

  /**
   * @param value {number}
   */
  set points(value) {
    this._points = value;
  }

  /**
   * @return {boolean}
   */
  get isGraded() {
    return this.points !== AssignmentWorkQuestion.UNGRADED;
  }

  /**
   * Adds the entered score to the existing score for this question
   * @param value {undefined|string|number}
   * @return {number|string}
   */
  addScore(value) {
    if (!angular.isNumber(value) || value < 0) {
      return this.points;
    }
    else if (this.points === AssignmentWorkQuestion.UNGRADED) {
      return value;
    }
    else {
      return parseFloat((value + this.points).toFixed(2));
    }
  }

  /**
   * @returns {ChatMessageList}
   */
  get messages() {
    return this._messages;
  }

  /**
   * @return {{value: string, correct: boolean}}
   */
  get answer() {
    return this._answer;
  }

  /**
   * @param value {{value: string, correct: boolean}}
   */
  set answer(value) {
    this._answer = value;
  }

  /**
   * @returns {moment}
   */
  get startedAt() {
    return this._startedAt;
  }

  /**
   * @param value {moment}
   */
  set startedAt(value) {
    this._startedAt = value;
  }

}
