export default class CkAnimations {

  static makeElementPulse(selector, infinite) {
    let selectRosterButton = angular.element(selector);

    let pulseClass = infinite ? 'ck-pulse-infinite' : 'ck-pulse';
    selectRosterButton.removeClass('ck-pulse-infinite ck-pulse');
    selectRosterButton.off('animationend');

    let onAnimation = () => {
      selectRosterButton.removeClass(pulseClass);
      selectRosterButton.off('animationend', onAnimation);
    };

    selectRosterButton.on('animationend', onAnimation);

    selectRosterButton.addClass(pulseClass);
  }

}
