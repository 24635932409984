
'use strict';

import SaveStates from './save-states';

export default class FirebaseElementSavingIndicator {

  constructor($timeout, AssignmentTrackingService) {

    this.$timeout = $timeout;
    /** @type {AssignmentTrackingService} */
    this._assignmentTrackingService = AssignmentTrackingService;

    this._saveState = '';

    this._assignmentTrackingService.previewModified.subscribe(this._handlePreviewModified, this);
    this._assignmentTrackingService.modified.subscribe(this._handleModified, this);
  }

  /**
   * @returns {string}
   */
  get saveState() {
    return this._saveState;
  }

  /**
   * @param value {string}
   */
  set saveState(value) {
    this._saveState = value;
  }

  _handlePreviewModified() {
    this._saveState = SaveStates.UNSAVED;
    this.$timeout(() => {});
  }

  _handleModified() {
    this._saveState = SaveStates.SAVED;
    this.$timeout(() => {});
  }

  destroy() {
    this._assignmentTrackingService.previewModified.unsubscribe(this._handlePreviewModified, this);
    this._assignmentTrackingService.modified.unsubscribe(this._handleModified, this);
  }

}
