'use strict';
import Base64 from 'base-64';
import LazyVar from '../../model/util/lazy-var';
import {UserRoles} from '../../model/domain/user';
import {ContractPlans} from '../../model/domain/contract';
import HttpService from '../http/http.service';
import JSEvent from '../../model/util/js-event';
import ClassCodeCodec from '../../model/codec/class-code-codec';

export class UserTokenInfo {
  /**
   * @param id {string}
   * @param name {string}
   * @param email {string}
   * @param username {string}
   * @param authType {string}
   * @param roles {string[]}
   * @param plans {string[]}
   * @param contracts {object}
   * @param firebaseToken {string}
   * @param token {string}
   */
  constructor(id, name, email, username, authType, roles, plans, contracts, firebaseToken, token) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.username = username;
    this.authType = authType;
    this.roles = roles;
    this.plans = plans || [];
    this.contracts = contracts || {};
    this.contractIds = Object.keys(this.contracts);

    this.firebaseToken = firebaseToken;
    this.token = token;
  }

  hasRole(role) {
    return this.roles.findIndex((x) => x.toLowerCase() === role.toLowerCase()) >= 0;
  }

  get isAdmin() {
    return this.hasRole(UserRoles.ADMIN);
  }

  get isStudent() {
    return this.hasRole(UserRoles.STUDENT);
  }

  get isTeacher() {
    return this.hasRole(UserRoles.TEACHER);
  }

  get isFree() {
    return !this.isPro;
  }

  get isPro() {
    return this.isProClassroom || this.isProSchool || this.isProDistrict;
  }

  get isProClassroom() {
    return this.plans.includes(ContractPlans.PRO_CLASSROOM);
  }

  get isProSchool() {
    return this.plans.includes(ContractPlans.PRO_SCHOOL);
  }

  get isProDistrict() {
    return this.plans.includes(ContractPlans.PRO_DISTRICT);
  }

  get login() {
    return this.email || this.username;
  }

  /**
   * @param contractId {string}
   * @returns {boolean}
   */
  isContractAdmin(contractId) {
    return this.contracts[contractId] && this.contracts[contractId].admin;
  }

  /**
   * Indicates whether the user is authed with Google
   * @returns {boolean}
   */
  get isGoogleAuthed() {
    return this.authType === AuthType.GOOGLE;
  }

  /**
   * Indicates whether the user is authed with Google
   * @returns {boolean}
   */
   get isCleverAuthed() {
    return this.authType === AuthType.CLEVER;
  }

  get isAnon() {
    return this.authType === AuthType.NONE;
  }
}

export class AuthType {
  static get PASSWORD() {
    return 'password';
  }

  static get NONE() {
    return 'none';
  }

  static get GOOGLE() {
    return 'google';
  }

  static get CLEVER() {
    return 'clever';
  }

  static get CLEVERIMPORT() {
    return 'clever-import';
  }
}

export default class AuthService {
  constructor($http, environment, $log, $q, $state, FirebaseService, StorageService, MixpanelService,
              GoogleClientService, LogRocketService, CleverService, PlatformHeaderService, AnalyticsService) {
    'ngInject';

    this._environment = environment;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {MixpanelService} */
    this._mixpanelService = MixpanelService;
    /** @type {GoogleClientService} */
    this._googleClientService = GoogleClientService;
    /** @type {LogRocketService} */
    this._logRocketService = LogRocketService;
    /** @type {CleverService} */
    this._cleverService = CleverService;
    /** @type {PlatformHeaderService} */
    this._platformHeaderService = PlatformHeaderService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this.$http = $http;
    this.$log = $log;
    this.$q = $q;
    this.$state = $state;

    /** @type {LazyVar.<UserTokenInfo>} */
    //this token gets switched to other users if they are an admin viewing as another user or a co-teacher entering a roster
    this._authData = new LazyVar();
    /** @type {UserTokenInfo} */
    this._adminAuthData = null;
    /** @type {LazyVar.<Promise<{classCode: ClassCode, userTokenInfo: UserTokenInfo}>>} */
    this._anonStudentAuth = new LazyVar();
    /** @type {JSEvent.<UserTokenInfo>} */
    this._userAuthenticated = new JSEvent(this);
    /** @type {JSEvent} */
    this._dataCleared = new JSEvent(this);
    /** @type {ClassCodeCodec} */
    this._classCodeCodec = new ClassCodeCodec();

    this.init();
  }

  init() {
    this._storageService.onRemoteLogOut.subscribe(this.signOut, this);

    // Listen for state changes which impact platform headers
    this._userAuthenticated.subscribe(this._platformHeaderService.setUserInfo, this._platformHeaderService);
    this._dataCleared.subscribe(this._platformHeaderService.clearUserInfo, this._platformHeaderService);
    this._viewAsUser = null;
  }

  /**
   * @returns {JSEvent.<UserTokenInfo>}
   */
  get userAuthenticated() {
    return this._userAuthenticated;
  }

  /**
   * @return {JSEvent}
   */
  get dataCleared() {
    return this._dataCleared;
  }

  get coTeacherAuthData(){
    return this._storageService.coTeacherUserInfo;
  }

  /**
   * @param classCode {string}
   * @param studentName {string}
   * @returns {Promise.<{authData: UserTokenInfo, classCode: string, rosterId: string, assignmentId: string}>}
   */
  authAnonStudent(classCode, studentName, isStudentPreview = false) {

    if (this._anonStudentAuth.classCode !== classCode || this._anonStudentAuth.studentName !== studentName) {
      this._anonStudentAuth.clear();
    }

    this._anonStudentAuth.classCode = classCode;
    this._anonStudentAuth.studentName = studentName;

    return this._anonStudentAuth.value(() => {
      return this._anonLogin(classCode, studentName)
        .then(({token, classCode}) => {
          const authData = isStudentPreview ? this.processTokenResult(token) : this.processTokenResult(token, false);

          return this.$q.all({
            authData,
            classCode
          });
        })
        .catch((err) => {
          this._anonStudentAuth.clear();
          throw err;
        });
    });
  }

  /**
   * @param classCode {string}
   * @param studentName {string}
   * @return {Promise.<{token:string, classCode: ClassCode}>}
   */
  _anonLogin(classCode, studentName) {
    return this
      ._post(
        `${this._environment.serverUrlBase}/v1/users/login/anonymous-student`,
        {
          class_code: classCode,
          name: studentName
        }
      )
      .then(({data}) => {
        return {
          token: data.token,
          classCode: this._classCodeCodec.decode(data.class_code)
        };
      });
  }

  /**
   * Calls to the web services to reset a user's password
   *
   * @param token The reset token
   * @param password the new password
   * @returns {$q} an empty $http promise
   */
  resetPassword(token, password) {
    return this._post(
      `${this._environment.serverUrlBase}/v1/users/password-reset`,
      {
        password: password
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
      .then((result) => {
        return this.processTokenResult(result.data.token, this.rememberMe);
      });
  }

  /**
   * Sends a password reset email to the parameter, if possible
   *
   * @param email the email address for which to reset the password
   * @returns {Promise} an empty $http promise
   */
  requestPasswordReset(email) {
    return this._post(`${this._environment.serverUrlBase}/v1/users/password-reset-request`, {email: email});
  }

  /**
   * Auths a user with a password. returns the resulting auth data and token obtained
   * from our web service response in a Promise.
   * @param id {string}
   * @param password {string}
   * @param [rememberMe] {boolean}
   * @returns {Promise.<UserTokenInfo>}
   */
  authUserWithPassword(id, password, rememberMe) {
    return this.authUser(id, {type: AuthType.PASSWORD, password: password}, rememberMe);
  }

  /**
   * Auths a user. Returns the resulting auth data and token obtained
   * from our web service response in a Promise.
   * @param id {string}
   * @param auth {{type: string, password: string?, token: string?}}
   * @param [rememberMe] {boolean}
   * @returns {Promise.<UserTokenInfo>}
   */
  authUser(id, auth, rememberMe) {
    return this._post(
      `${this._environment.serverUrlBase}/v1/users/login`,
      {
        id: id,
        auth: auth
      }
    )
      .then((result) => this.processTokenResult(result.data.token, rememberMe))
      .then((user) => {
        return user;
      });
  }

  /**
   *
   * @param id {string} email, phone number, or username of user
   * @returns {Promise.<UserTokenInfo>}
   */
  authAsOther(id) {
    return this._post(
      `${this._environment.serverUrlBase}/v1/users/login-as-user`,
      {
        id: id
      },
      {
        headers: {
          Authorization: this.adminAuthData ? `Bearer ${this.adminAuthData.token}` : this.authHeader
        }
      }
    )
      .then((result) => {
        const currentUser = this.adminAuthData || this.authData;
        this.clearData();
        this._adminAuthData = currentUser;
        return result;
      })
      .then((result) => this.processTokenResult(result.data.token));
  }

  /**
   * @returns {Promise.<UserTokenInfo>}
   */
  unauthAsOther() {
    if (this._adminAuthData) {
      const token = this._adminAuthData.token;
      this.clearData();
      return this.processTokenResult(token);
    }
    else {
      return this.$q.resolve(this.authData);
    }
  }

  /**
   * Signs up a new user. returns the resulting auth data and token obtained
   * from our web service response in a Promise.
   * @param email
   * @param password
   * @param rememberMe
   * @returns {Promise}
   */
  signUpTeacherWithEmail(formInputData, password, rememberMe) {
    let userData = {
      email: formInputData.email,
      first_name: formInputData.firstName,
      last_name: formInputData.lastName,
      auth: {
        type: AuthType.PASSWORD,
        password: password
      },
      roles: [UserRoles.TEACHER]
    };

    return this.signUpUser(userData, rememberMe);
  }

  /**
   * @param classCode {string}
   * @param email {string}
   * @param password {string}
   * @param rememberMe {boolean}
   */
  signupStudentWithEmail(classCode, email, password, rememberMe) {
    let userData = {
      class_code: classCode,
      email: email,
      auth: {
        type: AuthType.PASSWORD,
        password: password
      },
      roles: [UserRoles.STUDENT]
    };

    return this.signUpUser(userData, rememberMe);
  }

  /**
   * @param classCode {string}
   * @param username {string}
   * @param password {string}
   * @param rememberMe {boolean}
   */
  signupStudentWithUsername(classCode, username, password, rememberMe) {
    let userData = {
      class_code: classCode,
      username: username,
      auth: {
        type: AuthType.PASSWORD,
        password: password
      },
      roles: [UserRoles.STUDENT]
    };

    return this.signUpUser(userData, rememberMe);
  }

  signUpUser(userData, rememberMe) {
    try {
      let mixpanelId = this._mixpanelService._enabled ? this._mixpanelService.getDistinctId() : null;
      userData['properties'] = {'mixpanel_id': mixpanelId};
      userData['campaign_id'] = this._storageService.utmCampaignId;
    }
    catch (error) {
      this.$log.error(error);
    }

    return this._post(`${this._environment.serverUrlBase}/v1/users`, userData)
      .then((result) => {
        return this.processTokenResult(result.data.token, rememberMe);
      })
      .then((userInfo) => {
        this._analyticsService.accountCreated(userData.auth.type);
        return userInfo;
      });
  }

  /**
   * updates the user's password. requires the old password and
   * the user's access token (i.e. user must be logged in and authed)
   * @param  {String} oldPassword old password
   * @param  {String} newPassword new password
   * @return {Boolean}            success
   */
  updatePassword(oldPassword, newPassword) {
    return this._post(
      `${this._environment.serverUrlBase}/v1/users/${this.authData.id}/update-password`,
      {
        old_password: oldPassword,
        password: newPassword
      },
      {
        headers: {
          Authorization: this.authHeader
        }
      })
      .then((result) => {
        this.processTokenResult(result.data.token, this._storageService.rememberMe);
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  /**
   * takes the response of our web service endpoints, which contains the token,
   * and processes it. Extracts the token and decodes it. Utilizes StorageService
   * as indicated by the user. returns the exracted data in a Promise.
   *
   * @param token {string} The access token from the server
   * @param [rememberMe] {boolean} Only omit rememberMe if authing in a transient way - either as an anonymous student or impersonating another user
   * @returns {Promise.<UserTokenInfo>}
   */
  processTokenResult(token, rememberMe) {

    /**
     * @type {null|{sub, name, email, username, authType, roles, plans, contracts, firebase}}
     */
    let extracted = null;

    try {
      extracted = this._extractToken(token);
    }
    catch (err) {
      return this.$q.reject(err);
    }

    let info = new UserTokenInfo(
      extracted.sub,
      extracted.name,
      extracted.email,
      extracted.username,
      extracted.authType,
      extracted.roles,
      extracted.plans,
      extracted.contracts,
      extracted.firebase,
      token
    );

    return this._firebaseService.auth(info)
      .then(() => {
        if (angular.isDefined(rememberMe)) {
          this._storageService.setUserInfo(info, rememberMe);
        }
        // Identify the user
        this._authData.set(info);
        this._analyticsService.lockUntilIdentify();
        this.userAuthenticated.raise(info);
        return info;
      });
  }

  /**
   * separates the three parts of the token
   * @param  {String} token
   */
  _extractToken(token) {
    let splits = token.split('.');
    if (splits.length !== 3) {
      throw new Error('Invalid format');
    }

    try {
      return angular.fromJson(Base64.decode(splits[1]));
    }
    catch (err) {
      throw new Error('Could not extract token');
    }
  }

  /**
   * returns the current user token.
   * @returns {String|undefined}
   */
  get currentToken() {
    return this.authData && this.authData.token;
  }

  /**
   * returns the current user's auth data (i.e. extracted token). First tries
   * to fetch this data from the local variable _authData. If _authData is not
   * defined, attempts to fetch the data from storage via StorageService.
   * @return {UserTokenInfo} currently saved authData
   */
  get authData() {
    return this._authData.value(() => {
      let info = this._storageService.userInfo;
      if (info) {
        this._analyticsService.lockUntilIdentify();
        this.userAuthenticated.raise(info);
      }
      return info;
    });
  }

  get anonStudentAuth() {
    return this._anonStudentAuth;
  }

  /**
   * @returns {UserTokenInfo|null}
   */
  get adminAuthData() {
    return this._adminAuthData;
  }

  /**
   * @returns {string|undefined}
   */
  get currentUserId() {
    return this.isLoggedIn ? this.authData.id : undefined;
  }

  /**
   * @returns {string}
   */
  get authHeader() {
    if (this.isLoggedIn) {
      return `Bearer ${this.currentToken}`;
    }

    return '';
  }

  /**
   * @returns {boolean}
   */
  get rememberMe() {
    return this._storageService.rememberMe;
  }

  /**
   * returns whether there is a user currently logged in.
   * @return {Boolean}
   */
  get isLoggedIn() {
    return !!this.authData;
  }

  /**
   * returns whether the logged in user is a superuser
   * @return {Boolean}
   */
  get isSuperuser() {
    return this.adminAuthData || (this.authData && this.authData.isAdmin);
  }

  /**
   * returns whether to view as user
   * @return {Boolean}
   */
  get viewAsUser() {
    return this._viewAsUser;
  }

  /**
   * @params value {Boolean}
   */
  set viewAsUser(value) {
    this._viewAsUser = value;
  }

  /**
   *
   */
  clearData() {
    // Clear the mixpanel identity
    this._analyticsService.clearOutData();
    this._authData.clear();
    this._anonStudentAuth.clear();
    this._adminAuthData = null;

    this.dataCleared.raise({});
  }

  /**
   * Removes a user's information from session and local storage
   */
  signOut() {
    let isAnonStudent = this.authData && this.authData.isStudent && this.authData.isAnon;

    this.clearData();
    this._storageService.setUserInfo(null, false);

    if (this._storageService.utmCampaignId) {
      this._storageService.utmCampaignId = '';
    }
    this._storageService.clearCoTeacherInfo();

    if (isAnonStudent) {
      this.$log.debug('Signing out anon student');
      this.$state.go('root.login', {});
    } else {
      this._storageService.hideOfflineStudents = false;
      this._storageService.hideUnstartedAssignmentWorks = false;
      this._storageService.assignmentsListAsc = undefined;
      this._storageService.assignmentsListColumn = undefined;
      this._storageService.assignmentLibrarySubject = '';
      this._storageService.assignmentLibraryGradeRange = '';
      this._storageService.assignmentLibraryQuery = '';
      this._storageService.assignmentLibrarySelection = null;

      this.$state.go('root.account-login');
      this._googleClientService.signOut();
    }
  }

  /**
   * Returns a promise to sign in with google. If failure, rejects with an Error
   *
   * @returns {Promise.<{idToken: string, firstName: string, lastName: string, email: string}>}
   */
  googleSignIn() {
    return this._googleClientService.signIn();
  }

  /**
   * Auths a user with google. If googleInfo parameter (result from #googleSignIn()) is not provided,
   * #googleSignIn() will be called.
   *
   * @param [googleInfo] {{idToken: string, firstName: string, lastName: string, email: string}}
   * @returns {Promise.<UserTokenInfo>}
   */
  authUserWithGoogle(googleInfo) {
    let promise = this.$q.resolve(googleInfo);
    if (!googleInfo) {
      promise = this.googleSignIn();
    }
    return promise
      .then((result) => {
        return this.authUser(result.email, {type: AuthType.GOOGLE, token: result.idToken}, true);
      });
  }

  /**
   * @param googleInfo
   * @param role {string}
   * @param [classCode] {string}
   * @return {Promise.<UserTokenInfo>}
   */
  signUpWithGoogle(googleInfo, role, classCode) {
    let userData = {
      email: googleInfo.email,
      first_name: googleInfo.firstName,
      last_name: googleInfo.lastName,
      auth: {
        type: AuthType.GOOGLE,
        token: googleInfo.idToken
      },
      roles: [role],
      class_code: classCode
    };
    return this.signUpUser(userData, true);
  }

  /**
   * @param [classCode] {string}
   */
  linkToCleverAuth(classCode) {
    this._cleverService.linkToCleverAuth(classCode);
  }

  /**
   * @param cleverInfo {CleverInfo}
   * @param [classCode] {String}
   */
  signUpWithClever(cleverInfo, classCode) {
    let userData = {
      first_name: cleverInfo.firstName,
      last_name: cleverInfo.lastName,
      username: cleverInfo.cleverUsername,
      auth: {
        type: AuthType.CLEVER,
        token: cleverInfo.accessToken
      },
      roles: [cleverInfo.userType]
    };

    if (cleverInfo.email) {
      userData.email = cleverInfo.email;
    }

    if (classCode) {
      userData['class_code'] = classCode;
    }

    return this.signUpUser(userData, true);
  }

  /**
   * Auths a user with Clever. Returns the resulting auth data and token obtained
   * from our web service response in a Promise.
   *
   * @param cleverInfo {CleverInfo}
   * @returns {Promise.<UserTokenInfo>}
   */
  authUserWithClever(cleverInfo) {
    let cleverId = cleverInfo.email ? cleverInfo.email : cleverInfo.cleverUsername;

    return this.authUser(cleverId, {type: AuthType.CLEVER, token: cleverInfo.accessToken}, true);
  }

  /**
   * @param url {string}
   * @param data {*}
   * @param [config] {*}
   * @returns {Promise.<T>}
   */
  _post(url, data, config) {
    config = this._platformHeaderService.addHeaders(url, config);
    return this.$http.post(url, data, config)
      .catch((response) => HttpService.handleErrorResponse(response, this.$q, this.$log, this));
  }

  /**
   * @return {boolean}
   */
  isACoTeacher() {
    if (this.coTeacherAuthData) {
      return (this.authData && this.authData.id) !== this.coTeacherAuthData.id;
    }
    return false;
  }
}
