
'use strict';

import InviteCodeCodec from '../../model/codec/invite-code-codec';

export default class InviteCodeService {

  /**
   * @ngInject
   */
  constructor(environment, HttpService) {
    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {InviteCodeCodec} */
    this._inviteCodeCodec = new InviteCodeCodec();
  }

  /**
   * @param contractId {string}
   * @param [rosterId] {string}
   * @return {Promise.<{code: string, contractId: string, rosterId: string}>}
   */
  createInviteCode(contractId, rosterId) {
    return this._httpService
      .authPost(
        this._uri('/v1/invite-codes'),
        {
          contract_id: contractId,
          roster_id: rosterId
        }
      )
      .then((data) => {
        return {
          code: data.code,
          contractId: data.contract_id,
          rosterId: data.roster_id
        };
      });
  }

  get(code) {
    return this._httpService
      .authGet(this._uri(`/v1/invite-codes/${code}`))
      .then((data) => {
        return this._inviteCodeCodec.decode(data);
      });
  }

  submit(code) {
    return this._httpService
      .authPost(
        this._uri(`/v1/invite-codes/${code}/submit`),
        {}
      )
      .then((data) => {
        return data.token;
      });
  }

  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }

}
