'use strict';

import { SubscriptionFunnel } from '../../services/mixpanel/mixpanel.service';
import ContextualPaywallDialogTemplate from './contextual-paywall-dialog.html';

export class ContextualPaywalls {

  static get Folders() {
    return 'folders';
  }

  static get StudentWorkExport() {
    return 'student_work_export';
  }

  static get ScientificFitb() {
    return 'scientific_fitb';
  }

  static get GradeExport() {
    return 'grade_export';
  }

  static get AnswerExport() {
    return 'answer_export';
  }

  static get SQVStickers() {
    return 'sqv_stickers';
  }

  static get StudentAccounts() {
    return 'student_accounts';
  }

  static get GradebookIntegration() {
    return 'gradebook_integration';
  }
}

export default class ContextualPaywallDialogController {

  /**
   * @ngInject
   */
  constructor($mdDialog, BreadcrumbService, AnalyticsService, StaticContentService, LogRocketService, paywallId, source) {
    this.$mdDialog = $mdDialog;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {LogRocketService} */
    this._logRocketService = LogRocketService;

    this._paywalls = [];
    this._currentPaywallId = paywallId;
    this._currentIndex = 0;
    this._source = source;
    this._loading = true;

    this._init();
  }

  _init() {

    this._analyticsService.subscriptionFunnel(
      SubscriptionFunnel.PAYWALL,
      undefined,
      undefined,
      this._source
    );

    this._staticContentService.getContextualPaywalls()
      .then((paywalls) => {
        this._paywalls = paywalls;
        this._currentIndex = this.indexForId(this._currentPaywallId);
        this._loading = false;
      });
  }

  get loading() {
    return this._loading;
  }

  indexForId(id) {
    let index = 0;
    this._paywalls.forEach((paywall, i) => {
      if (paywall.id === id) {
        index = i;
      }
    });
    return index;
  }

  get currentTitle() {
    let paywall = this.paywalls[this.currentIndex];
    return paywall && paywall.text;
  }

  get currentIndex() {
    return this._currentIndex;
  }

  get paywalls() {
    return this._paywalls;
  }

  get ImageHeight() {
    return 304;
  }

  get imagesStyle() {
    return {
      transform: `translateY(${this.ImageHeight * this._currentIndex * -1}px)`
    };
  }

  prev() {
    let prevIndex = this._currentIndex - 1;
    this._currentIndex = prevIndex < 0 ? this._paywalls.length - 1 : prevIndex;
    let prevPaywall = this._paywalls[this._currentIndex];
    this._currentPaywallId = prevPaywall.id;
  }

  next() {
    let nextIndex = this._currentIndex + 1;
    this._currentIndex = this._paywalls.length > nextIndex ? nextIndex : 0;
    let nextPaywall = this._paywalls[this._currentIndex];
    this._currentPaywallId = nextPaywall.id;
  }

  goTo(paywall) {
    this._currentPaywallId = paywall.id;
    this._currentIndex = this.indexForId(this._currentPaywallId);
  }

  get onFirst() {
    return this._currentIndex === 0;
  }

  get onLast() {
    return this._paywalls && this._currentIndex === this._paywalls.length - 1;
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  goToTeacherPage() {
    this.$mdDialog.hide();
    this._breadcrumbService.go('root.account.create-payment');
  }

  goToOrderPage() {
    this._analyticsService.subscriptionFunnel(
      SubscriptionFunnel.LEARN_MORE,
      undefined,
      undefined,
      this._source
    );

    this.$mdDialog.hide();
    this._staticContentService.goToOrdersPage(this._logRocketService.initialized);
  }

  /**
   * Shows the contextual paywall dialog
   *
   * @param $mdDialog
   * @param paywallId {string} highlighter | ?
   * @param source {string} where the paywall originated - for mixpanel
   * @returns {Promise}
   */
  static show($mdDialog, paywallId, source) {
    return $mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: true,
      template: ContextualPaywallDialogTemplate,
      controller: ContextualPaywallDialogController,
      controllerAs: 'ctrl',
      locals: {
        paywallId,
        source
      }
    });
  }
}
