const fontFamilyAvailable = [
  {
    name: 'Gotham',
    value: 'GothamRoundedMedium'
  },
  {
    name: 'Gotham Italic',
    value: 'GothamRoundedLightItalic'
  },
  {
    name: 'Baskerville',
    value: 'LibreBaskervilleRegular'
  },
  {
    name: 'Bebas Neue',
    value: 'BebasNeueRegular'
  },
  {
    name: 'Caveat',
    value: 'CaveatRegular'
  },
  {
    name: 'Comic Neue',
    value: 'ComicNeueRegular'
  },
  {
    name: 'Courier Prime',
    value: 'CourierPrimeRegular'
  },
  {
    name: 'Garamond',
    value: 'EBGaramondRegular'
  },
  {
    name: 'Indie Flower',
    value: 'IndieFlowerRegular'
  },
  {
    name: 'Lato',
    value: 'LatoRegular'
  },
  {
    name: 'Montserrat',
    value: 'MontserratRegular'
  },
  {
    name: 'Open Sans',
    value: 'OpenSansRegular'
  },
  {
    name: 'Pacifico',
    value: 'PacificoRegular'
  },
  {
    name: 'PT Serif',
    value: 'PTSerifRegular'
  },
  {
    name: 'Roboto Bold',
    value: 'RobotoBold'
  },
  {
    name: 'Roboto Regular',
    value: 'RobotoRegular'
  }
];
  
export class FontFamily {

  /**
   * @returns {{name: string}[]}
   */
  static get ELEMENT_FONTFAMILY() {
    return fontFamilyAvailable;
  }

}