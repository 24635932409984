'use strict';

import Codec from './codec';
import InviteCode from '../domain/invite-code';

/**
 * Encodes/decodes an invite code
 */
export default class InviteCodeCodec extends Codec {

  /**
   * @param value {InviteCode}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        code: value.code,
        contract_id: value.contractId,
        owner_id: value.ownerId
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {InviteCode}
   */
  decode(value) {
    if (value) {
      return new InviteCode(
        value.code,
        value.contract_id,
        value.owner_id
      );
    }

    return null;
  }

}
