'use strict';

import Codec from './codec';
import SharedWork from '../domain/shared-work';
import SharedWorkUserCodec from './shared-work-user.codec';
import AssignmentCodec from './assignment-codec';
import AssignmentWorkCodec from './assignment-work-codec';

/**
 * Encodes/Decodes a shared work user
 */
export default class SharedWorkCodec extends Codec {
  constructor(FirebaseService) {
    super();
    this._firebaseService = FirebaseService;
    this._sharedWorkUserCodec = new SharedWorkUserCodec();
    this._assignmentCodec = new AssignmentCodec(this._firebaseService);
    this._assignmentWorkCodec = new AssignmentWorkCodec(this._firebaseService);
  }

  encode(value) {
    return {
      id: value.id,
      shared_by_user: value.shareByUser,
      student: value.student,
      assignment: this._assignmentCodec.encode(value.assignmentWork.assignment),
      work: this._assignmentWorkCodec.encode(value.assignmentWork)

    };
  }
  decode(value) {
    const assignment = value && this._assignmentCodec.decode(value.assignment);
    return new SharedWork(
      value.id,
      this._sharedWorkUserCodec.decode(value.shared_by_user),
      this._sharedWorkUserCodec.decode(value.student),
      this._assignmentWorkCodec.decode(assignment, value.work)
    );
  }
}
