'use strict';
import { UserRoles } from '../../model/domain/user';
import ChatMessageTemplate from './chat-message.component.html';

/**
 * All of the static information needed to properly render a chat message
 */
export class ChatMessageConfig {

  /**
   * @param current {ChatMessage} the current message to be displayed
   * @param currentUserId {string} the current user's id
   * @param role {string} does the current have a user role
   * @param helpeeId {string} the user id of the helpee related to the help center
   * @param [anonAnimalNameForHelpee] {string|undefined} the anon name used to hide helpee's identify to peers
   * @param [anonAnimalNameForHelper] {string|undefined} the anon name used to hide a helper's identify to other helpers
   * @param teacherId {string} the id of the assignment owner, which is a proxy for the teacher
   */
  constructor(current, currentUserId, role, helpeeId, anonAnimalNameForHelpee, anonAnimalNameForHelper, teacherId) {
    this._current = current;
    this._currentUserId = currentUserId;
    this._role = role;
    this._helpeeId = helpeeId;
    this._anonAnimalNameForHelpee = anonAnimalNameForHelpee;
    this._anonAnimalNameForHelper = anonAnimalNameForHelper;
    this._teacherId = teacherId;
  }

  get current() {
    return this._current;
  }

  get currentUserId() {
    return this._currentUserId;
  }

  get role() {
    return this._role;
  }

  get isStudent() {
    return this._role && this._role === UserRoles.STUDENT;
  }

  get isTeacher() {
    return this._role && this._role === UserRoles.TEACHER;
  }

  get helpeeId() {
    return this._helpeeId;
  }

  get anonAnimalNameForHelpee() {
    return this._anonAnimalNameForHelpee;
  }

  get anonAnimalNameForHelper() {
    return this._anonAnimalNameForHelper;
  }

  get teacherId() {
    return this._teacherId;
  }

}

/**
 * Displays a single chat message from a chat conversation
 */
class ChatMessageController {

  /**
   * @ngInject
   */
  constructor() {}

  /**
   * @returns {ChatMessageConfig}
   */
  get config() {
    return this._config;
  }

  /**
   * @param value {ChatMessageConfig}
   */
  set config(value) {
    this._config = value;
  }

  /**
   * @return {ChatMessage}
   */
  get prev() {
    return this._prev;
  }

  /**
   * @param value {ChatMessage}
   */
  set prev(value) {
    this._prev = value;
  }

  /**
   * @return {ChatMessage}
   */
  get current() {
    return this.config && this.config.current;
  }

  /**
   * @return {ChatMessage}
   */
  get next() {
    return this._next;
  }

  /**
   * @param value {ChatMessage}
   */
  set next(value) {
    this._next = value;
  }

  get currentUserId() {
    return this.config && this.config.currentUserId;
  }

  get isStudent() {
    return this.config && this.config.isStudent;
  }

  get helpeeId() {
    return this.config && this.config.helpeeId;
  }

  get anonAnimalNameForHelpee() {
    return this.config && this.config.anonAnimalNameForHelpee;
  }

  get anonAnimalNameForHelper() {
    return this.config && this.config.anonAnimalNameForHelper;
  }

  get teacherId() {
    return this.config && this.config.teacherId;
  }

  get formatName() {
    return this.messageIsFromCurrentUser ? 'Me' : this.displayName;
  }

  get displayName() {
    let currentUserIsPeerHelper = this.isStudent && !this.helpeeIsCurrentUser;

    if (currentUserIsPeerHelper && this.current.userId === this.helpeeId) {
      return this.anonAnimalNameForHelpee;
    }
    else if (currentUserIsPeerHelper && this.current.userId !== this.teacherId) {
      return this.anonAnimalNameForHelper;
    }
    else {
      return this.current.displayName;
    }
  }

  get messageClass() {
    return `${this.current.type} ${this.blendingClass}`;
  }

  get blendingClass() {
    if (this.blendBoth) {
      return 'blend-both';
    }
    else if (this.blendTop) {
      return 'blend-top';
    }
    else if (this.blendBottom) {
      return 'blend-bottom';
    }
    else {
      return '';
    }
  }

  get blendBoth() {
    return this.blendTop && this.blendBottom;
  }

  get blendTop() {
    return this.previousMessageIsFromSameUser && this.previousMessageIsOfSameType;
  }

  get blendBottom() {
    return this.nextMessageIsFromSameUser && this.nextMessageIsOfSameType;
  }

  get showName() {
    return !this.prev || this.previousMessageIsFromDifferentUser;
  }

  get previousMessageIsFromDifferentUser() {
    return this.prev && this.prev.userId !== this.current.userId;
  }

  get previousMessageIsFromSameUser() {
    return this.prev && this.prev.userId === this.current.userId;
  }

  get previousMessageIsOfSameType() {
    return this.prev && this.prev.type === this.current.type;
  }

  get nextMessageIsFromSameUser() {
    return this.next && this.next.userId === this.current.userId;
  }

  get nextMessageIsOfSameType() {
    return this.next && this.next.type === this.current.type;
  }

  get messageIsFromCurrentUser() {
    return this.currentUserId === this.current.userId;
  }

  get helpeeIsCurrentUser() {
    return this.helpeeId === this.currentUserId;
  }

  get messageIsFromHelpee() {
    return this.helpeeId === this.current.userId;
  }

  get showHand() {
    return this.current.isCheck || this.current.isHelp || this.current.isHandDown;
  }

  get formatMessage() {
    if (this.current.isCheck || this.current.isHelp) {
      let subject = this.messageIsFromCurrentUser ? 'You' : this.displayName;
      let object = this.messageIsFromCurrentUser ? 'your' : 'their';
      let checkOrHelp = this.current.isHelp ? 'help' : 'a check';
      return `${subject} raised ${object} hand for ${checkOrHelp}!`;
    }
    else if (this.current.isHandDown) {
      let subject = this.messageIsFromCurrentUser ? 'You' : this.displayName;

      let object;
      if (this.helpeeIsCurrentUser) {
        object = 'your';
      }
      else if (this.messageIsFromHelpee) {
        object = 'their';
      }
      else {
        object = 'a';
      }

      return `${subject} put ${object} hand down!`;
    }
    else if (this.current.isMessage) {
      return this.current.text;
    } else {
      return '';
    }
  }

}

export default {
  bindings: {
    config: '<',
    prev: '<',
    next: '<'
  },
  template: ChatMessageTemplate,
  controller: ChatMessageController,
  controllerAs: 'ctrl'
};
