'use strict';

import ErrorDialogController from '../error-dialog/error-dialog.controller';
import Validation from '../../model/util/validation';
import StaticContentService from '../../services/static/static-content.service';
import AdminInfoDialogControllerTemplate from '../../components/admin-info-dialog/admin-info-dialog.html';


/**
 * Dialog to submit admin info
 */
export default class AdminInfoDialogController {

  /**
   * @ngInject
   */
  constructor($mdDialog, CacheService, OrganizationService, OrderService, StaticContentService, AnalyticsService) {

    this.$mdDialog = $mdDialog;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this.emailRegex = Validation.EmailRegex;
    this.state = this.EMAIL_ADMIN;

    this._user = null;
    this._adminFirstName = '';
    this._adminLastName = '';
    this._adminTitle = '';
    this._adminEmail = '';
    this._emailSubject = 'I found a great opportunity to get Classkick Pro for everybody!';
    this._emailBody = this.DEFAULT_EMAIL_BODY;

    this._titles = ['Principal', 'Instructional Coach', 'Technology Coordinator', 'Curriculum/Department Chair'];

    this._errorDialog = ErrorDialogController.show;

    this._init();
  }

  _init() {
    this._cacheService.getUser()
      .then((user) => {
        this._user = user;
      });
  }

  get EMAIL_ADMIN() {
    return 'email_admin';
  }

  get INPUT_ADMIN_INFO() {
    return 'input_admin_info';
  }

  get FORM_SUBMITTED() {
    return 'form_submitted';
  }

  /**
   * @return {string}
   */
  get adminFirstName() {
    return this._adminFirstName;
  }

  /**
   * @param value {string}
   */
  set adminFirstName(value) {
    this._adminFirstName = value;
  }

  /**
   * @return {string}
   */
  get adminLastName() {
    return this._adminLastName;
  }

  /**
   * @param value {string}
   */
  set adminLastName(value) {
    this._adminLastName = value;
  }

  /**
   * @return {string}
   */
  get adminTitle() {
    return this._adminTitle;
  }

  /**
   * @param value {string}
   */
  set adminTitle(value) {
    this._adminTitle = value;
  }

  /**
   * @return {string}
   */
  get adminEmail() {
    return this._adminEmail;
  }

  /**
   * @param value {string}
   */
  set adminEmail(value) {
    this._adminEmail = value;
  }

  /**
   * @return {string}
   */
  get emailSubject() {
    return this._emailSubject;
  }

  /**
   * @param value {string}
   */
  set emailSubject(value) {
    this._emailSubject = value;
  }

  /**
   * @return {string}
   */
  get emailBody() {
    return this._emailBody;
  }

  /**
   * @param value {string}
   */
  set emailBody(value) {
    this._emailBody = value;
  }

  /**
   * @return {string}
   */
  get DEFAULT_EMAIL_BODY() {
    return "Hi,\n\nI've been using Classkick in my classroom this year and my students love the collaboration and feedback it helps me give to each of them.\n\nIf our school upgrades to a Pro School subscription, then every teacher and student can use it to help us improve student learning and engagement. We get unlimited assignments, advanced classroom management and grade export.\n\nView the price here https://pro.classkick.com/order to lock in the best limited time price.\n\nThank you!";
  }

  /**
   * @return {Number}
   */
  get EMAIL_BODY_MAX_SIZE() {
    return 1000;
  }

  goToSalesBuddy() {
    this._analyticsService.sendEvent({eventTag: 'proCTA:quoteLink_clicked'});
    this._staticContentService.goToOrdersPage();
  }

  /**
   * Shows an admin information dialog with the provided message
   *
   * @param $mdDialog
   * @param isAlert {boolean}
   * @param fullscreen {boolean}
   * @returns {*|Promise|void}
   */

  static show($mdDialog, isAlert = false, fullscreen = false) {
    let config = {
      clickOutsideToClose: true,
      escapeToClose: true,
      template: AdminInfoDialogControllerTemplate,
      controller: AdminInfoDialogController,
      controllerAs: 'ctrl',
      locals: {
        isAlert
      },
      fullscreen
    };
    return $mdDialog.show(config);
  }

  _setValidity(scope, key, value) {
    scope.adminEmailForm.adminEmailInput.$setValidity(key, value);
  }

  isButtonDisabled() {
    if (this.emailRegex.test(this.adminEmail) && this.isFieldValid(this.adminTitle) && this.isFieldValid(this.adminFirstName) && this.isFieldValid(this.adminLastName)){
      return false;
    }
    return true;
  }

  isEmailValid(string) {
    return string !== '' && angular.isDefined(string) && this.emailRegex.test(string);
  }

  stringHasComma(string) {
    return string.indexOf(',') !== -1;
  }

  splitEmails(string) {
    return string.split(/[,;]/).map((email) => email.trim());
  }

  emailUpdated(scope) {
    this._setValidity(scope, 'email-format', this.isEmailValid(this.adminEmail));
  }

  parseEmailBeforeSend(string) {
    return string.replaceAll('\n', '<br>');
  }

  submit() {
    this._analyticsService.sendEvent({eventTag: 'proCTA:sendEmail_clicked'});
    this._organizationService.getForUser(this._user.id)
      .then((result) => {
        const firstOrg = result ? result[0] : null;
        if (firstOrg) {
          // submit form info as organization admin
          return this._organizationService.addAdmin(firstOrg.id, `${this._adminFirstName} ${this._adminLastName}`, this._adminEmail, this._adminTitle);
        }
      })
      .then((result) => {
        let promise;
        if (this.state === this.EMAIL_ADMIN) {
          promise = this._orderService.share(
            '',
            'pro@classkick.com',
            this.splitEmails(this.adminEmail),
            [this._user.email],
            this.emailSubject,
            this.parseEmailBeforeSend(this.emailBody),
            undefined,
            {
              user_id: this._user !== null ? this._user.id : null,
              event_tag: 'proCTA:sendEmail_clicked'
            }
          );
        } else {
          promise = Promise.resolve();
        }
        this.state = this.FORM_SUBMITTED;
        return promise;
      })
      .catch(() => {
        this.showErrorDialog();
      });
  }

  showErrorDialog() {
    this._errorDialog(this.$mdDialog, 'Uh oh! An error occurred', 'Please try again later or send an email to pro@classkick.com');
  }

  cancel(){
    this.$mdDialog.hide();
  }
}
