'use strict';

import TextboxBase from './textbox-base';
import Size from '../size';

export default class Textbox extends TextboxBase {

  /**
   * @param id {string}
   * @param metadata {ElementMetadata}
   * @param text {string}
   * @param location {Point}
   * @param [size] {Size}
   * @param [fontSize] {number}
   * @param [padding] {Size}
   * @param [colorSpans] {Array.<ColorSpan>}
   * @param [activeColor] {string}
   * @param [backgroundColor] {string}
   * @param [borderColor] {string}
   */
  constructor(id, metadata, text, location, size, fontSize, padding, colorSpans, activeColor, backgroundColor, borderColor, fontFamily) {
    super(id, Textbox.type, metadata);

    this._text = text || this._text;
    this._location = location || this._location;
    this._fontSize = fontSize || this._fontSize;
    this._size = size || new Size(100 + 10 * this._fontSize, 200);

    this._padding = padding || this._padding;
    this._colorSpans = colorSpans || this._colorSpans;
    this._activeColor = activeColor || this._activeColor;
    this._backgroundColor = backgroundColor || TextboxBase.DEFAULT_BACKGROUND_COLOR;
    this._borderColor = borderColor || TextboxBase.DEFAULT_BORDER_COLOR;
    this._fontFamily = fontFamily || TextboxBase.DEFAULT_TEXT_FONT_FAMILY;
  }

  static get type() {
    return 'textbox';
  }

  static get CHARACTER_LIMIT() {
    return 4000;
  }

  /**
   * @param other {Textbox}
   */
  merge(other) {
    this._text = angular.isDefined(other._text) ? other._text : this._text;
    this._colorSpans = other._colorSpans || this._colorSpans;

    const oldFontSize = this._fontSize;
    this._fontSize = other._fontSize || this._fontSize;

    this._size = other._size || this._size;
    this._location = other._location || this._location;
    this._metadata = other._metadata || this._metadata;
    this._backgroundColor = other._backgroundColor || this._backgroundColor;
    this._borderColor = other._borderColor || this._borderColor;
    this._fontFamily =  other._fontFamily || this._fontFamily;

    if (this._fontSize !== oldFontSize) {
      this._arranger.invalidateLayout();
    }

    this.tryUpdate();
  }

  /**
   * @returns {{_text: string, _colorSpans: Array.<ColorSpan>, _fontSize: number, _size: Size, _location: Point}}
   */
  snapshot() {
    return {
      _text: this._text,
      _colorSpans: this._colorSpans,
      _fontSize: this._fontSize,
      _size: this._size,
      _location: this._location,
      _metadata: this._metadata,
      _backgroundColor: this._backgroundColor,
      _borderColor: this._borderColor,
      _fontFamily: this._fontFamily
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {Textbox}
   */
  fromSnapshot(id, snapshot) {
    return new Textbox(
      id,
      snapshot._metadata,
      snapshot._text,
      snapshot._location,
      snapshot._size,
      snapshot._fontSize,
      undefined,
      snapshot._colorSpans,
      undefined,
      snapshot._backgroundColor,
      snapshot._borderColor,
      snapshot._fontFamily
    );
  }

}
