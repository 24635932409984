
'use strict';

import { AssignmentsTableColumns } from '../assignments-list/assignments-list.controller';
import Assignment, { AssignmentTags } from '../../model/domain/assignment';
import { ProfileViewFunnel } from '../../services/mixpanel/mixpanel.service';

export default class PublicAssignmentsListController {

  constructor($mdSidenav, $location, $stateParams, $mdToast, AnalyticsService, UserService, BreadcrumbService, AuthService, StorageService, clipboard) {
    'ngInject';

    this.$mdSidenav = $mdSidenav;
    /** @type {$stateParams} */
    this.$stateParams = $stateParams;
    /** @type {$mdToast} */
    this.$mdToast = $mdToast;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {UserService} */
    this._userService = UserService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    this._clipboard = clipboard;
    this._orderBy = AssignmentsTableColumns.NAME;
    this._isAscending = true;
    this._loading = true;
    this._username = null;

    if (!this.isAuthenticated) {
      this._breadcrumbService.go('public-assignments-list', {
        userId: $stateParams.userId
      });
    } else {
      this._breadcrumbService.go('root.account.nav.public-assignments-list', {
        userId: $stateParams.userId
      });
    }

    let protocol = $location.protocol();
    let host = $location.host();
    let port = '';
    // If we're not on the default http or https ports, we'll include the port in the link
    if (!($location.port() === 80 || $location.port() === 443)) {
      port = `:${$location.port()}`;
    }

    let path = `/users/${this.$stateParams.userId}/shared`;
    this._link = `${protocol}://${host}${port}${path}`;

    this.init();
  }

  /**
   * Loads a user's public assignments
   */
  init() {
    this._userService.getPublicAssignmentsWithFolderAndTags(this.$stateParams.userId)
      .then(({assignments, username}) => {
        this._assignments = assignments;
        this.loadAssignmentsAndFoldersToDisplay();
        this._username = username;
        this._loading = false;
        this._analyticsService.profileViewFunnel(ProfileViewFunnel.VIEWED, this.$stateParams.userId, this._authService.currentUserId);
      })
      .catch((error) => {
        this._error = error;
      });
  }

  get isAuthenticated() {
    return this._authService.isLoggedIn;
  }

  get clipboardIsSupported() {
    return this._clipboard && this._clipboard.supported;
  }

  /**
   * @return {string}
   */
  get username() {
    return this._username;
  }

  /**
   * @return {Assignment[]}
   */
  get assignments() {
    return this._assignments;
  }

  /**
   * The number of assignments needed to not show the help message under the assignments list
   * @returns {number}
   */
  get ASSIGNMENTS_HELP_MESSAGE_LIMIT() {
    return 3;
  }

  get PUBLIC_ASSIGNMENTS_HELP_MESSAGE_LIMIT() {
    return 1;
  }

  /**
   * @returns {boolean}
   */
  get loading() {
    return this._loading && !this._error;
  }

  /**
   * @returns {boolean}
   */
  get error() {
    return this._error;
  }

  /**
   * indicates if currently selected column should be ascending or descending
   * @returns {boolean}
   */
  get isAscending() {
    return this._isAscending;
  }

  /**
   * indicates when to show carrot next to the name column in table header
   * @returns {boolean}
   */
  get showNameCarrot() {
    return this._orderBy === AssignmentsTableColumns.NAME;
  }

  /**
  * @return {boolean}
  */
  get showBanner() {
    return (this._storageService.lastSeenTrialConversionBanner && this._storageService.lastSeenTrialConversionBanner.showBanner === true)
        || (this._storageService.lastSeenRenewalConversionBanner && this._storageService.lastSeenRenewalConversionBanner.showBanner === true)
        || (this._storageService.lastSeenAssignmentNotificationBanner && this._storageService.lastSeenAssignmentNotificationBanner.showBanner === true);
  }

  /**
   * @return {Assignment[]}
  */
  get assignmentsToDisplay() {
    return this._assignmentsToDisplay;
  }

  /**
   * find public folders + public assignments that are not part of folders + public assignments that are part of ** non-public ** folders
   * @return {Assignment[]}
  */
  loadAssignmentsAndFoldersToDisplay() {
    this._assignmentsToDisplay = this._assignments.filter((assignment) => {
      const tags = assignment.tags || [];
      const parentFolder = tags.find((tag) => tag.name === AssignmentTags.FOLDER);

      if (parentFolder) {
        const { data } = parentFolder;
        if (!(AssignmentTags.PARENT in data) && AssignmentTags.IS_FOLDER in data) {
          return true;
        }

        if (AssignmentTags.PARENT in data) {
          const parentAssignment = this._assignments.find((a) => a.id === data.parent);
          return !parentAssignment;
        }
      }

      return true;
    });

    return this._assignmentsToDisplay;
  }

  /**
  * @return {boolean}
  */
  isFolder(assignment) {
    if (assignment.tags.length > 0) {
      if (AssignmentTags.IS_FOLDER in assignment.tags[0].data) {
        return true;
      }
    }
  }

  /**
   * handles the logic to change the focus and order of columns
   *
   * @param colName {string} one of the TableColumn properties
   * @param shouldAscend {boolean} should the column be ascending or descending
   */
  setOrToggle(colName, shouldAscend) {
    if (this._orderBy !== colName) {
      this._orderBy = colName;
      this._isAscending = shouldAscend;
    }
    else {
      this._isAscending = !this._isAscending;
    }
  }

  /**
   * sets the list order to the name column and/or toggles ascending/descending
   */
  setOrToggleName() {
    this.setOrToggle(AssignmentsTableColumns.NAME, true);
  }

  /**
   * @returns {Function} returns a function that know which column value to order by
   */
  orderBy() {
    let orderBy = this._orderBy;
    return function (assignment){
      if (AssignmentsTableColumns.NAME === orderBy) {
        return assignment.name;
      }
    };
  }

  goToAssignment(assignment) {
    this._analyticsService.profileViewFunnel(
      ProfileViewFunnel.CLICKED_ASSIGNMENT,
      this.$stateParams.userId,
      this._authService.currentUserId,
      assignment.name
    );

    if (this.isFolder(assignment)){
      const path = this._authService.isLoggedIn
      ? 'root.account.nav.public-folder'
      : 'root.public-folder';
      this._breadcrumbService.go(path, { folderId: assignment.id, usernameOrEmail: this.$stateParams.userId});
    } else {
      const path = this._authService.isLoggedIn
      ? 'root.account.nav.public-assignment'
      : 'root.public-assignment';
      this._breadcrumbService.go(path, { assignmentId: assignment.id});
    }
  }

  navToLogin() {
    if (this._authService.isLoggedIn && this._authService.authData.isTeacher) {
      this._breadcrumbService.go('root.account.home');
    }
    else {
      this._breadcrumbService.go('root.account-login');
    }
  }

  copy() {
    if (this._clipboard.supported) {
      this._clipboard.copyText(this._link);
      this.$mdToast.show(
        this.$mdToast.simple()
          .textContent('Link copied to clipboard')
          .position('bottom right')
      );
    }
  }

  goBack() {
    this._breadcrumbService.goBack('root.account.nav.assignments');
  }

  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }
}
