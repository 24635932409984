
'use strict';

import Codec from './codec';

export default class SimpleCodec extends Codec {
  /**
   * @param value {*}
   * @returns {*}
   */
  encode(value) {
    // Copy the value
    var result = angular.fromJson(angular.toJson(value));
    if (result) {
      // remove id, if any
      delete result.id;
    }
    return result;
  }

  /**
   * @param value {*}
   * @param key {string}
   * @returns {*}
   */
  decode(value, key) {
    var result = angular.fromJson(angular.toJson(value));
    if (angular.isObject(result)) {
      result.id = key;
    }
    return result;
  }
}
