export default class MdrTeacher {

    constructor(
      emailAddress,
      firstName,
      lastName
    ) {
      this._email = emailAddress;
      this._firstName = firstName;
      this._lastName = lastName;
    }

    /**
     * @return {string}
     */
    get email() {
      return this._email;
    }
  
    /**
     * @return {string}
     */
    get firstName() {
      return this._firstName;
    }
  
    /**
     * @return {string}
     */
    get lastName() {
      return this._lastNname;
    }
  }
  