'use strict';

import moment from 'moment';
import Notification from './notification';
import JSEvent from '../util/js-event';

/**
 * Notification for accounts
 */
export default class AccountNotification extends Notification {
  /**
   * @param notificationShardKay {NotificationShardKey}
   * @param userId {string}
   * @param FirebaseService {FirebaseService}
   */
  constructor(notificationShardKey, userId, FirebaseService) {
    super(
      Notification.V3,
      notificationShardKey,
      `users/${userId}/account`,
      FirebaseService,
      true,
      'AccountNotification'
    );

    this._accountInitialized = new JSEvent(this);

    this._logTrace('cstr complete');
  }

  static get ACCOUNT_INITIALIZED() {
    return 'accountInitialized';
  }

  /**
   * @returns {JSEvent}
   */
  get accountInitialized() {
    return this._accountInitialized;
  }

  /**
   * @param value {{user_id: string, change: string, t: string}}
   * @private
   */
  _changed(value) {
    if (value && value.change === AccountNotification.ACCOUNT_INITIALIZED) {
        this._logTrace('_changed');
        this._accountInitialized.raise(this._value);
    }
  }
}
