export default class GradebookStudent {

  /**
   * @param id { string }
   * @param name {string }
   * @param firstName { string }
   * @param lastName { string }
   * @param email { string }
   * @param isLinkActive { boolean }
   *
   */
  constructor(
    id,
    name,
    firstName,
    lastName,
    email,
    isLinkActive
  ) {
    this._id = id;
    this._name = name;
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._isLinkActive = isLinkActive;
  }

  get id (){
    return this._id;
  }

  get name (){
    return this._name;
  }

  get firstName (){
    return this._firstName;
  }

  get lastName (){
    return this._lastName;
  }

  get displayName() {
    return this._email ? this._email : this.name;
  }

  get email (){
    return this._email;
  }

  get isLinkActive (){
    return this._isLinkActive;
  }
}
