'use strict';

import { AssignmentToolbarConfig } from '../../components/assignment-toolbar/assignment-toolbar.directive';
import FirebaseElementSavingIndicator from '../../components/saving-indicator/firebase-element-saving-indicator';
import { StatusActivities } from './user-status-editor';
import { AssignmentQuestionMetadata } from '../../components/assignment-question/assignment-question.directive';
import { ElementIntents } from './element-metadata';
import { UserRoles } from './user';

export default class StudentAssignmentFeedback {

  constructor($q, $timeout, $stateParams, AssignmentWorkService, CacheService, AssignmentTrackingService,
              BreadcrumbService, StorageService, StudentCacheService, ToolbarService, FeedbackService) {

    this.$q = $q;
    this.$timeout = $timeout;
    this.$stateParams = $stateParams;

    /** @type{AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type{CacheService} */
    this._cacheService = CacheService;
    /** @type{AssignmentTrackingService} */
    this._assignmentTrackingService = AssignmentTrackingService;
    /** @type{BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {FeedbackService} */
    this._feedbackService = FeedbackService;

    this._firstFeedback = true;
    this._assignmentQuestionConfig = undefined;
    this._savingIndicator = new FirebaseElementSavingIndicator(this.$timeout, this._assignmentTrackingService);

    this._toolbarOptions = new AssignmentToolbarConfig();

    this._request = this.$stateParams.request || this._storageService.feedbackHelpRequest;
    this._storageService.feedbackHelpRequest = this._request;
    this._error = this._request ? null : new Error(this.CANNOT_ACCESS_HELP_REQUEST_ERROR);

    this._assignmentTrackingService.previewModified.subscribe(this._handleFeedback, this);
  }

  get CANNOT_ACCESS_HELP_REQUEST_ERROR() {
    return 'cannot_access_help_request';
  }

  get assignmentQuestionConfig() {
    return this._assignmentQuestionConfig;
  }

  get error() {
    return this._error;
  }

  get toolbarOptions() {
    return this._toolbarOptions;
  }

  get saveState() {
    return this._savingIndicator && this._savingIndicator.saveState;
  }

  /**
   * @return {string}
   */
  get title() {
    if (!this._helpeeAssignmentWork) {
      return 'Loading...';
    }
    let anonAnimalName = this._request.anonAnimalNameForHelpee;
    let questionNumber = this.data.assignmentWork.questionNumberForId(this._request.questionId);
    return `You are helping ${anonAnimalName.toLowerCase()} on question ${questionNumber}`;
  }

  /**
   * @return {StudentSessionData}
   */
  get data() {
    return this._helperSessionData;
  }

  /**
   * @param assignmentWork {AssignmentWork}
   * @return {Promise<StudentSessionData>}
   */
  init(assignmentWork) {

    this.assignmentWorkId = assignmentWork.id;

    return this._studentCacheService
      .getSessionData(
        assignmentWork,
        this._cacheService
      )
      .then((data) => {
        this._helperSessionData = data;

        return this.$q.all({
          helpeeAssignmentWork: this._assignmentWorkService.getForPeer(
            this.data.assignment,
            this.data.roster.id,
            this._request.helpeeId
          ),
          helper: this._cacheService.getUser()
        });
      })
      .then((result) => {
        this._helper = result.helper;
        this._helpeeAssignmentWork = result.helpeeAssignmentWork;

        this._configureAssignmentQuestionConfig();

        this._cacheService.startHelping(
          assignmentWork.assignment,
          this.data.userId,
          this._helper.name,
          UserRoles.STUDENT,
          this._request.helpeeId,
          this.data.assignment.id,
          this._request.questionId
        );

        // set question_id in status firebase location
        this._cacheService.setUserStatus(
          this.data.assignment.id,
          this._request.questionId,
          StatusActivities.HELPING,
          this._helpeeAssignmentWork.ownerId
        );

        this.data.classCode.lockStudentWorkUpdated.subscribe(this._handleLockStudentWork, this);
      })
      .catch((error) => {
        this._error = error;
      });
  }

  _configureAssignmentQuestionConfig() {
    this._assignmentQuestionConfig = new AssignmentQuestionMetadata(
      this._helpeeAssignmentWork,
      this._request.questionId,
      this.lockStudentWork,
      false,
      this.data.userId,
      UserRoles.STUDENT,
      ElementIntents.FEEDBACK,
      undefined,
      this.data.helpRequestSet
    );
  }

  /**
   * @return {boolean}
   */
  get lockStudentWork() {
    if (this.data && this.data.classCode && angular.isDefined(this.data.classCode.lockStudentWork)) {
      return this.data.classCode.lockStudentWork;
    }
    return false;
  }

  _handleLockStudentWork() {
    this._configureAssignmentQuestionConfig();
  }

  /**
   * @param event {{type: string, element: Element}}
   * @private
   */
  _handleFeedback(event) {
    if (this._isStudentFeedback(event.element)) {
      if (this._firstFeedback) {
        this._firstFeedback = false;

        if (this._toolbarService.helpCenter.helpRequestManager.currentHelpRequest) {
          this._toolbarService.helpCenter.lowerHelpRequest();
        }
      }

      this._feedbackService.give(
        this._request.helpeeId,
        this._helpeeAssignmentWork,
        this._request.questionId,
        this._helper.id,
        this._helper.name
      );
    }
  }

  /**
   * @param element {Element}
   * @returns {boolean}
   * */
  _isStudentFeedback(element) {
    return element.metadata &&
      element.metadata.intent === ElementIntents.FEEDBACK &&
      element.metadata.role   === UserRoles.STUDENT;
  }

  destroy() {
    if (this._assignmentTrackingService) {
      this._assignmentTrackingService.previewModified.unsubscribe(this._handleFeedback, this);
    }

    if (this.data && this.data.classCode) {
      this.data.classCode.lockStudentWorkUpdated.unsubscribe(this._handleLockStudentWork, this);
    }

    this._cacheService.stopHelping();
  }

}
