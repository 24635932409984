'use strict';
import ErrorCodes from '../../model/domain/error-codes';

class PasswordResetStatus {
  static get Success() {
    return 'password_reset_success';
  }

  static get Start() {
    return 'password_reset_start';
  }

  static get Loading() {
    return 'password_reset_loading';
  }
}

export default class ForgotPasswordController {

  constructor($scope, BreadcrumbService, AuthService) {
    'ngInject';

    this.$scope = $scope;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AuthService} */
    this._authService = AuthService;

    this._email = '';
    this._status = PasswordResetStatus.Start;
    this._emailPattern = '.+@.+\\..+';
    this._errorMessage = '';
  }

  /**
   * @return {string}
   */
  get email() {
    return this._email;
  }

  /**
   * @param value {string}
   */
  set email(value) {
    this._email = value;
  }

  /**
   * @return {string}
   */
  get status() {
    return this._status;
  }

  /**
   * @param value {string}
   */
  set status(value) {
    this._status = value;
  }

  /**
   * @return {string}
   */
  get emailPattern() {
    return this._emailPattern;
  }

  /**
   * @return {boolean}
   */
  get isPasswordResetStart() {
    return this.status === PasswordResetStatus.Start;
  }

  /**
   * @return {boolean}
   */
  get isPasswordResetSuccess() {
    return this.status === PasswordResetStatus.Success;
  }

  /**
   * @return {boolean}
   */
  get isLoading() {
    return this.status === PasswordResetStatus.Loading;
  }

  /**
   * @return {string}
   */
  get errorMessage() {
    return this._errorMessage;
  }

  goBack() {
    this._breadcrumbService.goBack('root.account-login');
  }

  resetPassword() {
    this.status = PasswordResetStatus.Loading;

    this._authService.requestPasswordReset(this.email)
      .then(() => {
        this.status = PasswordResetStatus.Success;
      })
      .catch((error) => {
        this._handleError(error);
      });
  }

  _handleError(error) {
    let data = error.data;

    if (error.code === ErrorCodes.PASSWORD_NOT_CONFIGURED) {
      this._errorMessage = 'Please try logging in with Google. No password is set up for this account.';
    }
    else if (data && data.status === 404) {
      this._errorMessage = 'Whoops! The email you provided was not found';
    }
    else if (data && data.status === 429) {
      this._errorMessage = 'Whoops! Too many reset requests';
    }

    this.$scope.resetPasswordForm.emailInput.$setValidity('error', false);
    this.status = PasswordResetStatus.Start;
  }
}
