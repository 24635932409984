
'use strict';

import { Notifications } from '../../services/toolbar/toolbar.service';
import { Colors } from '../../model/domain/colors';
import { Emojis } from '../../model/domain/emojis';
import { FontFamily } from '../../model/domain/font-family';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import ImagePicker from '../../components/image-picker-dialog/image-picker-dialog.controller';
import UserSticker from '../../model/domain/user-sticker';
import { MoreSection, Section, SelectSection } from './toolbar-section';
import ToolbarModel from './toolbar-model';
import { FlexSpace, IconButton, PointsField, RenderedButton, TextButton } from './toolbar-item';
import StaticService from '../../services/static/static.service';
import { ToolbarModes } from '../../services/toolbar/toolbar.service';
import ColorCodec from '../../model/codec/color-codec';
import Debouncer from '../../model/util/debouncer';
import {Locations, PaywallSources, StickerSources} from '../../services/mixpanel/mixpanel.service';
import Snackbar from '../../components/snackbar/snackbar.controller';
import ViewHelpDialogController, { ViewHelps } from '../view-help-dialog/view-help-dialog.controller';
import ToolbarPanelManager from './toolbar-panel-manager';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import SaveStates from '../../components/saving-indicator/save-states';
import AssignmentToolbarTemplate from './assignment-toolbar.html';
import {ABTest} from '../../services/ab-test/ab-test-service';
import TextToSpeech from '../../model/ui/elements/text-to-speech';
import SlideForeground from '../../model/ui/elements/slide-foreground';
import ContextualPaywallDialogController, { ContextualPaywalls } from '../../components/contextual-paywall-dialog/contextual-paywall-dialog.controller';

//MathFieldElement is not called directly but must be present in the directive for it to show up the UI
import { MathfieldElement } from 'mathlive';

export class FitbAnswerTypes {
  static get PLAIN () {
    return {
      value: 'plain',
      text: 'Plain Text'
    };
  }
  static get SCIENTIFIC () {
    return {
      value: 'scientific',
      text: 'STEM'
    };
  }
}

export class AssignmentToolbarConfig {
  constructor() {
    /** @type {boolean} */
    this.readonly = false;

    /** @type {boolean} */
    this.zoomIn = true;
    /** @type {boolean} */
    this.zoomOut = true;

    /** @type {boolean} */
    this.selectMode = true;
    /** @type {boolean} */
    this.panMode = StaticService.get && StaticService.get.isTouchDevice;
    /** @type {boolean} */
    this.penMode = true;
    /** @type {boolean} */
    this.highlightMode = true;
    /** @type {boolean} */
    this.eraseMode = true;

    /** @type {boolean} */
    this.openEmoji = true;
    /** @type {boolean} */
    this.addTextbox = true;
    /** @type {boolean} */
    this.addStraightLine = true;
    /** @type {boolean} */
    this.addAudio = true;
    /** @type {boolean} */
    this.addLink = true;
    /** @type {boolean} */
    this.addImage = true;
    /** @type {boolean} */
    this.addAiAssistant = false;
    /** @type {boolean} */
    this.addManipulativeImage = false;
    /** @type {boolean} */
    this.addFillInTheBlank = false;
    /** @type {boolean} */
    this.addSticker = false;
    /** @type {boolean} */
    this.editSticker = false;
    /** @type {boolean} */
    this.addMultipleChoice = false;
    /** @type {boolean} */
    this.editBackground = false;

    /** @type {boolean} */
    this.editPoints = false;
    /** @type {boolean} */
    this.editScore = false;
    /** @type {boolean} */
    this.viewScore = false;

    /** @type {boolean} */
    this.toggleFeedback = false;
    /** @type {boolean} */
    this.requestHelp = false;
    /** @type {boolean} */
    this.resolveHelp = false;
  }
}

export default function assignmentToolbarDirective() {
  'ngInject';

  return {
    restrict: 'E',
    scope: {
      options: '=',
      showGradeInput: '=',
      locked: '='
    },
    transclude: true,
    template: AssignmentToolbarTemplate,
    link: AssignmentToolbarController.link,
    controller: AssignmentToolbarController,
    controllerAs: 'ctrl'
  };
}

class AssignmentToolbarController {
  constructor($q, $document, $rootScope, $scope, $stateParams, $timeout, $mdPanel, $mdDialog, $mdMedia, $mdToast, $log, $window,
              CacheService, ToolbarService, AuthService, AssignmentTrackingService, AnalyticsService) {
    'ngInject';

    this.$q = $q;
    this.$document = $document;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$mdPanel = $mdPanel;
    this.$mdDialog = $mdDialog;
    this.$mdMedia = $mdMedia;
    this.$mdToast = $mdToast;
    this.$log = $log;
    this.$window = $window;
    this._window = angular.element($window);

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {kingServiceAssignmentTrac} */
    this._assignmentTrackingService = AssignmentTrackingService;

    /** @type {ColorCodec} */
    this._colorCodec = new ColorCodec();

    this._showConfirmMenu = ConfirmDialogController.show;
    this._showImagePicker = ImagePicker.show;
    this._helpDialog = ViewHelpDialogController.show;
    this._contextualPaywallDialog = ContextualPaywallDialogController.show;

    this._showGradeInput = false;
    this._locked = false;

    /** @type {Blob} */
    this.capturedAudioBlob = null;


    /** @type {Object} */
    this.capturedAiAssistantBlob = {};

    this._toolbarMeasurer = new Debouncer(50, 200, () => this._updateAndMeasureToolbarButtons(), this.$scope);

    this._snackbar = new Snackbar(this.$mdToast);
    this._panel = new ToolbarPanelManager(this.$mdPanel, this._toolbarService);
    this._displayingAssignmentLockMessage = false;
    this._newMCSegment = false;
    this._teacherAssistantSegment = false;
    this._pro = undefined;
    this._hasCoverSlide = null;

    this.saveState = '';
    this.fitbAnswerOptions = [FitbAnswerTypes.PLAIN, FitbAnswerTypes.SCIENTIFIC];
    this.fitbFormat = FitbAnswerTypes.PLAIN.value;

    this._init();

    //Waits for FITB side nav to finish loading to pre-populate math-field with saved answer from server
    $scope.$watch('ctrl.isFillInTheBlankModeVisible', () => {
      $timeout(() => {
        const content = this.$document[0].getElementsByClassName('sidenav-header')[0];
        if (content) {
          this._addFitbAnswersToMathFields();
        }
      });
    });

    //Creates an observer to watch the DOM for any element changes. If there is a
    //new math-field added, it will attach a input listener to the math-field element
    this.observer = new MutationObserver((mutations, obs) => {
      this._addMathFieldsEventListeners();
    });

    this.observer.observe(this.$document[0], {
      childList: true,
      subtree: true
    });
  }

  /**
   * the link function is run after the constructor for the controller
   * @param scope {$scope}
   * @param element {object}
   * @param attrs {object}
   * @param ctrl {AssignmentToolbarController}
   */
  static link(scope, element, attrs, ctrl) {

    scope.$watch('options', (value) => {
      if (!value) {
        return;
      }

      ctrl.options = value;
    });

    scope.$watch('showGradeInput', (value) => {
      if (value === true || value === false) {
        ctrl._showGradeInput = value;
        ctrl.updateToolbarButtons(true);
      }
    });

    scope.$watch('locked', (value) => {
      if ((value === false && ctrl.locked === true) || (value === true && ctrl.locked === false)) {
        ctrl.locked = value;
      }
    });
  }

  _init() {
    this.$scope.$on('$destroy', () => this._destroy());
    this._toolbarService.extendedStateUpdated.subscribe(this._onToolbarServiceStateUpdated, this);
    this.$rootScope.$on('save-answer', () => this.saveAnswerNotification());

    this._anonOnResize = () => { this.onWindowResized(); };
    this._window.on('resize', this._anonOnResize);

    this._toolbarService.clipboardManager.init(this.$scope);
    this._toolbarService.stickerManager.init();
    this._toolbarService.fillInTheBlankManager.init();
    this._toolbarService.multipleChoiceManager.init();
    this._textToSpeech = TextToSpeech.instance;

    this._user = undefined;

    this.$q.all({
      user: this._cacheService.getUser(),
      mcSegment: this._cacheService.getTestSegment(ABTest.MultipleChoice),
      teacherAssistantSegment: this._cacheService.getTestSegment(ABTest.TeacherAssignmentAIAssistant),
      proInfo: this._cacheService.getProInfo(false),
    }).then(({user, mcSegment, teacherAssistantSegment, proInfo}) => {
      this._user = user;
      this._newMCSegment = mcSegment;
      this._teacherAssistantSegment = teacherAssistantSegment;
      this._pro = proInfo;
      this._initToolbarModel(true);
    });
  }

  /**
   * @private
   */
  _destroy() {
    this._toolbarService.extendedStateUpdated.unsubscribe(this._onToolbarServiceStateUpdated, this);

    if (this._anonOnResize) {
      this._window.off('resize', this._anonOnResize);
    }

    if (this._displayingAssignmentLockMessage) {
      this._snackbar.dismiss();
    }

    this._removeMathFieldsEventListeners();
    this.observer.disconnect();
  }

  /**
   * @param value {AssignmentToolbarConfig}
   */
  set options(value) {
    this._options = value;

    this._initToolbarModel(true);

    // If the new use of the toolbar doesn't permit a feature and the feature is open, close it
    if (this.shouldResetSidenav) {
      this._toolbarService.sidenavManager.reset();
    }

    if (this.options.addSticker || this.options.editSticker) {
      this._toolbarService.stickerManager.init();
    }

    if (this.options.editBackground) {
      this._toolbarService.slideBackgroundManager.init();
    }

    if (this.options.toggleFeedback) {
      this._toolbarService.feedback.reset();
    }

    if (this.options.addFillInTheBlank) {
      this._toolbarService.fillInTheBlankManager.init();
    }
  }

  /**
   * @return {boolean}
   */
  get shouldResetSidenav() {
    return (!this.options.editSticker && !this.options.addSticker && this.sidenav.isStickerModeOpen) ||
      (!this.options.addAudio && this.sidenav.isAudioModeOpen) ||
      (!this.options.addAiAssistant && this.sidenav.isAiAssistantOpen) ||
      (!this.options.addMultipleChoice && this.sidenav.isMultipleChoiceModeOpen) ||
      (!this.options.requestHelp && !this.options.resolveHelp && this.sidenav.isHelpCenterModeOpen) ||
      (!this.options.editBackground && this.sidenav.isSlideBackgroundModeOpen);
  }

  /**
   * @returns {AssignmentToolbarConfig}
   */
  get options() {
    return this._options;
  }

  /**
   * @returns {ToolbarModel}
   */
  get toolbarModel() {
    return this._toolbarModel;
  }

  get state() {
    return this._toolbarService.state;
  }

  get focusedElement() {
    return this._toolbarService.focusedElement;
  }

  /**
   * @param value {boolean}
   */
  set hasCoverSlide(value) {
   this._hasCoverSlide = value;
  }

  /**
   * @returns {boolean}
   */
  get hasCoverSlide() {
    return this._hasCoverSlide;
  }

  /**
   * @return {boolean}
   */
  get panel() {
    return this._panel;
  }

  get isFreeUser(){
    return this._authService.authData.isFree;
  }
  //----------------------- Event Handlers -------------------------------

  undo(ev) {
    ev.originalEvent.preventElementBlur = true;
    this._toolbarService.undo();
  }

  redo(ev) {
    ev.originalEvent.preventElementBlur = true;
    this._toolbarService.redo();
  }

  toggleTextToSpeech(ev) {
    ev.originalEvent.preventElementBlur = true;
    this._toolbarService.toggleTextToSpeech();
  }

  toggleCoverSlide(ev) {
    ev.originalEvent.preventElementBlur = true;
    this._toolbarService.toggleCoverSlide();
    if (this.hasCoverSlide) {
      this.hasCoverSlide = false;
      this._analyticsService.coverSlideActivity(Locations.ASSIGNMENT_WORK_QUESTION, this.$stateParams.questionId, 'removed');
      this._toolbarModel.buttonMap.coverSlide = new IconButton('ck-opened-eye', null, 'Add Cover Slide', (ev) => this.toggleCoverSlide(ev));
      this._toolbarModel.sections[4]._displayItems[15] = this._toolbarModel.buttonMap.coverSlide;
      this._toolbarModel.sections[4]._items[14] = this._toolbarModel.buttonMap.coverSlide;
    } else {
      this.hasCoverSlide = true;
      this._analyticsService.coverSlideActivity(Locations.ASSIGNMENT_WORK_QUESTION, this.$stateParams.questionId, 'added');
      this._toolbarModel.buttonMap.coverSlide = new IconButton('ck-closed-eye', null, 'Remove Cover Slide', (ev) => this.toggleCoverSlide(ev));
      this._toolbarModel.sections[4]._displayItems[15] = this._toolbarModel.buttonMap.coverSlide;
      this._toolbarModel.sections[4]._items[14] = this._toolbarModel.buttonMap.coverSlide;
    }
  }

  mouseEnterDelete() {
    this._toolbarService.notify(Notifications.MOUSE_ENTER_DELETE, {needsFocusedElement: true}, false);
  }

  mouseLeaveDelete() {
    this._toolbarService.notify(Notifications.MOUSE_LEAVE_DELETE, {needsFocusedElement: true}, false);
  }

  deleteElement() {
    this._toolbarService.notify(Notifications.DELETE, {needsFocusedElement: true});
    this.updateToolbarButtons();
  }

  zoomIn(ev) {
    ev.originalEvent.preventElementBlur = true;
    this._toolbarService.zoomIn();
  }

  zoomOut(ev) {
    ev.originalEvent.preventElementBlur = true;
    this._toolbarService.zoomOut();
  }

  decrementFont(ev) {
    ev.originalEvent.preventElementBlur = true;
    this._toolbarService.updateTextSize(false);
  }

  incrementFont(ev) {
    ev.originalEvent.preventElementBlur = true;
    this._toolbarService.updateTextSize(true);
  }

  copyPasteElementAction(ev) {
    this._analyticsService.sendEvent({
      eventTag:'asmt:duplicateElement_clicked'
    });
    ev.originalEvent.preventElementBlur = true;
    this._toolbarService.copyPasteElement();
  }

  /**
   * @param color {{hex: string, name: string}}
   */
  updateTextboxColor(color) {
    this._toolbarService.updateTextColor(color);
    this.panel.closeToolPanel();
    this.panel.closeMorePanel();
  }

  /**
   * @param color {{hex: string, name: string}}
   */
  updateTextboxBackgroundColor(color) {
    this._toolbarService.updateTextboxBackgroundColor(color);
    this.panel.closeToolPanel();
    this.panel.closeMorePanel();
  }

  /**
   * @param color {{hex: string, name: string}}
   */
  updateTextboxBorderColor(color) {
    this._toolbarService.updateTextboxBorderColor(color);
    this.panel.closeToolPanel();
    this.panel.closeMorePanel();
  }


  /**
   * @param font_selected {{name: string, value: string}}
   */

  updateFontFamily(font_selected) {
    this._toolbarService.updateFontFamily(font_selected);
    this.panel.closeToolPanel();
    this.panel.closeMorePanel();
  }


  /**
   * @param color {{hex: string, name: string}}
   */
  updateStraightLineColor(color) {
    this._toolbarService.updateStraightLineColor(color);
    this.panel.closeToolPanel();
    this.panel.closeMorePanel();
  }

  /**
   * @param width {number}
   */
  updateStraightLineWidth(width) {
    this._toolbarService.updateStraightLineWidth(width);
  }

  /**
   * @param color {{hex: string, name: string}}
   */
  updatePenColor(color) {
    this._toolbarService.updateColor(color);
    this.panel.closeToolPanel();
    this.panel.closeMorePanel();
  }

  /**
   * @param width {number}
   */
  updatePenWidth(width) {
    this._toolbarService.updatePenWidth(width);
  }

  /**
   * @param color {{hex: string, name: string}}
   */
  updateHighlighterColor(color) {
    const hColor = {
      name: color.name,
      hex: this._colorCodec.hex6ToRgba(color.hex),
      active: color.active
    };

    this._toolbarService.updateHighlighterColor(hColor);
    this.panel.closeToolPanel();
    this.panel.closeMorePanel();
  }

  updateHighlighterWidth(w) {
    this._toolbarService.updateHighlighterWidth(w);
  }

  createTextbox() {
    this._checkIfHelpViewed(ViewHelps.PlaceTextbox, () => {
      this._toolbarService.notify(Notifications.CREATE_TEXTBOX, {needsFocusedElement: true}, false);
      this.panel.closeMorePanel();
    });
  }


  openEmoji(ev) {
    this.panel.closeMorePanel();

    this.panel.openToolPanel(ev, this.panel.EmojiSelection, this, {
      position: this.$mdPanel.newPanelPosition()
        .relativeTo(ev.currentTarget)
        .addPanelPosition(this.$mdPanel.xPosition.ALIGN_START, this.$mdPanel.yPosition.BELOW)
    });

  }

  createEmoji(emoji_selected) {
    this._toolbarService.createEmoji(emoji_selected);
    this.panel.closeToolPanel();
    this.panel.closeMorePanel();
  }


  createStraightLine() {
    this._checkIfHelpViewed(ViewHelps.PlaceSline, () => {
      this._toolbarService.notify(Notifications.CREATE_STRAIGHT_LINE, {needsFocusedElement: true});
      this.panel.closeMorePanel();
    });
  }

  createLink() {
    if (ToolbarModes.isPlacementMode(this.currentMode)){
      this._toolbarService.clearPlacement();
    }

    this._toolbarService.notify(Notifications.CREATE_LINK, {needsFocusedElement: false});
    this.panel.closeMorePanel();
  }

  openImageMenu(ev) {
    ev.target.blur();
    this.panel.closeMorePanel();

    this.panel.openToolPanel(ev, this.panel.ImageMenu, this, {
      position: this.$mdPanel.newPanelPosition()
        .relativeTo(ev.currentTarget)
        .addPanelPosition(this.$mdPanel.xPosition.ALIGN_START, this.$mdPanel.yPosition.BELOW)
    });
  }

  createImageFromCamera(ev) {
    if (ToolbarModes.isPlacementMode(this.currentMode)){
      this._toolbarService.clearPlacement();
    }

    ev.target.blur();
    this.panel.closeMorePanel();
    this.panel.closeToolPanel();

    this._toolbarService.notify(Notifications.CREATE_IMAGE_FROM_CAMERA, {needsFocusedElement: false});
  }

  createImageFromFile(ev) {
    if (ToolbarModes.isPlacementMode(this.currentMode)){
      this._toolbarService.clearPlacement();
    }

    ev.target.blur();
    this.panel.closeMorePanel();
    this.panel.closeToolPanel();

    this._toolbarService.notify(Notifications.CREATE_IMAGE_FROM_FILE, {needsFocusedElement: false});
  }

  toggleAudio() {
    this._toolbarService.notify(Notifications.SHOW_ALL_CONTRIBUTORS);
    if (ToolbarModes.isPlacementMode(this.currentMode)){
      this._toolbarService.clearPlacement();
      this._toolbarService.updateMode(ToolbarModes.Selector);
    }

    if (this.isAudioModeVisible) {
      this.sidenav.closeState();
    }
    else {
      this.sidenav.openRecordAudio();
    }
    this.panel.closeMorePanel();
  }


  toggleAiAssistant() {
    this._toolbarService.notify(Notifications.SHOW_ALL_CONTRIBUTORS);
    if (ToolbarModes.isPlacementMode(this.currentMode)){
      this._toolbarService.clearPlacement();
      this._toolbarService.updateMode(ToolbarModes.Selector);
    }

    if (this.isAiAssistantModeVisible) {
      this.sidenav.closeState();
    }
    else {
      this.sidenav.openAiAssistant();
    }
    this.panel.closeMorePanel();
  }

  createManipulativeImage(ev) {
    if (ToolbarModes.isPlacementMode(this.currentMode)){
      this._toolbarService.clearPlacement();
    }

    ev.target.blur();
    this._toolbarService.notify(Notifications.CREATE_MANIPULATIVE_IMAGE, {needsFocusedElement: false});
    this.panel.closeMorePanel();
  }

  createFillInTheBlank(ev) {
    this.sidenav.closeState();
    this._checkIfHelpViewed(ViewHelps.PlaceFitb, () => {
      ev.target.blur();
      this._toolbarService.notify(Notifications.CREATE_FILL_IN_THE_BLANK, {needsFocusedElement: true});
      this.panel.closeMorePanel();
    });
  }

  toggleStickers() {
    this._toolbarService.notify(Notifications.SHOW_ALL_CONTRIBUTORS);
    if (ToolbarModes.isPlacementMode(this.currentMode)){
      this._toolbarService.clearPlacement();
      this._toolbarService.updateMode(ToolbarModes.Selector);
    }

    if (this.isStickerModeVisible) {
      while (this.isStickerModeVisible) {
        this.sidenav.closeState();
      }
    }
    else {
      this.goToPlaceStickers();
    }
    this.panel.closeMorePanel();
  }

  toggleMultipleChoice() {
    if (ToolbarModes.isPlacementMode(this.currentMode)){
      this._toolbarService.clearPlacement();
      this._toolbarService.updateMode(ToolbarModes.Selector);
    }

    if (this.isMultipleChoiceModeVisible) {
      while (this.isMultipleChoiceModeVisible) {
        this.sidenav.closeState();
      }
    }
    else {
      this.goToEditMultipleChoice();
      this._analyticsService.sendEvent({
        eventTag: 'asmt:mc_opened'
      });
    }
    this.panel.closeMorePanel();
  }

  toggleSlideBackground() {
    if (this.isSlideBackgroundModeVisible) {
      while (this.isSlideBackgroundModeVisible) {
        this.sidenav.closeState();
      }
    }
    else {
      this.goToSlideBackground();
    }
    this.panel.closeMorePanel();
  }

  clearAll() {
    this._toolbarService.clearAll();
  }

  addAudio() {
    this.sidenav.closeState();
    this._toolbarService.notify(Notifications.CREATE_AUDIO, this.capturedAudioBlob, {needsFocusedElement: false});
    this.capturedAudioBlob = null;
  }

  addAiAssistant() {
    this._toolbarService.notify(Notifications.CREATE_AI_ASSISTANT, this.capturedAiAssistantBlob, {needsFocusedElement: false});
    this.capturedAiAssistantBlob = {};
  }

  toggleFeedback() {
    this._toolbarService.feedback.toggled();
  }

  toggleContributors() {
    this._toolbarService.notify(Notifications.SHOW_ALL_CONTRIBUTORS);
    if (this.isContributorsTabModeVisible) {
      while (this.isContributorsTabModeVisible) {
        this.sidenav.closeState();
      }
    }
    else {
      // close any open tabs first
      this.sidenav.closeState();
      this.goToContributorsTab();
      this._analyticsService.sendEvent({
        eventTag: 'asmt:ch_opened'
      });
    }
    this.panel.closeMorePanel();
  }

  toggleHelpRequest() {
    this._toolbarService.notify(Notifications.SHOW_ALL_CONTRIBUTORS);
    if (this.isHelpCenterModeVisible) {
      while (this.isHelpCenterModeVisible) {
        this.sidenav.closeState();
      }
    }
    else {
      // close any open tabs first
      this.sidenav.closeState();
      this.goToHelpCenter();
    }
    this.panel.closeMorePanel();
  }

  updateMode(modeName) {
    if (ToolbarModes.isPlacementMode(this.currentMode)){
      this._toolbarService.clearPlacement();
    }

    this._toolbarService.updateMode(modeName);
    window.mathVirtualKeyboard.hide();
  }

  /**
   * @param helpId {string} place_textbox|place_fitb|place_sline|place_sticker
   * @param callback {Function}
   */
  _checkIfHelpViewed(helpId, callback) {
    let promise = this.$q.resolve();

    if (this._user && !this._user.getHelpDialogViewed(helpId) && !this._authService.authData.isAnon) {
      promise = this._helpDialog(this.$mdDialog, helpId).catch(() => {
        this._user.setHelpDialogViewed(helpId, true);
        this._cacheService.updateUser(this._user);
      });
    }

    promise.finally(callback);
  }

  //---------------------------------- Toolbar Styling ----------------------------------------------

  /**
   * @returns {{width: number}[]}
   */
  get penWidths() {
    return this._toolbarService.penWidths;
  }

  /**
   * @returns {{width: number}[]}
   */
  get highlighterWidths() {
    return this._toolbarService.highlighterWidths;
  }

  /**
   * @returns {{width: number}[]}
   */
  get straightLineWidths() {
    return this._toolbarService.straightLineWidths;
  }

  get straightLineColor() {
    return this._toolbarService.state.straightLines.color.hex;
  }

  get straightLineWidth() {
    return this._toolbarService.state.straightLines.width;
  }

  get penColor() {
    return this._toolbarService.state.lines.color.hex;
  }

  get penWidth() {
    return this._toolbarService.state.lines.width;
  }

  get highlighterColor() {
    return this._colorCodec.rgbaOverrideAlpha(this._toolbarService.state.highlighter.color.hex, 1);
  }

  get highlighterWidth() {
    return this._toolbarService.state.highlighter.width;
  }

  get textColor() {
    return this._toolbarService.state.text.color;
  }

  get textboxBackgroundColor() {
    return this._toolbarService.state.text.backgroundColor;
  }

  get textboxBorderColor() {
    return this._toolbarService.state.text.borderColor;
  }

  get textboxFontFamily() {
    return this._toolbarService.state.text.fontFamily;
  }

  get isTeacher() {
    return this._authService.authData.isTeacher;
  }

  get isStudent() {
    return this._authService.authData.isStudent;
  }

  /**
   * @returns {{hex: string, name: string}[]}
   */
  get elementColors() {
    return Colors.ELEMENT_COLORS;
  }

  get elementEmojis() {
    return Emojis.ELEMENT_EMOJIS;
  }

  get elementFontFamily() {
    return FontFamily.ELEMENT_FONTFAMILY;
  }

  //------------------------------------ Feedback States ------------------------------------------------

  /**
   * @returns {boolean}
   */
  get isFeedbackVisible() {
    return this._toolbarService.isFeedbackVisible;
  }

  /**
   * @returns {boolean}
   */
  get isFeedbackInvisible() {
    return this._toolbarService.isFeedbackInvisible;
  }

  /**
   * @returns {boolean}
   */
  get isFeedbackDisabled() {
    return this._toolbarService.isFeedbackDisabled;
  }

  /**
   * @returns {boolean}
   */
  get isFeedbackNotification() {
    return this._toolbarService.isFeedbackNotification;
  }

  /**
   * @returns {string}
   */
  get toggleFeedbackTooltipMessage() {
    if (this.isFeedbackDisabled) {
      return 'You have no feedback on this question';
    }
    return 'Toggle feedback on and off';
  }

  //------------------------------------ Grading Methods ------------------------------------------------

  /**
   * Returns the appropriate class for the grade input
   * @returns {string}
   */
  get gradeInputClass() {
    if (this.options.editPoints) {
      return 'value';
    }
    else if (this.options.viewScore || this.options.editScore) {
      return 'score';
    }
    else {
      return 'hide';
    }
  }

  /**
   * @returns {boolean}
   */
  get isGradeInputReadonly() {
    return this.options.viewScore;
  }

  /**
   * @returns {number}
   */
  get currentPoints() {
    if (this.options.editPoints) {
      return this.pointValue;
    }
    else {
      return this.scoredPoints;
    }
  }

  /**
   * @param value {number}
   */
  set currentPoints(value) {
    if (this.options.editPoints) {
      this.pointValue = value;
    }
    else {
      this.scoredPoints = value;
    }
  }

  /**
   * @returns {boolean|number}
   */
  get potentialScore() {
    return !this.options.editPoints && this.pointValue;
  }

  /**
   * @returns {AssignmentQuestion}
   */
  get contentQuestion() {
    return this._toolbarService.contentQuestion;
  }

  /**
   * @returns {AssignmentWorkQuestion}
   */
  get workQuestion() {
    return this._toolbarService.workQuestion;
  }

  /**
   * @returns {number}
   */
  get pointValue() {
    return this.contentQuestion && this.contentQuestion.points;
  }

  /**
   * @param value {number}
   */
  set pointValue(value) {
    this.contentQuestion.points = value;
    this.resolveHelpRequest();
    this.updateToolbarButtons(true);
    this._toolbarService.notify(Notifications.UPDATE_POINT_VALUE, {needsFocusedElement: false}, false);
  }

  /**
   * @returns {number}
   */
  get scoredPoints() {
    return this.workQuestion && this.workQuestion.points;
  }

  get hasScoredPoints() {
    return angular.isNumber(this.scoredPoints) && !!this.scoredPoints;
  }

  /**
   * @param value {number}
   */
  set scoredPoints(value) {
    this.updateToolbarButtons(true);
    this._toolbarService.notify(Notifications.UPDATE_SCORED_POINTS, {
      needsFocusedElement: false,
      newScore: value
    }, false);
  }

  get showGradeInput() {
    return this.options && ((this.options.viewScore && this._showGradeInput) || this.options.editScore || this.options.editPoints) ;
  }

  //------------------------------------ Sidenav methods ------------------------------------------------

  /**
   * @returns {SidenavManager}
   * @private
   */
  get sidenav() {
    return this._toolbarService.sidenavManager;
  }

  cancelSidenav(ev, shouldPreventElementBlur, isMultipleChoice = false, isFitb = false) {
    if (isMultipleChoice) {
       this._analyticsService.sendEvent({
         eventTag:'asmt:mc_closed'
       });
    }

    if (isFitb){
      this._analyticsService.closeFitb(this.fitbFormat);
    }

    if (ev) {
      ev.originalEvent.preventElementBlur = !!shouldPreventElementBlur;
    }
    this.sidenav.closeState();
  }

  get isAudioModeVisible() {
    return this.sidenav.isOpen && this.sidenav.canShowMode && this.sidenav.mode.isAudioMode;
  }

  get isAiAssistantModeVisible() {
    return this.sidenav.isOpen && this.sidenav.canShowMode && this.sidenav.mode.isAiAssistantMode;
  }

  get isStickerModeVisible() {
    return this.sidenav.isOpen && this.sidenav.canShowMode && this.sidenav.mode.isStickerMode;
  }

  get isFillInTheBlankModeVisible() {
    return this.sidenav.isOpen && this.sidenav.canShowMode && this.sidenav.mode.isFillInTheBlankMode;
  }

  get isMultipleChoiceModeVisible() {
    return this.sidenav.isOpen && this.sidenav.canShowMode && this.sidenav.mode.isMultipleChoiceMode;
  }

  get isSlideBackgroundModeVisible() {
    return this.sidenav.isOpen && this.sidenav.canShowMode && this.sidenav.mode.isSlideBackgroundMode;
  }

  get isHelpCenterModeVisible() {
    return this.sidenav.isOpen && this.sidenav.canShowMode && this.sidenav.mode.isHelpCenterMode;
  }

  get isContributorsTabModeVisible() {
    return this.sidenav.isOpen && this.sidenav.canShowMode && this.sidenav.mode.isContributorsTabMode;
  }

  get showPlaceStickers() {
    return this.sidenav.isModeVisible(this.sidenav.modes.PLACE_STICKERS);
  }

  get showEditSticker() {
    return this.sidenav.isModeVisible(this.sidenav.modes.EDIT_STICKER);
  }

  get showCaptureAudio() {
    return this.sidenav.isModeVisible(this.sidenav.modes.AUDIO_RECORD);
  }

  get showAiAssistant() {
    return this.sidenav.isModeVisible(this.sidenav.modes.AI_ASSISTANT_MODE);
  }

  get showEditFillInTheBlank() {
    return this.sidenav.isModeVisible(this.sidenav.modes.EDIT_FILL_IN_THE_BLANK);
  }

  get showEditMultipleChoice() {
    return this.sidenav.isModeVisible(this.sidenav.modes.EDIT_MULTIPLE_CHOICE);
  }

  get showEnhancedMultipleChoice() {
    return this._newMCSegment;
  }

  get showSlideBackground() {
    return this.sidenav.isModeVisible(this.sidenav.modes.SLIDE_BACKGROUND);
  }

  get showHelpCenter() {
    return this.sidenav.isModeVisible(this.sidenav.modes.HELP_CENTER);
  }

  get showContributorsTab() {
    return this.sidenav.isModeVisible(this.sidenav.modes.CONTRIBUTORS_TAB);
  }

  get showDelete() {
    return this.showEditSticker && this.selectedSticker && this.selectedSticker.id;
  }

  /**
   * @param sticker {UserSticker}
   */
  goToEditSticker(sticker) {
    if (this._toolbarService.state.stickers.selectedId) {
      this._toolbarService.clearPlacement();
    }
    this.sidenav.openEditSticker(sticker);
  }

  goToPlaceStickers() {
    this._analyticsService.stickerSidenavOpened(this.stickerSource);
    this.sidenav.openPlaceSticker();
  }

  createSticker() {
    this.goToEditSticker(UserSticker.DEFAULT_STICKER);
  }

  goToEditMultipleChoice(multipleChoice) {
    this.sidenav.openEditMultipleChoice(multipleChoice);
  }

  goToSlideBackground() {
    this.sidenav.openSlideBackground();
  }

  goToHelpCenter() {
    this.sidenav.openHelpCenter();
  }

  goToContributorsTab() {
    this.sidenav.openContributorsTab();
  }

  //---------------------------------- Sticker CRUD ------------------------------------------------------

  get stickerManager() {
    return this._toolbarService.stickerManager;
  }

  /**
   * @return {UserSticker[]}
   */
  get stickers() {
    return this.stickerManager && this.stickerManager.stickers;
  }

  get stickerSortOptions() {
    if (!this._stickerSortOptions) {
      this._stickerSortOptions = {
        // Drag and drop in Firefox causes the click event to fire after moving a sticker, so we're disabling sorting
        // functionality in Firefox browsers
        handle: (StaticService.get && StaticService.get.isFirefox) ? '> .does-not-exist' : '> .md-button .sticker-item-content',
        // Ensures the sticker list items can only be dragged up and down
        axis: 'y',
        // The mode for how to check if a dragging item should be moved https://api.jqueryui.com/sortable/#option-tolerance
        tolerance: 'pointer',
        start: (event, ui) => this.onStickerSortStart(ui),
        sort: (event, ui) => this.onStickerSort(ui),
        stop: (event, ui) => this.onStickerSortStop(ui)
      };
    }
    return this._stickerSortOptions;
  }

  onStickerSortStart(ui) {
    this._stickerListViewport = angular.element('.place-stickers md-content');
    this._stickerListViewportScrollLimit = this.fullStickerListHeight - this._stickerListViewport.height();
  }

  onStickerSort(ui) {
    let helper = ui.helper[0];
    let partialStickerListHeight = this.partialStickerListHeight;

    // If the helper is below the end of the list, move it back above
    if (helper.offsetTop > partialStickerListHeight) {
      ui.helper.css('top', partialStickerListHeight);
    }
    // If helper is above the "add sticker" button, move it below
    else if (helper.offsetTop < this.stickerListItemHeight) {
      ui.helper.css('top', this.stickerListItemHeight);
    }

    // If we scrolled too, move back to within limit
    if (this._stickerListViewport.scrollTop() > this._stickerListViewportScrollLimit) {
      this._stickerListViewport.scrollTop(this._stickerListViewportScrollLimit);
    }
  }

  onStickerSortStop(ui) {
    let stickerId =  ui.item[0] && ui.item[0].id;
    return this._toolbarService.stickerManager.save(stickerId);
  }

  get stickerListStyle() {
    let fullStickerListHeight = this.fullStickerListHeight;
    return {
      height: fullStickerListHeight,
      maxHeight: fullStickerListHeight
    };
  }

  /**
   *  Calculates the height of all the sticker list items AND the "create sticker" button
   * @return {number}
   */
  get fullStickerListHeight() {
    return this.partialStickerListHeight + this.stickerListItemHeight;
  }

  /**
   * Calculates the height of all the sticker list items
   * @return {number}
   */
  get partialStickerListHeight() {
    return this.stickers.length * this.stickerListItemHeight;
  }

  /**
   * This is the height of each individual sticker item and the "create sticker" button
   * @return {number}
   */
  get stickerListItemHeight() {
    return 48;
  }

  stickerClass(id) {
    return {
      'selected': this.state.stickers.selectedId === id
    };
  }

  /**
   * @return {UserSticker}
   */
  get selectedSticker() {
    return this.stickerManager && this.stickerManager.selected;
  }

  /**
   * @return {string}
   */
  get selectedStickerImageUrl() {
    return this.stickerManager && this.stickerManager.selectedImageUrl;
  }

  /**
   * @param value {string}
   */
  set selectedStickerImageUrl(value) {
    if (this.stickerManager) {
      this.stickerManager.selectedImageUrl = value;
    }
  }

  get defaultStickerImageUrl() {
    return UserSticker.BLANK_STICKER_URL;
  }

  /**
   * @return {string}
   */
  get selectImageText() {
    return this.selectedStickerImageUrl ? 'change image' : 'add image';
  }

  /**
   * @return {string}
   */
  get selectedStickerText() {
    return this.stickerManager && this.stickerManager.selectedText;
  }

  /**
   * @param value {string}
   */
  set selectedStickerText(value) {
    if (this.stickerManager) {
      this.stickerManager.selectedText = value;
    }
  }

  /**
   * @return {number}
   */
  get selectedStickerScore() {
    return this.stickerManager && this.stickerManager.selectedScore;
  }

  /**
   * @param value {number}
   */
  set selectedStickerScore(value) {
    if (this.stickerManager) {
      this.stickerManager.selectedScore = value;
    }
  }

  stickerScoreMinus() {
    if (this.selectedStickerScore === 0) {
      this.selectedStickerScore = null;
    }
    else if (this.selectedStickerScore >= 1) {
      this.selectedStickerScore -= 1;
    }
  }

  stickerScorePlus() {
    if (this.selectedStickerScore === null) {
      this.selectedStickerScore = 0;
    }
    else if (this.selectedStickerScore) {
      this.selectedStickerScore += 1;
    }
    else {
      this.selectedStickerScore = 1;
    }
  }

  saveSticker() {
    this._toolbarService.saveSticker().then((sticker) => {
      this._analyticsService.stickerSaved(this.stickerSource, sticker.imageType, sticker.imageName);
      this.$timeout(() => {
        let stickerItem = angular.element(`#${sticker.id}`);
        scrollIntoViewIfNeeded(stickerItem[0], false);
      }, 0, false);
    });
  }

  /**
   * Returns the sticker source depending on the toolbar's configuration
   * @return {string}
   */
  get stickerSource() {
    if (this.options.addSticker) {
      return StickerSources.FEEDBACK;
    }
    else if (this.options.editSticker) {
      return StickerSources.CONTENT;
    }
    else {
      this.$log.warn('Should not be able to call "_stickerSource" method unless either "addSticker" or "editSticker" option is enabled');
      return '';
    }
  }

  deleteSticker() {
    let deleteMessage = 'Are you sure you want to delete this sticker?';
    this._showConfirmMenu(this.$mdDialog, deleteMessage).then(() => {
      return this._toolbarService.deleteSelected(this.selectedSticker);
    });
  }

  /**
   * @param sticker {UserSticker}
   */
  addSticker(sticker) {
    if (this.options.addSticker) {
      this._checkIfHelpViewed(ViewHelps.PlaceSticker, () => {
        this._addSticker(sticker, false);
      });
    }
    else if (this.options.editSticker) {
      this._analyticsService.stickerPlaced(StickerSources.CONTENT, sticker.imageType, sticker.imageName);
      this._helpDialog(this.$mdDialog, ViewHelps.ContentStickers);
    }
    else {
      this.$log.warn('Should not be able to call "addSticker" method unless either "addSticker" or "editSticker" option is enabled');
    }
  }

  /**
   * @param sticker {UserSticker}
   * @param ev {jQuery.Event}
   */
  addStickerWithoutPlacement(sticker, ev) {
    if (this.options.addSticker) {
      // Prevents behavior where double click handler is called twice
      ev.stopPropagation();
      this._addSticker(sticker, true);
    }
  }

  /**
   * @param sticker {UserSticker}
   * @param skipPlacement {boolean}
   */
  _addSticker(sticker, skipPlacement) {
    this._analyticsService.stickerPlaced(StickerSources.FEEDBACK, sticker.imageType, sticker.imageName);

    let stickerImage = angular.element(`#${sticker.id} img`)[0];
    this._toolbarService.notify(Notifications.ADD_STICKER, { sticker, stickerImage, skipPlacement }, false);
  }

  openHelpInfoDialogForCustomStickers() {
    this._helpDialog(this.$mdDialog, ViewHelps.CustomStickers);
  }

  //---------------------------------- Help Center ------------------------------------------------------

  /**
   * @returns {HelpCenterManager}
   */
  get helpCenter() {
    return this._toolbarService.helpCenter;
  }

  resolveHelpRequest() {
    if (this.helpCenter.helpRequestManager.currentHelpRequest) {
      this.helpCenter.lowerHelpRequest();
    }
  }

  get helpRequestButtonClass() {
    let isHelpRequestHelp = this.helpCenter.helpRequestManager.isHelpRequestHelp;
    let isHelpRequestCheck = this.helpCenter.helpRequestManager.isHelpRequestCheck;
    let isToggled = this.showHelpCenter && !isHelpRequestHelp && !isHelpRequestCheck;
    return {
      'help': isHelpRequestHelp,
      'check': isHelpRequestCheck,
      'toggled': isToggled,
      'unread-messages': this.helpCenter.hasUnreadMessages && !isHelpRequestCheck && !isHelpRequestHelp && !isToggled
    };
  }

  //---------------------------------- Multiple Choice CRUD ------------------------------------------------------

  get multipleChoiceManager() {
    return this._toolbarService.multipleChoiceManager;
  }

  saveMultipleChoice() {
    this.sidenav.closeState();
    this._toolbarService.notify(Notifications.CREATE_MULTIPLE_CHOICE, this.multipleChoiceManager.options, { needsFocusedElement: false });

    if (this._newMCSegment) {
      this.multipleChoiceManager.options.length < 5
        ? this._analyticsService.sendEvent({
          eventTag: 'asmt:mc_savedLessThanFive'
        })
        :
        this._analyticsService.sendEvent({
          eventTag: 'asmt:mc_savedFive'
        });
    }

    this.multipleChoiceManager.resetOptions();
    this._analyticsService.sendEvent({
      eventTag: 'asmt:mc_saved'
    });
  }

  selectImage() {
    this._showImagePicker(this.$mdDialog, this.$q.defer(), this.selectedStickerImageUrl)
      .then((selectedStickerImageUrl) => {
        this.selectedStickerImageUrl = selectedStickerImageUrl || '';
      });
  }

  /**
   * @returns {string}
   */
  get currentMode() {
    return this._toolbarService.state.mode;
  }

  //---------------------------------- Slide Background stuff ------------------------------------------------------

  get backgroundManager() {
    return this._toolbarService.slideBackgroundManager;
  }


  //---------------------------------- FITB Methods ----------------------------------------------------------------
  get isScientificFitb(){
    return this.fitbFormat === FitbAnswerTypes.SCIENTIFIC.value;
  }

  _addFitbAnswersToMathFields() {
    const mf = this.$document[0].getElementsByTagName('math-field');
    if (mf.length) {
      Array.from(mf).forEach((mathFieldElement, index) => {
        mathFieldElement.value = this.fillInTheBlankManager.selectedAnswers[index] && this.fillInTheBlankManager.selectedAnswers[index].answer;
      });
    }
  }

  _addMathFieldsEventListeners(){
    const mfParent = this.$document[0].querySelector('.edit-fill-in-the-blank');
    if (mfParent) {
      const mf = mfParent.querySelectorAll('.fitb-math');
      if (mf.length) {
        Array.from(mf).forEach((mathFieldElement, index) => {
          if (mf[index].getAttribute('inputListener') !== 'true') {
            mf[index].addEventListener('input', (event) => this.handleMathFieldInput(event, index));
            mf[index].setAttribute('inputListener', 'true');
          }
        });
      }
    }
  }

  _removeMathFieldsEventListeners(){
    const mfParent = this.$document[0].querySelector('.edit-fill-in-the-blank');
    if (mfParent) {
      const mf = mfParent.querySelectorAll('.fitb-math');
      if (mf.length) {
        Array.from(mf).forEach((mathFieldElement, index) => {
          if (mf[index].getAttribute('inputListener') === 'true') {
            mf[index].removeEventListener('input', (event) => this.handleMathFieldInput(event, index));
            mf[index].setAttribute('inputListener', 'false');
          }
        });
      }
    }
  }
  _changeAnswerType() {
    const fitbParent = this.fillInTheBlankManager.selected;
    fitbParent.format = this.fitbFormat;
    fitbParent.answers = [];
    this.fillInTheBlankManager.selected = fitbParent;
  }

  /**
   * Updates input value and save answer for math-fields
   * @param event {Event}
   * @param index {number}
   */
  handleMathFieldInput(event, index) {
    this.fillInTheBlankManager.handleChange();
    const changedValue = event.target.value;
    this.fillInTheBlankManager.selectedAnswers[index].answer = changedValue;
    this.fillInTheBlankManager.saveAnswers();
  }

  /**
   * Updates answer selection for Answer Type dropdown
   * @param currentSelection {jQuery.Event}
   */
  handleChangeAnswerType(currentSelection) {
    if (this.isFreeUser) {
      this.fitbFormat = FitbAnswerTypes.PLAIN.value;
      return this._contextualPaywallDialog(this.$mdDialog, ContextualPaywalls.ScientificFitb, PaywallSources.SCIENTIFIC_FITB);
    }
    const currentAnswerTypeText = currentSelection.currentTarget.innerText;

    if (this.fillInTheBlankManager.selectedAnswers && this.fillInTheBlankManager.selectedAnswers.length > 0) {
      this._showConfirmMenu(this.$mdDialog, 'Changing this answer type will clear your existing answers', 'Are you sure you want to continue?')
        .then(() => {
          return this._changeAnswerType();
        })
        .catch(() => {
          //when they select 'No'
          const currentAnswerTypeValue = this.fitbAnswerOptions
            .find((answerType) => answerType.text === currentAnswerTypeText);

          //switch back to correct answer type and re-populate answer if answer type is STEM
          new Promise((resolve, reject) => {
            if (currentAnswerTypeValue && currentAnswerTypeValue.value) {
              this.fitbFormat = currentAnswerTypeValue.value;
            } else {
              this.fitbFormat = FitbAnswerTypes.PLAIN.value;
            }
            resolve();
          }).then(() => {
            if (this.fitbFormat === FitbAnswerTypes.SCIENTIFIC.value) {
              this._addFitbAnswersToMathFields();
            }
          });
        });
    } else {
      return this._changeAnswerType();
    }
  }

  showProLogo(type){
    return type && type.value === FitbAnswerTypes.SCIENTIFIC.value && this.isFreeUser;
  }

  //---------------------------------- Toolbar Creation and Sync stuff ------------------------------------------

  /**
   * Sets _toolbarModel {ToolbarModel}
   */
  _initToolbarModel(forceReset = false) {

    if (forceReset === false && (this._toolbarModel || !this.options)) {
      return;
    }

    const buttons = {};
    const sections = {};

    buttons.undo = new IconButton('ck-undo', null, 'Undo', (ev) => this.undo(ev));
    buttons.redo = new IconButton('ck-redo', null, 'Redo', (ev) => this.redo(ev));

    buttons.zoomOut = new IconButton('ck-zoomout', null, 'Zoom Out', (ev) => this.zoomOut(ev));
    buttons.zoomIn = new IconButton('ck-zoomin', null, 'Zoom In', (ev) => this.zoomIn(ev));

    buttons.textbox = new IconButton('ck-text', null, 'Add Textbox', (ev) => this.createTextbox(ev));
    buttons.emoji = new IconButton('ck-smallplus', null, 'Add Symbol and Emoji', (ev) => this.openEmoji(ev));
    buttons.straightLine = new IconButton('ck-linetool', null, 'Add Line', (ev) => this.createStraightLine(ev));
    buttons.link = new IconButton('ck-link', null, 'Add Link', (ev) => this.createLink(ev));
    buttons.image = new IconButton('ck-image-document', null, 'Add Image', (ev) => this.openImageMenu(ev));
    buttons.textToSpeech = new IconButton('ck-volume', null, 'Toggle Text to Speech', (ev) => this.toggleTextToSpeech(ev));
    buttons.coverSlide = new IconButton('ck-opened-eye', null, 'Add Cover Slide', (ev) => this.toggleCoverSlide(ev));
    buttons.audio = new IconButton('ck-audio', null, 'Add Audio', (ev) => this.toggleAudio(ev));
    buttons.manipulativeImage = new IconButton('ck-manipulative', null, 'Add Manipulative', (ev) => this.createManipulativeImage(ev));
    buttons.fillInTheBlank = new IconButton('ck-fillintheblank', null, 'Add Fill In The Blank', (ev) => this.createFillInTheBlank(ev));
    buttons.multipleChoice = new IconButton('ck-multiplechoice', null, 'Add Multiple Choice', (ev) => this.toggleMultipleChoice(ev), null,null, null, null);
    buttons.sticker = new IconButton('ck-quickfeedback', null, 'Add Sticker', (ev) => this.toggleStickers(ev));
    buttons.ai_assistant = new IconButton('ck-fire', null, 'Generate Content with AI Assistance' + (this._toolbarService.isAiAssistantDisabled ? ' (UNAVAILABLE)' : ''), (ev) => this.toggleAiAssistant(ev));

    buttons.pointsField = new PointsField(this.options && this.options.editPoints ? 100 : 145);

    buttons.straightLineColor = new RenderedButton('.assignment-toolbar .rendered-buttons .straight-line-color', 'Line Color', (ev) => this.panel.openToolPanel(ev, this.panel.StraightLineColor, this), null, null, false);
    buttons.straightLineWidth = new IconButton('ck-linewidth', null, 'Line Width', (ev) => this.panel.openToolPanel(ev, this.panel.StraightLineWidth, this));
    buttons.penColor = new RenderedButton('.assignment-toolbar .rendered-buttons .pen-color', 'Pen Color', (ev) => this.panel.openToolPanel(ev, this.panel.PenColor, this), null, null, false);
    buttons.penWidth = new IconButton('ck-linewidth', null, 'Pen Width', (ev) => this.panel.openToolPanel(ev, this.panel.PenWidth, this));
    buttons.highlighterColor = new RenderedButton('.assignment-toolbar .rendered-buttons .highlighter-color', 'Highlighter Color', (ev) => this.panel.openToolPanel(ev, this.panel.HighlighterColor, this), null, null, false);
    buttons.highlighterWidth = new IconButton('ck-linewidth', null, 'Highlighter Width', (ev) => this.panel.openToolPanel(ev, this.panel.HighlighterWidth, this));
    buttons.textColor = new RenderedButton('.assignment-toolbar .rendered-buttons .text-color', 'Text Color', (ev) => this.panel.openToolPanel(ev, this.panel.TextColor, this), null, null, false);
    buttons.textboxBackgroundColor = new RenderedButton('.assignment-toolbar .rendered-buttons .text-background-color', 'Background Color', (ev) => this.panel.openToolPanel(ev, this.panel.TextBackgroundColor, this), null, null, false);
    buttons.textboxBorderColor = new RenderedButton('.assignment-toolbar .rendered-buttons .text-border-color', 'Border Color', (ev) => this.panel.openToolPanel(ev, this.panel.TextBorderColor, this), null, null, false);
    buttons.fontFamily = new RenderedButton('.assignment-toolbar .rendered-buttons .text-font-family', 'Change Font Family', (ev) => this.panel.openToolPanel(ev, this.panel.FontSelection, this), null, null, false);
    buttons.decreaseTextSize = new IconButton('ck-textdecrease', null, 'Make Text Smaller', (ev) => this.decrementFont(ev));
    buttons.increaseTextSize = new IconButton('ck-textincrease', null, 'Make Text Bigger', (ev) => this.incrementFont(ev));
    buttons.slideBackground = new TextButton('Background', undefined, 'Add background to slide', (ev) => this.toggleSlideBackground(ev), undefined, undefined, undefined, 105);
    buttons.clearAll = new TextButton('Clear All', undefined, 'Removes pen and highlighter lines', (ev) => this.clearAll(ev), undefined, undefined, undefined, 90);
    buttons.copyPasteElement = new IconButton('ck-options-duplicate', null, 'Duplicate Element (Ctrl c and Ctrl v works too!)', (ev) => this.copyPasteElementAction(ev));
    buttons.trash = new IconButton('ck-trash', null, 'Delete Object', (ev) => this.deleteElement(ev), () => this.mouseEnterDelete(), () => this.mouseLeaveDelete());

    buttons.toggleFeedback = new RenderedButton('.assignment-toolbar .rendered-buttons .toggle-feedback', 'Toggle Feedback', (ev) => this.toggleFeedback(ev));
    buttons.helpRequest = new RenderedButton('.assignment-toolbar .rendered-buttons .request-help', 'Raise Hand', (ev) => this.toggleHelpRequest(ev), false);
    buttons.toggleContributors = new RenderedButton('.assignment-toolbar .rendered-buttons .toggle-contributors', 'Toggle Contributors', (ev) => this.toggleContributors(ev), false);

    sections.undo = new Section(
      [
        buttons.undo,
        buttons.redo
      ],
      -1,
      false
    );

    sections.zoom = new Section(
      [
        buttons.zoomOut,
        buttons.zoomIn
      ],
      1
    );

    const selectButton = { value: ToolbarModes.Selector, icon: 'ck-select', tooltip: 'Select Mode'};
    const panButton = { value: ToolbarModes.Touch, icon: 'ck-pan', tooltip: 'Pan Mode' };
    const penButton = { value: ToolbarModes.Lines, icon: 'ck-pen', tooltip: 'Pen Mode' };
    const highlighterButton = { value: ToolbarModes.Highlighter, icon: 'ck-highlighter', tooltip: 'Highlighter Mode' };
    const eraserButton = { value: ToolbarModes.Eraser, icon: 'ck-eraser', tooltip: 'Eraser Mode' };

    const modes = [
      this.options && this.options.selectMode ? selectButton : null,
      this.options && this.options.panMode ? panButton : null,
      this.options && this.options.penMode ? penButton : null,
      this.options && this.options.highlightMode ? highlighterButton : null,
      this.options && this.options.eraseMode ? eraserButton : null
    ].filter((x) => !!x);

    sections.mode = new SelectSection(
      modes.filter((x) => !!x),
      2,
      (value) => value && this.updateMode(value.value),
      modes.find((x) => x.value === this.currentMode),
      true,
      'Select Mode',
      'mode-select-container'
    );

    sections.addObjects = new Section(
      [
        this.options && this.options.addTextbox ? buttons.textbox : null,
        this.options && this.options.openEmoji ? buttons.emoji: null,
        this.options && this.options.addStraightLine ? buttons.straightLine : null,
        this.options && this.options.addLink ? buttons.link : null,
        this.options && this.options.addImage ? buttons.image : null,
        this.options && this.options.addAudio ? buttons.audio : null,
        this.options && this.options.addManipulativeImage ? buttons.manipulativeImage : null,
        this.options && this.options.addFillInTheBlank ? buttons.fillInTheBlank : null,
        this.options && this.options.addMultipleChoice ? buttons.multipleChoice: null,
        this.options && (this.options.addSticker || this.options.editSticker) ? buttons.sticker : null,
        this._textToSpeech._isSupported ? buttons.textToSpeech : null,
        this.options && this.options.addAiAssistant && this._user && this._user.isTeacher && this._pro && this._pro.isPro && this._teacherAssistantSegment ? buttons.ai_assistant : null,
      ].filter((x) => !!x),
      3
    );

    sections.config = new Section([
        buttons.straightLineColor,
        buttons.straightLineWidth,
        buttons.penColor,
        buttons.penWidth,
        buttons.highlighterColor,
        buttons.highlighterWidth,
        buttons.textColor,
        buttons.textboxBackgroundColor,
        buttons.textboxBorderColor,
        buttons.fontFamily,
        buttons.decreaseTextSize,
        buttons.increaseTextSize,
        buttons.copyPasteElement,
        buttons.slideBackground,
        buttons.coverSlide,
        buttons.clearAll,
        buttons.trash
      ],
      3,
      true,
      // Set this section to a constant width of 4 buttons because when a Textbox is displayed, 4 buttons
      // are visible -- Color, Embiggen Text, Smaller Text, Trash
      4 * buttons.penColor.width
    );

    sections.more = new MoreSection((ev) => this.panel.openMorePanel(ev));

    sections.spacer = new Section(
      [
        new FlexSpace()
      ],
      -1,
      false
    );

    sections.points = new Section(
      [
        this.options && (this.options.viewScore || this.options.editPoints || this.options.editScore) ? buttons.pointsField : null
      ].filter((x) => !!x),
      5,
      false
    );

    sections.hand = new Section(
      [
        this.options && this.options.toggleFeedback ? buttons.toggleFeedback : null,
        this.options && !this.options.editPoints ? buttons.helpRequest : null,
        this.options && this.options.toggleContributors ? buttons.toggleContributors : null
      ].filter((x) => !!x),
      -1,
      false
    );

    const model = new ToolbarModel([
      sections.undo,
      sections.zoom,
      sections.mode,
      sections.addObjects,
      sections.config,
      sections.more,
      sections.spacer,
      sections.points,
      sections.hand
    ]);

    model.buttonMap = buttons;
    model.sectionMap = sections;
    model.modes = modes;

    this._toolbarModel = model;

    this._updateAndMeasureToolbarButtons();
  }

  updateToolbarButtons() {
    this._toolbarMeasurer.tick();
  }

  _updateAndMeasureToolbarButtons() {
    if (this.toolbarModel) {
      this._updateCoverSlideStatus();
      // NOTE: cover slide option is added to a specific index
      // in the array which may need to adjusted if toolbar options are added/removed.
      if (this.hasCoverSlide){
        this._toolbarModel.buttonMap.coverSlide = new IconButton('ck-closed-eye', null, 'Remove Cover Slide', (ev) => this.toggleCoverSlide(ev));
        this._toolbarModel.sections[4]._displayItems[15] = this._toolbarModel.buttonMap.coverSlide;
        this._toolbarModel.sections[4]._items[14] = this._toolbarModel.buttonMap.coverSlide;
      } else {
        this._toolbarModel.buttonMap.coverSlide = new IconButton('ck-opened-eye', null, 'Add Cover Slide', (ev) => this.toggleCoverSlide(ev));
        this._toolbarModel.sections[4]._displayItems[15] = this._toolbarModel.buttonMap.coverSlide;
        this._toolbarModel.sections[4]._items[14] = this._toolbarModel.buttonMap.coverSlide;
      }
      this._updateToolbarButtonsSync();
      this.onWindowResized();
    }
    else {
      this._initToolbarModel();
    }
  }

  _updateCoverSlideStatus() {
    const questions = this._assignmentTrackingService
      && this._assignmentTrackingService.target
      && this._assignmentTrackingService.target.questions;
    if (questions && !Array.isArray(questions)) {
     if (this.contentQuestion && this.contentQuestion.id){
       const elementMap = questions.get(this.contentQuestion.id).elements._objectMap;
       if (elementMap) {
         for (let element of elementMap) {
           if (element[1].type === SlideForeground.type) {
             return this.hasCoverSlide = true;
           }
         }
         return this.hasCoverSlide = false;
       }
     }
    }
  }

  _updateToolbarButtonsSync() {

    const selectorMode = this.currentMode === ToolbarModes.Selector;
    const penMode = this.currentMode === ToolbarModes.Lines;
    const highlightMode = this.currentMode === ToolbarModes.Highlighter;
    const eraserMode = this.currentMode === ToolbarModes.Eraser;
    const placeTextboxMode = this.currentMode === ToolbarModes.PlaceTextbox;
    const placeStraightLineMode = this.currentMode === ToolbarModes.PlaceStraightLine;
    const placeFillInTheBlankMode = this.currentMode === ToolbarModes.PlaceFillInTheBlank;

    const {
      focusedElementIsTextbox,
      focusedElementIsFillInTheBlankChild,
      focusedElementIsStraightLine
    } = this._toolbarService;

    const focusedElementIsTextboxBase = focusedElementIsTextbox || focusedElementIsFillInTheBlankChild;

    this.toolbarModel.sectionMap.mode.currentValue = this.toolbarModel.sectionMap.mode.values.find((x) => x.value === this.currentMode);

    this.toolbarModel.buttonMap.undo.disabled = !this._toolbarService.canUndo;
    this.toolbarModel.buttonMap.redo.disabled = !this._toolbarService.canRedo;
    this.toolbarModel.buttonMap.zoomIn.disabled = !this._toolbarService.canZoomIn;
    this.toolbarModel.buttonMap.zoomOut.disabled = !this._toolbarService.canZoomOut;
    this.toolbarModel.buttonMap.decreaseTextSize.disabled = focusedElementIsTextboxBase && this.focusedElement.isFontSizeAtMin;
    this.toolbarModel.buttonMap.increaseTextSize.disabled = focusedElementIsTextboxBase && this.focusedElement.isFontSizeAtMax;
    this.toolbarModel.buttonMap.copyPasteElement.disabled = !this.focusedElement || this.focusedElement.isChildManipulative;
    this.toolbarModel.buttonMap.trash.disabled = !this.focusedElement || this.focusedElement.isChildManipulative;
    this.toolbarModel.buttonMap.toggleFeedback.disabled = this._toolbarService.isFeedbackDisabled;
    this.toolbarModel.buttonMap.ai_assistant.disabled = this._toolbarService.isAiAssistantDisabled;

    this.toolbarModel.buttonMap.textbox.toggled = placeTextboxMode;
    this.toolbarModel.buttonMap.straightLine.toggled = placeStraightLineMode;
    this.toolbarModel.buttonMap.fillInTheBlank.toggled = placeFillInTheBlankMode;
    this.toolbarModel.buttonMap.audio.toggled = this.isAudioModeVisible;
    this.toolbarModel.buttonMap.ai_assistant.toggled = this.isAiAssistantModeVisible;
    this.toolbarModel.buttonMap.sticker.toggled = this.isStickerModeVisible;
    this.toolbarModel.buttonMap.multipleChoice.toggled = this.isMultipleChoiceModeVisible;
    this.toolbarModel.buttonMap.toggleFeedback.toggled = this._toolbarService.isFeedbackVisible;
    this.toolbarModel.buttonMap.toggleContributors.toggled = this.isContributorsTabModeVisible;

    this.toolbarModel.buttonMap.straightLineColor.visible = selectorMode && focusedElementIsStraightLine;
    this.toolbarModel.buttonMap.straightLineWidth.visible = selectorMode && focusedElementIsStraightLine;
    this.toolbarModel.buttonMap.penColor.visible = penMode;
    this.toolbarModel.buttonMap.penWidth.visible = penMode;
    this.toolbarModel.buttonMap.highlighterColor.visible = highlightMode;
    this.toolbarModel.buttonMap.highlighterWidth.visible = highlightMode;
    this.toolbarModel.buttonMap.textColor.visible = selectorMode && focusedElementIsTextboxBase;
    this.toolbarModel.buttonMap.textboxBackgroundColor.visible = selectorMode && focusedElementIsTextbox;
    this.toolbarModel.buttonMap.textboxBorderColor.visible = selectorMode && focusedElementIsTextbox;
    this.toolbarModel.buttonMap.fontFamily.visible = selectorMode && focusedElementIsTextbox;
    this.toolbarModel.buttonMap.decreaseTextSize.visible = selectorMode && focusedElementIsTextboxBase;
    this.toolbarModel.buttonMap.increaseTextSize.visible = selectorMode && focusedElementIsTextboxBase;
    this.toolbarModel.buttonMap.slideBackground.visible = this.options && this.options.editBackground && selectorMode && !this.focusedElement;
    this.toolbarModel.buttonMap.coverSlide.visible = this.options && this.options.coverSlide;
    this.toolbarModel.buttonMap.clearAll.visible = eraserMode;
    this.toolbarModel.buttonMap.copyPasteElement.visible = this.focusedElement && selectorMode;
    this.toolbarModel.buttonMap.trash.visible = this.focusedElement && selectorMode;

    this.toolbarModel.buttonMap.pointsField.visible = this.showGradeInput;
  }

  onWindowResized() {
    if (this.toolbarModel) {
      this.toolbarModel.sizeToFit(this._window.width() - 30);
    }
  }

  _onToolbarServiceStateUpdated(ev) {
    this.updateToolbarButtons();

    //prevents fitb sidenav from closing because of answer type switch dialog
    if (this.focusedElement && this.focusedElement.classList
      && (Array.from(this.focusedElement.classList).includes('cancel-btn')|| Array.from(this.focusedElement.classList).includes('confirm-btn'))) {
      return;
    }

    //prevents fitb sidenav from closing because of answer type switch dialog
    if (this.focusedElement && this.focusedElement.className === 'md-dialog-is-showing') {
      return;
    }

    //prevents fitb sidenav from closing because of answer change
    if (ev && ev.constructor && ev.constructor.name === 'Change'){
      return;
    }

    //closes fitb sidenav when you click the close button
    if (ev && ev.oldState && ev.oldState.mode.isFillInTheBlankMode && ev.oldState.isOpen === true) {
      this.sidenav.closeState();
      this.focusedElement && this.focusedElement.blur();
    }
    else if (this._toolbarService.focusedElementIsFillInTheBlankParent) {
      const saveAnswer = new Promise((resolve, reject) => {
        this.fillInTheBlankManager.saveAnswers();
        resolve();
      });
      saveAnswer.then(() => {
        this.fitbFormat = this.fillInTheBlankManager && this.fillInTheBlankManager.selected && this.fillInTheBlankManager.selected.format;
      });
      this.sidenav.openEditFillInTheBlank(this.focusedElement);
    }
    else if (this.isFillInTheBlankModeVisible) {
      this.fillInTheBlankManager.saveAnswers();
      while (this.isFillInTheBlankModeVisible) {
        this.sidenav.closeState();
        this._analyticsService.closeFitb(this.fitbFormat);
      }
    }
  }

  preventElementBlur(ev, value) {
    ev.originalEvent.preventElementBlur = angular.isDefined(ev.originalEvent.preventElementBlur) ? ev.originalEvent.preventElementBlur : value;
  }

  /**
   * @return {FillInTheBlankManager}
   */
  get fillInTheBlankManager() {
    return this._toolbarService.fillInTheBlankManager;
  }

  addFillInTheBlankAnswer() {
    this.fillInTheBlankManager.addAnswer();
  }

  /**
   * When a user is focused on an answer input and presses enter, focus will move
   * to the next answer input or, if they are focused on the last input, a new
   * answer input will be created.
   *
   * @param index {number}
   */
  handleEnter(index) {
    let answers = angular.element('.edit-fill-in-the-blank .answer input');
    let nextAnswer = answers[index + 1];

    if (nextAnswer) {
      nextAnswer.focus();
    }
    else {
      this.addFillInTheBlankAnswer();
      this.saveAnswerNotification();
    }
  }

  get locked() {
    return this._locked;
  }

  set locked(value) {
    this._locked = value;

    this.toolbarModel.sections.forEach((section) => {
      section.visibility = !value;
    });

    if (value) {
      this.toolbarModel.sectionMap.spacer.visibility = true;
      this.toolbarModel.sectionMap.points.visibility = true;
      this.toolbarModel.sectionMap.hand.visibility = true;

      Snackbar.show(this.$mdToast, 'Your teacher has locked this assignment. You will not be able to make any changes to your work.', false);
      this._displayingAssignmentLockMessage = true;
    } else {
      this._snackbar.dismiss();
      this._displayingAssignmentLockMessage = false;
    }

    this.updateMode(ToolbarModes.Selector);

    this.toolbarModel.clear();
  }

  saveAnswerNotification(){
    this.saveState = SaveStates.ANSWER_SAVED;
    this.$timeout(() => {
      this.saveState = '';
    }, 1000);
  }
}
