'use strict';

import Snap from 'snapsvg-cjs';
import Element from './element';
import Point from '../point';
import Size from '../size';
import GestureManager from '../gesture-manager';
import StaticService from '../../../services/static/static.service';
import HexColors from '../../../css-constants';
import TextboxArranger from './textbox-arranger';
import { ColorSpan } from './textbox-color-span-arranger';
import MoreOptionsHandle from '../more-options-handle';
import { ContextMenuOptions } from '../../../components/canvas-context-menu/canvas-context-menu.controller';
import VideoPlayerDialogController from '../../../components/video-player-dialog/video-player-dialog.controller';
import youtubeIcon from '../../../../assets/colored-icons/youtube.svg';
import linkIcon from '../../../../assets/colored-icons/link.svg';
import UserNotificationDialogController from '../../../components/user-notification-dialog/user-notification-dialog.controller';

export default class Link extends Element {
  /**
   * @param id {string}
   * @param metadata {ElementMetadata}
   * @param location {Point}
   * @param size {Size}
   * @param text {string}
   * @param url {string}
   */
  constructor(id, metadata, location, size, text, url) {
    super(id, Link.type, metadata);

    this._location = location || new Point(100, 200);
    this._size = size || new Size(250, 55);
    this._url = url || '';
    this._text = text || '';
    this._initialFontSize = 16;
    this._arranger = new TextboxArranger();
    this._arranger.lineHeightScale = StaticService.get.isWindows ? 1 : 1.2;
    this._shadowFilter = Snap.filter.shadow(0, 0, 3, HexColors.CK_DISABLED_GREY, 1);
  }

  /**
   * @returns {string}
   */
  static get type() {
    return 'link';
  }

  /**
   * @returns {string}
   */
  get url() {
    return this._url;
  }

  /**
   * @returns {string}
   */
  get text() {
    return this._text;
  }

  /**
   * @param text {string}
   * @param url {string}
   */
  resetTextAndUrl(text, url) {
    this._trackChange(() => {
      this._text = text;
      this._url = url;
    });
  }

  /**
   * @returns {boolean}
   */
  get isVideoLink() {
    return this.url.indexOf('youtube.com') >= 0 || this.url.indexOf('youtu.be') >= 0;
  }

  get iconUri() {
    if (this.isVideoLink) {
      return youtubeIcon;
    }
    return linkIcon;
  }

  createElement(root, editable, active) {
    this._base = root.group();
    this._interactive = root.group();

    this._background = this._base.rect(0, 0, 0, 0);
    this._linkIcon = this._base.image(this.iconUri, 0, 0, 0, 0);

    this._touch = this._interactive.rect(0, 0, 0, 0).addClass('touch-foreground');
    this._textbox = this._base.text(0, 0, '');

    this._arranger.invalidateDom();

    if (active) {
      this._gesture = new GestureManager(this, this.canvas);
      this._gesture._dragThreshold = 0;
      this._gesture.start(this._touch.node);
      this._gesture.click.subscribe(this._handleClick, this);
    }

    if (editable) {
      this._moreOptionsHandle = new MoreOptionsHandle(this);
      this._moreOptionsHandle.render(this._interactive);
      this._moreOptionsHandle.mouseEnter.subscribe(this.hoverIn, this);
      this._moreOptionsHandle.mouseLeave.subscribe(this.hoverOut, this);

      this._drag = new GestureManager(this, this.canvas);
      this._drag.start(this._touch.node);
      this._drag.dragStart.subscribe(this._repositionStart, this);
      this._drag.dragMove.subscribe(this._repositionMove, this);
      this._drag.dragEnd.subscribe(this._repositionEnd, this);
      this._drag.mouseEnter.subscribe(this.hoverIn, this);
      this._drag.mouseLeave.subscribe(this.hoverOut, this);
    }
  }

  hoverIn() {
    this.tryUpdate();
  }

  hoverOut() {
    this.tryUpdate();
  }

  _repositionStart() {
    this._dragStartState = this.snapshot();
  }

  _repositionMove(data) {
    this.location = data.controlStart.plus(data.delta);
  }

  _repositionEnd() {
    this.focus();
    this._onChanged(this._dragStartState);
  }

  update(root, editable, active) {
    this._background.attr({
      x: this.location.x,
      y: this.location.y,
      width: this.width,
      height: this.height,
      rx: 4,
      fill: HexColors.BACKGROUND_GREY,
      stroke: HexColors.CK_DISABLED_GREY,
      strokeWidth: 2,
      filter: this._background.paper.filter(this._shadowFilter)
    });

    this._linkIcon.attr({
      href: this.iconUri,
      x: this.location.x + 20,
      y: this.location.y + (this.isVideoLink ? 18 : 16),
      width: 20,
      height: 20,
      preserveAspectRatio: 'xMidYMid meet'
    });

    this._touch.attr({
      x: this.location.x,
      y: this.location.y,
      width: this.width,
      height: this.height,
      rx: 4,
      ry: 4,
      fill: 'transparent',
      cursor: 'pointer'
    });

    this._renderAdjustedText(this.center.y, this._initialFontSize, 1, this.text);

    if (editable) {
      this._background.attr({
        stroke: this.hovering || this.hasFocus ? HexColors.CK_GREEN : HexColors.CK_DISABLED_GREY
      });
      this._moreOptionsHandle.location = this.location.plus(new Point(this.width, 0));
      this._moreOptionsHandle.visibility = this.hovering || this.hasFocus ? 'visible' : 'hidden';
    }
    else if (active) {
      this._touch.attr({
        x: this.location.x,
        y: this.location.y,
        width: this.width,
        height: this.height,
        rx: 4,
        fill: 'transparent',
        cursor: 'pointer'
      });
    }
  }

  /**
   * @return {number}
   */
  get MAX_LINES() {
    return 3;
  }

  /**
   * @param yLoc {number}
   * @param fontSize {number}
   * @param lines {number}
   * @param text {string}
   */
  _renderAdjustedText(yLoc, fontSize, lines, text) {

    this._renderText(yLoc, fontSize, text);

    if (this._linesOfText <= lines) {
      yLoc = this.location.y + (this.height - 2 - this._newHeight) / 2;
      this._renderText(yLoc, fontSize, text);
    }
    else if (lines + 1 > this.MAX_LINES) {
      this._renderAdjustedText(yLoc, fontSize, lines, this._truncatedText);
    }
    else {
      this._renderAdjustedText(yLoc, fontSize - 1, lines + 1, text);
    }
  }

  /**
   * @param y
   * @param fontSize
   * @param text
   * @private
   */
  _renderText(y, fontSize, text) {
    let textLocation = this._textLocation(y);

    this._textbox.attr({
      fontFamily: 'GothamRoundedMedium',
      alignmentBaseline: 'middle',
      fontSize: fontSize,
      pointerEvents: 'none'
    });

    this._arranger.renderText(
      textLocation,
      this._textbox,
      text || this.text,
      170,
      [new ColorSpan(0, null, HexColors.CK_GREEN)],
      fontSize
    );
  }

  /**
   * @param y {number}
   * @return {Point}
   */
  _textLocation(y) {
    return new Point(this.location.x + 60, y);
  }

  /**
   * @return {number}
   */
  get _linesOfText() {
    return this._arranger.lineData.length;
  }

  /**
   * @return {number}
   */
  get _newHeight() {
    return this._arranger._cachedLineHeight * this._linesOfText;
  }

  /**
   * @return {string}
   */
  get _truncatedText() {
    let thirdLineData = this._arranger.lineData[2];
    let endIndex = thirdLineData.endIdx;
    return `${this.text.slice(0, endIndex - 3)}...`;
  }

  warnBeforeDeletion() {
    this._background.attr({
      stroke: HexColors.CK_WARN
    });
  }

  /**
   * @returns {Boolean}
   */
  get hovering() {
    return this._drag.hovering || this._moreOptionsHandle.hovering;
  }

  _handleClick() {
    if (this.isVideoLink) {
      VideoPlayerDialogController.show(StaticService.get.$mdDialog, this.url);
    }
    else {
      const sanitizedUrl = Link.sanitizedUrl(this.url);
      if (Link.validateWebUrl(sanitizedUrl)) {
        StaticService.get.$window.open(sanitizedUrl);
      } else {
        UserNotificationDialogController.customShow(
          StaticService.get.$mdDialog,
          'Invalid Link',
          'We detected and blocked an invalid or potentially unsafe link.<br>Please have your teacher inspect and update it.',
          'invalid-url',
          'Back to safety'
        );
      }
    }
  }

  /**
   * Tries to validate url.  If it is not valid, it will return false.
   *
   * @returns {boolean}
   */
  static validateWebUrl(url) {
    let valid = true;

    // Attempt to validate with the URL interface
    try {
      const validUrl =  new URL(url);
      const protocol = validUrl.protocol || '';
      if ((validUrl.host === '' && validUrl.origin === 'null')
          || protocol.indexOf('http') !== 0) {
        valid = false;
      }
    } catch (err) {
      valid = false;
    }

    // Extra check on formatting of the url
    const match = new RegExp('^(https?):\\/\\/[\\-A-Za-z0-9+&@#\\/%?=~_|!:,.;]*[\\-A-Za-z0-9+&@#\\/%=~_|]');

    return valid && match.test(url) && url.indexOf('.') > 0;
  }

  /**
   * Check if the given url already starts with http(s)://
   * Checks if the given url already has other protocols :// before attempting to prepending http, otherwise a false
   *   positive is given for the url.
   * Otherwise, it will prepend http to the url.
   *
   * @param url
   * @returns {string|*}
   */
  static sanitizedUrl(url) {
    const httpRegex = new RegExp('^(https?):\\/\\/');
    if (httpRegex.test(url))  {
      // Matches starting with http(s)
      return url;
    } else if (url.indexOf('://') > 0) {
      // avoid concatenating http and creating a false positive
      return url;
    }
    return 'http://' + url;
  }

  /**
   * Called by MoreOptionsHandle when list item in more options menu is clicked
   * @param event
   */
  handleMoreOptionsClicked(event) {
    this.focus();
    // TODO uses parent controller
    this.moreOptionsManager.openMenu(event).then((option) => {
      if (option === ContextMenuOptions.Edit) {
        this.moreOptionsManager.editLink(this.text, this.url).then(({title, url}) => {
          this.resetTextAndUrl(title, url);
        });
      }
      else if (option === ContextMenuOptions.Delete) {
        this.delete();
      }
    });
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {Link}
   */
  merge(other) {
    this._metadata = other._metadata || this._metadata;
    this._location = other._location || this._location;
    this._size = other._size || this._size;
    this._text = other._text || this._text;
    this._url = other._url || this._url;

    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _location: this._location,
      _size: this._size,
      _text: this._text,
      _url: this._url
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {Link}
   */
  fromSnapshot(id, snapshot) {
    return new Link(
      id,
      snapshot._metadata,
      snapshot._location,
      snapshot._size,
      snapshot._text,
      snapshot._url
    );
  }
}
