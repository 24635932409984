'use strict';

import moment from 'moment';

export class SubscriptionPlans {

  static get AntaresAnnualV1() {
    return 'antares-annual-v1';
  }

  static get AntaresAnnualV2() {
    return 'antares-annual-v2';
  }

  static get NiagaraAnnualV1() {
    return 'niagara-annual-v1';
  }

  static get NiagaraMonthlyV1() {
    return 'niagara-monthly-v1';
  }

  static get NiagaraAnnualV2() {
    return 'niagara-annual-v2';
  }

  static get NiagaraMonthlyV2() {
    return 'niagara-monthly-v2';
  }

  static get NiagaraAnnualV3() {
    return 'niagara-annual-v3';
  }

  static get NiagaraMonthlyV3() {
    return 'niagara-monthly-v3';
  }

  static get NiagaraAnnualV4() {
    return 'niagara-annual-v4';
  }

  static get NiagaraMonthlyV4() {
    return 'niagara-monthly-v4';
  }

  static get NiagaraAnnualV6() {
    return 'niagara-annual-v6';
  }

  static get NiagaraMonthlyV6() {
    return 'niagara-monthly-v6';
  }

  static get NiagaraAnnualV7() {
    return 'niagara-annual-v7';
  }

  static get NiagaraMonthlyV7() {
    return 'niagara-monthly-v7';
  }
}

export class SubscriptionIntervals {

  static get Annual() {
    return 'annual';
  }

  static get Monthly() {
    return 'monthly';
  }

  static isMonthly(plan) {
    return SubscriptionIntervalsMap[plan] === SubscriptionIntervals.Monthly;
  }

}

export const SubscriptionIntervalsMap = {
  [SubscriptionPlans.AntaresAnnualV1]: SubscriptionIntervals.Annual,
  [SubscriptionPlans.AntaresAnnualV2]: SubscriptionIntervals.Annual,
  [SubscriptionPlans.NiagaraAnnualV1]: SubscriptionIntervals.Annual,
  [SubscriptionPlans.NiagaraMonthlyV1]: SubscriptionIntervals.Monthly,
  [SubscriptionPlans.NiagaraAnnualV2]: SubscriptionIntervals.Annual,
  [SubscriptionPlans.NiagaraMonthlyV2]: SubscriptionIntervals.Monthly,
  [SubscriptionPlans.NiagaraAnnualV3]: SubscriptionIntervals.Annual,
  [SubscriptionPlans.NiagaraMonthlyV3]: SubscriptionIntervals.Monthly,
  [SubscriptionPlans.NiagaraAnnualV4]: SubscriptionIntervals.Annual,
  [SubscriptionPlans.NiagaraMonthlyV4]: SubscriptionIntervals.Monthly,
  [SubscriptionPlans.NiagaraAnnualV6]: SubscriptionIntervals.Annual,
  [SubscriptionPlans.NiagaraMonthlyV6]: SubscriptionIntervals.Monthly,
  [SubscriptionPlans.NiagaraAnnualV7]: SubscriptionIntervals.Annual,
  [SubscriptionPlans.NiagaraMonthlyV7]: SubscriptionIntervals.Monthly
};

export class SubscriptionCoupons {

  static get NiagaraAnnualLegacyV1() {
    return 'niagara-annual-legacy-v1';
  }

  static get NiagaraMonthlyLegacyV1() {
    return 'niagara-monthly-legacy-v1';
  }

}

export class SubscriptionStatus {

  static get Created() {
    return 'created';
  }

  static get Trialing() {
    return 'trialing';
  }

  static get Active() {
    return 'active';
  }

  static get PastDue() {
    return 'past_due';
  }

  static get Cancelled() {
    return 'cancelled';
  }

  static get All() {
    return [
      SubscriptionStatus.Created,
      SubscriptionStatus.Trialing,
      SubscriptionStatus.Active,
      SubscriptionStatus.PastDue,
      SubscriptionStatus.Cancelled
    ];
  }

}

export default class Subscription {

  constructor(id, ownerId, contractId, status, provider, active, created, updated, subscriptionPlan, coupon) {
    this._id = id;
    this._ownerId = ownerId;
    this._contractId = contractId;
    this._status = status;
    this._provider = provider;
    this._active = active;
    this._created = created ? moment(created) : undefined;
    this._updated = updated ? moment(updated) : undefined;
    this._subscriptionPlan = subscriptionPlan;
    this._coupon = coupon;
  }

  /**
   * The number of days we add to a contract after the renewal date to give the user time to update payment info
   * in the event their payment fails and they need to update card info or find another way to pay. This is set
   * in web services but we use here to show an accurate renewal date.
   *
   * @return {number}
   */
  static get GracePeriod() {
    return 7;
  }

  /**
   * @return {string}
   */
  static get GracePeriodUnit() {
    return 'days';
  }

  /**
   * @return {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @return {string}
   */
  get ownerId() {
    return this._ownerId;
  }

  /**
   * @return {string}
   */
  get contractId() {
    return this._contractId;
  }

  /**
   * @return {string}
   */
  get status() {
    return this._status;
  }

  /**
   * @return {string}
   */
  get provider() {
    return this._provider;
  }

  /**
   * @return {boolean}
   */
  get active() {
    return this._active;
  }

  /**
   * @return {moment}
   */
  get created() {
    return this._created;
  }

  /**
   * @return {moment}
   */
  get updated() {
    return this._updated;
  }

  /**
   * @returns {string}
   */
  get subscriptionPlan() {
    return this._subscriptionPlan;
  }

  /**
   * @param value {string}
   */
  set subscriptionPlan(value) {
    this._subscriptionPlan = value;
  }

  /**
   * @return {string}
   */
  get coupon() {
    return this._coupon;
  }

  get nextCharge() {
    let clone = this._created.clone();
    let unit = this.subscriptionPlan === SubscriptionPlans.NiagaraAnnualV7 ? 'years' : 'month';
    return clone.add(1, unit).format('MMMM Do YYYY');
  }

}
