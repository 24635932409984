import Validation from '../util/validation';

export default class NewContractStudent {

  constructor(id, firstName, lastName, password, isIdValid = true, isNotDuplicate = true, isStudent = true) {
    this._id = id;
    this._firstName = firstName;
    this._lastName = lastName;
    this._password = password;
    this._isIdValid = isIdValid;
    this._isNotDuplicate = isNotDuplicate;
    this._isStudent = isStudent;
  }

  /**
   * A unique identifier like a username, email, or phone number
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * A unique identifier like a username, email, or phone number
   * @param value {string}
   */
  set id(value) {
    this._id = value;
  }

  get firstName() {
    return this._firstName;
  }

  set firstName(value) {
    this._firstName = value;
  }

  get lastName() {
    return this._lastName;
  }

  set lastName(value) {
    this._lastName = value;
  }

  get password() {
    return this._password;
  }

  set password(value) {
    this._password = value;
  }

  get isIdValid() {
    return this._isIdValid;
  }

  set isIdValid(value) {
    this._isIdValid = value;
  }

  get isNotDuplicate() {
    return this._isNotDuplicate;
  }

  set isNotDuplicate(value) {
    this._isNotDuplicate = value;
  }

  get isStudent() {
    return this._isStudent;
  }

  set isStudent(value) {
    this._isStudent = value;
  }

  /**
   * @return {boolean}
   */
  get isFirstNameValid() {
    return this._firstName ? this._firstName.length <= Validation.UserFirstNameMaxSize : true;
  }

  /**
   * @return {boolean}
   */
  get isLastNameValid() {
    return this._lastName ? this._lastName.length <= Validation.UserLastNameMaxSize : true;
  }

  /**
   * @return {boolean}
   */
  get valid() {
    return !!this.password && !!this.id && this.isIdValid && this.isFirstNameValid && this.isLastNameValid && !!this.isStudent;
  }

  get validAndNew() {
    return this.valid && this.isNotDuplicate;
  }

  get errorMessage() {
    let errors = [];
    let message = 'Invalid ';

    if (!this.isFirstNameValid) {
      errors.push('first name');
    }

    if (!this.isLastNameValid) {
      errors.push('last name');
    }

    if (!this.isIdValid || !this.id) {
      errors.push('username or email');
    }

    if (!this.password) {
      errors.push('password');
    }

    if (!this.isStudent) {
      errors.push('account type. This account is already a teacher account. Please remove before continuing.');
    }

    return this._serializeMessages(message, errors);
  }

  get warningMessage() {
    let warnings = [];

    if (!this.isNotDuplicate & this.isStudent) {
      warnings.push('Student account already exists, password will not be set.');
    }

    return this._serializeMessages('', warnings);
  }

  _serializeMessages(prefix, messages) {
    if (messages.length > 1) {
      return `${prefix}${messages.slice(0, messages.length - 1).join(', ')}, and ${messages.pop()}`;
    }
    else if (messages.length === 1) {
      return `${prefix}${messages[0]}`;
    }
    else {
      return '';
    }
  }
}
