'use strict';

import Snap from 'snapsvg-cjs';
import SvgThumbnailLayers from './svg-thumbnail-layers';
import SvgCanvas from './svg-canvas';

export default class SvgThumbnail extends SvgCanvas {

  constructor(element, $timeout, $window) {
    super();

    this.$timeout = $timeout;
    this._window = angular.element($window);

    this._svg = element.find('svg')[0];
    this._snap = new Snap(this._svg);
    this._layers = new SvgThumbnailLayers(this._snap);
    this._element = element;
  }

  /**
   * @return {SvgThumbnailLayers}
   */
  get layers() {
    return this._layers;
  }

  /**
   * @return {Point}
   */
  get absolutePosition() {
    throw new Error('absolutePosition getter method should not be called on elements that are rendered in thumbnails');
  }

  /**
   * @return {number}
   */
  get inverseScaleFactor() {
    throw new Error('inverseScaleFactor getter method should not be called on elements that are rendered in thumbnails');
  }

  /**
   * @param position {Point}
   * @returns {Point}
   */
  scale(position) {
    throw new Error('scale method should not be called on elements that are rendered in thumbnails');
  }

  get hiddenTextInput() {
    throw new Error('hiddenTextInput getter should not be called on elements that are rendered in thumbnails');
  }
}
