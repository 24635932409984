import ProTrialConstants from '../../model/util/pro-trial-util';
import ProTrialOverlayTemplate from './pro-trial-overlay.html';

export default class ProTrialOverlayController {
    /**
     * @ngInject
     */
    constructor($mdDialog, $scope, StorageService) {
  
      this.$mdDialog = $mdDialog;
      this.$scope = $scope;
      this.benefitsBanner = ProTrialConstants.BENEFITS_BANNER;
      this.referPeer = ProTrialConstants.REFER_PEER;
      this.noAction = ProTrialConstants.NO_ACTION;

      /** @type {StorageService} */
      this._storageService = StorageService;
    }
  
    static show($mdDialog) {
      return $mdDialog.show({
        controller: ProTrialOverlayController,
        template: ProTrialOverlayTemplate,
        controllerAs: 'ctrl',
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: {}
      });
    }

    close(action) {
      this._storageService.proTrialOverlaySeen = true;
      this.$mdDialog.hide(action);
    }
  }
  