'use strict';

import Control from './control';
import Size from './size';
import GestureManager from './gesture-manager';
import HexColors from '../../css-constants';
import volumeIcon from '../../../assets/ckicon/volume.svg';

export default class TextToSpeechHandle extends Control {

  constructor(target) {
    super(target.id + '-tts', 'tts'); 

    this._size = new Size(30, 30);
    this._iconDiameter = 14;
    this._target = target;
    this._gestureManager = new GestureManager(this._target, this._target.canvas);
  }

  createElement(root) {
    this._base = root.group();
    this._background = this._base.circle(0, 0, 0);
    this._volumeIcon = this._base.image(volumeIcon, 0, 0, 0, 0);

    this._interactive = root.group();
    this._touch = this._interactive.circle(0, 0, 0).addClass('touch-foreground');

    this._gestureManager.start(this._interactive.node);
    this._gestureManager.click.subscribe(this._clickHandler, this);
  }

  update() {
    this._base.attr({
      visibility: this._visibility
    });

    this._background.attr({
      cx: this.location.x,
      cy: this.location.y,
      r: this.width / 2,
      fill: HexColors.CK_ELEMENT_BLACK
    });

    if (angular.isNumber(this._target.rotation)) {
      this._volumeIcon.node.setAttribute('transform', `rotate(${360 - this._target.rotation }, ${this.location.x}, ${this.location.y})`);
    }

    this._volumeIcon.attr({
      x: this.location.x - ((this._iconDiameter+ 10) / 2),
      y: this.location.y - ((this._iconDiameter + 10)/ 2),
      width: this._iconDiameter + 10,
      height: this._iconDiameter + 10,
      preserveAspectRatio: 'xMidYMid meet',
      style: 'pointer-events:none;'
    });

    this._touch.attr({
      cx: this.location.x,
      cy: this.location.y,
      r: this.width / 2,
      fill: 'transparent',
      cursor: 'pointer'
    });
  }

  _clickHandler() {
    this._target.activateTextToSpeech();
  }

  remove() {
    this._gestureManager.stop();
    this._gestureManager.click.unsubscribe(this._clickHandler, this);
    super.remove();
  }

  get hovering() {
    return this._gestureManager.hovering;
  }

  get mouseEnter() {
    return this._gestureManager.mouseEnter;
  }

  get mouseLeave() {
    return this._gestureManager.mouseLeave;
  }
}
