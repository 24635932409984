import moment from 'moment';

export class OrderPurchasePeriod {

  /**
   * Gets the start date for the 'this year' purchase period for the current date
   * @return {moment}
   */
  static get ThisYearStart() {
    let now = moment();
    return OrderPurchasePeriod.getThisYearStart(now);
  }

  /**
   * Gets the end date for the 'this year' purchase period for the current date
   * @return {moment}
   */
  static get ThisYearEnd() {
    let now = moment();
    return OrderPurchasePeriod.getThisYearEnd(now);
  }

  /**
   * Gets the start date for the 'next year' purchase period for the current date
   * @return {moment}
   */
  static get NextYearStart() {
    let now = moment();
    return OrderPurchasePeriod.getNextYearStart(now);
  }

  /**
   * Gets the end date for the 'next year' purchase period for the current date
   * @return {moment}
   */
  static get NextYearEnd() {
    let now = moment();
    return OrderPurchasePeriod.getNextYearEnd(now);
  }

  /**
   * Gets the start date for the 'in two years' purchase period for the current date
   * @return {moment}
   */
  static get InTwoYearsStart() {
    let now = moment();
    return OrderPurchasePeriod.getInTwoYearsStart(now);
  }

  /**
   * Gets the end date for the 'in two years' purchase period for the current date
   * @return {moment}
   */
  static get InTwoYearsEnd() {
    let now = moment();
    return OrderPurchasePeriod.getInTwoYearsEnd(now);
  }

  /**
   * Gets the start date for the 'this year' purchase period for a given date
   * @param date {moment}
   * @return {moment}
   */
  static getThisYearStart(date) {
    return date;
  }

  /**
   * Gets the end date for the 'this year' purchase period for a given date
   * @param date {moment}
   * @return {moment}
   */
  static getThisYearEnd(date) {
    return OrderPurchasePeriod.getThisYearStart(date).add(1, 'years');
  }

  /**
   * Gets the start date for the 'next year' purchase period for a given date
   * @param date {moment}
   * @return {moment}
   */
  static getNextYearStart(date) {
    return OrderPurchasePeriod.getThisYearEnd(date);
  }

  /**
   * Gets the end date for the 'next year' purchase period for a given date
   * @param date {moment}
   * @return {moment}
   */
  static getNextYearEnd(date) {
    return OrderPurchasePeriod.getNextYearStart(date).add(1, 'years');
  }

  /**
   * Gets the start date for the 'in two years' purchase period for a given date
   * @param date {moment}
   * @return {moment}
   */
  static getInTwoYearsStart(date) {
    return OrderPurchasePeriod.getNextYearEnd(date);
  }

  /**
   * Gets the end date for the 'in two years' purchase period for a given date
   * @param date {moment}
   * @return {moment}
   */
  static getInTwoYearsEnd(date) {
    return OrderPurchasePeriod.getInTwoYearsStart(date).add(1, 'years');
  }

  /**
   * @param year {number}
   * @returns {moment}
   */
  static createStandardDate(year) {
    return moment(`${year}-07-01`);
  }

  /**
   * Calls the function and/or returns a value of the current pricing stage based on the month of the year
   * @param stage1 {number|string|string[]|moment} July to January
   * @param stage2 {number|string|string[]|moment} February to April
   * @param stage3 {number|string|string[]|moment} May to June
   * @param [date] {moment} The date to use as the basis for determining the current stage of the year
   * @return {number|string|string[]|moment}
   */
  static forStage(stage1, stage2, stage3, date) {

    date = date || moment();

    // Zero indexed month (e.g. January is 0, December is 11)
    let currentMonth = date.month();

    // From July to January
    if ((currentMonth >= 6 && currentMonth <= 11) || currentMonth === 0) {
      return stage1;
    }
    // From February to April
    else if (currentMonth >= 1 && currentMonth <= 3) {
      return stage2;
    }
    // From May to June
    else if (currentMonth >= 4 && currentMonth <= 5) {
      return stage3;
    }
  }

}
