
'use strict';

import { CursorInfo } from './textbox-cursor';
import Point from '../point';
import { SelectionInfo } from './textbox-selection';
import Rect from '../rect';
import StaticService from '../../../services/static/static.service';
import ColorSpanArranger from './textbox-color-span-arranger';
import LineBreaker from 'linebreak';

/**
 * Responsible for arranging text in a textbox
 */
export default class TextboxArranger {
  constructor() {
    /** @type {Array.<TspanMetadata>} */
    this.spanData = [];
    /** @type {string} */
    this.text = '';
    /** @type {Array.<ColorSpan>} */
    this.colors = [];
    /** @type {number} */
    this.lineHeightScale = 1.0;
    this._cachedLineHeight = 14;
    this.lineWidth = 0;
    this.lineData = [];
    this._dirtyLayout = true;
    this._dirtyRender = true;
  }

  /**
   * Renders the given text in a textbox
   *
   * @param location {Point}
   * @param textbox {Snap} The snap textbox element
   * @param text {string}
   * @param lineWidth {number}
   * @param [colors] {Array.<ColorSpan>}
   * @param [fontSize] {number}
   */
  renderText(location, textbox, text, lineWidth, colors, fontSize) {
    if (text.length === 1 &&  this._isCarriageReturn(text)) {
      text = `${text} `;
    }

    if (this._notInDom(textbox.node)) {
      this.invalidateDom();
      return;
    }

    colors = ColorSpanArranger.arrangeColors(colors, text);

    if (this.text !== text ||
      this.lineWidth !== lineWidth ||
      !angular.equals(this.colors, colors) ||
      this.fontSize !== fontSize) {

      this.invalidateLayout();
      this.text = text;
      this.colors = colors;
      this.lineWidth = lineWidth;
      this.fontSize = fontSize;
    }

    if (this._dirtyLayout) {

      // If there's no text, set the textbox to a sample char so we can measure the line height
      if (!text) {
        textbox.attr({text: 'abc'});
        this._cachedLineHeight = textbox.node.getBBox().height * this.lineHeightScale;
      }

      textbox.attr({text: text});

      this.spanData = this._arrangeText(
        textbox.node,
        text,
        lineWidth,
        this._findBoundaries(text),
        this.colors
      );

      this._dirtyLayout = false;
    }

    if (this._dirtyRender) {
      textbox.attr({
        text: this.spanData.map((x) => {
          return x.text;
        })
      });

      this._dirtyRender = false;
    }

    var tspans = textbox.selectAll('tspan');

    for (let i = 0; i < this.spanData.length; i++) {
      this.spanData[i].offset = location;
      this.spanData[i].tspan = tspans[i];
    }
  }

  /**
   * Marks the layout and dom as dirty, will recalculate layout and re-render dom
   * elements next time arrangeText is called.
   */
  invalidateLayout() {
    this._dirtyLayout = true;
    this.invalidateDom();
  }

  /**
   * Marks the dom as dirty, will re-render dom elements next time arrangeText is called.
   */
  invalidateDom() {
    this._dirtyRender = true;
  }

  /**
   *
   * @param textboxDom {SVGTextElement} The textbox dom element
   * @param text {string}
   * @param lineWidth {number}
   * @param wordBreaks {Array.<{index: number, type: string, , character: string}>}
   * @param colors {Array.<ColorSpan>}
   * @returns {Array.<TspanMetadata>}
   * @private
   */
  _arrangeText(textboxDom, text, lineWidth, wordBreaks, colors) {
    this._textBBox = textboxDom.getBBox();
    this._measuredTextHeight = this._textBBox.height;
    var lineHeight = this._measuredTextHeight ? this._measuredTextHeight * this.lineHeightScale : this._cachedLineHeight;
    this._cachedLineHeight = lineHeight;

    this.lineData = this._measureLines([], text, 0, textboxDom, lineWidth, lineHeight, wordBreaks);
    return this._formatLines([], text, textboxDom, this.lineData, colors[0], colors.slice(1));
  }

  /**
   * @param accumulator {Array.<LineMetadata>}
   * @param text {string}
   * @param startIdx {number}
   * @param dom {SVGTextElement}
   * @param lineWidth {number}
   * @param lineHeight {number}
   * @param wordBreaks {Array.<{type: string, idx: number, character: string}>}
   * @returns {Array.<{breaks: Array.<{type: string, idx: number}>, line: LineMetadata}>}
   * @private
   */
  _measureLines(accumulator, text, startIdx, dom, lineWidth, lineHeight, wordBreaks) {

    if (wordBreaks.length === 0) {
      return accumulator;
    }

    var result = this._measureLine(text, startIdx, dom, lineWidth, wordBreaks);

    var location;

    // If there are previous lines
    if (accumulator.length > 0) {
      // Set the new line's location to the previous line's location
      location = accumulator[accumulator.length - 1].location;
    }
    // If this is the first line
    else {
      // Set the line to the top of the words
      let adjustedTextHeight = StaticService.get.isWindows ? this._measuredTextHeight * 0.8 : this._measuredTextHeight;
      location = new Point(this._textBBox.x, adjustedTextHeight - lineHeight);
    }

    result.line.location = new Point(0, location.y + lineHeight);
    result.line.height = lineHeight;

    return this._measureLines(accumulator.concat([result.line]), text, result.line.endIdx, dom, lineWidth, lineHeight, result.breaks);
  }

  /**
   * Measures a single line of text
   *
   * @param text {string} The full text string
   * @param startIdx {number} The string index to start from
   * @param dom {SVGTextElement} A svg text dom element
   * @param lineWidth {number} Width of the wrap area
   * @param breaks {Array.<{type: string, index: number}>} The word breaks
   * @param [prev] {{type: string, index: number}} The previously processed break
   * @returns {{breaks: Array.<{type: string, index: number}>, line: LineMetadata}} description of a line
   * @private
   */
  _measureLine(text, startIdx, dom, lineWidth, breaks, prev) {

    var next = breaks[0];

    var lineStart = this._locationOfChar(dom, text, startIdx);
    var nextWordStart = this._locationOfChar(dom, text, next.index);

    var width = nextWordStart.x - lineStart.x;

    var prevWordEnd = new Point(0, 0);
    if (next.index > 0) {
      if (next.type === 'end') {
        prevWordEnd = this._getEndPositionOfChar(dom, next.index - 1);
      }
      else if (next.type === 'char') {
        prevWordEnd = this._getStartPositionOfChar(dom, next.index);
      }
      else {
        prevWordEnd = this._getStartPositionOfChar(dom, next.index - 1);
      }
    }

    if (prevWordEnd.x - lineStart.x > lineWidth) {
      if (!prev) {
        // overflow

        var wrapIdx = next.index;
        var wrapLocation = nextWordStart;

        // step backwards until we are in bounds
        do {
          wrapIdx--;
          wrapLocation = this._getStartPositionOfChar(dom, wrapIdx);
        } while (wrapLocation.x - lineStart.x > lineWidth);

        // Handle the case where the width is less than 1 character
        // This prevents an infinite loop :(
        if (wrapIdx === startIdx) {
          wrapIdx += 1;
        }

        if (wrapIdx === text.length) {
          wrapLocation = this._getEndPositionOfChar(dom, wrapIdx - 1);
        }
        else {
          wrapLocation = this._getStartPositionOfChar(dom, wrapIdx);
        }

        return {
          breaks: breaks,
          line: new LineMetadata(startIdx, wrapIdx, wrapLocation.x - lineStart.x)
        };
      }
      else {
        // word wrap
        var prevWordStart = this._locationOfChar(dom, text, prev.index);
        return {
          breaks: breaks,
          line: new LineMetadata(startIdx, prev.index, prevWordStart.x - lineStart.x)
        };
      }
    }
    else if (next.type === 'newline') {
      // new line
      return {
        breaks: breaks.slice(1),
        line: new LineMetadata(startIdx, next.index, width)
      };
    }
    else if (next.type === 'end') {
      // string end
      return {
        breaks: [],
        line: new LineMetadata(startIdx, text.length, width)
      };
    }

    return this._measureLine(text, startIdx, dom, lineWidth, breaks.slice(1), next);
  }

  /**
   * Find the position of a character in the text
   *
   * @param dom {SVGTextElement} dom element
   * @param text {string}
   * @param index {number}
   * @returns {Point}
   * @private
   */
  _locationOfChar(dom, text, index) {
    if (text === '') {
      return new Point(0, 0);
    }
    else if (index < text.length) {
      return this._getStartPositionOfChar(dom, index);
    }
    else {
      return this._getEndPositionOfChar(dom, index - 1);
    }
  }

  /**
   *
   * @param accumulator {Array.<TspanMetadata>}
   * @param text {string}
   * @param dom {SVGTextElement}
   * @param lines {Array.<LineMetadata>}
   * @param color {ColorSpan}
   * @param colors {Array.<ColorSpan>}
   * @returns {Array.<TspanMetadata>}
   * @private
   */
  _formatLines(accumulator, text, dom, lines, color, colors) {

    if (lines.length === 0) {
      return accumulator;
    }

    var line = lines[0];
    var lineColors = colors.filter((x) => { return x.index < line.endIdx; });
    var nextColor = color;
    if (lineColors.length > 0) {
      nextColor = lineColors[lineColors.length - 1];
    }

    var spans = this._createSpans([], text, dom, line, line.startIdx, line.location.x, color, lineColors);

    return this._formatLines(accumulator.concat(spans), text, dom, lines.slice(1), nextColor, colors.slice(lineColors.length));
  }

  /**
   *
   * @param accumulator {Array.<TspanMetadata>}
   * @param text {string}
   * @param dom {SVGTextElement}
   * @param line {LineMetadata}
   * @param index {number}
   * @param prevX {number}
   * @param color {ColorSpan}
   * @param colors {Array.<ColorSpan>}
   * @returns {Array.<TspanMetadata>}
   * @private
   */
  _createSpans(accumulator, text, dom, line, index, prevX, color, colors) {

    if (colors.length === 0) {
      return accumulator.concat([
        new TspanMetadata(
          index,
          text.substring(index, line.endIdx),
          color.color,
          new Point(prevX, line.location.y),
          line.width - prevX,
          line.height
        )
      ]);
    }

    var width = 0;
    var spans = [];
    var nextColor = colors[0];
    var nextIndex = Math.min(nextColor.index, line.endIdx);

    if (nextIndex > index) {
      width = this._getStartPositionOfChar(dom, nextIndex).x - this._getStartPositionOfChar(dom, index).x;
      spans.push(new TspanMetadata(
        index,
        text.substring(index, nextIndex),
        color.color,
        new Point(prevX, line.location.y),
        width,
        line.height
        )
      );
    }

    return this._createSpans(accumulator.concat(spans), text, dom, line, nextIndex, prevX + width, nextColor, colors.slice(1));
  }

  _findBoundaries(text) {
    let breaker = new LineBreaker(text);
    let result = [];
    let bk = breaker.nextBreak();

    while (bk) {
      const char = text[bk.position - 1];
      const isSpaceOrTab = this._isSpaceOrTab(char);
      const isNewLine = this._isNewLine(char) || bk.required;

      let index = bk.position;
      let type;

      if (isSpaceOrTab) {
        type = 'space';
      }
      else if (isNewLine) {
        type = 'newline';
      }
      else if (index === text.length) {
        break;
      }
      else {
        type = 'char';
      }

      result.push({ type, index });
      bk = breaker.nextBreak();
    }
    return [...result, {index: text.length, type: 'end'}].sort((a, b) => a.index - b.index);
  }

  _isSpaceOrTab(char) {
    const breakRegex = /[ \t]+/g;
    return !!breakRegex.exec(char);
  }

  _isNewLine(char) {
    const newlineRegex = /\n/g;
    return !!newlineRegex.exec(char);
  }

  /**
   * @param text {string}
   * @returns {boolean}
   * @private
   */
  _isCarriageReturn(text) {
    return text.charCodeAt(0) === 10;
  }

  /**
   * @param point {Point} relative point within the textbox
   * @returns {CursorInfo}
   */
  cursorForPoint(point) {
    if (this.text.length === 0) {
      return this.defaultCursor();
    }

    var spanData = this.spanData[this.spanData.length - 1];
    for (let j = 0; j < this.spanData.length; j++) {
      var span = this.spanData[j];

      if (point.y >= (span.location.y - span.height)
        && point.y <= span.location.y) {

        spanData = span;

        if (point.x >= span.location.x && point.x < (span.location.x + span.width)) {
          break;
        }
      }
    }

    var cursorX = spanData.location.x + spanData.width;
    var minDistance = Math.abs(cursorX - point.x);
    var before = false;
    var index = spanData.startIdx + spanData.text.length - 1;
    var endsWithANewLine = this.spanEndsWithNewLineCharacter(spanData);
    var x = 0;

    if (endsWithANewLine && point.x > cursorX) {
      return this.cursorForIndex(index, true);
    }

    if (point.y < spanData.location.y) {
      for (let i = 0; i < spanData.text.length; i++) {
        if (spanData.text.length > 1) {
          x = this._getStartPositionOfChar(spanData.tspan.node, i).x - spanData.offset.x;
        }
        var distance = Math.abs(x - point.x);
        if (distance < minDistance) {
          minDistance = distance;
          cursorX = x;
          before = true;
          index = i + spanData.startIdx;
        }
      }
    }

    return new CursorInfo(
      new Point(cursorX, spanData.location.y - spanData.height + this.spaceBetweenTopOfRowAndTopOfText),
      index,
      before,
      spanData.height,
      point
    );
  }

  /**
   * @param index {number}
   * @param [before] {boolean}
   * @returns {CursorInfo}
   */
  cursorForIndex(index, before) {
    if (this.text.length === 0) {
      return this.defaultCursor();
    }

    var span = this.spanData.find((x) => x.containsIndex(before ? index : index - 1));
    if (!span || (index >= this.text.length - 1 && !before)) {
      span = this.spanData[this.spanData.length - 1];
    }

    var location;
    var charPos;
    var endsWithANewLine = this.spanEndsWithNewLineCharacter(span);
    if (endsWithANewLine && (index - span.startIdx < 1 || span.text.length <= 1)) {
      location = new Point(span.location.x, span.location.y - span.height + this.spaceBetweenTopOfRowAndTopOfText);
      before = true;
    }
    else if (index >= this.text.length) {
      location = new Point(span.location.x + span.width, span.location.y - span.height  + this.spaceBetweenTopOfRowAndTopOfText);
      index = this.text.length - 1;
      before = false;
    }
    else if (before) {
      charPos = this._getStartPositionOfChar(span.tspan.node, index - span.startIdx);
      location = new Point(charPos.x - span.offset.x, span.location.y - span.height + this.spaceBetweenTopOfRowAndTopOfText);
    }
    else if (index - span.startIdx < span.text.length - 1){
      charPos = this._getStartPositionOfChar(span.tspan.node, index - span.startIdx + 1);
      location = new Point(charPos.x - span.offset.x, span.location.y - span.height + this.spaceBetweenTopOfRowAndTopOfText);
    }
    else {
      location = new Point(span.location.x + span.width, span.location.y - span.height + this.spaceBetweenTopOfRowAndTopOfText);
    }

    return new CursorInfo(
      location,
      index,
      !!before,
      span.height,
      index
    );
  }

  /**
   * @return {CursorInfo}
   */
  defaultCursor() {
    return new CursorInfo(
      new Point(0, 0),
      0,
      true,
      this._cachedLineHeight,
      0
    );
  }

  /**
   * @param span {TSpanMetadata}
   * @return {Bool}
   */
  spanEndsWithNewLineCharacter(span) {
    return span.text.charCodeAt([span.text.length - 1]) === 10;
  }

  /**
   * @param startPoint {Point}
   * @param endPoint {Point}
   */
  selectionForPoints(startPoint, endPoint) {
    var cursor1 = this.cursorForPoint(startPoint);
    var cursor2 = this.cursorForPoint(endPoint);

    return this.selectionForCursors(cursor1, cursor2);
  }

  selectionForIndices(startIndex, beforeStart, endIndex, beforeEnd) {

    var cursor1 = this.cursorForIndex(startIndex, beforeStart);
    var cursor2 = this.cursorForIndex(endIndex, beforeEnd);

    return this.selectionForCursors(cursor1, cursor2);
  }

  selectionForWordAtCursor(cursor) {

    var index = cursor.textIndex;
    var startIndex = index;
    var endIndex = index;
    var beforeEnd = true;

    var boundRegex = /[a-zA-Z\d_]/;
    for (let i = index - 1; i >= -1; i--) {
      if (i < 0 || !this.text[i].match(boundRegex)) {
        startIndex = i + 1;
        break;
      }
    }

    for (let i = index; i <= this.text.length; i++) {
      if (i === this.text.length) {
        endIndex = i - 1;
        beforeEnd = false;
      }
      else if (!this.text[i].match(boundRegex)) {
        endIndex = i;
        break;
      }
    }

    return this.selectionForIndices(Math.min(startIndex, index), true, Math.max(endIndex, index), beforeEnd);
  }

  selectionForCursors(cursor1, cursor2) {
    var idx1 = cursor1.before ? cursor1.index : cursor1.index + 1;
    var idx2 = cursor2.before ? cursor2.index : cursor2.index + 1;

    var boxes = [];
    var state = {};

    if (idx1 < idx2) {
      state.start = cursor1;
      state.startIndex = idx1;
      state.end = cursor2;
      state.endIndex = idx2;
    }
    else if (idx1 > idx2) {
      state.end = cursor1;
      state.endIndex = idx1;
      state.start = cursor2;
      state.startIndex = idx2;
    }
    else {
      return new SelectionInfo(
        cursor1,
        cursor2,
        idx1,
        idx2,
        boxes,
        cursor2
      );
    }

    var started = false;
    for (let i = 0; i < this.lineData.length; i++) {
      let line = this.lineData[i];

      if (line.endIdx > state.startIndex) {
        started = true;
      }

      if (started) {
        var location = line.location.minus(new Point(0, line.height - this.spaceBetweenTopOfRowAndTopOfText));
        var width = line.width;
        var height = line.height;

        if (line.startIdx < state.startIndex && state.start.location.y.toFixed(2) === location.y.toFixed(2)) {
          location = state.start.location;
          width = width - (state.start.location.x - line.location.x);
        }
        if (state.endIndex < line.endIdx) {
          width = state.end.location.x - location.x;
        }

        boxes.push(new Rect(
          location.x,
          location.y,
          width,
          height
        ));
      }

      if (state.endIndex <= line.endIdx) {
        break;
      }
    }

    return new SelectionInfo(
      state.start,
      state.end,
      state.startIndex,
      state.endIndex,
      boxes,
      cursor2
    );
  }

  /**
   *
   * @return {number}
   */
  get spaceBetweenTopOfRowAndTopOfText() {
    return 0.15 * this._cachedLineHeight;
  }

  /**
   * @param dom {SVGTextElement}
   * @param index {int}
   * @returns {Point}
   * @private
   */
  _getStartPositionOfChar(dom, index) {
    try {
      return dom.getStartPositionOfChar(index);
    }
    catch (err) {
      StaticService.get.$log.warn(err, this.text, dom, index);
      return new Point(0, 0);
    }
  }

  /**
   * @param dom {SVGTextElement}
   * @param index {int}
   * @returns {Point}
   * @private
   */
  _getEndPositionOfChar(dom, index) {
    try {
      return dom.getEndPositionOfChar(index);
    }
    catch (err) {
      StaticService.get.$log.warn(err, this.text, dom, index);
      return new Point(0, 0);
    }
  }

  /**
   * @param dom {SVGTextElement}
   * @returns {boolean}
   * @private
   */
  _notInDom(dom) {
    return !StaticService.get.$document[0].body.contains(dom);
  }
}


class LineMetadata {

  /**
   * @param startIdx {number}
   * @param endIdx {number}
   * @param width {number}
   * @param [height] {number}
   * @param [location] {Point}
   */
  constructor(startIdx, endIdx, width, height, location) {
    this.startIdx = startIdx;
    this.endIdx = endIdx;
    this.width = width;
    this.height = height;
    this.location = location;
  }

  containsIndex(index) {
    return index >= this.startIdx && index < this.endIdx;
  }
}


class TspanMetadata {

  /**
   * @param startIdx {number} The spans start index within the whole string
   * @param text {string} The substring contained in this span
   * @param color {string} The color of the text
   * @param location {Point} The offset of the span
   * @param width {number} The width
   * @param height {number} The height
   * @param [tspan] {Snap} The Snap tspan element
   */
  constructor(startIdx, text, color, location, width, height, tspan) {
    if (!text) {
      text = '';
    }

    this.startIdx = startIdx;
    this.text = text;
    this.color = color;
    this.location = location;
    this.width = width;
    this.height = height;
    this.tspan = tspan;
    this.offset = new Point(0, 0);
  }

  /**
   * @returns {Snap}
   */
  get tspan() {
    return this._tspan;
  }

  /**
   * @param value {Snap}
   */
  set tspan(value) {
    this._tspan = value;
    if (this._tspan) {
      this._tspan.addClass('noselect');
      this._tspan.attr({
        x: this.location.x + this.offset.x,
        y: this.location.y + this.offset.y,
        fill: this.color,
        pointerEvents: 'none'
      });
    }
  }

  /**
   * @param index {number}
   * @returns {boolean}
   */
  containsIndex(index) {
    return index >= this.startIdx && index < this.startIdx + this.text.length;
  }
}
