export default class Class {

  /**
   * @param id {string}
   * @param courseId {string}
   * @param name {string}
   *
   */
  constructor(
    id,
    courseId,
    name
  ) {
    this._id = id;
    this._courseId = courseId;
    this._name = name;
  }

  get id (){
    return this._id;
  }

  get courseId (){
    return this._courseId;
  }

  get name (){
    return this._name;
  }
}
