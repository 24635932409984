
'use strict';

import Codec from './codec';
import AssignmentWork from '../domain/assignment-work';
import AssignmentWorkQuestion from '../domain/assignment-work-question';
import ElementList from '../domain/element-list';
import ChatMessageList from '../domain/chat-message-list';

/**
 * Encodes/Decodes an assignment work
 */
export default class AssignmentWorkCodec extends Codec {

  constructor(FirebaseService) {
    super();
    this._firebaseService = FirebaseService;
  }

  encode(value) {

    let questions = {};

    value.questions.forEach((question, questionId) => {
      questions[questionId] = {
        element_list_id: question.elementListId,
        element_list_db: question.elementListDb,
        chat_id: question.chatId,
        scored_points: question.points,
        started_at: question.startedAt && question.startedAt.toISOString()
      };
    });

    return {
      id: value.id,
      owner_id: value.ownerId,
      roster_id: value.rosterId,
      questions: questions
    };
  }

  /**
   * @param assignment {Assignment}
   * @param value {object}
   * @returns {AssignmentWork}
   */
  decode(assignment, value) {

    let questions = new Map();

    angular.forEach(value.question_works, (question, questionId) => {
      questions.set(
        questionId,
        new AssignmentWorkQuestion(
          questionId,
          new ElementList(question.element_list_id, question.element_list_db, this._firebaseService, 'AssignmentWorkQuestionElementList'),
          new ChatMessageList(question.chat_id, question.element_list_db, this._firebaseService),
          question.scored_points,
          question.answer,
          question.started_at
        )
      );
    });

    return new AssignmentWork(
      assignment,
      value.id,
      value.owner_id,
      value.roster_id,
      questions,
      value.created,
      value.modified
    );
  }
}
