export default class OrganizationUsageStats {
  constructor(
    totalTeachers,
    totalAssignments,
    totalStudents,
    totalHelpRequestsResolved,
    activeTeachers,
    teachersActiveThisYear,
    studentsActiveThisYear,
    assignmentsCreatedThisYear,
    assignmentsWorkedOnThisYear,
    assignmentQuestionsWorkedOnThisYear,
    rostersCreatedThisYear,
    helpRequestsResolvedThisYear,
  ) {
    this._totalTeachers = totalTeachers;
    this._totalAssignments = totalAssignments;
    this._totalStudents = totalStudents;
    this._totalHelpRequestsResolved = totalHelpRequestsResolved;
    this._activeTeachers = activeTeachers;
    this._teachersActiveThisYear = teachersActiveThisYear;
    this._studentsActiveThisYear = studentsActiveThisYear;
    this._assignmentsCreatedThisYear = assignmentsCreatedThisYear;
    this._assignmentsWorkedOnThisYear = assignmentsWorkedOnThisYear;
    this._assignmentQuestionsWorkedOnThisYear = assignmentQuestionsWorkedOnThisYear;
    this._rostersCreatedThisYear = rostersCreatedThisYear;
    this._helpRequestsResolvedThisYear = helpRequestsResolvedThisYear;
  }

  get totalTeachers() {
    return this._totalTeachers;
  }

  get totalAssignments() {
    return this._totalAssignments;
  }

  get totalStudents() {
    return this._totalStudents;
  }

  get totalHelpRequestsResolved() {
    return this._totalHelpRequestsResolved;
  }

  get activeTeachers() {
    return this._activeTeachers;
  }

  get teachersActiveThisYear() {
    return this._teachersActiveThisYear;
  }

  get studentsActiveThisYear() {
    return this._studentsActiveThisYear;
  }

  get assignmentsCreatedThisYear() {
    return this._assignmentsCreatedThisYear;
  }

  get assignmentsWorkedOnThisYear() {
    return this._assignmentsWorkedOnThisYear;
  }

  get assignmentQuestionsWorkedOnThisYear() {
    return this._assignmentQuestionsWorkedOnThisYear;
  }

  get pagesWorkedOnThisYear() {
    if (this._assignmentQuestionsWorkedOnThisYear) {
      return (this._assignmentQuestionsWorkedOnThisYear);
    } else {
      return undefined;
    }
  }

  get approximateSavings() {
    if (this._assignmentsWorkedOnThisYear) {
      return ((this._assignmentsWorkedOnThisYear * 5 * 29 * .08).toFixed());
    } else {
      return undefined;
    }
  }

  get rostersCreatedThisYear() {
    return this._rostersCreatedThisYear;
  }

  get helpRequestsResolvedThisYear() {
    return this._helpRequestsResolvedThisYear;
  }
}


