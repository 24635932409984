'use strict';

import moment from 'moment';

export default class ChatMessageRead {

  constructor(id, messageId, timestamp) {
    this._id = id;
    this._messageId = messageId;
    this._timestamp = angular.isDefined(timestamp) ? moment(timestamp) : undefined;
  }

  /**
   * The user that read a chat
   * @return {string}
   */
  get id() {
    return this._id;
  }

  /**
   * The most recent message the user has read
   * @return {string}
   */
  get messageId() {
    return this._messageId;
  }

  /**
   * The time when they read the most recent message
   * @return {undefined|moment}
   */
  get timestamp() {
    return this._timestamp;
  }

}
