

export class ZoomModes {

  static get FIXED() {
    return 'zoom_fixed';
  }

  static get NO_GROW() {
    return 'zoom_no_grow';
  }

  static get TO_FIT() {
    return 'zoom_to_fit';
  }

}

export class ZoomConstants {

  static get UPPER_LIMIT() {
    return 5;
  }

  static get LOWER_LIMIT() {
    return 0.25;
  }

  static get INCREMENT() {
    return 0.25;
  }

}

export default class ZoomManager {

  /**
   * @param sideNavUpdated {JSEvent}
   * @param $timeout
   */
  constructor(sideNavUpdated, $timeout) {
    this._factor = 1;
    this.$timeout = $timeout;
    sideNavUpdated.subscribe(this._handleSidenavUpdated, this);
  }

  _handleSidenavUpdated(ev) {

    let newState = ev.newState;
    let oldState = ev.oldState;

    if (this._svgSheet && newState.isOpen !== oldState.isOpen) {
      // TODO investigate if this is not working properly when opening sidenav
      this.$timeout(() => this.mode = this.mode || ZoomModes.NO_GROW, 500, false);
      this.$timeout(() => this.mode = this.mode || ZoomModes.NO_GROW, 1000, false);
    }
  }

  set svgSheet(value) {
    this._svgSheet = value;
    this.mode = this.mode || ZoomModes.NO_GROW;
  }

  get mode() {
    return this._mode;
  }

  set mode(value) {
    this._mode = value;
    this._factor = this._determineFactor();
    this._svgSheet.zoom(this._factor);
  }

  get factor() {
    return this._factor;
  }

  increment() {
    this.setFactor(this.factor + ZoomConstants.INCREMENT);
  }

  decrement() {
    this.setFactor(this.factor - ZoomConstants.INCREMENT);
  }

  set factor(value) {
    if (value > ZoomConstants.UPPER_LIMIT) {
      value = ZoomConstants.UPPER_LIMIT;
    } else if (value < ZoomConstants.LOWER_LIMIT) {
      value = ZoomConstants.LOWER_LIMIT;
    }

    this._factor = value;
  }

  setFactor(value) {
    this.factor = value;
    this.mode = ZoomModes.FIXED;
  }

  get percentage() {
    return `${(Math.floor(this._factor * 100))}%`;
  }

  _determineFactor() {
    if (this._mode === ZoomModes.FIXED) {
      return this._factor;
    }
    else if (this._mode === ZoomModes.TO_FIT) {
      return this._svgSheet.toFitFactor;
    }
    else if (this._mode === ZoomModes.NO_GROW) {
      return this._svgSheet.noGrowFactor;
    }
  }

}
