'use strict';

import ObservableCollection from './observable-collection';

/**
 * Represents a collection rooted in Firebase
 */
export default class FirebaseCollection extends ObservableCollection {
  /**
   * @param [merge] {function(object, object)} Function which merges the second parameter into the first parameter
   */
  constructor(merge) {
    super();
    // Ordered array of ids
    this._idArray = [];
    // map of id -> object
    this._objectMap = new Map();

    this._merge = merge;
  }

  /**
   * returns the value at the given integer index
   * @param idx {number}
   * @returns {object}
   */
  valueAtIndex(idx) {
    return this._objectMap.get(this._idArray[idx]);
  }

  /**
   * returns the value for the given id, or undefined if none match
   * @param id {string}
   * @returns {object}
   */
  valueForId(id) {
    if (this._objectMap.has(id)) {
      return this._objectMap.get(id);
    }
    return undefined;
  }

  /**
   * returns the index of the object with an id matching the given value, or -1 if none
   * @param value {object} must have a property named `id`
   * @returns {number}
   */
  indexOf(value) {
    for (let i = 0; i < this._idArray.length; i++) {
      if (this._idArray[i] === value.id) {
        return i;
      }
    }
    return -1;
  }

  /**
   * @param id {string}
   * @returns {boolean}
   */
  has(id) {
    return this._objectMap.has(id);
  }

  /**
   * returns the number of objects in this collection
   * @returns {number}
   */
  get size() {
    return this._objectMap.size;
  }

  /**
   * iterates this collection. callback may return true to short circuit the iteration
   * @param callback {function(value, id)} calls the callback with each child of the collection in order
   */
  forEach(callback) {
    for (let i = 0; i < this._idArray.length; i++) {
      let child = this._objectMap.get(this._idArray[i]);
      var result = callback(child, child.id, i);

      // Provide short-circuit mechanism
      if (result === true) {
        return;
      }
    }
  }

  /**
   * @param callback {Function}
   * @returns {Array}
   */
  map(callback) {
    let result = [];
    callback = callback || ((child) =>  child);
    this.forEach((child, childId) => {
      result.push(callback(child, childId));
    });
    return result;
  }

  /**
   * @param callback {Function}
   * @return {Array}
   */
  filter(callback) {
    let result = [];
    this.forEach((child, childId) => {
      if (callback(child, childId)) {
        result.push(child);
      }
    });
    return result;
  }

  /**
   * @param callback {Function}
   * @return {Element}
   */
  find(callback) {
    let result = this.filter(callback);
    return result[0];
  }

  /**
   * A least one item in the list satisfies the predicate condition
   * @param predicate {Function}
   * @return {boolean}
   */
  some(predicate) {
    let result = false;
    this.forEach((child, childId) => {
      return result = predicate(child, childId);
    });
    return result;
  }

  /**
   * Called by FirebaseCollectionMapping when a child is added
   * @param child {object}
   * @param prevChildKey {string}
   * @returns {FirebaseCollection}
   * @protected
   */
  _remoteAdd(child /*, prevChildKey*/) {
    // TODO fix ordering using prevChildKey
    FirebaseCollection.verify(child);
    if (!this._objectMap.has(child.id)) {
      this._idArray.push(child.id);
      this._objectMap.set(child.id, child);

      this._onAdded(child);
    }

    return this;
  }

  /**
   * Called by FirebaseCollectionMapping when a child is removed
   * @param child {object}
   * @returns {FirebaseCollection}
   * @protected
   */
  _remoteRemove(child) {
    FirebaseCollection.verify(child);
    if (this._objectMap.has(child.id)) {
      let oldChild = this._objectMap.get(child.id);

      let index = this.indexOf(child);
      this._idArray.splice(index, 1);
      this._objectMap.delete(child.id);

      this._onRemoved(oldChild);
    }
    return this;
  }

  /**
   * Called by FirebaseCollectionMapping when a child is updated
   * @param child {object}
   * @param prevChildKey {string}
   * @returns {FirebaseCollection}
   * @protected
   */
  _remoteChange(child /*, prevChildKey*/) {
    // TODO fix ordering using prevChildKey
    FirebaseCollection.verify(child);
    if (this._objectMap.has(child.id)) {
      let oldChild = this._objectMap.get(child.id);

      if (this._merge) {
        this._merge(oldChild, child);
        this._onChanged(oldChild, oldChild);
      }
      else {
        this._objectMap.set(child.id, child);
        this._onChanged(child, oldChild);
      }
    }

    return this;
  }

  static verify(child) {
    if (angular.isUndefined(child.id)) {
      throw Error('Children must have a member .id');
    }
  }
}
