'use strict';

import ErrorDialogController from '../error-dialog/error-dialog.controller';
import Validation from '../../model/util/validation';
import InviteCoTeacherDialogTemplate from './invite-co-teacher-dialog.html';

export class InviteCoTeacherDialogStates {
  static get INVITE_TEACHER(){
    return 'invite-teacher';
  }

  static get LOADING() {
    return 'loading';
  }
}

export default class InviteCoTeacherDialogController {

  constructor($mdDialog, $mdToast,  AnalyticsService, CoTeacherService, rosterId) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {CoTeacherService} */
    this._coTeacherService = CoTeacherService;

    this._errorDialog = ErrorDialogController.show;

    this.emailRegex = Validation.EmailRegex;

    this.rosterId = rosterId;
    this.state = InviteCoTeacherDialogStates.INVITE_TEACHER;
    this.email = '';
    this.error = false;
  }

  /**
   * show the dialog
   * @param  {$mdDialog}  $mdDialog
   * @param  {assignment} assignment
   * @param  {string} rosterId
   * @return {Promise}
   */
  static show($mdDialog, rosterId) {

    let config = {
      controller: InviteCoTeacherDialogController,
      template: InviteCoTeacherDialogTemplate,
      controllerAs: 'ctrl',
      autoWrap: false,
      clickOutsideToClose: true,
      focusOnOpen: false,
      locals: {
        rosterId
      }
    };

    return $mdDialog.show(config);
  }

  /**
   * tests whether email is valid
   * @return {Boolean} whether the email is valid or not
   */
  get isEmailValid() {
    return this.emailRegex.test(this.email.trim());
  }

  get isInviteTeacher() {
    return this.state === InviteCoTeacherDialogStates.INVITE_TEACHER;
  }

  get isLoading() {
    return this.state === InviteCoTeacherDialogStates.LOADING;
  }

  /**
   * set validity of email input
   */
  _displayToastMessage(message) {
    this._toastMessage = message;
    let toastClassName = 'referral-toast';
    const config = this.$mdToast.simple(this._toastMessage).action(' ').actionKey('x').position('top right').toastClass(toastClassName).hideDelay(4000);
    this.$mdToast.show(config);
  }

  /**
   * close the dialog. rejects the promise returned from the show method
   */
  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * set validity of email input
   * @return {Boolean}
   */
  setEmailValidityForCoTeacher(scope) {
    scope.emailInviteCoTeacherForm.email.$setValidity('pattern', this.isEmailValid);
  }

  /**
   * Sends email invite to teacher
   */
  sendEmailToCoTeacher(){
    if (this.isEmailValid) {
      this._analyticsService.inviteCoTeacher(this.rosterId);
      this._coTeacherService.inviteCoTeacher(this.rosterId, this.email.trim().toLowerCase())
        .then(() => {
          this.$mdDialog.hide();
          this._displayToastMessage('Your invite has been sent!');
        })
        .catch((error) => {
          let errorMessage = 'Please try again later or send an email to support@classkick.com';
          switch (error.message) {
            case 'Found an account with the email address but they are not a teacher.':
              errorMessage = 'You entered an email that cannot be invited to co-teach. Please try a different email.';
              break;
            case 'Cannot assign self as coteacher to owned roster.':
              errorMessage = 'You cannot invite yourself as a co-teacher';
              break;
          }
          this._errorDialog(this.$mdDialog, 'Uh oh! An error occurred', errorMessage);
      });
    }
  }



}
