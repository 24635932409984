'use strict';

import Snap from 'snapsvg-cjs';
import GestureManager from './gesture-manager';
import Point from './point';
import Debouncer from '../util/debouncer';
import SvgSheetLayers from './svg-sheet-layers';
import SvgCanvas from './svg-canvas';

export default class SvgSheet extends SvgCanvas {

  constructor(element, $timeout, $window, FocusManagerService, ToolbarService) {
    super();

    this.$timeout = $timeout;
    this._window = angular.element($window);

    /** @type {FocusManagerService} */
    this._focusManager = FocusManagerService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;

    this._width = undefined;
    this._hiddenTextInput = undefined;
    this._svg = element.find('svg')[0];
    this._snap = new Snap(this._svg);
    this._layers = new SvgSheetLayers(this._snap);
    this._element = element;
    this._zoomFactor = undefined;
    this._initialWidth = element.width();
    this._initialHeight = element.height();
    this._pinchState = {};

    this._scaleFactor = 1;
    this._inverseScaleFactor = 1;
    this._absolutePosition = new Point(0, 0);
    this._scrollViewAbsolutePosition = new Point(0, 0);

    /*
      When the SvgSheet is loaded initially, we want to set the width, scale factors, and the position

      When the SvgSheet is resized, we want to reset the width, scale factors and position

      When the SvgSheet is scrolled, we want to reset just the position
    */

    this.refreshLayout();

    this._resize = new Debouncer(50, 750, () => {
      this.width = element.width();
      this.absolutePosition = element.offset();
      this._initialHeight = element.height();
      this._initialWidth = element.width();

      if (this._scrollView) {
        this.scrollViewAbsolutePosition = this._scrollView.offset();
      }
    });

    this._anonResize = () => {
      this._resize.tick();
    };
    this._window.on('resize', this._anonResize);

    this._scroll = new Debouncer(50, 750, () => {
      this.absolutePosition = element.offset();
    });

    this._anonScrollListener = () => this._onContainerScrolled();
    this._anonPinchStart = (event) => this.pinchStart(event);
    this._anonPinchMove = (event) => this.pinchMove(event);
    this._anonPinchEnd = (event) => this.pinchEnd(event);
    this._gesture = new GestureManager(undefined, this);

    this._element.on('touchstart', this._anonPinchStart);
  }

  set readonly(value) {
    if (!value) {
      this._gesture.start(this._element);
      this._gesture.click.subscribe(this.handleFallThrough, this);
    }
    else {
      this._gesture.stop();
      this._gesture.click.unsubscribe(this.handleFallThrough, this);
    }
  }

  _onContainerScrolled() {
    this._scroll.tick();
  }

  refreshLayout() {
    this.$timeout(() => {
      this.width = this._element.width();
      this.absolutePosition = this._element.offset();
    }, 0, false);
  }

  showSheet() {
    this._element.css({
      visibility: 'inherit'
    });
  }

  get toFitFactor() {
    return this.scrollViewWidthFactor;
  }

  get noGrowFactor() {
    let scrollViewWidthFactor = this.scrollViewWidthFactor;
    return Math.min(scrollViewWidthFactor - 0.05, 1);
  }

  get initialWidthFactor() {
    return this._initialWidth / SvgCanvas.INITIAL_WIDTH;
  }

  get scrollViewWidthFactor() {
    return this._scrollView ? this._scrollView.width() / SvgCanvas.INITIAL_WIDTH : 1;
  }

  /**
   * Zooms the canvas to the specified zoom factor
   * @param zoomFactor
   */
  zoom(zoomFactor) {
    if (this._initialHeight && this._initialWidth && this._scrollView) {
      let prevHeight = this._element.height();
      let prevWidth = this._element.width();
      let newHeight = SvgCanvas.INITIAL_HEIGHT * zoomFactor;
      let newWidth = SvgCanvas.INITIAL_WIDTH * zoomFactor;
      let heightDelta = Math.abs(newHeight - this._scrollView.height());
      let widthDelta = Math.abs(newWidth - this._scrollView.width());

      // Find the percent scroll and use that to decide how much of the change in width and height should be used in scrolling
      let scrollXProportion = this._scrollView.scrollLeft() / (prevWidth - this._scrollView.width());
      let scrollYProportion = this._scrollView.scrollTop() / (prevHeight - this._scrollView.height());

      if (isNaN(scrollXProportion) || (prevWidth <= this._scrollView.width() && scrollXProportion <= 0)) {
        scrollXProportion = 0.5;
      }

      this._zoomFactor = zoomFactor;

      this._element.css({
        height: newHeight,
        width: newWidth
      });

      this._scrollView.scrollTop(heightDelta * scrollYProportion);
      this._scrollView.scrollLeft(widthDelta * scrollXProportion);

      this.refreshLayout();
    }
  }

  pinchStart(event) {
    if (event.originalEvent.touches && event.originalEvent.touches.length >= 2) {
      event.preventDefault();
      event.stopPropagation();
      this.savePinchState(event);
      this._element.on('touchmove', this._anonPinchMove);
      this._element.on('touchend', this._anonPinchEnd);
    }
  }

  pinchMove(event) {
    let touches = event.originalEvent.touches;

    if (this._pinchState.touches && this._pinchState.touches.length > 1 && touches.length > 1) {
      let width = this._pinchState.svgWidth;
      let height = this._pinchState.svgHeight;
      let a = this._pinchState.touches[0].clientX - this._pinchState.touches[1].clientX;
      a = a * a;
      let b = this._pinchState.touches[0].clientY - this._pinchState.touches[1].clientY;
      b = b * b;
      let dist1 = Math.sqrt(a + b);

      a = touches[0].clientX - touches[1].clientX;
      a = a * a;
      b = touches[0].clientY - touches[1].clientY;
      b = b * b;
      let dist2 = Math.sqrt(a + b);

      let ratio = dist2 / dist1;

      width = width * ratio;
      height = height * ratio;

      let zoomFactor = Math.min((width / SvgCanvas.INITIAL_WIDTH), (height / SvgCanvas.INITIAL_HEIGHT));
      this._toolbarService.zoomFactor = zoomFactor;
    }

  }

  pinchEnd(event) {
    event.preventDefault();
    this._element.off('touchend', this._anonPinchEnd);
    this._element.off('touchmove', this._anonPinchMove);
  }

  savePinchState(event) {
    this._pinchState.svgWidth = this._element.width();
    this._pinchState.svgHeight = this._element.height();
    this._pinchState.svgLeft = this._element.offset().left;
    this._pinchState.svgTop = this._element.offset().top;
    this._pinchState.x = (event.clientX || event.pageX);
    this._pinchState.y = (event.clientY || event.pageY);

    if (angular.isDefined(event.originalEvent.touches)){
      this._pinchState.touches = event.originalEvent.touches;
    }
    this._pinchState.event = event;
  }

  handleFallThrough() {
    this._focusManager.focusedElement = undefined;
  }

  /**
   * @return {SvgSheetLayers}
   */
  get layers() {
    return this._layers;
  }

  get width() {
    return this._width;
  }

  set width(value) {
    this._width = value > 0 ? value : this._width;
    this._scaleFactor =  this._width / SvgCanvas.INITIAL_WIDTH;
    this._inverseScaleFactor = SvgCanvas.INITIAL_WIDTH / this._width;
  }

  get scaleFactor() {
    return this._scaleFactor;
  }

  get inverseScaleFactor() {
    return this._inverseScaleFactor;
  }

  get absolutePosition() {
    return this._absolutePosition;
  }

  set absolutePosition(position) {
    this._absolutePosition = new Point(position.left, position.top);
  }

  get scrollViewAbsolutePosition() {
    return this._scrollViewAbsolutePosition;
  }

  set scrollViewAbsolutePosition(position) {
    this._scrollViewAbsolutePosition = new Point(position.left, position.top);
  }

  set scrollViewSelector(value) {
    if (this._scrollView) {
      this._scrollView.off('scroll', this._anonScrollListener);
      this._scrollView = undefined;
    }

    this._scrollViewSelector = value;

    if (this._scrollViewSelector) {
      this._scrollView = angular.element(this._scrollViewSelector);
      this.scrollViewAbsolutePosition = this._scrollView.offset();
      this._scrollView.on('scroll', this._anonScrollListener);
    }
  }

  destroy() {
    this._resize.destroy();
    this._scroll.destroy();
    this.scrollViewSelector = undefined;
    this._gesture.stop();
    this._window.off('resize', this._anonResize);
  }

  /**
   * @param position {Point}
   * @returns {Point}
   */
  scrollOffset(position) {
    return position.minus(new Point(this.absolutePosition.x, this.absolutePosition.y));
  }

  /**
   * Gives the origin of the canvas in device pixels
   * @returns {Point}
   */
  get canvasOrigin() {
    let origin = this.absolutePosition.minus(this.scrollViewAbsolutePosition);
    return new Point(Math.min(origin.x, 0), Math.min(origin.y, 0));
  }

  /**
   * Gives the origin of the canvas in canvas pixels
   * @returns {Point}
   */
  get scaledCanvasOrigin() {
    return this.canvasOrigin.times(this.inverseScaleFactor);
  }

  elementOffset(position) {
    return position.times(Math.min(1, this.inverseScaleFactor)).minus(this.scaledCanvasOrigin);
  }

  /**
   * @param position {Point}
   * @returns {Point}
   */
  scale(position) {
    return position.times(this.scaleFactor);
  }

  /**
   * @param position {Point}
   * @returns {Point}
   */
  inverseScale(position) {
    return position.times(this.inverseScaleFactor);
  }

  get hiddenTextInput() {
    if (!this._hiddenTextInput) {
      this._hiddenTextInput = this._element.find('.hidden-text-input');
    }
    return this._hiddenTextInput;
  }
}
