'use strict';

import LazyVar from '../../model/util/lazy-var';
import {UserTokenInfo} from '../auth/auth.service';
import HelpRequestCodec from '../../model/codec/help-request-codec';
import CkRedirectCodec from '../../model/codec/ck-redirect-codec';
import JSEvent from '../../model/util/js-event';
import CopyAssignmentRequestCodec from '../../model/codec/copy-assignment-request-codec';
import CopyQuestionsRequestCodec from '../../model/codec/copy-questions-request-codec';

export default class StorageService {

  static get RememberMe() {
    return 'persist_info';
  }

  static get UserInfo() {
    return 'user_info';
  }

  static get CoTeacherInfo() {
    return 'co_teacher_info';
  }

  static get AssignmentLibraryQuery() {
    return 'assignment_library_query';
  }

  static get AssignmentLibrarySelection() {
    return 'assignment_library_selection';
  }

  static get AssignmentLibraryGradeRange() {
    return 'assignment_library_grade_range';
  }

  static get AssignmentLibrarySubject() {
    return 'assignment_library_subject';
  }

  static get AssignmentDashboardQuery() {
    return 'assignment_dashboard_query';
  }

  static get HasRecommendedBroswer() {
    return 'has_recommended_browser';
  }

  static get HelpRequest() {
    return 'help_request';
  }

  static get CkRedirect() {
    return 'ck_redirect';
  }

  static get StudentFolderStatus() {
    return 'ck_student_folder_status';
  }

  static get SessionSort() {
    return 'session_sort';
  }

  static get HideUnstartedThumbnails() {
    return 'hide_unstarted_thumbnails';
  }

  static get HideOfflineStudents() {
    return 'hide_offline_students';
  }

  static get HideUnstartedAssignmentWorks() {
    return 'hide_unstarted_assignment_works';
  }

  static get HideStudentNames() {
    return 'hide_student_names';
  }

  static get AssignmentsListColumn() {
    return 'assignments_list_column';
  }

  static get AssignmentsListAsc() {
    return 'assignments_list_asc';
  }

  static get CopyAssignmentRequest() {
    return 'copy_assignment_request';
  }

  static get CopyQuestionsRequest() {
    return 'copy_questions_request';
  }

  static get SelectedUsersAdminDashboard() {
    return 'selected_users_admin_dashboard';
  }

  static get SkipMdrForNow() {
    return 'skip_mdr_for_now';
  }

  static get utmCampaignId() {
    return 'utm_campaign_id';
  }

  static get StudentOverviewSortingProperty() {
    return 'student_overview_sorting_property';
  }

  static get StudentOverviewQuery() {
    return 'student_overview_query';
  }

  static get CustomColors() {
    return 'CustomColors';
  }

  static get CustomColorSet() {
    return 'CustomColorSet';
  }

  static get ProTrialOverlaySeen() {
    return 'pro_trial_overlay_seen';
  }

  static get ProTrialExtensionOverlaySeen() {
    return 'pro_trial_extension_overlay_seen';
  }

  static get lastVisitedHiddenRoster() {
    return 'last_visited_hidden_roster';
  }

  static get lastSeenTrialConversionBanner() {
    return 'last_seen_trial_conversion_banner';
  }

  static get lastSeenAssignmentNotificationBanner() {
    return 'last_seen_assignment_notification_banner';
  }

  static get lastSeenRenewalConversionBanner() {
    return 'last_seen_renewal_conversion_banner';
  }

  static get lastHidRoster() {
    return 'last_hid_roster';
  }

  static get lastSeenProTrialConversionOverlay() {
    return 'last_seen_pro_trial_conversion_overlay';
  }

  static get lastSeenIncentiveDialog() {
    return 'last_seen_incentive_dialog';
  }

  static get sentAdminEmail() {
    return 'sent_admin_email';
  }

  static get sharedWorkPath() {
    return 'shared_work_path';
  }

  static get sharedWorkSignUpAuthType() {
    return 'shared_work_sign_up_auth_type';
  }

  static get edlink_code() {
    return 'edlink_code';
  }

  static get edlink_error() {
    return 'edlink_error';
  }

  static get ckRosterId() {
    return 'ck_roster_id';
  }

  static get ckAssignmentId() {
    return 'ck_assigment_id';
  }

  /**
   * @param $window
   * @param $timeout
   * @ngInject
   */
  constructor($window, $timeout, $log) {
    this.$window = $window;
    this.$timeout = $timeout;
    this.$log = $log;

    /** @type {LazyVar.<boolean>} */
    this._rememberMe = new LazyVar();
    /** @type {HelpRequestCodec} */
    this._helpRequestCodec = new HelpRequestCodec();
    /** @type {CkRedirectCodec} */
    this._ckRedirectCodec = new CkRedirectCodec();
    /** @type {CopyAssignmentRequestCodec} */
    this._copyAssignmentRequestCodec = new CopyAssignmentRequestCodec();
    /** @type {CopyQuestionsRequestCodec} */
    this._copyQuestionsRequestCodec = new CopyQuestionsRequestCodec();

    const transferSessionUser = (event) => this._transferSessionUser(event);

    // listen for changes to localStorage
    if ($window.addEventListener) {
      $window.addEventListener('storage', transferSessionUser, false);
    } else {
      $window.attachEvent('onstorage', transferSessionUser);
    }

    // Ask other tabs for session storage
    if (!this.rememberMe && !sessionStorage.getItem(StorageService.UserInfo)) {
      this._triggerEvent(this._GET_SESSION_USER, 'retrieve');
    }

    /** @type {JSEvent.<string>} */
    this._remoteLogOut = new JSEvent(this);
  }

  _triggerEvent(name, value) {
    localStorage.setItem(name, value);
    localStorage.removeItem(name);
  }

  /**
   * @param key {string}
   * @param value {*}
   * @param [storage] {Storage}
   * @private
   */
  _write(key, value, storage) {
    storage = storage || this._storage;
    storage.setItem(key, angular.toJson(value));
  }

  /**
   *
   * @param key {string}
   * @param [storage] {Storage}
   * @return {*}
   * @private
   */
  _read(key, storage) {
    try {
      storage = storage || this._storage;
      return angular.fromJson(storage.getItem(key));
    } catch (error) {
      this.$log.error(error);
    }
  }

  /**
   *
   * @returns {Storage}
   * @private
   */
  get _storage() {
    if (this.rememberMe) {
      return localStorage;
    }
    else {
      return sessionStorage;
    }
  }

  get _LOG_OUT() {
    return 'logOut';
  }
  get _GET_SESSION_USER() {
    return 'getSessionUser';
  }
  get _TRANSFER_SESSION_USER() {
    return 'transferSessionUser';
  }

  /**
   * @return {JSEvent.<string>}
   */
  get onRemoteLogOut() {
    return this._remoteLogOut;
  }

  /**
   * Allows the user data stored in sessionStorage to be transferred between tabs when the app is loaded.
   * Adapted from: https://stackoverflow.com/questions/20325763/browser-sessionstorage-share-between-tabs
   * @param event {StorageEvent}
   * @private
   */
  _transferSessionUser(event) {

    if (!event) {
      event = this.$window.event; // ie support
    }
    if (!event.newValue) {
      return;          // do nothing if no value to work with
    }
    if (event.key === this._GET_SESSION_USER && sessionStorage.getItem(StorageService.UserInfo) && !this.rememberMe) {
      // another tab asked for the sessionStorage -> send it
      localStorage.setItem(this._TRANSFER_SESSION_USER, sessionStorage.getItem(StorageService.UserInfo));

      // Remove transfer entry after a short time
      this.$timeout(() => {
        localStorage.removeItem(this._TRANSFER_SESSION_USER);
      }, 100);
    } else if (event.key === this._TRANSFER_SESSION_USER && !sessionStorage.getItem(StorageService.UserInfo)) {
      sessionStorage.setItem(StorageService.UserInfo, event.newValue);
    }
    else if (event.key === this._LOG_OUT) {
      if (!this.userInfo || event.newValue === this.userInfo.id) {
        this.onRemoteLogOut.raise(event.newValue);
      }
    }
  }

  /**
   * @returns {boolean}
   */
  get rememberMe() {
    return this._rememberMe.value(() => {
      return !!this._read(StorageService.RememberMe, localStorage);
    });
  }

  /**
   * @param value {boolean}
   */
  set rememberMe(value) {
    this._write(StorageService.RememberMe, value, localStorage);
    this._rememberMe.clear();

    if (!value) {
      localStorage.removeItem(StorageService.UserInfo);
    }
  }

  /**
   * @returns {UserTokenInfo|null}
   */
  get userInfo() {
    const data = this._read(StorageService.UserInfo);
    if (data) {
      return new UserTokenInfo(
        data.id,
        data.name,
        data.email,
        data.username,
        data.authType,
        data.roles,
        data.plans,
        data.contracts,
        data.firebaseToken,
        data.token
      );
    }
    else {
      return null;
    }
  }

  /**
   * @returns {UserTokenInfo|null}
   */
  get coTeacherUserInfo() {
    const data = this._read(StorageService.CoTeacherInfo);
    if (data) {
      return new UserTokenInfo(
          data.id,
          data.name,
          data.email,
          data.username,
          data.authType,
          data.roles,
          data.plans,
          data.contracts,
          data.firebaseToken,
          data.token
      );
    }
    else {
      return null;
    }
  }


  /**
   * @param value {UserTokenInfo|null}
   * @param rememberMe {boolean}
   */
  setUserInfo(value, rememberMe) {

    const oldValue = this.userInfo;

    this.rememberMe = rememberMe;

    localStorage.removeItem(StorageService.UserInfo);
    sessionStorage.removeItem(StorageService.UserInfo);

    this._write(StorageService.UserInfo, value);

    if (!value && oldValue) {
      this._triggerEvent(this._LOG_OUT, oldValue.id);
    }
  }

  /**
   * Change's co-teacher's auth info back to original state
   */
  setUserInfoBackToCoTeacherAuth(){
    localStorage.removeItem(StorageService.UserInfo);
    sessionStorage.removeItem(StorageService.UserInfo);
    const coTeacherUserInfo = this.coTeacherUserInfo;
    this.setUserInfo(coTeacherUserInfo, this.rememberMe);
  }

  /**
   * Co-teacher's actual auth, does not ever change
   * @param value {UserTokenInfo|null}
   */
  setCoTeacherInfo(value) {
    const oldValue = this.coTeacherUserInfo;

    this.clearCoTeacherInfo();

    this._write(StorageService.CoTeacherInfo, value);

    if (!value && oldValue) {
      this._triggerEvent(this._LOG_OUT, oldValue.id);
    }
  }

  /**
   * Clear co-teacher's auth info in localStorage and sessionStorage
   */
  clearCoTeacherInfo(){
    localStorage.removeItem(StorageService.CoTeacherInfo);
    sessionStorage.removeItem(StorageService.CoTeacherInfo);
  }

  /**
   * @returns {boolean}
   */
  get hasRecommendedBrowser() {
    return !!this._read(StorageService.HasRecommendedBroswer, sessionStorage);
  }

  /**
   * @param value {boolean}
   */
  set hasRecommendedBrowser(value) {
    this._write(StorageService.HasRecommendedBroswer, value, sessionStorage);
  }

  /**
   * @returns {string}
   */
  get assignmentLibraryQuery() {
    return this._read(StorageService.AssignmentLibraryQuery, sessionStorage);
  }

  /**
   * @param value {string}
   */
  set assignmentLibraryQuery(value) {
    this._write(StorageService.AssignmentLibraryQuery, value, sessionStorage);
  }

    /**
   * @returns {string}
   */
     get assignmentLibraryGradeRange() {
      return this._read(StorageService.AssignmentLibraryGradeRange, sessionStorage);
    }

    /**
     * @param value {string}
     */
    set assignmentLibraryGradeRange(value) {
      this._write(StorageService.AssignmentLibraryGradeRange, value, sessionStorage);
    }

  /**
   * @returns {string}
   */
   get assignmentLibrarySubject() {
    return this._read(StorageService.AssignmentLibrarySubject, sessionStorage);
  }

  /**
   * @param value {string}
   */
  set assignmentLibrarySubject(value) {
    this._write(StorageService.AssignmentLibrarySubject, value, sessionStorage);
  }

  /**
   * @returns {string}
   */
  get assignmentDashboardQuery() {
    return this._read(StorageService.AssignmentDashboardQuery, sessionStorage);
  }

  /**
   * @param value {string}
   */
  set assignmentDashboardQuery(value) {
    this._write(StorageService.AssignmentDashboardQuery, value, sessionStorage);
  }

  /**
   * @returns {Object}
   */
  get assignmentLibrarySelection() {
    return this._read(StorageService.AssignmentLibrarySelection, sessionStorage);
  }

  /**
   * @param value {Object}
   */
  set assignmentLibrarySelection(value) {
    this._write(StorageService.AssignmentLibrarySelection, value, sessionStorage);
  }


  /**
   * @returns {HelpRequest}
   */
  get feedbackHelpRequest() {
    return this._helpRequestCodec.decode(
      this._read(StorageService.HelpRequest, sessionStorage)
    );
  }

  /**
   * @param value {HelpRequest}
   */
  set feedbackHelpRequest(value) {
    if (value) {
      let encodedValue = this._helpRequestCodec.encode(value);
      this._write(StorageService.HelpRequest, encodedValue, sessionStorage);
    }
    else {
      sessionStorage.removeItem(StorageService.HelpRequest);
    }
  }

  /**
   * @return {CkRedirect}
   */
  get ckRedirect() {
    return this._ckRedirectCodec.decode(
      this._read(StorageService.CkRedirect, sessionStorage)
    );
  }

  /**
   * @param value {CkRedirect}
   */
  set ckRedirect(value) {
    if (value) {
      let encodedValue = this._ckRedirectCodec.encode(value);
      this._write(StorageService.CkRedirect, encodedValue, sessionStorage);
    }
    else {
      sessionStorage.removeItem(StorageService.CkRedirect);
    }
  }

  /**
   * @param status {object}
   */
  set studentFolderStatus(status) {
    this._write(StorageService.StudentFolderStatus, status);
  }

  /**
   * @returns {object}
   */
  get studentFolderStatus() {
    return this._read(StorageService.StudentFolderStatus);
  }

  /**
   * @param value {string}
   */
  set sessionSort(value) {
    this._write(StorageService.SessionSort, value, sessionStorage);
  }

  /**
   * @return {string}
   */
  get sessionSort() {
    return this._read(StorageService.SessionSort, sessionStorage);
  }

  /**
   * @return {boolean}
   */
  get hideUnstartedThumbnails() {
    return this._read(StorageService.HideUnstartedThumbnails, sessionStorage);
  }

  /**
   * @param value {boolean}
   */
  set hideUnstartedThumbnails(value) {
    this._write(StorageService.HideUnstartedThumbnails, value, sessionStorage);
  }

  /**
   * @return {boolean}
   */
  get hideOfflineStudents() {
    return this._read(StorageService.HideOfflineStudents, sessionStorage);
  }

  /**
   * @param value {boolean}
   */
  set hideOfflineStudents(value) {
    this._write(StorageService.HideOfflineStudents, value, sessionStorage);
  }

  /**
   * @return {boolean}
   */
  get hideUnstartedAssignmentWorks() {
    return this._read(StorageService.HideUnstartedAssignmentWorks, sessionStorage);
  }

  /**
   * @param value {boolean}
   */
  set hideUnstartedAssignmentWorks(value) {
    this._write(StorageService.HideUnstartedAssignmentWorks, value, sessionStorage);
  }

  /**
   * @return {boolean}
   */
  get hideStudentNames() {
    return this._read(StorageService.HideStudentNames, sessionStorage);
  }

  /**
   * @param value {boolean}
   */
  set hideStudentNames(value) {
    this._write(StorageService.HideStudentNames, value, sessionStorage);
  }

  /**
   * @return {string}
   */
  get assignmentsListColumn() {
    return this._read(StorageService.AssignmentsListColumn, sessionStorage);
  }

  /**
   * @param value {string}
   */
  set assignmentsListColumn(value) {
    if (angular.isDefined(value)) {
      this._write(StorageService.AssignmentsListColumn, value, sessionStorage);
    }
    else {
      sessionStorage.removeItem(StorageService.AssignmentsListColumn);
    }
  }

  /**
   * @return {string}
   */
  get assignmentsListAsc() {
    return this._read(StorageService.AssignmentsListAsc, sessionStorage);
  }

  /**
   * @param value {string}
   */
  set assignmentsListAsc(value) {
    if (angular.isDefined(value)) {
      this._write(StorageService.AssignmentsListAsc, !!value, sessionStorage);
    }
    else {
      sessionStorage.removeItem(StorageService.AssignmentsListAsc);
    }
  }

  /**
   * @return {CopyAssignmentRequest}
   */
  get copyAssignmentRequest() {
    return this._copyAssignmentRequestCodec.decode(
      this._read(StorageService.CopyAssignmentRequest, sessionStorage)
    );
  }

  /**
   * @param value {CopyAssignmentRequest}
   */
  set copyAssignmentRequest(value) {
    if (value) {
      let encodedValue = this._copyAssignmentRequestCodec.encode(value);
      this._write(StorageService.CopyAssignmentRequest, encodedValue, sessionStorage);
    }
    else {
      sessionStorage.removeItem(StorageService.CopyAssignmentRequest);
    }
  }

  /**
   * @return {CopyQuestionsRequest}
   */
  get copyQuestionsRequest() {
    return this._copyQuestionsRequestCodec.decode(
      this._read(StorageService.CopyQuestionsRequest, sessionStorage)
    );
  }

  /**
   * @param value {CopyQuestionsRequest}
   */
  set copyQuestionsRequest(value) {
    if (value) {
      let encodedValue = this._copyQuestionsRequestCodec.encode(value);
      this._write(StorageService.CopyQuestionsRequest, encodedValue, sessionStorage);
    }
    else {
      sessionStorage.removeItem(StorageService.CopyQuestionsRequest);
    }
  }

  /**
   * @returns {Object}
   */
  get selectedUsersAdminDashboard() {
    return this._read(StorageService.SelectedUsersAdminDashboard, sessionStorage);
  }

  /**
   * @param value {Object}
   */
  set selectedUsersAdminDashboard(value) {
    this._write(StorageService.SelectedUsersAdminDashboard, value, sessionStorage);
  }

  /**
   * @returns {string}
   */
  get utmCampaignId() {
    return this._read(StorageService.utmCampaignId, sessionStorage);
  }

  /**
   * @param value {string}
   */
  set utmCampaignId(value) {
    this._write(StorageService.utmCampaignId, value, sessionStorage);
  }

  /**
   * @returns {Object}
   */
  get studentOverviewSortingProperty() {
    return this._read(StorageService.StudentOverviewSortingProperty, sessionStorage);
  }

  /**
   * @param value {Object}
   */
  set studentOverviewSortingProperty(value) {
    this._write(StorageService.StudentOverviewSortingProperty, value, sessionStorage);
  }

  /**
   * @returns {string}
   */
  get studentOverviewQuery() {
    return this._read(StorageService.StudentOverviewQuery, sessionStorage);
  }

  /**
   * @param value {string}
   */
  set studentOverviewQuery(value) {
    this._write(StorageService.StudentOverviewQuery, value, sessionStorage);
  }

  /**
   * @returns {string}
   */
  get skipMdrForNow() {
    return this._read(StorageService.SkipMdrForNow, sessionStorage);
  }

  /**
   * @param value {string}
   */
  set skipMdrForNow(value) {
    this._write(StorageService.SkipMdrForNow, value, sessionStorage);
  }

  /**
   * @returns {Array<Object>}
   */
  get customColors() {
    return this._read(StorageService.CustomColors, sessionStorage);
  }

  /**
   * @param value {Array<Object>}
   */
  set customColors(value) {
    this._write(StorageService.CustomColors, value, sessionStorage);
  }

  /**
   * @returns {Set}
   */
  get customColorSet() {
    return this._read(StorageService.CustomColorSet, sessionStorage);
  }

  /**
   * @param value {Set}
   */
  set customColorSet(value) {
    this._write(StorageService.CustomColorSet, value, sessionStorage);
  }

  /**
   * @returns {string}
   */
  get proTrialOverlaySeen() {
    return this._read(StorageService.ProTrialOverlaySeen, localStorage);
  }

  /**
   * @param value {string}
   */
  set proTrialExtensionOverlaySeen(value) {
    this._write(StorageService.ProTrialExtensionOverlaySeen, value, localStorage);
  }

  /**
   * @returns {string}
   */
  get proTrialExtensionOverlaySeen() {
    return this._read(StorageService.ProTrialExtensionOverlaySeen, localStorage);
  }

  /**
   * @param value {string}
   */
  set proTrialOverlaySeen(value) {
    this._write(StorageService.ProTrialOverlaySeen, value, localStorage);
  }

  /**
   * @returns {string}
   */
  get lastVisitedHiddenRoster() {
    return this._read(StorageService.lastVisitedHiddenRoster, localStorage);
  }

  /**
   * @param value {string}
   */
  set lastVisitedHiddenRoster(value) {
    this._write(StorageService.lastVisitedHiddenRoster, value, localStorage);
  }

  /**
   * @returns {Object}
   */
  get lastSeenTrialConversionBanner() {
    return this._read(StorageService.lastSeenTrialConversionBanner, localStorage);
  }

  /**
   * @param value {Object}
   */
  set lastSeenTrialConversionBanner(value) {
    this._write(StorageService.lastSeenTrialConversionBanner, value, localStorage);
  }

  /**
   * @returns {Object}
   */
  get lastSeenAssignmentNotificationBanner() {
    return this._read(StorageService.lastSeenAssignmentNotificationBanner, localStorage);
  }

  /**
   * @param value {Object}
   */
  set lastSeenAssignmentNotificationBanner(value) {
    this._write(StorageService.lastSeenAssignmentNotificationBanner, value, localStorage);
  }

  /**
   * @returns {Object}
   */
  get lastSeenRenewalConversionBanner() {
    return this._read(StorageService.lastSeenRenewalConversionBanner, localStorage);
  }

  /**
   * @param value {Object}
   */
  set lastSeenRenewalConversionBanner(value) {
    this._write(StorageService.lastSeenRenewalConversionBanner, value, localStorage);
  }

  /**
   * @returns {string}
   */
  get lastHidRoster() {
    return this._read(StorageService.lastHidRoster, localStorage);
  }

  /**
   * @param value {string}
   */
  set lastHidRoster(value) {
    this._write(StorageService.lastHidRoster, value, localStorage);
  }

  /**
   * @returns {string}
   */
  get lastSeenProTrialConversionOverlay() {
    return this._read(StorageService.lastSeenProTrialConversionOverlay, localStorage);
  }

  /**
   * @param value {string}
   */
  set lastSeenProTrialConversionOverlay(value) {
    this._write(StorageService.lastSeenProTrialConversionOverlay, value, localStorage);
  }

  /**
   * @returns {string}
   */
  get lastSeenIncentiveDialog() {
    return this._read(StorageService.lastSeenIncentiveDialog, localStorage);
  }

  /**
   * @param value {string}
   */
  set lastSeenIncentiveDialog(value) {
    this._write(StorageService.lastSeenIncentiveDialog, value, localStorage);
  }

  /**
   * @returns {boolean}
   */
  get sentAdminEmail() {
    return this._read(StorageService.sentAdminEmail, localStorage);
  }

  /**
   * @param value {boolean}
   */
  set sentAdminEmail(value) {
    this._write(StorageService.sentAdminEmail, value, localStorage);
  }

  /**
   * @returns {string}
   */
  get sharedWorkPath() {
    return this._read(StorageService.sharedWorkPath, localStorage);
  }

  /**
   * @param value {string}
   */
  set sharedWorkPath(value) {
    this._write(StorageService.sharedWorkPath, value, localStorage);
  }

  /**
   * @returns {string}
   */
  get sharedWorkSignUpAuthType() {
    return this._read(StorageService.sharedWorkSignUpAuthType, localStorage);
  }

  /**
   * @param value {string}
   */
  set sharedWorkSignUpAuthType(value) {
    this._write(StorageService.sharedWorkSignUpAuthType, value, localStorage);
  }

  get edlinkCode() {
    return this._read(StorageService.edlink_code, localStorage);
  }

  /**
   * @param value {string}
   */
  set edlinkCode(value) {
    this._write(StorageService.edlink_code, value, localStorage);
  }

  get edlinkError() {
    return this._read(StorageService.edlink_error, localStorage);
  }

  /**
   * @param value {string}
   */
  set edlinkError(value) {
    this._write(StorageService.edlink_error, value, localStorage);
  }

  get ckRosterId() {
    return this._read(StorageService.ckRosterId, localStorage);
  }

  /**
   * @param value {string}
   */
  set ckRosterId(value) {
    this._write(StorageService.ckRosterId, value, localStorage);
  }

  get ckAssignmentId() {
    return this._read(StorageService.ckAssignmentId, localStorage);
  }

  /**
   * @param value {string}
   */
  set ckAssignmentId(value) {
    this._write(StorageService.ckAssignmentId, value, localStorage);
  }



  removeFromLocalStorage(property){
    localStorage.removeItem(property);
  }
}
