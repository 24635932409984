'use strict';

// Base class for all codecs
export default class Codec {
  constructor() {
    if (angular.isUndefined(this.encode)) {
      throw new TypeError('The encode(value) method must be defined');
    }
    if (angular.isUndefined(this.decode)) {
      throw new TypeError('The decode(value, key) method must be defined');
    }
  }
}



