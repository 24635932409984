
'use strict';

import Element from './element';

/**
 * Displays a view only image
 */
export default class ImageView extends Element {
  /**
   * @param src {string}
   * @param location {Point}
   * @param size {Size}
   */
  constructor(src, location, size) {
    super('image-view', 'image-view', null);

    this._src = src;
    this._location = location;
    this._size = size;
  }

  createElement(root) {
    this._image = root.image(this._src, 0, 0, 0, 0);
  }

  update() {
    this._image.attr({
      x: this.location.x,
      y: this.location.y,
      width: this._size.width,
      height: this._size.height
    });
  }
}
