
'use strict';

import Codec from './codec';
import User from '../domain/user';
import ContractMemberCodec from './contract-member-codec';

/**
 * Encodes/Decodes a user
 */
export default class UserCodec extends Codec {

  constructor() {
    super();

    this._contractMemberCodec = new ContractMemberCodec();
  }

  encode(value) {
    let contractIds = [];
    if (value.contractMembers) {
      for (let contractMember in value.contractMembers) {
        contractIds.push(this._contractMemberCodec.encode(contractMember));
      }
    }

    return {
      id: value.id,
      username: value.username,
      email: value.email,
      first_name: value.firstName,
      last_name: value.lastName,
      display_name: value.displayName,
      properties: value.properties,
      contract_ids: contractIds,
      auth_types: value.authTypes
    };
  }

  decode(value) {
    let contractMembers = [];
    if (value.contract_ids) {
      for (let contractMember of value.contract_ids) {
        contractMembers.push(this._contractMemberCodec.decode(contractMember));
      }
    }

    return new User(
      value.id,
      value.username,
      value.email,
      value.first_name,
      value.last_name,
      value.display_name,
      value.created,
      value.properties,
      value.roles,
      contractMembers,
      value.auth_types
    );
  }

}
