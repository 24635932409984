/*global gapi:false */

'use strict';

import ErrorCodes from '../../model/domain/error-codes';

class GoogleScopes {

  static get PROFILE_AND_EMAIL() {
    return 'profile email';
  }

  static get READ_COURSES_AND_STUDENT_EMAIL() {
    return 'https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/classroom.profile.emails';
  }

}

class GoogleDiscoveryDocs {

  static get CLASSROOM_DISCOVERY_DOC() {
   return 'https://www.googleapis.com/discovery/v1/apis/classroom/v1/rest';
  }

}


export default class GoogleClientService {
  constructor($q, $log, environment) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;

    /** @type {environment} */
    this._environment = environment;
    /** @type {gapi} */
    this._gapi = gapi;

    /** @type {gapi.auth2.GoogleAuth} */
    this._googleAuth = null;

    this._initGoogleAuth();
  }

  /**
   * Initializes google sign in
   * Documentation on the discoveryUrl can be found here: https://developers.google.com/api-client-library/javascript/features/discovery
   * @return Promise<gapi.auth2.GoogleAuth>
   */
  _initGoogleAuth() {
    let discoveryUrl = GoogleDiscoveryDocs.CLASSROOM_DISCOVERY_DOC;
    let scope = GoogleScopes.PROFILE_AND_EMAIL;

    this._gapi.load('client:auth2', () => {
      this._gapi.client
        .init({
          apiKey: this._environment.google.auth.apiKey,
          discoveryDocs: [discoveryUrl],
          client_id: this._environment.google.auth.clientId,
          scope: scope
        })
        .then(() => {
          this._googleAuth = this._gapi.auth2.getAuthInstance();
        }, (err) => {
          this._googleAuthErr = err;
        });
    });
  }

  /**
   * Signs in using googleAuth. Returns a promise containing an auth object.
   * @returns Promise<Object>
   */
  signIn() {
    if (!this.googleAuth) {
      let err = new Error(this._googleAuthErr.details);
      err.code = ErrorCodes.GOOGLE_AUTH_SETUP_FAILED;
      return this.$q.reject(err);
    }

    let deferred = this.$q.defer();

    this.googleAuth.signIn()
      .then(
        (user) => {
          deferred.resolve({
            idToken: user.getAuthResponse().id_token,
            firstName: user.getBasicProfile().getGivenName(),
            lastName: user.getBasicProfile().getFamilyName(),
            email: user.getBasicProfile().getEmail()
          });
        },
        (error) => {
          let err = new Error(error.error);
          err.code = error.error;
          deferred.reject(err);
        }
      );

    return deferred.promise;
  }

  signOut() {
    if (this.googleAuth) {
      this.googleAuth.signOut();
    }
  }

  get hasCourseAndStudentPermissions() {
    let googleAuth = this.googleAuth;
    let user = googleAuth && googleAuth.currentUser.get();
    return user && user.hasGrantedScopes(GoogleScopes.READ_COURSES_AND_STUDENT_EMAIL);
  }

  /**
   * Grants scopes to read courses, rosters, and student emails. See more about scopes here: https://developers.google.com/classroom/guides/auth
   *
   * @returns {Promise}
   */
  grantCourseAndStudentPermissions() {
    let deferred = this.$q.defer();

    this.googleAuth.currentUser
      .get()
      .grant({
        scope: GoogleScopes.READ_COURSES_AND_STUDENT_EMAIL
      })
      .then(() => {
        deferred.resolve();
      })
      .catch((err) => {
        this.$log.error(err);
        deferred.reject(err);
      });

    return deferred.promise;
  }

  /**
   * Gets the Google course information for the current user.
   * @param nextPageToken
   * @param courses
   * @return {Promise}
   */
  getCourses(nextPageToken, courses = []) {

    let request = {
      pageSize: 20,
      teacherId: this.userId
    };

    if (nextPageToken) {
      request.pageToken = nextPageToken;
    }

    return this._gapi.client.classroom.courses
      .list(request)
      .then((response) => {
        let nextPageToken = response.result.nextPageToken;
        let retrievedCourses = response.result.courses || [];

        if (nextPageToken) {
          return this.getCourses(nextPageToken, retrievedCourses.concat(courses));
        }
        else {
          return retrievedCourses.concat(courses);
        }
      })
      .catch(() => {
        this.$log.error('Could not get Google courses.');
      });
  }

  /**
   * Returns the google user id
   * @return {string}
   */
  get userId() {
    let user = this.googleAuth.currentUser.get();
    return user && user.getId();
  }

  /**
   * Makes a request to the Google Classroom API to get the students from the course in the specified Google Course Id.
   *
   * @param googleCourseId {string}
   * @param [pageToken] {string}
   * @param [students] {object[]}
   * @returns Promise<object[]>
   */
  getGoogleCourseStudents(googleCourseId, pageToken, students = []){
    return this._getGoogleCourseStudents(googleCourseId, pageToken)
      .then((data) => {
        let nextPageToken = data.result.nextPageToken;
        let retrievedStudents = data.result.students || [];

        if (nextPageToken) {
          return this.getGoogleCourseStudents(googleCourseId, nextPageToken, retrievedStudents.concat(students));
        }
        else {
          return retrievedStudents.concat(students);
        }
      });
  }

  /**
   * Gets students for a google course
   * See docs for details https://developers.google.com/classroom/reference/rest/v1/courses.students/list
   * @param googleCourseId {string}
   * @param pageToken {string|undefined}
   * @return {Promise<object[]>}
   * @private
   */
  _getGoogleCourseStudents(googleCourseId, pageToken) {
    let path = `https://classroom.googleapis.com/v1/courses/${googleCourseId}/students`;

    if (pageToken) {
      path = `${path}?pageToken=${pageToken}`;
    }

    return this._gapi.client.request({ path });
  }

  get googleAuth() {
    return this._googleAuth;
  }


}
