export default class InviteCode {

  /**
   * @param code {string}
   * @param contractId {string}
   * @param [ownerId] {string}
   */
  constructor(code, contractId, ownerId) {
    this._code = code;
    this._contractId = contractId;
    this._ownerId = ownerId;
  }

  get code() {
    return this._code;
  }

  get contractId() {
    return this._contractId;
  }

  get ownerId() {
    return this._ownerId;
  }
}
