import JSEvent from '../../model/util/js-event';

export default class BulkUpdateService {
  constructor() {
    'ngInject';
    this._updated = new JSEvent(this);
  }

  /**
   * @returns {JSEvent}
   */
  get updated() {
    return this._updated;
  }

  notify(value){
    this._updated.raise(value);
  }

  bulkUpdateChanged(value){
    this.notify(value);
  }
}
