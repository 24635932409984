
'use strict';

import MoveQuestionDialogTemplate from './move-question-dialog.html';

/**
 * Dialog to support reordering of assignment questions
 */
export default class MoveQuestionDialogController {
  constructor($mdDialog, assignment, questionId) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    /** @type {Assignment} */
    this._assignment = assignment;

    const questions = Array.from(this._assignment.questions.entries());

    this.positions = questions.map((entry) => entry[0] + 1);
    this.currentPosition = questions.find((entry) => entry[1].id === questionId)[0] + 1 || 1;
  }

  /**
   *
   * @param $mdDialog
   * @param assignment {Assignment}
   * @param questionId {string}
   * @returns {Promise.<string>} Promise of the new position
   */
  static show($mdDialog, assignment, questionId) {
    return $mdDialog.show({
      controller: MoveQuestionDialogController,
      template: MoveQuestionDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      locals: {
        assignment: assignment,
        questionId: questionId
      }
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * Resolves the promise with the beforeQuestionId indicating the new position
   */
  confirm(position) {
    let result = null;
    let newPosition = parseInt(position);

    if (newPosition !== this.currentPosition) {
      if (newPosition !== this._assignment.questions.length) {
        if (newPosition < this.currentPosition) {
          newPosition = newPosition - 1;
        }
        result = this._assignment.questions[newPosition].id;
      }

      this.$mdDialog.hide(result);
    }
    else {
      this.cancel();
    }
  }
}
