
'use strict';

import { HelpRequestTypes } from '../../model/domain/help-request';
import HelpRequestSet from '../../model/domain/help-request-set';
import ConfirmDialogController from '../confirm-dialog/confirm-dialog.controller';
import LoadingDialogController from '../loading-dialog/loading-dialog.controller';
import HelpInboxTemplate from './help-inbox.html';

export class HelpInboxType {

  static get TEACHER() {
    return 'helpInboxTeacherType';
  }

  static get STUDENT() {
    return 'helpInboxStudentType';
  }

  static get STUDENT_ACCOUNT() {
    return 'helpInboxStudentAccountType';
  }
}

export class HelpInboxMetadata {
  constructor(helpRequestSet, studentsMap, studentWorksMap, type) {
    /** @type HelpRequestSet */
    this._helpRequestSet = helpRequestSet;
    /** @type Map <string, User> */
    this._studentsMap = studentsMap;
    /** @type Map <string, AssignmentWork> */
    this._studentWorksMap = studentWorksMap;

    this._type = type;
  }

  /**
   * @returns {HelpRequestSet}
   */
  get helpRequestSet() {
    return this._helpRequestSet;
  }

  /**
   * @returns {Map.<studentId:string, User>}
   */
  get studentsMap() {
    return this._studentsMap;
  }

  /**
   * @returns {Map.<studentId:string, AssignmentWork>}
   */
  get studentWorksMap() {
    return this._studentWorksMap;
  }

  /**
   * Student or teacher type
   * @returns {HelpInboxType}
   */
  get type() {
    return this._type;
  }

}

export default function HelpInboxDirective() {
  'ngInject';

  return {
    restrict: 'E',
    scope: {
      helpInboxMetadata: '=',
      onSelect: '&',
      version:'@'
    },
    template: HelpInboxTemplate,
    link: HelpInboxController.link,
    controller: HelpInboxController,
    controllerAs: 'ctrl'
  };
}

class HelpInboxController {

  constructor($q, $scope, $timeout, $mdDialog, $mdToast, HelpRequestService, CacheService) {
    'ngInject';

    this.$q = $q;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;

    /** @type {HelpRequestService} */
    this._helpRequestService = HelpRequestService;
    /** @type {CacheService} */
    this._cacheService = CacheService;

    /** @type Map <string, User> */
    this._studentsMap = undefined;
    /** @type Map <string, AssignmentWork> */
    this._studentWorksMap = undefined;
    /** @type {string} */
    this._type = undefined;

    this._unresolvedRequestSet = undefined;
    this._unresolvedHelpRequestSet = undefined;
    this._unresolvedCheckRequestSet = undefined;
    this._unresolvedChatRequestSet = undefined;
    this._user = undefined;

    this._confirmDialog = ConfirmDialogController.show;
    this._loadingDialog = LoadingDialogController.show;
  }

  static link(scope, element, attrs, ctrl) {

    scope.$watch('helpInboxMetadata', (value) => {
      if (angular.isDefined(value)) {
        ctrl._type = value.type;
        ctrl._studentsMap = value.studentsMap;
        ctrl._studentWorksMap = value.studentWorksMap;
        ctrl._unresolvedRequestSet = value.helpRequestSet.createChild(
          HelpRequestSet.FILTER_FUNC_TYPES_UNRESOLVED(value.type === HelpInboxType.TEACHER ? HelpRequestTypes.ALL: [HelpRequestTypes.HELP, HelpRequestTypes.CHECK])
        );
        ctrl._unresolvedHelpRequestSet = value.helpRequestSet.createChild(HelpRequestSet.FILTER_FUNC_TYPES_UNRESOLVED([HelpRequestTypes.HELP]));
        ctrl._unresolvedCheckRequestSet = value.helpRequestSet.createChild(HelpRequestSet.FILTER_FUNC_TYPES_UNRESOLVED([HelpRequestTypes.CHECK]));
        ctrl._unresolvedChatRequestSet = value.helpRequestSet.createChild(HelpRequestSet.FILTER_FUNC_TYPES_UNRESOLVED([HelpRequestTypes.CHAT]));
      }
    });

    scope.$watch('version', (value) => {
      if (value) {
        ctrl.version = value;
      }
    });
  }


  get isTeacher() {
    return this._type === HelpInboxType.TEACHER;
  }

  /**
   * @return {HelpRequestSet}
   */
  get unresolvedRequestSet() {
    return this._unresolvedRequestSet;
  }

  /**
   * @returns {Array.<HelpRequest>}
   */
  get unresolvedRequests() {
    return this._unresolvedRequestSet && this._unresolvedRequestSet.requests;
  }

  /**
   * @returns {Array.<HelpRequest>}
   */
  get unresolvedHelpRequests() {
    return this._unresolvedHelpRequestSet && this._unresolvedHelpRequestSet.requests;
  }

  /**
   * @returns {Array.<HelpRequest>}
   */
  get unresolvedCheckRequests() {
    return this._unresolvedCheckRequestSet && this._unresolvedCheckRequestSet.requests;
  }

  /**
   * @returns {Array.<HelpRequest>}
   */
  get unresolvedChatRequests() {
    return this._unresolvedChatRequestSet && this._unresolvedChatRequestSet.requests;
  }

  /**
   * @returns {boolean}
   */
  get hasUnresolvedRequests() {
    let unresolvedRequests = this.unresolvedRequests;
    return unresolvedRequests && unresolvedRequests.length > 0;
  }

  /**
   * @returns {boolean}
   */
  get hasUnresolvedHelpRequests() {
    let unresolvedHelpRequests = this.unresolvedHelpRequests;
    return unresolvedHelpRequests && unresolvedHelpRequests.length > 0;
  }

  /**
   * @returns {boolean}
   */
  get hasUnresolvedCheckRequests() {
    let unresolvedCheckRequests = this.unresolvedCheckRequests;
    return unresolvedCheckRequests && unresolvedCheckRequests.length > 0;
  }

  /**
   * @return {boolean}
   */
  get hasUnresolvedChatRequests() {
    let unresolvedChatRequests = this.unresolvedChatRequests;
    return unresolvedChatRequests && unresolvedChatRequests.length > 0;
  }

  /**
   * @returns {number}
   */
  get unresolvedRequestsCount() {
    return this.unresolvedRequests && this.unresolvedRequests.length;
  }

  /**
   * @returns {number}
   */
  get unresolvedHelpRequestsCount() {
    return this.unresolvedHelpRequests && this.unresolvedHelpRequests.length;
  }

  /**
   * @returns {number}
   */
  get unresolvedCheckRequestsCount() {
    return this.unresolvedCheckRequests && this.unresolvedCheckRequests.length;
  }

  /**
   * @return {Number}
   */
  get unresolvedChatRequestsCount() {
    let unresolvedChatRequests = this.unresolvedChatRequests;
    return unresolvedChatRequests && unresolvedChatRequests.length;
  }

  /**
   * @returns {number}
   */
  getQuestionNumber(request) {
    let work = this._studentWorksMap.values().next().value;
    return work && work.indexForQuestionId(request.questionId) + 1;
  }

  /**
   * @param request {HelpRequest}
   */
  giveFeedbackTo(request) {
    this.$scope.onSelect({request});
  }

  studentNameForStudentId(studentId) {
    return this._studentsMap.get(studentId).name;
  }

  get isTeacherHelpInbox() {
    return this._type === HelpInboxType.TEACHER;
  }

  get isStudentHelpInbox() {
    return this.isAnonStudent || this.isProStudent;
  }

  get isAnonStudent() {
    return this._type === HelpInboxType.STUDENT;
  }

  get isProStudent() {
    return this._type === HelpInboxType.STUDENT_ACCOUNT;
  }

  get isStudentAccountHelpInbox() {
    return this._type === HelpInboxType.STUDENT_ACCOUNT;
  }

  get shouldShowChat() {
    return this.isTeacher;
  }

  get explanation() {
    if (this._type === HelpInboxType.STUDENT || this._type === HelpInboxType.STUDENT_ACCOUNT) {
      return 'Your classmates need help!';
    }
    else if (this._type === HelpInboxType.TEACHER) {
      return 'Your students need help!';
    } else {
      return '';
    }
  }

  clearHands() {
    this._confirmDialog(this.$mdDialog, 'Are you sure you want to clear all hands?')
      .then(() => {
        return this._cacheService.getUser();
      })
      .then((user) => {

        let promises = this.$q.all(this.unresolvedRequests.map((request) => {
          let work = this._studentWorksMap.get(request.helpeeId);
          let question = work.questionForId(request.questionId);
          return this._helpRequestService.lowerHand(request, question, user);
        }));

        this._loadingDialog(this.$mdDialog, promises);

        return promises;
      })
      .then(() => {
        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent('Hands cleared')
            .position('bottom right')
        );
      });
  }

}
