export class SharedWorkUser {
  /**
   * Contains the shared work information
   *
   * @param id {string}
   * @param username {string}
   * @param email {string}
   * @param firstName {string}
   * @param lastName {string}
   * @param displayName {string}
   *
   */
  constructor(id, username, email, firstName, lastName, displayName) {
    this._id = id;
    this._username = username;
    this._email = email;
    this._firstName = firstName;
    this._lastName = lastName;
    this._displayName = displayName;
  }

  /**
   * @return {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @return {string}
   */
  get email() {
    return this._email;
  }

  /**
   * @return {string}
   */
  get firstName() {
    return this._firstName;
  }

  /**
   * @return {string}
   */
  get lastName() {
    return this._lastName;
  }

  /**
   * @return {string}
   */
  get_displayName() {
    return this._displayName;
  }

  /**
   * @return {string}
   */
  get firstLastCombo() {
    let firstName = this._firstName || '';
    let lastName = this._lastName || '';
    let space = firstName && lastName ? ' ' : '';
    return `${firstName}${space}${lastName}`;
  }

  /**
   * @return {string}
   */
  get name () {
    if (this._displayName) {
      return this._displayName;
    }
    else if (this._firstName || this._lastName) {
      return this.firstLastCombo;
    }
    else if (this._username) {
      return this._username;
    }
    else if (this._email) {
      return this._email;
    }
    else {
      return 'User';
    }
  }

}

export default class SharedWork {

  /**
   * Contains the shared work information
   *
   * @param id {string} share work id
   * @param shareByUser {SharedWorkUser} user that shared assignment work
   * @param student {SharedWorkUser} student who owns assignment work
   * @param assignmentWork {AssignmentWork} assignment work
   */
  constructor(id, sharedByUser, student, assignmentWork) {
    this._id = id,
    this._sharedByUser = sharedByUser;
    this._student = student;
    this._assignmentWork = assignmentWork;
  }

  /**
   * @return {string}
   */
  get id(){
    return this._id;
  }

  /**
   * @return {SharedWorkUser}
   */
  get sharedByUser(){
    return this._sharedByUser;
  }

  /**
   * @return {SharedWorkUser}
   */
  get student(){
    return this._student;
  }

  /**
   * @return {AssignmentWork}
   */
  get assignmentWork() {
    return this._assignmentWork;
  }
}