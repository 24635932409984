'use strict';

import moment from 'moment';
import Notification from './notification';
import NotificationShardKey from './notification-shard-key.js';
import JSEvent from '../util/js-event';

class AssignmentRosterChange {

  /**
   * @param id {string}
   * @param showStudentScores {boolean}
   * @param lockStudentWork {boolean}
   * @param hideStudentWork {boolean}
   * @param allowPdf {boolean}
   * @param time {string}
   */
  constructor(id, showStudentScores, lockStudentWork, hideStudentWork, allowPdf, time) {
    this._id = id;
    this._showStudentScores = showStudentScores;
    this._lockStudentWork = lockStudentWork;
    this._hideStudentWork = hideStudentWork;
    this._allowPdf = allowPdf;
    this._time = time ? moment(time) : undefined;
  }

  static apply(value) {
    if (value) {
      return new AssignmentRosterChange(
        value.id,
        value.show_student_scores,
        value.lock_student_work,
        value.hide_student_work,
        value.allow_pdf,
        value.t
      );
    }
    return null;
  }

  get id() {
    return this._id;
  }

  get showStudentScores() {
    return this._showStudentScores;
  }

  get lockStudentWork() {
    return this._lockStudentWork;
  }

  get hideStudentWork() {
    return this._hideStudentWork;
  }

  get allowPdf() {
    return this._allowPdf;
  }

  get time() {
    return this._time;
  }
}

export default class AssignmentRosterNotification extends Notification {

  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param assignmentId {string}
   * @param rosterId {string}
   * @param FirebaseService {FirebaseService}
   * @param [showInitialNotification] {boolean}
   */
  constructor(notificationShardKey, assignmentId, rosterId, FirebaseService, showInitialNotification) {

    super(
      Notification.V3,
      notificationShardKey,
      `assignments/${assignmentId}/rosters/${rosterId}`,
      FirebaseService,
      showInitialNotification,
      'AssignmentRosterNotification'
    );

    this._metadata = new JSEvent(this);

    this._logTrace('cstr complete');
  }

  /**
   * @returns {JSEvent}
   */
  get metadata() {
    return this._metadata;
  }

  /**
   * @returns {AssignmentWorkChange|null}
   */
  get value() {
    return this._value;
  }

  _changed(value) {
    this._logTrace('_changed');
    this._value = AssignmentRosterChange.apply(value);
    if (value) {
      this._metadata.raise(this._value);
    }
  }
}
