
'use strict';

export default function CloneDirective() {
  'ngInject';

  return {
    restrict: 'E',
    scope: false,
    template: '',
    link: CloneDirectiveController.link,
    controller: CloneDirectiveController
  };
}

class CloneDirectiveController {
  constructor($compile) {
    'ngInject';
    this.$compile = $compile;
  }

  static link(scope, element, attrs, ctrl) {

    scope.$watch(attrs.selector, (value) => {

      // TODO: figure out why .first() is necessary to avoid duplication
      const target = angular.element(value).first().clone();
      target.appendTo(element);
      ctrl.$compile(target)(scope);
    });
  }
}
