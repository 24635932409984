'use strict';

import Element from './element';
import ElementMetadata from '../../domain/element-metadata';

export default class ArchivedData extends Element {

  /**
   * @param role {string}
   * @param intent {string}
   */
  constructor(role, intent) {
    let metadata = new ElementMetadata(ArchivedData.id, role, intent);
    super(ArchivedData.id, ArchivedData.type, metadata);
  }

  /**
   * @returns {string}
   */
  static get id() {
    return 'arcv';
  }

  /**
   * @returns {string}
   */
  static get type() {
    return 'archived';
  }

  createElement(root, editable) { }

  update(root, editable, active) {}
}

