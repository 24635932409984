
export default class LocationUtil {

  /**
   * Provides a the base url for the app without the protocol, eg app.classkick.com/#
   * @param $location {$location} The location service
   * @returns {string}
   */
  static displayRootUrl($location) {
    return LocationUtil.displayUrl(LocationUtil.absRootUrl($location));
  }

  /**
   * Removes the protocol from the a url so that it is nicer to read
   * @param url {string} A URL
   * @returns {string}
   */
  static displayUrl(url) {
    return url.slice(url.indexOf('://') + 3);
  }

  /**
   * Provides the host for the app with the port if running locally
   * @param $location
   * @return {string}
   */
  static host($location) {
    let host = $location.host();

    // If we're not on the default http or https ports, we'll include the port in the link
    if (!($location.port() === 80 || $location.port() === 443)) {
      host = `${host}:${$location.port()}`;
    }

    return host;
  }

  /**
   * Provides the base url for the app, eg https://app.classkick.com
   * @param $location {$location} The location service
   * @returns {string}
   */
  static absRootUrl($location) {
    let protocol = $location.protocol();
    let host = LocationUtil.host($location);
    return `${protocol}://${host}`;
  }

}
