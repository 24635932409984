
'use strict';

import Codec from './codec';
import ElementCodec from './element-codec';
import Sticker from '../ui/elements/sticker';
import Point from '../ui/point';

/**
 * Encodes/Decodes an audio chip
 */
export default class StickerCodec extends Codec {

  /**
   * @param value {Sticker}
   * @returns {{data: Object, metadata: Object}}
   */
  encode(value) {
    return ElementCodec.preEncode(value, (size/*, center*/) => {
      return {
        type: value.type,
        image_url: value.url,
        size: size,
        text: value.text,
        center: ElementCodec.pointCodec.encode(ElementCodec.centerFromLocationAndSize(value.location, value.size)),
        score: value.score,
        viewed_by_student: value.viewedByStudent
      };
    });
  }

  /**
   * @param value {object}
   * @param key {string}
   * @returns {Sticker}
   */
  decode(value, key) {
    return ElementCodec.preDecode(value, (metadata, size, location) => {
      return new Sticker(
        key,
        metadata,
        location,
        size,
        value.data.text,
        value.data.image_url,
        value.data.score,
        value.data.viewed_by_student
      );
    });
  }
}
