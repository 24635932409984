
'use strict';

import Codec from './codec';
import ElementMetadataCodec from './element-metadata-codec';
import PointCodec from './point-codec';
import SizeCodec from './size-codec';

import Point from '../ui/point';

import ArchivedData from '../ui/elements/archived-data';
import ArchivedDataCodec from './archived-data-codec';
import AudioClip from '../ui/elements/audio-clip';
import AudioClipCodec from './audio-clip-codec';
import CkImage from '../ui/elements/ckimage';
import CkImageCodec from './ckimage-codec';
import Line from '../ui/elements/line';
import Highlight from '../ui/elements/highlight';
import LineCodec from './line-codec.js';
import Link from '../ui/elements/link';
import LinkCodec from './link-codec';
import Sticker from '../ui/elements/sticker';
import StickerCodec from './sticker-codec';
import Textbox from '../ui/elements/textbox';
import TextboxCodec from './textbox-codec';
import HighlightCodec from './highlight-codec';
import MultipleChoiceChild from '../ui/elements/multiple-choice-child';
import MultipleChoiceChildCodec from './multiple-choice-child-codec';
import MultipleChoiceParent from '../ui/elements/multiple-choice-parent';
import MultipleChoiceParentCodec from './multiple-choice-parent-codec';
import ManipulativeImageParent from '../ui/elements/manipulative-image-parent';
import ManipulativeImageParentCodec from './manipulative-image-parent-codec';
import ManipulativeImageChild from '../ui/elements/manipulative-image-child';
import ManipulativeImageChildCodec from './manipulative-image-child-codec';
import FillInTheBlankParent from '../ui/elements/fill-in-the-blank-parent';
import FillInTheBlankParentCodec from './fill-in-the-blank-parent-codec';
import FillInTheBlankChild from '../ui/elements/fill-in-the-blank-child';
import FillInTheBlankChildCodec from './fill-in-the-blank-child-codec';
import StraightLine from '../ui/elements/straight-line';
import StraightLineCodec from './straight-line-codec';
import SlideBackground from '../ui/elements/slide-background';
import SlideForeground from '../ui/elements/slide-foreground';
import SlideBackgroundCodec from './slide-background-codec';
import SlideForegroundCodec from './slide-foreground-codec';

var metadataCodec = new ElementMetadataCodec();
var pointCodec = new PointCodec();
var sizeCodec = new SizeCodec();

export default class ElementCodec extends Codec {

  constructor() {
    super();
    this._codecMap = new Map()
      .set(ArchivedData.type, new ArchivedDataCodec())
      .set(AudioClip.type, new AudioClipCodec())
      .set(CkImage.type, new CkImageCodec())
      .set(Line.type, new LineCodec())
      .set(Highlight.type, new HighlightCodec())
      .set(Link.type, new LinkCodec())
      .set(Sticker.type, new StickerCodec())
      .set(Textbox.type, new TextboxCodec())
      .set(MultipleChoiceChild.type, new MultipleChoiceChildCodec())
      .set(MultipleChoiceParent.type, new MultipleChoiceParentCodec())
      .set(ManipulativeImageParent.type, new ManipulativeImageParentCodec())
      .set(ManipulativeImageChild.type, new ManipulativeImageChildCodec())
      .set(FillInTheBlankParent.type, new FillInTheBlankParentCodec())
      .set(FillInTheBlankChild.type, new FillInTheBlankChildCodec())
      .set(StraightLine.type, new StraightLineCodec())
      .set(SlideBackground.type, new SlideBackgroundCodec())
      .set(SlideForeground.type, new SlideForegroundCodec())
    ;
  }

  /**
   * @param value {Element}
   * @returns {object}
   */
  encode(value) {
    if (this._codecMap.has(value.type)) {
      return this._codecMap.get(value.type).encode(value);
    }
    throw new Error('Unknown type: ' + value.type);
  }

  /**
   * @param value {object}
   * @param key {string}
   * @returns {Element}
   */
  decode(value, key) {
    let type = value.data.type;
    if (this._codecMap.has(type)) {
      return this._codecMap.get(type).decode(value, key);
    }
    throw new Error('Unknown type: ' + type + ' with id: ' + key);
  }

  /**
   * @param center {Point}
   * @param size {Size}
   * @returns {Point}
   */
  static locationFromCenterAndSize(center, size) {
    return new Point(
      center.x - (size.width / 2),
      center.y - (size.height / 2)
    );
  }

  /**
   * @param location {Point}
   * @param size {Size}
   * @returns {Point}
   */
  static centerFromLocationAndSize(location, size) {
    return new Point(
      location.x + (size.width / 2),
      location.y + (size.height / 2)
    );
  }

  /**
   * @param value {object}
   * @returns {ElementMetadata}
   */
  static extractMetadata(value) {
    return metadataCodec.decode(value.metadata);
  }

  /**
   * @param data {object}
   * @param metadata {ElementMetadata}
   * @returns {{data: object, metadata: object}}
   */
  static firebaseElement(data, metadata) {
    return {
      data: data,
      metadata: metadataCodec.encode(metadata)
    };
  }

  /**
   * @param element {Element}
   * @param func {function(object, object)} parameters: size, center
   * @returns {{data: Object, metadata: Object}}
     */
  static preEncode(element, func) {
    let size = sizeCodec.encode(element.size);
    let center = pointCodec.encode(ElementCodec.centerFromLocationAndSize(element.location, element.size));

    return ElementCodec.firebaseElement(
      func(size, center),
      element.metadata
    );
  }

  /**
   * @param value {object}
   * @param func {function(ElementMetadata, Size, Point, Point)} parameters: metadata, size, location, center
   * @returns {Element}
   */
  static preDecode(value, func) {
    let metadata = ElementCodec.extractMetadata(value);
    let size = value.data.size && sizeCodec.decode(value.data.size);
    let center = value.data.center && pointCodec.decode(value.data.center);
    let location = center && size && ElementCodec.locationFromCenterAndSize(center, size);

    return func(metadata, size, location, center);
  }

  /**
   * @returns {SizeCodec}
   */
  static get sizeCodec() {
    return sizeCodec;
  }

  /**
   * @returns {PointCodec}
   */
  static get pointCodec() {
    return pointCodec;
  }
}
