'use strict';

import LocationUtil from '../../model/util/location-util';
import InviteTeacherDialogTemplate from './invite-teacher-dialog.html';

/**
 * Invite teachers to join contract
 */
export default class InviteTeacherDialogController {

  /**
   * @ngInject
   */
  constructor($mdDialog, InviteCodeService, $location, contractId) {
    this.$mdDialog = $mdDialog;

    /** @type {InviteCodeService} */
    this._inviteCodeService = InviteCodeService;

    this._proInviteCode = '';
    this._contractId = contractId;
    this._loading = true;
    this._absBaseUrl = `${LocationUtil.absRootUrl($location)}/account/login`;
    this._currentBaseUrl = LocationUtil.displayUrl(this._absBaseUrl);

    this.init();
  }

  get loading() {
    return this._loading;
  }

  get proInviteCode() {
    return this._proInviteCode;
  }

  get currentBaseUrl() {
    return this._currentBaseUrl;
  }

  get absBaseUrl() {
    return this._absBaseUrl;
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  init() {
    this._inviteCodeService
      .createInviteCode(this._contractId)
      .then((data) => {
        this._proInviteCode = data.code;
        this._loading = false;
      });
  }

  static show($mdDialog, contractId) {
    return $mdDialog.show({
      controller: InviteTeacherDialogController,
      template: InviteTeacherDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: true,
      locals: {
        contractId: contractId
      }
    });
  }

}
