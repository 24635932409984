'use strict';

import StudentSessionData from './student-session-data';
import AssignmentExportDialogController
  from '../../components/assignment-export-dialog/assignment-export-dialog.controller';
import {ElementIntents} from '../../model/domain/element-metadata';
import {UserRoles} from '../../model/domain/user';

export default class StudentAssignmentOverview {

  constructor($timeout, $state, $stateParams, $mdDialog, StudentCacheService, CacheService, BreadcrumbService,
              ExportService, PlatformHeaderService, helpInboxType) {
    this.$timeout = $timeout;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;

    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {ExportService} */
    this._exportService = ExportService;
    /** @type {PlatformHeaderService} */
    this._platformHeaderService = PlatformHeaderService;

    this._studentSessionData = undefined;
    this._assignmentSheetConfigs = new Map();
    this._loading = false;
    this._helpInboxType = helpInboxType;
    this._thumbnails = [];

    this._showExportAssignmentDialog = AssignmentExportDialogController.show;
  }

  /**
   * @return {boolean}
   */
  get loading() {
    return this._loading;
  }

  /**
   * @return {boolean}
   */
  get allowPeerHelp() {
    return this.roster && this.roster.allowPeerHelp;
  }

  /**
   * @return {Roster}
   */
  get roster() {
    return this.data && this.data.roster;
  }

  /**
   * @return {StudentSessionData}
   */
  get data() {
    return this._studentSessionData;
  }

  /**
   * @return {StudentWorkThumbnail[]}
   */
  get thumbnails() {
    return this._thumbnails;
  }

  /**
   * @param questionId {string}
   * @return {AssignmentSheetMetadata}
   */
  assignmentSheetConfig(questionId) {
    if (!this._assignmentSheetConfigs.has(questionId)) {
      this._assignmentSheetConfigs.set(
        questionId,
        this.data.createSheetConfig(questionId)
      );
    }

    return this._assignmentSheetConfigs.get(questionId);
  }

  /**
   * @param assignmentWork {AssignmentWork}
   * @param rosterId {string|null}
   * @return {Promise<StudentSessionData>}
   */
  init(assignmentWork, rosterId = null) {

    this.assignmentWorkId = assignmentWork.id;

    return this._studentCacheService
      .getSessionData(
        assignmentWork,
        this._cacheService
      )
      .then((sessionData) => {
        this._studentSessionData = sessionData;

        this._platformHeaderService.setAssignmentId(this._studentSessionData.assignment.id);
        const studentRosterId = this._studentSessionData.roster ? this._studentSessionData.roster.id : rosterId;
        this._platformHeaderService.setRosterId(studentRosterId);

        this.data.assignmentNotification.questionAdded.subscribe(this._handleAssignmentUpdated, this);
        this.data.assignmentNotification.questionRemoved.subscribe(this._handleAssignmentUpdated, this);
        this.data.assignmentNotification.questionUpdated.subscribe(this._handleQuestionUpdated, this);
        this.data.questionFeedbackList.updated.subscribe(this._handleFeedbackGivenUpdate, this);

        this._thumbnails = this.data.assignment.questions.map((question) => {
          return new StudentWorkThumbnail(this.data.assignmentWork, question.id);
        });

        this._loading = false;
      });
  }

  destroy() {
    this._platformHeaderService.setAssignmentId(undefined);
    this._platformHeaderService.setRosterId(undefined);

    if (this.data && this.data.assignmentNotification) {
      this.data.assignmentNotification.questionAdded.unsubscribe(this._handleAssignmentUpdated, this);
      this.data.assignmentNotification.questionRemoved.unsubscribe(this._handleAssignmentUpdated, this);
      this.data.assignmentNotification.questionUpdated.unsubscribe(this._handleQuestionUpdated, this);
    }

    if (this.data && this.data.questionFeedbackList) {
      this.data.questionFeedbackList.updated.unsubscribe(this._handleFeedbackGivenUpdate, this);
    }
  }

  /**
   * @param change {AssignmentStatusChange}
   */
  _handleQuestionUpdated(change) {
    let questionIndex = this.data.assignment.indexForQuestionId(change.questionId);
    let nextIndex = this.data.assignment.indexForQuestionId(change.beforeQuestionId);

    if (questionIndex !== nextIndex - 1) {
      this._handleAssignmentUpdated();
    }
  }

  _handleAssignmentUpdated() {
    StudentSessionData.launchAssignmentUpdatedDialog(this.$mdDialog).then(() => {
      this.$state.reload();
    });
  }

  /** Help Inbox and Request functionality **/

  /**
   * @return {HelpInboxMetadata}
   */
  get helpInboxMetadata() {
    return this.data && this.data.helpInboxMetadata(this._helpInboxType);
  }

  /** Unread Feedback Inbox Functionality **/

  _handleFeedbackGivenUpdate() {
    this.$timeout(() => {});
  }

  /**
   * @returns {string}
   */
  get assignmentTitle() {
    if (this.data && this.data.assignment) {
      return this.data.assignment.name;
    }
    return 'Loading...';
  }

  /**
   * @return {boolean}
   */
  get lockStudentWork() {
    if (this.data && this.data.classCode && angular.isDefined(this.data.classCode.lockStudentWork)) {
      return this.data.classCode.lockStudentWork;
    }
    return false;
  }

  exportToPDF() {
    let works = new Map().set(this.data.userId, this.data.assignmentWork);
    let students = [{ name: this.data.user.name, id: this.data.userId }];
    let assignmentWorks = this._exportService.createAssignmentExports(works, students, this.data.classCode, this.data.assignment, this.data.classCode.showStudentScores);

    this._showExportAssignmentDialog(this.$mdDialog, assignmentWorks, ElementIntents.WORK, UserRoles.STUDENT, true);
  }

}

class StudentWorkThumbnail {

  /**
   * @param assignmentWork {AssignmentWork}
   * @param questionId {string}
   */
  constructor(assignmentWork, questionId) {
    this._assignmentWork = assignmentWork;
    this._questionId = questionId;
    this._question = undefined;
    this._questionWork = undefined;
  }

  /**
   * @return {AssignmentQuestion}
   */
  get question() {
    if (!this._question) {
      this._question = this._assignmentWork.assignment.questionForId(this._questionId);
    }

    return this._question;
  }

  /**
   * @return {AssignmentWorkQuestion}
   */
  get questionWork() {
    if (!this._questionWork) {
      this._questionWork = this._assignmentWork.questionForId(this._questionId);
    }

    return this._questionWork;
  }

  /**
   * @return {number|string}
   */
  get currentPoints() {
    return this.questionWork.points;
  }

  /**
   * @param value {number|string}
   */
  set currentPoints(value) {
    this.questionWork.points = value;
  }

  /**
   * @return {number}
   */
  get potentialScore() {
    return this.question.points;
  }

  /**
   * @param value {number}
   */
  set potentialScore(value) {
    this.question.points = value;
  }

}
