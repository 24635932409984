
'use strict';

import FirebaseObject from '../firebase-mapping/firebase-object';
import FirebaseCollection from '../firebase-mapping/firebase-collection';
import ModelMapping from '../firebase-mapping/model-mapping';
import FirebaseCollectionMapping from '../firebase-mapping/firebase-collection-mapping';
import HelperCodec from '../codec/helper-codec';
import NotificationShardKey from './notification-shard-key.js';

let mapping = new ModelMapping('/notifications/v3/users/{userId}',
  {
    helpers: new FirebaseCollectionMapping(
      '/helpers',
      (instance) => {
        return instance._listen;
      },
      (instance) => {
        return instance.helpers;
      },
      new HelperCodec()
    )
  }
);

export default class HelpersNotification extends FirebaseObject {

  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param helpeeId {string}
   * @param FirebaseService {FirebaseService}
   * @param listen {boolean}
   */
  constructor(notificationShardKey, helpeeId, FirebaseService, listen = true) {
    let firebaseInstanceId = FirebaseService.firebaseNotificationInstanceIdForShardKey(notificationShardKey);
    let traceTag = 'HelpersNotification[instance=' + firebaseInstanceId + ', ' + notificationShardKey + ', helpeeId=' + helpeeId + ']';

    super(
      new Map().set('userId', helpeeId),
      mapping,
      firebaseInstanceId,
      FirebaseService,
      listen,
      undefined,
      traceTag
    );

    this._helpers = new FirebaseCollection();

    this._logTrace('cstr complete');
  }

  /**
   * @returns {FirebaseCollection.<Helper>}
   */
  get helpers() {
    return this._helpers;
  }
}
