'use strict';

import CanvasContextMenuTemplate from './canvas-context-menu.html';

export class ContextMenuOptions {

  static get Edit() {
    return 'edit';
  }

  static get Delete() {
    return 'delete';
  }

}

export default class CanvasContextMenuController {

  /**
   * @ngInject
   */
  constructor(mdPanelRef, deferred, ToolbarService) {
    this._mdPanelRef = mdPanelRef;
    this._deferred = deferred;

    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
  }

  /**
   * @param $mdPanel
   * @param $q
   * @param event
   * @return {Promise.<{userId: string, questionId: string, event: object}>}
   */
  static show($mdPanel, $q, event) {

    const position = $mdPanel.newPanelPosition()
      .absolute()
      .left(event.absoluteLocation.x - 8 + 'px')
      .top(event.absoluteLocation.y - 10 + 'px');

    const deferred = $q.defer();

    $mdPanel.open({
      position,
      template: CanvasContextMenuTemplate,
      controller: CanvasContextMenuController,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      panelClass: 'canvas-context-menu',
      locals: {
        deferred
      },
      onRemoving: () => {
        deferred.reject();
      }
    });

    return deferred.promise;
  }

  edit() {
    this._mdPanelRef.close();
    this._deferred.resolve(ContextMenuOptions.Edit);
  }

  delete() {
    this._mdPanelRef.close();
    this._deferred.resolve(ContextMenuOptions.Delete);
  }

}
