
'use strict';


import Codec from './codec';
import ElementCodec from './element-codec';
import PointArrayCodec from './point-array-codec';
import ColorCodec from './color-codec';

import Line from '../ui/elements/line';

/**
 * Encodes/Decodes a line
 */
export default class LineCodec extends Codec {
  constructor() {
    super();
    this._pointsCodec = new PointArrayCodec();
    this._colorCodec = new ColorCodec();
  }

  /**
   * @param value {Line}
   * @returns {{data: Object, metadata: Object}}
   */
  encode(value) {
    let data = {
      type: value.type,
      color: this._colorCodec.encode(value.color),
      points: this._pointsCodec.encode(value.points),
      width: value.width
    };
    return ElementCodec.firebaseElement(data, value.metadata);
  }

  /**
   * @param value {object}
   * @param key {string}
   * @returns {Line}
   */
  decode(value, key) {
    let metadata = ElementCodec.extractMetadata(value);

    return new Line(
      key,
      metadata,
      this._pointsCodec.decode(value.data.points),
      this._colorCodec.decode(value.data.color),
      value.data.width
    );
  }
}
