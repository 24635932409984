'use strict';

import moment from 'moment';
import Sorts from './sorts';

const UserSorts = {};
UserSorts.SORT_NAME_ASC = (a, b) => Sorts.NAME_ASC(a.name, b.name);
UserSorts.SORT_NAME_DESC = (a, b) => Sorts.NAME_DESC(a.name, b.name);

export class UserProperties {

  /**
   * @returns {string}
   */
  static get SCHOOL () {
    return 'school';
  }

  /**
   * @returns {string}
   */
  static get COUNTRY() {
    return 'country';
  }

  /**
   * @returns {string}
   */
  static get STATE() {
    return 'state';
  }

  /**
   * @returns {string}
   */
  static get CITY() {
    return 'city';
  }

  /**
   * @returns {string}
   */
  static get SUBJECT() {
    return 'subject';
  }

  /**
   * @returns {string}
   */
  static get STUDENT_AGE() {
    return 'student_age';
  }

  /**
   * @returns {string}
   */
  static get REFERRAL () {
    return 'referral';
  }

  /**
   * @returns {string}
   */
  static get JOB_TITLE () {
    return 'job_title';
  }

  /**
   * @returns {string}
   */
  static get MIGRATION () {
    return 'migration';
  }

  /**
   * @returns {string}
   */
  static get LEGACY_ID () {
    return 'legacy_id';
  }

  /**
   * @returns {string}
   */
  static get MIXPANEL_ID () {
    return 'mixpanel_id';
  }

  /**
   * @returns {string}
   */
  static get SESSION_VIEW_SHOW_WORK() {
    return 'session_view_show_work';
  }

  /**
   * @return {string}
   */
  static get SHOW_FILL_IN_THE_BLANK_INTRO() {
    return 'show_fill_in_the_blank_intro';
  }

  /**
   * @return {string}
   */
  static get SHOW_MULTIPLE_CHOICE_INTRO() {
    return 'show_mc_intro';
  }

  /**
   * @return {string}
   */
  static get HELP_DIALOGS_VIEWED() {
    return 'help_dialogs_viewed';
  }

  /**
   * @return {string}
   */
  static get LAST_VIEWED_LATEST_FEATURES() {
    return 'last_viewed_latest_features';
  }

  /**
   * @return {string}
   */
  static get SHOW_SESSION_REFACTOR_DIALOG() {
    return 'show_session_refactor_dialog';
  }

  /**
   * @return {string}
   */
  static get ASSIGNMENTS_LIMIT() {
    return 'assignments_limit';
  }

  static get ASSIGNMENTS_LIMIT_NOTIFICATION_DATE() {
    return 'assignment_limit_notification_date';
  }

  static get SHOW_CLASSKICK_INTRO_VIDEO() {
    return 'show_classkick_intro_video';
  }

  /**
   * @returns {{COMPLETE: string, ERROR: string, WORKING: string}}
   */
  static get MIGRATION_VALUES() {
    return {
      COMPLETE: 'complete',
      ERROR: 'error',
      WORKING: 'working'
    };
  }

  /**
   * @returns {string[]}
   */
  static get PREFIX_OPTIONS() {
    return [
      'Ms.',
      'Mrs.',
      'Mr.',
      'Mx.',
      'Miss',
      'Coach',
      'Prof.',
      'Dr.'
    ];
  }

  /**
   * @returns {string[]}
   */
  static get SUBJECT_OPTIONS() {
    return [
      'Math',
      'ELA',
      'Science',
      'Social Studies',
      'Foreign Language'
    ];
  }

  /**
   * @returns {string[]}
   */
  static get STUDENT_AGE_OPTIONS() {
    return [
      '0-6',
      '6-9',
      '9-12',
      '12-15',
      '15-18',
      '18+'
    ];
  }

  /**
   * @returns {string[]}
   */
  static get JOB_TITLE_OPTIONS() {
    return [
      'Teacher',
      'Administrator',
      'Coach',
      'Parent',
      'Social Worker',
      'Tutor',
      'Other'
    ];
  }

  /**
   * @returns {string[]}
   */
  static get REFERRAL_OPTIONS() {
    return [
      'A Colleague',
      'My Administration',
      'PD / Training',
      'Social Media',
      'The Internet'
    ];
  }

  /**
   * List of countries from https://www.countries-ofthe-world.com/all-countries.html
   * @returns {string[]}
   */
  static get COUNTRY_OPTIONS() {
    return [
      'United States of America (USA)',
      'United Kingdom (UK)',
      'Afghanistan',
      'Albania',
      'Algeria',
      'Andorra',
      'Angola',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bhutan',
      'Bolivia',
      'Bosnia and Herzegovina',
      'Botswana',
      'Brazil',
      'Brunei',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cabo Verde',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Central African Republic (CAR)',
      'Chad',
      'Chile',
      'China',
      'Colombia',
      'Comoros',
      'Democratic Republic of the Congo',
      'Republic of the Congo',
      'Costa Rica',
      'Cote d\'Ivoire',
      'Croatia',
      'Cuba',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Fiji',
      'Finland',
      'France',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Greece',
      'Grenada',
      'Guatemala',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Honduras',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran',
      'Iraq',
      'Ireland',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Kosovo',
      'Kuwait',
      'Kyrgyzstan',
      'Laos',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macedonia',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Mauritania',
      'Mauritius',
      'Mexico',
      'Micronesia',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Morocco',
      'Mozambique',
      'Myanmar (Burma)',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'North Korea',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Palestine',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Poland',
      'Portugal',
      'Qatar',
      'Romania',
      'Russia',
      'Rwanda',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Vincent and the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome and Principe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Korea',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Swaziland',
      'Sweden',
      'Switzerland',
      'Syria',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'Timor-Leste',
      'Togo',
      'Tonga',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates (UAE)',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Vatican City (Holy See)',
      'Venezuela',
      'Vietnam',
      'Yemen',
      'Zambia',
      'Zimbabwe'
    ];
  }

  static get STATE_OPTIONS() {
    return [
      'AL',
      'AK',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DE',
      'DC',
      'FL',
      'GA',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'ME',
      'MD',
      'MA',
      'MI',
      'MN',
      'MS',
      'MO',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
      'other'
    ];
  }
}

export class UserRoles {
  /**
   * @returns {string}
   */
  static get TEACHER() {
    return 'teacher';
  }

  /**
   * @returns {string}
   */
  static get STUDENT() {
    return 'student';
  }

  /**
   * @returns {string}
   */
  static get ADMIN() {
    return 'admin';
  }
}

export class UserPreferences {
  /**
   * @returns {string[]}
   */
  static get GRADE_OPTIONS() {
    return [
      'Pre-K (3-5 years old)',
      'Kindergarten (5-6 years old)',
      '1st (6-7 years old)',
      '2nd (7-8 years old)',
      '3rd (8-9 years old)',
      '4th (9-10 years old)',
      '5th (10-11 years old)',
      '6th (11-12 years old)',
      '7th (12-13 years old)',
      '8th (13-14 years old)',
      '9th (14-15 years old)',
      '10th (15-16 years old)',
      '11th (16-17 years old)',
      '12th (17-18 years old)',
      'College (18+ years old)'
    ];
  }
}

/**
 * Contains a user's information
 */
export default class User {

  /**
   * @param id {string} the user id
   * @param username {string} a user's username
   * @param email {string} a user's email
   * @param firstName {string} a user's first name
   * @param lastName {string} a user's last name
   * @param displayName {string} a user's display name
   * @param created {string} a user's created at date
   * @param properties {Object} an object with key/value pairs of user properties
   * @param roles {string[]}
   * @param contractMembers {Map[]}
   * @param authTypes {Array}
   */
  constructor(id, username, email, firstName, lastName, displayName, created, properties, roles, contractMembers, authTypes) {
    this._id = id;
    this._username = username;
    this._email = email;
    this._firstName = firstName;
    this._lastName = lastName;
    this._displayName = displayName;
    this._created = created ? moment(created) : undefined;
    this._properties = properties || {};
    this._roles = roles;
    this._contractMembers = contractMembers;
    this._authTypes = authTypes;
  }

  /**
   * @returns {string} the user id
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {string} a user's username
   */
  get username() {
    return this._username;
  }

  /**
   * @param value {string}
   */
  set username(value) {
    this._username = value;
  }

  /**
   * @param value {string}
   */
  set email(value) {
    this._email = value;
  }

  /**
   * @returns {string} a user's email
   */
  get email() {
    return this._email;
  }

  /**
   * @param value {string}
   */
  set firstName(value) {
    this._firstName = value || null;
  }

  /**
   * @returns {string} a user's first name
   */
  get firstName() {
    return this._firstName;
  }

  /**
   * @param value {string}
   */
  set lastName(value) {
    this._lastName = value || null;
  }

  /**
   * @returns {string} a user's last name
   */
  get lastName() {
    return this._lastName;
  }

  /**
   * @param value {string}
   */
  set displayName(value) {
    this._displayName = value || null;
  }

  /**
   * @returns {string} a user's display name
   */
  get displayName() {
    return this._displayName;
  }

  /**
   * @returns {moment}
   */
  get created() {
    return this._created;
  }

  /**
   * @return {boolean}
   */
  get isNewUser() {
    return this._wasCreatedWithin(30, 'days');
  }

  /**
   * @return {boolean}
   */
  get justSignedUp() {
    return this._wasCreatedWithin(1, 'days');
  }

  /**
   * @param value {number}
   * @param unit {string}
   * @return {boolean|*}
   * @private
   */
  _wasCreatedWithin(value, unit) {
    if (!this._created) {
      return false;
    }
    else {
      let past = moment().subtract(value, unit);
      let now = moment();
      return this._created.isBetween(past, now);
    }
  }

  /**
   * @returns {Object}
   */
  get properties() {
    return this._properties;
  }

  /**
   * @returns {Map[]}
   */
  get contractMembers() {
    return this._contractMembers;
  }

  /**
   * @returns {Array}
   */
  get authTypes() {
    return this._authTypes;
  }

  /**
   * @returns {boolean}
   */
  get isStudent() {
    return this._roles.includes(UserRoles.STUDENT);
  }

  /**
   * @returns {boolean}
   */
  get isTeacher() {
    return this._roles.includes(UserRoles.TEACHER);
  }

  /**
   * @returns {boolean}
   */
  get isClever() {
    return this.authTypes.includes('clever-import');
  }

  //--------------------- Property Getters / Setters --------------------------//

  /**
   * @returns {string}
   */
  get school() {
    return this._properties[UserProperties.SCHOOL];
  }

  /**
   * @param value {string}
   */
  set school(value) {
    this._setProperty(UserProperties.SCHOOL, value);
  }

  /**
   * @returns {string}
   */
  get country() {
    return this._properties[UserProperties.COUNTRY];
  }

  /**
   * @param value {string}
   */
  set country(value) {
    this._setProperty(UserProperties.COUNTRY, value);
  }

  /**
   * @returns {string}
   */
  get state() {
    return this._properties[UserProperties.STATE];
  }

  /**
   * @param value {string}
   */
  set state(value) {
    this._setProperty(UserProperties.STATE, value);
  }

  /**
   * @returns {string}
   */
  get city() {
    return this._properties[UserProperties.CITY];
  }

  /**
   * @param value {string}
   */
  set city(value) {
    this._setProperty(UserProperties.CITY, value);
  }

  /**
   * @returns {string} One of @UserProperties.JOB_TITLE_OPTIONS or freeform
   */
  get jobTitle() {
    return this._properties[UserProperties.JOB_TITLE];
  }

  /**
   * @param value {string} One of @UserProperties.JOB_TITLE_OPTIONS or freeform
   */
  set jobTitle(value) {
    this._setProperty(UserProperties.JOB_TITLE, value);
  }

  /**
   * @returns {string} One of @UserProperties.SUBJECT_OPTIONS or freeform
   */
  get subject() {
    return this._properties[UserProperties.SUBJECT];
  }

  /**
   * @param value {string} One of @UserProperties.SUBJECT_OPTIONS or freeform
   */
  set subject(value) {
    this._setProperty(UserProperties.SUBJECT, value);
  }

  /**
   * @returns {string} One of @UserProperties.STUDENT_AGE_OPTIONS
   */
  get studentAge() {
    return this._properties[UserProperties.STUDENT_AGE];
  }

  /**
   * @param value {string} One of @UserProperties.STUDENT_AGE_OPTIONS
   */
  set studentAge(value) {
    this._setProperty(UserProperties.STUDENT_AGE, value);
  }

  /**
   * @returns {string} One of @UserProperties.REFERRAL_OPTIONS or freeform
   */
  get referral() {
    return this._properties[UserProperties.REFERRAL];
  }

  /**
   * @param value {string} One of @UserProperties.REFERRAL_OPTIONS or freeform
   */
  set referral(value) {
    this._setProperty(UserProperties.REFERRAL, value);
  }

  /**
   * @returns {string} One of @UserProperties.REFERRAL_OPTIONS or freeform
   */
  get migration() {
    return this._properties[UserProperties.MIGRATION];
  }

  /**
   * @returns {boolean}
   */
  get sessionViewShowWork() {
    return !this._properties[UserProperties.SESSION_VIEW_SHOW_WORK] || this._properties[UserProperties.SESSION_VIEW_SHOW_WORK] === true.toString();
  }

  /**
   * @param value {boolean}
   */
  set sessionViewShowWork(value) {
    this._setProperty(UserProperties.SESSION_VIEW_SHOW_WORK, value.toString());
  }

  /**
   * @returns {boolean}
   */
  get showClasskickIntroVideo() {
    return !this._properties[UserProperties.SHOW_CLASSKICK_INTRO_VIDEO] || this._properties[UserProperties.SHOW_CLASSKICK_INTRO_VIDEO] === true.toString();
  }

  /**
   * @param value {boolean}
   */
  set showClasskickIntroVideo(value) {
    this._setProperty(UserProperties.SHOW_CLASSKICK_INTRO_VIDEO, value.toString());
  }

  /**
   * @return {boolean}
   */
  get showFillInTheBlankIntro() {
    return !this._properties[UserProperties.SHOW_FILL_IN_THE_BLANK_INTRO] || this._properties[UserProperties.SHOW_FILL_IN_THE_BLANK_INTRO] === true.toString();
  }

  /**
   * @param value {boolean}
   */
  set showFillInTheBlankIntro(value) {
    this._setProperty(UserProperties.SHOW_FILL_IN_THE_BLANK_INTRO, value.toString());
  }

  /**
   * @return {boolean}
   */
  get showMultipleChoiceIntro() {
    return !this._properties[UserProperties.SHOW_MULTIPLE_CHOICE_INTRO] || this._properties[UserProperties.SHOW_MULTIPLE_CHOICE_INTRO] === true.toString();
  }

  /**
   * @param value {boolean}
   */
  set showMultipleChoiceIntro(value) {
    this._setProperty(UserProperties.SHOW_MULTIPLE_CHOICE_INTRO, value.toString());
  }

  /**
   * @return {boolean}
   */
  get showSessionRefactorHelpDialog() {
    return !this._properties[UserProperties.SHOW_SESSION_REFACTOR_DIALOG] || this._properties[UserProperties.SHOW_SESSION_REFACTOR_DIALOG] === true.toString();
  }

  /**
   * @param value {boolean}
   */
  set showSessionRefactorHelpDialog(value) {
    this._setProperty(UserProperties.SHOW_SESSION_REFACTOR_DIALOG, value.toString());
  }

  /**
   * @return {undefined|object}
   */
  get helpDialogsViewed() {
    let helpDialogsViewedRaw = this._properties[UserProperties.HELP_DIALOGS_VIEWED];
    return helpDialogsViewedRaw && angular.fromJson(helpDialogsViewedRaw);
  }

  /**
   * @param value {object}
   */
  set helpDialogsViewed(value) {
    this._setProperty(UserProperties.HELP_DIALOGS_VIEWED, angular.toJson(value));
  }

  /**
   * @returns {string}
   */
  get assignmentLimitNotificationDate() {
    return this._properties[UserProperties.ASSIGNMENTS_LIMIT_NOTIFICATION_DATE];
  }

  /**
   * @param value {string}
   */
  set assignmentLimitNotificationDate(value) {
    this._setProperty(UserProperties.ASSIGNMENTS_LIMIT_NOTIFICATION_DATE, value);
  }

  /**
   * @param dialogId {string} place_textbox|place_fitb|place_sline
   * @return {boolean}
   */
  getHelpDialogViewed(dialogId) {
    let helpDialogsViewed = this.helpDialogsViewed;
    if (helpDialogsViewed && angular.isDefined(helpDialogsViewed[dialogId])) {
      return helpDialogsViewed[dialogId];
    }
    else {
      return false;
    }
  }

  /**
   * @param id {string}
   * @param value {boolean}
   */
  setHelpDialogViewed(id, value) {
    let current = this.helpDialogsViewed || {};
    current[id] = value;
    this.helpDialogsViewed = current;
  }

  /**
   * @return {string}
   */
  get lastViewedLatestFeatures() {
    return this._properties[UserProperties.LAST_VIEWED_LATEST_FEATURES];
  }

  /**
   * @param value {string}
   */
  set lastViewedLatestFeatures(value) {
    this._setProperty(UserProperties.LAST_VIEWED_LATEST_FEATURES, value);
  }

  /**
   * @param key {string}
   * @param value {string}
   * @private
   */
  _setProperty(key, value) {
    this._properties[key] = value || null;
  }

  //--------------------- Utility Methods -------------------------------------//

  /**
   * @returns {string} Representing the best name we have available
   */
  get name() {
    if (this._displayName) {
      return this._displayName;
    }
    else if (this._firstName || this._lastName) {
      return this.firstLastCombo;
    }
    else if (this._username) {
      return this._username;
    }
    else if (this._email) {
      return this._email;
    }
    else {
      return 'User';
    }
  }

  /**
   * Returns the contract name.
   *
   * @returns {string}
   */
  get contractName() {
    if (this.school) {
      return `${this.name} at ${this.school}`;
    }
    else {
      return this.name;
    }
  }

  get firstLastCombo() {
    let firstName = this._firstName || '';
    let lastName = this._lastName || '';
    let space = firstName && lastName ? ' ' : '';
    return `${firstName}${space}${lastName}`;
  }

  /**
   * @returns {string} Email if exists, otherwise username
   */
  get login() {
    if (this._email) {
      return this._email;
    }
    else {
      return this._username;
    }
  }

  /**
   * @returns {boolean} user is an anonymous user
   */
  get userIsAnonymous() {
    return /[A-Z\d]{20,50}\|.+/g.test(this.username);
  }

  /**
   * @returns {(function(User, User))}
   */
  static get SORT_NAME_ASC() {
    return UserSorts.SORT_NAME_ASC;
  }

  /**
   * @returns {(function(User, User))}
   */
  static get SORT_NAME_DESC() {
    return UserSorts.SORT_NAME_DESC;
  }

  getAssignmentLimitCountdown(compareDate) {
    if (this.assignmentLimitNotificationDate) {
      const expiration = moment(this.assignmentLimitNotificationDate).add(30, 'days');
      return expiration && expiration.diff(compareDate, 'days');
    }
    return null;
  }

}
