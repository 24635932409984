'use strict';

import TextboxBase, { BorderStyle } from './textbox-base';
import FillInTheBlankParent, { FillInTheBlankAnswer } from './fill-in-the-blank-parent';
import FITBHandle from '../fitb-acceptable-answer-manager';
import Point from './../point';
import Size from '../size';
import HexColors from '../../../css-constants';
import { UserRoles } from '../../domain/user';
import ElementMetadata, { ElementIntents } from '../../domain/element-metadata';
import checkIcon from '../../../../assets/colored-icons/check-dark.svg';
import closeIcon from '../../../../assets/colored-icons/close-dark.svg';
import { FitbAnswerTypes } from '../../../components/assignment-toolbar/assignment-toolbar.directive';
import { MathfieldElement } from 'mathlive';

export default class FillInTheBlankChild extends TextboxBase {

  /**
   * @param id {string}
   * @param metadata {ElementMetadata}
   * @param location {Point}
   * @param size {Size}
   * @param answers {FillInTheBlankAnswer[]}
   * @param fontSize {number}
   * @param colorSpans {ColorSpan[]}
   * @param answer {string}
   */
  constructor(
    id,
    metadata,
    location,
    size,
    answers,
    fontSize,
    colorSpans,
    answer,
    format = FitbAnswerTypes.PLAIN.value,
  ) {
    super(id, FillInTheBlankChild.type, metadata, format);

    this._location = location || this._location;
    this._size = size;
    this._parentSize = size || this._size;
    this._fontSize = fontSize || this._fontSize;
    this._minSize = new Size(0, 0);
    this._padding = new Size(7, 7);
    this._colorSpans = colorSpans || this._colorSpans;
    this._text = answer || this._text;
    this._answers = answers;
    this._submitted = !!this._text;
    this._placeholderText = '';
    this._borderColor = HexColors.CK_GREEN;
    this._editBoxBorderColor = 'transparent';
    this._format = format;
    this._displayMathKeyboard = false;

    this._enableResize = false;
    this._enableRemove = false;
    this._enableReposition = false;

    this.blurred.subscribe((ev) => this._handleBlurred(ev), this);
  }

  /**
   * @return {string}
   */
  static get type() {
    return 'fitb_child';
  }

  /**
   * @return {boolean}
   */
  get isChildManipulative() {
    return true;
  }

  get answerDisplay() {
    return this._text || '--';
  }

  resetFontSize() {
    if (!this.text) {
      let fontSize = this._fontSize;
      while (fontSize > (this.height - this._padding.height - this._padding.height)) {
       fontSize -= TextboxBase.FONT_INCREMENT;
      }
      this._fontSize = fontSize;
    }
  }

  /**
   * @return {FillInTheBlankAnswer[]}
   */
  get answers() {
    return this._answers;
  }

  /**
   * @return {string}
   */
  get answer() {
    return this._text;
  }

  /**
   * @param value {string}
   */
  set answer(value) {
    this._text = value;
  }

  get scoredPoints() {
    return this.isCorrect ? this.pointValue : 0;
  }

  get scoredPointsDisplay() {
    return angular.isNumber(this.scoredPoints) ? this.scoredPoints : '--';
  }

  get pointValue() {
    return this.hasPotentiallyCorrectResponse ? this.answers[0].points : undefined;
  }

  get pointOrPointsDisplay() {
    return this.pointValue === 1 ? 'Pt' : 'Pts';
  }

  get showScoreboardText() {
    return angular.isNumber(this.pointValue);
  }

  get format() {
    return this._format;
  }

  _handleBlurred() {
    this._submitted = !!this.text;
    this.tryUpdate();
  }

  tabPressed() {
    this.focusNextElement.raise();
  }

  shiftTabPressed() {
    this.focusPrevElement.raise();
  }

  /**
   * Fill in the blank children are not able to be repositioned.
   * When we encounter a drag, focus the element and pass the data
   * to the superclass.
   * @param data
   * @private
   */

  _dragStart(data) {
    this.focus();
    super._dragStart(data);
  }

  /**
   * @param text {string}
   * @param startIndex {string}
   * @param endIndex {string}
   */
  setText(text, startIndex, endIndex) {
    this._submitted = false;
    super.setText(text, startIndex, endIndex);
  }

  createElement(root, editable) {
    super.createElement(root, editable);

    this._scoreboard = this._interactive.group();
    this._scoreboardRect = this._scoreboard.rect(0, 0, 0, 0);
    this._scoreboardText = this._scoreboard.text(0, 0, 'test');
    this._scoreboardCheckIcon = this._scoreboard.image(checkIcon, 0, 0, 0, 0);
    this._scoreboardCloseIcon = this._scoreboard.image(closeIcon, 0, 0, 0, 0);

    if (this.classCode && this.classCode.showStudentScoresUpdated) {
      this.classCode.showStudentScoresUpdated.subscribe(this._showStudentScoresUpdated, this);
    }
  }

  update(root, editable) {
    this._borderColor = this.currentBorderColor;
    this._borderStyle = this.showSelected ? BorderStyle.SOLID : BorderStyle.DASHED;
    this._editBoxBorderColor = this.currentBorderColor;

    super.update(root, editable);

    const height = Math.max(this._parentSize.height, this.height);

    this._backgroundRect.attr({
      height
    });

    this._editBox.attr({
      height
    });

    if (editable) {
      this._interactRect.attr({
        height,
        cursor: 'text'
      });
    }

    this._scoreboard.attr({
      visibility: (this.showCorrect || this.showIncorrect) ? 'inherit' : 'hidden'
    });

    const scoreboardPadding = 5;
    const scoreboardHeight = 21;
    const scoreboardContent = scoreboardHeight - scoreboardPadding - scoreboardPadding;

    this._scoreboardText.attr({
      text: this.getScoreBoardText(),
      dominantBaseline: 'text-before-edge',
      fontFamily: 'GothamRoundedMedium',
      fontSize: `${scoreboardContent}px`,
      fill: HexColors.CK_ELEMENT_BLACK
    });

    const scoreboardTextWidth = this._scoreboardText.node.getBBox().width;
    const scoreboardWidth = scoreboardTextWidth ? scoreboardTextWidth + scoreboardPadding + scoreboardPadding : scoreboardHeight;
    const scoreboardX = this.location.x + this.width - scoreboardWidth + 1;
    const scoreboardY = this.location.y - scoreboardHeight + this._padding.height - 1;

    this._scoreboardRect.attr({
      x: scoreboardX,
      y: scoreboardY,
      rx: 2,
      ry: 2,
      width: scoreboardWidth,
      height: scoreboardHeight,
      fill: this.currentBorderColor
    });

    this._scoreboardText.attr({
      x: scoreboardX + scoreboardPadding,
      y: scoreboardY + scoreboardPadding
    });

    const iconAttributes = {
      x: scoreboardX + scoreboardPadding,
      y: scoreboardY + scoreboardPadding,
      width: scoreboardContent,
      height: scoreboardContent,
      pointerEvents: 'none',
      preserveAspectRatio: 'xMidYMid meet'
    };

    //TODO: setting this to hidden for now until we can fix the X
    // and check mark icon in PDF view, using emoji check and X for now
    this._scoreboardCheckIcon.attr({
      ...iconAttributes,
      visibility: 'hidden'
    });

    this._scoreboardCloseIcon.attr({
      ...iconAttributes,
      visibility: 'hidden'
    });

    //when user switches from STEM to plain, it should remove the math-field element tied to this FITB
    if (this._format === FitbAnswerTypes.PLAIN.value){
      const mathFields = document.querySelectorAll(`foreignObject.fo-${this.id}`);
      if (mathFields.length) {
        Array.from(mathFields).forEach((element) => {
          element.remove();
        });
      }
    }

    if (this._format === FitbAnswerTypes.SCIENTIFIC.value) {
      const parentElements = Array.from(document.getElementsByClassName(this.id));
      if (parentElements.length) {
        this._createOrUpdateForeignObjectAndMathFieldElements(parentElements, editable);
      }
    }

    // provide notification to the teacher when child response does not match list of acceptable answers
    if (this.viewerIsTeacher && (this._text !== '') && (this._answers.length > 0)) {
      this._fitbHandle = new FITBHandle(this);
      this._fitbHandle.render(this._interactive);
      this._fitbHandle.location = this.location.plus(new Point(-27, Math.max(this._parentSize.height, this.height) - 24));
      this._fitbHandle.visibility = this.isCorrect ? 'hidden' : 'visible';
    }

    // handle case where student resets a given answer; then remove the notification
    if ((this._text === '') && this._fitbHandle && (this._answers.length > 0)) {
      this._fitbHandle.visibility = 'hidden';
    }
  }

  getScoreBoardText () {
    if (this.showScoreboardText) {
      return `${this.scoredPointsDisplay}/${this.pointValue} ${this.pointOrPointsDisplay}`;
    }
    else if (this.showCorrect){
      return '✔️';
    } else if (this.showIncorrect){
      return '✖️';
    } else {
      return '';
    }
  }

  // prepare data in format to update list of acceptable answers
  trackIncorrectResponseToBeMarkedCorrect() {
    const studentResponse = this._text.trim().toLowerCase();
    const assignmentId = this._assignment.assignment.id;

    const newFillInTheBlankAnswer = new FillInTheBlankAnswer(
      studentResponse,
      this._answers[0].points
    );

    // add the new response into accepted answers
    const newAnswerArray = [];
    newAnswerArray.push(this._answers);
    newAnswerArray.push(newFillInTheBlankAnswer);

    let elementMetaData = new ElementMetadata(
      this.viewerMetadata['userId'],
      'teacher',
      'work'
    );

    const newFillInTheBlankParent = new FillInTheBlankParent(
      this.id,
      elementMetaData,
      this._location,
      this._size,
      newAnswerArray.flat(),
      this._fontSize,
      this._format
    );
    // newFillInTheBlankParent._answers = newAnswerArray.flat();

    return {
      childElementId: this.id,
      assignmentId: assignmentId,
      newFillInTheBlankParent: newFillInTheBlankParent,
      studentResponse: studentResponse
    };
  }

  _showStudentScoresUpdated() {
    this.tryUpdate();
  }

  get currentBorderColor() {
    if (this.showCorrect) {
      return HexColors.CK_GRADING_MEETS;
    }
    else if (this.showIncorrect) {
      return HexColors.CK_GRADING_BELOW;
    }
    else if (this.showSelected) {
      return HexColors.CK_ELEMENT_GREY;
    }
    else {
      return HexColors.CK_GREEN;
    }
  }

  get showCorrect() {
    return this.canShowCorrectness && this.isCorrect;
  }

  get showIncorrect() {
    return this.canShowCorrectness && !this.isCorrect && !!this.text;
  }

  get canShowCorrectness() {
    if (this.viewerIsTeacher || this.viewerIsNotMainTeacher) {
      return this.hasPotentiallyCorrectResponse;
    }
    else if (this.viewerIsStudentDoingWork) {
      return (this._submitted || !!this._text) && this.showStudentScores && this.hasPotentiallyCorrectResponse;
    }
    else {
      return false;
    }
  }

  get hasPotentiallyCorrectResponse() {
    return this._answers.length > 0;
  }

  get showSelected() {
    return this.hasFocus || !!this.text;
  }

  get viewerIsStudentDoingWork() {
    return this.viewerIsDoingWork && this.viewerIsStudent;
  }

  get viewerIsDoingWork() {
    return this.viewerIntent && this.viewerIntent === ElementIntents.WORK;
  }

  /**
   * @return {boolean}
   */
  get viewerIsTeacher() {
    return this.viewerRole && this.viewerRole === UserRoles.TEACHER && this.viewerIntent === ElementIntents.FEEDBACK;
  }

  /**
   * @return {boolean}
   */
  get viewerIsNotMainTeacher() {
    return this.viewerRole && this.viewerRole === UserRoles.TEACHER && this.viewerIntent === ElementIntents.VIEW;
  }

  /**
   * @return {boolean}
   */
  get viewerIsStudent() {
    return this.viewerRole && this.viewerRole === UserRoles.STUDENT;
  }

  /**
   * @return {boolean}
   */
  get showStudentScores() {
    return this.classCode && this.classCode.showStudentScores;
  }

  /**
   * @return {ClassCode}
   */
  get classCode() {
    return this.assignment && this.assignment.classCode;
  }

  get isCorrect() {
    return this._answers.some((answer) => {
      let acceptableAnswer = answer.answer.trim().toLowerCase();
      let studentResponse = this._text.trim().toLowerCase();
      return studentResponse === acceptableAnswer;
    });
  }

  get potentialScore() {
    return this._answers.map((answer) => answer.points).reduce((accum, answerPoints) => {
      return accum > answerPoints ? accum : answerPoints;
    });
  }

  remove() {
    if (this.classCode) {
      this.classCode.showStudentScoresUpdated.unsubscribe(this._showStudentScoresUpdated, this);
    }
    super.remove();
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {FillInTheBlankChild}
   * @param forceUpdate {boolean}
   */
  merge(other, forceUpdate) {
    this._metadata = other._metadata || this._metadata;
    this._location = other._location || this._location;
    this._size = other._size || this._size;
    this._parentSize = other._size || this._parentSize;
    this._answers = other._answers || this._answers;
    this._fontSize = other._fontSize || this._fontSize;
    this._colorSpans = other._colorSpans || this._colorSpans;
    this._format = other._format || this._format;
    // Use other text when undoing/redoing (aka forceUpdate) or when the viewer of this fitb is a teacher or peer giving feedback
    this._text = (angular.isDefined(other._text) && (forceUpdate || !this.viewerIsStudentDoingWork)) ? other._text : this._text;

    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _location: this._location,
      _size: this._size,
      _answers: this._answers,
      _fontSize: this._fontSize,
      _colorSpans: this._colorSpans,
      _answer: this._text,
      _text: this._text
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {FillInTheBlankChild}
   */
  fromSnapshot(id, snapshot) {
    return new FillInTheBlankChild(
      id,
      snapshot._metadata,
      snapshot._location,
      snapshot._size,
      snapshot._answers,
      snapshot._fontSize,
      snapshot._colorSpans,
      snapshot._answer,
      snapshot._format
    );
  }

  /**
   * @param element {Element}
   * @param editable {boolean}
   */
  _createForeignObjectAndMathFieldElement(element, editable){
    const ns = 'http://www.w3.org/2000/svg';
    const foreignObject = document.createElementNS(ns, 'foreignObject');
    foreignObject.classList.add(`fo-${this.id}`);

    //set math-field value to text value
    const mfe = new MathfieldElement();
    mfe.value = this._text;

    //add math-field to foreign object and then foreign object to body
    foreignObject.append(mfe);
    element.append(foreignObject);

    //set attributes and styling to math-field element
    this._applyStylingAndAttributesToForeignObjectAndMathFieldElements(element, editable);

    //listens for changes to math-field height and then updates foreignObject height to match
    const foElements = Array.from(document.getElementsByClassName(`fo-${this.id}`));
    foElements.forEach((foElement) => {
      const mfeDomElement = foElement.getElementsByClassName(`mf-${this.id}`)[0];
      if (mfeDomElement) {
        const resizeObserver = new ResizeObserver(() => {
          foElement.setAttribute('height', mfeDomElement.offsetHeight.toString());
          if (this._fitbHandle) {
            this._fitbHandle.location = this.location.plus(new Point(-27, mfeDomElement.offsetHeight - 24));
          }
        });
        resizeObserver.observe(mfeDomElement);
      }
    });

    const keyboard = mfe.shadowRoot.querySelector('.ML__virtual-keyboard-toggle');
    const textElem = document.createElement('span');
    keyboard.parentNode.insertBefore(textElem, keyboard);
    textElem.innerHTML = 'Toggle Virtual Keyboard';
    textElem.classList.add('tooltiptext');

    const tooltipStyling = `
      background-color:#616161;
      color:#fff;
      padding:8px;
      font-size:11px;
      width:135px;
      visibility:hidden;
      position:absolute;
      right: 44px;
      top: 50%;
      transform: translate(0%, -50%);
      box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
      text-align: center;
      transition: all 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;`;

    textElem.style = tooltipStyling;

    this._addMathFieldEventListeners(mfe, editable);
  }

  /**
   * @param parentElements {Element[]}
   * @param editable {boolean}
   */
  _createOrUpdateForeignObjectAndMathFieldElements(parentElements, editable) {
    parentElements.forEach((parentElement) => {
      const interactiveElements = parentElement.getElementsByClassName('interactive');

      Array.from(interactiveElements).forEach((element) => {
        const mathItem = element.getElementsByTagName('math-field');

        if (element && this._format === FitbAnswerTypes.SCIENTIFIC.value && !mathItem.length) {
          this._createForeignObjectAndMathFieldElement(element, editable);
        } else {
          this._applyStylingAndAttributesToForeignObjectAndMathFieldElements(element, editable);
        }
      });
    });
  }

  /**
   * @param element {Element}
   * @param editable {boolean}
   */
  _applyStylingAndAttributesToForeignObjectAndMathFieldElements(element, editable){
    const foreignObject = element.querySelectorAll(`.fo-${this.id}`)[0];
    const mathFieldElement = element.getElementsByTagName('math-field')[0];

    if (foreignObject) {
      foreignObject.setAttribute('x', this.location.x+1);
      foreignObject.setAttribute('y', this.location.y+4);
      foreignObject.setAttribute('width', `${this.width}`);
      foreignObject.setAttribute('height', `${element.getElementsByTagName('math-field')[0].offsetHeight +1}`);
    }
    
    const borderStyle = this._text ? BorderStyle.SOLID : BorderStyle.DASHED;
    mathFieldElement.value = this._text;
    mathFieldElement.style.border = `2px ${borderStyle} ${this.currentBorderColor}`;
    mathFieldElement.style.borderStyle = BorderStyle.DEFAULT_DASH_SPACING;
    mathFieldElement.classList.add(`mf-${this.id}`);
    mathFieldElement.style.fontSize = '2.5rem';
    mathFieldElement.style.height = 'auto';
    mathFieldElement.style.width = `${this.width-12}px`;
    mathFieldElement.readonly = editable ? false: true;
  }

  /**
   * @param text {string}
   */
  _setMathText(text) {
    this._text = text;
  }

  /**
   * @param event {Event}
   */
  _handleMathFieldInput(event) {
    //this is to allows the autosuggest in math-field to work
    if (event.data === 'insertText' && event.inputType === 'insertText') {
      return event.preventDefault();
    }
    if (event.data === 'insertLineBreak') {
      window.mathVirtualKeyboard.hide();
    }
    this._setMathText(event.target.value);
  }

  _openMathKeyboard(mathFieldElement) {
    this._displayMathKeyboard = true;
    mathFieldElement.focus();
    window.mathVirtualKeyboard.show();
  }

  _closeMathKeyboard(trackChange){
    this._displayMathKeyboard = false;
    window.mathVirtualKeyboard.hide();
    trackChange(() => {});
  }

  _toggleMathKeyboard(mathFieldElement){
    return this._displayMathKeyboard ? this._closeMathKeyboard(this._trackChange.bind(this)) : this._openMathKeyboard(mathFieldElement);
  }

  /**
   * @param mathFieldElement {HTMLElement}
   */
  _handleMathFieldFocus(mathFieldElement){
    mathFieldElement.style.outline = this.currentBorderColor;
    mathFieldElement.style.border =`2px solid ${this.currentBorderColor}`;
    this._openMathKeyboard(mathFieldElement);
  }

  /**
   * @param mathFieldElement {HTMLElement}
   * @param borderStyle {string}
   */
  _handleMathFieldMouseOut(mathFieldElement, borderStyle){
    if (!this._text) {
      mathFieldElement.style.border = `2px ${borderStyle} ${this.currentBorderColor}`;
    }
  }

  _keyboardMouseOver(element){
    element.parentNode.querySelector('.tooltiptext').style.visibility = 'visible';
  }

  _keyboardMouseOut(element){
    element.parentNode.querySelector('.tooltiptext').style.visibility = 'hidden';
  }

  /**
   * @param mathFieldElement {HTMLElement}
   */
  _handleMathFieldMouseOver(mathFieldElement) {
    mathFieldElement.style.border = `2px solid ${this.currentBorderColor}`;
  }

  /**
   * @param mathFieldElement {HTMLElement}
   */
  _addMathFieldEventListeners(mathFieldElement, editable) {
    const borderStyle = this._text ? BorderStyle.SOLID : BorderStyle.DASHED;
    this.toggleKeyboard = this._toggleMathKeyboard.bind(this);

    if (editable) {
      //if event listener does not exist, add and set attribute
      if (mathFieldElement.getAttribute('inputListener') !== 'true') {
        mathFieldElement.addEventListener('input', (event) => this._handleMathFieldInput(event));
        mathFieldElement.setAttribute('inputListener', 'true');
      }

      if (mathFieldElement.getAttribute('focusListener') !== 'true') {
        mathFieldElement.addEventListener('focus', (event) => this._handleMathFieldFocus(mathFieldElement));
        mathFieldElement.setAttribute('focusListener', 'true');
      }

      if (mathFieldElement.getAttribute('blurListener') !== 'true') {
        mathFieldElement.addEventListener('blur', () => this._closeMathKeyboard(this._trackChange.bind(this)));
        mathFieldElement.setAttribute('blurListener', 'true');
      }

      const keyboard = mathFieldElement.shadowRoot.querySelector('.ML__virtual-keyboard-toggle');
      if (keyboard && keyboard.getAttribute('clickListener') !== 'true') {
        keyboard.addEventListener('click', () => this.toggleKeyboard(mathFieldElement));
        mathFieldElement.setAttribute('clickListener', 'true');
      }

      if (keyboard && keyboard.getAttribute('mouseoverListener') !== 'true'){
        keyboard.addEventListener('mouseover', () => this._keyboardMouseOver(keyboard));
        keyboard.setAttribute('mouseoverListener', 'true');
      }

      if (keyboard && keyboard.getAttribute('mouseoutListener') !== 'true'){
        keyboard.addEventListener('mouseout', () => this._keyboardMouseOut(keyboard));
        keyboard.setAttribute('mouseoutListener', 'true');
      }
    }

    if (mathFieldElement.getAttribute('mouseoutListener') !== 'true') {
      mathFieldElement.addEventListener('mouseout', () => this._handleMathFieldMouseOut(mathFieldElement, borderStyle));
      mathFieldElement.setAttribute('mouseoutListener', 'true');
    }

    if (mathFieldElement.getAttribute('mouseoverListener') !== 'true') {
      mathFieldElement.addEventListener('mouseover', () => this._handleMathFieldMouseOver(mathFieldElement));
      mathFieldElement.setAttribute('mouseoverListener', 'true');
    }
  }

  removeMathFieldEventListeners(){
    const borderStyle = this._text ? BorderStyle.SOLID : BorderStyle.DASHED;
    const mathFieldElements= Array.from(document.getElementsByClassName(`mf-${this.id}`));

    mathFieldElements.forEach((mathFieldElement) => {
      if (mathFieldElement.getAttribute('inputListener') === 'true') {
        mathFieldElement.removeEventListener('input', (event) => this._handleMathFieldInput(event));
        mathFieldElement.setAttribute('inputListener', 'false');
      }

      if (mathFieldElement.getAttribute('focusListener') === 'true') {
        mathFieldElement.removeEventListener('focus', (event) => this._handleMathFieldFocus(mathFieldElement));
        mathFieldElement.setAttribute('focusListener', 'false');
      }

      if (mathFieldElement.getAttribute('blurListener') === 'true') {
        mathFieldElement.removeEventListener('blur', () => this._closeMathKeyboard(this._trackChange.bind(this)));
        mathFieldElement.setAttribute('blurListener', 'false');
      }

      if (mathFieldElement.getAttribute('mouseoutListener') === 'true') {
        mathFieldElement.removeEventListener('mouseout', () => this._handleMathFieldMouseOut(mathFieldElement, borderStyle));
        mathFieldElement.setAttribute('mouseoutListener', 'false');
      }

      if (mathFieldElement.getAttribute('mouseoverListener') === 'true') {
        mathFieldElement.removeEventListener('mouseover', () => this._handleMathFieldMouseOver(mathFieldElement));
        mathFieldElement.setAttribute('mouseoverListener', 'false');
      }

      const keyboard = mathFieldElement.shadowRoot.querySelector('.ML__virtual-keyboard-toggle');
      if (keyboard && keyboard.getAttribute('clickListener') === 'true') {
        keyboard.removeEventListener('click', () => this.toggleKeyboard(mathFieldElement));
        mathFieldElement.setAttribute('clickListener', 'false');
      }

      if (keyboard && keyboard.getAttribute('mouseoverListener') === 'true'){
        keyboard.removeEventListener('mouseover', () => this._keyboardMouseOver(keyboard));
        keyboard.setAttribute('mouseoverListener', 'false');
      }

      if (keyboard && keyboard.getAttribute('mouseoutListener') === 'true'){
        keyboard.removeEventListener('mouseout', () => this._keyboardMouseOut(keyboard));
        keyboard.setAttribute('mouseoutListener', 'false');
      }
    });
  }
}
