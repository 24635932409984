
'use strict';

/**
 * Service for additional Classkick specific request headers to backend
 * Reference:
 *  https://docs.angularjs.org/api/ng/service/$http#setting-http-headers
 */
export default class PlatformHeaderService {

  constructor(environment, constants, DatadogRumService) {
    'ngInject';
    this._environment = environment;
    this._constants = constants;
    this._headers = new Map();
    this._datadogRumService = DatadogRumService;

    this.init();
  }

  init() {
    this._headers.set('X-CK-PLATFORM', 'web');
    this._headers.set('X-CK-VERSION', this._constants.version);
    this._headers.set('X-CK-USER', undefined);
    this._headers.set('X-CK-ASSIGNMENT', undefined);
    this._headers.set('X-CK-ROSTER', undefined);
  }

  setUserInfo(info) {
    this._headers.set('X-CK-USER', info.id);
    this._datadogRumService.setUserId(info.id);
  }

  clearUserInfo() {
    this._headers.set('X-CK-USER', undefined);
    this._datadogRumService.setUserId(undefined);
  }

  setAssignmentId(assignmentId) {
    this._headers.set('X-CK-ASSIGNMENT', assignmentId);
  }

  setRosterId(rosterId) {
    this._headers.set('X-CK-ROSTER', rosterId);
  }

  addHeaders(url, config) {
    if (url.includes(this._environment.serverUrlBase)) {
      if (!config) {
        config = {};
      }
      if (!config.headers) {
        config.headers = {};
      }
      this._headers.forEach((value, key) => config.headers[key] = value);
    }
    return config;
  }
}
