'use strict';

import Codec from './codec';
import { SharedWorkUser } from '../domain/shared-work';

/**
 * Encodes/Decodes a shared work user
 */
export default class SharedWorkUserCodec extends Codec {

  constructor() {
    super();
  }

  encode(value) {
    return {
      id: value.id,
      username: value.username,
      email: value.email,
      first_name: value.firstName,
      last_name: value.lastName,
      display_name: value.displayName,
    };
  }

  decode(value) {
    return new SharedWorkUser(
      value.id,
      value.username,
      value.email,
      value.first_name,
      value.last_name,
      value.display_name,
    );
  }
}
