'use strict';

import Size from '../../model/ui/size';
import { CropResult } from '../../model/ui/elements/image-cropper';
import { ImageImport, QuestionDisplay } from '../import-image-dialog/import-image-dialog.controller';
import Point from '../../model/ui/point';
import CameraDialogTemplate from './camera-dialog.html';

export default class CameraDialogController {

  /**
   * @ngInject
   */
  constructor($q, $scope, $mdDialog, $window, $document, $timeout, $log, ImageEditService, targetIndex, questionId) {
    this.$q = $q;
    this.$scope = $scope;
    this.$mdDialog = $mdDialog;
    this.$window = $window;
    this.$document = $document;
    this.$timeout = $timeout;
    this.$log = $log;

    this._imageEditService = ImageEditService;

    this._state = this.Loading;

    this._targetIndex = targetIndex;
    this._questionId = questionId;
    this._stream = undefined;

    if (this.isSupported) {
      this._init();
    }
    else {
      this._state = this.Unsupported;
    }

  }

  _init() {
    if (this.$window.innerWidth > this.$window.innerHeight) {
      this._height = this.$window.innerHeight - 200;
      this._width = Math.round(this._height * 1.76);
    }
    else {
      this._width = this.$window.innerWidth - 0;
      this._height = this._width * 0.56;
    }

    this._mediaConstraints = {
      audio: false,
      video: {
        width: this._width,
        height: this._height
      }
    };

    this.$timeout(() => this.setupCamera())
      .then(() => {
        this._state = this.Active;
      })
      .catch((error) => {
        this._state = this.Blocked;
      });

    this.$scope.$on('$destroy', () => this._destroy());
  }

  get state() {
    return this._state;
  }

  get Loading() {
    return 'Loading';
  }

  get Active() {
    return 'Active';
  }

  get Unsupported() {
    return 'Unsupported';
  }

  get Blocked() {
    return 'Blocked';
  }

  get height() {
    return this._height;
  }

  get width() {
    return this.state === this.Active ? this._width : 'unset';
  }

  /**
   * Shows the camera player dialog
   *
   * @param $mdDialog
   * @param targetIndex {string}
   * @param questionId {string}
   * @returns {Promise}
   */
  static show($mdDialog, targetIndex, questionId) {
    return $mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: true,
      template: CameraDialogTemplate,
      controller: CameraDialogController,
      controllerAs: 'ctrl',
      locals: {
        targetIndex,
        questionId
      }
    });
  }

  setupCamera() {
    this._player = angular.element('#player');

    // Attach the video stream to the video element and autoplay.
    return this._getUserMedia()
      .then((stream) => {
        this._player[0].srcObject = stream;
        this._stream = stream;
      });
  }

  /**
   * Uses optimal browser api based on availability
   * @returns {Promise.<Blob>}
   * @private
   */
  _getUserMedia() {
    if (navigator.mediaDevices.getUserMedia) {
      return navigator.mediaDevices.getUserMedia(this._mediaConstraints);
    }

    return this.$q((resolve, reject) => {
      this._setGetUserMedia();

      navigator.getUserMedia(
        this._mediaConstraints,
        (stream) => resolve(stream),
        (err) => reject(err)
      );
    });
  }

  _setGetUserMedia() {
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
  }

  get isSupported() {
    return !!navigator.getUserMedia || !!navigator.webkitGetUserMedia || !!navigator.mozGetUserMedia || !!navigator.msGetUserMedia;
  }


  takePicture() {
    let canvas = this.$document[0].getElementById('canvas');
    let context = canvas.getContext('2d');

    let track = this._stream.getTracks()[0];
    let { width, height } = track.getSettings();

    // Draw the video frame to the canvas.
    canvas.width = width;
    canvas.height = height;
    context.drawImage(this._player[0], 0, 0, width, height);

    this._imageEditService.imageFromCanvas(canvas).then((result) => {
      let tempFile = undefined;
      try {
        const blob = this._imageEditService.dataURItoBlob(result.src);
        tempFile = new File([blob], 'camera.png', blob);
      } catch (err) {
        this.$log.error('Unable to convert camera image into file', err);
      }
      this.$mdDialog.hide({
        imageImport: this._formatImageImport(result),
        tempFile: tempFile
      });
    });
  }

  /**
   * @param image {Image}
   * @return {ImageImport}
   */
  _formatImageImport(image) {

    const imageSize = new Size(
      image.naturalWidth,
      image.naturalHeight
    );

    const cropResult = new CropResult(
      imageSize,
      new Point(0, 0),
      imageSize
    );

    const imageSrc = this._imageEditService.crop(
      image,
      new Point(0, 0),
      imageSize
    );

    return new ImageImport(
      imageSrc,
      this._targetIndex,
      cropResult,
      [ new QuestionDisplay(this._questionId, this._targetIndex, true, false, undefined) ]
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  _destroy() {
    if (this._stream) {
      this._stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

}

