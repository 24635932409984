export default class GradebookStudentsIntegrationStatus {

  /**
   * @param rosterStudents {RosterStudent[]}
   * @param lmsStudents {GradebookStudent[]}
   *
   */
  constructor(
    rosterStudents,
    lmsStudents
  ) {
    this._rosterStudents = rosterStudents;
    this._lmsStudents = lmsStudents;
  }

  get rosterStudents (){
    return this._rosterStudents;
  }

  get lmsStudents (){
    return this._lmsStudents;
  }
}
