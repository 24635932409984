/*global delighted:false */
/*global delightedThumbs:false */
/*global delightedThumbs2:false */
/*global delightedThumbs3:false */
/*global delightedThumbs4:false */
'use strict';

export default class DelightedService {

  constructor() {}

  /**
   * @param user {User}
   * @param isPro {boolean}
   */
  surveyNPS(user, isPro) {
    delighted.survey({
      email: user.email,
      name: user.name,
      properties: {
        id: user.id,
        isPro
      }
    });
  }

  /**
   * @param user {User}
   * @param isPro {boolean}
   * @param organization {Organization}
   */
  surveyImpact(user, isPro, organization) {
    let orgName = organization ? organization.name : 'undefined';
    let orgId = organization ? organization.id : 'undefined';
    delightedThumbs3.survey({
      email: user.email,
      name: user.name,
      properties: {
        id: user.id,
        isPro,
        org_name: orgName,
        org_id: orgId
      }
    });
  }

  /**
   * @param user {User}
   * @param isPro {boolean}
   * @param organization {Organization}
   */
  surveyUserReferral(user, isPro, organization) {
    let orgName = organization ? organization.name : 'undefined';
    let orgId = organization ? organization.id : 'undefined';
    if (isPro) {
      delightedThumbs2.survey({
        email: user.email,
        name: user.name,
        properties: {
          id: user.id,
          isPro,
          org_name: orgName,
          org_id: orgId
        }
      });
    } else {
      delightedThumbs.survey({
        email: user.email,
        name: user.name,
        properties: {
          id: user.id,
          isPro,
          org_name: orgName,
          org_id: orgId
        }
      });
    }
  }

  /**
   * @param user {User}
   * @param isPro {boolean}
   * @param organization {Organization}
   */
  surveyProTeacherConversion(user, isPro, organization) {
    let orgName = organization ? organization.name : 'undefined';
    let orgId = organization ? organization.id : 'undefined';
    delightedThumbs4.survey({
      email: user.email,
      name: user.name,
      properties: {
        id: user.id,
        isPro,
        org_name: orgName,
        org_id: orgId
      }
    });
  }
}
