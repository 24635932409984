
'use strict';
import AccountLoginComponentTemplate from '../account-login-component/account-login-component.html';
import ErrorCodes from '../../model/domain/error-codes';
import googleIcon from '../../../assets/images/google-icon.svg';
import cleverIcon from '../../../assets/images/clever-logo.svg';

class AccountLoginComponentController {

  constructor($state, $scope, $stateParams, $interval, $mdDialog, $log, $q, environment, AuthService, CacheService, BreadcrumbService,
              StorageService, RosterService, ClassCodeService, ToolbarService, AnalyticsService) {
    'ngInject';

    this.$log = $log;
    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$interval = $interval;
    this.$mdDialog = $mdDialog;
    this._environment = environment;
    this.$scope = $scope;
    this.googleIcon = googleIcon;
    this.cleverIcon = cleverIcon;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {ClassCodeService} */
    this._classCodeService = ClassCodeService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._isLoading = false;
    this._ckRedirect = this._storageService.ckRedirect;
    this._emailOrUsername = '';
    this._password = '';
    this._rememberMe = true;
    this._errorMessage = '';

    if (this._authService.isLoggedIn) {
      this._emailOrUsername = this._authService.authData.login;
    }

    /* Optional: supply a class code to add the logging in student to its roster. */
    this._classCode = this.$stateParams.classCode || '';
  }

  /**
   * @return {boolean}
   */
  get isLoading() {
    return this._isLoading;
  }

  /**
   * @return {string}
   */
  get emailOrUsername() {
    return this._emailOrUsername;
  }

  /**
   * @param value {string}
   */
  set emailOrUsername(value) {
    this._emailOrUsername = value;
  }

  /**
   * @return {string}
   */
  get password() {
    return this._password;
  }

  /**
   * @param value {string}
   */
  set password(value) {
    this._password = value;
  }

  /**
   * @return {boolean}
   */
  get rememberMe() {
    return this._rememberMe;
  }

  /**
   * @param value {boolean}
   */
  set rememberMe(value) {
    this._rememberMe = value;
  }

  /**
   * @return {string}
   */
  get errorMessage() {
    return this._errorMessage;
  }

  /**
   *
   * Visible for Testing
   * @param value {string}
   */
  set classCode(value) {
    this._classCode = value;
  }

    /**
     *
     * @return {boolean}
     */
  get isFromSharedWork () {
    return this.$state.current && this.$state.current.name === 'root.shared-work';
  }

  goToCreateAccountChoice() {
    if (this.isFromSharedWork) {
      return this.handleSignUpView();
    }
    this._analyticsService.sendEvent({eventTag: 'accountLogin:signup_clicked'});
    this._breadcrumbService.go('root.signup-choice');
    this.$mdDialog.hide();
  }

  goBack() {
    if (this.isFromSharedWork) {
      return this.handleSignUpView();
    }
    this._breadcrumbService.go('root.login');
  }

  goToResetPassword() {
    if (this.isFromSharedWork) {
      this.$mdDialog.cancel();
    }
    this._breadcrumbService.go('root.forgot-password');
  }

  loginToAccount() {
    this._isLoading = true;

    return this._authService.authUserWithPassword(this.emailOrUsername, this.password, this.rememberMe)
      .then(() => {
        this._cacheService.reset();
        return this._cacheService.getUser(true);
      })
      .then((user) => {
        if (user && user.isStudent) {
          return this._addStudentToRoster();
        }
      })
      .then(() => {
        this._loginSuccess();
      })
      .catch((error) => {
        if (error.code === ErrorCodes.ROSTER_LOCKED) {
          this._errorMessage = 'Whoops! Looks like the roster associated with the given class code is locked.';
        }
        else if (error.code === ErrorCodes.PASSWORD_NOT_CONFIGURED) {
          this._errorMessage = 'Whoops! You are trying to use a password to access a Google-authenticated account. Please click "continue with google" to log in.';
        }
        else {
          this._errorMessage = 'Whoops! Looks like your email and/or password are incorrect.';

          if (this.isFromSharedWork) {
            return this.handleError({error: this._errorMessage});
          }
        }
        this._isLoading = false;
        this.password = '';
      });
  }

  _addStudentToRoster() {
    if (this._classCode) {
      return this._classCodeService.get(this._classCode)
        .then((classCodeData) => {
          return this._rosterService.addSelf(classCodeData.rosterId);
        });
    }
  }

  _loginSuccess() {
    this._toolbarService.reset();
    this._breadcrumbService.clear();

    if (this._ckRedirect) {
      this._ckRedirect.redirect(this._breadcrumbService);
      this._storageService.ckRedirect = null;

      if (this.isFromSharedWork) {
        this._storageService.sharedWorkPath = '';
        this.closeAndReload();
      }
    }
    else {
      this._breadcrumbService.go('root.account.home', {}, true);
    }
  }

  googleSignIn() {
    this._isLoading = true;
    this._errorMessage = '';

    this._authService.authUserWithGoogle()
      .then((user) => {
        if (user && user.isStudent) {
          return this._addStudentToRoster();
        }
      })
      .then(() => {
        this._loginSuccess();
      })
      .catch((err) => {
        this._isLoading = false;
        if (err.code === ErrorCodes.GOOGLE_POPUP_CLOSED_BY_USER || err.code === ErrorCodes.GOOGLE_ACCESS_DENIED) {
          // do nothing
        }
        else if (err.code === ErrorCodes.INVALID_CREDENTIALS) {
          this.notFoundLoginId = err.config.data.id;
          this._noAccountExistsDialog();
        }
        else if (err.code === ErrorCodes.GOOGLE_AUTH_SETUP_FAILED) {
          this._errorMessage = 'Whoops, there was a problem using Google to log in: ' + err.message;
          this.$log.warn('googleSignIn error', err, err.message);
          if (this.isFromSharedWork) {
            return this.handleError({error: this._errorMessage});
          }
        }
        else if (err.code === ErrorCodes.ROSTER_LOCKED) {
          this._errorMessage = 'Whoops! Looks like the roster associated with the given class code is locked.';
          if (this.isFromSharedWork) {
            return this.handleError({error: this._errorMessage});
          }
        }
        else {
          this.$log.error('googleSignIn error', err, angular.toJson(err));
          this._errorMessage = 'Whoops, there was a problem logging in with Google Auth. Please try again later or email support@classkick.com.';
          if (this.isFromSharedWork) {
            return this.handleError({error: this._errorMessage});
          }
        }
      });
  }

  _noAccountExistsDialog() {
    this.$mdDialog.show({
        clickOutsideToClose: true,
        escapeToClose: true,
        contentElement: '.account-login .no-account'
      });
  }

  linkToCleverAuth() {
    this._authService.linkToCleverAuth(this.$stateParams.classCode);
  }
}

/**
 *
 * More information about the bindings:
 * https://docs.angularjs.org/api/ng/service/$compile#-scope-
 * https://docs.angularjs.org/guide/component
 */
export default {
  bindings: {
    handleSignUpView: '&', //& symbol is used in the context of directive bindings. Specifically, it's used for passing a function as an expression into a directive
    closeAndReload: '&',
    closeDialog: '&',
    handleError: '&',

  },
  template: AccountLoginComponentTemplate,
  controller: AccountLoginComponentController,
  controllerAs: 'ctrl'
};