
'use strict';

import SignupDialogController from '../../components/signup-dialog/signup-dialog';
import {Locations} from '../../services/mixpanel/mixpanel.service';
import {AssignmentQuestionMetadata} from '../../components/assignment-question/assignment-question.directive';
import CkRedirect from '../../model/domain/ck-redirect';
import CopyAssignmentRequest from '../../model/domain/copy-assignment-request';
import AssignmentManager from '../../model/domain/assignment-manager';

export default class PublicAssignmentQuestionController {

  constructor($mdDialog, $mdToast, $stateParams, $q, $window, AnalyticsService, AssignmentService, AuthService,
              BreadcrumbService, CacheService, StorageService) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.$window = $window;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$q = $q;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;

    this._assignmentManager = new AssignmentManager(
        this.$q,
        this.$mdDialog,
        this.$mdToast,
        this._cacheService,
        this._assignmentService,
        this._breadcrumbService,
        undefined,
        undefined,
        this._analyticsService,
        undefined
    );

    /** @type {AssignmentQuestionMetadata} */
    this._assignmentQuestionConfig = undefined;
    this._showSignupDialog = SignupDialogController.show;
    this._organizationIdFromPublicAssignmentLibrary = this._storageService.assignmentLibrarySelection &&
      this._storageService.assignmentLibrarySelection._id;


    if (!this.isAuthenticated) {
      this._breadcrumbService.go('root.public-assignment-question', {
        assignmentId: $stateParams.assignmentId,
        questionId: $stateParams.questionId
      });
    } else {
      this._breadcrumbService.go('root.account.nav.public-assignment-question', {
        assignmentId: $stateParams.assignmentId,
        questionId: $stateParams.questionId
      });
    }

    this._init();
  }

  _init() {
    this._cacheService.getAssignment(this.assignmentId, false).then((assignment) => {

        this.assignmentName = assignment.name;

        this._assignmentQuestionConfig = new AssignmentQuestionMetadata(
          assignment,
          this.questionId,
          true,
          false
        );

        this._analyticsService.publicAssignmentQuestionViewed(
          this.assignmentId,
          this.questionId,
          this._organizationIdFromPublicAssignmentLibrary,
          this.isFromAssignmentLibrary
        );
      })
      .catch((err) => {
        this.error = err;
      });
  }


  get isAuthenticated() {
    return this._authService.isLoggedIn;
  }

  get assignmentQuestionConfig() {
    return this._assignmentQuestionConfig;
  }

  /**
   * @returns {Boolean}
   */
  get isLoggedIn() {
    return this._authService.isLoggedIn;
  }

  /**
   * Assignment for the current question
   * @returns {string}
   */
  get assignmentId() {
    return this.$stateParams.assignmentId;
  }

  /**
   * Current question id
   * @returns {string}
   */
  get questionId() {
    return this.$stateParams.questionId;
  }

  /**
   * Current user id
   * @returns {string}
   */
  get userId() {
    return this._authService.authData ? this._authService.authData.id : '';
  }

  showSignupDialog() {
    this._showSignupDialog(this.$mdDialog).then((stateName) => {
      this._storageService.ckRedirect = new CkRedirect('root.account.nav.assignments', {});

      this._breadcrumbService.go(stateName, {
        referral: Locations.PUBLIC_ASSIGNMENT
      });
    });
  }

  /**
   * open youtube video in new tab.
   */
  whatIsClasskick() {
    this.$window.open('https://www.youtube.com/watch?v=l5vza3HTW5I', '_blank');
  }

  /**
   * If logged in, go to the user's assignment list and copy the assignment there.
   * Otherwise, bring up the signup dialog.
   */
  copyAssignment() {
    this._storageService.copyAssignmentRequest = new CopyAssignmentRequest(this.assignmentId, this.assignmentName);

    this._analyticsService.publicAssignmentCopyClicked(
      this.assignmentId,
      this._organizationIdFromPublicAssignmentLibrary,
      this.isFromAssignmentLibrary
    );

    if (this.isLoggedIn) {
      this._assignmentManager.checkAssignmentLimit(Locations.PUBLIC_ASSIGNMENT_QUESTION, this.assignmentId, () => {
        this._breadcrumbService.go('root.account.nav.assignments', {});
      });
    } else {
      this.showSignupDialog();
    }
  }

  goBack() {
    const path = this.isAuthenticated
      ? 'root.account.nav.public-assignment'
      : 'root.public-assignment';

    this._breadcrumbService.goBack(
      path,
      {
        assignmentId: this.assignmentId
      }
    );
  }

  get isFromAssignmentLibrary() {
    const stack = this._breadcrumbService.stack;
    if (stack.length && (stack[stack.length - 2].to === 'root.account.nav.public-assignments-library' ||
      stack[stack.length - 2].to === 'root.public-assignments-library')) {
      return true;
    }
    return false;
  }
}
