'use strict';

import VerifyOrganizationMemberDialogTemplate from './verify-organization-member-dialog.html';

export default class VerifyOrganizationMemberDialogController {

  /**
   * @ngInject
   */
  constructor($mdDialog, member, organization) {
    this.$mdDialog = $mdDialog;
    this._member = member;
    this._organization = organization;
  }

  get memberName() {
    return this._member && this._member.name;
  }

  get memberEmail() {
    return this._member && this._member.email;
  }

  get organizationName() {
    return this._organization && this._organization.name;
  }

  get organizationType() {
    return this._organization && this._organization.type;
  }

  /**
   * @param $mdDialog
   * @param member {User}
   * @param organization {Organization}
   */
  static show($mdDialog, member, organization) {
    return $mdDialog.show({
      controller: VerifyOrganizationMemberDialogController,
      template: VerifyOrganizationMemberDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      locals: {
        member,
        organization
      }
    });
  }

  verify() {
    this.$mdDialog.hide(true);
  }

  remove() {
    this.$mdDialog.hide(false);
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
