'use strict';

import FeedbackIndicatorTemplate from './feedback-indicator.component.html';

class FeedbackIndicatorController {

  /**
   * @ngInject
   */
  constructor($timeout, $scope, AnalyticsMetaService) {
    this.$timeout = $timeout;
    this.$scope = $scope;
    this._analyticsMetaService = AnalyticsMetaService;

    this.$scope.$on('$destroy', () => this._destroy());
  }

  /**
   * @param value {QuestionFeedbackList}
   */
  set feedbackList(value) {
    if (this._feedbackList) {
      this._feedbackList.updated.unsubscribe(this._onUpdate, this);
    }

    this._feedbackList = value;

    if (this._feedbackList) {
      this._onUpdate();
      this._feedbackList.updated.subscribe(this._onUpdate, this);
    }
  }

  _onUpdate() {
    this._countDisplay = this._feedbackList.feedbackCount;

    this._analyticsMetaService.checkForFeedbackUpdates(this._feedbackList, this._countDisplay);

    if (this.$timeout) {
      this.$timeout(() => {});
    }
  }

  _destroy() {
    if (this._feedbackList) {
      this._feedbackList.updated.unsubscribe(this._onUpdate, this);
    }
  }

  /**
   * @return {string|number}
   */
  get countDisplay() {
    if (angular.isDefined(this._countDisplay)) {
      return this._countDisplay;
    }
    else {
      return '-';
    }
  }

}

export default {
  bindings: {
    feedbackList: '<',
    showIcon: '<'
  },
  template: FeedbackIndicatorTemplate,
  controller: FeedbackIndicatorController,
  controllerAs: 'ctrl'
};
