'use strict';

import Codec from './codec';
import ChatMessage from '../domain/chat-message';

export default class ChatMessageCodec extends Codec {

  /**
   * @param value {ChatMessage}
   * @return {object}
   */
  encode(value) {
    return {
      type: value.type,
      user_id: value.userId,
      display_name: value.displayName,
      text: value.text,
      timestamp: value.timestamp && value.timestamp.toISOString()
    };
  }

  /**
   * @param value {object}
   * @param key {string}
   * @return {ChatMessage|null}
   */
  decode(value, key) {
    if (value) {
      return new ChatMessage(
        key,
        value.type,
        value.user_id,
        value.display_name,
        value.text,
        value.timestamp
      );
    }
    return null;
  }
}
