
'use strict';

export default class Features {

  /**
   * @returns {string}
   */
  static get STUDENT_ACCOUNTS() {
    return 'student_accounts';
  }

  /**
   * @returns {string}
   */
  static get FOLDERS() {
    return 'folders';
  }

  /**
   * @return {string}
   */
  static get GRADE_EXPORT() {
    return 'grade_export';
  }

  /**
   * @return {string}
   */
  static get UNLIMITED_ASSIGNMENTS() {
    return 'unlimited_assignments';
  }

  /**
   * @return {string}
   */
  static get EXPORT_STUDENT_WORK() {
    return 'export_student_work';
  }
}
