
'use strict';

import SnackbarTemplate from './snackbar.html';

export default class SnackbarController {
  constructor($mdToast, message, action) {
    'ngInject';

    this.$mdToast = $mdToast;
    this.message = message;
    this.action = action;
  }

  static show($mdToast, message, dismissable) {

    return $mdToast.show({
      controller: SnackbarController,
      template: SnackbarTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: false,
      hideDelay: dismissable ? 3000 : 0, //Setting to zero keeps the toast open until dismissed
      locals: {
        'message': message,
        'action': dismissable ? 'GOT IT' : null
      },
      bindToController: true
    });
  }

  confirm() {
    this.$mdToast.hide();
  }

  dismiss() {
    this.$mdToast.hide();
  }
}
