import Session from '../domain/session';
import Class from '../domain/class';
import GradebookAssignmentCategory from '../domain/gradebook-assignment-category';
import GradebookAssignment from '../domain/gradebook-assignment';

export default class GradebookRosterIntegrationStatus {

  /**
   * @param isLinked {boolean}
   * @param isLinkActive {boolean}
   * @param canSubmitLargeBatch {boolean}
   * @param linkedStudentIds {string[]}
   * @param categories {GradebookAssignmentCategory[]}
   * @param assignments {GradebookAssignment[]}
   * @param selectedLink {GradebookRoster}
   *
   */
  constructor(
    isLinked,
    isLinkActive,
    canSubmitLargeBatch,
    linkedStudentIds,
    categories,
    assignments,
    selectedLink,
  ) {
    this._isLinked = isLinked;
    this._isLinkActive = isLinkActive;
    this._canSubmitLargeBatch = canSubmitLargeBatch;
    this._linkedStudentIds = linkedStudentIds;
    this._categories = categories;
    this._assignments = assignments;
    this._selectedLink = selectedLink;
  }

  get isLinked (){
    return this._isLinked;
  }

  get isLinkActive () {
    return this._isLinkActive;
  }

  get canSubmitLargeBatch () {
    return this._canSubmitLargeBatch;
  }

  get linkedStudentIds () {
    return this._linkedStudentIds;
  }

  get categories (){
    return this._categories;
  }

  get assignments (){
    return this._assignments;
  }

  get selectedLink (){
    return this._selectedLink;
  }

}
