'use strict';

import Line from '../../model/ui/elements/line';
import Point from '../../model/ui/point';
import GestureManager from '../../model/ui/gesture-manager';
import Control from '../../model/ui/control';
import ElementMetadata from '../../model/domain/element-metadata';
import SvgCanvas from '../../model/ui/svg-canvas';
import Highlight from '../../model/ui/elements/highlight';
import { ToolbarModes } from '../../services/toolbar/toolbar.service';
import Snap from 'snapsvg-cjs';
import cursorEraser from '../../../assets/images/cursors/cursor_eraser.png';
import cursorHighlighter from '../../../assets/images/cursors/cursor_highlighter.png';
import cursorPen from '../../../assets/images/cursors/cursor_pen.png';

export default class LineCapture extends Control {

  constructor(canvas, ownerId, role, intent, isWork, index, elements, FirebaseService, AssignmentTrackingService, ToolbarService,
              $window, $location) {
    super('lineCapture', undefined);

    this.$window = $window;
    this.$location = $location;

    this._lineCaptureSnap = canvas.layers.lineCapture;
    this._newlineSnap = canvas.layers.newLine;

    this._mode = ToolbarService.state.mode;
    this._ownerId = ownerId;
    this._role = role;
    this._intent = intent;
    this._isWork = isWork;
    this._index = index;
    this._elements = elements;
    this._firebaseService = FirebaseService;
    this._assignmentTrackingService = AssignmentTrackingService;

    this._currentPenColor = ToolbarService.state.lines.color.hex || '#111111';
    this._currentWidth = ToolbarService.state.lines.width || 2;
    this._isErasing = false;
    this._active = false;
    this._newLinePoints = undefined;
    this._linesToDelete = [];
    this._lastEraserCoords = undefined;

    this._gesture = new GestureManager(undefined, canvas);
    this._gesture._dragThreshold = 0;
    this._gesture.start(this._lineCaptureSnap.node);
    this._gesture.click.subscribe(this.handleClick, this);
    this._gesture.dragStart.subscribe(this.startDrawing, this);
    this._gesture.dragMove.subscribe(this.whileDrawing, this);
    this._gesture.dragEnd.subscribe(this.endDrawing, this);
  }

  set state(ev) {
    this._mode = ev.mode;
    this.visibility = this.isLinesMode || this.isHighlighterMode || this.isEraserMode ? 'visible' : 'hidden';
    this.erasing = this.isEraserMode;

    if (this.isLinesMode) {
      this.color = ev.lines.color.hex;
      this.width = ev.lines.width;
    }
    else if (this.isHighlighterMode) {
      this.color = ev.highlighter.color.hex;
      this.width = ev.highlighter.width;
    }
  }

  get isLinesMode() {
    return this._mode === ToolbarModes.Lines;
  }

  get isHighlighterMode() {
    return this._mode === ToolbarModes.Highlighter;
  }

  get isEraserMode() {
    return this._mode === ToolbarModes.Eraser;
  }

  get isSelectorMode() {
    return this._mode === ToolbarModes.Selector;
  }

  get isTouchMode() {
    return this._mode === ToolbarModes.Touch;
  }

  static get MAX_POINTS() {
    return 1000;
  }

  static get ERASER_RADIUS() {
    return 8;
  }

  static get ERASER_DIAMETER() {
    return LineCapture.ERASER_RADIUS * 2;
  }

  createElement(root) {
    this._root = root
      .rect(0, 0, SvgCanvas.INITIAL_WIDTH, SvgCanvas.INITIAL_HEIGHT)
      .addClass(this.id)
      .attr({
        fill: 'transparent',
        cursor: this.getCursor(this.visibility, this._isErasing)
      });

    this._root.attr({
      visibility: 'hidden'
    });
  }

  update() {
    this._root.attr({
      visibility: this.visibility,
      cursor: this.getCursor(this.visibility, this._isErasing)
    });
  }

  getCursor(visibility, isErasing) {
    let cursor = 'pointer';

    if (visibility) {
      let cursorFile;
      let cursorPosition;
      if (isErasing) {
        cursorFile = cursorEraser;
        cursorPosition = '6 12';
      }
      else if (this.isHighlighterMode) {
        cursorFile = cursorHighlighter;
        cursorPosition = '2 20';
      }
      else {
        cursorFile = cursorPen;
        cursorPosition = '1 20';
      }

      cursor = `url(${cursorFile}) ${cursorPosition}, auto`;
    }

    return cursor;
  }

  handleClick(ev) {
    this.startDrawing(ev);
    this.endDrawing(ev);
  }

  startDrawing(ev) {
    ev.originalEvent.preventDefault();

    this._active = true;
    let formattedCoords = this.formatCoords(ev);

    if (!this._isErasing) {
      this.newLinePoints = formattedCoords;
    }
    else {
      this._lastEraserCoords = formattedCoords[0];
      this.checkForCollisions(this._elements, formattedCoords, (line) => this.handleCollision(line));
    }
  }

  whileDrawing(ev) {
    ev.originalEvent.preventDefault();

    /**
     * Stop drawing if the mouse leaves the canvas.
     */
    if (ev.offsetLocation.x > SvgCanvas.INITIAL_WIDTH || ev.offsetLocation.x < 0 || ev.offsetLocation.y > SvgCanvas.INITIAL_HEIGHT || ev.offsetLocation.y < 0) {
      this.endDrawing(ev);
    }

    if (this._active) {

      let formattedCoords = this.formatCoords(ev);

      if (this._isErasing) {
        let coordsToCheck = this.interpolate(this._lastEraserCoords, formattedCoords[0]);

        this.checkForCollisions(this._elements, coordsToCheck, (line) => this.handleCollision(line));

        this._lastEraserCoords = formattedCoords[0];
      }
      else {
        this.newLinePoints = [...this._newLinePoints, ...formattedCoords];
      }
    }
  }

  endDrawing(ev) {
    ev.originalEvent.preventDefault();

    if (this._active) {
      if (this._isErasing) {
        this._assignmentTrackingService.deleteElements(this._linesToDelete, this._index, this._isWork);
        this._linesToDelete = [];
      } else {

        let newLine = this.lineOrHighlight(
          this._firebaseService.newId(),
          new ElementMetadata(this._ownerId, this._role, this._intent),
          this.newLinePoints,
          this._currentPenColor,
          this._currentWidth
        );

        this._assignmentTrackingService.createElement(newLine, this._index, this._isWork);
      }
    }

    this.newLinePoints = undefined;
    this._active = false;
  }

  /**
   * Returns interpolated points if necessary
   * @param previousCoords {Point}
   * @param currentCoords {Point}
   * @returns {Array.<Point>}
   */
  interpolate(previousCoords, currentCoords) {
    let interpolatedPoints = [];

    // get the distance of last and new coords
    let distance = previousCoords.getDist(currentCoords);

    if (distance > LineCapture.ERASER_RADIUS) {
      let numOfPointsToInterpolate = Math.ceil(distance / LineCapture.ERASER_RADIUS);
      let scaledVector = previousCoords.minus(currentCoords).times(1 / (numOfPointsToInterpolate + 1));

      for (var i = 1; i <= numOfPointsToInterpolate; i++) {
        interpolatedPoints.push(previousCoords.minus(scaledVector.times(i)));
      }
    }

    return [previousCoords, ...interpolatedPoints, currentCoords];
  }

  /**
   * Checks if the given elements collide with the erasing line
   * @param elements {FirebaseCollection}
   * @param eraserLocs {Array.<Point>}
   * @param handleCollision {Function}
   */
  checkForCollisions(elements, eraserLocs, handleCollision) {
    elements.forEach((elem) => {
      if ((elem.type === Line.type || elem.type === Highlight.type) && elem.editable && this.intersection(elem.points, eraserLocs)) {
        handleCollision(elem);
      }
    });
  }

  /**
   * @param line {Line}
   */
  handleCollision(line) {
    if (!line.pendingErase) {
      line.pendingErase = true;
      this._linesToDelete.push(line);
    }
  }

  /**
   * Checks if paths have intersection
   * @param path {Array.<Point>}
   * @param eraserLocs {Array.<Point>}
   * @param collisionRadius {Number}
   * @returns {Boolean}
   */
  intersection(path, eraserLocs, collisionRadius = LineCapture.ERASER_RADIUS) {
    return eraserLocs.some((eraserLoc) => {
      return this.intersectionBBox(path, eraserLoc, collisionRadius) && this.intersectionSVGPaths(path, eraserLoc, collisionRadius);
    });
  }

  /**
   * Checks if boundry box of two paths overlap
   * @param path {Array.<Point>}
   * @param eraserLoc {Point}
   * @returns {Boolean}
   */
  intersectionBBox(path, eraserLoc) {

    if (!angular.isString(path)) {
      path = Line.svgPathFormat(path);
    }

    var bbox1 = Snap.path.getBBox(path);

    bbox1.x = bbox1.x - LineCapture.ERASER_RADIUS;
    bbox1.y = bbox1.y - LineCapture.ERASER_RADIUS;

    bbox1.x2 = bbox1.x2 + LineCapture.ERASER_RADIUS;
    bbox1.y2 = bbox1.y2 + LineCapture.ERASER_RADIUS;

    return bbox1.x <= eraserLoc.x &&
           eraserLoc.x <= bbox1.x2 &&
           bbox1.y < eraserLoc.y &&
           eraserLoc.y < bbox1.y2;
  }

  /**
   * Checks if two paths intersect
   * @param path {Array.<Point>}
   * @param eraserLoc {Point}
   * @param collisionRadius {Number}
   * @returns {Boolean}
   */
  intersectionSVGPaths(path, eraserLoc, collisionRadius = LineCapture.ERASER_RADIUS) {
    return path.some((currentPoint, idx, path) => {
      if (idx === 0) {
        return eraserLoc.getDist(currentPoint) < collisionRadius;
      }

      return this.interpolate(path[idx - 1], currentPoint).some((p) => {
        return eraserLoc.getDist(p) < collisionRadius;
      });
    });
  }

  formatCoords(ev) {
    return [new Point(ev.offsetLocation.x, ev.offsetLocation.y)];
  }

  renderNewLine(points) {
    this.clearNewLine();
    Line.draw(this._newlineSnap, points, this._currentPenColor, this._currentWidth);
  }

  clearNewLine() {
    if (this._newlineSnap.select('path')) {
      this._newlineSnap.select('path').remove();
    }
  }

  get newLinePoints() {
    if (this._newLinePoints.length > LineCapture.MAX_POINTS) {
      return this._newLinePoints.slice(0, LineCapture.MAX_POINTS);
    }
    else {
      return this._newLinePoints;
    }
  }

  set newLinePoints (points) {
    this._newLinePoints = points;

    if (this._newLinePoints) {
      this.renderNewLine(this._newLinePoints);
    }
    else {
      this.clearNewLine();
    }
  }

  set color(color) {
    this._currentPenColor = color;
    this.update();
  }

  set width(width) {
    this._currentWidth = width;
  }

  set erasing(isErasing) {
    this._isErasing = isErasing;
    this.update();
  }

  lineOrHighlight(id, metadata, points, color, width) {
    if (this.isLinesMode) {
      return new Line(id, metadata, points, color, width);
    }
    else if (this.isHighlighterMode) {
      return new Highlight(id, metadata, points, color, width);
    }
    else {
      throw new Error(`
        Cannot create new lines or highlights in ${this._mode} mode.
        Line capture must be in either lines or highlighter mode to create new elements.
      `);
    }
  }

  destroy() {
    this._gesture.stop();
    this._gesture.click.unsubscribe(this.handleClick, this);
    this._gesture.dragStart.unsubscribe(this.startDrawing, this);
    this._gesture.dragMove.unsubscribe(this.whileDrawing, this);
    this._gesture.dragEnd.unsubscribe(this.endDrawing, this);
  }
}
