'use strict';

export default class CdnUtils {
  /**
   * Takes incoming url and converts the url to use the CDN at assets.classkick.com or www.googleapis.com
   *
   * @param url
   * @returns {string}
   */
  static urlTransform(url) {
    if (this.cannotTransform(url)) {
      return url;
    }

    // if the url already has www.googleapis.com, return same url
    if (/www\.googleapis\.com/.test(url)) {
      return url;
    }

    // if the url already is assets.classkick.com, run transform assets
    if (/assets\.classkick\.com/.test(url)) {
      return this.transformAssets(url);
    }

    // if the url is storage.googleapis.com/classkick-assets, transform to use assets.classkick.com
    if (/^(http|https):\/\/storage\.googleapis\.com\/classkick-assets\/(view-helps|sticker-images)\/.*$/.test(url)) {
      return this.transformAssets(url.replace('//storage.googleapis.com/classkick-assets', '//assets.classkick.com'));
    }

    // if the url is storage.googleapis.com and belongs to classkick assets, transform to use assets.classkick.com
    if (/^(http|https):\/\/storage\.googleapis\.com\/(view-helps|sticker-images)\/.*$/.test(url)) {
      return this.transformAssets(url.replace('//storage.googleapis.com', '//assets.classkick.com'));
    }

    // if the url is storage.googleapis.com, transform to use www.googleapis.com
    if (/storage\.googleapis\.com/.test(url)) {
      return url.replace('//storage.googleapis.com', '//www.googleapis.com');
    }

    return url;
  }

  /**
   * If the url uses assets.classkick.com and does not have an extension already like png, gif, jpg, or jpeg,, the file
   * probably exists with .png and we need to add it.
   *
   * @param url
   * @returns {string|*}
   */
  static transformAssets(url) {
    if (this.cannotTransform(url)) {
      return url;
    }

    // if the url is assets.classkick.com, check if the url does not contain png extension
    if (/^(http|https):\/\/assets\.classkick\.com\/.*\.(png|gif|jpg|jpeg)$/.test(url) === false) {
      return `${url}.png`;
    }

    return url;
  }

  /**
   * Checks if url is not null, empty, undefined or is url accessed via http/https
   *
   * @param url
   * @returns {boolean}
   */
  static cannotTransform(url) {
    return /^(http|https):/.test(url) === false;
  }
}
