'use strict';
import Codec from './codec';
import GradebookStudent from '../domain/gradebook-student';

/**
 * Encodes/decodes Gradebook student information
 */
export default class GradebookStudentCodec extends Codec {
  /**
   * @param value {GradebookStudent}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        id: value.id,
        name: value.name,
        first_name: value.firstName,
        last_name: value.lastName,
        email: value.email? value.email : null,
        isLinkActive: value.isLinkActive
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {GradebookStudent}
   */
  decode(value) {
    if (value) {
      return new GradebookStudent(
        value.id,
        value.name,
        value.first_name,
        value.last_name,
        value.email ? value.email : null,
        value.is_link_active
      );
    }

    return null;
  }

}
