'use strict';

import moment from 'moment';
import {maxBy} from 'lodash';
import Subscription from './subscription';
import Sorts from './sorts';
import { OrderPurchasePeriod } from './order-purchase-period';

export class ContractPlans {

  /**
   * @returns {string}
   */
  static get PRO_CLASSROOM() {
    return 'niagara';
  }

  /**
   * @return {string}
   */
  static get PRO_SCHOOL() {
    return 'prometheus';
  }

  /**
   * @return {string}
   */
  static get PRO_DISTRICT() {
    return 'invincible';
  }

  static get All() {
    return [
      ContractPlans.PRO_CLASSROOM,
      ContractPlans.PRO_SCHOOL,
      ContractPlans.PRO_DISTRICT
    ];
  }

}

// Ranks the contract types
const ContractPlansRanking = {
  [ContractPlans.PRO_CLASSROOM]: 1,
  [ContractPlans.PRO_SCHOOL]: 2,
  [ContractPlans.PRO_DISTRICT]: 3
};

/**
 * Defines a contract
 */
export default class Contract {
  /**
   * @param id {string}
   * @param name {string}
   * @param plan {string}
   * @param created {string}
   * @param expires {string}
   * @param isTrial {boolean}
   * @param pricePaid {number}
   */
  constructor(id, name, plan, created, expires, isTrial, pricePaid) {
    this._id = id;
    this._name = name;
    this._plan = plan;
    this._created = created ? moment(created) : undefined;
    this._expires = expires ? moment(expires) : undefined;
    this._isTrial = isTrial;
    this._pricePaid = pricePaid;
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {string}
   */
  get name() {
    return this._name;
  }

  /**
   * @returns {string}
   */
  get plan() {
    return this._plan;
  }

  /**
   * @returns {moment}
   */
  get created() {
    return this._created;
  }

  /**
   * @return {string}
   */
  get createdDisplay() {
    return this.created && this.created.format('MMMM Do YYYY');
  }

  /**
   * @returns {moment}
   */
  get expires() {
    return this._expires;
  }

  /**
   * @returns {boolean}
   */
  get isTrial() {
    return this._isTrial;
  }

  /**
   * @returns {number}
   */
  get pricePaid() {
    return this._pricePaid;
  }

  /**
   * @return {string}
   */
  get expiresDisplay() {
    return this.expires && this.expires.format('MMMM D, YYYY');
  }

  /**
   * @return {string}
   */
  get subscriptionRenewsDisplay() {
    return this.expires && moment(this.expires).subtract(Subscription.GracePeriod, Subscription.GracePeriodUnit).format('MMMM D, YYYY');
  }

  /**
   * @returns {boolean}
   */
  get isProClassroom() {
    return this._plan === ContractPlans.PRO_CLASSROOM;
  }

  /**
   * @return {boolean}
   */
  get isProSchool() {
    return this._plan === ContractPlans.PRO_SCHOOL;
  }

  /**
   * @return {boolean}
   */
  get isProDistrict() {
    return this._plan === ContractPlans.PRO_DISTRICT;
  }

  /**
   * @return {boolean}
   */
  get isPro() {
    return this.isProClassroom || this.isProSchool || this.isProDistrict;
  }

  /**
   * @return {string}
   */
  get planType() {
    if (this.isProClassroom) {
      return 'Pro Classroom';
    }
    else if (this.isProSchool) {
      return 'Pro School';
    }
    else if (this.isProDistrict) {
      return 'Pro District';
    } else {
      return '';
    }
  }

  get isExpired() {
    if (!this._now) {
      this._now = moment();
    }

    return this._now.isAfter(this._expires);
  }

  get expiresThisSchoolYear() {
    return OrderPurchasePeriod.ThisYearEnd.year() === this.expires.year();
  }

  get activeDisplay() {
    return this.isExpired ? 'Inactive' : 'Active';
  }

  /**
   * Returns highest value contract in contract list, by default it removes expired contracts
   *
   * @param contracts {Contract[]}
   * @param ignoreExpired {boolean} (Optional, Default false)
   * @return {undefined|Contract}
   */
  static FilterContracts(contracts, ignoreExpired = false) {
    const activeDistrictPlans = contracts.filter((contract) =>
      (ignoreExpired || !contract.isExpired) && contract.plan === ContractPlans.PRO_DISTRICT);
    const activeSchoolPlans = contracts.filter((contract) =>
      (ignoreExpired || !contract.isExpired) && contract.plan === ContractPlans.PRO_SCHOOL);
    const activeProClassroomPlans = contracts.filter((contract) =>
      (ignoreExpired || !contract.isExpired) && contract.plan === ContractPlans.PRO_CLASSROOM);

    const districtPlan = activeDistrictPlans.length ? maxBy(activeDistrictPlans, (contract) => contract.expires): undefined;
    const schoolPlan =  activeSchoolPlans.length ? maxBy(activeSchoolPlans, (contract) => contract.expires): undefined;
    const classroomPlan = activeProClassroomPlans.length ? maxBy(activeProClassroomPlans, (contract) => contract.expires): undefined;

    if (districtPlan) {
      return districtPlan;
    } else if (schoolPlan) {
      return schoolPlan;
    } else if (classroomPlan) {
      return classroomPlan;
    }
    return undefined;
  }

  /**
   * Gets active contract with latest expiration date in the order of ranking from district, school and then classroom
   *
   * @param contracts {Contract[]}
   * @return {undefined|Contract}
   */
  static ActiveContract(contracts) {
    return Contract.FilterContracts(contracts, false);
  }

  /**
   * Get last highest value active contract from list of contracts
   *
   * @param contracts {Contract[]}
   * @return {undefined | Contract}
   */
  static LastActiveContract(contracts) {
    return Contract.FilterContracts(contracts, true);
  }

  static contractCountdown(contract) {
    const expiration = contract && contract.expires;
    const daysLeft = expiration && expiration.diff(moment(), 'days');
    return daysLeft;
  }
}
