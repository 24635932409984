
'use strict';

/**
 * An object which can observe an ObservableCollection
 */
export default class CollectionObserver {
  constructor() {
    if (angular.isUndefined(this.onAdded)) {
      throw new TypeError('The onAdded(value) method must be defined');
    }
    if (angular.isUndefined(this.onRemoved)) {
      throw new TypeError('The onRemoved(value) method must be defined');
    }
    if (angular.isUndefined(this.onChanged)) {
      throw new TypeError('The onChanged(value, oldValue) method must be defined');
    }
  }
}
