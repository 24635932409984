
'use strict';


import Codec from './codec';
import ElementCodec from './element-codec';
import ColorCodec from './color-codec';
import SlideForeground from '../ui/elements/slide-foreground';

export default class SlideForegroundCodec extends Codec {
  constructor() {
    super();
    this._colorCodec = new ColorCodec();
  }

  /**
   * @param value {SlideForeground}
   * @returns {{data: Object, metadata: Object}}
   */
  encode(value) {
    let data = {
      type: value.type,
      color: angular.isDefined(value.hex) ? this._colorCodec.encode(value.hex) : null,
      url: angular.isDefined(value.url) ? value.url : null,
      size: ElementCodec.sizeCodec.encode(value.size),
      center: ElementCodec.pointCodec.encode(value.location),
    };
    return ElementCodec.firebaseElement(data, value.metadata);
  }

  /**
   * @param value {object}
   * @param key {string}
   * @returns {SlideBackground}
   */
  decode(value, key) {
    return ElementCodec.preDecode(value, (metadata, size, location) => {
      return new SlideForeground(
        key,
        metadata,
        value.data.color && this._colorCodec.decode(value.data.color),
        value.data.url,
        size,
        location
      );
    });
  }
}
