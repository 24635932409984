
'use strict';

import AssignmentListItem from '../../model/domain/assignment-list-item';
import ShareDialogController from '../../components/share-dialog/share-dialog.controller';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import { Locations, PaywallSources } from '../../services/mixpanel/mixpanel.service';
import ImportImageDialog from '../../components/import-image-dialog/import-image-dialog.controller';
import { UserRoles } from '../../model/domain/user';
import { ElementIntents } from '../../model/domain/element-metadata';
import ElementMetadata from '../../model/domain/element-metadata';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import Assignment, { AssignmentTags } from '../../model/domain/assignment';
import FolderNameDialogController from '../../components/folder-name-dialog/folder-name-dialog.controller';
import MoveAssignmentDialog from '../../components/move-assignment-dialog/move-assignment-dialog.controller';
import FolderListItem from '../../model/domain/folder-list-item';
import ViewHelpDialogController, { ViewHelps } from '../../components/view-help-dialog/view-help-dialog.controller';
import ContextualPaywallDialogController, { ContextualPaywalls } from '../../components/contextual-paywall-dialog/contextual-paywall-dialog.controller';
import { ABTest } from '../../services/ab-test/ab-test-service';
import UpdateUserSchoolDialogController
  from '../../components/update-user-school-dialog/update-user-school-dialog.controller';
import { OrganizationTypes } from '../../model/domain/organization';
import AssignmentManager from '../../model/domain/assignment-manager';
import lodash from 'lodash';
import moment from 'moment/moment';
import { AppConfiguration, DEFAULT_ASSIGNMENT_LIMIT } from '../../model/domain/app-configuration';

/**
 * Defines the potential columns in the assignments table
 */
export class AssignmentsTableColumns {

  static get NAME() {
    return 'name';
  }

  static get LAST_MODIFIED() {
    return 'last_modified';
  }

  static get HELPS() {
    return 'helps';
  }

}

export default class AssignmentsListController {

  constructor($scope, $q, $document, $state, $stateParams, $mdSidenav, $mdDialog, $mdPanel, $mdToast, $log, $timeout, $rootScope,
    AuthService, RosterService, AssignmentService, HelpRequestService, CacheService, AnalyticsService,
    AssignmentTrackingService, BreadcrumbService, StaticContentService, StorageService, IPStackService,
    OrganizationService, GooglePickerService) {
    'ngInject';

    if (!AuthService.authData && !AuthService.authData.isTeacher) {
      BreadcrumbService.go('root.account.home');
      return;
    }

    this.$log = $log;
    this.$scope = $scope;
    this.$q = $q;
    this.$document = $document;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdSidenav = $mdSidenav;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;
    this.$mdToast = $mdToast;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {HelpRequestService} */
    this._helpRequestService = HelpRequestService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {AssignmentTrackingService} */
    this._assignmentTrackingService = AssignmentTrackingService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {IPStackService} */
    this._ipStackService = IPStackService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    this.googlePickerService = GooglePickerService;
    this.createFromGoogleDrive = this.createFromGoogleDrive.bind(this);

    this._assignmentManager = new AssignmentManager(
      this.$q,
      this.$mdDialog,
      this.$mdToast,
      this._cacheService,
      this._assignmentService,
      this._breadcrumbService,
      undefined,
      undefined,
      this._analyticsService,
      undefined
    );

    this._loadingDialog = LoadingDialogController.show;
    this._shareDialog = ShareDialogController.show;
    this._imageImportDialog = ImportImageDialog.show;
    this._helpDialog = ViewHelpDialogController.show;
    this._folderNameDialog = FolderNameDialogController.show;
    this._moveAssignmentDialog = MoveAssignmentDialog.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._contextualPaywallDialog = ContextualPaywallDialogController.show;
    this._errorDialog = ErrorDialogController.show;
    this._updateUserSchoolDialog = UpdateUserSchoolDialogController.show;
    
    // configures the columns to show in the table
    this._showName = true;
    this._showHelps = true;
    this._showLastModified = true;

    // configures the initial behavior of the table
    this._orderBy = this._storageService.assignmentsListColumn || AssignmentsTableColumns.LAST_MODIFIED;
    this._isAscending = angular.isDefined(this._storageService.assignmentsListAsc) ? this._storageService.assignmentsListAsc : true;

    // configures the initial search bar behavior
    this._query = this._storageService.assignmentDashboardQuery ? this._storageService.assignmentDashboardQuery : '';

    this._assignmentListItems = [];
    this._folders = [];
    this._loading = true;

    // Clean up after ourselves
    $scope.$on('$destroy', () => this._destroy());

    let copyAssignmentRequest = this._storageService.copyAssignmentRequest;
    let shouldCopyPublicAssignment = copyAssignmentRequest && copyAssignmentRequest.isValid;

    this._cacheService.getAppConfig()
      .then((config) => {
        this._appConfig = config;
        this.init()
          .then(() => {
            if (shouldCopyPublicAssignment) {
              this._checkAssignmentLimit(() => {
                this.copyPublicAssignment(copyAssignmentRequest.id, copyAssignmentRequest.name);
              });
            }
          });
      });

    this.handleErrorImportingFile();
  }

  /**
   * Loads a user's assignments and all the help requests for each assignment,
   * then configures notifications for help request updates
   */
  init() {
    //if a co-teacher, we need to reset authData back to co-teacher's correct auth token
    if (this.isACoTeacher()) {
      this._storageService.setUserInfoBackToCoTeacherAuth();
      return this._authService.processTokenResult(this._authService.coTeacherAuthData.token, this._storageService.rememberMe)
        .then(() => {
          this._initializeAssignmentListData();
        });
    } else {
      return this._initializeAssignmentListData();
    }
  }




  _initializeAssignmentListData() {
    if (this._authService.authData) {
      this.isFreeUser = this._authService.authData.isFree;
      /** @type {boolean} */
      this.isProUser = this._authService.authData.isPro;
    }
    return this.$q
      .all({
        assignmentsMap: this._cacheService.getAssignmentsForUser(true),
        proInfo: this._cacheService.getProInfo(),
        user: this._cacheService.getUser(),
        assignmentLimit20Segment: this._cacheService.getTestSegment(ABTest.AssignmentLimit20)
      })
      .then(({
        assignmentsMap,
        proInfo,
        user,
        assignmentLimit20Segment
      }) => {

        this._assignmentsMap = assignmentsMap;
        this._pro = proInfo;
        this._user = user;
        this.assignmentLimitCountdown = this._user.getAssignmentLimitCountdown(moment());
        this.isAssignmentLimit20Segment = assignmentLimit20Segment;

        const ownerId = this.isACoTeacher() ? this._authService.coTeacherAuthData.id : this._authService.authData.id;
        const assignments = Array.from(assignmentsMap, (tuple) => tuple[1]).filter((assignment) => assignment.ownerId === ownerId);

        // Inits AssignmentListItems
        const assignmentsOnly = assignments.filter((assignment) => !assignment.isFolder)
          .map((assignment) => new AssignmentListItem(assignment, false));

        this._assignmentListItems = lodash.orderBy(assignmentsOnly, (assignmentItem) => {
          return assignmentItem.assignment.lastModified;
        }, ['desc']);

        //sets assignments up to the number of the user's assignment limit to disabled if they are a basic user
        this._assignmentListItems.forEach((assignmentItem, index) => {
          if (index >= this.assignmentsLimit
            && this.isAssignmentLimit20Segment
            && this.assignmentLimitCountdown < 0
            && this.isFreeUser
            && (this._authService.viewAsUser === null || this._authService.viewAsUser)
          ) {
            assignmentItem.disabled = true;
          }
        });

        // Inits FolderListItems
        this._folders = assignments.filter((assignment) => assignment.isFolder)
          .map((folder) => new FolderListItem(folder, false));

        this._breadcrumbs = this.getBreadcrumbs(
          this.currentFolderId,
          this._folders.map((item) => item.assignment),
          []
        );

        this._loading = false;

        return this.$timeout(() => { });
      })
      .then(() => {
        // TODO temporary fix to address symptoms in ws-433 (https://getclasskick.atlassian.net/browse/WS-433)
        if (this._assignmentsMap.size < 350) {
          return this._initItemHelpRequests();
        }
      })
      .then(() => {
        // disabling popup which forces a user to join an org, per https://getclasskick.atlassian.net/browse/KARP-157. now returns an empty promise 💔
        return this._handleConfirmSchool();
        // return this.$q.resolve();
      })
      .catch((err) => {
        this.$log.error('#init', err);
        this._error = true;
      });
  }

  _initItemHelpRequests() {
    return this.$q.all(this._assignmentListItems.map((item) => this._initAssignmentHelpRequests(item)))
      .then(() => {
        // Initialize the folders after the assignments b/c folders help requests
        // are derived from assignments in folder
        return this._folders.map((item) => this._initFolderHelpRequests(item));
      });
  }

  /**
   * @param item {AssignmentListItem}
   * @return {Promise<AssignmentListItem>}
   */
  _initAssignmentHelpRequests(item) {
    return this._helpRequestService.getHelpRequestSetForAssignment(item.assignment.ownerId, item.assignment.id)
      .then((helpRequests) => {
        item.helpRequests = helpRequests;
        helpRequests.start();
        return item;
      });
  }

  /**
   * @param item {FolderListItem}
   * @return {FolderListItem}
   */
  _initFolderHelpRequests(item) {
    const sets = this._helpRequestSetsForFolder(item.folder.id);
    item.helpRequests = this._helpRequestService.combineHelpRequestSets(sets);
    return item;
  }

  /**
   * @param folderId {string}
   * @return {HelpRequestSet[]}
   */
  _helpRequestSetsForFolder(folderId) {
    let helpRequests = this._assignmentsForFolder(folderId).map((assignment) => assignment.helpRequests);

    // recursively loop over every sub-folder and accumulate those help requests
    this._foldersInFolder(folderId).forEach((folder) => {
      let folderHelpRequests = this._helpRequestSetsForFolder(folder.assignment.id);
      helpRequests = [...helpRequests, ...folderHelpRequests];
    });

    return helpRequests;
  }

  /**
   * @param folderId {string}
   * @return {Assignment[]}
   */
  _foldersInFolder(folderId) {
    return this._folders.filter((item) => item.assignment.folder === folderId);
  }

  /**
   * @param folderId {string}
   * @return {Assignment[]}
   */
  _assignmentsForFolder(folderId) {
    return this._assignmentListItems.filter((item) => item.assignment.folder && item.assignment.folder === folderId);
  }

  _handleConfirmSchool() {
    return this._cacheService.getSchools()
      .then((schools) => {

        // if user is not a part of a school and is part of confirm school ab test
        if (schools.length === 0) {

          let promise = this.$q.resolve();

          // If user has previously listed a school
          if (this._user.school) {
            // See if we have a good match in ours or Google's db
            promise = this._searchForSchool(this._user.school);
          }

          return promise.then((organizationOrPlace) => {
            this._analyticsService.userOrgConfirmed();

            // If we find a good match to the user's previously entered school, pass it along as default to matching dialog
            return this._updateUserSchoolDialog(this.$mdDialog, 'Confirm Organization', 'Please start typing the name of your organization. If you see your organization, click it and press "join organization." If you do not see your organization, click "add organization" and enter your organization\'s details.', organizationOrPlace, true)
              .then((school) => {
                return this._joinSchool(school);
              });
          });
        }
      })
      // Adding a catch block here prevents errors related to confirming a user's school from
      // Showing an error on the screen
      .catch((error) => {
        this.$log.error('#_handleConfirmSchool', error);
      });
  }

  /**
   * Adds user to selected school
   * @param school {Organization}
   * @return {Promise<Organization[] | never>}
   */
  _joinSchool(school) {
    let promise = this._organizationService.addSelf(school.id)
      .then((token) => {
        this._cacheService.reset();
        return this._authService.processTokenResult(token, this._authService.rememberMe);
      })
      .then(() => {
        this.$state.reload();
      });

    this._loadingDialog(this.$mdDialog, promise);

    return promise.then(() => {
      this._toast(`You are now a member of ${school.name}`);
    });
  }

  /**
   * @param schoolName {string}
   * @return {Promise<Organization|GooglePlace>}
   */
  _searchForSchool(schoolName) {
    return this._ipStackService.getLocation()
      .then((location) => {
        return this._organizationService.search(OrganizationTypes.School, location.lat, location.lng, schoolName);
      })
      .then((organizations) => {
        // If we have a match in our db, use it
        return organizations[0];
      });
  }

  /**
   * stop listening for notifications for assignment help requests
   */
  _destroy() {
    this._assignmentListItems.forEach((assignmentListItem) => {
      assignmentListItem.helpRequests && assignmentListItem.helpRequests.stop();
    });
  }

  /**
   * @return {ProInfo}
   */
  get pro() {
    return this._pro;
  }

  /**
   * indicates if table should display name column
   * @returns {boolean}
   */
  get showName() {
    return this._showName;
  }

  /**
   * indicates if table should display help requests column
   * @returns {boolean}
   */
  get showHelps() {
    return this._showHelps && this.hasRequests;
  }

  /**
   * indicates if table should display last modified column
   * @returns {boolean}
   */
  get showLastModified() {
    return this._showLastModified;
  }

  /**
   * gets the current value of the search bar query
   * @returns {string}
   */
  get query() {
    return this._query;
  }

  /**
   * sets the current value of the search bar query
   * @param value {string}
   */
  set query(value) {
    this._query = value;
  }

  /**
   * @returns {Array.<AssignmentListItem>}
   */
  get assignmentListItems() {
    if (this.query) {
      return this._assignmentListItems;
    }
    else if (this.currentFolderId) {
      return this._assignmentsForFolder(this.currentFolderId);
    }
    else {
      return this._assignmentListItems.filter((item) => {
        let noFolder = !item.assignment.folder;
        let missingFolder = false;
        if (!noFolder) {
          missingFolder = this._missingFolder(item.assignment.folder);
        }

        return noFolder || missingFolder;
      });
    }
  }

  /**
   * @returns {boolean}
   */
  _missingFolder(parentFolderId) {
    return parentFolderId && !this._folders.some(({ folder }) => folder.id === parentFolderId);
  }

  /**
   * @returns {boolean}
   */
  get loading() {
    return this._loading && !this._error;
  }

  /**
   * @returns {boolean}
   */
  get error() {
    return this._error;
  }

  /**
   * @returns {boolean}
   */
  get hasRequests() {
    return this.totalRequests > 0;
  }

  /**
   * Determines the total number of help requests
   * @returns {number}
   */
  get totalRequests() {
    return [...this.folders, ...this.assignmentListItems]
      .reduce((accum, item) => accum + (item.helpRequests ? item.helpRequests.total : 0), 0);
  }

  /**
   * indicates if currently selected column should be ascending or descending
   * @returns {boolean}
   */
  get isAscending() {
    return this._isAscending;
  }

  /**
   * indicates when to show carrot next to the name column in table header
   * @returns {boolean}
   */
  get showNameCarrot() {
    return this._orderBy === AssignmentsTableColumns.NAME;
  }

  /**
   * indicates when to show carrot next to the last modified column in table header
   * @returns {boolean}
   */
  get showModifiedCarrot() {
    return this._orderBy === AssignmentsTableColumns.LAST_MODIFIED;
  }

  /**
   * indicates when to show carrot next to the help request column in table header
   * @returns {boolean}
   */
  get showHelpsCarrot() {
    return this._orderBy === AssignmentsTableColumns.HELPS;
  }

  /**
   * handles the logic to change the focus and order of columns
   *
   * @param colName {string} one of the TableColumn properties
   * @param shouldAscend {boolean} should the column be ascending or descending
   */
  setOrToggle(colName, shouldAscend) {
    if (this._orderBy !== colName) {
      this._orderBy = colName;
      this._isAscending = shouldAscend;
    }
    else {
      this._isAscending = !this._isAscending;
    }
    this._storageService.assignmentsListColumn = this._orderBy;
    this._storageService.assignmentsListAsc = this._isAscending;
  }

  /**
   * sets the list order to the name column and/or toggles ascending/descending
   */
  setOrToggleName() {
    this.setOrToggle(AssignmentsTableColumns.NAME, true);
  }

  /**
   * sets the list order to the last modified column and/or toggles ascending/descending
   */
  setOrToggleModified() {
    this.setOrToggle(AssignmentsTableColumns.LAST_MODIFIED, false);
  }

  /**
   * sets the list order to the helps column and/or toggles ascending/descending
   */
  setOrToggleHelps() {
    this.setOrToggle(AssignmentsTableColumns.HELPS, false);
  }

  /**
   * @returns {Function} returns a function that know which column value to order by
   */
  orderBy() {
    let orderBy = this._orderBy;
    return function (item) {
      let assignmentOrFolder = item.folder || item.assignment;
      if (AssignmentsTableColumns.NAME === orderBy) {
        return assignmentOrFolder.name;
      }
      else if (AssignmentsTableColumns.LAST_MODIFIED === orderBy) {
        return assignmentOrFolder.lastModified;
      }
      else if (AssignmentsTableColumns.HELPS === orderBy && item.helpRequests) {
        return item.helpRequests.total;
      }
    };
  }

  /**
   * @returns {Function} returns a function that knows how to filter the list of assignments
   */
  searchFilter() {
    let query = this.query;
    return function (item) {
      if (query.length > 0) {
        let assignmentOrFolder = item.folder || item.assignment;
        return (assignmentOrFolder.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
      }
      return true;
    };
  }

  /**
   * Tracking for click on add assignment action
   */
  clickAddAssignment() {
    this._analyticsService.clickAddAssignmentAction();
  }

  /**
   * Creates a new assignment and transitions to new assignment in assignment edit state
   */
  create() {
    this._checkAssignmentLimit(() => {
      let promise = this._assignmentManager.create(this.newAssignment)
        .then((assignment) => {
          this.$state.go('root.account.assignment', {
            assignmentId: assignment.id,
            isNew: true
          });
        });

      this._analyticsService.createNewBlankAssignment();
      this._loadingDialog(this.$mdDialog, promise);
    });
  }

  /**
   * Creates a new assignment from a file
   */
  createFromFile() {
    this._checkAssignmentLimit(() => {
      this._imageImportDialog(this.$q, this.$mdDialog, [], true, true)
        .then((importResult) => {
          return this._handleImageImport(importResult);
        });
    });
  }

  createFromGoogleDrive() {
    this._googleDriveButtonClicked();
    this._checkAssignmentLimit(() => {
      this.googlePickerService.init((importResultPromise) => this._createFromGoogleDriveCallback(importResultPromise));
    });
  }

  _createFromGoogleDriveCallback(importResultPromise) {
    importResultPromise.then((result) => {
      return this._handleImageImport(result);
    });
  }

  _googleDriveButtonClicked() {
    this._analyticsService.googleDriveOpened();
  }

  /**
   * @param importResult {{newQuestions: QuestionDisplay[], images: ImageImport[]}}
   * @return {Promise<Assignment>}
   */
  _handleImageImport(importResult) {
    let promise = this._assignmentManager.create(this.newAssignment, 0)
      .then((assignment) => {

        const elementMetadata = new ElementMetadata(
          assignment.ownerId,
          UserRoles.TEACHER,
          ElementIntents.CONTENT
        );

        return this._assignmentTrackingService.importImages(
          importResult,
          assignment,
          elementMetadata
        );
      })
      .then((assignment) => {
        this.$state.go('root.account.assignment', {
          assignmentId: assignment.id,
          isNew: true
        });
      });

    this._analyticsService.createNewAssignmentByFileUpload();
    this._loadingDialog(this.$mdDialog, promise);

    return promise;
  }

  get newAssignment() {
    let assignment = { name: 'New Assignment' };

    if (this.currentFolderId) {
      assignment.tags = [
        {
          name: AssignmentTags.FOLDER,
          data: {
            parent: this.currentFolderId
          }
        }
      ];
    }

    return assignment;
  }

  /**
   * Launches a panel that allows the user to share their assignment with others
   *
   * @param assignment {Assignment}
   */
  share(assignment) {
    this._shareDialog(this.$mdDialog, assignment, Locations.ASSIGNMENT_LIST);
  }

  /**
   * Duplicates an assignment
   * @param assignmentId {string}
   * @param oldName {string}
   */
  duplicate(assignmentId, oldName) {
    this._checkAssignmentLimit(() => {
      this._assignmentManager.duplicate(assignmentId, oldName, false);
    });
  }

  /**
   * Copies the public assignment given by state params.
   * @param  {string} assignmentId
   * @param  {string} assignmentName
   */
  copyPublicAssignment(assignmentId, assignmentName) {
    const promise = this._assignmentService.duplicate(assignmentId, assignmentName)
      .then((assignment) => {
        this._storageService.copyAssignmentRequest = null;
        return this._cacheService.addAssignmentForUser(assignment);
      })
      .then((assignment) => {
        let item = new AssignmentListItem(assignment, true);
        return this._initAssignmentHelpRequests(item);
      })
      .then((item) => {
        this._assignmentListItems.push(item);
      });

    this._loadingDialog(this.$mdDialog, promise);

    promise.catch(() => {
      this._storageService.copyAssignmentRequest = null;
      this._errorDialog(
        this.$mdDialog,
        `Oops! Something went wrong copying ${assignmentName} to your account. Please try again and if you continue to have problems please reach out to support@classkick.com`
      );
    });
  }

  /**
   * Deletes an assignment with the specified id
   * @param assignment {Assignment}
   */
  delete(assignment) {
    this._assignmentManager.delete(assignment)
      .then(() => {
        this._assignmentListItems = this._assignmentListItems.filter((item) => item.assignment.id !== assignment.id);
        //updates to the assignment list so the number of user's assignments are always active
        const idxNumVisibleAssignments = this.assignmentsLimit - 1;
        if (this._assignmentListItems[idxNumVisibleAssignments]
          && this._assignmentListItems[idxNumVisibleAssignments].disabled === true) {
          this._assignmentListItems[idxNumVisibleAssignments].disabled = false;
        }
      });
  }

  /**
   * @param folderId {string}
   */
  deleteFolder(folderId) {
    if (angular.isUndefined(folderId)) {
      return;
    }

    let deleteMessage = `Deleting this folder will delete all assignments contained in this folder.
                         Are you sure you’d like to continue?`;
    this._confirmDialog(this.$mdDialog, deleteMessage)
      .then(() => {
        let promise = this._cacheService.deleteFolder(folderId);

        this._loadingDialog(this.$mdDialog, promise);

        return promise;
      })
      .then(() => {
        this._folders = this._folders.filter((item) => item.folder.id !== folderId);
        this._toast('Folder deleted');
      });
  }

  watchAssignment(assignmentId) {
    this.$state.go('root.account.session.work', {
      assignmentId: assignmentId
    });
    this._analyticsService.tappedViewStudentWork(assignmentId, Locations.ASSIGNMENT_LIST);
  }

  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }

  /**
   * The number of assignments needed to not show the help message under the assignments list
   * @returns {number}
   */
  get ASSIGNMENTS_HELPER_LIMIT() {
    return 5;
  }

  get showHelperMessage() {
    if (!this._showHelperMessage && this._assignmentsMap && this._user) {
      let belowAssignmentHelperLimit = this._assignmentsMap && this._assignmentsMap.size <= this.ASSIGNMENTS_HELPER_LIMIT;
      let isNewUser = this._user && this._user.isNewUser;
      this._showHelperMessage = belowAssignmentHelperLimit || isNewUser;
    }
    return this._showHelperMessage;
  }

  showFirstSuccessHelp() {
    this._helpDialog(this.$mdDialog, ViewHelps.FirstSuccess);
  }

  showHelp() {
    this._helpDialog(this.$mdDialog, ViewHelps.AssignmentsList);
  }

  goToPremade() {
    this._analyticsService.clickOnBrowseAssignmentLibrary();
    this._breadcrumbService.go('root.account.nav.public-assignments-library', {});
  }

  /** Folders **/

  /**
   * @return {string}
   */
  get currentFolderId() {
    return this.$stateParams.folderId;
  }

  /**
   * @return {boolean}
   */
  get showBreadcrumbs() {
    return this.currentFolderId && !this.query;
  }

  /**
   * @return {boolean}
   */
  get showBanner() {
    return (this._storageService.lastSeenTrialConversionBanner && this._storageService.lastSeenTrialConversionBanner.showBanner === true)
      || (this._storageService.lastSeenRenewalConversionBanner && this._storageService.lastSeenRenewalConversionBanner.showBanner === true)
      || (this._storageService.lastSeenAssignmentNotificationBanner && this._storageService.lastSeenAssignmentNotificationBanner.showBanner === true);
  }

  /**
   * @return {Assignment}
   */
  get currentFolder() {
    return this._folders
      .map((item) => item.folder)
      .find((folder) => folder.id === this.currentFolderId);
  }

  /**
   * @return {FolderListItem[]}
   */
  get folders() {
    // a folder list item holds an Assignment named "folder", which is the actual folder.
    // an Assignment has a folder accessor that is the actual folder id.
    return this._folders.filter((folderListItem) => {
      let matchingFolder = folderListItem.folder.folder === this.currentFolderId;
      // check for missing parent folder if we're at the root level
      let missingFolder = false;
      if (!matchingFolder && !this.currentFolderId) {
        missingFolder = this._missingFolder(folderListItem.folder.folder);
      }

      return matchingFolder || missingFolder;
    });
  }

  /**
   * @return {Assignment[]}
   */
  get breadcrumbs() {
    return this._breadcrumbs;
  }

  createFolder() {
    this._folderNameDialog(this.$mdDialog)
      .then((name) => {
        let folder = { name: name };
        if (this.currentFolderId) {
          folder.tags = [
            {
              name: AssignmentTags.FOLDER,
              data: {
                parent: this.currentFolderId
              }
            }
          ];
        }

        return this._assignmentService.createFolder(folder);
      })
      .then((folder) => {
        return this._cacheService.addAssignmentForUser(folder);
      })
      .then((folder) => {
        let newFolder = new FolderListItem(folder, true);
        this._initFolderHelpRequests(newFolder);
        this._folders = [...this._folders, newFolder];
        this._analyticsService.createFolderAction();
        this._toast('New folder created');
      });
  }

  /**
   * @param folder {Assignment}
   */
  renameFolder(folder) {
    this._folderNameDialog(this.$mdDialog, folder.name, 'Rename Folder')
      .then((name) => {
        folder.name = name;
        return this._cacheService.updateAssignmentForUser(folder);
      })
      .then(() => {
        this._toast('Folder name updated');
      });
  }

  /**
   * @param assignment {Assignment}
   */
  moveAssignmentToFolder(assignment) {
    let folders = this._folders.map((item) => item.folder);

    this._moveAssignmentDialog(this.$mdDialog, folders, assignment)
      .then((folder) => {
        return this._moveAssignmentToFolder(assignment, folder);
      });
  }

  /**
   * @param assignment {Assignment}
   * @param folder {Assignment}
   * @return {Promise}
   */
  _moveAssignmentToFolder(assignment, folder) {
    assignment.folder = MoveAssignmentDialog.RootDirectory === folder.id ? undefined : folder.id;
    return this._cacheService.updateAssignmentForUser(assignment)
      .then(() => {
        this._toast(`${assignment.name} has been moved to ${folder.name}`);
      });
  }

  /**
   * @param folder {Assignment}
   */
  goToFolder(folder) {
    this.$state.go('root.account.nav.folder', {
      folderId: folder.id
    });
  }

  /**
   * @param folderId {string|undefined}
   * @param folders {Array}
   * @param collection {Assignment[]}
   * @return {Assignment[]}
   */
  getBreadcrumbs(folderId, folders, collection = []) {
    if (!folderId) {
      return collection;
    }

    let folder = folders.find((f) => {
      return f.id === folderId;
    });

    if (folder) {
      return this.getBreadcrumbs(folder.folder, folders, [folder, ...collection]);
    }

    return this.getBreadcrumbs(undefined, folders, collection);
  }

  /**
   * @param message {string}
   */
  _toast(message) {
    let config = this.$mdToast.simple().textContent(message).position('bottom right');
    this.$mdToast.show(config);
  }

  /**
   * @return {string}
   */
  get assignmentsListTitle() {
    if (!this.loading && this.currentFolderId) {
      return this.currentFolder.name;
    }
    else {
      return 'Assignments';
    }
  }

  /**
   * @return {boolean}
   */
  get showAssignmentLimit() {
    return this._isOverLimit(0.5) && !this.pro.hasUnlimitedAssignments;
  }

  /**
   * @return {string}
   */
  get assignmentLimitText() {
    if (this.loading) {
      return '';
    }
    if (this.assignmentsUsedCount > this.assignmentsLimit && this.isAssignmentLimit20Segment) {
      return ` ${this.assignmentsLimit} out of ${this.assignmentsUsedCount} assignments available`;
    }
    else {
      return `${this.assignmentsUsedCount} of ${this.assignmentsLimit} assignments used`;
    }
  }

  /**
   * @return {number}
   */
  get assignmentsUsedCount() {
    return (this._assignmentsMap && this._folders) && this._assignmentsMap.size - this._folders.length;
  }

  /**
   * @return {number}
   */
  get assignmentsLimit() {
    return (this._appConfig && this._appConfig.assignmentLimit) || DEFAULT_ASSIGNMENT_LIMIT;
  }

  /**
   * @param value {number}
   * @return {boolean}
   */
  _isOverLimit(value) {
    return this.assignmentsUsedCount / this.assignmentsLimit >= value;
  }

  /**
   * @param value {number}
   * @return {boolean}
   */
  _isAtLimit(value) {
    return this.assignmentsUsedCount / this.assignmentsLimit === value;
  }

  /**
   * @return {boolean}
   */
  get willBeOverAssignmentLimit() {
    return this._willBeOverLimit(1);
  }

  /**
   * @param value {number}
   * @return {boolean}
   */
  _willBeOverLimit(value) {
    return (this.assignmentsUsedCount + 1) / this.assignmentsLimit >= value;
  }

  /**
   * @param value {number}
   * @return {boolean}
   */
  _willBeAtLimit(value) {
    return (this.assignmentsUsedCount + 1) / this.assignmentsLimit === value;
  }

  /**
   * @param assignmentsUsedCount {number}
   * @param assignmentsLimit {number}
   * @param clickedFrom {string}
   * @param isDisabledAssignment {boolean}
   * @param assignmentId {string}
   */
  openAssignmentLimitPaywall(assignmentsUsedCount, assignmentsLimit, isDisabledAssignment = false, assignmentId = null) {
    return this._assignmentManager.openAssignmentLimitPaywall(assignmentsUsedCount, assignmentsLimit, Locations.ASSIGNMENT_LIST, isDisabledAssignment, assignmentId);
  }

  /**
   * Checks if user is above assignment limit
   * @param f {Function}
   */
  _checkAssignmentLimit(f) {
    // if user does not have unlimited assignments and is at/past limit
    if (!this.pro.hasUnlimitedAssignments && (this._willBeAtLimit(0.5) || this._willBeAtLimit(1))) {
      this.openAssignmentLimitPaywall(this.assignmentsUsedCount, this.assignmentsLimit)
        .finally(() => {
          f();
        });
    }
    else if (!this.pro.hasUnlimitedAssignments && this.willBeOverAssignmentLimit) {
      this.openAssignmentLimitPaywall(this.assignmentsUsedCount, this.assignmentsLimit);
    }
    else {
      f();
    }
  }

  /**
   * @param assignmentId {string}
   * @param [assignRosters] {boolean}
   */
  goToAssignment(assignmentId, assignRosters = false) {
    if (assignRosters) {
      this._breadcrumbService.go('root.account.assignment-rosters', { assignmentId });
    }
    else {
      this._breadcrumbService.go('root.account.assignment', { assignmentId });
    }
  }

  /**
   * @param assignmentId {string}
   * @param assignRosters {boolean}
   */
  goToAssignmentOrOpenPaywall(assignmentItem, assignRosters = false) {
    if (assignmentItem.disabled) {
      this._analyticsService.clickOnDisabledAssignment(this.assignmentsUsedCount, assignmentItem.assignment.id);
      return this.openAssignmentLimitPaywall(this.assignmentsUsedCount, this.assignmentsLimit, true, assignmentItem.assignment.id);
    }
    return this.goToAssignment(assignmentItem.assignment.id, assignRosters);
  }

  /**
   * @param event {jQuery.Event}
   * @param assignmentId {string}
   */
  onDragStart(event, assignmentId) {

    // Add dragging class to list item to get desired styling
    let element = angular.element(`#${assignmentId}`);
    element.addClass('dragging');

    // Use the inner contents of the list item for the display image
    let listItemInner = angular.element(`#${assignmentId} .md-list-item-inner`);
    event.dataTransfer.setDragImage(listItemInner[0], 25, 25);

    event.dataTransfer.setData('classkick/assignmentId', assignmentId);
  }

  /**
   * Called on the assignment that is being dragged after drag is done
   * @param event {jQuery.Event}
   * @param assignmentId {string}
   */
  onDragEnd(event, assignmentId) {
    let element = angular.element(`#${assignmentId}`);
    element.removeClass('dragging');
    this._currentFolderId = '';
  }

  /**
   * @param event {jQuery.Event}
   */
  onDragOver(event) {
    event.preventDefault();

    let folder = event.target.parentElement.parentElement;

    if (this._currentFolderId === folder.id) {
      return;
    }

    this._currentFolderId = folder.id;
    angular.element(folder).addClass('drag-over');
  }

  /**
   * @param event {jQuery.Event}
   */
  onDragLeave(event) {
    let folder = event.target.parentElement.parentElement;
    angular.element(folder).removeClass('drag-over');
    this._currentFolderId = '';
  }

  /**
   * Called when assignment is dropped in a folder's target zone
   * @param event {jQuery.Event}
   */
  onDrop(event) {
    let assignmentId = event.dataTransfer.getData('classkick/assignmentId');
    let assignment = this._assignmentListItems
      .map((item) => item.assignment)
      .find((assignment) => assignment.id === assignmentId);

    let folders = this._folders.map((item) => item.folder).filter((f) => f.id !== this.currentFolderId);
    let folder = folders.find((folder) => folder.id === event.target.parentElement.parentElement.id);

    this._moveAssignmentToFolder(assignment, folder);

    angular.element(`#${folder.id}`).removeClass('drag-over');
  }

  /**
   * Called when a folder is dropped in the "my assignments" drop zone from within a folder
   * @param event
   */
  onDropMyAssignments(event) {
    let assignmentId = event.dataTransfer.getData('classkick/assignmentId');
    let assignmentItem = this._assignmentListItems.find((item) => item.assignment.id === assignmentId);
    let assignment = assignmentItem && assignmentItem.assignment;

    this._moveAssignmentToFolder(assignment, MoveAssignmentDialog.rootDirectoryObject);
  }

  handleChange() {
    this._storageService.assignmentDashboardQuery = this._query;
  }

  isACoTeacher() {
    return this._authService.isACoTeacher();
  }

  handleErrorImportingFile() {
    this.$scope.$watch(() => this.$rootScope.importError, (newValue, oldValue) => {
      if (newValue === true && oldValue === false) {
        // Call the function when the boolean becomes true
        this._errorDialog(
          this.$mdDialog,
          'Error Importing',
          'We are unable to import this assignment.<br> It may be too large for Google Slide Import (over 30 slides).<br> Try making it smaller and trying again, import it as a PDF, or try with a different assignment.'
        );
      }
    });
  }
}
