
'use strict';

import {
  HelpArticleCategories,
  HelpArticleCategoriesDisplayMap,
  HelpArticleSections, HelpArticleSectionsDisplayMap
} from '../../model/domain/help-article';
import Sorts from '../../model/domain/sorts';
import { TeacherHelpCenterViews } from '../../services/mixpanel/mixpanel.service';

export default class HelpSectionController {

  /**
   * @ngInject
   */
  constructor($state, $stateParams, StaticService, CacheService, HelpArticleService, AuthService, BreadcrumbService,
              AnalyticsService) {

    this.$state = $state;
    this.$stateParams = $stateParams;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {HelpArticleService} */
    this._helpArticleService = HelpArticleService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._displayingInIpadApp = StaticService.isClasskickIPadApp;

    this._sectionId = this.$stateParams.sectionId;
    this._articles = [];

    this.init();
  }

  init() {
    if (!this._sectionId || !HelpArticleSections.isValid(this._sectionId)) {
      this.$state.go('root.help');
    }

    this._cacheService.getArticles()
      .then((articles) => {
        this._articles = articles.filter((a) => a.sectionId === this._sectionId)
          .sort((a, b) => Sorts.NUMERIC_UNDEFINED_ASC(a.priority, b.priority));

        this._categoryId = this._articles[0] && this._articles[0].categoryId;

        this._analyticsService.teacherHelpCenter(TeacherHelpCenterViews.Section, this._sectionId);
      });
  }

  createNewArticle(categoryId, sectionId) {
    if (this._authService.authData && this._authService.authData.isAdmin) {
      this._helpArticleService.create(categoryId, sectionId)
        .then((article) => {
          this.$state.go('root.help-article', {
            articleId: article.id
          });
        });
      }
  }

  /**
   * @return {string}
   */
  get sectionId() {
    return this._sectionId;
  }

  get articles() {
    return this._articles;
  }

  filterBySection(sectionId) {
    return (article) => {
      return article.sectionId === sectionId;
    };
  }

  goToCategory() {
    this.$state.go('root.help-category', {
      categoryId: this._categoryId
    });
  }

  goToArticle(articleId) {
    this.$state.go('root.help-article', {
      articleId
    });
  }

  goToHelpCenter() {
    this.$state.go('root.help');
  }

  returnToClasskick() {
    this.$state.go('root.account.home');
  }

  get categoryId() {
    return this._categoryId;
  }

  get categoryDisplay() {
    return this._categoryId && HelpArticleCategoriesDisplayMap[this._categoryId];
  }

  get sectionDisplay() {
    return this._sectionId && HelpArticleSectionsDisplayMap[this._sectionId];
  }

  get WhatIsClasskick() {
    return HelpArticleCategories.WhatIsClasskick;
  }

  get WhatIsClasskick_Default() {
    return HelpArticleSections.WhatIsClasskick_Default;
  }

  get WhatIsClasskick_Secondary() {
    return HelpArticleSections.WhatIsClasskick_Secondary;
  }

  get GettingStarted() {
    return HelpArticleCategories.GettingStarted;
  }

  get GettingStarted_Edit() {
    return HelpArticleSections.GettingStarted_Edit;
  }

  get GettingStarted_Assign() {
    return HelpArticleSections.GettingStarted_Assign;
  }

  get GettingStarted_View() {
    return HelpArticleSections.GettingStarted_View;
  }

  get BeyondTheBasics() {
    return HelpArticleCategories.BeyondTheBasics;
  }

  get BeyondTheBasics_DeeperFeedback() {
    return HelpArticleSections.BeyondTheBasics_DeeperFeedback;
  }

  get BeyondTheBasics_MeaningfulAssignments() {
    return HelpArticleSections.BeyondTheBasics_MeaningfulAssignments;
  }

  get BeyondTheBasics_AnalyzeData() {
    return HelpArticleSections.BeyondTheBasics_AnalyzeData;
  }

  get BeyondTheBasics_Collaboration() {
    return HelpArticleSections.BeyondTheBasics_Collaboration;
  }

  get BeyondTheBasics_Grading() {
    return HelpArticleSections.BeyondTheBasics_Grading;
  }

  get hideOutsideLinks() {
    return this._displayingInIpadApp;
  }
}
