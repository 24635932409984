'use strict';

import CleverInfoCodec from '../../model/codec/clever-info-codec';

export default class CleverService {

  /**
   * @ngInject
   */
  constructor($window, $mdDialog, $location, $log, $http, environment, PlatformHeaderService) {
    this.$window = $window;
    this.$mdDialog = $mdDialog;
    this.$location = $location;
    this.$log = $log;
    this.$http = $http;
    this._environment = environment;

    /** @type {PlatformHeaderService} */
    this._platformHeaderService = PlatformHeaderService;

    this._cleverInfoCodec = new CleverInfoCodec();
  }

  linkToCleverAuth(classCode) {
    try {
      this.$window.location.href = this._cleverAuthUrl(classCode);
    }
    catch (error) {
      this.$log.error(error);
    }
  }

  /**
   * @param token {string}
   * @return {Promise<CleverInfo>}
   */
  getInfo(token) {
    let url = `${this._environment.serverUrlBase}/v1/clever/${token}`;
    let config = this._platformHeaderService.addHeaders(url, {});
    return this.$http.get(url, config).then((response) => {
      return this._cleverInfoCodec.decode(response.data);
    });
  }


  /**
   * @return {string}
   */
  get redirectUri() {
    return `${this._environment.serverUrlBase}/v1/clever/auth`;
  }

  /**
   * @return {string}
   */
  _cleverAuthUrl(classCode) {
    let clientId = this._environment.clever.clientId;
    let state = angular.toJson({classCode, redirectUri: this.redirectUri});
    let params = `?response_type=code&client_id=${clientId}&redirect_uri=${this.redirectUri}&state=${state}`;
    this.$mdDialog.cancel();
    return `https://clever.com/oauth/authorize${params}`;
  }

  goToLogin() {
    try {
      this.$window.location.href = 'https://clever.com/login';
    }
    catch (error) {
      this.$log.error(error);
    }
  }

}
