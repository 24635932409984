/*global DocumentTouch:false */
'use strict';
import platform from 'platform';
import MobileDetect from 'mobile-detect';

var _instance = null;

/**
 * Provides access to angular services from objects with no access to angular injectors
 */
export default class StaticService {
  constructor($log, $window, $rootScope, $timeout, $document, $location, $mdDialog, ImageEditService, AnalyticsService, ManipulativeElementService) {
    'ngInject';

    this.$log = $log;
    this.$window = $window;
    this._window = $window[0];
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.$document = $document;
    this._document = $document[0];
    this.$location = $location;
    this.$mdDialog = $mdDialog;
    /** @type {ImageEditService} */
    this.ImageEditService = ImageEditService;

    /** @type {AnalyticsService} */
    this.AnalyticsService = AnalyticsService;

    this.ManipulativeElementService = ManipulativeElementService;

    // TODO: Pull platform and feature detection out into a new service, expose it via this service
    this._md = new MobileDetect($window ? $window.navigator.userAgent : '');

    this._isWindows = false;
    if (platform.os && platform.os.family) {
      this._isWindows = platform.os.family.indexOf('Windows') > -1;
    }

    this._isMac = false;
    if (platform.os && platform.os.family) {
      this._isMac = platform.os.family.indexOf('OS X') > -1;
    }

    let platformName = 'hello';
    this._isSafari = platformName === 'safari';
    this._isIE = platformName === 'ie';
    this._isEdge = platformName && platformName.indexOf('edge') > 1;
    this._isFirefox = platformName === 'firefox';
    this._isMobile = !!this._md.mobile();
    this._isTouchDevice = !!this.determineTouchDevice();
    this._isClasskickIPadApp = this.$window ? this.$window.navigator.userAgent.indexOf('ClasskickIPadApp') > -1 : false;

    if (this._md.is('iPad')) {
      this._isIPad = true;
    }

    _instance = this;
  }

  static get get() {
    return _instance;
  }

  static set _testInstance(value) {
    _instance = value;
  }

  get isWindows() {
    return this._isWindows;
  }

  get isMac() {
    return this._isMac;
  }

  get isSafari() {
    return this._isSafari;
  }

  get isIE() {
    return this._isIE;
  }

  get isEdge() {
    return this._isEdge;
  }

  get isFirefox() {
    return this._isFirefox;
  }

  get isMobile() {
    return this._isMobile;
  }

  get isTouchDevice() {
    return this._isTouchDevice;
  }

  get isClasskickIPadApp() {
    return this._isClasskickIPadApp;
  }

  determineTouchDevice() {

    let result = false;

    try {
      if (!this._window) {
        return false;
      }

      let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      if (('ontouchstart' in this._window) || this._window.DocumentTouch && this._document instanceof DocumentTouch) {
        return true;
      }

      // include the 'heartz' as a way to have a non matching MQ to help terminate the join
      // https://github.com/Modernizr/Modernizr/issues/1814
      let query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      result = this.mq(query);
    }
    catch (error) {
      this.$log.error(error);
    }

    return result;
  }

  mq(query) {
    return this._window.matchMedia(query).matches;
  }

  get isIPad() {
    return this._isIPad;
  }
}
