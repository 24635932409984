'use strict';

import { Notifications } from '../../services/toolbar/toolbar.service';
import ContributorsTabTemplate from '../../components/contributors-tab/contributors-tab.component.html';

class ContributorsTabController {
  /* @ngInject */
  constructor($scope, $timeout, $mdDialog, $stateParams, AuthService, AssignmentTrackingService, ToolbarService,
              CacheService, ContributorHistoryService) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$mdDialog = $mdDialog;
    this.$stateParams = $stateParams;
    this._contributors = [];

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {ContributorHistoryService} */
    this._contributorHistoryService = ContributorHistoryService;

    /** @type {SessionData} */
    this._sessionData = null;
    this._error = null;
    this._currentTeacherId = this._authService.currentUserId;
    this._currentStudentId = this._toolbarService.target.ownerId;
    this._assignmentWorkId = $stateParams.assignmentWorkId;
    this._highlightedContributorId = null;
    this._assignmentId = $stateParams.assignmentId;
    this._rosterId = $stateParams.rosterId;
    this._hasTeacherFeedback = false;
    this._hasStudentWork = false;
    this._contributors = [];
    this._init();

    $scope.$on('$destroy', () => this._destroy());
  }

  _init() {
    this._cacheService.getSessionData(this._assignmentId, this._rosterId, false, true)
      .then((data) => {
        this._sessionData = data;
        this._currentStudentId = data && data.works.get(this._assignmentWorkId).ownerId;
        this.getContributors();
        this._contributorHistoryService.updated.subscribe(this._hasCanvasUpdate, this);
      })
      .catch((err) => {
        this._error = err;
        throw err;
      });
  }

  _hasCanvasUpdate() {
    this.$timeout(() => {
      this._toolbarService.notify(Notifications.SHOW_ALL_CONTRIBUTORS);
      this._cacheService.getSessionData(this._assignmentId, this._rosterId, false, false)
        .then((data) => {
          this._sessionData = data;
          this._currentStudentId = data && data.works.get(this._assignmentWorkId).ownerId;
          this.getContributors();
        })
        .catch((err) => {
          this._error = err;
          throw err;
        });
    }, 500);
  }

  _destroy(){
    this._contributorHistoryService.updated.unsubscribe(this._hasCanvasUpdate, this);
  }

  get contributors() {
    return this._contributors;
  }

  set contributors(value) {
    this._contributors = value;
  }

  highlightContributions(user) {
    if (this.contributors.length === 1) {
      if (this._highlightedContributorId !== null) {
        this._toolbarService.notify(Notifications.HIDE_ALL_CONTRIBUTORS);
        this._highlightedContributorId = null;
      } else {
        this._toolbarService.notify(Notifications.HIGHLIGHT_CONTRIBUTOR, { userId: user.id });
        this._highlightedContributorId = user.id;
      }
    }
    else if (this._highlightedContributorId !== user.id) {
      this._toolbarService.notify(Notifications.HIGHLIGHT_CONTRIBUTOR, { userId: user.id });
      this._highlightedContributorId = user.id;
    } else {
      this._toolbarService.notify(Notifications.SHOW_ALL_CONTRIBUTORS);
      this._highlightedContributorId = null;
    }
  }

  /**
   * @returns {SessionData}
   */
  get data() {
    return this._sessionData;
  }

  get teacher() {
    return this.data.teacher;
  }

  get currentStudent() {
    return this.data.students.get(this._currentStudentId);
  }

  getContributors() {
    this.updateWorkStatus();
    let entireHelpHistory = this.data.studentHelpHistorySortedChronologically(this._currentStudentId);
    let helpHistoryForThisQuestion = this.findHelpersForThisQuestion(entireHelpHistory);
    let contributorsObject = this.formContributorsObject(helpHistoryForThisQuestion);
    let contributorsArray = Object.values(contributorsObject);

    this.contributors = contributorsArray;
    if (this.contributors.length === 1) {
      this._highlightedContributorId = this.contributors[0].id;
    } else {
      this._highlightedContributorId = null;
    }
  }

  updateWorkStatus() {
    this.data.workById(this._assignmentWorkId).questionForId(this.$stateParams.questionId).elements._objectMap
      .forEach((element) => {
        if (element.metadata.intent === 'feedback' && element.metadata.role === 'teacher') {
          this._hasTeacherFeedback = true;
        }
        if (element.metadata.intent === 'work' && element.metadata.ownerId === this.currentStudent.id) {
          this._hasStudentWork = true;
        }
      });
  }

  formContributorsObject(helps) {
    let contributors = {};

    if (this._hasTeacherFeedback) {
      contributors[this.teacher.id] = this.teacher;
    }

    if (this._hasStudentWork) {
      contributors[this.currentStudent.id] = this.currentStudent;
    }

    for (const help of helps) {
      let contributor = this.data.students.get(help.helperId);
      if (contributor && contributor !== this.currentStudent.id && !contributors[contributor.id]) {
        contributors[contributor.id] = contributor;
      }
    }

    return contributors;
  }

  findHelpersForThisQuestion(allHelpers) {
    let filteredList = allHelpers.filter((helper) => {
      if (helper.questionId === this.$stateParams.questionId) {
        return helper;
      }
    });
    return filteredList;
  }

  cancelSidenav() {
    this._toolbarService.notify(Notifications.SHOW_ALL_CONTRIBUTORS);
    this._toolbarService.sidenavManager.closeState();
    this._highlightedContributorId = null;
  }

  identifierForUser(user) {
    if (user.id === this._currentTeacherId) {
      return 'You';
    } else if (user.id === this._currentStudentId) {
      return 'This Student';
    } else {
      return 'Peer Helper';
    }
  }

  displayEyes(user) {
    if (this.contributors.length === 1) {
      if (this._highlightedContributorId === user.id) {
        return 'ck-opened-eye';
      } else {
        return 'ck-closed-eye';
      }
    } else if (this._highlightedContributorId === user.id || this._highlightedContributorId === null) {
      return 'ck-opened-eye';
    } else {
      return 'ck-closed-eye';
    }
  }
}

export default {
  bindings: {
    options: '<'
  },
  template: ContributorsTabTemplate,
  controller: ContributorsTabController,
  controllerAs: 'ctrl'
};
