
'use strict';

import AssignmentCodec from '../../model/codec/assignment-codec';
import ClassCodeCodec from '../../model/codec/class-code-codec';
import { AssignmentTags } from '../../model/domain/assignment';
import LibraryAssignmentCodec from '../../model/codec/library-assignment-codec';
import RosterCodec from '../../model/codec/roster-codec';
import UserCodec from '../../model/codec/user-codec';
import AssignmentWorkCodec from '../../model/codec/assignment-work-codec';

/**
 * Manages assignments
 */
export default class AssignmentService {
  constructor(HttpService, $q, environment, FirebaseService, BootstrapService, AuthService, AnalyticsService, $log) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;
    this._environment = environment;

    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {BootstrapService} */
    this._bootstrapService = BootstrapService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {AssignmentCodec} */
    this._assignmentCodec = new AssignmentCodec(this._firebaseService);
    /** @type {ClassCodeCodec} */
    this._classCodeCodec = new ClassCodeCodec();
    /** @type {RosterCodec} */
    this._rosterCodec = new RosterCodec();
    /** @type {UserCodec} */
    this._userCodec = new UserCodec();
    /** @type {AssignmentWorkCodec} */
    this._assignmentWorkCodec = new AssignmentWorkCodec();
    /** @type {LibraryAssignmentCodec} */
    this._libraryAssignmentCodec = new LibraryAssignmentCodec(this._firebaseService);

  }

  /**
   * @deprecated
   * Retrieves the assignments for a user. Defaults to the currently logged in user.
   *
   * @param [userId] {string}
   * @returns {Promise.<Array.<Assignment>>}
   */
  getUserAssignments(userId) {
    return this.getForUser(userId || this._authService.authData.id);
  }

  /**
   * Retrieves the assignments for the currently logged in user.
   *
   * @returns {Promise.<Array.<Assignment>>}
   */
  getForCurrentUser() {
    return this.getForUser(this._authService.authData.id);
  }

  /**
   * Retrieves the assignments for a user.
   *
   * @param userId {string}
   * @returns {Promise.<Array.<Assignment>>}
   */
  getForUser(userId) {
    return this._httpService.authGet(this._uri(`/v1/users/${userId}/assignments?includeFolders=true`))
      .then((data) => {
        return data.assignments.map((assignment) => {
          return this._assignmentCodec.decode(assignment);
        });
      });
  }

  getAssignmentId(assignments , index){
    return assignments[index]._id;
  }

  /**
   * Deletes an assignment with the specified id
   * @param assignmentId {string}
   * @returns {Promise.<*>}
   */
  delete(assignmentId) {
    return this._httpService.authDelete(this._uri(`/v1/assignments/${assignmentId}`));
  }

  /**
   * Deletes a folder with the specified id
   * @param folderId {string}
   * @returns {Promise.<*>}
   */
  deleteFolder(folderId) {
    return this._httpService.authDelete(this._uri(`/v1/assignments/folder/${folderId}`));
  }

  /**
   * Creates a new assignment owned by the caller
   *
   * @param assignment {object}
   * @param [questionCount] {int}
   * @returns {Promise.<Assignment>}
   */
  create(assignment, questionCount) {
    return this._httpService.authPost(
      this._uri('/v1/assignments'),
      {
        name: assignment.name,
        brief: assignment.brief,
        access: assignment.access,
        tags: assignment.tags || [],
        question_count: angular.isNumber(questionCount) ? questionCount : 3
      })
      .then((data) => {
        const result = this._assignmentCodec.decode(data);
        this._analyticsService.assignmentCreated(result.id);
        return result;
      });
  }

  makePublic(assignment) {
    return this._httpService.authPost(
      this._uri(`/v1/assignments/make-public/${assignment.id}`),
      this._assignmentCodec.encode(assignment)
    );
  }

  /**
   * @param folder {object}
   * @return {Promise.<Assignment>}
   */
  createFolder(folder) {
    let tags = folder.tags || [];
    tags.push(
      {
        name: AssignmentTags.FOLDER,
        data: {
          is_folder: true
        }
      }
    );

    return this.create(
      {
        name: folder.name,
        tags: tags
      },
      0
    );
  }

  /**
   * Gets an assignment. This will retrieve any of the currently logged in users assignments, or any public assignment.
   *
   * @param assignmentId {string}
   * @returns {Promise.<Assignment>}
   */
  get(assignmentId) {
    const promise = this._httpService.authGet(this._uri(`/v1/assignments/${assignmentId}`))
      .then((data) => this._assignmentCodec.decode(data));

    promise.catch(() => this.$log.warn(`Error getting assignment: ${assignmentId}`));

    return promise;
  }

  getPublicFolder(folderId) {
    const promise = this._httpService.authGet(this._uri(`/v1/public-assignments/folder/${folderId}`))
    .then((data) => {
      return data.map((item) => this._assignmentCodec.decode(item));
    });

    promise.catch(() => this.$log.warn(`Error getting folder: ${folderId}`));

    return promise;
  }

  getNestedPublicFolder(folderId) {
    const promise = this._httpService.authGet(this._uri(`/v1/public-assignments/flat-folder/${folderId}`))
    .then((data) => {
      return data.map((item) => this._assignmentCodec.decode(item));
    });

    promise.catch(() => this.$log.warn(`Error getting folder: ${folderId}`));

    return promise;
  }

  /**
   * Updates an assignment
   *
   * @param assignment {Assignment}
   * @returns {Promise.<T>}
   */
  update(assignment) {
    return this._httpService.authPut(
      this._uri(`/v1/assignments/${assignment.id}`),
      this._assignmentCodec.encode(assignment)
    );
  }

  /**
   * Updates an assignment modified date
   *
   * @param assignmentId {String}
   * @returns {Promise.<T>}
   */
  updateAssignmentModifiedDate(assignmentId) {
    return this._httpService.authPut(
        this._uri(`/v1/assignments/${assignmentId}/modified`)
    );
  }


  /**
   * Duplicates an assignment
   *
   * @param assignmentId {string}
   * @param [name] {string}
   * @return {Assignment}
   */
  duplicate(assignmentId, name) {
    return this._httpService.authPost(
      this._uri(`/v1/assignments/${assignmentId}/duplicate`),
      {
        name: name
      })
      .then((data) => {
        const result = this._assignmentCodec.decode(data);
        this._analyticsService.assignmentCreated(result.id);
        return result;
      });
  }

  duplicateKeepTags(assignment) {
    let encodedAssignment = this._assignmentCodec.encode(assignment);

    return this._httpService.authPost(
      this._uri(`/v1/assignments/${encodedAssignment.id}/duplicate?keepTags=true`),
      {
        name: encodedAssignment.name,
        tags: encodedAssignment.tags
      })
      .then((data) => {
        const result = this._assignmentCodec.decode(data);
        this._analyticsService.assignmentCreated(result.id);
        return result;
      });
  }

  /**
   * Creates a new question in the given assignment
   *
   * @param assignmentId {string}
   * @param [beforeQuestionId] {string}
   * @returns {Promise.<AssignmentQuestion>}
   */
  addQuestion(assignmentId, beforeQuestionId) {
    return this._httpService
      .authPost(
        this._uri(`/v1/assignments/${assignmentId}/questions`),
        {
          before_question_id: beforeQuestionId
        }
      )
      .then((question) => {
        return this._assignmentCodec.decodeQuestion(question);
      });
  }

  /**
   * Removes the given question from the assignment
   *
   * @param assignmentId {string}
   * @param questionId {string}
   * @returns {Promise.<T>}
   */
  removeQuestion(assignmentId, questionId) {
    return this._httpService.authDelete(
      this._uri(`/v1/assignments/${assignmentId}/questions/${questionId}`)
    );
  }

  /**
   * Reorders a question within the assignment
   *
   * @param assignmentId {string}
   * @param question {AssignmentQuestion}
   * @param [beforeQuestionId] {string}
   * @returns {Promise.<T>}
     */
  updateQuestion(assignmentId, question, beforeQuestionId) {
    return this._httpService.authPut(
      this._uri(`/v1/assignments/${assignmentId}/questions/${question.id}`),
      {
        point_value: question.points,
        title: question.title,
        before_question_id: beforeQuestionId
      });
  }

  /**
   * Duplicates a question within the given assignment
   *
   * @param assignmentId {string}
   * @param questionId {string}
   * @param [beforeQuestionId] {string}
   * @returns {Promise.<T>}
   */
  duplicateQuestion(assignmentId, questionId, beforeQuestionId) {
    return this._httpService.authPost(
      this._uri(`/v1/assignments/${assignmentId}/questions/${questionId}/duplicate`),
      {
        before_question_id: beforeQuestionId
      });
  }

  /**
   * Duplicate questions across assignments
   * @param fromAssignmentId {string}
   * @param toAssignmentId {string}
   * @param questionIds {Array.<string>}
   * @param beforeQuestionId {string}
   * @returns {Promise.<T>}
   */
  duplicateQuestionsAcrossAssigments(fromAssignmentId, toAssignmentId, questionIds, beforeQuestionId) {
    return this._httpService.authPost(
      this._uri(`/v1/assignments/${fromAssignmentId}/questions/copy`),
      {
        to_assignment_id: toAssignmentId,
        question_ids: questionIds,
        before_question_id: beforeQuestionId
      });
  }

  /**
   * Associated a roster with an assignment
   *
   * @param assignmentId {string}
   * @param rosterId {string}
   * @param [showStudentScores] {boolean}
   * @param [lockStudentWork] {boolean}
   * @param [hideStudentWork] {boolean}
   * @returns {Promise.<ClassCode>}
   */
  addOrUpdateRoster(assignmentId, rosterId, showStudentScores = false, lockStudentWork = false, hideStudentWork = false, allowPdf = true) {
    return this._httpService
      .authPut(
        this._uri(`/v1/assignments/${assignmentId}/rosters/${rosterId}`),
        {
          show_student_scores: showStudentScores,
          lock_student_work: lockStudentWork,
          hide_student_work: hideStudentWork,
          allow_pdf: allowPdf
        })
      .then((result) => {
        return this._classCodeCodec.decode(result.class_code);
      });
  }

  /**
   * Disassociated a roster from an assignment
   *
   * @param assignmentId {string}
   * @param rosterId {string}
   * @returns {Promise.<T>}
   */
  removeRoster(assignmentId, rosterId) {
    return this._httpService.authDelete(
      this._uri(`/v1/assignments/${assignmentId}/rosters/${rosterId}`)
    );
  }

  /**
   * Gets assignments that are associated with a roster
   *
   * @param rosterId {string}
   * @returns {Promise.<Assignment[]>}
   */
  getRosterAssignments(rosterId) {
    return this._httpService.authGet(this._uri(`/v1/rosters/${rosterId}/assignments`))
        .then((response) => {
          return response.assignments.map((assignment) => {
            if (assignment.class_code) {
              const classCode = this._classCodeCodec.decode(assignment.class_code);
              assignment.class_code = classCode;
            }
            return this._assignmentCodec.decode(assignment);
          });
        });
  }

  /**
   * Sends emails to the each of the provided email addresses containing a link to view the assignment
   *
   * @param assignmentId {string}
   * @param emails {Array.<string>}
   * @returns {Promise.<T>}
   */
  shareOverEmail(assignmentId, emails) {
    return this._httpService.authPost(
      this._uri(`/v1/assignments/${assignmentId}/email`),
      {
        emails: emails
      });
  }

  /**
   * Sends emails to email addresses, and share messages to emails that have Classkick account
   *
   * @param assignmentId
   * @param emails
   * @returns {Promise.<T>}
   */
  shareAssignment(assignmentId, emails) {
    return this._httpService.authPost(
      this._uri(`/v1/assignments/${assignmentId}/share`),
      {
        emails: emails
      });
  }

  /**
   * Creates a URL from a path
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }

  /**
   * @param organizationId {string}
   * @return {Promise.<{Array.<Assignments>}>}
   */
  getPublicAssignmentsByOrganizationId(organizationId) {
    return this._httpService.authGet(`${this._environment.serverUrlBase}/v1/public-assignments/organization/${organizationId}`)
      .then((response) => {
        return response.assignments.map((a) => this._libraryAssignmentCodec.decode(a));
      });
  }

  /**
   * @param districtId {string}
   * @return {Promise.<{Array.<Assignments>}>}
   */
  getPublicAssignmentsByDistrictId(districtId) {
    return this._httpService.authGet(`${this._environment.serverUrlBase}/v1/public-assignments/district/${districtId}`)
      .then((response) => {
        return response.assignments.map((a) => this._libraryAssignmentCodec.decode(a));
      });
  }

  /**
   * @param assignmentId {string}
   * @return {Promise.<{Object.<T>}>}
   */
  generateStudentPreview(assignment) {
    return this._httpService.authPost(`${this._environment.serverUrlBase}/v1/assignments/${assignment.id}/student-preview`)
      .then((response) => {
        return {
          classCode: this._classCodeCodec.decode(response.class_code),
          roster: this._rosterCodec.decode(response.roster),
          user: this._userCodec.decode(response.user),
          assignmentWork: this._assignmentWorkCodec.decode(assignment, response.assignment_work),
          token: response.token
        };
      });
  }
}
