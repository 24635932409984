
'use strict';

import CkRedirect from '../../model/domain/ck-redirect';
import { Locations } from '../../services/mixpanel/mixpanel.service';

export class LoginSources {

  static get LOGIN() {
   return 'Login';
  }

  static get SIGN_UP() {
    return 'Sign up';
  }

}

export default class LoginController {
  constructor ($q, $log, $timeout, $state, $stateParams, $scope, $mdDialog, $document, ClassCodeService, BreadcrumbService,
               AuthService, ToolbarService, CacheService, RosterService, StorageService, AnalyticsService) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$scope = $scope;
    this.$mdDialog = $mdDialog;
    this.$document = $document;

    /** @type {ClassCodeService} */
    this._classCodeService = ClassCodeService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._isLoading = false;
    this._nameTimeout = null;
    this._focusClassCode = true;
    this._focusName = false;
    this._errorMessage = '';

    this._allowNewMembers = false;
    this._classCode = $stateParams.classCode;
    this._checkingClassCode = '';
    this._name = $stateParams.name;

    this._classCodePattern = '^[a-zA-Z\\d\\s]+$';
    this._classCodeRegex = new RegExp(this._classCodePattern);

    this._classCodeIsValid = false;
    this._studentNames = new Set();
    this._nameIsValid = false;
    this._hasSentAnalyticsClassCode = false;
    this._isCleverClassCode = false;

    $timeout(() => this.classCodeUpdated());
  }

  /**
   * @return {boolean}
   */
  get isLoading() {
    return this._isLoading;
  }

  /**
   * @return {boolean}
   */
  get focusClassCode() {
    return this._focusClassCode;
  }

  /**
   * @param value {boolean}
   */
  set focusClassCode(value) {
    this._focusClassCode = value;
  }

  /**
   * @return {boolean}
   */
  get focusName() {
    return this._focusName;
  }

  /**
   * @param value {boolean}
   */
  set focusName(value) {
    this._focusName = value;
  }

  /**
   * @return {string}
   */
  get errorMessage() {
    return this._errorMessage;
  }

  /**
   * @param value {string}
   */
  set errorMessage(value) {
    this._errorMessage = value;
  }

  /**
   * @return {string}
   */
  get anonStudentLoginButtonMessage() {
    return this.canResume ? 'Continue' : 'Go';
  }

  // Called whenever the classCode text field is modified
  classCodeUpdated() {
    if (!this._hasSentAnalyticsClassCode) {
      this._analyticsService.sendEvent({
        eventTag: 'login:classCode_typed'
      });
      this._hasSentAnalyticsClassCode = true;
    }
    if (this.$scope.anonStudentLoginForm) {
      this.$scope.anonStudentLoginForm.classCode.$touched = true;
      this.$scope.anonStudentLoginForm.name.$touched = true;
      this.$scope.anonStudentLoginForm.classCode.$setValidity('bad-ccode', true);
      this.$scope.anonStudentLoginForm.classCode.$setValidity('pattern', true);
    }

    this._studentNames = new Set();
    this.nameUpdated();
    this._classCodeIsValid = false;

    let classCodeMaxLength = this._classCode.indexOf(' ') > -1 ? 7 : 6;
    this._classCode = this._classCode.toUpperCase().slice(0, classCodeMaxLength);

    if (!this._classCode) {
      return;
    }
    else if (!this._classCodeRegex.test(this._classCode)) {
      if (this.$scope.anonStudentLoginForm) {
        this.$scope.anonStudentLoginForm.classCode.$setValidity('pattern', false);
      }
      return;
    }

    let cleanedClassCode = this._removeSpaces(this._classCode);

    if (cleanedClassCode.length === 6) {
      if (this._checkingClassCode !== cleanedClassCode) {
        this._isLoading = true;
        this._checkingClassCode = cleanedClassCode;
        this._loadClassCodeData(cleanedClassCode);
      }
    } else {
      this._checkingClassCode = '';
    }
  }

  /**
   * @param code {string}
   */
  _removeSpaces(code) {
    return code.replace(/[\s]/g, '');
  }

  /**
   * Verifies that a classCode exists, and then loads the students for that classCode
   * @param tempClassCode
   * @private
   */
  _loadClassCodeData(tempClassCode) {
    this._classCodeService.getUsernames(tempClassCode)
      .then((classCodeData) => {
        this._isLoading = false;
        if (this._removeSpaces(this._classCode) !== tempClassCode) {
          throw new Error('class Code was modified');
        }

        this.$scope.anonStudentLoginForm.classCode.$setValidity('bad-ccode', true);
        this._allowNewMembers = classCodeData.allowNewMembers;
        this._classCodeIsValid = true;
        this._focusName = true;
        this._isPro = classCodeData.pro;
        this._hideStudentWork = classCodeData.hideStudentWork;

        this._studentNames = new Set(classCodeData.names.map((name) => name.toLowerCase()));

        this.nameUpdated();
      })
      .catch((err) => {
        if (this.$scope.anonStudentLoginForm) {
          if (err.message === 'clever-import created'){
            this._analyticsService.sendEvent({
              eventTag: 'login:classcode_warningCleverRoster'
            });
            this._isCleverClassCode = true;
            this.$scope.anonStudentLoginForm.classCode.$setValidity('clever-locked', false);
          } else {
            this._analyticsService.sendEvent({
              eventTag: 'login:classcode_warningRoster'
            });
            this.$scope.anonStudentLoginForm.classCode.$setValidity('bad-ccode', false);
          }
        }
        this.$log.debug('_loadClassCodeData error', err);
        this._isLoading = false;
      });
  }



  /**
   * Called whenever the name text field is modified
   */
  nameUpdated() {
    if (this.$scope.anonStudentLoginForm) {
      this.$scope.anonStudentLoginForm.classCode.$touched = true;
      this.$scope.anonStudentLoginForm.name.$touched = true;
    }

    this.$timeout.cancel(this._nameTimeout);
    this._nameIsValid = false;
    this._nameIsPresent = false;

    if (this._name) {

      this._nameIsValid = this._studentNames.has(this._name.toLowerCase());
      this._nameIsPresent = this._nameIsValid || this._allowNewMembers;

      if (!this._nameIsPresent) {
        this._nameTimeout = this.$timeout(() => {
          this._analyticsService.sendEvent({
            eventTag: 'login:classcode_warningRoster'
          });
          this.$scope.anonStudentLoginForm.name.$setValidity('no-student', false);
        }, 500);
      }
      else {
        this.$scope.anonStudentLoginForm.name.$setValidity('no-student', true);
      }
    }
  }

  login() {
    if (!this.canStartNew && !this.canResume) {
      return;
    }

    this._toolbarService.reset();
    this._cacheService.reset();

    this.$state.go('root.anon-student-assignment-work', {
      classCode: this._removeSpaces(this._classCode),
      name: this._name,
      source: this.canStartNew ? LoginSources.SIGN_UP : LoginSources.LOGIN,
      question: 1
    });
  }

  /**
   * no classCode OR
   * classCode not 6 characters OR
   * classCode not valid
   * @returns {boolean}
   */
  get needClassCode() {
    return !this._classCode || this._removeSpaces(this._classCode).length !== 6 || !this._classCodeIsValid;
  }

  /**
   * valid classCode AND
   * no name OR
   * name is invalid && disallow new students
   * @returns {boolean}
   */
  get needName() {
    return !this.needClassCode && (!this._name || (!this._nameIsValid && !this._allowNewMembers));
  }

  /**
   * valid  class code AND
   * valid name
   * @return {boolean}
   */
  get canStart() {
    return !this.needClassCode && !this.needName;
  }

  /**
   * valid classCode AND
   * valid name AND
   * returning user
   * @returns {boolean}
   */
  get canResume() {
    return this.canStart && this._nameIsValid;
  }

  /**
   * valid classCode AND
   * some name AND
   * invalid name AND
   * allow new students
   * @returns {boolean}
   */
  get canStartNew() {
    return !this.canResume && !this.needClassCode && !this.needName;
  }

  get classCodeMinLength() {
    if (!this._name) {
      return 0;
    }
    return 6;
  }

  /**
   * @returns {string}
   */
  get name() {
    return this._name;
  }

  /**
   * @param value {string}
   */
  set name(value) {
    this._name = value;
  }

  /**
   * @returns {string}
   */
  get classCode() {
    return this._classCode;
  }

  /**
   * @param value {string}
   */
  set classCode(value) {
    this._classCode = value;
  }

  /**
   * @returns {string}
   */
  get classCodePattern() {
    return this._classCodePattern;
  }

  goToAccountLogin() {
    this._analyticsService.sendEvent({
      eventTag: 'login:login_clicked'
    });
    if (this.classCodeValid || this._isCleverClassCode) {
      this._storageService.ckRedirect = new CkRedirect('root.account.home', {
        classCode: this._removeSpaces(this.classCode)
      });
    }

    this._breadcrumbService.go('root.account-login', {
      classCode: this._removeSpaces(this._classCode)
    });
  }

  goToCreateAccountChoice() {
    this._analyticsService.sendEvent({
      eventTag: 'login:signup_clicked'
    });
    this._breadcrumbService.go('root.signup-choice');
  }

  /**
   * @return {string}
   */
  get whatIsAClassCodeModalId() {
    return 'what-is-a-class-code';
  }

  showClassCodeModal() {
    this._analyticsService.classCodeDefinitionClicked(Locations.LOGIN);
    this.$mdDialog.show({
      contentElement: `#${this.whatIsAClassCodeModalId}`,
      parent: angular.element(this.$document[0].body),
      onRemoving: () => {
        this._focusClassCode = true;
      }
    });
  }

  get classCodeValid() {
    return this._classCodeIsValid;
  }

  get showPro() {
    return this._classCodeIsValid && this._isPro === true && !this._hideStudentWork;
  }

  get hideStudentWork() {
    return this._hideStudentWork;
  }

  get title() {
    if (this.showPro) {
      return 'Classkick Pro';
    }
    return 'Start Working';
  }

  goToCreateStudentAccount() {
    this._analyticsService.sendEvent({
      eventTag: 'login:signup_clicked'
    });
    let classCode = this._removeSpaces(this.classCode);

    this._storageService.ckRedirect = new CkRedirect('root.account.home', {
      classCode
    });

    this._breadcrumbService.go('root.signup-student', {
      classCode
    });
  }

  goBack() {
    this.classCode = '';
    this.classCodeUpdated();
  }

}
