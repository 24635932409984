
'use strict';

import { PageTraverser } from '../../components/assignment-question/assignment-question.directive';
import { HelpInboxType } from '../../components/help-inbox/help-inbox.directive';
import StudentSessionData from '../../model/domain/student-session-data';
import { FeedbackMenuController } from '../../components/feedback-menu/feedback-menu.controller';

/**
 * Allows students with a log-in to work on an assignment
 */
export default class AnonStudentAssignmentWorkController {
  /**
   * @ngInject
   */
  constructor($q, $scope, $state, $stateParams, $mdDialog, $mdPanel, BreadcrumbService, AuthService, StudentCacheService,
              StudentAssignmentService, DatadogRumService) {

    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {StudentAssignmentService} */
    this._studentAssignmentService = StudentAssignmentService;
    /** @type {DatadogRumService} */
    this._datadogRumService = DatadogRumService;

    this._feedbackMenu = FeedbackMenuController.show;

    this._questionNumber = parseInt(this.$stateParams.question);

    this._model = this._studentAssignmentService.getStudentAssignmentWork(
      HelpInboxType.STUDENT,
      new PageTraverser(
        (controller) => {
          return {
            question: controller.prevQuestionIndex + 1
          };
        },
        (controller) => {
          return {
            question: controller.nextQuestionIndex + 1
          };
        },
        (controller) => {
          return {
            question: controller.targetQuestionIndex + 1
          };
        }
      )
    );

    this._loading = false;

    // Clean up after ourselves
    $scope.$on('$destroy', () => this._model.destroy());

    this.init().then(() => {
      this._loading = false;
    });
  }

  /**
   * @return {StudentAssignmentWork}
   */
  get model() {
    return this._model;
  }

  get formattedClassCode() {
    return `${this.$stateParams.classCode.substr(0, 3)} ${this.$stateParams.classCode.substr(3)}`;
  }

  get username() {
    return this.$stateParams.name;
  }

  /**
   * @return {boolean}
   */
  get loading() {
    return this._loading;
  }

  init() {
    return this._authService.authAnonStudent(this.$stateParams.classCode, this.$stateParams.name)
      .then(({classCode}) => {
        this._datadogRumService.addUserAndClassInfo(
          classCode.assignmentId,
          classCode.rosterId
        );
        return this._studentCacheService.getOrCreateWorkForSelf(
          classCode.assignmentId,
          classCode.rosterId
        );
      })
      .then((assignmentWork) => {
        var questionIndex = this._questionNumber - 1;
        var questionId = assignmentWork.questionIdForIndex(questionIndex);

        if (!questionId) {
          this._questionNumber = 1;
          questionIndex = this._questionNumber - 1;
          questionId = assignmentWork.questionIdForIndex(questionIndex);
        }

        return this._model.initCoreData(assignmentWork, questionId);
      })
      .then((assignmentWork) => {
        this._loading = true; // eager load the first assignment page, wait for .init to finish
        let questionIndex = this._questionNumber - 1;
        let questionId = assignmentWork.questionIdForIndex(questionIndex);

        return this._model.initSubscriptionData(assignmentWork, questionId);
      })
      .catch((error) => {
        if (error.message === StudentSessionData.singleDeviceLoginError) {
          StudentSessionData.launchSingleDeviceErrorDialog(this.$mdDialog, false)
            .then(() => {
              this.logOut();
            })
            .catch(() => {
              this.$state.reload();
            });
        }
        else if (error.message === StudentSessionData.assignmentHiddenError) {
          StudentSessionData.launchAssignmentHiddenErrorDialog(this.$mdDialog, false)
            .then(() => {
              this.logOut();
            })
            .catch(() => {
              this.$state.reload();
            });
        }
        this._error = error;
      });
  }

  /**
   * Reloads this controller by navigating to the same state and passing reload as true
   */
  reload() {
    this.$state.go(
      'root.anon-student-assignment-work',
      {
        classCode: this.$stateParams.classCode,
        name: this.$stateParams.name,
        question: this._questionNumber
      },
      {
        reload: true
      }
    );
  }

  /**
   * @param questionId {string}
   */
  goToQuestion(questionId) {
    this._breadcrumbService.go(
      'root.anon-student-assignment-work',
      {
        classCode: this.$stateParams.classCode,
        name: this.$stateParams.name,
        question: this.model.subscriptionData.assignmentWork.indexForQuestionId(questionId) + 1
      },
      true
    );
  }

  /**
   * Navigates to overview of all slides
   */
  goToOverview() {
    this._breadcrumbService.go(
      'root.anon-student-assignment-overview',
      {
        classCode: this.$stateParams.classCode,
        name: this.$stateParams.name
      },
      true
    );
  }

  /**
   * @param request {HelpRequest}
   */
  goToFeedback(request) {
    this._breadcrumbService.go('^.anon-student-assignment-feedback', {
      classCode: this.$stateParams.classCode,
      name: this.$stateParams.name,
      request: request
    });
  }

  /**
   * Logs the user out and sends the user to the account login page
   */
  logOut() {
    this._authService.signOut();
  }

  /**
   * @return {boolean}
   */
  get isStudentAuthed() {
    return this._authService.isLoggedIn;
  }

  get hasExportStudentWork() {
    // This is technically not the same as having the "export student work" feature but it's a reasonable proxy and
    // obviates the need for making another call to web services
    return this.isStudentAuthed && this._authService.authData.isPro;
  }

  openFeedbackMenu(ev) {
    this._feedbackMenu(this.$mdPanel, this.$q, ev, this.model.subscriptionData.questionFeedbackList, this.model.subscriptionData.assignmentWork)
      .then(({questionId}) => {
        this.goToQuestion(questionId);
      });
  }

}
