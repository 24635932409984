export default class Session {

  /**
   * @param id {string}
   * @param name {string}
   *
   */
  constructor(id, name) {
    this._id = id;
    this._name = name;
  }

  get id (){
    return this._id;
  }
  get name (){
    return this._name;
  }
}
