
'use strict';

/**
 * Executes the contained expression when the target element is loaded
 */
export default function NgReadyDirective($parse) {
  'ngInject';

  return {
    priority: -100,
    restrict: 'A',
    link: function( $scope, elem, attrs ) {

      elem.ready(() => {
        var func = $parse(attrs.ngReady);
        func($scope);
      });
    }
  };
}
