'use strict';

import VirtualCollection from '../../model/domain/virtual-collection';
import Validation from '../../model/util/validation';
import OrganizationService from '../../services/organization/organization.service';
import SelectPeersDialogTemplate from './select-peers-dialog.html';

export default class SelectPeersDialogController {

  constructor($mdDialog, $log, $mdToast, AnalyticsService, organizationId, emailConfig, clickedFrom, OrderService, OrganizationService) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.$log = $log;
    this.$mdToast = $mdToast;
    this._emailConfig = emailConfig;
    this._organizationId = organizationId;
    this._clickedFrom = clickedFrom;
    this._peers = [];
    this.isValidEmail = Validation.isValidEmail;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;

    this._state = this.showDialog;
    this._isLoading = true;
    this._selectAllCheckbox = false;
    this._virtualPeers = undefined;
    this._selectedListObj = {};
    this._checkedCount = 0;
    this._query = '';
    this._toastMessage;
    this._formError = false;
    this._selectionError = false;

    this._init();
  }

  _init() {
    this._organizationService.getMdrPeers(this._organizationId)
      .then((peers) => {
        if (peers.length) {
          this._peers = peers;
          this._virtualPeers = new VirtualCollection(this._peers, 50);

          this._virtualPeers.loadedCollection.forEach((peer) => {
            if (this._selectedListObj[peer.email]) {
              this._selectedListObj[peer.email] = true;
            } else {
              this._selectedListObj[peer.email] = false;
            }
          });

          this._isLoading = false;
        }
        else {
          this.state = this.showError;
        }
      })
      .catch((error) => {
        this.$log.error(error);
        this.state = this.showError;
      });
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @param val {string}
   */
  set state(val) {
    this._state = val;
  }

  get showDialog() {
    return 'show-dialog';
  }

  get showError() {
    return 'error';
  }

  isLoading() {
    return this._isLoading;
  }

  get virtualPeers() {
    return this._virtualPeers;
  }

  get selectAllCheckbox() {
    return this._selectAllCheckbox;
  }

  set selectAllCheckbox(val) {
    this._selectAllCheckbox = val;
  }

  get query() {
    return this._query;
  }

  set query(val) {
    this._query = val;
    val ? this.search() : this.clearSearch();
  }

  get formError() {
    return this._formError;
  }

  set formError(val) {
    this._formError = val;
  }

  get selectionError() {
    return this._selectionError;
  }

  set selectionError(val) {
    this._selectionError = val;
  }

  selectAllPeers() {
    this._selectionError = false;

    if (!this._query) {
      if (this._checkedCount === this._peers.length) {
        this._selectAllCheckbox = false;
        this._checkedCount = 0;
      }
      else {
        this._selectAllCheckbox = true;
        this._checkedCount = this._virtualPeers.getLength();
      }
    }
    else if (this._query) {
      let checkedCountForThisQuery = this.getCheckedCountForQuery();
      if (checkedCountForThisQuery === this._virtualPeers.getLength()) {
        this._selectAllCheckbox = false;
        this._checkedCount = this._checkedCount - checkedCountForThisQuery;
      }
      else {
        this._selectAllCheckbox = true;
        this._checkedCount = (this._checkedCount - checkedCountForThisQuery) + this._virtualPeers.getLength();
      }
    }

    this.checkOrUncheckAll(this._selectAllCheckbox);
  }

  getCheckedCountForQuery() {
    return this._virtualPeers._fullCollection.reduce((count, peer) => {
      if (this._selectedListObj[peer.email]) {
        count++;
      }
      return count;
    }, 0);
  }

  checkOrUncheckAll(checkState) {
    this._virtualPeers._fullCollection.forEach((peer) => {
      this._selectedListObj[peer.email] = checkState;
    });
  }

  selectAllLabel() {
    let labelText = 'select all';
    let fullCount = this._virtualPeers ? this._virtualPeers.getLength() : '';

    if (this._checkedCount === 0) {
      labelText = `select all (${fullCount})`;
    }
    else if (this._checkedCount > 0) {
      labelText = `selected (${this._checkedCount})`;
    }
    return labelText;
  }

  shouldDashmarkAppear() {
    return (this._checkedCount > 0 && this._checkedCount < this._peers.length);
  }

  selectSinglePeer(peer) {
    this._selectionError = false;
    this._selectedListObj[peer.email] ? this._checkedCount++ : this._checkedCount--;

    if (this._checkedCount === this._peers.length) {
      this._selectAllCheckbox = true;
    }

    if (this._checkedCount === 0) {
      this._selectAllCheckbox = false;
    }
  }

  search() {
    this.formError = false;
    this._virtualPeers.fullCollection = this.filterEmails();
  }

  clearSearch() {
    this.formError = false;
    this._virtualPeers.fullCollection = this._peers;
  }

  addCustomEmail(event) {
    if (event.key === 'Enter') {
      const isValidEmail = this.isValidEmail(this._query);

      if (isValidEmail && !this.peerExists(this._query)) {
        let newPeer = this.createNewPeer(this._query);
        this._peers.push(newPeer);
        this._virtualPeers.fullCollection = this.filterEmails();
        this._selectedListObj[newPeer.email] = true;
        this.selectSinglePeer(newPeer);
      }
      else if (!isValidEmail) {
        return this.formError = true;
      }
    }
  }

  createNewPeer(email) {
    return { email, custom: true };
  }

  filterEmails() {
    return this._peers.filter((peer) => (peer.email.toLowerCase().indexOf(this._query.toLowerCase()) > -1));
  }

  peerExists(email) {
    return !!this._peers.find((peer) => peer.email.toLowerCase() === email.toLowerCase());
  }

  resultCount() {
    return this._virtualPeers.getLength();
  }

  recipientList() {
    let customEmails = this.getAllCustomEmails();

    return Object.keys(this._selectedListObj).reduce((list, email) => {
      if (this._selectedListObj[email]) {
        customEmails[email] ?
        this._analyticsService.customEmailReferred()
        : this._analyticsService.mdrEmailReferred();
        list.push(email.trim());
      }
      return list;
    }, []);
  }

  getAllCustomEmails() {
    return this._peers.reduce((obj, peer) => {
      if (peer.custom) {
        obj[peer.email] = true;
      }
      return obj;
    }, {});
  }

  submit() {
    if (!this._checkedCount) {
      return this._selectionError = true;
    }
    this._analyticsService.selectPeersDialogReferralSent(this._clickedFrom);

    this._orderService
      .share(
        this._emailConfig.orderId,
        this._emailConfig.from,
        this.recipientList(),
        this._emailConfig.internalEmail,
        this._emailConfig.subject,
        this._emailConfig.body,
        this._emailConfig.fromName,
        {
          user_id: this._emailConfig.user_id,
          event_tag: `${this._clickedFrom}:selectPeersDialog_sent`
        }
      )
      .then((data) => {
        if (data.success) {
          // all emails sent
          this.$mdToast.show(this.formatToastConfig(this._checkedCount));
          this.$mdDialog.cancel();
        }
        else if (!data.success && this.failureCount(data.message) < this._checkedCount) {
          // some emails sent
          this.$mdToast.show(this.formatToastConfig(this._checkedCount - this.failureCount(data.message)));
          this.$mdDialog.cancel();
        }
        else if (data) {
          // no emails sent
          this.$mdToast.show(this.formatToastConfig(0));
          this.$mdDialog.cancel();
        }
      })
      .catch((error) => {
        this.$log.error(error);
        this._state = this.showError;
      });
  }

  formatToastConfig(successCount) {
    this._toastMessage = successCount ? `You Referred ${successCount} Colleague${this.pluralizer(successCount)}` : `The selected colleague${this.pluralizer(this._checkedCount)} ha${this.havePluralizer(this._checkedCount)} already been referred. Thank you!`;
    return this.$mdToast.simple(this._toastMessage).action(' ').actionKey('x').position('top right').toastClass('referral-toast').hideDelay(4000);
  }

  failureCount(message) {
    let startingIndex = message.search(/\bfailed to send to\b/) + 18;
    return message.slice(startingIndex, -1).split(',').length;
  }

  pluralizer(itemCount) {
    return itemCount === 1 ? '' : 's';
  }

  havePluralizer(itemCount) {
    return itemCount === 1 ? 's' : 've';
  }

  /**
   * show the dialog
   * @param  {$mdDialog}  $mdDialog
   * @param  {organizationId} {string}
   * @param  {emailConfig} Object
   * @return {Promise}
   */
  static show($mdDialog, organizationId, emailConfig, clickedFrom) {

    let config = {
      controller: SelectPeersDialogController,
      template: SelectPeersDialogTemplate,
      controllerAs: 'ctrl',
      autoWrap: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
      locals: {
        organizationId: organizationId,
        emailConfig: emailConfig,
        clickedFrom
      }
    };

    return $mdDialog.show(config);
  }
  /**
   * close the dialog. rejects the promise returned from the show method
   */
  cancel() {
    this._analyticsService.selectPeersDialogDismissed(this._clickedFrom);
    this.$mdDialog.cancel();
  }
}
