
'use strict';

import { HelpInboxType } from '../../components/help-inbox/help-inbox.directive';
import StudentSessionData from '../../model/domain/student-session-data';
import { FeedbackMenuController } from '../../components/feedback-menu/feedback-menu.controller';

/**
 * Allows students with a log-in to view all of the questions on an assignment
 */
export default class StudentAccountAssignmentOverviewController {
  /**
   * @ngInject
   */
  constructor($q, $scope, $state, $stateParams, $mdDialog, $mdPanel, CacheService, BreadcrumbService, StudentCacheService,
              StudentAssignmentService) {

    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {StudentAssignmentService} */
    this._studentAssignmentService = StudentAssignmentService;

    this._error = undefined;
    this._model = this._studentAssignmentService.getStudentAssignmentOverview(HelpInboxType.STUDENT_ACCOUNT);

    this._feedbackMenu = FeedbackMenuController.show;

    // Clean up after ourselves
    $scope.$on('$destroy', () => this._model.destroy());

    this.init();
  }

  /**
   * @return {Error}
   */
  get error() {
    return this._error;
  }

  /**
   * @return {StudentAssignmentOverview}
   */
  get model() {
    return this._model;
  }

  init() {
    this._studentCacheService.getUserWork(this.$stateParams.assignmentWorkId, false)
      .then((assignmentWork) => {
        return this._model.init(assignmentWork);
      })
      .catch((error) => {
        if (error.message === StudentSessionData.singleDeviceLoginError) {
          StudentSessionData.launchSingleDeviceErrorDialog(this.$mdDialog, true)
            .then(() => {
              this.goBack();
            })
            .catch(() => {
              this.reload();
            });
        }
        else if (error.message === StudentSessionData.assignmentHiddenError) {
          StudentSessionData.launchAssignmentHiddenErrorDialog(this.$mdDialog, true)
            .then(() => {
              this.goBack();
            })
            .catch(() => {
              this.$state.reload();
            });
        }
        this._error = error;
      });
  }

  /**
   * Reloads this controller by navigating to the same state and passing reload as true
   */
  reload() {
    this.$state.go(
      'root.account.student-assignment-overview',
      {
        assignmentWorkId: this.$stateParams.assignmentWorkId
      },
      {
        reload: true
      }
    );
  }

  /**
   * @param questionId {string}
   */
  goToQuestion(questionId) {
    this._breadcrumbService.go(
      'root.account.student-assignment-work',
      {
        assignmentWorkId: this.$stateParams.assignmentWorkId,
        questionId
      },
      true
    );
  }

  /**
   * @param request {HelpRequest}
   */
  goToFeedback(request) {
    this._breadcrumbService.go('^.student-assignment-feedback', {
      assignmentWorkId: this.$stateParams.assignmentWorkId,
      request: request
    });
  }

  goBack() {
    this._cacheService.clearUserStatusDetails();
    this._breadcrumbService.goBack('root.account.nav.student-assignments-list');
  }

  get name() {
    let data = this.model && this.model.data;
    let user = data && data.user;
    return user && user.name;
  }

  openFeedbackMenu(ev) {
    this._feedbackMenu(this.$mdPanel, this.$q, ev, this.model.data.questionFeedbackList, this.model.data.assignmentWork)
      .then(({questionId}) => {
        this.goToQuestion(questionId);
      });
  }
}
