'use strict';

export default class IncentiveService {

    constructor(environment, HttpService) {
        'ngInject';

        this._environment = environment;
        /** @type { HttpService } */
        this._httpService = HttpService;
    }

    /**
     * Save user incentive
     *
     * @param incentiveId
     * @param postBody
     * @returns {Promise<T>}
     */
    save(incentiveId, postBody) {
        return this._httpService
            .authPost(
                this._uri(`/v1/incentives/${incentiveId}`),
                postBody
            );
    }

    /**
     * Returns a string that specifies a uri given a path.
     *
     * @param path {string}
     * @returns {string}
     * @private
     */
    _uri(path) {
        return `${this._environment.serverUrlBase}${path}`;
    }
}
