
'use strict';

import AssignmentWorkCodec from '../../model/codec/assignment-work-codec';
import AssignmentCodec from '../../model/codec/assignment-codec';
import WorkQuestionSet from '../../model/domain/work-question-set';
import AssignmentWorkQuestion from '../../model/domain/assignment-work-question';
import ClassCodeCodec from '../../model/codec/class-code-codec';
import AssignmentRosterCodec from '../../model/codec/assignment-roster-codec';

export default class AssignmentWorkService {

  constructor(environment, $q, AuthService, HttpService, FirebaseService, NotificationService) {
    'ngInject';

    this._environment = environment;

    /** @type {$q} */
    this.$q = $q;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;

    this._assignmentCodec = new AssignmentCodec(this._firebaseService);
    this._assignmentWorkCodec = new AssignmentWorkCodec(this._firebaseService);
    this._classCodeCodec = new ClassCodeCodec();
    this._assignmentRosterCodec = new AssignmentRosterCodec();
  }

  /**
   * Gets or creates an assignment work for the current user
   *
   * @param assignmentId {string}
   * @param rosterId {string}
   * @returns {Promise.<AssignmentWork>}
   */
  getOrCreateForSelf(assignmentId, rosterId) {
    return this._httpService.authPost(
      `${this._environment.serverUrlBase}/v1/assignment-works`,
      {
        assignment_id: assignmentId,
        roster_id: rosterId
      }
    ).then((httpAssignmentWork) => {
      return this.$q.all({
        assignment: this.getAssignment(httpAssignmentWork.id),
        assignmentWork: httpAssignmentWork
      });
    }).then((result) => {
      return this._assignmentWorkCodec.decode(result.assignment, result.assignmentWork);
    });
  }

  /**
   * Gets or creates an assignment work for the current user
   *
   * @param assignment {Assignment}
   * @param rosterId {string}
   * @returns {Promise.<AssignmentWork>}
   */
  getOrCreateForSelfWithAssignment(assignment, rosterId) {
    return this._httpService.authPost(
      `${this._environment.serverUrlBase}/v1/assignment-works`,
      {
        assignment_id: assignment.id,
        roster_id: rosterId
      }
    ).then((result) => {
      return this._assignmentWorkCodec.decode(assignment, result);
    });
  }

  /**
   * Gets or creates an assignment work for the specified user
   *
   * @param assignment {Assignment}
   * @param rosterId {string}
   * @param userId {string}
   * @returns {Promise.<AssignmentWork>}
   */
  getOrCreateForOther(assignment, rosterId, userId) {
    return this._httpService.authPost(
      `${this._environment.serverUrlBase}/v1/assignments/${assignment.id}/assignment-works`,
      {
        roster_id: rosterId,
        user_id: userId
      }
    ).then((httpAssignmentWork) => this._assignmentWorkCodec.decode(assignment, httpAssignmentWork));
  }

  /**
   * Gets an assignment work by id
   * Authorization: Owner of assignment work
   *
   * @param assignment {Assignment}
   * @param assignmentWorkId {string}
   * @returns {Promise.<*>}
   */
  get(assignment, assignmentWorkId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/assignment-works/${assignmentWorkId}`)
      .then((httpAssignmentWork) => this._assignmentWorkCodec.decode(assignment, httpAssignmentWork));
  }

  /**
   * Gets an assignment work by roster and user id
   * Authorization: The roster owner, or any member of the roster
   *
   * @param assignment {Assignment}
   * @param rosterId {string}
   * @param userId {string}
   * @returns {Promise.<AssignmentWork>}
   */
  getForPeer(assignment, rosterId, userId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/rosters/${rosterId}/users/${userId}/assignments/${assignment.id}/assignment-work`)
      .then((httpAssignmentWork) => {
        return this._assignmentWorkCodec.decode(assignment, httpAssignmentWork);
      });
  }

  /**
   * @returns {Promise.<{ works: AssignmentWork[], assignments: Map<string, Assignment>, assignmentRosters: Map<string, assignmentRosters> }>}
   */
  getForCurrentUser() {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/users/${this._authService.authData.id}/assignment-works`)
      .then((result) => {
        const assignments = new Map(
          result.assignments
            .map((a) => [a.id, this._assignmentCodec.decode(a)])
        );
        const assignmentRosters = new Map(
          result.assignment_rosters
            .map((ar) => {
              let assignmentRoster = this._assignmentRosterCodec.decode(ar);
              return [
                assignmentRoster.assignmentId + assignmentRoster.rosterId,
                assignmentRoster
              ];
            })
        );
        const works = result.assignment_works
          .map((w) => {
            let work = this._assignmentWorkCodec.decode(assignments.get(w.assignment_id), w);
            work.assignmentRoster = assignmentRosters.get(w.assignment_id + work.rosterId);
            return work;
          });

        return {
          works,
          assignments,
          assignmentRosters
        };
      });
  }

  /**
   * @returns {Promise.<{ works: AssignmentWork[], assignments: Map<string, Assignment>, assignmentRosters: Map<string, assignmentRosters> }>}
   */
  getAllForStudent(studentId, rosterId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/users/${studentId}/${rosterId}/student-assignment-works`)
      .then((result) => {
        const assignments = new Map(
          result.assignments
            .map((a) => [a.id, this._assignmentCodec.decode(a)])
        );
        const assignmentRosters = new Map(
          result.assignment_rosters
            .map((ar) => {
              let assignmentRoster = this._assignmentRosterCodec.decode(ar);
              return [
                assignmentRoster.assignmentId + assignmentRoster.rosterId,
                assignmentRoster
              ];
            })
        );
        const works = result.assignment_works
        .map((w) => {
          let work = this._assignmentWorkCodec.decode(assignments.get(w.assignment_id), w);
          work.assignmentRoster = assignmentRosters.get(w.assignment_id + work.rosterId);
          return work;
        });

        return {
          works,
          assignments,
          assignmentRosters
        };
      });
  }

  /**
   * Deletes an assignment work by id
   * @param assignmentWorkId {string}
   * @returns {Promise.<T>}
   */
  delete(assignmentWorkId) {
    return this._httpService.authDelete(`${this._environment.serverUrlBase}/v1/assignment-works/${assignmentWorkId}`);
  }

  /**
   * Gets assignment work for specified assignment
   * Authorization: Owner of assignment
   *
   * @param assignment {Assignment}
   * @param assignmentWorkId {string}
   * @returns {Promise.<*>}
   */
  getForAssignment(assignment, assignmentWorkId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/assignments/${assignment.id}/assignment-works/${assignmentWorkId}`)
      .then((httpAssignmentWork) => this._assignmentWorkCodec.decode(assignment, httpAssignmentWork));
  }

  /**
   * Gets all assignment works for an assignment
   *
   * @param assignment {Assignment}
   * @returns {Promise<AssignmentWork[]>}
   */
  getAllForAssignment(assignment) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/assignments/${assignment.id}/assignment-works`)
      .then((httpAssignmentWorks) => {
        return httpAssignmentWorks.assignment_works.map((httpAssignmentWork) => {
          return this._assignmentWorkCodec.decode(assignment, httpAssignmentWork);
        });
      });
  }

  /**
   * Gets all assignment works for associated assignment and roster
   *
   * @param assignment {Assignment}
   * @param rosterId {string}
   */
  getAllForAssignmentRoster(assignment, rosterId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/assignments/${assignment.id}/rosters/${rosterId}/assignment-works`)
      .then((response) => {
        return response.assignment_works.map((httpAssignmentWork) => {
          return this._assignmentWorkCodec.decode(assignment, httpAssignmentWork);
        });
      });
  }

  /**
   * Gets the assignment work for associated roster, assignment and user
   * Should be used when student needs access to peer's assignment work to give feedback
   *
   * @param rosterId {string}
   * @param assignment {Assignment}
   * @param userId {string}
   * @returns {Promise.<AssignmentWork>}
   */
  getForRosterAssignmentUser(rosterId, assignment, userId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/rosters/${rosterId}/users/${userId}/assignments/{${assignment.id}/assignment-works`)
      .then((httpAssignmentWork) => this._assignmentWorkCodec.decode(assignment, httpAssignmentWork));
  }

  /**
   * Gets assignment for specified assignment work
   * Used to get assignment associated with an assignment work
   *
   * @param assignmentWorkId {string}
   * @returns {Promise.<Assignment>}
   * @private
   */
  getAssignment(assignmentWorkId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/assignment-works/${assignmentWorkId}/assignment`)
      .then((httpAssignment) => this._assignmentCodec.decode(httpAssignment));
  }

  /**
   * @param assignmentId {string}
   * @param assignmentWorkId {string}
   * @param assignmentWorkQuestion {AssignmentWorkQuestion}
   * @returns {Promise.<T>}
   */
  updateQuestion(assignmentId, assignmentWorkId, assignmentWorkQuestion) {
    return this._httpService.authPut(
      `${this._environment.serverUrlBase}/v1/assignments/${assignmentId}/assignment-works/${assignmentWorkId}/questions/${assignmentWorkQuestion.id}`,
      {
        scored_points: assignmentWorkQuestion.points === AssignmentWorkQuestion.UNGRADED ? null : assignmentWorkQuestion.points,
        started_at: assignmentWorkQuestion.startedAt
      }
    );
  }

  /**
   * @param score {number}
   * @param question {AssignmentWorkQuestion}
   * @param assignmentId {string}
   * @param assignmentWorkId {string}
   * @return {Promise}
   */
  updateQuestionScore(score, question, assignmentId, assignmentWorkId) {
    let previousScore = question.points;
    question.points = score;
    return this.updateQuestion(assignmentId, assignmentWorkId, question)
      .catch(() => {
        // If score does not save, we should revert to previous score
        question.points = previousScore;
      });
  }

  /**
   * @param assignmentWork {AssignmentWork}
   * @returns {WorkQuestionSet}
   */
  getAssignmentWorkQuestionSet(assignmentWork) {
    return new WorkQuestionSet(
      assignmentWork,
      this._notificationService.getAssignmentWorkNotification(assignmentWork, false)
    );
  }

}
