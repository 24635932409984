
'use strict';

import {AssignmentQuestionMetadata} from '../../components/assignment-question/assignment-question.directive';
import FirebaseElementSavingIndicator from '../../components/saving-indicator/firebase-element-saving-indicator';
import {UserRoles} from '../../model/domain/user';
import {ElementIntents} from '../../model/domain/element-metadata';
import {Notifications} from '../../services/toolbar/toolbar.service';
import SaveStates from '../../components/saving-indicator/save-states';
import {AssignmentToolbarConfig} from '../../components/assignment-toolbar/assignment-toolbar.directive';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import { Locations } from '../../services/mixpanel/mixpanel.service';
import PreviewStudentUtil from '../../model/util/preview-student-util';

export default class AssignmentEditQuestionController {

  constructor($stateParams, $timeout, $scope, $window, $location, $mdDialog, CacheService, AuthService, BreadcrumbService,
              AssignmentTrackingService, ToolbarService, AssignmentService, NotificationService, PlatformHeaderService,
              AnalyticsService
  ) {
    'ngInject';
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$location = $location;
    this.$mdDialog = $mdDialog;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AssignmentTrackingService} */
    this._assignmentTrackingService = AssignmentTrackingService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {AssignmentService} */
    this._assignmentService= AssignmentService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;
    /** @type {PlatformHeaderService} */
    this._platformHeaderService = PlatformHeaderService;
    /** @type {PlatformHeaderService} */
    this._analyticsService = AnalyticsService;

    this._assignmentQuestionConfig = undefined;
    this._assignmentModified = false;

    this.toolbarOptions = new AssignmentToolbarConfig();
    this.toolbarOptions.editPoints = true;
    this.toolbarOptions.addMultipleChoice = true;
    this.toolbarOptions.addManipulativeImage = true;
    this.toolbarOptions.addFillInTheBlank = true;
    this.toolbarOptions.editSticker = true;
    this.toolbarOptions.editBackground = true;
    this.toolbarOptions.addAiAssistant = true;

    this._savingIndicator = new FirebaseElementSavingIndicator(this.$timeout, this._assignmentTrackingService);

    this._assignmentTrackingService.modified.subscribe(this._handleModified, this);

    this._loadingDialog = LoadingDialogController.show;

    $scope.$on('$destroy', () => this._destroy());
    this._init();
  }

  _init() {

      this._cacheService.getAssignmentForUser(this.assignmentId)
      .then((assignment) => {
        this._assignment = assignment;
        this.assignmentName = assignment.name;

        this._platformHeaderService.setAssignmentId(this._assignment.id);

        this._assignmentQuestionConfig = new AssignmentQuestionMetadata(
          assignment,
          this.questionId,
          false,
          false,
          this.userId,
          UserRoles.TEACHER,
          ElementIntents.CONTENT
        );

        this._toolbarService.notification.subscribe(this._handleToolbarNotification, this);

        this._assignmentNotification = this._notificationService.getAssignmentStatusNotification(this._assignment);
        this._assignmentNotification.questionUpdated.subscribe(this._onQuestionUpdated, this);
        this._assignmentNotification.start();

        this._configAiAssistantTool();
      })
      .catch((err) => {
        this.error = err;
      });
  }

  _handleModified(){
    this._assignmentModified = true;
  }

  _configAiAssistantTool() {
    this._toolbarService.enableAiAssistant();
    const sidenav = this._toolbarService.sidenavManager;
    if (sidenav.isOpen && sidenav.canShowMode && sidenav.mode.isAiAssistantMode && this._toolbarService.isAiAssistantDisabled) {
      sidenav.close();
    }
  }

  _handleToolbarNotification(ev) {
    if (ev.type === Notifications.CREATE_AI_ASSISTANT && ev.data && ev.data.disabled) {
      this._toolbarService.disableAiAssistant();
    }
    else if (ev.type === Notifications.UPDATE_POINT_VALUE) {
      this._savingIndicator.saveState = SaveStates.UNSAVED;

      let question = this._assignment.questionForId(this.questionId);
      let beforeQuestionId = this._assignment.beforeQuestionId(this.questionId);

      this._assignmentService.updateQuestion(this._assignment.id, question, beforeQuestionId)
        .then(() => {
          this._savingIndicator.saveState = SaveStates.SAVED;
        })
        .catch(() => {
          this._savingIndicator.saveState = SaveStates.SAVE_ERROR;
        });
      this._cacheService.reset();
    }
  }

  /**
   * @param ev {AssignmentStatusChange}
   * @private
   */
  _onQuestionUpdated(ev) {
    let question = this._assignment.questionForId(ev.questionId);
    question.points = ev.points;
  }

  _destroy() {
    this._platformHeaderService.setAssignmentId(undefined);

    if (this._assignmentModified) {
      this._cacheService.updateAssignmentModifiedDateForUser(this._assignment);
    }

    this._savingIndicator.destroy();

    if (this._toolbarService.notification) {
      this._toolbarService.notification.unsubscribe(this._handleToolbarNotification, this);
    }
    if (this._assignmentNotification) {
      this._assignmentNotification.questionUpdated.unsubscribe(this._onQuestionUpdated, this);
      this._assignmentNotification.stop();
    }
    this._assignmentTrackingService.modified.unsubscribe(this._handleModified, this);
  }

  get assignmentQuestionConfig() {
    return this._assignmentQuestionConfig;
  }

  /**
   * Assignment for the current question
   * @returns {string}
   */
  get assignmentId() {
    return this.$stateParams.assignmentId;
  }

  /**
   * Current question id
   * @returns {string}
   */
  get questionId() {
    return this.$stateParams.questionId;
  }

  /**
   * Current user id
   * @returns {string}
   */
  get userId() {
    return this._authService.authData ? this._authService.authData.id : '';
  }

  /**
   * @returns {SavingIndicator}
   */
  get saveState() {
    return this._savingIndicator && this._savingIndicator.saveState;
  }

  goBack() {
    this._breadcrumbService.goBack('root.account.assignment', {assignmentId: this.assignmentId});
  }

  openStudentPreview() {
    PreviewStudentUtil.openStudentPreview({
      analyticsService: this._analyticsService,
      pageLocation: Locations.ASSIGNMENT_EDIT_SINGLE_QUESTION,
      assignment: this._assignment,
      questionId: this.$stateParams.questionId,
      assignmentService: this._assignmentService,
      loadingDialog: this._loadingDialog,
      $mdDialog: this.$mdDialog,
      $location: this.$location,
      $window: this.$window
    });
  }
}
