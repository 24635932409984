'use strict';

import AssignmentSelectionDialogTemplate from './assignment-selection-dialog.html';

export default class AssignmentSelectionDialogController {

  /**
   * @ngInject
   */
  constructor($mdDialog, assignments, answerExportStatus = false, CacheService, $window, StaticContentService, $q) {
    this.$mdDialog = $mdDialog;
    this._assignments = assignments.sort(this._sortFunc);
    this.searchTerm = null;
    this._filteredAssignments = null;
    this._allSelected = false;
    /** @type {Set.<string>} */
    this._selectedAssignmentIds = new Set();
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    this.answerExportStatus = answerExportStatus;

    this.$window = $window;
    this.$q = $q;

    this._loading = false;
  }

  /**
   * @return {Assignment[]}
   */
  get assignments() {
    return this._filteredAssignments || this._assignments;
  }

  /**
   * @returns {boolean}
   */
  get loading() {
    return this._loading && !this._assignments;
  }

  /**
   * @returns {boolean}
   */
  get allSelected() {
    return this._allSelected;
  }

  set allSelected(value) {
    this._allSelected = value;
  }

  /**
   * @returns {Set}
   */
  get selectedAssignmentIds() {
    return this._selectedAssignmentIds;
  }

  /**
   * Launches assignment select dialog
   * @param $mdDialog
   * @param assignments {assignment[]}
   */
  static show($mdDialog, assignments, answerExportStatus) {
    return $mdDialog.show({
      controller: AssignmentSelectionDialogController,
      template: AssignmentSelectionDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: true,
      locals: {
        assignments,
        answerExportStatus
      }
    });
  }

  search() {
    if (this.searchTerm && this._assignments) {
      const searchTerm = this.searchTerm.toLowerCase();
      this._filteredAssignments = this._assignments.filter((x) =>
        x.name.toLowerCase().includes(searchTerm)
      );
    }
    else {
      this._filteredAssignments = null;
    }
  }

  toggleAllAssignments() {
    //this is to stop the the search input field from triggering the checkbox because it's part of the md list every click triggers the checkbox change
    if (event.target && event.target.tagName === 'INPUT') {
      this._allSelected = !this._allSelected;
      return;
    }
    if (this.allSelected === true) {
      this.assignments.forEach((assignment) => {
        assignment.toggled = true;
        if (!this._selectedAssignmentIds.has(assignment.id)) {
          this._selectedAssignmentIds.add(assignment.id);
        }
      });
    } else {
      this._cleanAssignments();
    }
  }

  toggleAssignment(assignment) {
    if (this._selectedAssignmentIds.has(assignment.id)) {
      this._selectedAssignmentIds.delete(assignment.id);
    }
    else {
      this._selectedAssignmentIds.add(assignment.id);
    }
  }

  export() {
    let selectedAssignmentIdsInOrder = this._assignments
      .filter((assignment) => this._selectedAssignmentIds.has(assignment.id))
      .sort(this._sortFunc)
      .map((assignment) => assignment.id);

    this._cleanAssignments();

    this.$mdDialog.hide(selectedAssignmentIdsInOrder);
  }

  _cleanAssignments() {
    this._assignments.forEach((assignment) => {
      delete assignment.toggled;
      this._selectedAssignmentIds.delete(assignment.id);
    });
  }

  /**
   * @param assignmentA {Assignment}
   * @param assignmentB {Assignment}
   * @return {number}
   */
  _sortFunc(assignmentA, assignmentB) {
    var nameA = assignmentA.name.toUpperCase();
    var nameB = assignmentB.name.toUpperCase();
    return nameA.localeCompare(nameB);
  }

  cancel() {
    this._cleanAssignments();
    this.$mdDialog.cancel();
  }
}
