
'use strict';

import StaticService from '../../services/static/static.service.js';

/**
 * General purpose eventing system
 * @template T
 */
export default class JSEvent {
  /**
   * @param [source] {object} The source of the event
   */
  constructor(source) {
    this._source = source;
    this._listeners = [];
  }

  /**
   * Subscribes to this event
   *
   * @param func {function(T, source)} - a callback function. First parameter is the event args, second is the source object which raised the event
   * @param [context] {object} - the context to be used with the callback
   */
  subscribe(func, context) {
    if (this._listeners.some((x) => x.f === func && x.c === context)) {
      return;
    }

    var x = {
      f: func,
      c: context
    };
    this._listeners.push(x);
    return x;
  }

  /**
   * Unsubscribes from this event
   *
   * @param func {function(T, source)} - a callback function. First parameter is the event args, second is the source object which raised the event
   * @param [context] {object} - the context to be used with the callback
   */
  unsubscribe(func, context) {
    this._listeners = this._listeners.filter((x) => !(x.f === func && x.c === context));
  }

  /**
   * Subscribes to the next occurrence of this event
   *
   * @param func {function(T, source)} - a callback function. First parameter is the event args, second is the source object which raised the event
   * @param [context] {object} - the context to be used with the callback
   */
  once(func, context) {
    context = context || this;

    var f = (args, source) => {
      func.call(context, args, source || this._source);
      this.unsubscribe(f);
    };

    this.subscribe(f);

    return f;
  }

  cancelOnce(f) {
    this.unsubscribe(f);
  }

  /**
   * Raises the event with the specified args and source
   *
   * @param [args] {T} The event arguments
   * @param [source] {object} The object raising the event
   */
  raise(args, source) {
    this._listeners.forEach((handler) => {
      try {
        handler.f.call(handler.c || this, args, source || this._source);
      }
      catch (ex) {
        StaticService.get.$log.error('callback error', ex, handler);
      }
    });
  }

  /**
   * Clears all listeners for this event
   */
  clear() {
    this._listeners = [];
  }
}
