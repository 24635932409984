
'use strict';

import BootstrapService_ from '../../services/bootstrap/bootstrap.service';
import ConnectionIndicator from '../../model/util/connection-indicator';

export default class RootController {
  constructor($log, BootstrapService, $state, $scope, FirebaseService, $mdToast, $timeout, StaticContentService, AnalyticsService) {
    'ngInject';

    this.$log = $log;
    this.$state = $state;
    /** @type {BootstrapService} */
    this._bootstrapService = BootstrapService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {StaticContentService} */
    this._analyticsService = AnalyticsService;

    $scope.$on(BootstrapService_.EVENT_STATE, () => { this._stateUpdated(); });

    // TODO: Connection toast removed for connection issues. Uncomment when issues are resolved.
    // this._connectionIndicator = new ConnectionIndicator(FirebaseService, BootstrapService, $scope, $mdToast, $timeout);
    // this._connectionIndicator.start();

    this._staticContentService.recommendBestBrowser();
  }

  // Called when the state of the bootstrap service changes,
  // when bootstrap is complete, this reloads the child state so that the constructor is run again
  // We reload only the child state so that the animation can effectively animate in the loaded state. If we
  // reload the whole page, no animation plays because the HTML including the animation is reloaded.
  _stateUpdated() {
    if (this.state === 'ready') {
      this.$state.go(this.$state.current, {}, {reload: this._firstChild(this.$state.current.name)});
    }
  }

  // Returns the name of the first child of the this state - 'root.[child]'
  _firstChild(stateName) {
    var idx = stateName.indexOf('.');
    idx = stateName.indexOf('.', idx + 1);

    if (idx < 0) {
      return stateName;
    }

    return stateName.substring(0, idx);
  }

  get state() {
    return this._bootstrapService.state;
  }

  get errorMessage() {
    return this._bootstrapService.errorMessage;
  }

}
