
'use strict';

import QuestionObserver from './question-observer';
import UndoStack from './undo-stack';
import Change from '../../model/ui/elements/change';
import JSEvent from '../../model/util/js-event';
import SvgSheet from '../../model/ui/svg-sheet';
import SvgCanvas from '../../model/ui/svg-canvas';
import Point from '../../model/ui/point';
import CkImage from '../../model/ui/elements/ckimage';
import Size from '../../model/ui/size';
import { GradeInputDirectiveController } from '../../components/grade-input/grade-input.directive';
import AudioClip from '../../model/ui/elements/audio-clip';
import Sticker from '../../model/ui/elements/sticker';
import ManipulativeImageParent from '../../model/ui/elements/manipulative-image-parent';
import MimeTypes from '../../model/domain/mime-types';
import MultiChange from '../../model/ui/elements/multi-change';
import CdnUtils from '../../model/util/cdn-utils';

/**
 * Responsible for saving changes to assignment elements, and storing the save/undo stack
 */
export default class AssignmentTrackingService {
  constructor($rootScope, $q, $timeout, $log, MediaService, AssignmentService, ImageEditService, AssignmentWorkService, ManipulativeElementService) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.$q = $q;
    this.$log = $log;

    /** @type {MediaService} */
    this._mediaService = MediaService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {ImageEditService} */
    this._imageEditService = ImageEditService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {ManipulativeElementService} */
    this._manipulativeElementService = ManipulativeElementService;

    this._cascadeStart = 50;
    this._cascadeIncrement = 50;

    /** @type {Assignment|AssignmentWork} */
    this._target = null;
    /** @type {Assignment} */
    this._assignment = null;
    /** @type {AssignmentWork} */
    this._assignmentWork = null;

    /** @type {JSEvent} */
    this._previewModified = new JSEvent(this);
    /** @type {JSEvent} */
    this._modified = new JSEvent(this);

    /** @type {JSEvent} */
    this._undoStackUpdated = new JSEvent(this);

    /** @type {Map.<string, QuestionObserver>} */
    this._workObservers = new Map();
    /** @type {Map.<string, QuestionObserver>} */
    this._assignmentObservers = new Map();

    /** @type {UndoStack} */
    this._assignmentUndo = undefined;
    /** @type {UndoStack} */
    this._workUndo = undefined;
  }

  /**
   * @param value {Assignment|AssignmentWork}
   */
  set target(value) {
    this._init(() => {
      this._target = value;
      if (value && value.isWork) {
        this._assignment = value.assignment;
        this._assignmentWork = value;
      }
      else {
        this._assignment = value;
        this._assignmentWork = null;
      }
    });
  }

  /**
   * @returns {JSEvent}
   */
  get previewModified() {
    return this._previewModified;
  }

  /**
   * @returns {JSEvent}
   */
  get modified() {
    return this._modified;
  }

  /**
   * @returns {JSEvent}
   */
  get undoStackUpdated() {
    return this._undoStackUpdated;
  }

  /**
   * @returns {Assignment|AssignmentWork}
   */
  get target() {
    return this._target;
  }

  get assignment() {
    return this._assignment;
  }

  get assignmentWork() {
    return this._assignmentWork;
  }

  clearTarget() {
    this.target = null;
  }

  _raiseModification(event, type, element) {
    event.raise({
      type: type,
      element: element
    });
  }

  /**
   * Persists creation of an element into firebase
   *
   * @param element {Element} The element
   * @param questionIndex {number} The index of the question
   * @param isWork {boolean} Student work/feedback (true) or content (false)
   * @returns {Promise}
   */
  createElement(element, questionIndex, isWork) {
    this._raiseModification(this._previewModified, this.MODIFICATION_CREATE, element);

    let question = this._questionForIndex(questionIndex, isWork);

    return this._createElement(question, element)
      .then((elementId) => {
        let change = new Change(element.id || elementId, element.fromSnapshot);
        this._undoStack(isWork).push(change, question);
        this.undoStackUpdated.raise(change);
        this._raiseModification(this._modified, this.MODIFICATION_CREATE, element);
      });
  }

  /**
   * @param question {AssignmentQuestion|AssignmentWorkQuestion}
   * @param element {Element}
   * @return {Promise}
   */
  _createElement(question, element) {
    if (element.isParentManipulative) {
      question.remoteAdd(element);
      return this._manipulativeElementService.createParent(this.assignment.id, question.id, element)
        .then((element) => element.id);
    }
    else {
      return question.saveElement(element);
    }
  }

  /**
   * Persists deletion of multiple elements into firebase
   *
   * @param elements {Element[]} The elements
   * @param questionIndex {number} The index of the question
   * @param isWork {boolean} Student work/feedback (true) or content (false)
   * @returns {Promise}
   */
  deleteElements(elements, questionIndex, isWork) {
    if (elements.length === 0) {
      return Promise.resolve();
    }

    let question = this._questionForIndex(questionIndex, isWork);
    let results = elements.map((element) => this._deleteElement(element, questionIndex, isWork));
    let changes = elements.map((element) => new Change(element.id, element.fromSnapshot, element.snapshot(), true));
    let change = new MultiChange(changes);
    this._undoStack(isWork).push(change, question);

    this.undoStackUpdated.raise(change);

    return Promise.all(results);
  }

  /**
   * Persists deletion of an element into firebase
   *
   * @param element {Element} The element
   * @param questionIndex {number} The index of the question
   * @param isWork {boolean} Student work/feedback (true) or content (false)
   * @returns {Promise}
   */
  deleteElement(element, questionIndex, isWork) {
    element.flush();

    let question = this._questionForIndex(questionIndex, isWork);
    let result = this._deleteElement(element, questionIndex, isWork);
    let change = new Change(element.id, element.fromSnapshot, element.snapshot(), true);
    this._undoStack(isWork).push(change, question);

    this.undoStackUpdated.raise(change);

    return result;
  }

  /**
   * @param f {function} executes modifications to controller state to surround by subscription logic
   * @private
   */
  _init(f) {
    //tear down subscriptions
    if (this._assignment) {
      this._assignment.questions.forEach((q) => {
        if (this._assignmentObservers.has(q.id)) {
          let observer = this._assignmentObservers.get(q.id);
          observer.stop(q);
        }
      });
    }
    if (this._assignmentWork) {
      this._assignmentWork.questions.forEach((q) => {
        if (this._workObservers.has(q.id)) {
          let observer = this._workObservers.get(q.id);
          observer.stop(q);
        }
      });
    }

    // apply modifications
    f();

    // Set up subscriptions
    if (!this._assignmentUndo || this._assignment.id !== this._assignmentUndo.id) {
      this._assignmentUndo = new UndoStack(
        this.assignment.id,
        this.undoCreate.bind(this),
        this.undoRemove.bind(this),
        this.undoChange.bind(this)
      );
    }
    this._workUndo = new UndoStack(
      this.assignment.id,
      this.undoCreate.bind(this),
      this.undoRemove.bind(this),
      this.undoChange.bind(this)
    );

    this._assignmentObservers = new Map();
    this._workObservers = new Map();

    if (this._assignment) {
      this._assignment.questions.forEach((q, index) => {
        let o = new QuestionObserver(this, index, false);
        o.start(q);
        this._assignmentObservers.set(q.id, o);
      });
    }
    if (this._assignmentWork) {
      this._assignmentWork.questions.forEach((q, questionId) => {
        try {
          let index = this._assignmentWork.indexForQuestionId(questionId);
          let o = new QuestionObserver(this, index, true);
          o.start(q);
          this._workObservers.set(q.id, o);
        }
        catch (error) {
          this.$log.error(error);
        }
      });
    }
  }

  /**
   * @param element {Element}
   * @private
   */
  _previewElementChanged(element) {
    this._raiseModification(this._previewModified, this.MODIFICATION_UPDATE, element);
  }

  /**
   * @param change {Change}
   * @param index {index}
   * @param isWork {boolean}
   * @private
   */
  _elementChanged(change, index, isWork) {
    let question = this._questionForIndex(index, isWork);
    /** @type {Element|object} */
    let element = question.elements.valueForId(change.elementId);

    if (change.deleted) {
      this._deleteElement(element, index, isWork);
    }
    else {
      this._write(index, isWork, (q) => {
        return this._saveElement(q, element);
      })
      .then(() => {
        this._raiseModification(this._modified, this.MODIFICATION_UPDATE, element);
      });
    }

    this._undoStack(isWork).push(change, question);

    this.undoStackUpdated.raise(change);
  }

  /**
   * @param question {AssignmentQuestion|AssignmentWorkQuestion}
   * @param element {Element}
   * @return {Promise}
   */
  _saveElement(question, element) {
    if (element.isParentManipulative) {
      return this._manipulativeElementService.updateParent(this.assignment.id, question.id, element);
    }
    else if (element.isChildManipulative) {
      return this._manipulativeElementService.updateChild(this.assignmentWork.id, question.id, element);
    }
    else {
      return question.saveElement(element);
    }
  }

  /**
   * @param element {Element}
   * @param questionIndex {number}
   * @param isWork {boolean}
   * @returns {Promise}
   * @private
   */
  _deleteElement(element, questionIndex, isWork) {
    this._raiseModification(this._previewModified, this.MODIFICATION_DELETE, element);

    return this._write(questionIndex, isWork, (q) => {
        this._removeElementSideEffects(q, element);
        return this._removeElement(q, element);
      })
      .then(() => {
        this._raiseModification(this._modified, this.MODIFICATION_DELETE, element);
      });
  }

  /**
   * @param question {AssignmentQuestion|AssignmentWorkQuestion}
   * @param element {Element}
   * @return {Promise}
   */
  _removeElement(question, element) {
    if (element.isParentManipulative) {
      return this._manipulativeElementService.deleteParent(this.assignment.id, question.id, element.id);
    }
    else if (element.isChildManipulative) {
      // add web services request when child manipulatives become removable
    }
    else {
      return question.removeElement(element);
    }
  }

  /**
   * @param question {AssignmentQuestion|AssignmentWorkQuestion}
   * @param element {Element}
   */
  _removeElementSideEffects(question, element) {
    if (element.type === CkImage.type || element.type === AudioClip.type || element.type === ManipulativeImageParent.type) {
      this._mediaService.delete(element.id, element.url, question.elementListId);
    }
    else if (element.type === Sticker.type) {
      this._removeScoreFromQuestion(question, element.score);
    }
  }

  /**
   * @param isWork
   * @returns {UndoStack}
   * @private
   */
  _undoStack(isWork) {
    if (isWork) {
      return this._workUndo;
    }
    else {
      return this._assignmentUndo;
    }
  }

  /**
   * @param index {number}
   * @param isWork {boolean}
   * @returns {AssignmentQuestion}
   * @private
   */
  _questionForIndex(index, isWork) {
    if (isWork) {
      return this._assignmentWork.questionForIndex(index);
    }
    else {
      return this._assignment.questions[index];
    }
  }

  /**
   * Helper function to write to multiple schemas
   *
   * @param index {number} The question index
   * @param isWork {boolean} Whether we need assignment or work content
   * @param f {function(AssignmentQuestion)} executes a save or delete operation on an AssignmentQuestion
   * @private
   */
  _write(index, isWork, f) {
    return f(isWork ? this._assignmentWork.questionForIndex(index) : this._assignment.questions[index]);
  }

  /**
   * Returns an version of #_write which only requires the function
   *
   * @param index {number} The question index
   * @param isWork {boolean} Whether we need assignment or work content
   * @returns {function(function(AssignmentQuestion))}
   * @private
   */
  _writer(index, isWork) {
    return (f) => {
      this._write(index, isWork, f);
    };
  }

  /**
   * @param questionIndex {number}
   * @param isWork {boolean}
   * @returns {boolean}
   */
  canUndo(questionIndex, isWork) {
    const question = this._questionForIndex(questionIndex, isWork);
    if (question) {
      return !this._undoStack(isWork).isUndoEmpty(question);
    }
    return false;
  }

  /**
   * @param questionIndex {number}
   * @param isWork {boolean}
   */
  undo(questionIndex, isWork) {
    let question = this._questionForIndex(questionIndex, isWork);
    question.elements.forEach((e) => e.flush());
    let change = this._undoStack(isWork).undo(question);
    this.undoStackUpdated.raise(change);
    return change;
  }

  /**
   * @param questionIndex {number}
   * @param isWork {boolean}
   * @returns {boolean}
   */
  canRedo(questionIndex, isWork) {
    const question = this._questionForIndex(questionIndex, isWork);
    if (question) {
      return !this._undoStack(isWork).isRedoEmpty(question);
    }
    return false;
  }

  /**
   * @param questionIndex {number}
   * @param isWork {boolean}
   */
  redo(questionIndex, isWork) {
    let question = this._questionForIndex(questionIndex, isWork);
    let change = this._undoStack(isWork).redo(question);
    this.undoStackUpdated.raise(change);
    return change;
  }

  get MODIFICATION_CREATE() {
    return 'create';
  }

  get MODIFICATION_UPDATE() {
    return 'update';
  }

  get MODIFICATION_DELETE() {
    return 'delete';
  }

  //---------------------- Image Import Methods ----------------------------

  /**
   * @param data {{newQuestions: QuestionDisplay[], images: ImageImport[]}}
   * @param target {Assignment|AssignmentWork}
   * @param metadata {ElementMetadata}
   * @param [sheet] {SvgSheet}
   * @param [currentQuestionId] {string}
   * @returns {Promise.<Assignment>}
   */
  importImages(data, target, metadata, sheet, currentQuestionId) {

    const cascade = new Map();

    return this._updateTarget(data.newQuestions, target)
      .then((result) => {
        return this._addImages(data.images, result.newTarget, metadata, sheet, cascade, currentQuestionId);
      });
  }

  /**
   * @param newQuestions {QuestionDisplay[]}
   * @param target {Assignment|AssignmentWork}
   * @returns {Promise.<{newTarget, originalTarget}>}
   */
  _updateTarget(newQuestions, target) {
    if (newQuestions.length === 0) {
      return this.$q.resolve({
        newTarget: target,
        originalTarget: target
      });
    }

    /** @type {Assignment} */
    let assignment = target.isWork ? target.assignment : target;

    const originalTrackingTarget = this.target;

    return this._addQuestions(newQuestions, assignment)
      .then(() => {
        if (!target.isWork) {
          return this._assignmentService.get(assignment.id);
        }
        else {
          return target;
        }
      })
      .then((newTarget) => {
        this.target = newTarget;

        return {
          newTarget: newTarget,
          originalTarget: originalTrackingTarget
        };
      });
  }

  /**
   * @param newQuestions {QuestionDisplay[]}
   * @param assignment {Assignment}
   * @returns {Promise}
   */
  _addQuestions(newQuestions, assignment) {
    if (newQuestions.length === 0) {
      return this.$q.resolve(assignment);
    }

    const newQuestion = newQuestions[0];

    return this._assignmentService.addQuestion(assignment.id, newQuestion.beforeQuestionId)
      .then((question) => {
        // Replace the temporary id with the real id from web services
        newQuestion.questionId = question.id;
        // Add the new question in the appropriate place
        assignment.questions.splice(newQuestion.index, 0, question);
        return this._addQuestions(newQuestions.slice(1), assignment);
      });
  }

  /**
   * @param images {ImageImport[]}
   * @param target {Assignment|AssignmentWork}
   * @param metadata {ElementMetadata}
   * @param sheet {SvgSheet}
   * @param cascade {Map.<string, int>}
   * @param currentQuestionId {string}
   * @returns {Promise}
   */
  _addImages(images, target, metadata, sheet, cascade, currentQuestionId) {
    if (images.length === 0) {
      return this.$q.resolve(target);
    }

    const current = images[0];

    return this._addImageDestinations(current.destinations, current, target, metadata, sheet, cascade, currentQuestionId)
      .then(() => {
        return this._addImages(images.slice(1), target, metadata, sheet, cascade, currentQuestionId);
      });
  }

  /**
   * @param destinations {QuestionDisplay[]}
   * @param image {ImageImport}
   * @param target {Assignment|AssignmentWork}
   * @param metadata {ElementMetadata}
   * @param sheet {SvgSheet}
   * @param cascade {Map.<string, int>}
   * @param currentQuestionId {string}
   * @returns {Promise}
   */
  _addImageDestinations(destinations, image, target, metadata, sheet, cascade, currentQuestionId) {
    if (destinations.length === 0) {
      return this.$q.resolve();
    }

    const questionId = destinations[0].questionId;
    const isCurrentQuestion = currentQuestionId === questionId;
    const isWork = target.isWork;
    const index = target.indexForQuestionId(questionId);
    const elementListId = isWork ? target.questions.get(questionId).elementListId : target.questions[index].elementListId;

    return this._addImage(image, elementListId, metadata, index, isWork, sheet, cascade, isCurrentQuestion)
      .then(() => {
        return this._addImageDestinations(destinations.slice(1), image, target, metadata, sheet, cascade, currentQuestionId);
      });
  }

  /**
   * @param image {ImageImport}
   * @param elementListId {string}
   * @param metadata {ElementMetadata}
   * @param index {int}
   * @param isWork {boolean}
   * @param sheet {SvgSheet}
   * @param cascade {Map.<string, int>}
   * @param isCurrentQuestion {boolean}
   * @returns {Promise}
   */
  _addImage(image, elementListId, metadata, index, isWork, sheet, cascade, isCurrentQuestion) {

    let blob;

    if (image.originalFile && image.originalFile.type === MimeTypes.IMAGE_GIF) {
      blob = image.originalFile;
    }
    else {
      // Convert our uri to blob
      blob = this._imageEditService.dataURItoBlob(image.imageSrc);
    }

    // Upload the media
    return this._mediaService.create(blob, metadata.intent, elementListId)
      .then((result) => {
        // Download the image
        const transformedImageUrl = CdnUtils.urlTransform(result.mediaLink);
        return this.$q.all({
          image: this._imageEditService.imageFromUrl(transformedImageUrl),
          mediaLink: result.mediaLink,
          newElementId: result.newElementId
        });
      })
      // Create the new element
      .then((result) => {
        const imageSize = new Size(result.image.naturalWidth, result.image.naturalHeight);

        const constructor = image.isManipulative ? ManipulativeImageParent : CkImage;

        return new constructor(
          result.newElementId,
          metadata.copy(),
          this._getLocation(sheet, cascade, elementListId, imageSize, isCurrentQuestion),
          imageSize,
          1,
          0,
          result.mediaLink
        );
      })
      // Add the element to firebase
      .then((element) => {
        return this.createElement(element, index, isWork);
      });
  }

  /**
   * @param sheet {SvgSheet}
   * @param cascade {Map.<string, int>}
   * @param elementListId {string}
   * @param imageSize {Size}
   * @param isCurrentQuestion {boolean}
   * @returns {Point}
   * @private
   */
  _getLocation(sheet, cascade, elementListId, imageSize, isCurrentQuestion) {

    const y = cascade.has(elementListId) ? cascade.get(elementListId) : this._cascadeStart;
    cascade.set(elementListId, y + this._cascadeIncrement);

    if (isCurrentQuestion) {
      // If we're adding to the current page, place the image in the view
      return new Point(
        SvgCanvas.INITIAL_WIDTH * .5,
        sheet.elementOffset(new Point(0, y)).y + (imageSize.height * .5)
      );

    }
    else {
      // Otherwise center the image at the top of the question
      return new Point(
        SvgCanvas.INITIAL_WIDTH * .5,
        y + (imageSize.height * .5)
      );
    }
  }

  /**
   * @param question {AssignmentWorkQuestion}
   * @param score {number}
   */
  _removeScoreFromQuestion(question, score) {
    if (!angular.isNumber(question.points) || !angular.isNumber(score)) {
      return;
    }

    question.points = parseFloat((question.points - score).toFixed(2));

    if (question.points < 0) {
      question.points = 0;
    }

    this._assignmentWorkService.updateQuestion(this.assignment.id, this.assignmentWork.id, question);
  }

  /**
   * @param question {AssignmentQuestion|AssignmentWorkQuestion}
   * @param element {Element}
   */
  undoCreate(question, element) {
    this._removeElementSideEffects(question, element);
    this._removeElement(question, element);
  }

  /**
   * @param question {AssignmentQuestion|AssignmentWorkQuestion}
   * @param element {Element}
   */
  undoRemove(question, element) {
    this._restoreElementSideEffects(question, element);
    this._restoreElement(question, element);
  }

  /**
   * @param question {AssignmentQuestion|AssignmentWorkQuestion}
   * @param element {Element}
   * @return {Promise}
   */
  _restoreElement(question, element) {
    if (element.isParentManipulative) {
      return this._manipulativeElementService.restoreParent(this.assignment.id, question.id, element.id);
    }
    else if (element.isChildManipulative) {
      // add web services request when child manipulatives become removable
    }
    else {
      return question.saveElement(element);
    }
  }

  /**
   * @param question {AssignmentQuestion}
   * @param element {Element}
   */
  _restoreElementSideEffects(question, element) {
    if (element.type === CkImage.type || element.type === AudioClip.type || element.type === ManipulativeImageParent.type) {
      this._mediaService.restore(element.id, element.url, question.elementListId);
    }
    else if (element.type === Sticker.type) {
      this._addScoreToQuestion(question, element.score);
    }
  }

  /**
   * @param question {AssignmentWorkQuestion}
   * @param score {number}
   */
  _addScoreToQuestion(question, score) {
    if (!angular.isNumber(question.points) || !angular.isNumber(score)) {
      return;
    }

    question.points = Math.min(question.points + score, GradeInputDirectiveController.MAX_GRADE);

    this._assignmentWorkService.updateQuestion(this.assignment.id, this.assignmentWork.id, question);
  }

  /**
   * @param question {AssignmentQuestion|AssignmentWorkQuestion}
   * @param element {Element}
   */
  undoChange(question, element) {
    this._saveElement(question, element);
  }

}
