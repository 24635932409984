
'use strict';

import Codec from './codec';
import ElementCodec from './element-codec';

import CkImage from '../ui/elements/ckimage';
import JsonCodec from './json-codec';

/**
 * Encodes/Decodes an image element
 */
export default class CkImageCodec extends Codec {
  constructor() {
    super();
    this._jsonCodec = new JsonCodec();
  }

  /**
   * @param value {CkImage}
   * @returns {{data: Object, metadata: Object}}
   */
  encode(value) {
    return ElementCodec.preEncode(value, () => {
      let transform = this._jsonCodec.encode({
        scale: value.scale,
        rotation: value.rotation
      });

      return {
        type: value.type,
        image_url: value.url,
        size: ElementCodec.sizeCodec.encode(value.imageSize),
        center: ElementCodec.pointCodec.encode(value.location),
        transform: transform
      };
    });
  }

  /**
   * @param value {object}
   * @param key {string}
   * @returns {CkImage|Element}
   */
  decode(value, key) {
    return ElementCodec.preDecode(value, (metadata, size, location, center) => {

      let transform = this._decodeTransform(value.data.transform);

      return new CkImage(
        key,
        metadata,
        center,
        size,
        transform.scale,
        transform.rotation,
        value.data.image_url
      );
    });
  }

  /**
   * Wraps the decoding of the transform in a try/catch to protect against unexpected values for transform
   * such as "nan" values for rotation and scale that cannot be parsed from JSON string
   *
   * @param rawTransform {string}
   * @return {{rotation: number, scale: number}}
   */
  _decodeTransform(rawTransform) {
    try {
      return this._jsonCodec.decode(rawTransform);
    }
    catch (error) {
      return {scale: 1, rotation: 0};
    }
  }
}
