import JSEvent from '../../model/util/js-event';

export default class ContributorHistoryService {
  constructor() {
    'ngInject';
    this._updated = new JSEvent(this);
  }

  /**
   * @returns {JSEvent}
   */
  get updated() {
    return this._updated;
  }

  notify(value){
    this._updated.raise({
      type: 'canvas_update',
      data: value
    });
  }

  canvasUpdated(value){
    this.notify(value);
  }
}
