/*
 * Purpose: Paginating larger lists to avoid slow loading times.
 * For use with md-virtual-repeat.
 */

export default class VirtualCollection {

    constructor(fullCollection, pageSize) {
      this._fullCollection = fullCollection;
      this._length = 0;
      this._loadedVirtualPages = {};
      this._pageSize = pageSize;
    }

    get pageSize() {
      return this._pageSize;
    }

    getItemAtIndex(index) {
      let virtualPageNumber = Math.floor(index / this.pageSize);
      let virtualPage = this._loadedVirtualPages[virtualPageNumber];
      if (virtualPage && virtualPage.length) {
        return virtualPage[index % this.pageSize];
      } else {
        return this._fetchVirtualPage(virtualPageNumber);
      }
    }

    getLength() {
      return this._fullCollection && this._fullCollection.length;
    }

    set fullCollection(fullCollection) {
      this._fullCollection = fullCollection;
      this._reset();
    }

    get loadedCollection(){
      let loadedCollection = [];
      Object.keys(this._loadedVirtualPages).forEach((page) => {
        loadedCollection = [...loadedCollection, ...this._loadedVirtualPages[page]];
      });
      return loadedCollection;
    }

    _reset() {
      this._loadedVirtualPages = {};
    }

    _fetchVirtualPage(virtualPageNumber) {
      this._loadedVirtualPages[virtualPageNumber] = [];
      let virtualPageOffset = virtualPageNumber * this.pageSize;
      for (let i = virtualPageOffset; i < virtualPageOffset + this.pageSize; i++) {
        this._loadedVirtualPages[virtualPageNumber].push(this._fullCollection[i]);
      }
    }
  }
