'use strict';

import VirtualCollection from '../../model/domain/virtual-collection';
import {StudentAssignmentItem} from '../student-assignments-list/student-assignment-folder-item';
import UserListItem from '../../model/domain/user-list-item';
import StudentOverviewAssignmentItem from '../../model/domain/student-overview-assignment-item';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import AssignmentSelectionDialogController
  from '../../components/assignment-selection-dialog/assignment-selection-dialog.controller';
import AssignmentManager from '../../model/domain/assignment-manager';
import {PaywallSources, SubscriptionFunnel} from '../../services/mixpanel/mixpanel.service';

/**
 * Defines the columns in the student overview table
 */
export class StudentOverviewTableColumns {

  static get ASSIGNMENT_NAME() {
    return 'assignment_name';
  }

  static get START_DATE() {
    return 'start_date';
  }

  static get SLIDES() {
    return 'slides';
  }

  static get GRADE() {
    return 'grade';
  }

  static get HELPS() {
    return 'helps';
  }
}

/**
 * Allows teachers to see all work for a single student in a roster
 */
export default class StudentOverviewController {

  constructor($q, $scope, $log, $mdDialog, $mdToast, $timeout, $stateParams, environment, AnswerExportService, AssignmentService, AssignmentWorkService,
              AuthService, BreadcrumbService, CacheService, ExportService, FeedbackService, GradeExportService, HelpRequestService, NotificationService,
              AnalyticsService, RosterService, StorageService, StaticContentService, LogRocketService) {
    'ngInject';

    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$log = $log;
    this.$timeout = $timeout;
    this.$stateParams = $stateParams;
    this._environment = environment;

    /** @type {AnswerExportService} */
    this._answerExportService = AnswerExportService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {ExportService} */
    this._exportService = ExportService;
    /** @type {FeedbackService} */
    this._feedbackService = FeedbackService;
    /** @type {GradeExportService} */
    this._gradeExportService = GradeExportService;
    /** @type {HelpRequestService} */
    this._helpRequestService = HelpRequestService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {LogRocketService} */
    this._logRocketService = LogRocketService;

    this._isAscending = this._storageService.studentOverviewSortingProperty && angular.isDefined(this._storageService.studentOverviewSortingProperty.isAscending) ? this._storageService.studentOverviewSortingProperty.isAscending : false;
    this._confirmDialog = ConfirmDialogController.show;
    this._loading = true;
    this._orderByColumn = this._storageService.studentOverviewSortingProperty && this._storageService.studentOverviewSortingProperty.column ? this._storageService.studentOverviewSortingProperty.column : StudentOverviewTableColumns.START_DATE;
    this._roster = null;
    this._studentAssignmentsToBeDisplayed = new Map();
    this._studentAssignmentsToBeDisplayedList = [];
    this._showAssignmentSelectionDialog = AssignmentSelectionDialogController.show;
    this._studentUser = null;
    this._virtualStudentAssignmentsToBeDisplayed = null;

    this._userNotification = null;
    this.fetchDataCount = 0;
    this.query = this._storageService.studentOverviewQuery ? this._storageService.studentOverviewQuery : '';
    this.isProUser = false;
    this.rosterId = $stateParams.rosterId;
    this.salesBuddyUrl = this._environment.salesBuddyV2Url;
    this.studentUserId = $stateParams.userId;

    this._assignmentManager = new AssignmentManager(
      this.$q,
      this.$mdDialog,
      this.$mdToast,
      this._cacheService,
      this._assignmentService,
      this._breadcrumbService,
      this._gradeExportService,
      this._answerExportService,
      this._analyticsService,
      this._exportService
    );

    $scope.$on('$destroy', () => this._destroy());

    this.init();
  }

  init() {
    if (this._authService.authData) {
      this.isProUser = this._authService.authData.isPro;
    }

    this.$q.all({
      assignmentsAndAssignmentRostersAndAssignmentWorks: this._cacheService.getStudentAssignmentsAndWorks(this.studentUserId, this.rosterId, true),
      helpRequestSet: this._helpRequestService.getHelpRequestSetForRosterAndStudentId(this._authService.currentUserId, this.rosterId, this.studentUserId),
      rosterList: this._cacheService.getStudentRosters(this.studentUserId, true),
      rosterMembers: this._cacheService.getRosterUsers(this.rosterId)
    })
      .then(({assignmentsAndAssignmentRostersAndAssignmentWorks, helpRequestSet, rosterList, rosterMembers}) => {
        this._helpRequestSet = helpRequestSet;
        this._roster = rosterList.get(this.rosterId);
        this._studentUser = rosterMembers.get(this.studentUserId);
        this._userNotification = this._notificationService.getUserStatusNotification(this.studentUserId);
        this._convertToStudentAssignmentItems(assignmentsAndAssignmentRostersAndAssignmentWorks);
        this._userNotification.updated.subscribe(this._onStudentWorkUpdate, this);
        this._userNotification.start();
        this._analyticsService.sendEvent({
          eventTag: 'roster:so_opened',
          properties: {isPortfolio: !this._studentUser.userIsAnonymous}
        });
        this._loading = false;
      })
      .catch((error) => {
        this._error = error;
      });
  }

  /**
   * Indicates if initial load has completed
   * @returns {boolean}
   */
  get loading() {
    return this._loading;
  }

  /**
   * @return {null|string}
   */
  get errorMessage() {
    return this._error;
  }

  /**
   * indicates if currently selected column should be ascending or descending
   * @returns {boolean}
   */
  get isAscending() {
    return this._isAscending;
  }

  /**
   * indicates when to show carrot next to the assignment name column in table header
   * @returns {boolean}
   */
  get showAssignmentCarrot() {
    return this._orderByColumn === StudentOverviewTableColumns.ASSIGNMENT_NAME;
  }


  /**
   * indicates when to show carrot next to the start date column in table header
   * @returns {boolean}
   */
  get showDateCarrot() {
    return this._orderByColumn === StudentOverviewTableColumns.START_DATE;
  }

  /**
   * indicates when to show carrot next to the slides viewed column in table header
   * @returns {boolean}
   */
  get showProgressCarrot() {
    return this._orderByColumn === StudentOverviewTableColumns.SLIDES;
  }

  /**
   * indicates when to show carrot next to the grade column in table header
   * @returns {boolean}
   */
  get showGradeCarrot() {
    return this._orderByColumn === StudentOverviewTableColumns.GRADE;
  }

  /**
   * indicates when to show carrot next to the help request column in table header
   * @returns {boolean}
   */
  get showHelpsCarrot() {
    return this._orderByColumn === StudentOverviewTableColumns.HELPS;
  }

  /**
   * @returns {VirtualCollection}
   */
  get virtualStudentAssignmentsToBeDisplayed() {
    return this._virtualStudentAssignmentsToBeDisplayed;
  }
  /**
   * @returns {User}
   */
  get studentUser() {
    return this._studentUser;
  }

  /**
   * @returns {Array<Assignments>}
   */
  get assignments() {
    return this._assignments;
  }

  /**
   * @param assignmentsAndAssignmentRostersAndAssignmentWorks {Object.<{assignments: Map<string, Assignment>, works: Map<string, AssignmentWork>, assignmentRosters: Map<string, AssignmentRoster>}>}
   */
  _convertToStudentAssignmentItems(assignmentsAndAssignmentRostersAndAssignmentWorks) {
    this._assignments = [...assignmentsAndAssignmentRostersAndAssignmentWorks.assignments.values()];
    this._assignmentsMap = new Map(this._assignments.map((assignment) => [assignment.id, assignment]));
    this._assignmentWorks = [...assignmentsAndAssignmentRostersAndAssignmentWorks.works.values()];

    this._assignments.map((assignment) => {

      let helpRequestSet = new UserListItem(
        this._studentUser,
        this._userNotification,
        this._helpRequestSet,
        this._assignmentsMap,
        this._assignmentService
      ).start().getHelpRequestsByAssignmentId(assignment.id);

      this._studentAssignmentsToBeDisplayed.set(assignment.id, new StudentOverviewAssignmentItem(assignment, helpRequestSet));

    });

    this._assignmentWorks.map((work) => {
      let studentAssignmentItem = this._studentAssignmentsToBeDisplayed.get(work.assignment.id);
      if (studentAssignmentItem) {
        studentAssignmentItem.assignmentWork = new StudentAssignmentItem(
          work,
          this._roster,
          work.assignmentRoster,
          () => {},
          this._notificationService,
          this._assignmentWorkService,
          this._feedbackService
        );
      }
    });

    this._studentAssignmentsToBeDisplayedList = [...this._studentAssignmentsToBeDisplayed.values()];
    this._virtualStudentAssignmentsToBeDisplayed = new VirtualCollection(this._studentAssignmentsToBeDisplayedList, 50);
    this._applyAllFiltersAndSorting();
  }

  /**
   * @param update {UserStatus}
   */
  _onStudentWorkUpdate(update) {
    //this is to stop the callback from calling multiple times and only update when user is moving through slides
    if (this._currentQuestionId !== update.questionId) {
      this._cacheService.getStudentAssignmentsAndWorks(this.studentUserId, this.rosterId, true)
        .then((assignmentsAndAssignmentRostersAndAssignmentWorks) => {
          this._convertToStudentAssignmentItems(assignmentsAndAssignmentRostersAndAssignmentWorks);
        });
      this._currentQuestionId = update.questionId;
    }
  }

  /**
   * @param item {StudentOverviewAssignmentItem}
   * @return {number}
   */
  _convertGradesToNumbers(item) {
    if (item.assignmentWork && item.assignmentWork.studentTotalScore === '--' || !item.assignmentWork) {
      if (item.assignment.totalPotentialPoints() === 0) {
        return -1;
      }
      return (-1 / item.assignment.totalPotentialPoints());
    }
    return (item.assignmentWork.studentTotalScore / item.assignment.totalPotentialPoints());
  }

  /**
   * @param item {StudentOverviewAssignmentItem}
   * @return {moment|number}
   */
  _compareDates(item) {
    if (item.getStartDate() !== '--') {
      return item.assignmentWork.assignment.questionForIndex(0).startedAt;
    }
    return 0;
  }

  /**
   * Applies search filter and sorting
   */
  _applyAllFiltersAndSorting() {
    let sort = (a, b) => {
      let result;
      let propertyA = this._orderBy(a);
      let propertyB = this._orderBy(b);
      if (propertyA > propertyB) {
        result = 1;
      } else if (propertyA === propertyB) {
        result = 0;
      } else {
        result = -1;
      }
      return this._isAscending ? result : -result;
    };

    this._virtualStudentAssignmentsToBeDisplayed.fullCollection = this._studentAssignmentsToBeDisplayedList
      .filter((item) => this._findMatchingAssignments(item, this.query)).sort(sort);
  }

  /**
   * @param item {StudentOverviewAssignmentItem}
   * @returns {string|number}
   */
  _orderBy(item) {
    switch (this._orderByColumn) {
      case StudentOverviewTableColumns.START_DATE:
        return this._compareDates(item);
      case StudentOverviewTableColumns.SLIDES:
        return (item.getSlidesViewed() / item.totalQuestions);
      case StudentOverviewTableColumns.HELPS:
        return item.helpRequestSet.requests.length;
      case StudentOverviewTableColumns.GRADE:
        return this._convertGradesToNumbers(item);
      default:
        return item.assignment.name.toLowerCase();
    }
  }

  /**
   * @param item {StudentOverviewAssignmentItem}
   * @param query {string}
   * @return {boolean}
   */
  _findMatchingAssignments(item, query) {
    this._storageService.studentOverviewQuery = query;
    return item.assignment.name.toLowerCase().includes(query.toLowerCase());
  }

  /**
   * @param assignmentId {string}
   * @return {Promise<AssignmentWork>}
   */
  _createStudentWork(assignmentId) {
    const assignment = this._studentAssignmentsToBeDisplayed.get(assignmentId).assignment;
    return this._assignmentWorkService.getOrCreateForOther(assignment, this.rosterId, this.studentUserId)
      .then((work) => {
        return work;
      });
  }

  /**
   * @param assignmentId {string}
   * @return {Promise<AssignmentWork>}
   */
  _getOrCreateWork(assignmentId) {
    let work = this._studentAssignmentsToBeDisplayed.get(assignmentId).assignmentWork
      && this._studentAssignmentsToBeDisplayed.get(assignmentId).assignmentWork.assignment;
    return work ? this.$q.resolve(work) : this._createStudentWork(assignmentId);
  }

  /**
   * @param assignmentWorkId {string}
   * @param questionId {string}
   * @param assignmentId {string}
   * @param rosterId {string}
   */
  _navToFeedbackQuestion(assignmentWorkId, questionId, assignmentId, rosterId) {
    this._breadcrumbService.go(
      'root.account.session.work.roster',
      {
        assignmentId: assignmentId,
        assignmentWorkId: assignmentWorkId,
        questionId: questionId,
        rosterId: rosterId
      },
      false,
      {
        reload: false
      }
    );
  }

  /**
   *
   * @param contractsUsers {Map.<String, Map.<String, User>>}
   * @param userId {string}
   * @return {string}
   * @private
   */
  _userIsInContracts(contractsUsers, userId) {
    return Array.from(contractsUsers.keys())
      .find((contractId) => contractsUsers.get(contractId).has(userId));
  }

  _editProUser() {
    this._cacheService.getUsersForContracts()
      .then((contractIdToUsers) => {

        const contractId = this._userIsInContracts(contractIdToUsers, this.studentUser.id);
        if (contractId) {
          return contractId;
        } else {
          return this._cacheService.getUsersForContracts(true)
            .then((secondTryWithFreshData) => {
              return this._userIsInContracts(secondTryWithFreshData, this.studentUser.id);
            });
        }
      })
      .then((contractId) => {
        if (!contractId) {
          this._loading = false;
          this._confirmDialog(this.$mdDialog, "Sorry, that user isn't a member of your organization.", undefined, true);
          return;
        }

        this._breadcrumbService.go(
          'root.account.contract-user',
          {
            contractId: contractId,
            userId: this.studentUser.id
          }
        );
      })
      .catch((err) => {
        this._loading = false;
        this._confirmDialog(this.$mdDialog, 'Sorry, there was a problem loading this user.', undefined, true);
        this.$log.error(err);
      });
  }

  _destroy() {
    if (this._userNotification) {
      this._userNotification.updated.unsubscribe(this._onStudentWorkUpdate, this);
    }
  }

  sortByAssignmentName(){
    this.setOrToggleColumn(StudentOverviewTableColumns.ASSIGNMENT_NAME);
  }


  sortByStartDate(){
    this.setOrToggleColumn(StudentOverviewTableColumns.START_DATE, false);
  }

  sortBySlidesProgress(){
    this.setOrToggleColumn(StudentOverviewTableColumns.SLIDES);
  }

  sortByGrade(){
    this.setOrToggleColumn(StudentOverviewTableColumns.GRADE);
  }

  sortByHelpRequests(){
    this.setOrToggleColumn(StudentOverviewTableColumns.HELPS, false);
  }

  /**
   * sets the list order to the selected column and/or toggles ascending/descending and
   * handles the logic to change the focus and order of columns
   *
   * @param colName {string} one of the TableColumn properties
   * @param shouldAscend {boolean} should the column be ascending or descending
   */
  setOrToggleColumn(colName, shouldAscend = true) {
    if (this._orderByColumn !== colName) {
      this._orderByColumn = colName;
      this._isAscending = shouldAscend;
    } else {
      this._isAscending = !this._isAscending;
    }
    this._storageService.studentOverviewSortingProperty = {
      column: this._orderByColumn,
      isAscending: this._isAscending
    };
    this._applyAllFiltersAndSorting();
  }

  filterByAssignmentQuery() {
    this._applyAllFiltersAndSorting();
  }

  /**
   * @param assignmentId {string}
   */
  goToStudentAssignmentWork(assignmentId) {
    const questionId = this._studentAssignmentsToBeDisplayed.get(assignmentId).assignment.questions[0].id;
    this._getOrCreateWork(assignmentId)
      .then((work) => {
        this._navToFeedbackQuestion(work.id, questionId, assignmentId, this.rosterId);
      })
      .catch((error) => {
        this.error = error;
    });
  }

  goBack() {
    this._breadcrumbService.goBack('root.account.nav.roster', {
      rosterId: this.rosterId
    });
  }

  editUser() {
    this._loading = true;
    this._editProUser();
  }

  exportGrades(){
    this._assignmentManager.exportGradesForSingleStudent(this._assignments, this._studentAssignmentsToBeDisplayed, this._roster, this.studentUser);
  }

  exportAnswers(){
    this._assignmentManager.exportAnswersForSingleStudent(this._assignments, this._studentAssignmentsToBeDisplayed, this._roster, this.studentUser);
  }

  exportWorkToPDF() {
    let worksMap = new Map(this._assignmentWorks.map((work) => [work.assignment.id, work]));
    this._assignmentManager.exportOverviewWorkToPDF(worksMap, this.studentUser, this.virtualStudentAssignmentsToBeDisplayed._fullCollection);
  }

  goToOrderPage() {
    this._analyticsService.subscriptionFunnel(
      SubscriptionFunnel.LEARN_MORE,
      undefined,
      undefined,
      PaywallSources.STUDENT_OVERVIEW_PAYWALL
    );
    this._staticContentService.goToOrdersPage(this._logRocketService.initialized);
  }
}
