/* eslint-disable angular/document-service */

'use strict';

import Point from '../../model/ui/point';
import Size from '../../model/ui/size';
import MimeTypes from '../../model/domain/mime-types';
import LazyVar from '../../model/util/lazy-var';
import Debouncer from '../../model/util/debouncer';
import { CropResult } from '../../model/ui/elements/image-cropper';
import ErrorDialogController from '../error-dialog/error-dialog.controller';
import ImportImageDialogTemplate from './import-image-dialog.html';

export class QuestionDisplay {
  /**
   * @param questionId {string}
   * @param index {number}
   * @param [isCurrent] {boolean}
   * @param [isNew] {boolean} indicates this is a new question and the questionId is a temporary value
   * @param [beforeQuestionId] {string} If representing a new question, this marks the question which
   *  this new one shall precede
   */
  constructor(questionId, index, isCurrent, isNew, beforeQuestionId) {
    this.questionId = questionId;
    this.index = index;
    this.isCurrent = isCurrent;
    this.isNew = isNew || false;
    this.beforeQuestionId = beforeQuestionId;
  }

  get name() {
    return `Q${this.index + 1}${this.isCurrent ? ' (Current Page)' : ''}${this.isNew ? ' (New)' : ''}`;
  }
}

export class ImageImport {
  /**
   * @param imageSrc {string}
   * @param page {int}
   * @param cropResult {CropResult}
   * @param destinations {QuestionDisplay[]} Collection of destination questions
   * @param isManipulative {boolean}
   * @param originalFile {File}
   */
  constructor(imageSrc, page, cropResult, destinations, isManipulative, originalFile) {
    this._imageSrc = imageSrc;
    this._page = page;
    this._cropResult = cropResult;
    this._destinations = destinations;
    this._isManipulative = isManipulative;
    this._originalFile = originalFile;
  }

  /**
   * @returns {string}
   */
  get imageSrc() {
    return this._imageSrc;
  }

  get page() {
    return this._page;
  }

  /**
   * @returns {CropResult}
   */
  get cropResult() {
    return this._cropResult;
  }

  /**
   * @returns {QuestionDisplay[]}
   */
  get destinations() {
    return this._destinations;
  }

  /**
   * @returns {string}
   */
  get destinationNames() {
    return this._destinations.map((x) => x.name).join(', ');
  }

  /**
   * @return {boolean}
   */
  get isManipulative() {
    return this._isManipulative;
  }

  /**
   * @return {File}
   */
  get originalFile() {
    return this._originalFile;
  }
}

/**
 * Controller used to import and crop images / pdfs
 */
export default class ImportImageDialogController {
  /**
   * @ngInject
   */
  constructor($q, $mdDialog, $scope, $timeout, ImageEditService, questions, allowChooseDestinations,
    allowNewQuestions, currentQuestionId, isManipulative, originalFile) {

    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this.$scope = $scope;
    this.$timeout = $timeout;

    /** @type {ImageEditService} */
    this._imageEditService = ImageEditService;

    /** @type {AssignmentQuestion[]} */
    this._questions = questions;
    this._currentQuestionId = currentQuestionId;
    this._allowNewQuestions = allowNewQuestions;
    this._allowChooseDestinations = allowChooseDestinations;
    this._isManipulative = isManipulative;

    /** @type {File} */
    this.originalFile = originalFile;
    /** @type {LazyVar.<Image|PDFDocumentProxy>} */
    this._source = new LazyVar(true);

    this.numPages = 1;
    this.currentPage = 1;

    this._cropPager = new Debouncer(250, 10000, () => this.loadImage());
    /** @type {Image} */
    this._cropSource = undefined;
    /** @type {CropResult} */
    this.cropResult = undefined;
    /** @type {String} */
    this._croppedImageSrc = undefined;

    this._state = undefined;

    this._newQuestionEnd = { name: 'New Question' };
    this._newQuestionBefore = { name: 'New Question Before' };

    this.availableDestinations = questions ? this._initialDestinations(questions) : [];
    this.defaultDestinations = [];
    if (this._currentQuestionId) {
      this.defaultDestinations.push(this.availableDestinations.find((q) => q.questionId === this._currentQuestionId));
    }
    else if (this._allowNewQuestions) {
      this.defaultDestinations.push(this._newQuestionEnd);
    }

    /** @type {ImageImport[]} */
    this.selectedImports = [];

    this._errorDialog = ErrorDialogController.show;

    this.$scope.$on('$destroy', () => this._destroy());

    this._init();
  }

  _init() {
    if (MimeTypes.ImageSupport.includes(this.originalFile.type)) {
      if (this.originalFile.type === MimeTypes.IMAGE_GIF) {
        this.to(this.CHOOSE_DESTINATIONS);
      }
      else {
        this.to(this.CROP);
        this.debouncedLoadImage(1);
      }
    }
    else {
      this._errorDialog(this.$mdDialog, 'The selected file is not supported.', 'We support the following file types: BMP, GIF, JPG/JPEG, PDF, PNG');
    }
  }

  /**
   * @param questions {AssignmentQuestion[]}
   * @returns {Array.<QuestionDisplay>}
   */
  _initialDestinations(questions) {
    return questions.map(
      /**
       * @param q {AssignmentQuestion}
       * @param index {int}
       */
      (q, index) => {
        return new QuestionDisplay(
          q.id,
          index,
          q.id === this._currentQuestionId
        );
      }).concat(this._allowNewQuestions ? [this._newQuestionEnd] : []);
  }

  /**
   * @return {boolean}
   */
  get isManipulative() {
    return this._isManipulative;
  }

  /**
   * @returns {string}
   */
  get state() {
    return this._state;
  }

  /**
   * Transitions dialog to the selected state
   * @param state {string}
   */
  to(state) {
    if (state === this.CHOOSE_DESTINATIONS) {
      this.cropImage();
      if (!this._allowChooseDestinations) {
        if (this._questions) {
          state = this.REVIEW;
        }
        else {
          this.resolveAvailableQuestions();
          this.cropSelectAll();
          this.finish();
        }
      }
    }
    this._state = state;

    if (this._state === this.CROP) {
      this._croppedImageSrc = undefined;
    }
    else if (this._state === this.REVIEW) {
      this.resolveAvailableQuestions();
      this.cropSelectAll();
    }
  }

  //----------------- File Select Functions ---------------------------

  /**
   * @param $q
   * @param supportedMimeTypes
   * @return {Promise}
   */
  static selectFile($q, supportedMimeTypes) {
    let deferred = $q.defer();

    let input = angular.element(`<input type="file" accept="${supportedMimeTypes}"/>`);

    input.on('change', (event) => {
      deferred.resolve(event.target.files[0]);
    });

    input.click();

    return deferred.promise;
  }

  get isPdf() {
    return this.originalFile && this.originalFile.type === MimeTypes.APPLICATION_PDF;
  }

  get isGif() {
    return this.originalFile && this.originalFile.type === MimeTypes.IMAGE_GIF;
  }

  //----------------- Cropper Functions ---------------------------

  /**
   * @returns {Promise.<Image|PDFDocumentProxy>}
   */
  get source() {
    return this._source.value(() => {
      if (this.isPdf) {
        return this._imageEditService.pdfFromFile(this.originalFile)
          .then((pdf) => {
            this.numPages = pdf.numPages;
            return pdf;
          });
      }
      else if (this.isGif) {
        let img = document.createElement('img');
        img.src = URL.createObjectURL(this.originalFile);
        return this.$q.resolve(img);
      }
      else {
        return this._imageEditService.imageFromFile(this.originalFile);
      }
    });
  }

  /**
   * @param page {int}
   */
  debouncedLoadImage(page) {

    this.currentPage = page || this.currentPage;
    this.cropSource = null;
    this._cropPager.tick();
  }

  /**
   * @param page {int}
   * @returns {Image}
   */
  loadImage(page) {
    this.currentPage = page || this.currentPage;
    this.cropSource = null;

    let promise = this.source;
    if (this.isPdf) {
      promise = promise
        .then((pdf) => {
          return this._imageEditService.imageFromPdfPage(pdf, this.currentPage);
        });
    }

    return promise
      .then((image) => {
        this.cropSource = image;
        return image;
      })
      .catch((err) => {
        this._errorDialog(this.$mdDialog, 'There was an error opening the selected file', '');
      });
  }

  crop_hasNext() {
    return this.currentPage < this.numPages;
  }

  crop_hasPrev() {
    return this.currentPage > 1;
  }

  crop_next() {
    this.debouncedLoadImage(this.currentPage + 1);
  }

  crop_prev() {
    this.debouncedLoadImage(this.currentPage - 1);
  }

  /**
   * @param value {Image}
   */
  set cropSource(value) {
    this._cropSource = value;
    this.cropResult = null;
  }

  /**
   * @returns {Image}
   */
  get cropSource() {
    return this._cropSource;
  }

  get hasCropSource() {
    return !!this._cropSource;
  }

  cropSelectAll() {
    this.cropResult = null;
  }

  cropImage() {

    if (this.isGif) {
      this._croppedImageSrc = URL.createObjectURL(this.originalFile);
    }
    else {
      // If selected size is too big, scale it down
      const scale = Math.min(1, this._imageEditService.canvasFitSize.fitScale(this.cropResult.selectedSize));
      this._croppedImageSrc = this._imageEditService.crop(this.cropSource, this.cropResult.selectedLocation.times(scale), this.cropResult.selectedSize.times(scale), scale);
    }

    if (this.currentSelection) {
      this.currentSelection._currentPage = this.currentPage;
      this.currentSelection._cropResult = this.cropResult;
      this.currentSelection._imageSrc = this._croppedImageSrc;
    }
    else {
      this.currentSelection = new ImageImport(
        this._croppedImageSrc,
        this.currentPage,
        this.cropResult,
        this.defaultDestinations.slice(0),
        this._isManipulative,
        this.originalFile
      );
    }
  }

  cleanup() {
    if (this.isPdf && this._source.isSet) {
      this.source
        .then((source) => {
          source.destroy();
        });
    }
    this._source.clear();
    this.cropSource = null;
    this.currentPage = 1;
  }

  importWholePdf() {

    this.to(this.LOAD_PDF);

    return this.importPdfPages(1, this.numPages)
      .finally(() => {
        this.to(this.REVIEW);
      });
  }

  /**
   * @param startPage {int}
   * @param endPage {int}
   * @returns {Promise}
   */
  importPdfPages(startPage, endPage) {
    if (startPage > endPage) {
      return this.$q.resolve();
    }

    return this.importPdfPage(startPage)
      .then(() => {
        return this.importPdfPages(startPage + 1, endPage);
      });
  }

  /**
   * @param page {int}
   * @returns {Promise}
   */
  importPdfPage(page) {
    return this.loadImage(page)
      .then(/** @param image {Image} */(image) => {
        const imageSize = new Size(image.naturalWidth, image.naturalHeight);

        let questionDisplay = this._newQuestionEnd;

        if (this._currentQuestionId) {
          questionDisplay = this._newQuestionBefore;
        }

        this.currentSelection = new ImageImport(
          image.src,
          page,
          new CropResult(
            imageSize,
            new Point(0, 0),
            imageSize
          ),
          [questionDisplay]
        );
        this.resolveAvailableQuestions();
      });
  }

  get canImportWholePdf() {
    return this._allowNewQuestions && this.isPdf && !this.hasWork && this.numPages > 1;
  }

  //----------------- Destination Chooser Functions ---------------------------

  get croppedImageSrc() {
    return this._croppedImageSrc;
  }

  //----------------- Review Functions --------------------------------------

  resolveAvailableQuestions() {
    if (this.currentSelection) {
      // Add a new question to end if necessary
      const newIdx = this.currentSelection.destinations.indexOf(this._newQuestionEnd);
      if (newIdx !== -1) {
        const newQuestion = new QuestionDisplay(Math.random().toString(), this.availableDestinations.length - 1, false, true);
        this.availableDestinations.splice(this.availableDestinations.length - 1, 0, newQuestion);
        this.currentSelection.destinations[newIdx] = newQuestion;
      }

      // Add new question after the current question if necessary
      const newQuestionBeforeIdx = this.currentSelection.destinations.indexOf(this._newQuestionBefore);
      if (newQuestionBeforeIdx !== -1) {
        const currentQuestionDestination = this.availableDestinations.find((q) => q.isCurrent);
        const beforeQuestionDestination = this._beforeQuestionDestination(currentQuestionDestination);
        const nextQuestion = this._questions[currentQuestionDestination.index + 1];
        const newQuestion = new QuestionDisplay(
          Math.random().toString(),
          beforeQuestionDestination.index + 1,
          false,
          true,
          nextQuestion && nextQuestion.id
        );
        this.availableDestinations.splice(beforeQuestionDestination.index + 1, 0, newQuestion);
        this.currentSelection.destinations[0] = newQuestion;
      }

      // Sort the destinations for the current selection
      this.currentSelection.destinations.sort((a, b) => a.index > b.index);

      if (!this.selectedImports.includes(this.currentSelection)) {
        this.selectedImports.push(this.currentSelection);
      }

      this.currentSelection = null;
    }

    // Get the collection of new questions which appear in all the imports
    const newQuestions = new Set(this.selectedImports.reduce((p, v) => {
      return p.concat(v.destinations.filter((q) => q.isNew));
    }, []));

    // Remove any now unused new questions
    this.availableDestinations = this.availableDestinations
      .filter(/** @param q {QuestionDisplay} */(q) => {
        if (q.isNew) {
          return newQuestions.has(q);
        }
        return true;
      });

    // Reset the question indices according to the above changes
    let reindex = 0;
    this.availableDestinations.forEach(/** @param q {QuestionDisplay} */(q) => {
      if (q !== this._newQuestionEnd) {
        q.index = reindex;
        reindex++;
      }
    });

  }

  /**
   * @param questionDestination {QuestionDisplay}
   * @return {QuestionDisplay}
   */
  _beforeQuestionDestination(questionDestination) {
    let nextQuestionDestination = this.availableDestinations[questionDestination.index + 1];

    if (!nextQuestionDestination || nextQuestionDestination === this._newQuestionEnd || !nextQuestionDestination.isNew) {
      return questionDestination;
    }
    else {
      return this._beforeQuestionDestination(nextQuestionDestination);
    }
  }

  removeSelection(selection) {
    this.selectedImports = this.selectedImports.filter((x) => x !== selection);
    this.resolveAvailableQuestions();
  }

  editSelection(selection) {
    this.currentSelection = selection;
    this.to(this.CROP);
    this.loadImage(this.currentSelection.page)
      .then(() => {
        this.cropResult = this.currentSelection.cropResult;
      });
  }

  get hasWork() {
    return this.selectedImports.length > 0;
  }

  /**
   * Completes the work done in this dialog
   */
  finish() {
    this.$mdDialog.hide({
      newQuestions: this.availableDestinations.filter(/** @param d {QuestionDisplay} */(d) => d.isNew),
      images: this.selectedImports
    });
  }

  //----------------- Verify Cancel Functions --------------------------------------

  /**
   * @param [force] {boolean}
   */
  cancel(force) {
    if (force || !this.hasWork) {
      this.$mdDialog.cancel();
    }
    else {
      this._resumeState = this._state;
      this.to(this.CANCEL_CHECK);
    }
  }

  resume() {
    if (this._resumeState) {
      this.to(this._resumeState);
      this._resumeState = undefined;
    }
  }

  _destroy() {
    this.cleanup();
  }

  /**
   * TODO refactor the image import dialog into two separate dialogs. One that is intended to place the image on the canvas
   * and allows the user to crop the image and create/select the slides to add the images to and another that only allows
   * the user to crop (used for adding images to stickers, backgrounds, etc.
   *
   * @param $q
   * @param $mdDialog
   * @param questions {AssignmentQuestion[]}
   * @param allowChooseDestinations {boolean}
   * @param allowNewQuestions {boolean}
   * @param [currentQuestionId] {string}
   * @param isManipulative {boolean}
   * @param [supportedMimeTypes] {string[]}
   *
   * @returns {Promise.<{newQuestions: QuestionDisplay[], images: ImageImport[]}>}
   */
  static show($q, $mdDialog, questions, allowChooseDestinations, allowNewQuestions, currentQuestionId, isManipulative, supportedMimeTypes = MimeTypes.ImageSupport, file) {
    let promise;

    if (angular.isDefined(file)) {
      promise = $q.resolve(file);
    } else {
      promise = ImportImageDialogController.selectFile($q, supportedMimeTypes);
    }

    return promise
      .then((originalFile) => {
        if (originalFile.type === MimeTypes.IMAGE_GIF && !allowChooseDestinations && !allowNewQuestions) {

          let destinations = currentQuestionId ?
            [new QuestionDisplay(
              currentQuestionId,
              questions && questions.findIndex((q) => q.id === currentQuestionId),
              true,
              false,
              undefined
            )] :
            undefined;

          return $q.resolve({
            images: [new ImageImport(
              URL.createObjectURL(originalFile),
              undefined,
              undefined,
              destinations,
              undefined,
              originalFile
            )],
            newQuestions: []
          });
        }

        return $mdDialog.show({
          controller: ImportImageDialogController,
          template: ImportImageDialogTemplate,
          controllerAs: 'ctrl',
          clickOutsideToClose: false,
          escapeToClose: false,
          locals: {
            questions,
            allowChooseDestinations,
            allowNewQuestions,
            currentQuestionId,
            isManipulative,
            originalFile
          }
        });
      });
  }

  /** @returns {string} */
  get CROP() {
    return 'crop';
  }
  /** @returns {string} */
  get CHOOSE_DESTINATIONS() {
    return 'make-selection';
  }
  /** @returns {string} */
  get REVIEW() {
    return 'review';
  }
  /** @returns {string} */
  get CANCEL_CHECK() {
    return 'cancel-check';
  }
  /** @returns {string} */
  get LOAD_PDF() {
    return 'load-pdf';
  }
}
