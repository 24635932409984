'use strict';

import Codec from './codec';
import GradebookAssignmentLinkStatus from '../domain/gradebook-assignment-link-status';
import GradebookAssignmentCategoryCodec from './gradebook-assignment-category-codec';
import moment from 'moment';

/**
 * Encodes/decodes Gradebook integration status
 */
export default class GradebookAssignmentLinkStatusCodec extends Codec {
  constructor() {
    super();

    this._assignmentCategoryCodec = new GradebookAssignmentCategoryCodec();
  }

  /**
   * @param value {GradebookAssignmentLinkStatus}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      const selectedCategory = value.selectedCategory && this._assignmentCategoryCodec.encode(value.selectedCategory) || null;
      return {
        selected_assignment_id: value.selectedAssignmentId,
        selected_category: selectedCategory,
        scaled_points: value.scaledPoints,
        due_date: value.dueDate ? value.dueDate.toISOString() : null
      };
    }
    return null;
  }

  /**
   * @param value {object}
   * @returns {GradebookAssignmentLinkStatus}
   */
  decode(value) {
    if (value) {
      const selectedCategory = value.selected_category && this._assignmentCategoryCodec.decode(value.selected_category) || null;
      return new GradebookAssignmentLinkStatus(
        value.selected_assignment_id ? value.selected_assignment_id : null,
        selectedCategory,
        value.scaled_points ? value.scaled_points : null,
        value.due_date ? value.due_date : null
      );
    }

    return null;
  }
}
