
'use strict';

import { Colors } from '../../model/domain/colors';
import ColorMenuTemplate from './color-menu.html';

/**
 * Dialog to select color
 */
export default class ColorMenuController {

  /**
   * @param mdPanelRef {$mdPanelRef} a reference to the currently open panel
   * @param result {Deferred} a deferred promise to be resolved when color is selected
   * @param colors {Array} rosterColors|penColors|textColors
   */
  constructor(mdPanelRef, result, colors) {
    'ngInject';
    this._mdPanelRef = mdPanelRef;
    this._result = result;
    this._colors = colors;
  }

  /**
   * @returns {string} the id of the element to attach the menu
   */
  static get SELECTOR() {
    return 'ck-color-menu';
  }

  /**
   * @returns {string} the id of the element to attach the menu
   */
  static get SELECTOR_WIDE() {
    return 'ck-color-menu-wide';
  }

  /**
   * @returns {Array.<Object>}
   */
  get colors() {
    return this._colors;
  }

  /**
   * @param value {Array.<Object>}
   */
  set colors(value) {
    this._colors = value;
  }

  style(color) {
    return {
      'background-color':color.hex,
      'border': color.name === 'white' ? '1px solid #eeeeef' : ''
    };
  }

  /**
   * Selects color from menu and closes menu
   *
   * @param color {object} color object with properties for name and hex value
   */
  select(color) {
    this._result.resolve(color);
    this._mdPanelRef.hide(color);
  }

  /**
   * Shows color menu dialog
   *
   * @param $q {$q} angular promise service
   * @param $mdPanel {$mdPanel} angular material panel service
   * @param $mdMedia {$mdMedia} angular material media service
   * @param selector {string} The target element for the panel
   * @returns {Promise}
   */
  static show($q, $mdPanel, $mdMedia, selector, colors, isWide) {

    colors = colors || Colors.ROSTER_COLORS;
    let panelSelector = isWide ? ColorMenuController.SELECTOR_WIDE : ColorMenuController.SELECTOR;

    let deferred = $q.defer();

    let position = $mdPanel
      .newPanelPosition()
      .relativeTo(selector);

    // If the window is narrow and the picker is wide, open the picker to the left so it's not cut off by the window.
    if (isWide && $mdMedia('max-width: 800px')) {
      position = position.addPanelPosition($mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.BELOW);
    } else {
      position = position.addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);
    }

    let animation = $mdPanel
      .newPanelAnimation()
      .openFrom(selector)
      .closeTo(selector)
      .withAnimation($mdPanel.animation.FADE);

    let config = {
      position: position,
      animation: animation,
      controller: ColorMenuController,
      controllerAs: 'ctrl',
      template: ColorMenuTemplate,
      panelClass: panelSelector,
      clickOutsideToClose: true,
      clickEscapeToClose: true,
      focusOnOpen: true,
      locals: {
        result: deferred,
        colors: colors
      },
      onRemoving: () => {
        deferred.reject();
      }
    };

    $mdPanel.open(config);

    return deferred.promise;
  }
}

