'use strict';

import SignUpComponentTemplate from '../sign-up-component/sign-up.component.html';
import { AccountCreationStep } from '../../services/mixpanel/mixpanel.service';
import { UserRoles } from '../../model/domain/user';
import ErrorCodes from '../../model/domain/error-codes';
import { AuthType } from '../../services/auth/auth.service';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import CkRedirect from '../../model/domain/ck-redirect';
import googleIcon from '../../../assets/images/google-icon.svg';
import cleverIcon from '../../../assets/images/clever-logo.svg';
import Validation from '../../model/util/validation';

class SignUpComponentController {

  constructor($q, $scope, $state, $stateParams, $log, $window, $mdDialog, $mdMedia, $timeout, environment, BreadcrumbService,
              StaticContentService, AuthService, StaticService, ClassCodeService,
              StorageService, CampaignService, AnalyticsService, PendoService) {
    'ngInject';

    this.$q = $q;
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$log = $log;
    this.$mdDialog = $mdDialog;
    this.$mdMedia = $mdMedia;
    this.$timeout = $timeout;
    this._environment = environment;
    this.$window = $window;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StaticService} */
    this._staticService = StaticService;
    /** @type {ClassCodeService} */
    this._classCodeService = ClassCodeService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {CampaignService} */
    this._campaignService = CampaignService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {PendoService} */
    this._pendoService = PendoService;

    this._state = this.Choice;
    this._classCodeText = this.$stateParams.classCode || '';
    this._firstName = '';
    this._lastName = '';
    this._email = '';
    this._emailOrUsername = '';
    this._password = '';
    this._passwordConfirm = '';
    this._showPassword = false;
    this._rememberMe = true;
    this._emailPattern = Validation.EmailPattern;
    this._hasSentAnalyticsEmail = false;
    this._hasSentAnalyticsFirstName = false;
    this._hasSentAnalyticsLastName = false;
    this._hasSentAnalyticsPassword = false;
    this._hasSentAnalyticsClassCode = false;

    this._confirmDialog = ConfirmDialogController.show;
    this.googleIcon = googleIcon;
    this.cleverIcon = cleverIcon;

    if (this.isTeacher && this._staticService.isMobile && this.$mdMedia('xs')) {
      let message = 'There is no Classkick phone app for teachers. <br><br> The Classkick app is available on iPad or on any computer in a Google Chrome browser.';
      this._confirmDialog(this.$mdDialog, message, undefined, true, true);
    }
    else if (this.isStudent && !!this._classCodeText) {
      $timeout(() => this.classCodeUpdated());
    }

    this._getAndSaveCampaignId(this.$window.location.href);

    this._analyticsService.sendEvent({
      eventTag: 'page_viewed',
      properties: {
         location: 'signup'
      }
    });
  }

  /**
   * @return {boolean}
   */
  get isTeacher() {
    return this.$stateParams.isTeacher
        || this.$state.current.name === 'root.shared-work';
  }

  /**
   * @return {boolean}
   */
  get isStudent() {
    return this.$stateParams.isStudent;
  }

  /**
   * @return {string} loading | signup_choice | signup_password
   */
  get state() {
    return this._state;
  }

  /**
   * @param value {string} loading | signup_choice | signup_password
   */
  set state(value) {
    this._state = value;
  }

  get Loading() {
    return 'loading';
  }

  get Choice() {
    return 'signup_choice';
  }

  get Password() {
    return 'signup_password';
  }

  /**
   * @return {boolean}
   */
  get isLoading() {
    return this._state === this.Loading;
  }

  /**
   * @return {boolean}
   */
  get showFooter() {
    return this.isTeacher || this.isStudent;
  }

  /**
   * @return {boolean}
   */
  get isChoice() {
    return this._state === this.Choice;
  }

  /**
   * @return {boolean}
   */
  get isPassword() {
    return this._state === this.Password;
  }

  /**
   * @return {string}
   */
  get email() {
    return this._email;
  }

  /**
   * @param value {string}
   */
  set email(value) {
    this._email = value;
  }

  /**
   * @return {string}
   */
  get firstName() {
    return this._firstName;
  }

  /**
   * @param value {string}
   */
  set firstName(value) {
    this._firstName = value;
  }

  /**
   * @return {string}
   */
  get lastName() {
    return this._lastName;
  }

  /**
   * @param value {string}
   */
  set lastName(value) {
    this._lastName = value;
  }

  /**
   * @return {string}
   */
  get emailOrUsername() {
    return this._emailOrUsername;
  }

  /**
   * @param value {string}
   */
  set emailOrUsername(value) {
    this._emailOrUsername = value;
  }

  /**
   * @return {string}
   */
  get password() {
    return this._password;
  }

  /**
   * @param value {string}
   */
  set password(value) {
    this._password = value;
  }

  /**
   * @return {string}
   */
  get passwordConfirm() {
    return this._passwordConfirm;
  }

  /**
   * @param value {string}
   */
  set passwordConfirm(value) {
    this._passwordConfirm = value;
  }

  /**
   * @return {boolean}
   */
  get showPassword() {
    return this._showPassword;
  }

  /**
   * @param value {boolean}
   */
  set showPassword(value) {
    this._showPassword = value;
  }

  /**
   * @return {boolean}
   */
  get rememberMe() {
    return this._rememberMe;
  }

  /**
   * @param value {boolean}
   */
  set rememberMe(value) {
    this._rememberMe = value;
  }

  /**
   * @return {string}
   */
  get emailPattern() {
    return this._emailPattern;
  }

  /**
   * @return {string}
   */
  get servicesErrorMessage() {
    return this._servicesErrorMessage;
  }

  /**
   * @param value {string}
   */
  set servicesErrorMessage(value) {
    this._servicesErrorMessage = value;
  }

  /**
   * @return {string}
   */
  get errorMessage() {
    return this._errorMessage;
  }

  /**
   * @return {boolean}
   */
  get isFromSharedWork () {
    return this.$state.current && this.$state.current.name === 'root.shared-work';
  }

  signUpWithEmail(){
    if (this.isTeacher) {
      this._analyticsService.sendEvent({
        eventTag:'teacherSignUp:email_clicked'});
    } else {
      this._analyticsService.sendEvent({
        eventTag:'studentSignUp:email_clicked'});
    }
    this.state = this.Password;
  }

  goBack() {
    if (this.isFromSharedWork) {
      return this.handleSignUpView();
    }

    if (this.isPassword) {
      this.state = this.Choice;
    }
    else {
      this._breadcrumbService.goBack('root.signup-choice');
    }
  }

  goToLogin() {
    if (this.isFromSharedWork) {
      return this.handleLoginView();
    }

    this._breadcrumbService.go('root.account-login');
  }

  goToTermsOfServicePage() {
    if (this.isTeacher) {
      this._analyticsService.sendEvent({
        eventTag:'teacherSignUp:termsOfService_clicked'});
    } else {
      this._analyticsService.sendEvent({
        eventTag:'studentSignUp:termsOfService_clicked'});
    }
    this._staticContentService.goToTermsOfServicePage();
  }

  goToPrivacyPolicyPage() {
    if (this.isTeacher) {
      this._analyticsService.sendEvent({
        eventTag:'teacherSignUp:privacyPolicy_clicked'});
    } else {
      this._analyticsService.sendEvent({
        eventTag:'studentSignUp:privacyPolicy_clicked'});
    }
    this._staticContentService.goToPrivacyPolicyPage();
  }

  createAccount() {
    if (this.$stateParams.isTeacher || this.isFromSharedWork) {
      this._analyticsService.sendEvent({
        eventTag:'teacherSignUp:signup_clicked'});
      this.createTeacherAccount();
    }
    else {
      this._analyticsService.sendEvent({
        eventTag:'studentSignUp:signup_clicked'});
      this.createStudentAccount();
    }
  }

  createTeacherAccount() {
    this._state = this.Loading;
    let formInputData = {
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName
    };

    this._authService.signUpTeacherWithEmail(formInputData, this.password, this.rememberMe)
        .then(() => {
          this._analyticsService.createAccountProgress(AccountCreationStep.STARTED);
          this._showPendoToNewUser();

          if (this.isFromSharedWork) {
            this._storageService.sharedWorkSignUpAuthType = AuthType.PASSWORD;
            this._storageService.sharedWorkPath = '';
            this._storageService.ckRedirect = null;
            return this.closeAndReload();
          } else {
            this._breadcrumbService.go('root.signup-teacher-name');
          }

        })
        .catch((error) => {
          this._handleSignupError(error);
        });
  }

  /**
   * @param error {Error}
   */
  _handleSignupError(error) {
    let message = error && error.data && error.data.message;
    this.servicesErrorMessage = message;

    /* in the instance we don't receive a message error,
    a default error dialog gets displayed with generic error message */
    if (this.isFromSharedWork && !message) {
      this.handleError({});
    }

    if (this.isTeacher && this.$scope.createAccountForm.emailInput) {
      this.$scope.createAccountForm.emailInput.$setValidity('services-error', false);
    }
    else if (this.isStudent && this.$scope.createAccountForm.emailOrUsernameInput) {
      this.$scope.createAccountForm.emailOrUsernameInput.$setValidity('services-error', false);
    }
    this._state = this.Password;
  }

  emailOrUsernameUpdated() {
    if (!this._hasSentAnalyticsEmail) {
      if (this.isTeacher) {
        this._analyticsService.sendEvent({
          eventTag:'teacherSignUp:email_typed'});
        this._hasSentAnalyticsEmail = true;
      } else {
        this._analyticsService.sendEvent({
          eventTag:'studentSignUp:email_typed'});
        this._hasSentAnalyticsEmail = true;
      }
    }

    this._setValidity('email-format', true);
    this._setValidity('email-length', true);
    this._setValidity('username-format', true);
    this._setValidity('username-length', true);
  }

  passwordUpdated() {
    if (!this._hasSentAnalyticsPassword) {
      if (this.isTeacher) {
        this._analyticsService.sendEvent({
          eventTag:'teacherSignUp:password_typed'});
        this._hasSentAnalyticsPassword = true;
      } else {
        this._analyticsService.sendEvent({
          eventTag:'studentSignUp:password_typed'});
        this._hasSentAnalyticsPassword = true;
      }
    }
  }

  passwordConfirmUpdated() {
    if (this._password !== this._passwordConfirm) {
      this.$scope.createAccountForm.passwordConfirmInput.$setValidity('nomatch', false);
    }
    else {
      this.$scope.createAccountForm.passwordConfirmInput.$setValidity('nomatch', true);
    }

  }

  firstNameUpdated() {
    if (!this._hasSentAnalyticsFirstName) {
      if (this.isTeacher) {
        this._analyticsService.sendEvent({
          eventTag: 'teacherSignUpName:firstName_typed'
        });
        this._hasSentAnalyticsFirstName = true;
      }
    }
  }

  lastNameUpdated() {
    if (!this._hasSentAnalyticsLastName) {
      if (this.isTeacher) {
        this._analyticsService.sendEvent({
          eventTag: 'teacherSignUpName:lastName_typed'
        });
        this._hasSentAnalyticsLastName = true;
      }
    }
  }

  toggleRememberMe(){
    if (this.isTeacher) {
      this._analyticsService.sendEvent({
        eventTag:'teacherSignUp:rememberMe_clicked'});
    } else {
      this._analyticsService.sendEvent({
        eventTag:'studentSignUp:rememberMe_clicked'});
    }
  }

  toggleShowPassword(){
    if (this.isTeacher) {
      this._analyticsService.sendEvent({
        eventTag:'teacherSignUp:showPassword_clicked'});
    } else {
      this._analyticsService.sendEvent({
        eventTag:'studentSignUp:showPassword_clicked'});
    }
  }

  createStudentAccount() {
    const idValidation = Validation.validateUsernameOrEmail(this.emailOrUsername);
    if (idValidation) {
      this._setValidity(idValidation, false);
      return;
    }

    this._state = this.Loading;
    let promise, classCode = this._removeSpaces(this._classCodeText);

    if (Validation.hasAtSign(this.emailOrUsername)) {
      promise = this._authService.signupStudentWithEmail(
          classCode,
          this.emailOrUsername,
          this.password,
          this.rememberMe
      );
    }
    else {
      promise = this._authService.signupStudentWithUsername(
          classCode,
          this.emailOrUsername,
          this.password,
          this.rememberMe
      );
    }

    promise
        .then(() => {
          this._analyticsService.createAccountProgress(AccountCreationStep.STARTED);

          this._storageService.ckRedirect = new CkRedirect('root.account.home', {
            classCode
          });

          this._breadcrumbService.go('root.signup-student-name');
        })
        .catch((error) => {
          this._handleSignupError(error);
        });
  }

  _setValidity(key, value) {
    this.$scope.createAccountForm.emailOrUsernameInput.$setValidity(key, value);
  }

  googleSignUp() {
    this._state = this.Loading;
    if (this.$stateParams.isTeacher || this.isFromSharedWork) {
      this._analyticsService.sendEvent({
        eventTag:'teacherSignUp:google_clicked'});
      this.googleSignUpForTeacher();
    }
    else {
      this._analyticsService.sendEvent({
        eventTag:'studentSignUp:google_clicked'});
      this.googleSignUpForStudent();
    }
  }

  googleSignUpForTeacher() {
    return this._authService.googleSignIn()
        .then((googleAuthInfo) => {
          return this.$q.all({
            signUpWithGoogle: this._authService.signUpWithGoogle(googleAuthInfo, UserRoles.TEACHER),
            googleAuthInfo
          });
        })
        .then((result) => {
          this._analyticsService.createAccountProgress(AccountCreationStep.STARTED);
          this._showPendoToNewUser();

          if (this.isFromSharedWork) {
            this._storageService.sharedWorkSignUpAuthType = AuthType.GOOGLE;
            this._storageService.sharedWorkPath = '';
            this._storageService.ckRedirect = null;
            return this.closeAndReload();
          } else {
            this._breadcrumbService.go('root.signup-teacher-name', {
              firstName: result.googleAuthInfo.firstName,
              lastName: result.googleAuthInfo.lastName
            });
          }

        })
        .catch((error) => {
          this._handleGoogleSignUpError(error);
        });
  }

  googleSignUpForStudent() {
    let classCode = this._removeSpaces(this._classCodeText);

    return this._authService.googleSignIn()
        .then((googleAuthInfo) => {
          return this.$q.all({
            signUpWithGoogle: this._authService.signUpWithGoogle(
                googleAuthInfo,
                UserRoles.STUDENT,
                classCode
            ),
            googleAuthInfo
          });
        })
        .then((result) => {
          this._analyticsService.createAccountProgress(AccountCreationStep.STARTED);

          this._storageService.ckRedirect = new CkRedirect('root.account.home', {
            classCode
          });

          this._breadcrumbService.go('root.signup-student-name', {
            firstName: result.googleAuthInfo.firstName,
            lastName: result.googleAuthInfo.lastName
          });
        })
        .catch((error) => {
          this._handleGoogleSignUpError(error);
        });
  }

  /**
   * @param error {Error}
   */
  _handleGoogleSignUpError(error) {
    if (error.code === ErrorCodes.GOOGLE_POPUP_CLOSED_BY_USER || error.code === ErrorCodes.GOOGLE_ACCESS_DENIED) {
      this._state = this.Choice;
    }
    else if (error.code === ErrorCodes.DUPLICATE_EMAIL) {
      let email = error.config.data.email;
      let idToken = error.config.data.auth.token;
      this._logInUserWithGoogle(email, idToken);
    }
    else if (error.code === ErrorCodes.GOOGLE_AUTH_SETUP_FAILED) {
      this._state = this.Choice;
      this._errorMessage = 'Whoops, there was a problem using Google to sign up: ' + error.message;
      this.$log.warn('googleSignUp error', error, error.message);
    }
    else {
      this._state = this.Choice;
      this.$log.warn('googleSignUp error', error, angular.toJson(error));
      this._errorMessage = 'Whoops, there was a problem signing up with Google Auth. Please try again later or email support@classkick.com.';
    }
  }

  /**
   * @param email {string}
   * @param idToken {string}
   * @private
   */
  _logInUserWithGoogle(email, idToken) {
    this._authService
        .authUserWithGoogle({
          email: email,
          idToken: idToken
        })
        .then(() => {
          this._goToAssignments(AuthType.GOOGLE);
        })
        .catch(() => {
          this._errorMessage = 'Whoops, there was a problem signing in with Google Auth. Please try again later or email support@classkick.com.';
        });
  }

  _goToAssignments(authType = AuthType.PASSWORD) {
    this._breadcrumbService.clear();
    this._breadcrumbService.go('root.account.home', { authType }, true);
  }

  linkToCleverAuth() {
    if (this.isTeacher) {
      this._analyticsService.sendEvent({
        eventTag:'teacherSignUp:clever_clicked'});
    } else {
      this._analyticsService.sendEvent({
        eventTag:'studentSignUp:clever_clicked'});
    }
    this._authService.linkToCleverAuth(this._removeSpaces(this._classCodeText));
    this._showPendoToNewUser();
  }

  /** Class code input functionality **/

  get disabled() {
    return this.isStudent && (!this._classCodeData || !this._classCodeData.allowNewMembers || !this._classCodeData.pro);
  }

  /**
   * @return {boolean}
   */
  get showClassCodeError() {
    return this.$scope.createAccountForm.classCodeInput.$invalid;
  }

  get classCodePattern() {
    return '^[a-zA-Z\\d\\s]+$';
  }

  get classCodeText() {
    return this._classCodeText;
  }

  set classCodeText(value) {
    if (angular.isDefined(value)) {
      this._classCodeText = value;
    }
  }

  classCodeUpdated() {
    if (!this._hasSentAnalyticsClassCode) {
      this._analyticsService.sendEvent({
        eventTag:'studentSignUp:classCode_typed'});
      this._hasSentAnalyticsClassCode = true;
    }

    this._errorMessage = '';

    this.$scope.createAccountForm.classCodeInput.$setValidity('npro', true);
    this.$scope.createAccountForm.classCodeInput.$setValidity('nccode', true);
    this.$scope.createAccountForm.classCodeInput.$setValidity('locked', true);

    this._classCodeData = undefined;

    let classCodeMaxLength = this._classCodeText.indexOf(' ') > -1 ? 7 : 6;
    this._classCodeText = this._classCodeText.toUpperCase().slice(0, classCodeMaxLength);
    let cleanedClassCode = this._removeSpaces(this._classCodeText);

    if (cleanedClassCode.length === 6) {
      this._loadClassCodeData(cleanedClassCode);
    }
  }

  /**
   * Verifies that a classCode exists, and then loads the students for that classCode
   * @param tempClassCode
   * @private
   */
  _loadClassCodeData(tempClassCode) {
    this._classCodeService.getUsernames(tempClassCode)
        .then((classCodeData) => {
          if (this._removeSpaces(this._classCodeText) !== tempClassCode) {
            throw new Error('class Code was modified');
          }

          this._classCodeData = classCodeData;

          if (this._classCodeData && !this._classCodeData.pro) {
            this.$scope.createAccountForm.classCodeInput.$setValidity('npro', false);
          }

          if (this._classCodeData && !this._classCodeData.allowNewMembers) {
            this.$scope.createAccountForm.classCodeInput.$setValidity('locked', false);
          }
        })
        .catch((err) => {
          this.$scope.createAccountForm.classCodeInput.$setValidity('nccode', false);
          this.$log.debug('_loadClassCodeData error', err);
        });
  }

  /**
   * @param code {string}
   */
  _removeSpaces(code) {
    return code.replace(/[\s]/g, '');
  }

  _generateUTMData(url){
    const params = url.match(/\?.*/);
    if (params && params.length > 0){
      const utmData = params[0]
          .replace(/utm_/g, '')
          .slice(1)
          .split('&');
      const utmObj = {};

      utmData.forEach((utmParam) => {
        const [utmProp, utmValue] = utmParam.split('=');
        utmObj[utmProp] = utmValue;
      });
      return utmObj;
    }
    return null;
  }

  _getAndSaveCampaignId(url){
    const utmObj = this._generateUTMData(url);
    if (utmObj && Object.keys(utmObj).length > 0) {
      this._campaignService.getCampaignInfo(utmObj).then((data) => {
        this._storageService.utmCampaignId = data.id;
      });
    }
  }

  _showPendoToNewUser() {
    this._pendoService.init();
  }
}

/**
 *
 * More information about the bindings:
 * https://docs.angularjs.org/api/ng/service/$compile#-scope-
 * https://docs.angularjs.org/guide/component
 */
export default {
  bindings: {
    handleLoginView: '&', //& symbol is used in the context of directive bindings. Specifically, it's used for passing a function as an expression into a directive
    handleSignUpView: '&',
    closeAndReload: '&',
    closeDialog: '&',
    handleError: '&'
  },
  template: SignUpComponentTemplate,
  controller: SignUpComponentController,
  controllerAs: 'ctrl'
};
