import Textbox from './textbox';

export default class ColorSpanArranger {

  /**
   * @param colorSpans {ColorSpan[]}
   * @param selectedFrom {number}
   * @param selectedTo {number}
   * @param activeColor {string}
   * @param currentText {string}
   * @returns {ColorSpan[]}
   */
  updateSelectedTextColor(colorSpans, selectedFrom, selectedTo, currentText, activeColor) {
    colorSpans = ColorSpanArranger.arrangeColors(colorSpans, currentText);
    colorSpans = this._updateColorSpansForDeletion(colorSpans, selectedFrom, selectedTo, currentText);
    return this._updateColorSpansForInsertion(colorSpans, selectedFrom, selectedTo, currentText, activeColor);
  }

  /**
   * @param colorSpans {ColorSpan[]} the color spans before updates
   * @param oldText {string} the text before updates
   * @param newText {string} the text after updates
   * @param oldStartIndex {number} the starting selection index before updates (inclusive)
   * @param oldEndIndex {number} the ending selection index before updates (exclusive)
   * @param newStartIndex {number} the starting selection index after updates (inclusive)
   * @param newEndIndex {number} the ending selection index after updates (exclusive)
   * @param activeColor {string} the color any new insertions should be rendered as
   * @returns {ColorSpan[]}
   */
  updateColorSpans(colorSpans, oldText, newText, oldStartIndex, oldEndIndex, newStartIndex, newEndIndex, activeColor) {

    colorSpans = ColorSpanArranger.arrangeColors(colorSpans, oldText);

    let oldSelected = oldEndIndex - oldStartIndex;
    let textChange = newText.length - oldText.length;

    // inserted one or more characters
    if (oldSelected === 0 && textChange > 0) {
      colorSpans = this._updateColorSpansForInsertion(colorSpans, oldStartIndex, newEndIndex - 1, newText, activeColor);
    }
    // deleted one or more characters
    else if (oldSelected === 0 && textChange < 0) {
      let fromIndex = oldStartIndex + textChange;
      let toIndex = oldEndIndex - 1;
      colorSpans = this._updateColorSpansForDeletion(colorSpans, fromIndex, toIndex, oldText);
    }
    // when text is selected
    else if (oldSelected > 0) {

      let deleteFrom = oldStartIndex;
      let deleteTo = oldEndIndex - 1;
      colorSpans = this._updateColorSpansForDeletion(colorSpans, deleteFrom, deleteTo, oldText);

      let charactersAfterDeletetion = oldText.length - oldSelected;
      let newCharacters = newText.length - charactersAfterDeletetion;

      let insertFrom = oldStartIndex;
      let insertTo = oldStartIndex  + newCharacters - 1;

      if (newCharacters > 0) {
        colorSpans = this._updateColorSpansForInsertion(colorSpans, insertFrom, insertTo, newText, activeColor);
      }
    }

    return ColorSpanArranger.arrangeColors(colorSpans, newText);
  }

  /**
   * @param colorSpans {ColorSpan[]} the color spans before updates
   * @param startInsertion {number}
   * @param endInsertion {number}
   * @param newText {string} the text after insertion
   * @param activeColor {string}
   * @returns {ColorSpan[]} the updated color spans
   * @private
   */
  _updateColorSpansForInsertion(colorSpans, startInsertion, endInsertion, newText, activeColor) {

    let indexAfterInsertion = endInsertion + 1;
    let charactersAdded = indexAfterInsertion - startInsertion;
    let hasCharacterAfterInsertion = !!newText[indexAfterInsertion];
    let spanAtStartInsertion = this._spanAt(colorSpans, startInsertion);
    let spanToTheLeftOfInsertion = this._spanToLeftOf(colorSpans, startInsertion);
    let firstSpan = colorSpans[0];

    if (startInsertion === 0 && firstSpan && firstSpan.color === activeColor) {
      colorSpans = this._shift(colorSpans, 1, charactersAdded);
    }
    else if (startInsertion === 0 && firstSpan && firstSpan.color !== activeColor) {
      colorSpans = [
        new ColorSpan(0, -1, activeColor),
        ...this._shift(colorSpans, 0, charactersAdded)
      ];
    }
    else if (spanToTheLeftOfInsertion.color === activeColor) {
      colorSpans = this._shift(colorSpans, startInsertion, charactersAdded);
    }
    else if (spanAtStartInsertion && spanAtStartInsertion.color === activeColor) {
      colorSpans = [
        ...this._shift(colorSpans, startInsertion + 1, charactersAdded)
      ];
    }
    else if (spanAtStartInsertion && spanAtStartInsertion.color !== activeColor) {
      colorSpans = [
        ...this._shift(colorSpans, startInsertion, charactersAdded),
        new ColorSpan(startInsertion, -1, activeColor)
      ];
    }
    else {
      colorSpans = [
        new ColorSpan(startInsertion, -1, activeColor),
        ...this._shift(colorSpans, startInsertion, charactersAdded)
      ];

      if (hasCharacterAfterInsertion) {
        colorSpans = [...colorSpans, new ColorSpan(indexAfterInsertion, -1, spanToTheLeftOfInsertion.color)];
      }
    }

    return ColorSpanArranger.arrangeColors(colorSpans, newText);
  }

  /**
   * Removes color spans that are within specified indices
   *
   * @param colorSpans {ColorSpan[]}
   * @param deleteFrom {number} the index (inclusive) of the first character to be deleted
   * @param deleteTo {number} the index (inclusive) of the last character to be deleted
   * @param text {string} the text before deletion
   * @returns {ColorSpan[]}
   */
  _updateColorSpansForDeletion(colorSpans, deleteFrom, deleteTo, text) {

    if (deleteFrom > deleteTo) {
      throw new Error('Invalid parameters: deleteFrom must be less than or equal to deleteTo');
    }

    if (text.length === 0) {
      return colorSpans;
    }

    let initialCursorIndex = deleteTo + 1;
    let hasCharacterAtInitialCursorIndex = !!text[initialCursorIndex];
    let textChange = (initialCursorIndex - deleteFrom) * -1;

    let colorSpansWithinDeletionRange = this._spansBetween(colorSpans, deleteFrom, deleteTo);

    if (colorSpansWithinDeletionRange.length === 0) {
      colorSpans = this._shift(colorSpans, initialCursorIndex, textChange);
    }
    else if (colorSpansWithinDeletionRange.length > 0) {
      let spanAtInitialCursor = this._spanAt(colorSpans, initialCursorIndex);
      let hasSpanAtInitialCursorIndex = !!spanAtInitialCursor;
      let spanToTheLeftOfFirstDeletedCharacter = this._spanToLeftOf(colorSpans, deleteFrom);

      if (hasSpanAtInitialCursorIndex || !hasCharacterAtInitialCursorIndex) {

        if (this._sameColor(spanToTheLeftOfFirstDeletedCharacter, spanAtInitialCursor)) {
          colorSpans = this._removeSpans(colorSpans, deleteFrom, deleteTo + 1);
        }
        else {
          colorSpans = this._removeSpans(colorSpans, deleteFrom, deleteTo);
        }

        colorSpans = this._shift(colorSpans, initialCursorIndex, textChange);
      }
      else {
        colorSpans = [...this._removeSpans(colorSpans, deleteFrom, deleteTo)];

        let lastColorSpanWithinDeletionRange = colorSpansWithinDeletionRange[colorSpansWithinDeletionRange.length - 1];
        if (!this._sameColor(spanToTheLeftOfFirstDeletedCharacter, lastColorSpanWithinDeletionRange)) {
          colorSpans = [ ...colorSpans, new ColorSpan(initialCursorIndex, -1, lastColorSpanWithinDeletionRange.color)];
        }

        colorSpans = this._shift(colorSpans, initialCursorIndex, textChange);
      }
    }

    return ColorSpanArranger.arrangeColors(colorSpans, text);
  }

  /**
   * @param colorSpans {ColorSpan[]}
   * @param from {number}
   * @param count {number}
   * @returns {ColorSpan[]}
   */
  _shift(colorSpans, from, count = 1) {
    return colorSpans.map((colorSpan) => {
      if (colorSpan.start >= from) {
        colorSpan.start += count;
      }
      return colorSpan;
    });
  }

  /**
   * @param colorSpans {ColorSpan[]}
   * @param index {number}
   * @returns {ColorSpan}
   */
  _spanToLeftOf(colorSpans, index) {
    return colorSpans.find((colorSpan, i, collection) => {
      let next = collection[i+1];
      if (next) {
        return colorSpan.start < index && index <= next.start;
      }
      return colorSpan.start < index;
    });
  }

  /**
   * @param colorSpans {ColorSpan[]}
   * @param index {number}
   * @returns {ColorSpan}
   */
  _spanAt(colorSpans, index) {
    let span = colorSpans.find((colorSpan) => {
      return colorSpan.start === index;
    });
    if (span) {
      return new ColorSpan(span.start, -1, span.color);
    }
  }

  /**
   * @param colorSpans {ColorSpan[]}
   * @param startIndex {number}
   * @param endIndex {number}
   * @returns {ColorSpan[]}
   */
  _spansBetween(colorSpans, startIndex, endIndex) {
    return colorSpans.filter((colorSpan) => {
      return startIndex <= colorSpan.start && colorSpan.start <= endIndex;
    });
  }

  /**
   * @param colorSpans {ColorSpan[]}
   * @param fromIndex {number}
   * @param toIndex {number}
   * @returns {ColorSpan[]}
   */
  _removeSpans(colorSpans, fromIndex, toIndex) {
    return colorSpans.filter((colorSpan) => {
      return colorSpan.start < fromIndex || colorSpan.start > toIndex;
    });
  }

  /**
   * @param colorSpan1 {ColorSpan}
   * @param colorSpan2 {ColorSpan}
   * @returns {boolean}
   */
  _sameColor(colorSpan1, colorSpan2) {
    if (colorSpan1 && colorSpan2) {
      return colorSpan1.color === colorSpan2.color;
    }
    return false;
  }

  /**
   * @param colors {Array.<ColorSpan>}
   * @param text
   * @returns {Array.<ColorSpan>}
   */
  static arrangeColors(colors, text) {
    var col =  colors ? colors : [];

    // Is there a color span for the zero index
    if (!col.find((x) => x.index === 0)) {
      col.push(new ColorSpan(0, 0, Textbox.DEFAULT_TEXT_COLOR));
    }
    return col
      .filter((x) => x.index < text.length || x.index === 0)
      .sort((a, b) => a.index - b.index );
  }
}

export class ColorSpan {
  /**
   * @param start {number}
   * @param length {number}
   * @param color {string}
   */
  constructor(start, length, color) {
    this._start = start;
    this._length = length;
    this._color = color;
  }

  /**
   * @returns {number}
   */
  get start() {
    return this._start;
  }

  /**
   * @param value {number}
   */
  set start(value) {
    this._start = value;
  }

  /**
   * @returns {number}
   */
  get index() {
    return this._start;
  }

  /**
   * @deprecated to be removed in schema 2
   * @returns {number}
   */
  get length() {
    return this._length;
  }

  /**
   * @returns {string}
   */
  get color() {
    return this._color;
  }

  get type() {
    return 'color';
  }
}

