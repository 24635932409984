'use strict';

import Order from '../../model/domain/order';
import SelectPurchasePeriodDialogTemplate from './select-purchase-period-dialog.html';
import moment from 'moment';

export default class SelectPurchasePeriodDialogController {

  /**
   * @ngInject
   */
  constructor($q, $mdDialog, OrderService, organization, order) {
    this.$q = $q;
    this.$mdDialog = $mdDialog;

    this._orderService = OrderService;

    this._selectedStart = order.start;
    this._selectedEnd = order.end;
    this._orgType = organization.orgType;
    this._quantity = order.quantity || 1;
    this._prices = undefined;
    this._hideThisYear = false;
    this._isRenewal = order.renewal;
    this._date = moment().diff(order.start, 'days') > 0 ? moment() : order.start;
    this._duration = order.end.diff(order.start, 'years') - 1;

    this.$q
      .all({
        prices: this._orderService.getPrices(organization.type, order.renewal, organization.virtual, this._date),
        orders: this._orderService.getForOrganization(organization.id)
      })
      .then(({prices, orders}) => {
        this._prices = prices;
        this._selectedStart = prices && prices[0].start;
        this._selectedEnd = this._duration > 0 ? prices && prices[this._duration].end : prices && prices[0].end;
        this._hideThisYear = Order.hasAlreadyPurchasedThisYear(orders);
        this._isRenewal = Order.hasAlreadyPurchasedThisYear(orders) || order.renewal;
      });
  }

  get prices() {
    return this._prices;
  }

  get hideThisYear() {
    return this._hideThisYear;
  }

  get isRenewal() {
    return this._isRenewal;
  }

  get selectedStart() {
    return this._selectedStart;
  }

  set selectedStart(value) {
    this._selectedStart = value;
  }

  get selectedEnd() {
    return this._selectedEnd;
  }

  set selectedEnd(value) {
    this._selectedEnd = value;
  }

  get orgType() {
    return this._orgType;
  }

  get quantity() {
    return this._quantity;
  }

  set quantity(value) {
    this._quantity = value;
  }

  get isValidPurchasePeriod() {
    return this._isValidPurchasePeriod;
  }

  set isValidPurchasePeriod(value) {
    this._errorMessage = '';
    this._isValidPurchasePeriod = value;
  }

  get errorMessage() {
    return this._errorMessage;
  }

  selectPurchasePeriod(scope) {

    if (scope.selectPurchasePeriodForm.$invalid) {
      return;
    }

    if (!this.isValidPurchasePeriod) {
      this._errorMessage = 'Invalid purchase period. Please select one or more consecutive years.';
      return;
    }

    this.$mdDialog.hide({
      start: this._selectedStart,
      end: this._selectedEnd
    });
  }



  /**
   * Launches create order dialog
   * @param $mdDialog
   * @param organization {Organization}
   * @param order {Order}
   */
  static show($mdDialog, organization, order) {
    return $mdDialog.show({
      controller: SelectPurchasePeriodDialogController,
      template: SelectPurchasePeriodDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      locals: {
        organization,
        order
      }
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
