
'use strict';

import { MultipleChoiceOption } from '../../model/ui/elements/multiple-choice-parent';
import ScoreUtil from '../../model/util/score-utils';

export default class MultipleChoiceManager {

  /**
   * @param CacheService {CacheService}
   */
  constructor(CacheService) {
    this._cacheService = CacheService;

    this.resetOptions();
    this._selected = null;
    this._user = undefined;
    this._showHelp = false;
    this.currentMaxOptions = 5;
  }

  init() {
    this._cacheService.getUser(false).then((user) => {
      this._user = user;
      this._showHelp = this._user.showMultipleChoiceIntro;
    });
  }

  get answers() {
    return ['A', 'B', 'C', 'D', 'E'];
  }

  /**
   * @return {MultipleChoiceOption[]}
   */
  get options() {
    return this._options;
  }

  /**
   * @return {MultipleChoiceOption|null}
   */
  get selected() {
    return this._selected;
  }

  /**
   * @param value {MultipleChoiceOption|null}
   */
  set selected(value) {
    this._selected = value;
  }

  resetOptions() {
    this._options = this.answers.map((letter) => new MultipleChoiceOption(letter));
    this._selected = null;
  }

  /**
   * @param option {MultipleChoiceOption}
   */
  selectAnswer(option) {
    if (this.selected) {
      option.points = this.selected.points;
    }

    this.options
      .filter((opt) => opt !== option)
      .forEach((opt) => {
        opt.correct = false;
        opt.points = angular.isNumber(option.points) ? 0 : null;
      });

    this.selected = option;
    this.selected.correct = true;
  }

  removeCorrectAnswer() {
    if (this.selected) {
      this.resetOptions();
      this.selected = null;
    }
  }

  get isSelected() {
    return !!this.selected;
  }

  multipleChoiceScorePlus() {
    if (this.selected) {
      this.selectedPoints = this.selected.points + 1;
    }
  }

  multipleChoiceScoreMinus() {
    if (this.selected.points >= 1) {
      this.selectedPoints = this.selected.points - 1;
    }
  }

  get selectedPoints() {
    if (this.selected) {
      return this.selected.points;
    } else {
      return '';
    }
  }

  set selectedPoints(value) {
    if (this.selected) {
      const newScore = ScoreUtil.formatScore(value);
      this.options.forEach((opt) => opt.points = angular.isNumber(newScore) ? 0 : null);
      this.selected.points = newScore;
    }
  }

  /**
   * @return {boolean}
   */
  get showHelp() {
    return this._showHelp;
  }

  /**
   * @param value {boolean}
   */
  set showHelp(value) {
    this._showHelp = value;

    if (this._user && this._user.showMultipleChoiceIntro && !value) {
      this._user.showMultipleChoiceIntro = value;
      this._cacheService.updateUser(this._user);
    }
  }

  get canDelete() {
    return this._options.length > 2;
  }

  get canAdd() {
    return this._options.length < this.currentMaxOptions;
  }

  deleteOption() {
    if (this.canDelete) {
      this._options.pop();
    }
  }

  addOption() {
    if (this.canAdd) {
      const nextOption = this._options.length;

      this._options = [
        ...this._options,
        new MultipleChoiceOption(this.answers[nextOption])
      ];
    }
  }
}
