export default class OrderCoupon {

  constructor(id, discount, description, available) {
    this.id = id;
    this.discount = discount;
    this.description = description;
    this.available = available;
  }

}
