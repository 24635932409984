'use strict';

import InputPipe from '../../model/ui/input-pipe';
import CollectionObserver from '../../model/firebase-mapping/collection-observer';
import TextboxBase from '../../model/ui/elements/textbox-base';
import JSEvent from '../../model/util/js-event';

class Observer extends CollectionObserver {
  constructor(service) {
    super();
    this.service = service;
  }

  /**
   * @param value {Element}
   */
  onAdded(value) {
    value.focused.subscribe(this.service._focus, this.service);
    value.deleted.subscribe(this.service._deleted, this.service);
    value.focusNextElement.subscribe(this.service._focusNextElement, this.service);
    value.focusPrevElement.subscribe(this.service._focusPreviousElement, this.service);
  }

  /**
   * @param value {Element}
   */
  onRemoved(value) {
    value.focused.unsubscribe(this.service._focus, this.service);
    value.deleted.unsubscribe(this.service._deleted, this.service);
    value.focusNextElement.unsubscribe(this.service._focusNextElement, this.service);
    value.focusPrevElement.unsubscribe(this.service._focusPreviousElement, this.service);
  }

  onChanged() {}
}

/**
 * When you want to focus an element, call element.focus()
 * When you want to make sure there are no focused elements, set focusManager.focusedElement to
 * null or undefined
 */
export default class FocusManagerService {

  constructor() {
    'ngInject';

    /** @type {InputPipe} */
    this._inputPipe = new InputPipe();

    this._focusedElement = undefined;
    this._elementList = undefined;
    this._elementListObserver = new Observer(this);
    this._updated = new JSEvent(this);

    this._anonHandleClick = (ev) => this._handleClick(ev);
    this._body = angular.element('body');
    this._body.on('click', this._anonHandleClick);
  }

  /**
   * @returns {JSEvent}
   */
  get updated() {
    return this._updated;
  }

  notify(value){
    this._updated.raise(value);
  }

  focusedElementUpdated(value){
    this.notify(value);
  }

  set elementList(value) {

    if (this._elementList) {
      this._elementList.unsubscribe(this._elementListObserver);

      this._elementList.forEach((elem) => {
        this._elementListObserver.onRemoved(elem);
      });
    }

    this._elementList = value;

    if (this._elementList) {
      this._elementList.subscribe(this._elementListObserver);
    }
    else {
      this.focusedElement = undefined;
    }
  }

  /**
   * @param args
   * @param src
   * @private
   */
  _focus(args, src) {
    this.focusedElement = src;
  }

  /**
   * @param args
   * @param src
   * @private
   */
  _deleted(args, src) {
    if (this.focusedElement && this.focusedElement.id === src.id) {
      this.focusedElement = undefined;
    }
  }

  /**
   * @param args {undefined}
   * @param src {Element}
   */
  _focusNextElement(args, src) {
    this._moveFocus(src, 1, 0);
  }

  /**
   * @param args {undefined}
   * @param src {Element}
   */
  _focusPreviousElement(args, src) {
    this._moveFocus(src, -1, this._elementList.size - 1);
  }

  /**
   * @param currentElement {Element} the currently focused element
   * @param indexChange {number} the number to add to the index to reach the next element
   * @param fallbackIndex {number} the fallback index of the element to focus if none are found
   */
  _moveFocus(currentElement, indexChange, fallbackIndex) {
    let index = this._elementList.indexOf(currentElement);
    let previous = this._elementList.valueAtIndex(index + indexChange) || this._elementList.valueAtIndex(fallbackIndex);

    if (previous) {
      previous.focus();
    }
  }

  /**
   * @param value {Element|undefined|null}
   */
  set focusedElement(value) {

    this._elementList && this._elementList.forEach((elem) => {
      if (!value || elem.id !== value.id) {
        angular.isDefined(elem.blur) && elem.blur();
      }
    });

    // TODO confirm that we only want to blur when prev element is textbox
    if (this._isTextbox(this.focusedElement)) {
      this._inputPipe.blur();
    }

    if (this._isTextbox(value)) {
      this._inputPipe.focus(value);
    }

    this._focusedElement = value;
    this.focusedElementUpdated(value);
  }

  /**
   * @returns {Element|undefined|null}
   */
  get focusedElement() {
    return this._focusedElement;
  }

  _handleClick(ev) {
    if (ev) {
      if (!ev.originalEvent) {
        return;
      }

      //prevent focusedElement from clearing when interacting with answer type switch dialog
      if (ev.target
        && (Array.from(ev.target.classList).includes('cancel-btn')|| Array.from(ev.target.classList).includes('confirm-btn'))) {
        return this.focusedElement = ev.currentTarget;
      }

      //prevent focusedElement from clearing when interacting with answer type switch dialog
      if (ev.target && ev.currentTarget.className === 'md-dialog-is-showing') {
        return this.focusedElement = ev.currentTarget;
      }

      //prevent the FITB side nav and math keyboard from closing when focus switches out of the answer input
      if (!(ev.target instanceof SVGElement)
        && !(ev.originalEvent && ev.originalEvent.preventElementBlur)
        && !(ev.currentTarget && ev.currentTarget.className.includes('ML_'))
        && !(ev.target && ev.target.className.includes('MLK'))
        && !(ev.target && ev.target.className.includes('ML'))
        && !(ev.target && ev.target.id.includes('ML_'))
        && !(ev.target && Array.from(ev.target.childNodes))
              .some((element) => element && element.id && element.id.includes('ML_')
                  || (element && element.className && typeof element.className === 'string' && element.className.includes('ML_'))
          )
        && !(ev.target.tagName === 'LI')
      ) {
        return this.focusedElement = undefined;
      }

      //if clicking another canvas element hide keyboard or outside the math-field FITB
      const undoButton = ev.target && ev.target.childNodes[0] && ev.target.childNodes[0].tagName === 'use';
      if (!undoButton && (ev.target.tagName === 'rect' || ev.target instanceof SVGElement)) {
        window.mathVirtualKeyboard.hide();
        if (document.activeElement.tagName === 'MATH-FIELD') {
          document.activeElement.blur();
        }
      }

      else if (ev.originalEvent.preventElementBlur && this._isTextbox(this.focusedElement)) {
        this.refocusInput();
      }
    }
  }

  refocusElement() {
    this.focusedElement = this.focusedElement;
  }

  refocusInput() {
    this._inputPipe.focusInput();
  }

  /**
   * @param value {Element}
   */
  _isTextbox(value) {
    return value && value instanceof TextboxBase;
  }
}
