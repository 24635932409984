
'use strict';

import Codec from './codec';
import PointCodec from './point-codec';

export default class PointArrayCodec extends Codec {
  constructor() {
    super();
    this._pointCodec = new PointCodec();
  }

  /**
   * Encodes an array of points to a json string
   *
   * @param value {Array.<Point>}
   */
  encode(value) {
    return angular.toJson(value.map((p) => [Math.round(p.x), Math.round(p.y)]));
  }

  /**
   * Decodes a point from the given input, either JSON string or array of arrays of numbers
   *
   * @param value {string|Array.<Array.<number>>}
   * @returns {Array.<Point>}
   */
  decode(value) {
    var encoded = value;
    if (angular.isString(encoded)) {
      encoded = angular.fromJson(encoded);
    }

    return encoded.map((p) => this._pointCodec.decode(p));
  }
}
