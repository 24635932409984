
import JSEvent from '../util/js-event';
'use strict';

/**
 * Class Code
 */
export default class ClassCode {
  /**
   * @param classCode {string}
   * @param rosterId {string}
   * @param [assignmentId] {string}
   * @param [sameContract] {boolean}
   * @param [showStudentScores] {boolean}
   * @param [lockStudentWork] {boolean}
   * @param [hideStudentWork] {boolean}
   * @param [allowPdf] {boolean}
   */
  constructor(classCode, rosterId, assignmentId, sameContract, showStudentScores, lockStudentWork, hideStudentWork, allowPdf) {
    this._classCode = classCode;
    this._rosterId = rosterId;
    this._assignmentId = assignmentId;
    this._sameContract = sameContract;
    this._showStudentScores = showStudentScores;
    this._showStudentScoresUpdated = new JSEvent(this);
    this._lockStudentWork = lockStudentWork;
    this._lockStudentWorkUpdated = new JSEvent(this);
    this._hideStudentWork = hideStudentWork;
    this._hideStudentWorkUpdated = new JSEvent(this);
    this._allowPdf = allowPdf;
    this._allowPdfUpdated = new JSEvent(this);
  }

  /**
   * @returns {string}
   */
  get classCode() {
    return this._classCode;
  }

  /**
   * @returns {string}
   */
  get rosterId() {
    return this._rosterId;
  }

  /**
   * @returns {string|undefined}
   */
  get assignmentId() {
    return this._assignmentId;
  }

  /**
   * @return {boolean}
   */
  get sameContract() {
    return this._sameContract;
  }

  /**
   * @return {boolean}
   */
  get showStudentScores() {
    return this._showStudentScores;
  }

  /**
   * @param value {boolean}
   */
  set showStudentScores(value) {
    this._showStudentScores = value;
    this._showStudentScoresUpdated.raise(value);
  }

  /**
   * @return {JSEvent}
   */
  get showStudentScoresUpdated() {
    return this._showStudentScoresUpdated;
  }

  /**
   * @return {boolean}
   */
  get lockStudentWork() {
    return this._lockStudentWork;
  }

  /**
   * @param value {boolean}
   */
  set lockStudentWork(value) {
    this._lockStudentWork = value;
    this._lockStudentWorkUpdated.raise(value);
  }

  /**
   * @return {JSEvent}
   */
  get lockStudentWorkUpdated() {
    return this._lockStudentWorkUpdated;
  }

  /**
   * @return {boolean}
   */
  get hideStudentWork() {
    return this._hideStudentWork;
  }

  /**
   * @param value {boolean}
   */
  set hideStudentWork(value) {
    this._hideStudentWork = value;
    this._hideStudentWorkUpdated.raise(value);
  }

  /**
   * @return {JSEvent}
   */
  get hideStudentWorkUpdated() {
    return this._hideStudentWorkUpdated;
  }

  /**
   * @return {boolean}
   */
  get allowPdf() {
    return this._allowPdf;
  }

  /**
   * @param value {boolean}
   */
  set allowPdf(value) {
    this._allowPdf = value;
    this._allowPdfUpdated.raise(value);
  }

  /**
   * @return {JSEvent}
   */
  get allowPdfUpdated() {
    return this._allowPdfUpdated;
  }

  /**
   * Returns the class code formatted like 'XXX XXX'
   * @returns {string}
   */
  format() {
    return `${this._classCode.substr(0, 3)} ${this._classCode.substr(3)}`;
  }

  /**
   * Returns the url to log in as a student on the current site (labs, staging, prod, etc.)
   *
   * @param $location {$location}
   * @returns {string}
   */
  studentUrl($location) {
    return $location.absUrl().replace($location.url(), `/login/${this.classCode}`);
  }
}
