
'use strict';

/**
 * Contains common sort methods
 */
export default class Sorts {

  static get NAME_ASC() {
    return (nameA, nameB) => {
      return nameA.localeCompare(nameB);
    };
  }

  static get NAME_DESC() {
    return (nameA, nameB) => {
      return nameB.localeCompare(nameA);
    };
  }

  static get BOOLEAN_TRUE_FIRST() {
    return (boolA, boolB) => {
      return (boolA === boolB)? 0 : boolA? -1 : 1;
    };
  }

  /**
   * @param sorts {function(*=, *=)[]}
   * @returns {function(*=, *=)}
   */
  static combine(...sorts) {
    return sorts.slice(1).reduce((a, b) => {
      return Sorts._combineTwo(a, b);
    }, sorts[0]);
  }

  /**
   * @param first {function(*=, *=)}
   * @param second {function(*=, *=)}
   * @returns {function(*=, *=)}
   * @private
   */
  static _combineTwo(first, second) {
    return (a, b) => {
      const result = first.call(null, a, b);
      if (result === 0) {
        return second.call(null, a, b);
      }
      return result;
    };
  }

  static get SIZE_ASC() {
    return (a, b) => {
      if (a.size < b.size) {
        return 1;
      }
      else if (a.size > b.size) {
        return -1;
      }
      else {
        return 0;
      }
    };
  }

  static get SIZE_DESC() {
    return (a, b) => {
      if (a.size > b.size) {
        return 1;
      }
      else if (a.size < b.size) {
        return -1;
      }
      else {
        return 0;
      }
    };
  }

  /**
   * Sorts numerically in ascending order while placing undefined values at bottom
   *
   * @param a {number|undefined}
   * @param b {number|undefined}
   */
  static NUMERIC_UNDEFINED_ASC(a, b) {
    if (angular.isUndefined(a) && angular.isNumber(b)) {
      return 1;
    }
    else if (angular.isNumber(a) && angular.isUndefined(b)) {
      return -1;
    }
    else if (angular.isUndefined(a) && angular.isUndefined(b)) {
      return 0;
    }
    else if (a < b) {
      return -1;
    }
    else if (a > b) {
      return 1;
    }
    else if (a === b) {
      return 0;
    }
  }

  /**
   * Sorts numerically in descending order while placing undefined values at bottom
   *
   * @param a {number|undefined}
   * @param b {number|undefined}
   */
  static NUMERIC_UNDEFINED_DESC(a, b) {
    if (angular.isUndefined(a) && angular.isNumber(b)) {
      return 1;
    }
    else if (angular.isNumber(a) && angular.isUndefined(b)) {
      return -1;
    }
    else if (angular.isUndefined(a) && angular.isUndefined(b)) {
      return 0;
    }
    else if (a < b) {
      return 1;
    }
    else if (a > b) {
      return -1;
    }
    else if (a === b) {
      return 0;
    }
  }
}
