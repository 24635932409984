'use strict';
import Codec from './codec';
import CourseCodec from './course-codec';
import SessionCodec from './session-codec';
import ClassCodec from './class-codec';
import GradebookRosterIntegrationStatus from '../domain/gradebook-roster-integration-status';
import GradebookRosterCodec from './gradebook-roster-codec';
import GradebookAssignmentCategoryCodec from './gradebook-assignment-category-codec';
import GradebookAssignmentCodec from './gradebook-assignment-codec';
/**
 * Encodes/decodes Gradebook roster integration status information
 */
export default class GradebookRosterIntegrationStatusCodec extends Codec {
  constructor() {
    super();

    this._sessionCodec = new SessionCodec();
    this._courseCodec = new CourseCodec();
    this._classCodec = new ClassCodec();
    this._gradebookRosterCodec = new GradebookRosterCodec();
    this._gradebookAssignmentCategoryCodec = new GradebookAssignmentCategoryCodec();
    this._gradebookAssignmentCodec = new GradebookAssignmentCodec();
  }

  /**
   * @param value {GradebookRosterIntegrationStatus}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      const selectedLink = value.selectedLink && this._gradebookRosterCodec.encode(value.selectedLink) || null;
      const categories = value.categories.map((category) => this._gradebookAssignmentCategoryCodec.encode(category));
      const assignments = value.assignments.map((assignment) => this._gradebookAssignmentCategoryCodec.encode(assignment));
      return {
        is_linked: value.isLinked,
        is_link_active: value.isLinkActive,
        can_submit_large_batch: value.canSubmitLargeBatch,
        linked_student_ids: value.linkedStudentIds,
        categories: categories,
        assignments: assignments,
        selected_link: selectedLink,
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {GradebookRosterIntegrationStatus}
   */
  decode(value) {
    if (value) {
      const selectedLink = value.selected_link && this._gradebookRosterCodec.decode(value.selected_link) || null;
      const categories = value.categories ? value.categories.map((category) => this._gradebookAssignmentCategoryCodec.decode(category)) : [];
      const assignments = value.assignments ? value.assignments.map((assignment) => this._gradebookAssignmentCodec.decode(assignment)) : [];
      return new GradebookRosterIntegrationStatus(
        value.is_linked,
        value.is_link_active,
        value.can_submit_large_batch,
        value.linked_student_ids,
        categories,
        assignments,
        selectedLink
      );
    }

    return null;
  }

}
