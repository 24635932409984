
'use strict';

import Debouncer from '../../model/util/debouncer';
import SaveStates from '../../components/saving-indicator/save-states';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ContractUserResetPasswordDialog from '../../components/contract-user-reset-password-dialog/contract-user-reset-password-dialog.controller';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import ErrorCodes from '../../model/domain/error-codes';
import BaseEditUserController from './base-edit-user.controller';

export default class ContractUserEditController extends BaseEditUserController {
  constructor($q, $scope, $stateParams, $mdDialog, $window, $log, CacheService, AuthService,
              BreadcrumbService, UserService, ContractService) {
    super();

    'ngInject';

    this.$q = $q;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;
    this.$window = $window;
    this.$log = $log;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {UserService} */
    this._userService = UserService;
    /** @type {ContractService} */
    this._contractService = ContractService;

    this._loadingDialog = LoadingDialogController.show;
    this._contractUserResetPasswordDialog = ContractUserResetPasswordDialog.show;
    this._confirmDialog = ConfirmDialogController.show;

    this.saveState = null;

    /** @type {string} */
    this.firstName = null;
    /** @type {string} */
    this.lastName = null;
    /** @type {string} */
    this.username = null;
    /** @type {string} */
    this.email = null;
    /** @type {string} */
    this.displayName = null;
    /** @type {boolean} */
    this.isCleverImported = undefined;
    /** @type {boolean} */
    this._isUserContractAdmin = undefined;

    /** @type {User} */
    this._user = null;

    this.contractUserForm = null;

    $scope.$on('$destroy', () => this._destroy());
    this.init();
  }

  init() {
    this._debouncer = new Debouncer(
      3000,
      5000,
      () => {
        this._commit();
      },
      this.$scope,
      this.$window);

    this._contractAdminDebouncer = new Debouncer(
      3000,
      5000,
      () => {
        this._updateContractUserAdmin();
      },
      this.$scope,
      this.$window
    );

    this.$q.all({
      contracts: this._cacheService.getContracts(),
      user: this._userService.getUserForContract(this.contractId, this.userId)
    })
      .then((result) => {
        this._contract = result.contracts.find((x) => x.id === this.contractId);
        this.user = result.user;
      })
      .catch((error) => {
        this.error = error;
      });
  }

  get contractId() {
    return this.$stateParams.contractId;
  }

  get contract() {
    return this._contract;
  }

  fieldUpdated(field, form, skip) {
    if (skip) {
      return;
    }

    field.$setTouched();

    if (field === form.username) {
      form.username.$setValidity('duplicate', true);
    }
    if (field === form.email) {
      form.email.$setValidity('duplicate', true);
    }

    if (!(this.email || this.username)) {
      form.username.$setValidity('id-required', false);
    } else {
      form.username.$setValidity('id-required', true);
    }

    let valid = form.$valid;

    this._debouncer.valid = valid;
    this._debouncer.tick();

    if (valid) {
      this.saveState = this.UNSAVED;
    } else {
      this.saveState = this.SAVE_ERROR;
    }
  }

  _commit() {
    this.user.displayName = this.displayName;
    this.user.firstName = this.firstName;
    this.user.lastName = this.lastName;
    this.user.username = this.username || null;
    this.user.email = this.email || null;

    return this._userService.updateContractUser(this.user, this.contractId)
      .then(() => {
        this.saveState = this.SAVED;
      })
      .catch((err) => {
        // TODO: Make the server return a proper error code, not a 500, then remove the includes check
        if (err.data.code === ErrorCodes.DUPLICATE_USERNAME || err.message.includes('user_ux_username')) {
          this.contractUserForm.username.$setValidity('duplicate', false);
        } else if (err.data.code === ErrorCodes.DUPLICATE_EMAIL || err.message.includes('user_ux_email')) {
          this.contractUserForm.email.$setValidity('duplicate', false);
        }
        this.$log.error(err.message, Math.random());
        this.saveState = this.SAVE_ERROR;
      });
  }

  resetPassword() {
    this._contractUserResetPasswordDialog(this.$mdDialog, this.user, this.contractId);
  }

  goBack() {
    if (this._authService.authData.isContractAdmin(this.contractId)) {
      this._breadcrumbService.goBack('root.account.nav.contract-users', {
        contractId: this.contractId
      });
    } else {
      this._breadcrumbService.goBack('root.account.home');
    }
  }

  /**
   * Removes the user from the organization
   */
  removeContractUser() {
    this._confirmDialog(this.$mdDialog, `Are you sure you want to remove ${this.user.name} from this organization?`, 'This account will still exist for individual use.')
      .then(() => {
        return this._contractService.removeUser(this.contractId, this.user.id);
      })
      .then(() => {
        this.goBack();
      });
  }

  /**
   *
   * @returns {boolean}
   */
  get isContractAdmin() {
    return this._authService.authData.isContractAdmin(this.contractId);
  }

  /**
   *
   * @returns {boolean}
   */
  get isUserContractAdmin() {
    return this._isUserContractAdmin;
  }

  /**
   *
   * @param value {boolean}
   */
  set isUserContractAdmin(value) {
    this._contractAdminDebouncer.tick();
    this.saveState = this.UNSAVED;
    this._isUserContractAdmin = value;
  }

  _updateContractUserAdmin() {
    return this._contractService.makeUserContractAdmin(
      this.contractId,
      this.userId,
      this.isUserContractAdmin)
      .then(() => {
        this.saveState = SaveStates.SAVED;
      })
      .catch((error) => {
        this.saveState = SaveStates.SAVE_ERROR;
        this.$log.error(error);
      });
  }

  _destroy() {
    this._debouncer.destroy();
  }
}
