
'use strict';

import Codec from './codec';
import OrderCoupon from '../domain/order-coupon';

/**
 * Encodes/Decodes an order coupon
 */
export default class OrderCouponCodec extends Codec {

  constructor() {
    super();
  }

  // The order coupons are read-only from web services so no need for encoding
  encode() {}

  /**
   * @param value {Object}
   * @return {OrderCoupon|null}
   */
  decode(value) {
    if (value) {
      return new OrderCoupon(
        value.id,
        value.discount,
        value.description,
        value.available
      );
    }
    else {
      return null;
    }
  }

}
