'use strict';

import Debouncer from '../../model/util/debouncer';
import SaveStates from '../../components/saving-indicator/save-states';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import BaseEditUserController from './base-edit-user.controller';

export default class NonContractUserEditController extends BaseEditUserController {
  constructor($q, $scope, $stateParams, $mdDialog, $window, $log, CacheService, AuthService,
              BreadcrumbService, UserService) {
    super();

    'ngInject';

    this.$q = $q;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$window = $window;
    this.$log = $log;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {UserService} */
    this._userService = UserService;

    this._loadingDialog = LoadingDialogController.show;

    this.saveState = null;

    /** @type {string} */
    this.firstName = null;
    /** @type {string} */
    this.lastName = null;
    /** @type {string} */
    this.username = null;
    /** @type {string} */
    this.email = null;
    /** @type {string} */
    this.displayName = null;
    /** @type {boolean} */
    this.isCleverImported = undefined;
    /** @type {boolean} */
    this._isUserContractAdmin = undefined;

    /** @type {User} */
    this._user = null;

    this.anonUserForm = null;

    $scope.$on('$destroy', () => this._destroy());
    this.init();
  }

  init() {
    this._debouncer = new Debouncer(
      3000,
      5000,
      () => {
        this._commit();
      },
      this.$scope,
      this.$window);

    this.$q.all({
      rosterMembers: this._cacheService.getRosterUsers(this.$stateParams.rosterId, true)
    })
      .then(({rosterMembers}) => {
        this.rosterMembers = rosterMembers;
        this._user = rosterMembers.get(this.userId);
        this.displayName = this._user.displayName;
      })
      .catch((error) => {
        this.error = error;
      });
  }

  get rosterId() {
    return this.$stateParams.rosterId;
  }

  get contract() {
    return this._contract;
  }

  fieldUpdated(field, form, skip) {
    if (skip) {
      return;
    }

    field.$setTouched();

    this.displayName
      ? form.displayName.$setValidity('required', true)
      : form.displayName.$setValidity('required', false);

    if (field === form.displayName) {
      form.displayName.$setValidity('duplicate', true);

    }

    let valid = form.$valid;

    this._debouncer.valid = valid;
    this._debouncer.tick();

    if (valid) {
      this.saveState = this.UNSAVED;
    } else {
      this.saveState = this.SAVE_ERROR;
    }
  }

  _commit() {
    this.user.displayName = this.displayName;
    return this._updateAnonUserName(this.user);
  }

  goBack() {
    this._breadcrumbService.goBack('root.account.home');
  }

  _updateAnonUserName(user) {
    if (user.displayName && user.displayName.length > 0) {
      const isDuplicate = this._isDuplicateDisplayName(user, this.rosterMembers);
      if (isDuplicate) {
        this.anonUserForm.displayName.$setValidity('duplicate', false);
        this.saveState = SaveStates.SAVE_ERROR;
        return;
      }
      this._userService.updateAnonStudent(this.rosterId, user.id, user.displayName)
        .then(() => {
          this._cacheService.updateUserForRosterUsers(this.rosterId, user);
          this.saveState = SaveStates.SAVED;
        })
        .catch(() => {
          this.saveState = SaveStates.SAVE_ERROR;
        });
    }
    else {
      this.saveState = this.SAVE_ERROR;
    }
  }

  _isDuplicateDisplayName (user, members) {
    const currentStudents = [...members.values()];
    const isDuplicate = currentStudents.find((studentInfo) =>
      studentInfo.displayName &&
      studentInfo.displayName.toLowerCase() === user.displayName.toLowerCase() &&
      studentInfo.id !== user.id);
    return isDuplicate? true : false;
  }

  get loading() {
    return !this.user && !this.error;
  }

  _destroy() {
    this._debouncer.destroy();
  }
}
