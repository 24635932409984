'use strict';

import moment from 'moment';
import Notification from './notification';
import JSEvent from '../util/js-event';

class AssignmentWorkChange {

  constructor(questionId, score, time, answer, startedAt) {
    this._questionId = questionId;
    this._score = score;
    this._time = moment(time);
    this._answer = answer;
    this._startedAt = startedAt ? moment(startedAt) : undefined;
  }

  static apply(obj) {
    if (obj) {
      const answer = {
        correct: obj.answer && Object.prototype.hasOwnProperty.call(obj.answer, 'correct') ? obj.answer.correct : null,
        value: obj.answer && obj.answer.value,
        format: obj.answer && Object.prototype.hasOwnProperty.call(obj.answer, 'format') ? obj.answer.format : null
      };

      return new AssignmentWorkChange(
        obj.question_id,
        obj.score,
        obj.t,
        answer,
        obj.started_at
      );
    }
    return null;
  }

  /**
   * @returns {string}
   */
  get questionId() {
    return this._questionId;
  }

  /**
   * @returns {string}
   */
  get score() {
    return this._score;
  }

  /**
   * @returns {moment}
   */
  get time() {
    return this._time;
  }

  /**
   * @return {{correct: boolean, value: string}}
   */
  get answer() {
    return this._answer;
  }

  /**
   * @return {undefined|moment}
   */
  get startedAt() {
    return this._startedAt;
  }

}


export default class AssignmentWorkNotification extends Notification {

  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param assignmentId {string}
   * @param assignmentWorkId {string}
   * @param FirebaseService {FirebaseService}
   * @param [showInitialNotification] {boolean}
   */
  constructor(notificationShardKey, assignmentId, assignmentWorkId, FirebaseService, showInitialNotification) {
    super(
      Notification.V3,
      notificationShardKey,
      `assignments/${assignmentId}/grades/assignment_work/${assignmentWorkId}`,
      FirebaseService,
      showInitialNotification,
      'AssignmentWorkNotification'
    );

    this._scoreUpdated = new JSEvent(this);

    this._logTrace('cstr complete');
  }

  /**
   * @returns {JSEvent}
   */
  get scoreUpdated() {
    return this._scoreUpdated;
  }

  /**
   * @returns {AssignmentWorkChange|null}
   */
  get value() {
    return this._value;
  }

  _changed(value) {
    this._value = AssignmentWorkChange.apply(value);
    if (this._value) {
      this._logTrace(`_changed questionId=${this._value.questionId}, value=${angular.toJson(value)}`);
      this._scoreUpdated.raise(this._value);
    }
  }

}
