
'use strict';

import { DefaultUserStickers } from '../../model/domain/user-sticker';
import ImportImageDialogController from '../import-image-dialog/import-image-dialog.controller';
import ImagePickerDialogTemplate from './image-picker-dialog.html';
import AddNewStickerDialogController from '../add-new-sticker-dialog/add-new-sticker-dialog.controller';

export default class ImagePickerDialog {

  constructor($q, $mdDialog, deferred, activeStickerUrl, isFromSessionWork) {
    'ngInject';

    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this._deferred = deferred;
    this._activeStickerUrl = activeStickerUrl;
    this._isFromSessionWork = isFromSessionWork;

    this._importImageDialog = ImportImageDialogController.show;
    this._addNewStickerDialog = AddNewStickerDialogController.show;
  }

  get defaultUserStickers() {
    return DefaultUserStickers;
  }

  /**
   * @returns {boolean}
   */
  get isFromSessionWork() {
    return this._isFromSessionWork;
  }

  /**
   * @param $mdDialog {$mdDialog}
   */
  openAddNewStickerDialog(){
    this._addNewStickerDialog(this.$mdDialog);
  }

  /**
   * Shows the image picker dialog
   *
   * @param $mdDialog
   * @param deferred {Promise}
   * @param activeStickerUrl {string}
   * @returns {Promise}
   */
  static show($mdDialog, deferred, activeStickerUrl, isFromSessionWork = false) {

    $mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: true,
      template: ImagePickerDialogTemplate,
      controller: ImagePickerDialog,
      controllerAs: 'ctrl',
      focusOnOpen: false,
      locals: {
        deferred,
        activeStickerUrl,
        isFromSessionWork
      }
    });

    return deferred.promise;
  }

  /**
   * @param stickerUrl {string}
   */
  selectSticker(stickerUrl) {
    this.$mdDialog.hide();

    if (this._isFromSessionWork) {
      this._deferred.resolve(stickerUrl);
      return this._addNewStickerDialog(this.$mdDialog, stickerUrl);
    }

    return this._deferred.resolve(stickerUrl);
  }

  /**
   * @param sticker {UserSticker}
   * @return {boolean}
   */
  isActive(sticker) {
    return sticker.url === this._activeStickerUrl;
  }

  selectCustomSticker() {
    this.$mdDialog.cancel();

    this._importImageDialog(this.$q, this.$mdDialog, undefined, false, false)
      .then(({images}) => {
        const image = images && images[0];

        if (this._isFromSessionWork) {
          this._deferred.resolve(image.imageSrc);
          return this._addNewStickerDialog(this.$mdDialog, image.imageSrc);
        }

        return this._deferred.resolve(image.imageSrc);
      });
  }
}


