/**
 * Used to instantiate and initialize services
 * Static service needs to be injected here so that it can be accessed statically
 *
 * @param DomObservabilityService {DomObservabilityService}
 * @param StorageService {StorageService}
 * @param StaticService {StaticService}
 * @param ImageEditService {ImageEditService}
 * @param CacheService {CacheService}
 * @param BootstrapService {BootstrapService}
 * @param $log
 * @param constants
 */
export function runBlock (DomObservabilityService, StorageService, StaticService,
                          ImageEditService, CacheService, BootstrapService,
                          $log, constants) {
  'ngInject';

  BootstrapService.init();

  $log.info('starting classkick', constants.version);
}
