'use strict';

import ChatMessage, {ChatMessageTypes} from '../../model/domain/chat-message';
import ChatMessageRead from '../../model/domain/chat-message-read';
import CollectionObserver from '../../model/firebase-mapping/collection-observer';
import moment from 'moment';

class MessageObserver extends CollectionObserver {

  constructor(ctrl) {
    super();
    /** @type {ChatMessageManager} */
    this._ctrl = ctrl;
  }

  onAdded(value) {
    if (this._ctrl.isNew(value.timestamp) && this._ctrl.showHelpCenter) {
      this._ctrl.markRead();
    }
  }

  onChanged() {}

  onRemoved() {}

}

class MessagesReadObserver extends CollectionObserver {

  constructor($timeout) {
    super();
    this.$timeout = $timeout;
  }

  onAdded() {
    this.$timeout(() => {});
  }

  onChanged() {
    this.$timeout(() => {});
  }

  onRemoved() {}

}

export default class ChatMessageManager {

  constructor($timeout, ToolbarService, AuthService, FirebaseService, AnalyticsService) {
    this.$timeout = $timeout;

    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    /** @type {ChatMessageList|undefined} */
    this._messages = undefined;
    this._firstMessage = undefined;
  }

  reset() {
    this._firstMessage = true;
  }

  get hasChatMessages() {
    return this._messages && this._messages.messages.size > 0;
  }

  /**
   * @returns {Array}
   */
  get messages() {
    if (this._messages) {
      return this._messages.messages.map((chatMessage) => {
        return chatMessage;
      });
    } else {
      return '';
    }
  }

  /**
   * @param value {Array}
   */
  set messages(value) {
    this._init(() => {
      this._messages = value;
    });
  }

  _init(f) {
    this.reset();

    if (this._messages) {
      this._messages.messagesRead.unsubscribe(this._messagesReadObserver);
      this._messages.messages.unsubscribe(this._messageObserver);
    }

    f();

    if (this._messages) {
      this._messagesReadObserver = new MessagesReadObserver(this.$timeout);
      this._messages.messagesRead.subscribe(this._messagesReadObserver);

      this._messageObserver = new MessageObserver(this);
      this._messages.messages.subscribe(this._messageObserver);

      this._messages.start();
    }
  }

  /**
   * @param type {string} help | check | hand_down | message
   * @param userId {string}
   * @param displayName {string}
   * @param [text] {string}
   */
  send(type, userId, displayName, text = '') {
    let message = new ChatMessage(
      this._firebaseService.newId(),
      type,
      userId,
      displayName,
      text,
      moment()
    );
    return this._messages.save(message)
      .then(() => {
        if (type === ChatMessageTypes.MESSAGE) {
          this._sendMixpanelEvent();
        }
      });
  }

  _sendMixpanelEvent() {
    if (this._firstMessage) {
      this._analyticsService.chatSent();
      this._firstMessage = false;
    }
  }

  /**
   * @return {ChatMessageRead[]}
   */
  get messagesRead() {
    if (this._messages) {
      return this._messages.messagesRead.map();
    }
    else {
      return [];
    }
  }

  /**
   * @return {ChatMessage}
   */
  get lastMessage() {
    let messages = this.messages;
    return messages && messages[messages.length - 1];
  }

  /**
   * @param timestamp {moment}
   * @return {boolean}
   */
  isNew(timestamp) {
    return this._now && this._now.isBefore(timestamp);
  }

  get showHelpCenter() {
    let sidenav = this._toolbarService.sidenavManager;
    return sidenav.isModeVisible(sidenav.modes.HELP_CENTER);
  }

  markRead() {
    let lastMessage = this.lastMessage;

    if (lastMessage) {
      this._now = moment();
      let chatMessageRead = new ChatMessageRead(
        this.currentUserId,
        lastMessage.id,
        this._now
      );
      return this._messages.markRead(chatMessageRead);
    }
    else {
      return Promise.resolve();
    }
  }

  /**
   * @param currentUserId {string}
   * @return {boolean}
   */
  hasUnreadMessages(currentUserId) {
    let currentUsersReadMessageStatus = this.messagesRead.find((messageRead) => messageRead.id === currentUserId);

    if (this.messages && this.messages.length > 0) {
      for (let index = this.messages.length - 1; index >= 0; index--) {
        let chatMessage = this.messages[index];
        if (currentUsersReadMessageStatus && chatMessage.id === currentUsersReadMessageStatus.messageId) {
          return false;
        } else if (chatMessage.type === ChatMessageTypes.MESSAGE) {
          return true;
        }
      }
    }
    return false;
  }

  get currentUserId() {
    return this._authService.currentUserId;
  }

}
