'use strict';

import Codec from './codec';
import GradebookIntegrationStatus from '../domain/gradebook-integration-status';
/**
 * Encodes/decodes Gradebook integration status
 */
export default class GradebookIntegrationStatusCodec extends Codec {

  /**
   * @param value {GradebookIntegrationStatus}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        is_linked: value.isLinked,
        is_link_active: value.isLinkActive,
        number_of_invites: value.numberOfInvites,
        minimum_wait_time: value.minimumWaitTime,
        lms_email: value.lmsEmail,
        previous_admin_email: value.previousAdminEmail
      };
    }
    return null;
  }

  /**
   * @param value {object}
   * @returns {GradebookIntegrationStatus}
   */
  decode(value) {
    if (value) {
      return new GradebookIntegrationStatus(
        value.is_linked,
        value.is_link_active,
        value.number_of_invites,
        value.minimum_wait_time,
        value.lms_email,
        value.previous_admin_email
      );
    }

    return null;
  }
}
