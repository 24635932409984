'use strict';

export default class FirebaseInstance {

  /**
   * @param id {string} the firebase instance id
   * @param baseRef {firebase.database.Reference} the base firebase reference for this instance
   * @param onConnectionChange {Function} function called when connection to instance changes after initial connection
   */
  constructor(id, baseRef, onConnectionChange) {
    this._id = id;
    this._baseRef = baseRef;
    this._connected = false;
    let initiallyConnected = false;

    this._baseConnectionRef = this._baseRef.$child('.info/connected');
    this._baseConnectionRef.on('value', (snapshot) => {
      // Determine the current connection state for this instance
      this._connected = snapshot.val() === true;

      // Keep track of if this instance has ever connected
      initiallyConnected = initiallyConnected || this._connected;

      // Only call connection change callback if instance has connected before
      if (initiallyConnected) {
        onConnectionChange();
      }
    });
  }

  /**
   * @return {boolean}
   */
  get connected() {
    return this._connected;
  }

  /**
   * Returns a firebase reference
   * @param [url] {string}
   * @return {firebase.database.Reference}
   */
  ref(url) {
    if (url) {
      return this._baseRef.$child(url);
    }
    else {
      return this._baseRef;
    }
  }

}
