'use strict';

import RosterSelectionDialogTemplate from './roster-selection-dialog.html';

export default class RosterSelectionDialogController {

  /**
   * @ngInject
   */
  constructor($q, $mdDialog, $window, CacheService, StaticContentService, rosters, title) {
    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this.$window = $window;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;

    this._rosters = rosters.sort(this._sortFunc);
    this._title = title || 'Select Roster Points to Export';
    this.searchTerm = null;
    this._filteredRosters = null;

    /** @type {Set.<string>} */
    this._selectedRosterIds = new Set();


    this._loading = false;
  }

  /**
   * @return {Assignment[]}
   */
  get rosters() {
    return this._filteredRosters || this._rosters;
  }

  get title() {
    return this._title;
  }

  /**
   * @returns {boolean}
   */
  get loading() {
    return this._loading && !this._rosters;
  }

  /**
   * Launches assignment select dialog
   * @param $mdDialog
   * @param rosters {Roster[]}
   * @param [title] {string}
   */
  static show($mdDialog, rosters, title) {
    return $mdDialog.show({
      controller: RosterSelectionDialogController,
      template: RosterSelectionDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: true,
      locals: {
        rosters,
        title
      }
    });
  }

  search() {
    if (this.searchTerm && this._rosters) {
      const searchTerm = this.searchTerm.toLowerCase();
      this._filteredRosters = this._rosters.filter((x) =>
        x.name.toLowerCase().includes(searchTerm)
      );
    }
    else {
      this._filteredRosters = null;
    }
  }

  toggleRoster(roster) {
    if (this._selectedRosterIds.has(roster.id)) {
      this._selectedRosterIds.delete(roster.id);
    }
    else {
      this._selectedRosterIds.add(roster.id);
    }
  }

  export() {
    let selectedRosterIdsInOrder = this._rosters
      .filter((roster) => this._selectedRosterIds.has(roster.id))
      .sort(this._sortFunc)
      .map((roster) => roster.id);

    this._cleanRosters();

    this.$mdDialog.hide(selectedRosterIdsInOrder);
  }

  _cleanRosters() {
    this._rosters.forEach((roster) => {
      delete roster.toggled;
    });
  }

  /**
   * @param rosterA {Roster}
   * @param rosterB {Roster}
   * @return {number}
   */
  _sortFunc(rosterA, rosterB) {
    var nameA = rosterA.name.toUpperCase();
    var nameB = rosterB.name.toUpperCase();
    return nameA.localeCompare(nameB);
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
