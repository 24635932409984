'use strict';

import AssignmentListItem from './assignment-list-item';

export default class FolderListItem extends AssignmentListItem {

  constructor(folder, newlyCopied) {
    super(folder, newlyCopied);
  }

  get folder() {
    return this.assignment;
  }
}
