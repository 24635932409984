'use strict';

import HelpRequestManager from './help-request-manager';
import ChatMessageManager from './chat-message-manager';
import { ChatMessageTypes } from '../../model/domain/chat-message';

export default class HelpCenterManager {

  constructor($q, $timeout, ToolbarService, HelpRequestService, AuthService, FirebaseService, CacheService, StorageService, FeedbackService, AnalyticsService, AnalyticsMetaService) {
    this.$q = $q;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {FeedbackService} */
    this._feedbackService = FeedbackService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {AnalyticsMetaService} */
    this._analyticsMetaService = AnalyticsMetaService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._helpRequestManager = new HelpRequestManager(ToolbarService, HelpRequestService, AuthService, StorageService);
    this._chatMessageManager = new ChatMessageManager($timeout, ToolbarService, AuthService, FirebaseService, AnalyticsService);
    this._userInfo = null;
  }

  /**
   * @returns {HelpRequestManager}
   */
  get helpRequestManager() {
    return this._helpRequestManager;
  }

  /**
   * @returns {ChatMessageManager}
   */
  get chatMessageManager() {
    return this._chatMessageManager;
  }

  /**
   * @returns {Promise.<User>}
   */
  get currentUser() {
    if (!this._userInfo) {
      this._userInfo = this._storageService.userInfo;
    }

    if (this._cacheService.getUser(false).id !== this._userInfo.id) {
      return this._cacheService.getUser(true);
    }

    return this._cacheService.getUser(false);
  }

  newHelpRequest_Help() {
    return this._helpRequestManager.newHelpRequest_Help()
      .then(() => this.currentUser)
      .then((currentUser) => {
        return this.chatMessageManager.send(
          ChatMessageTypes.HELP,
          currentUser.id,
          currentUser.name
        );
      });
  }

  newHelpRequest_Check() {
    return this._helpRequestManager.newHelpRequest_Check()
      .then(() => this.currentUser)
      .then((currentUser) => {
        return this.chatMessageManager.send(
          ChatMessageTypes.CHECK,
          currentUser.id,
          currentUser.name
        );
      });
  }

  lowerHelpRequest() {
    return this._helpRequestManager.lowerHelpRequest()
      .then(() => this.currentUser)
      .then((currentUser) => {
        this.chatMessageManager.send(
          ChatMessageTypes.HAND_DOWN,
          currentUser.id,
          currentUser.name
        );
      });
  }

  /**
   * @param text {string}
   * @returns {Promise}
   */
  sendMessage(text) {
    if (!text) {
      return this.$q.resolve([]);
    }

    return this.currentUser
      .then((currentUser) => {
        this._handleChatFeedback(text);
        return this.$q.all([
          this.chatMessageManager.send(
            ChatMessageTypes.MESSAGE,
            currentUser.id,
            currentUser.name,
            text
          ),
          this._handleHelpRequestOnMessage(currentUser),
          this._handleFeedbackNotificationOnMessage(currentUser.id, currentUser.name),
          this.chatMessageManager.markRead()
        ]);
      });
  }

  _handleHelpRequestOnMessage(currentUser) {
    if (this.helpRequestManager.helpeeId === this.helpRequestManager.currentUserId &&
      !this.helpRequestManager.isHelpRequestHelp &&
      !this.helpRequestManager.isHelpRequestCheck &&
      !this.helpRequestManager.isHelpRequestChat &&
      !this.helpRequestManager.isLoadingForHelp &&
      !this.helpRequestManager.isLoadingForCheck) {
      return this.helpRequestManager.newHelpRequest_Chat();
    }
    else if (this.helpRequestManager.helpeeId !== this.helpRequestManager.currentUserId && this.helpRequestManager.hasHelpRequest) {
      let isHelpRequestChat = this.helpRequestManager.isHelpRequestChat;
      return this.helpRequestManager.resolve()
        .then(() => {
          if (isHelpRequestChat) {
            return this.$q.resolve();
          }
          else {
            return this.chatMessageManager.send(
              ChatMessageTypes.HAND_DOWN,
              currentUser.id,
              currentUser.name
            );
          }
        });
    }
    else {
      return this.$q.resolve();
    }
  }

  get hasUnreadMessages() {
    return this.chatMessageManager.hasUnreadMessages(this.helpRequestManager.currentUserId);
  }

  /**
   * @param currentUserId {string}
   * @param currentUserName {string}
   * @return {Promise}
   */
  _handleFeedbackNotificationOnMessage(currentUserId, currentUserName) {
    if (this.helpRequestManager.helpeeId !== this.helpRequestManager.currentUserId) {
      return this.$q.all([
        this._feedbackService.give(
          this.helpRequestManager.helpeeId,
          this.helpRequestManager.assignmentWork,
          this.helpRequestManager.questionId,
          currentUserId,
          currentUserName
        )
      ]);
    }
    else {
      return this.$q.resolve();
    }
  }

  _handleChatFeedback(message) {
    if (this._analyticsMetaService.isTeacherFeedbackPage()) {
      this._analyticsService.sendEvent({
        eventTag: 'feedback:chat_added',
        properties: {
          ...this._analyticsMetaService.buildFeedbackAddedMeta(),
          messageLength: message.length || 0
        }
      });
    }
  }

  reset() {
    this._helpRequestManager.reset();
    this._chatMessageManager.reset();
  }
}
