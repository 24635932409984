'use strict';

import RosterListItem from '../../model/domain/roster-list-item';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import { Locations, PaywallSources, TeacherType } from '../../services/mixpanel/mixpanel.service';
import { Colors } from '../../model/domain/colors';
import ViewHelpDialogController, { ViewHelps } from '../../components/view-help-dialog/view-help-dialog.controller';
import ProInfoDialogController from '../../components/pro-info-dialog/pro-info-dialog.controller';
import { GoogleClassroom } from '../../services/google/google-classroom';
import Debouncer from '../../model/util/debouncer';
import SaveStates from '../../components/saving-indicator/save-states';
import moment from 'moment';

/**
 * Defines the potential columns in the rosters table
 */
export class RostersTableColumns {

  static get NAME() {
    return 'name';
  }

  static get HELPS() {
    return 'helps';
  }

  static get ROSTER_TYPE() {
    return 'roster_type';
  }
}

export default class RostersListController {
  constructor($log, $q, $scope, $window, $state, $timeout, $mdSidenav, $mdDialog, $mdToast, RosterService,
              HelpRequestService, AuthService, CacheService, AnalyticsService, BreadcrumbService, GoogleClassroomService,
              StorageService) {
    'ngInject';

    if (!AuthService.authData.isTeacher) {
      $state.go('^.student-assignments-list');
      return;
    }

    this.$log = $log;
    this.$q = $q;
    this.$scope = $scope;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$state = $state;
    this.$mdSidenav = $mdSidenav;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;

    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {HelpRequestService} */
    this._helpRequestService = HelpRequestService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {GoogleClassroomService} */
    this._googleClassroomService = GoogleClassroomService;
    /** @type {StorageService} */
    this._storageService = StorageService;


    this._googleClassroom = new GoogleClassroom(this.$log, this.$q, this.$mdDialog, this.$mdToast, this._authService, this._googleClassroomService);

    // column configuration
    this._showName = true;
    this._showChecks = true;
    this._showHelps = true;
    this._showHideOption = true;
    this._showRosterType = true;

    this._helpDialog = ViewHelpDialogController.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._proInfoDialog = ProInfoDialogController.show;

    // configures the initial behavior of the table
    this._orderBy = RostersTableColumns.NAME;
    this._isAscending = true;

    // configures the initial search bar behavior
    this._query = '';

    this._loading = true;
    this._error = undefined;
    this._saveMessage = '';
    this._rosterListItemMap = {};

    this.selectedRosters = {};
    this.list = [];

    // Clean up after ourselves
    $scope.$on('$destroy', () => this._destroy());

    this._init();
  }

  /**
   * Loads a user's rosters and all the help requests for each roster,
   * then configures notifications for help request updates
   */
  _init() {
    this._loading = true;

    this._saveRoster = new Debouncer(100, 500, () => {
      Object.keys(this.selectedRosters).map((rosterId) => {
        if (this.selectedRosters[rosterId] && this.rosterListItemMap[rosterId]) {
          this._rosterService.update(this.rosterListItemMap[rosterId].roster).then(() => {
            this._cacheService.updateRosterForUser(this.rosterListItemMap[rosterId].roster);
            this._saveMessage = SaveStates.SAVED;
            const syncedRoster = this.rosterListItemMap[rosterId].roster.isGoogleRoster || this.rosterListItemMap[rosterId].roster.isCleverRoster;
            this._storageService.lastHidRoster = moment().format('YYYY-MM-DD HH:mm:ss');
            this._analyticsService.hideRoster(syncedRoster);
            this._resetRosterList(rosterId);
            this._saveMessageTimeout();

          }).catch(() => {
            this._saveMessage = SaveStates.SAVE_ERROR;
          });
        } else {
          this._saveMessage = SaveStates.SAVE_ERROR;
        }
      });
    }, this.$scope, this.$window);

    if (this.isACoTeacher()) {
      this._storageService.setUserInfoBackToCoTeacherAuth();
      this._authService.processTokenResult(this._authService.coTeacherAuthData.token, this._storageService.rememberMe)
        .then(() => {
          this._initializeRosterListData();
        });
    } else {
      return this._initializeRosterListData();
    }
  }

  _initializeRosterListData() {
    return this.$q
      .all({
        rosters: this._cacheService.getRostersForUser(true),
        proInfo: this._cacheService.getProInfo()
      })
      .then(({rosters, proInfo}) => {

        this._proInfo = proInfo;

        let promises = [];

        angular.forEach(rosters, (roster) => {
          if (!roster.isHidden) {
            promises.push(this._helpRequestService.getHelpRequestSetForRoster(this._authService.currentUserId, roster.id).then((helpRequests) => {
              let rosterListItem = new RosterListItem(roster, helpRequests);
              rosterListItem.helpRequests.start();
              return rosterListItem;
            }));
          }
        });

        return this.$q.all(promises);
      })
      .then((result) => {
        result.map((rosterListItem) => {
          this._rosterListItemMap[rosterListItem.roster.id] = rosterListItem;
        });
        this.list = Object.values(this.rosterListItemMap);
        this._loading = false;
      })
      .catch((error) => {
        this._error = error;
      });
  }

  /**
   * stop listening for notifications for roster help requests
   */
  _destroy() {
    this.list.forEach((rosterListItem) => {
      rosterListItem.helpRequests.stop();
    });
    this._saveRoster.destroy();
  }

  /**
   * Removes selected roster from the all lists and stops listening for notifications
   * @param rosterId {string}
   */
  _resetRosterList(rosterId){
    if (this.selectedRosters[rosterId]) {
      delete this.selectedRosters[rosterId];
    }
    if (this.rosterListItemMap[rosterId]) {
      this.rosterListItemMap[rosterId].helpRequests.stop();
      delete this.rosterListItemMap[rosterId];
    }
    this.list = Object.values(this.rosterListItemMap);
  }

  /**
   * Resets save message after 3 secs
   */
  _saveMessageTimeout() {
    this.$timeout(() => {
      this._saveMessage = '';
    }, 3000);
  }

  /**
   * @return {boolean}
   */
  isACoTeacher() {
    return this._authService.isACoTeacher();
  }

  hideFeatureFromCoTeacher(roster){
    if (this._authService.coTeacherAuthData){
     return  this.isACoTeacher() || (this._authService.coTeacherAuthData.id !== roster.ownerId);
    }
    return this._authService.authData.id !== roster.ownerId;
  }

  /**
   * indicates if table should display roster name column
   * @returns {boolean}
   */
  get showName() {
    return this._showName;
  }

  /**
   * indicates if table should display check requests column
   * @returns {boolean}
   */
  get showChecks() {
    return this._showChecks && this.hasRequests;
  }

  /**
   * indicates if table should display help requests column
   * @returns {boolean}
   */
  get showHelps() {
    return this._showHelps && this.hasRequests;
  }

  /**
   * indicates if table should display hide option column
   * @returns {boolean}
   */
  get showHideOption() {
    return this._showHideOption;
  }

  /**
   * indicates if table should display roster type column
   * @returns {boolean}
   */
  get showRosterType() {
    return this._showRosterType;
  }

  /**
   * gets the current value of the search bar query
   * @returns {string}
   */
  get query() {
    return this._query;
  }

  /**
   * sets the current value of the search bar query
   * @param value {string}
   */
  set query(value) {
    this._query = value;
  }

  /**
   * @returns {object.<string, <RosterListItem>}
   */
  get rosterListItemMap() {
    return this._rosterListItemMap;
  }

  /**
   * @returns {object.<string, <RosterListItem>}
   */
  set rosterListItemMap(value) {
    this._rosterListItemMap = value;
  }

  /**
   * @returns {boolean}
   */
  get loading() {
    return this._loading;
  }

  /**
   * @return {Error|undefined}
   */
  get error() {
    return this._error;
  }

  /**
   * @returns {boolean}
   */
  get hasRequests() {
    return this.totalRequests > 0;
  }

  /**
   * Determines the total number of help requests
   * @returns {number}
   */
  get totalRequests() {
    return this.list.reduce((accum, rosterListItem) => {
      return accum + rosterListItem.helpRequests.total;
    }, 0);
  }

  /**
   * indicates if currently selected column should be ascending or descending
   * @returns {boolean}
   */
  get isAscending() {
    return this._isAscending;
  }

  /**
   * indicates when to show carrot next to the name column in table header
   * @returns {boolean}
   */
  get showNameCarrot() {
    return this._orderBy === RostersTableColumns.NAME;
  }

  /**
   * indicates when to show carrot next to the help request column in table header
   * @returns {boolean}
   */
  get showHelpsCarrot() {
    return this._orderBy === RostersTableColumns.HELPS;
  }

  /**
   * indicates when to show carrot next to the roster type column in table header
   * @returns {boolean}
   */
  get showRosterTypeCarrot() {
    return this._orderBy === RostersTableColumns.ROSTER_TYPE;
  }

  /**
   * message to show when in the process of saving or when item is saved
   * @returns {string}
   */
  get saveMessage() {
    return this._saveMessage;
  }

  /**
   * @return {boolean}
   */
  get showBanner() {
    return (this._storageService.lastSeenTrialConversionBanner && this._storageService.lastSeenTrialConversionBanner.showBanner === true)
        || (this._storageService.lastSeenRenewalConversionBanner && this._storageService.lastSeenRenewalConversionBanner.showBanner === true)
        || (this._storageService.lastSeenAssignmentNotificationBanner && this._storageService.lastSeenAssignmentNotificationBanner.showBanner === true);
  }

  /**
   * handles the logic to change the focus and order of columns
   *
   * @param colName {string} one of the TableColumn properties
   * @param shouldAscend {boolean} should the column be ascending or descending
   */
  setOrToggle(colName, shouldAscend) {
    if (this._orderBy !== colName) {
      this._orderBy = colName;
      this._isAscending = shouldAscend;
    }
    else {
      this._isAscending = !this._isAscending;
    }
  }

  /**
   * sets the list order to the name column and/or toggles ascending/descending
   */
  setOrToggleName() {
    this.setOrToggle(RostersTableColumns.NAME, true);
  }

  /**
   * sets the list order to the helps column and/or toggles ascending/descending
   */
  setOrToggleHelps() {
    this.setOrToggle(RostersTableColumns.HELPS, false);
  }

  /**
   * sets the list order to the roster type column and/or toggles ascending/descending
   */
  setOrToggleRosterType() {
    this.setOrToggle(RostersTableColumns.ROSTER_TYPE, true);
  }

  /**
   * @returns {Function} returns a function that know which column value to order by
   */
  orderBy() {
    let orderBy = this._orderBy;
    return function (item){
      if (RostersTableColumns.NAME === orderBy) {
        return item.roster.name;
      }
      else if (RostersTableColumns.HELPS === orderBy) {
        return item.helpRequests.total;
      }
      else if (RostersTableColumns.ROSTER_TYPE === orderBy){
        return item.roster.provider;
      }
    };
  }

  /**
   * @returns {Function} returns a function that knows how to filter the list of rosters
   */
  searchFilter() {
    let query = this.query;
    return function(item) {
      if (query.length > 0) {
        return (item.roster.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
      }
      return true;
    };
  }

  /**
   * Creates a new roster and transitions to new roster in roster edit state
   */
  create() {
    this._rosterService.create({
        name: 'New Roster',
        color: Colors.ROSTER_COLORS[Math.floor(Math.random() * 12)].hex,
        allowPeerHelp: false,
        allowNewMembers: true,
        allowMultiLogin: true
      }).then((roster) => {
        this.$state.go('root.account.nav.roster', {
          rosterId: roster.id,
          isNew: true
        });
      });
    this._analyticsService.rosterCreatedFrom(Locations.ROSTER_LIST);
  }

  /**
   * Deletes a roster with the specified id
   * @param rosterId {string}
   * @param rosterName {string}
   */
  delete(rosterId, rosterName) {
    let deleteMessage = `All work for all of the students in this roster will be deleted. This cannot be undone. <br> Are you sure you want to delete ${rosterName}?`;

    this._confirmDialog(this.$mdDialog, deleteMessage, '').then(() => {
      this._cacheService.deleteRoster(rosterId).then(() => {
        delete this.rosterListItemMap[rosterId];
         this.list = Object.values(this.rosterListItemMap);
      });
    });
  }


  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }

  showHelp() {
    this._helpDialog(this.$mdDialog, ViewHelps.RostersList);
  }

  /**
   * @param item {RosterListItem}
   */
  goToRoster(item) {
    if (item.roster.coteacherAccess) {
      const teacherType = this.isACoTeacher() && this._authService.coTeacherAuthData.id !== item.roster.ownerId
          ? TeacherType.CO_TEACHER
          : TeacherType.PRIMARY;
      const userId = this._authService.coTeacherAuthData ? this._authService.coTeacherAuthData.id : this._authService.authData && this._authService.authData.id;
      this._analyticsService.openCoTeachingRoster(item.roster.id, userId, teacherType);
    }
    this._breadcrumbService.go('root.account.nav.roster', {rosterId: item.roster.id});
  }

  /**
   * Imports Google Courses as Classkick Rosters
   */
  importFromGoogleClassroom() {
    if (!this.hasStudentAccounts) {
      return this._proInfoDialog(this.$mdDialog, PaywallSources.IMPORT_GOOGLE_ROSTERS);
    }
    else if (!this._googleClassroom.isGoogleAuthed) {
      return this._googleClassroom.showNoGoogleAuthError();
    }
    else {
      return this._googleClassroom.import()
        .then(() => {
          return this._init();
        });
    }
  }

  /**
   * Syncs the Google Course passed
   * @param roster {Roster}
   */
  syncGoogleRoster(roster) {
    if (!this.hasStudentAccounts) {
      this._proInfoDialog(this.$mdDialog, PaywallSources.IMPORT_GOOGLE_ROSTERS);
    }
    else if (!this._googleClassroom.isGoogleAuthed) {
      this._googleClassroom.showNoGoogleAuthError();
    }
    else {
      this._googleClassroom.sync(roster);
    }
  }

  /**
   * @return {ProInfo}
   */
  get pro() {
    return this._proInfo;
  }

  /**
   * @return {boolean}
   */
  get hasStudentAccounts() {
    return this.pro && this.pro.hasStudentAccounts;
  }

  goToHiddenRosters(){
    this._analyticsService.goToHiddenRostersPage();
    this._storageService.lastVisitedHiddenRoster = moment().format('YYYY-MM-DD HH:mm:ss');
    this._breadcrumbService.go('root.account.nav.hidden-rosters');
  }

  /**
   * Saves the roster's current state
   */
  save() {
    this._saveMessage = SaveStates.UNSAVED;
    this._saveRoster.tick();
  }

  /**
   * Hide selected roster by updating and saving their new property
   * @param roster {Roster}
   */
  hideRoster(roster){
    roster.properties = {...roster.properties, 'is_hidden': 'hidden'};
    this.save();
  }

  /**
   * Check if there are any Clever and Google rosters from the list
   * @return {boolean}
   */

  hasGoogleOrCleverRosters(){
    return !!this.list.find((rosterListItem) => rosterListItem.roster.isGoogleRoster || rosterListItem.roster.isCleverRoster);
  }

  /**
   * Determines whether to show red dot notification for hidden rosters
   * @return {boolean}
   */
  showHiddenRosterNotification() {
    const lastVisitedHiddenRoster = this._storageService.lastVisitedHiddenRoster;
    const lastHidRoster = this._storageService.lastHidRoster;


    if (lastHidRoster && !lastVisitedHiddenRoster) {
      return true;
    } else if (lastHidRoster && lastVisitedHiddenRoster) {
        return moment(lastHidRoster).isAfter(moment(lastVisitedHiddenRoster));
    } else {
      return false;
    }
  }
}
