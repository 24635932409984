
'use strict';

import Control from '../control';
import JSEvent from '../../util/js-event';
import Change from './change';
import Debouncer from '../../util/debouncer';

/**
 * Base class for controls which are persisted and/or owned by a user
 */
export default class Element extends Control {
  /**
   * @param id {string}
   * @param type {string}
   * @param metadata {ElementMetadata}
   */
  constructor(id, type, metadata) {
    super(id, type);

    this._changed = new JSEvent(this);
    this._deleted = new JSEvent(this);

    this._previewChanged = new JSEvent(this);
    this._metadata = metadata;

    this._debouncedChange = undefined;
    this._debouncer = new Debouncer(1000, 5000, () => this._flushChange());
  }

  /**
   * @return {boolean}
   */
  get isChildManipulative() {
    return false;
  }

  /**
   * @return {boolean}
   */
  get isParentManipulative() {
    return false;
  }

  /**
   * @return {boolean}
   */
  get isManipulative() {
    return this.isParentManipulative || this.isChildManipulative;
  }

  /**
   * User friendly copy of element. Element types that are confusing or difficult to read
   * should override this property.
   * @return {string}
   */
  get typeDisplay() {
    return this._type;
  }

  /**
   * User friendly copy of element's answer, if applicable. Element with answers should
   * override this property (e.g. multi choice and fill-in-the-blank).
   * @return {string}
   */
  get answerDisplay() {
    return '';
  }

  /**
   * @returns {ElementMetadata}
   */
  get metadata() {
    return this._metadata;
  }

  /**
   * @param value {ElementMetadata}
   */
  set metadata(value) {
    this._metadata = value;
  }

  /**
   * Emitted when this element is modified. Used to trigger saves to an element, implement undo/redo
   * @returns {JSEvent.<Change>}
   */
  get changed() {
    return this._changed;
  }

  /**
   * Emitted when this element is modified for the first time.
   * @returns {JSEvent.<Change>}
   */
  get previewChanged() {
    return this._previewChanged;
  }

  /**
   * Emitted when this element is deleted. Used to remove an element, implement undo/redo
   * @returns {JSEvent.<Change>}
   */
  get deleted() {
    return this._deleted;
  }

  /**
   * Raises the deleted event
   */
  delete() {
    this._deleted.raise(new Change(this.id, this.fromSnapshot, this.snapshot(), true));
  }

  /**
   * Raises the changed event
   * @param previous {object} the snapshot of the previous state of this object
   * @protected
   */
  _onChanged(previous) {
    this.previewChanged.raise(this);
    var previousState = previous;
    if (this._debouncedChange) {
      previousState = this._debouncedChange.previousState;
    }

    this._debouncedChange = new Change(this.id, this.fromSnapshot, previousState);
    this._debouncer.tick();
  }

  /**
   * Emits the changed event and updates the dom for an atomic change
   *
   * @param f {function} no-argument function which modifies the element
   * @protected
   */
  _trackChange(f) {
    let previous = this.snapshot();

    f();

    this.tryUpdate();
    this._onChanged(previous);
  }

  /**
   * Flushes any outstanding changes into the system
   */
  flush() {
    this._debouncer.flush();
  }

  /**
   * Callback method from debouncer
   * @private
   */
  _flushChange() {
    if (this._debouncedChange) {
      this.changed.raise(this._debouncedChange);
      this._debouncedChange = undefined;
    }
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {Element}
   * @param [forceUpdate] {boolean} a boolean indicating that the values of the provided element should be used, such as when undo/redoing a change
   */
  merge(other, forceUpdate) {
    throw new Error('merge not implemented' + other);
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    throw new Error('extract not implemented');
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {Element}
   */
  fromSnapshot(id, snapshot) {
    throw new Error('fromSnapshot not implemented' + id + ' ' + snapshot);
  }

  /**
   * method to call when the scope an element is in is destroyed
   */
  cleanUp() {
    this.flush();
  }

  /**
   * @param target {Element} The Element which will be modified in the merge
   * @param source {Element} The Element which will be rolled into the target
   */
  static merge(target, source) {
    target.merge(source);
  }

}
