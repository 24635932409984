import SelectPurchasePeriodTemplate from './select-purchase-period.component.html';

class SelectPurchasePeriodController {

  /* @ngInject */
  constructor($filter) {
    this.$filter = $filter;
  }

  get prices() {
    return this._prices;
  }

  set prices(value) {
    this._prices = value;

    if (value) {
      /**
       * If setting prices value retrieved from /v1/orders/prices, use the dates from request
       */
      this._thisYearStart = this.thisYear.start;
      this._thisYearEnd = this.thisYear.end;
      this._nextYearStart = this.nextYear.start;
      this._nextYearEnd = this.nextYear.end;
      this._inTwoYearsStart = this.inTwoYears.start;
      this._inTwoYearsEnd = this.inTwoYears.end;
    }
    this.initSelected();
  }

  get hideThisYear() {
    return this._hideThisYear;
  }

  set hideThisYear(value) {
    this._hideThisYear = value;
  }

  get isRenewal() {
    return this._isRenewal;
  }

  set isRenewal(value) {
    this._isRenewal = value;
  }

  /**
   * @return {OrderPrice}
   */
  get thisYear() {
    return this._prices && this._prices[0];
  }

  /**
   * @return {OrderPrice}
   */
  get nextYear() {
    return this._prices && this._prices[1];
  }

  /**
   * @return {OrderPrice}
   */
  get inTwoYears() {
    return this._prices && this._prices[2];
  }

  /**
   * @return {number}
   */
  get quantity() {
    return this._quantity;
  }

  /**
   * @param value {number}
   */
  set quantity(value) {
    this._quantity = value;
  }

  /**
   * @return {number}
   */
  get planPrice() {
    return this.thisYear && this.thisYear.plan.price * this.quantity;
  }

  get thisYearStart() {
    return this._thisYearStart;
  }

  get thisYearEnd() {
    return this._thisYearEnd;
  }

  get nextYearStart() {
    return this._nextYearStart;
  }

  get nextYearEnd() {
    return this._nextYearEnd;
  }

  get inTwoYearsStart() {
    return this._inTwoYearsStart;
  }

  get inTwoYearsEnd() {
    return this._inTwoYearsEnd;
  }

  initSelected() {
    let selectedStartYear = this.selectedStart.year();
    let selectedEndYear = this.selectedEnd.year();

    if (selectedStartYear === (this._thisYearStart && this._thisYearStart.year()) && selectedEndYear ===  (this._thisYearEnd && this._thisYearEnd.year())) {
      this._thisYearSelected = true;
      this._nextYearSelected = false;
      this._inTwoYearsSelected = false;
    }
    else if (selectedStartYear === (this._thisYearStart && this._thisYearStart.year()) && selectedEndYear === (this._nextYearEnd && this._nextYearEnd.year())) {
      this._thisYearSelected = true;
      this._nextYearSelected = true;
      this._inTwoYearsSelected = false;
    }
    else if (selectedStartYear === (this._thisYearStart && this._thisYearStart.year()) && selectedEndYear === (this._inTwoYearsEnd && this._inTwoYearsEnd.year())) {
      this._thisYearSelected = true;
      this._nextYearSelected = true;
      this._inTwoYearsSelected = true;
    }
    else {
      // Initialize the defaults
      this.thisYearSelected = true;
      this._nextYearSelected = false;
      this._inTwoYearsSelected = false;
    }

    this.valid = true;
  }

  get thisYearSelected() {
    return this._thisYearSelected;
  }

  set thisYearSelected(value) {
    this._thisYearSelected = value;

    this.valid = !this.thisYearAndInTwoYearsSelected && !this.onlyInTwoYearsSelected && !this.onlyThisYearSelectedAndFree && !this.noYearSelected;

    this.selectedStart = this.newStart;
    this.selectedEnd = this.newEnd;
  }

  get nextYearSelected() {
    return this._nextYearSelected;
  }

  set nextYearSelected(value) {
    this._nextYearSelected = value;

    this.valid = !this.thisYearAndInTwoYearsSelected && !this.onlyInTwoYearsSelected && !this.onlyThisYearSelectedAndFree && !this.noYearSelected;

    this.selectedStart = this.newStart;
    this.selectedEnd = this.newEnd;
  }

  get inTwoYearsSelected() {
    return this._inTwoYearsSelected;
  }

  set inTwoYearsSelected(value) {
    this._inTwoYearsSelected = value;

    this.valid = !this.thisYearAndInTwoYearsSelected && !this.onlyInTwoYearsSelected && !this.onlyThisYearSelectedAndFree && !this.noYearSelected;

    this.selectedStart = this.newStart;
    this.selectedEnd = this.newEnd;
  }

  get thisYearAndInTwoYearsSelected() {
    return this._thisYearSelected && !this._nextYearSelected && this._inTwoYearsSelected;
  }

  get onlyInTwoYearsSelected() {
    return !this._thisYearSelected && !this._nextYearSelected && this._inTwoYearsSelected;
  }

  get onlyThisYearSelectedAndFree() {
    return this.onlyThisYearSelected && this.thisYearTotal === 0;
  }

  get onlyThisYearSelected() {
    return this._thisYearSelected && !this._nextYearSelected && !this._inTwoYearsSelected;
  }

  get noYearSelected() {
    return !this._thisYearSelected && !this._nextYearSelected && !this._inTwoYearsSelected;
  }

  get totalPrice() {
    let price = 0;

    if (this._thisYearSelected) {
      price += this.thisYearTotal;
    }

    if (this._nextYearSelected) {
      price += this.nextYearTotal;
    }

    if (this._inTwoYearsSelected) {
      price += this.inTwoYearsTotal;
    }

    return price;
  }

  get thisYearTotal() {
    return this.thisYear && this.thisYear.total * this.quantity;
  }

  get nextYearTotal() {
    return this.nextYear && this.nextYear.total * this.quantity;
  }

  get inTwoYearsTotal() {
    return this.inTwoYears && this.inTwoYears.total * this.quantity;
  }

  get thisYearIsFree() {
    return this.thisYear && this.thisYear.total === 0;
  }

  get newStart() {
    if (this._thisYearSelected) {
      return this.thisYear && this.thisYear.start;
    }
    else if (this._nextYearSelected) {
      return this.nextYear && this.nextYear.start;
    }
    else if (this._inTwoYearsSelected) {
      return this.inTwoYears && this.inTwoYears.start;
    } else {
      return '';
    }
  }

  get newEnd() {
    if (this._inTwoYearsSelected) {
      return this.inTwoYears && this.inTwoYears.end;
    }
    else if (this._nextYearSelected) {
      return this.nextYear && this.nextYear.end;
    }
    else if (this._thisYearSelected) {
      return this.thisYear && this.thisYear.end;
    } else {
      return '';
    }
  }

  currency(amount) {
    return this.$filter('currency')(amount, '$', 0);
  }

  isSamePrice(discountPrice){
    return this.planPrice === discountPrice;
  }

  convertToDateToString(date){
    return date.format('MM/DD/YYYY');
  }
}

export default {
  bindings: {
    selectedStart: '=',
    selectedEnd: '=',
    valid: '=',
    quantity: '<',
    prices: '<',
    hideThisYear: '<',
    isRenewal: '<'
  },
  template: SelectPurchasePeriodTemplate,
  controller: SelectPurchasePeriodController,
  controllerAs: 'ctrl'
};


