export default class ChildOrganizationCount {
  constructor(
    count
  ) {
    this._count = count;
  }

  get count() {
    return this._count;
  }
}
