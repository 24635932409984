export default class Queue {
  constructor() {
    this.items = {};
    this.headIdx = 0;
    this.tailIdx = 0;
  }
  enqueue(item) {
    this.items[this.tailIdx] = item;
    this.tailIdx++;
  }
  dequeue() {
    const item = this.items[this.headIdx];
    delete this.items[this.headIdx];
    this.headIdx++;
    return item;
  }
  peek() {
    return this.items[this.headIdx];
  }
  get length() {
    return this.tailIdx - this.headIdx;
  }
}
