
'use strict';

import FirebaseObject from '../firebase-mapping/firebase-object';
import FirebaseCollection from '../firebase-mapping/firebase-collection';
import ModelMapping from '../firebase-mapping/model-mapping';
import FirebaseCollectionMapping from '../firebase-mapping/firebase-collection-mapping';
import ElementCodec from '../codec/element-codec';
import Element from '../ui/elements/element';
import CollectionObserver from '../firebase-mapping/collection-observer';

let mapping = new ModelMapping('/element_lists/{id}',
  {
    elements: new FirebaseCollectionMapping(
      '/elements',
      (instance) => { return instance._listen; },
      (instance) => { return instance.elements; },
      new ElementCodec()
    )
  }
);

class Observer extends CollectionObserver {

  constructor(elementList) {
    super();
    this._elementList = elementList;
  }

  onAdded() {
    this._elementList.invalidateCount();
  }

  onRemoved() {
    this._elementList.invalidateCount();
  }

  onChanged() {}

}

export default class ElementList extends FirebaseObject {
  /**
   * @param id {string} the element list id
   * @param firebaseInstanceId {string} the firebase instance id for this element list's data
   * @param FirebaseService {FirebaseService} the firebase service
   */
  constructor(id, firebaseInstanceId, FirebaseService, traceTag = '') {
    traceTag = traceTag + '[instance=' + firebaseInstanceId + ', id=' + id + ']';

    super(
      new Map()
        .set('id', id),
      mapping,
      firebaseInstanceId,
      FirebaseService,
      undefined,
      undefined,
      traceTag
    );

    this._elements = new FirebaseCollection(Element.merge);
    this._elements.subscribe(new Observer(this));
    this._elementCodec = new ElementCodec();

    this._logTrace('cstr complete');
  }

  /**
   * @returns {string}
   */
  get id() {
    return this.ids.get('id');
  }

  /**
   * @returns {FirebaseCollection.<Element>}
   */
  get elements() {
    return this._elements;
  }

  /**
   * @param element {Element}
   */
  saveElement(element) {
    this.remoteAdd(element);
    return this.firebase.upsertChild(this.mapping.properties.elements, element);
  }

  /**
   * @param element {Element}
   */
  remoteAdd(element) {
    if (this._listen && !this.elements.has(element.id)) {
      this._logTrace('remoteAdd id=' + element.id);
      this.elements._remoteAdd(element);
    }
  }

  /**
   * @param element {Element}
   */
  removeElement(element) {
    this._logTrace('removeElement id=' + element.id);
    return this.firebase.removeChild(this.mapping.properties.elements, element);
  }

  /**
   * @return {Promise.<Number>}
   */
  get elementCount() {
    if (angular.isUndefined(this._elementCount)) {
      return this.firebaseService
        .ref(`/element_lists/${this.id}`, this.firebaseInstanceId)
        .$once('value')
        .then((snapshot) => {
          let snapshotVal = snapshot.val();

          if (angular.isUndefined(snapshotVal) || snapshotVal === null || angular.isUndefined(snapshotVal.elements)) {
            return 0;
          }

          let elements = snapshot.val().elements;
          let elementIds = Object.keys(elements);
          let elementCount = elementIds.length;
          this._elementCount = elementCount;
          return elementCount;
        });
    }
    else {
      return Promise.resolve(this._elementCount);
    }
  }

  /**
   * @return {Promise.<Element[]>}
   */
  get allElements() {
    if (angular.isUndefined(this._allElements)) {
      return this.firebaseService
        .ref(`/element_lists/${this.id}`, this._firebaseInstanceId)
        .$once('value')
        .then((snapshot) => {
          let snapshotVal = snapshot.val();

          if (angular.isUndefined(snapshotVal) || snapshotVal === null || angular.isUndefined(snapshotVal.elements)) {
            return [];
          }

          let elements = snapshot.val().elements;
          let elementIds = Object.keys(elements);

          this._allElements = elementIds.map((elementId) => {
            return this._elementCodec.decode(elements[elementId], elementId);
          });

          return this._allElements;
        });
    }
    else {
      return Promise.resolve(this._allElements);
    }
  }

  invalidateCount() {
    this._elementCount = undefined;
  }

}

