/**
 * Represents each assignment that is displayed in the student overview page
 */
export default class StudentOverviewAssignmentItem {
  constructor(assignment, helpRequestSet, assignmentWork) {
    /** @type {Assignment} */
    this._assignment = assignment;

    /** @type {StudentAssignmentItem} */
    this._assignmentWork = assignmentWork;

    /** @type {HelpRequestSet} */
    this._helpRequestSet = helpRequestSet;

  }
  set assignment(value) {
    this._assignment = value;
  }

  get assignment() {
    return this._assignment;
  }

  set assignmentWork(value) {
    this._assignmentWork = value;
  }

  get assignmentWork() {
    return this._assignmentWork;
  }

  set helpRequestSet(value) {
    this._helpRequestSet = value;
  }

  get helpRequestSet() {
    return this._helpRequestSet;
  }

  get totalQuestions() {
    return this._assignment.questions.length;
  }

  getSlidesViewed() {
    let slidesViewed = 0;
    this._assignment.questions.forEach((question, index) => {
      if (
        this._assignmentWork &&
        this._assignmentWork.assignment &&
        this._assignmentWork.assignment.questionForIndex(index) &&
        !!this._assignmentWork.assignment.questionForIndex(index).startedAt
      ) {
        slidesViewed++;
      }
    });
    return slidesViewed;
  }

  getStartDate(){
    if (
      this._assignmentWork &&
      this._assignmentWork.assignment &&
      this._assignmentWork.assignment.questionForIndex(0) &&
      !!this._assignmentWork.assignment.questionForIndex(0).startedAt
    ) {
      return this._assignmentWork.assignment.questionForIndex(0).startedAt.format('L');
    }
    return '--';
  }
}
