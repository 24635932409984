
'use strict';

import {
  HelpArticleCategories,
  HelpArticleSections, HelpArticleSectionsDisplayMap
} from '../../model/domain/help-article';
import LocationUtil from '../../model/util/location-util';
import { TeacherHelpCenterViews } from '../../services/mixpanel/mixpanel.service';

export default class HelpCoachesController {

  /**
   * @ngInject
   */
  constructor($state, $stateParams, $window, $location, StaticService, CacheService, AuthService, BreadcrumbService, AnalyticsService) {

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$window = $window;
    this.$location = $location;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._displayingInIpadApp = StaticService.isClasskickIPadApp;

    this._sectionId = this.$stateParams.sectionId;

    this._coaches = [].filter((coach) => coach.sections.some((section) => section === this._sectionId));

    this.init();
  }

  init() {
    if (!this._sectionId || !HelpArticleSections.isValid(this._sectionId)) {
      this.$state.go('root.help');
    }

    this._analyticsService.teacherHelpCenter(TeacherHelpCenterViews.Coaches, this._sectionId);
  }

  /**
   * @return {string}
   */
  get sectionId() {
    return this._sectionId;
  }

  filterBySection(sectionId) {
    return (coach) => {
      return coach.sectionId === sectionId;
    };
  }

  goToSection(sectionId) {
    this.$state.go('root.help-section', {
      sectionId
    });
  }

  goToArticle(articleId) {
    this.$state.go('root.help-article', {
      articleId
    });
  }

  goToHelpCenter() {
    this.$state.go('root.help');
  }

  goToCategory(categoryId) {
    this.$state.go('root.help-category', {
      categoryId
    });
  }

  goToCoaches(sectionId) {
    this.$state.go('root.help-coaches', {
      sectionId
    });
  }

  returnToClasskick() {
    this.$state.go('root.account.home');
  }

  get sectionDisplay() {
    return this._sectionId && HelpArticleSectionsDisplayMap[this._sectionId];
  }

  get coaches() {
    return this._coaches;
  }

  get ConnectWithTeacher() {
    return HelpArticleCategories.ConnectWithTeacher;
  }

  get hideOutsideLinks() {
    return this._displayingInIpadApp;
  }

  goToTwitterProfile(handle) {
    this.$window.open(`https://twitter.com/${handle}`, '_blank');
  }

  goToInstagramProfile(handle) {
    this.$window.open(`https://www.instagram.com/${handle}`, '_blank');
  }

  openPublicAssignmentPage(email) {
    this.$window.open(`${LocationUtil.absRootUrl(this.$location)}/users/${email}/shared`, '_blank');
  }

}
