
'use strict';
import SignupDialogTemplate from './signup-dialog.html';

export default class SignupDialogController {
  constructor($mdDialog, $window) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.$window = $window;
  }

  static show($mdDialog) {
    return $mdDialog.show({
      controller: SignupDialogController,
      template: SignupDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      focusOnOpen: false
    });
  }

  whatIsClasskick() {
    this.$window.open('https://www.youtube.com/watch?v=l5vza3HTW5I', '_blank');
  }

  goToSignup() {
    this.$mdDialog.hide('root.signup-teacher');
  }

  goToLogin() {
    this.$mdDialog.hide('root.account-login');
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
