
'use strict';

/**
 * Supports tracking where you've been in the app, so that the back button can behave relably
 */
export default class BreadcrumbService {
  constructor($state, $window, $location, $transitions) {
    'ngInject';

    this.$state = $state;
    this.$window = $window;
    this.$location = $location;
    this.$transitions = $transitions;

    /** @type {Array.<{to: string, params: object}>} */
    this._stack = [];
    this._maxStackSize = 10;
    this._direction = this._none;

    this._init();
  }

  /**
   * @private
   */
  _init() {
    this.$transitions.onStart({}, this._beforeStateChange.bind(this));
    this.$transitions.onSuccess({}, this._onStateChange.bind(this));
  }

  _beforeStateChange(transition) {

    //---------------------- CKW-1017 ----------------------------
    // If we replaced a state, prevent the brower's back button from
    // returning to that state and instead transition to the intended previous state
    if (this._direction === this._none && this.$window.history.state === this._replace) {

      if (this._stack.length > 0) {
        this._direction = this._back;
        let destination = this._stack[this._stack.length - 1];
        return transition.router.stateService.target(destination.to, destination.params);
      }
    }
  }

  _onStateChange(event) {
    let toState = event.to();
    let toParams = event.params();
    let fromState = event.from();
    let fromParams = event.params('from');

    let to = this._stateObject(toState.name, toParams);

    if (!fromState.name) {
      // We're on the first page that the user has visited, do nothing
    }
    else if (this._direction === this._back ||
        (this._direction === this._none && (this._stack.length > 0 && to.to === this._stack[this._stack.length - 1].to))) {
      // Detect both our own back, and the browser's back
      this._fromState = fromState;
      this._stack.pop();
    }
    else if (this._direction === this._replace || toState.name === fromState.name) {
      // We're transitioning along the same state or replacing the current state -- do nothing
    }
    else {
      this._stack.push(this._stateObject(fromState.name, fromParams));
      this._fromState = fromState;
      if (this._stack.length > this._maxStackSize) {
        this._stack.shift();
      }
    }

    this._direction = this._none;
  }

  /**
   * @param to {string}
   * @param params {object}
   * @returns {{to: string, params: object}}
   * @private
   */
  _stateObject(to, params) {
    return {
      to: to,
      params: params ? {...params} : undefined
    };
  }

  /**
   *
   * @param to {string}
   * @param [params] {object}
   * @param [replace] {boolean}
   * @param [options] {object}
   */
  go(to, params, replace, options) {
    this._direction = replace ? this._replace : this._forward;
    this.$state.go(to, params, options);

    if (replace) {
      this.$window.history.replaceState(this._replace, '');
    }
  }

  /**
   * @param url {string}
   * @param replace {boolean}
   */
  goUrl(url, replace) {
    this._direction = replace ? this._replace : this._forward;
    this.$location.url(url);

    if (replace) {
      this.$window.history.replaceState(this._replace, '');
    }
  }

  get fromState() {
    return this._fromState;
  }

  /**
   *
   * @param defaultTo {string}
   * @param [defaultParams] {object}
   * @param shouldOverrideParams {boolean} When true, overridePrams overrides the existing params from
   *    the stack with the properties of defaultParams
   */
  goBack(defaultTo, defaultParams, shouldOverrideParams) {
    let destination = this._stateObject(defaultTo, defaultParams);

    if (this._stack.length > 0) {
      destination = this._stack[this._stack.length - 1];

      if (shouldOverrideParams) {
        for (let key of Object.keys(defaultParams)) {
          destination.params[key] = defaultParams[key];
        }
      }
    }


    this._direction = this._back;
    this.$state.go(destination.to, destination.params);
  }

  /**
   * Indicates whether the breadcrumb service can go back.
   * @returns {boolean}
   */
  get canGoBack() {
    return this._stack.length > 0;
  }

  /**
   * @return {*}
   */
  get lastState() {
    if (this._stack.length > 0) {
      return this._stack[this._stack.length - 1].to;
    }

    return null;
  }

  /**
   *
   * @param testSegment {string} the applicable ABTestSegment
   * @param aRoute {string} the route for users not included in the test
   * @param bRoute {string} the route for users included in the test
   * @param cacheService {CacheService}
   * @param analyticsService {AnalyticsService}
   */
  goToTestSegment(testSegment, aRoute, bRoute, cacheService, analyticsService) {
    cacheService.getTestSegment(testSegment)
      .then((result) => {
        analyticsService.experiment(testSegment);
        if (result) {
          this.go(bRoute, {}, true);
        }
        else {
          this.go(aRoute, {}, true);
        }
      });
  }

  /**
   * @returns {string}
   * @private
   */
  get _forward() {
    return 'forward';
  }

  /**
   * @returns {string}
   * @private
   */
  get _replace() {
    return 'replace';
  }

  /**
   * @returns {null}
   * @private
   */
  get _none() {
    return null;
  }

  /**
   * @returns {string}
   * @private
   */
  get _back() {
    return 'back';
  }

  /**
   * Clears the back stack. Useful for making it so that the back button doesn't go back to login screens, etc.
   */
  clear() {
    this._stack = [];
  }

  get stack(){
    return this._stack;
  }
}
