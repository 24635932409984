'use strict';

import SaveStates from '../../components/saving-indicator/save-states';
import Validation from '../../model/util/validation';
import {AuthType} from '../../services/auth/auth.service';

export default class BaseEditUserController {
  constructor($q, $scope, $stateParams, $mdDialog, $window, $log, CacheService, AuthService,
              BreadcrumbService, UserService, ContractService) {
    'ngInject';

    this.$stateParams = $stateParams;
    /** @type {AuthService} */
    this._authService = AuthService;
    this.$scope = $scope;

    this.$q = $q;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;
    this.$window = $window;
    this.$log = $log;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {UserService} */
    this._userService = UserService;
    /** @type {ContractService} */
    this._contractService = ContractService;

    /** @type {string} */
    this.firstName = null;
    /** @type {string} */
    this.lastName = null;
    /** @type {string} */
    this.username = null;
    /** @type {string} */
    this.email = null;
    /** @type {string} */
    this.displayName = null;
    /** @type {boolean} */
    this.isCleverImported = undefined;
    /** @type {boolean} */
    this._isUserContractAdmin = undefined;

    /** @type {User} */
    this._user = null;

    this.error = null;
  }

  /**
   * @returns {User}
   */
  get user() {
    return this._user;
  }

  get userId() {
    return this.$stateParams.userId;
  }

  get usernamePattern() {
    return Validation.UsernamePattern;
  }

  get emailPattern() {
      return Validation.EmailPattern;
  }

  get userHasGoogleAuth() {
    return this.user && this.user.authTypes && this.user.authTypes.includes(AuthType.GOOGLE);
  }

  /**
   * @param user {User}
   */
  set user(user) {
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.username = user.username;
    this.displayName = user.displayName;
    this.email = user.email;
    this.isCleverImported = user.isClever;
    if (user.contractMembers) {
      let contractMember = user.contractMembers.find((contractMember) => contractMember.contractId === this.contractId);
      this._isUserContractAdmin = contractMember ? contractMember.admin : false;
    }
    this._user = user;
  }

  get SAVED() {
    return SaveStates.SAVED;
  }

  get UNSAVED() {
    return SaveStates.UNSAVED;
  }

  get SAVE_ERROR() {
    return SaveStates.SAVE_ERROR;
  }

  /**
   * @return {boolean}
   */
  get isTeacher() {
    return this.user && this.user.isTeacher;
  }

  /**
   * @return {boolean}
   */
  get isStudent() {
    return this.user && this.user.isStudent;
  }

  /**
   * @return {boolean}
   */
  get isCurrentUser() {
    return this.user && (this.user.id === this._authService.authData.id);
  }

  /**
   *
   * @returns {string}
   */
  get role() {
    if (this.user !== null) {
      return this.user.isTeacher ? 'Teacher' : 'Student';
    }
    return '';
  }

  /**
   *
   * @returns {boolean}
   */
  get isUserContractAdmin() {
    return this._isUserContractAdmin;
  }

  get loading() {
    return !this.user && !this.error;
  }

}
