import moment from 'moment';
import { OrderPurchasePeriod } from './order-purchase-period';

export class OrderStatus {

  static get Created () {
    return 'created';
  }

  static get Invoiced () {
    return 'invoiced';
  }

  static get Paid () {
    return 'paid';
  }

  static get PastDue () {
    return 'past_due';
  }

  static get Canceled () {
    return 'canceled';
  }

}

export default class Order {

  constructor(id, quote, organizationId, planId, start, end, renewal, financialAid, quantity, couponIds, status, creatorId, creatorName, creatorEmail, created, expires, billingName, billingEmail, billingPhone, pointEmail, pointId, purchaseOrder) {
    this._id = id;
    this._quote = quote;
    this._organizationId = organizationId;
    this._planId = planId;
    this._start = start ? moment(start) : undefined;
    this._end = end ? moment(end) : undefined;
    this._renewal = renewal;
    this._financialAid = financialAid;
    this._quantity = quantity;
    this._couponIds = couponIds;
    this._status = status;
    this._creatorId = creatorId;
    this._creatorName = creatorName;
    this._creatorEmail = creatorEmail;
    this._created = created ? moment(created) : undefined;
    this._expires = expires ? moment(expires) : undefined;
    this._billingName = billingName;
    this._billingEmail = billingEmail;
    this._billingPhone = billingPhone;
    this._pointEmail = pointEmail;
    this._pointId = pointId;
    this._purchaseOrder = purchaseOrder;
  }

  /**
   * @return {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @return {number}
   */
  get quote() {
    return this._quote;
  }

  /**
   * @return {string}
   */
  get organizationId() {
    return this._organizationId;
  }

  /**
   * @return {string}
   */
  get planId() {
    return this._planId;
  }

  /**
   * @param value {string}
   */
  set planId(value) {
    this._planId = value;
  }

  /**
   * @return {OrderPlan}
   */
  get plan() {
    return this._activePlans && this._activePlans.find((plan) => plan.id === this._planId);
  }

  /**
   * @param value {OrderPlan[]}
   */
  set activePlans(value) {
    this._activePlans = value;
  }

  /**
   *
   * @param value {Object}
   */
  set customQuote(value) {
    this._customQuote = value;
  }

  /**
   *
   * @returns {Object}
   */
  get customQuote() {
    return this._customQuote;
  }

  /**
   * @return {moment}
   */
  get start() {
    return this._start;
  }

  /**
   * @param value {moment}
   */
  set start(value) {
    this._start = value;
  }

  /**
   * @return {moment}
   */
  get end() {
    return this._end;
  }

  /**
   * @param value {moment}
   */
  set end(value) {
    this._end = value;
  }

  /**
   * @return {number}
   */
  get duration() {
    return this.end.year() - this.start.year();
  }

  /**
   * @return {boolean}
   */
  get renewal() {
    return this._renewal;
  }

  /**
   * @param value {boolean}
   */
  set renewal(value) {
    this._renewal = value;
  }

  /**
   * @return {boolean}
   */
  get financialAid() {
    return this._financialAid;
  }

  /**
   * @param value {boolean}
   */
  set financialAid(value) {
    this._financialAid = value;
  }

  /**
   * @return {number}
   */
  get quantity() {
    return this._quantity;
  }

  /**
   * @return {string[]}
   */
  get couponIds() {
    return this._couponIds;
  }

  /**
   * @param value {string[]}
   */
  set couponIds(value) {
    this._couponIds = value;
  }

  /**
   * @return {OrderCoupon[]}
   */
  get coupons() {
    const coupons = this._couponIds
      .map((couponId) => this._activeCoupons.find((coupon) => couponId === coupon.id))
      .filter((coupon) => !!coupon);
    return coupons;
  }

  set activeCoupons(value) {
    this._activeCoupons = value;
  }

  /**
   * The discount per unit for the duration of the plan
   * @return {number}
   */
  get unitDiscount() {
    return this.coupons.reduce((accum, currentValue) => {
      if (currentValue.id !== 'free-trial-for-v5-plans' && currentValue.id !== 'free-trial-for-v6-plans'){
        accum += currentValue.discount;
      }
      return accum;
    }, 0);
  }

  /**
   * The unit discount times the quantity of units
   * @return {number}
   */
  get totalDiscount() {
    return this.unitDiscount * this.quantity;
  }

  /**
   * The cost of a single year for a plan
   * @return {number}
   */
  get planPrice() {
    return this.plan && this.plan.price;
  }

  /**
   * The price of per unit for the duration of the plan
   * @return {number}
   */
  get unitPrice() {
    if (this._couponIds.includes('free-trial-for-v5-plans') || this._couponIds.includes('free-trial-for-v6-plans') ){
      return this.planPrice * (this.duration - 1);
    }
    return this.planPrice * this.duration;
  }

  /**
   * The unit price times the quantity of of units
   * @return {number}
   */
  get totalPrice() {
    return this.unitPrice * this.quantity;
  }

  /**
   * The overall cost of the order
   * @return {number}
   */
  get total() {
    return this.totalPrice - this.totalDiscount;
  }

  /**
   * @return {string}
   */
  get status() {
    return this._status;
  }

  /**
   * @return {string}
   */
  get creatorId() {
    return this._creatorId;
  }

  /**
   * @return {string}
   */
  get creatorName() {
    return this._creatorName;
  }

  /**
   * @return {string}
   */
  get creatorEmail() {
    return this._creatorEmail;
  }

  /**
   * @return {moment}
   */
  get created() {
    return this._created;
  }

  /**
   * @return {string}
   */
  get createdAsDate() {
    return this.created.format('MM/DD/YYYY');
  }

  /**
   * @return {moment}
   */
  get expires() {
    return this._expires;
  }

  /**
   * @return {string}
   */
  get expiresAsDate() {
    return this.expires.format('MM/DD/YYYY');
  }

  /**
   * @return {string}
   */
  get billingName() {
    return this._billingName;
  }

  /**
   * @param value {string}
   */
  set billingName(value) {
    this._billingName = value;
  }

  /**
   * @return {string}
   */
  get billingEmail() {
    return this._billingEmail;
  }

  /**
   * @param value {string}
   */
  set billingEmail(value) {
    this._billingEmail = value;
  }

  /**
   * @return {string}
   */
  get billingPhone() {
    return this._billingPhone;
  }

  /**
   * @return {string}
   */
  get pointEmail() {
    return this._pointEmail;
  }

  /**
   * @param value {string}
   */
  set pointEmail(value) {
    this._pointEmail = value;
  }

  /**
   * @return {string}
   */
  get pointId() {
    return this._pointId;
  }

  /**
   * @return {string}
   */
  get purchaseOrder() {
    return this._purchaseOrder;
  }

  /**
   * @param value {string}
   */
  set purchaseOrder(value) {
    this._purchaseOrder = value;
  }

  /**
   * @param orders {Order[]}
   * @return {Order|undefined}
   */
  static findActiveOrder(orders) {
    let now = moment();
    return orders.find((order) => order.expires.isAfter(now) && order.status === OrderStatus.Created);
  }

  /**
   * @param orders {Order[]}
   * @return {boolean}
   */
  static findSubmittedOrder(orders) {
    let currentOrder = Order.getSubmittedOrders(orders)[0];
    if (currentOrder) {
      return currentOrder.end >= moment(new Date(2021, 6, 30));
    }
  }

  static getSubmittedOrders(orders) {
    return orders
      .filter((order) => {
        return order.status === OrderStatus.Paid || order.status === OrderStatus.Invoiced || order.status === OrderStatus.PastDue;
      });
  }

  static hasAlreadyPurchasedThisYear(orders) {
    return Order.getSubmittedOrders(orders)
      .some((order) => {
        return order.end >= Date.now();
      });
  }

  /**
   * @param orders {Order[]}
   * @return {boolean}
   */
  static hasSubmittedOrder(orders) {
    return !!Order.findSubmittedOrder(orders);
  }

  /**
   * @param [orders] {Order[]}
   * @return {moment}
   */
  static getDefaultStart(orders = [], isRenewal = false) {
    // JULY 2021: removing renewal check to fix overlap of plans ending after new 2021-22 start dates
    // return (Order.hasAlreadyPurchasedThisYear(orders) || isRenewal) ? OrderPurchasePeriod.NextYearStart : OrderPurchasePeriod.ThisYearStart;
    return OrderPurchasePeriod.ThisYearStart;
  }

  /**
   * @param [orders] {Order[]}
   * @return {moment}
   */
  static getDefaultEnd(orders = [], isRenewal = false) {
    // JULY 2021: removing renewal check to fix overlap of plans ending after new 2021-22 start dates
    // let defaultEnd = (Order.hasAlreadyPurchasedThisYear(orders)|| isRenewal) ? OrderPurchasePeriod.NextYearEnd : OrderPurchasePeriod.ThisYearEnd;
    return OrderPurchasePeriod.ThisYearEnd;
  }

  /**
   * Calculate the order's organization contract end date as ISO8601 string, which is hard-coded to July 1 of the
   * following year from the contract start date, for Classkick to work until the end of school year.
   *
   * @param [contractStartDate] {moment}
   * @returns {moment}
   */
  static calculateContractEndDate(contractStartDate) {
    const organizationContractEndDate = (contractStartDate.year() + 1).toString() + '-07-01T00:00:00.000Z';
    return moment(organizationContractEndDate);
  }

  /**
   * Calculate the order's organization contract end date as ISO8601 string, which is hard-coded to July 1 of the
   * 2 years from the contract start date, for Classkick to work until the end of school year.
   *
   * @param [contractStartDate] {moment}
   * @returns {moment}
   */
  static calculateContractEndDate2Years(contractStartDate) {
    const organizationContractEndDate = (contractStartDate.year() + 2).toString() + '-07-01T00:00:00.000Z';
    return moment(organizationContractEndDate);
  }

  /**
   * @param {Order}
   * @return {number}
   */
  static getCurrentOrderPricePerUnit(order) {
    const unitPrice = order.unitPrice;
    const discount = order.unitDiscount;
    return unitPrice - discount;
  }
}
