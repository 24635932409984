
'use strict';

export default function CkAutofocusDirective($timeout) {
  'ngInject';
  return {
    restrict: 'A',
    scope: {
      'ckAutofocus': '='
    },
    link: ($scope, $element) => {
      $scope.$watch('ckAutofocus', (focus) => {
        if (focus){
          $timeout(() => {
            $element.focus();

            if (focus === 'select') {
              $element.select();
            }
          });
        }
      });
    }
  };
}
