
'use strict';

import JSEvent from '../../model/util/js-event';
import {Notifications} from './toolbar.service';

class SidenavMode {

  /**
   * @param name {string}
   * @param type {string}
   * @param [filterFunc] {function} Returns `false` for SidenavStates which should be removed from stack
   * when transitioning to this state. Should be defined for initial state within each independent workflow.
   */
  constructor(name, type, filterFunc) {
    this._name = name;
    this._type = type;
    this._filterFunc = filterFunc;
  }

  /**
   * @returns {string}
   */
  get name() {
    return this._name;
  }

  /**
   * @returns {boolean}
   */
  get isStickerMode() {
    return this._type === SidenavMode.TYPE_STICKER;
  }

  get isAudioMode() {
    return this._type === SidenavMode.TYPE_AUDIO;
  }

  get isAiAssistantMode() {
    return this._type === SidenavMode.TYPE_AI_ASSISTANT;
  }

  get isFillInTheBlankMode() {
    return this._type === SidenavMode.TYPE_FILL_IN_THE_BLANK;
  }

  get isMultipleChoiceMode() {
    return this._type === SidenavMode.TYPE_MULTIPLE_CHOICE;
  }

  get isSlideBackgroundMode() {
    return this._type === SidenavMode.TYPE_SLIDE_BACKGROUND;
  }

  get isHelpCenterMode() {
    return this._type === SidenavMode.TYPE_HELP_CENTER;
  }

  get isContributorsTabMode() {
    return this._type === SidenavMode.TYPE_CONTRIBUTORS_TAB;
  }

  get filter() {
    return this._filterFunc;
  }

  /**
   * @returns {string}
   */
  static get TYPE_STICKER() {
    return 'sticker';
  }

  /**
   * @returns {string}
   */
  static get TYPE_AUDIO() {
    return 'audio';
  }

    /**
   * @returns {string}
   */
    static get TYPE_AI_ASSISTANT() {
      return 'ai';
    }

  /**
   * @return {string}
   */
  static get TYPE_FILL_IN_THE_BLANK() {
    return 'fill_in_the_blank';
  }

  /**
   * @returns {string}
   */
  static get TYPE_MULTIPLE_CHOICE() {
    return 'multiple_choice';
  }

  /**
   * @return {string}
   */
  static get TYPE_SLIDE_BACKGROUND() {
    return 'slide_background';
  }

  /**
   * @return {string}
   */
  static get TYPE_HELP_CENTER() {
    return 'help_center';
  }
  /**
   * @return {string}
   */
  static get TYPE_CONTRIBUTORS_TAB() {
    return 'contributors_tab';
  }
}

const stickerPlaceMode = new SidenavMode('place_stickers', SidenavMode.TYPE_STICKER, (x) => !x.mode.isStickerMode);
const stickerEditMode = new SidenavMode('edit_sticker', SidenavMode.TYPE_STICKER);
const recordAudioMode = new SidenavMode('audio_record', SidenavMode.TYPE_AUDIO, (x) => !x.mode.isAudioMode);
const aiAssistantMode = new SidenavMode('ai_assistant_mode', SidenavMode.TYPE_AI_ASSISTANT, (x) => !x.mode.isAiAssistantMode);
const fillInTheBlankEditMode = new SidenavMode('edit_fill_in_the_blank', SidenavMode.TYPE_FILL_IN_THE_BLANK, (x) => !x.mode.isFillInTheBlankMode);
const multipleChoiceEditMode = new SidenavMode('edit_multiple_choice', SidenavMode.TYPE_MULTIPLE_CHOICE);
const slideBackground = new SidenavMode('slide_background', SidenavMode.TYPE_SLIDE_BACKGROUND, (x) => !x.mode.isSlideBackgroundMode);
const helpCenter = new SidenavMode('help_center', SidenavMode.TYPE_HELP_CENTER, (x) => !x.mode.isHelpCenterMode);
const contributorsTab = new SidenavMode('contributors_tab', SidenavMode.TYPE_CONTRIBUTORS_TAB, (x) => !x.mode.isContributorsTabMode);

export class SidenavModes {

  get PLACE_STICKERS() {
    return stickerPlaceMode;
  }

  get EDIT_STICKER() {
    return stickerEditMode;
  }

  get AUDIO_RECORD() {
    return recordAudioMode;
  }

  get AI_ASSISTANT_MODE() {
    return aiAssistantMode;
  }

  get EDIT_FILL_IN_THE_BLANK() {
    return fillInTheBlankEditMode;
  }

  get EDIT_MULTIPLE_CHOICE() {
    return multipleChoiceEditMode;
  }

  get SLIDE_BACKGROUND() {
    return slideBackground;
  }

  get HELP_CENTER() {
    return helpCenter;
  }

  get CONTRIBUTORS_TAB() {
    return contributorsTab;
  }
}

class SidenavState {
  /**
   * @param mode {SidenavMode}
   * @param isOpen {boolean}
   * @param [data] {*}
   */
  constructor(mode, isOpen, data) {
    this._mode = mode;
    this._isOpen = isOpen;
    this._data = data;
  }

  /**
   * @returns {SidenavMode}
   */
  get mode() {
    return this._mode;
  }

  /**
   * @returns {boolean}
   */
  get isOpen() {
    return this._isOpen;
  }

  /**
   * @returns {*}
   */
  get data() {
    return this._data;
  }
}


export default class SidenavManager {

  /**
   * @param stickerManager {StickerManager}
   * @param fillInTheBlankManager {FillInTheBlankManager}
   */
  constructor(stickerManager, fillInTheBlankManager) {
    /** @type {StickerManager} */
    this._stickerManager = stickerManager;
    /** @type {FillInTheBlankManager} */
    this._fillInTheBlankManager = fillInTheBlankManager;
    this._updated = new JSEvent(this);
    this._errorMessage = undefined;
    this._isLoading = false;
    this._modes = new SidenavModes();

    /**
     * @type {[SidenavState]}
     * @private
     */
    this._stateStack = [];

    this.reset();
  }

  /**
   * @return {JSEvent}
   */
  get updated() {
    return this._updated;
  }

  _raiseUpdated(f) {
    const oldState = this._state;
    f();
    this._updated.raise({ newState: this._state, oldState: oldState });
  }

  //--------------- Error Methods -----------------------

  /**
   * @return {string}
   */
  get error() {
    return this._errorMessage;
  }

  /**
   * @param value {string}
   */
  setError(value) {
    this.finishLoading();
    this._errorMessage = value;
  }

  get isError() {
    return !!this._errorMessage;
  }

  clearError() {
    this._errorMessage = null;
  }

  //--------------- Loading Methods -----------------------

  get isLoading() {
    return this._isLoading;
  }

  startLoading() {
    this.clearError();
    this._isLoading = true;
  }

  finishLoading() {
    this._isLoading = false;
  }

  //--------------- Visibility Methods --------------------

  toggleOpen() {
    const state = this._state;
    this._goToState(new SidenavState(state.mode, !state.isOpen, state.data));
  }

  open() {
    if (!this.isOpen) {
      this.toggleOpen();
    }
  }

  close() {
    if (this.isOpen) {
      this.toggleOpen();
    }
  }

  /**
   * @return {boolean}
   */
  get isOpen() {
    return this._state.isOpen;
  }

  //--------------- Mode Transition methods ----------------

  /**
   * @returns {boolean}
   */
  get canShowMode() {
    return !this.isLoading && !this.isError;
  }

  /**
   * @returns {SidenavState}
   * @private
   */
  get _state() {
    return this._stateStack.length > 0 ? this._stateStack[this._stateStack.length - 1] : null;
  }

  /**
   * @return {SidenavMode}
   */
  get mode() {
    return this._state.mode;
  }

  /**
   * @param state {SidenavState}
   * @private
   */
  _goToState(state) {
    this.clearError();
    this.finishLoading();

    if (angular.equals(state, this._state)) {
      return;
    }

    this._raiseUpdated(() => {

      // Replace if mode is same as current
      if (state.mode === this.mode) {
        this._stateStack.pop();
      }
      // Otherwise, remove states if necesary
      else if (state.mode.filter) {
        this._stateStack = this._stateStack.filter(state.mode.filter);
      }

      this._stateStack.push(state);
      this._applyStateUpdate();
    });
  }

  _applyStateUpdate() {
    const state = this._state;
    if (state.mode && state.mode.isStickerMode) {
      this._stickerManager.selected = state.data;
    }
    else if (state.mode && state.mode.isFillInTheBlankMode) {
      this._fillInTheBlankManager.selected = state.data;
    }
  }

  closeState() {
    if (this._stateStack.length > 1) {

      this._raiseUpdated(() => {
          this._stateStack.pop();
          this._applyStateUpdate();
      });
    }
    else {
      this.close();
    }
  }

  openPlaceSticker() {
    this._goToState(new SidenavState(this.modes.PLACE_STICKERS, true, null));
  }

  /**
   * @param sticker {UserSticker}
   */
  openEditSticker(sticker) {
    this._goToState(new SidenavState(this.modes.EDIT_STICKER, true, sticker));
  }

  openRecordAudio() {
    this._goToState(new SidenavState(this.modes.AUDIO_RECORD, true));
  }


  openAiAssistant() {
    this._goToState(new SidenavState(this.modes.AI_ASSISTANT_MODE, true));
  }

  /**
   * @param fillInTheBlank {FillInTheBlankParent}
   */
  openEditFillInTheBlank(fillInTheBlank) {
    this._goToState(new SidenavState(this.modes.EDIT_FILL_IN_THE_BLANK, true, fillInTheBlank));
  }

  /**
   * @param multipleChoice {MultipleChoiceParent}
   */
  openEditMultipleChoice(multipleChoice) {
    this._goToState(new SidenavState(this.modes.EDIT_MULTIPLE_CHOICE, true, multipleChoice));
  }

  openSlideBackground() {
    this._goToState(new SidenavState(this.modes.SLIDE_BACKGROUND, true));
  }

  openHelpCenter() {
    this._goToState(new SidenavState(this.modes.HELP_CENTER, true));
  }

  openContributorsTab() {
    this._goToState(new SidenavState(this.modes.CONTRIBUTORS_TAB, true));
  }

  /**
   * @returns {SidenavModes}
   */
  get modes() {
    return this._modes;
  }

  /**
   * @param mode
   * @returns {boolean}
   */
  isModeVisible(mode) {
    return this.isOpen && this.canShowMode && mode === this.mode;
  }

  /**
   * Returns true if any sticker mode is open and in the stack
   * @returns {boolean}
   */
  get isStickerModeOpen() {
    return !!this._stateStack.find((x) => x.isOpen && x.mode.isStickerMode);
  }

  /**
   * Returns true if any audio mode is open and in the stack
   * @returns {boolean}
   */
  get isAudioModeOpen() {
    return !!this._stateStack.find((x) => x.isOpen && x.mode.isAudioMode);
  }

  /**
   * Returns true if any ai_assistant mode is open and in the stack
   * @returns {boolean}
   */
  get isAiAssistantOpen() {
    return !!this._stateStack.find((x) => x.isOpen && x.mode.isAiAssistantMode);
  }


  /**
   * Returns true if any multiple choice mode is open and in the stack
   * @returns {boolean}
   */
  get isMultipleChoiceModeOpen() {
    return !!this._stateStack.find((x) => x.isOpen && x.mode.isMultipleChoiceMode);
  }

  /**
   * @return {boolean}
   */
  get isSlideBackgroundModeOpen() {
    return !!this._stateStack.find((x) => x.isOpen && x.mode.isSlideBackgroundMode);
  }

  /**
   * Returns true if the help center mode is open and in the stack
   * @return {boolean}
   */
  get isHelpCenterModeOpen() {
    return !!this._stateStack.find((x) => x.isOpen && x.mode.isHelpCenterMode);
  }

  /**
   * Returns true if the contributors tab mode is open and in the stack
   * @return {boolean}
   */
  get isContributorsTabModeOpen() {
    return !!this._stateStack.find((x) => x.isOpen && x.mode.isContributorsTabMode);
  }

  reset() {
    this._stateStack = [new SidenavState(this.modes.PLACE_STICKERS, false, null)];
  }
}
