'use strict';

import moment from 'moment';
import Notification from './notification';
import NotificationShardKey from './notification-shard-key.js';
import JSEvent from '../util/js-event';

class UserStatus {

  /**
   * @param userId {string}
   * @param online {boolean}
   * @param activity {string}
   * @param assignmentId {string}
   * @param questionId {string}
   * @param helpeeId {string}
   * @param platform {string}
   * @param time {string}
   */
  constructor(userId, online, activity, assignmentId, questionId, helpeeId, platform, time) {
    this._userId = userId;
    this._online = online;
    this._activity = activity;
    this._assignmentId = assignmentId;
    this._questionId = questionId;
    this._helpeeId = helpeeId;
    this._platform = platform;
    this._time = moment(time);
  }

  /**
   * @param obj
   * @param userId
   * @returns {*}
   */
  static apply(obj, userId) {
    if (obj) {
      return new UserStatus(
        userId,
        obj.online,
        obj.activity,
        obj.assignment_id,
        obj.question_id,
        obj.helpee_id,
        obj.platform,
        obj.t
      );
    }
    return null;
  }

  /**
   * @returns {string}
   */
  get userId() {
    return this._userId;
  }

  /**
   * @returns {boolean}
   */
  get online() {
    return this._online;
  }

  /**
   * @returns {string}
   */
  get activity() {
    return this._activity;
  }

  /**
   * @returns {string}
   */
  get assignmentId() {
    return this._assignmentId;
  }

  /**
   * @returns {string}
   */
  get questionId() {
    return this._questionId;
  }

  /**
   * @returns {string}
   */
  get helpeeId() {
    return this._helpeeId;
  }

  /**
   * @returns {string}
   */
  get platform() {
    return this._platform;
  }

  /**
   * @returns {moment}
   */
  get time() {
    return this._time;
  }
}

export default class UserStatusNotification extends Notification {

  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param userId {string}
   * @param FirebaseService {FirebaseService}
   * @param [showInitialNotification] {boolean}
   */
  constructor(notificationShardKey, userId, FirebaseService, showInitialNotification) {
    super(
      Notification.V3,
      notificationShardKey,
      `users/${userId}/status`,
      FirebaseService,
      showInitialNotification,
      'UserStatusNotification'
    );

    this._userId = userId;
    this._updated = new JSEvent(this);

    /** @type {UserStatus} */
    this._status = null;
    /** @type {UserStatus} */
    this._oldStatus = null;

    this._logTrace('cstr complete');
  }

  /**
   * @returns {string}
   */
  get userId() {
    return this._userId;
  }

  /**
   * @returns {JSEvent.<UserStatus>}
   */
  get updated() {
    return this._updated;
  }

  /**
   * @returns {UserStatus}
   */
  get status() {
    return this._status;
  }

  /**
   * @return {UserStatus}
   */
  get oldStatus() {
    return this._oldStatus;
  }

  _changed(value) {
    this._oldStatus = this._status;
    this._status = UserStatus.apply(value, this._userId);
    if (value) {
      this._logTrace(`_changed userId=${this._userId}, value=${angular.toJson(this._status)}`);
      this._updated.raise(this._status);
    }
  }


}
