
'use strict';

import RostersListController from '../rosters-list/rosters-list.controller';
import RosterListItem from '../../model/domain/roster-list-item';
import Debouncer from '../../model/util/debouncer';
import SaveStates from '../../components/saving-indicator/save-states';

export default class HiddenRostersListController extends RostersListController {
  constructor($log, $q, $scope, $window, $state, $timeout, $mdSidenav, $mdDialog, $mdToast, RosterService,
              HelpRequestService, AuthService, CacheService, AnalyticsService, BreadcrumbService, GoogleClassroomService, StorageService) {
    super($log, $q, $scope, $window, $state, $timeout, $mdSidenav, $mdDialog, $mdToast, RosterService,
      HelpRequestService, AuthService, CacheService, AnalyticsService, BreadcrumbService, GoogleClassroomService);
    'ngInject';

    this.$log = $log;
    this.$scope = $scope;
    this.$window = $window;
    this.$q = $q;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$state = $state;
    this.$mdSidenav = $mdSidenav;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;

    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {HelpRequestService} */
    this._helpRequestService = HelpRequestService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {GoogleClassroomService} */
    this._googleClassroomService = GoogleClassroomService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    // column configuration
    this._showUnhideOption = true;


    // Clean up after ourselves
    $scope.$on('$destroy', () => this._destroy());

    this._init();
  }

  /**
   * Loads a user's hidden rosters
   */
  _init() {
    this._loading = true;

    this._saveRoster = new Debouncer(100, 500, () => {
      Object.keys(this.selectedRosters).map((rosterId) => {
        if (this.selectedRosters[rosterId] && this.rosterListItemMap[rosterId]) {
          this._rosterService.update(this.rosterListItemMap[rosterId].roster).then(() => {
            this._cacheService.updateRosterForUser(this.rosterListItemMap[rosterId].roster);
            this._saveMessage = SaveStates.SAVED;
            const syncedRoster = this.rosterListItemMap[rosterId].roster.isGoogleRoster || this.rosterListItemMap[rosterId].roster.isCleverRoster;
            this._analyticsService.unhideRoster(syncedRoster);
            this._resetRosterList(rosterId);
            this._saveMessageTimeout();

          }).catch(() => {
            this._saveMessage = SaveStates.SAVE_ERROR;
          });
        } else {
          this._saveMessage = SaveStates.SAVE_ERROR;
        }
      });
    }, this.$scope, this.$window);

    return this.$q
      .all({
        rosters: this._cacheService.getRostersForUser(),
        proInfo: this._cacheService.getProInfo()
      })
      .then(({rosters, proInfo}) => {

        this._proInfo = proInfo;

        let promises = [];

        angular.forEach(rosters, (roster) => {
          if (roster.isHidden) {
            let rosterListItem = new RosterListItem(roster, []);
            return promises.push(rosterListItem);
          }
        });

        return this.$q.all(promises);
      })
      .then((result) => {
        result.map((rosterListItem) => {
          this._rosterListItemMap[rosterListItem.roster.id] = rosterListItem;
        });
        this.list = Object.values(this.rosterListItemMap);
        this._loading = false;
      })
      .catch((error) => {
        this._error = error;
      });
  }

  /**
   * stop listening for notifications for roster help requests
   */
  _destroy() {
    this._saveRoster.destroy();
  }

  /**
   * indicates if table should display hide option column
   * @returns {boolean}
   */
  get showUnhideOption() {
    return this._showUnhideOption;
  }

  /**
   * @return {boolean}
   */
  get showBanner() {
    return (this._storageService.lastSeenTrialConversionBanner && this._storageService.lastSeenTrialConversionBanner.showBanner === true)
        || (this._storageService.lastSeenRenewalConversionBanner && this._storageService.lastSeenRenewalConversionBanner.showBanner === true)
        || (this._storageService.lastSeenAssignmentNotificationBanner && this._storageService.lastSeenAssignmentNotificationBanner.showBanner === true);
  }

  /**
   * Removes selected roster from the all lists
   * @param rosterId {string}
   */
  _resetRosterList(rosterId){
    if (this.selectedRosters[rosterId]) {
      delete this.selectedRosters[rosterId];
    }
    if (this.rosterListItemMap[rosterId]) {
      delete this.rosterListItemMap[rosterId];
    }
    this.list = Object.values(this.rosterListItemMap);
  }

  /**
   * Saves the roster's current state
   */
  save() {
    this._saveMessage = SaveStates.UNSAVED;
    this._saveRoster.tick();
  }

  /**
   * Show selected roster by updating and saving their new property
   * @param roster {Roster}
   */
  showRoster(roster){
    roster.properties = {...roster.properties, 'is_hidden': 'unhidden'};
    this.save();
  }

  goBackToRosters() {
    this._breadcrumbService.goBack('root.account.nav.rosters');
  }
}
