import HelpArticleCodec from '../../model/codec/help-article-codec';

export default class HelpArticleService {

  /**
   * @ngInject
   */
  constructor(HttpService, environment) {
    /** @type {HttpService} */
    this._httpService = HttpService;

    this._environment = environment;

    this._helpArticleCodec = new HelpArticleCodec();
  }

  getAll() {
    return this._httpService.get(this._uri('/v1/help-articles'))
      .then((data) => {
        return data.help_articles.map((article) => this._helpArticleCodec.decode(article));
      });
  }

  /**
   * @param categoryId {string}
   * @param sectionId {string}
   * @param [title] {string}
   * @param [body] {string}
   * @param [priority] {number}
   *
   * @return {Promise<HelpArticle>}
   */
  create(categoryId, sectionId, title = 'default title', body = 'default body', priority = 1) {
    return this._httpService
      .authPost(
        this._uri('/v1/help-articles'),
        {
          category_id: categoryId,
          section_id: sectionId,
          title,
          body,
          priority
        }
      )
      .then((data) => {
        return this._helpArticleCodec.decode(data);
      });
  }

  get(articleId) {
    return this._httpService.get(this._uri(`/v1/help-articles/${articleId}`))
      .then((data) => {
        return this._helpArticleCodec.decode(data);
      });
  }

  /**
   * @param article {HelpArticle}
   */
  update(article) {
    return this._httpService.authPut(this._uri(`/v1/help-articles/${article.id}`), this._helpArticleCodec.encode(article));
  }

  /**
   * Searches the title and body of help articles
   * @param query {string}
   * @return {Promise<T | never>}
   */
  search(query) {
    return this._httpService.get(this._uri(`/v1/help-articles/search?query=${query}`))
      .then((data) => {
        return data.help_articles.map((organization) => this._helpArticleCodec.decode(organization));
      });
  }

  /**
   * @param articleId {string}
   */
  delete(articleId) {
    return this._httpService.authDelete(this._uri(`/v1/help-articles/${articleId}`));
  }

  /**
   * Creates a URL from a path
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }

}
