'use strict';
import moment from 'moment';

/**
 * Contains the work portion of an assignment
 */
export default class AssignmentWork {

  /**
   * @param assignment {Assignment} The pertinent assignment
   * @param id {string} the id of the assignment work
   * @param ownerId {string} the id of the owner of the work
   * @param rosterId {string} the roster id associated with the assignment work
   * @param questions {Map.<string, AssignmentQuestion>} Map of element
   */
  constructor(assignment, id, ownerId, rosterId, questions, created, modified) {
    this._assignment = assignment;
    this._id = id;
    this._ownerId = ownerId;
    this._rosterId = rosterId;
    /** @type {Map.<string, AssignmentWorkQuestion>}*/
    this._questions = questions;
    this._classCode = undefined;
    this._assignmentRoster = undefined;
    this._created = moment(created);
    this._modified = moment(modified);
  }

  /**
   * @returns {boolean}
   */
  get isWork() {
    return true;
  }

  /**
   * @returns {Assignment} The assignment that this work is pertinent to
   */
  get assignment() {
    return this._assignment;
  }

  /**
   * The assignment work id
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * Date of creation
   * @returns {moment}
   */
  get created() {
    return this._created;
  }

  /**
   * Date of most recent update
   * @returns {moment}
   */
  get modified() {
    return this._modified;
  }


  /**
   * @returns {string} The owner of this work
   */
  get ownerId() {
    return this._ownerId;
  }

  /**
   * @returns {string} The roster id associated with this work
   */
  get rosterId() {
    return this._rosterId;
  }

  /**
   * @returns {string} The assignment id associated with this work
   */
  get assignmentId() {
    return this._assignment.id;
  }

  /**
   * @returns {Map.<string, AssignmentWorkQuestion>}
   */
  get questions() {
    return this._questions;
  }

  /**
   * Stops all firebase listeners
   */
  stop() {
    this._assignment.stop();
    this._questions.forEach((q) => q.stop());
  }

  /**
   * Returns the question work at the appropriate index
   * @param index {number}
   * @returns {AssignmentWorkQuestion}
   */
  questionForIndex(index) {
    if (!this._assignment.questions[index]) {
      return null;
    }
    let questionId = this._assignment.questions[index].id;
    return this._questions.get(questionId);
  }

  /**
   * @param questionId
   * @returns {AssignmentWorkQuestion}
   */
  questionForId(questionId) {
    return this._questions.get(questionId);
  }

  /**
   * @param questionId
   * @returns {Number}
   */
  indexForQuestionId(questionId) {
    return this._assignment.indexForQuestionId(questionId);
  }

  /**
   * @param index
   * @returns {string}
   */
  questionIdForIndex(index) {
    return this._assignment.questionIdForIndex(index);
  }

  /**
   * @param questionId {string}
   * @returns {number|undefined}
   */
  questionNumberForId(questionId) {
    return this._assignment.questionNumberForId(questionId);
  }

  /**
   * @return {boolean}
   */
  get hasScore() {
    let result = false;
    this.questions.forEach((question) => {
      result = result || angular.isNumber(question.points);
    });
    return result;
  }

  /**
   * @returns {number}
   */
  totalPoints() {
    let total = 0;
    this.questions.forEach((question) => {
      if (angular.isNumber(question.points)) {
        total += question.points * 100;
      }
    });
    return total/100;
  }

  /**
   * @returns {number}
   */
  totalPotentialPoints() {
    return this.assignment.totalPotentialPoints();
  }

  /**
   * Merges the AssignmentQuestions from another work into
   * this AssignmentWork, preserving any firebase data
   * cached therein. Optionally calls Stop on any questions
   * which exist in the target and not in the this object
   *
   * @param target {AssignmentWork}
   * @param [stopOrphans] {boolean}
   * @returns {AssignmentWork}
   */
  mergeFrom(target, stopOrphans) {
    if (target === this) {
      return this;
    }

    target.questions.forEach((question) => {
      if (this.questions.has(question.id)) {
        this.questions.get(question.id).mergeFrom(question);
      }
      else if (stopOrphans) {
        question.stop();
      }
    });

    return this;
  }

  /**
   * @param questionId {string}
   * @return {Promise.<number>}
   */
  initialElementCountForId(questionId) {

    let contentQuestion = this._assignment.questionForId(questionId);
    let workQuestion = this.questionForId(questionId);

    return Promise.all([ contentQuestion.elementCount, workQuestion.elementCount])
      .then((results) => {
        return results.reduce((current, accum) => {
          return current + accum;
        }, 0);
      });
  }

  /**
   * @param questionIndex {number}
   * @return {Promise.<number>}
   */
  initialElementCountForIndex(questionIndex) {
    let questionId = this.questionIdForIndex(questionIndex);
    return this.initialElementCountForId(questionId);
  }

  /**
   * @param value {ClassCode|undefined}
   */
  set classCode(value) {
    this._classCode = value;
  }

  /**
   * @return {ClassCode|undefined}
   */
  get classCode() {
    return this._classCode;
  }

  /**
   * @param value {AssignmentRoster|undefined}
   */
  set assignmentRoster(value) {
    this._assignmentRoster = value;
  }

  /**
   * @return {AssignmentRoster|undefined}
   */
  get assignmentRoster() {
    return this._assignmentRoster;
  }


  /**
   * @return {boolean}
   */
  get hasStarted() {
    return Array.from(this.questions.values()).some((q) => !!q.startedAt);
  }

}
