
'use strict';

import FeatureDialogTemplate from './feature-dialog.html';

export default class FeatureDialogController {

  constructor($mdDialog, title, url) {
    'ngInject';
    this.$mdDialog = $mdDialog;

    this._title = title;
    this._url = url;
  }

  get title() {
    return this._title;
  }

  get url() {
    return this._url;
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * Shows the help and resources dialog
   *
   * @param $mdDialog
   * @param title {string}
   * @param url {string}
   * @returns {Promise}
   */
  static show($mdDialog, title, url) {
    return $mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: true,
      template:  FeatureDialogTemplate,
      controller: FeatureDialogController,
      controllerAs: 'ctrl',
      locals: {
        title,
        url
      }
    });
  }

}

