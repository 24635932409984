export default class TestUtils {
  static get UppercaseLetters() {
    return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }

  static get LowercaseLetters() {
    return 'abcdefghijklmnopqrstuvwxyz';
  }

  static get Digits() {
    return '0123456789';
  }

  /**
   * @param num {number}
   * @param [chars] {string}
   * @return {string}
   */
  static generateRandomString(num, chars) {
    let text = '';
    let possible = chars || `${TestUtils.UppercaseLetters}${TestUtils.LowercaseLetters}${TestUtils.Digits}`;

    for (let i = 0; i < num; i++ ) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

}