'use strict';

import { SubscriptionFunnel } from '../../services/mixpanel/mixpanel.service';
import ProInfoDialogTemplate from './pro-info-dialog.html';

export default class ProInfoDialogController {

  /**
   * @ngInject
   */
  constructor($mdDialog, BreadcrumbService, AnalyticsService, StaticContentService, LogRocketService, source) {

    this.$mdDialog = $mdDialog;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {LogRocketService} */
    this._logRocketService = LogRocketService;

    this._loading = false;
    this._source = source;

    this._analyticsService.subscriptionFunnel(
      SubscriptionFunnel.PAYWALL,
      undefined,
      undefined,
      this._source
    );
  }

  /**
   * @return {boolean}
   */
  get loading() {
    return this._loading;
  }

  goToTeacherPage() {
    this.$mdDialog.hide();
    this._breadcrumbService.go('root.account.create-payment');
  }

  goToOrderPage() {
    this._analyticsService.subscriptionFunnel(
      SubscriptionFunnel.LEARN_MORE,
      undefined,
      undefined,
      this._source
    );

    this.$mdDialog.hide();
    this._staticContentService.goToOrdersPage(this._logRocketService.initialized);
  }

  static show($mdDialog, source) {
    return $mdDialog.show({
      controller: ProInfoDialogController,
      template: ProInfoDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      locals: {
        source
      }
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

}
