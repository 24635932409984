
'use strict';

import Codec from './codec';
import ElementCodec from './element-codec';
import Link from '../ui/elements/link';

/**
 * Encodes/Decodes an audio chip
 */
export default class AudioClipCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {Link}
   * @returns {{data: Object, metadata: Object}}
   */
  encode(value) {
    return ElementCodec.preEncode(value, (size, center) => {

      return {
        type: value.type,
        url: value.url,
        size: size,
        text: value.text,
        center: center
      };
    });
  }

  /**
   * @param value {object}
   * @param key {string}
   * @returns {Link}
   */
  decode(value, key) {
    return ElementCodec.preDecode(value, (metadata, size, location) => {
      return new Link(
        key,
        metadata,
        location,
        size,
        value.data.text,
        value.data.url
      );
    });
  }
}
