import ImportGoogleRostersDialogTemplate from './import-google-rosters-dialog.html';

export default class ImportGoogleRostersDialogController {

  constructor($mdDialog, RosterService, rosters) {
    'ngInject';

    this.$mdDialog = $mdDialog;

    /** @type {RosterService} */
    this._rosterService = RosterService;

    /** @type {GoogleClassroom.Roster} */
    this.rosters = rosters;
  }

  static show($mdDialog, rosters) {
    return $mdDialog.show({
      controller: ImportGoogleRostersDialogController,
      template: ImportGoogleRostersDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      locals: {
        rosters: rosters
      }
    });
  }

  cancel() {
    return this.$mdDialog.hide([]);
  }

  /**
   * Returns the courses the user intends to import.
   * @param rosters
   */
  importRosters(rosters) {
    return this.$mdDialog.hide(rosters);
  }
}

