export class CleverInfo {

  constructor(accessToken, email, cleverUsername, firstName, lastName, userType, exists) {
    this._accessToken = accessToken;
    this._email = email;
    this._cleverUsername = cleverUsername;
    this._firstName = firstName;
    this._lastName = lastName;
    this._userType = userType;
    this._exists = exists;
  }

  /**
   * @return {string}
   */
  get accessToken() {
    return this._accessToken;
  }

  /**
   * @return {string}
   */
  get email() {
    return this._email;
  }

  /**
   * @return {string}
   */
  get cleverUsername() {
    return this._cleverUsername;
  }

  /**
   * @return {string}
   */
  get firstName() {
    return this._firstName;
  }

  /**
   * @return {string}
   */
  get lastName() {
    return this._lastName;
  }

  /**
   * @return {string}
   */
  get userType() {
    return this._userType;
  }

  /**
   * @return {boolean}
   */
  get exists() {
    return this._exists;
  }
}
