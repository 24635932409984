'use strict';

// Utility class for assembling metadata for events
export default class AnalyticsMetaService {

  constructor($location, $stateParams, AuthService, AnalyticsService) {
    'ngInject';

    this.$location = $location;
    this.$stateParams = $stateParams;

    /** @type {AuthService} */
    this._authService = AuthService;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this.feedbackCounts = {};
  }

  /**
   * @returns true if teacher is on a student's assignment work page
   */
  isTeacherFeedbackPage() {
    const url = this.$location.absUrl();
    return url.includes('/work/feedback/');
  }

  /**
   * Tracks the number of feedbacks on an assignment for changes (which indicate student is notified of / has viewed feedback)
   * This method may be called multiple times with the same count, so it tracks by workId to prevent duplicate events firing.
   *
   * @param {QuestionFeedbackList} feedbackList summary of unviewed feedbacks
   * @param {Number} nextCount new number to be displayed (questions in assignment with fresh feedback)
   */
  checkForFeedbackUpdates(feedbackList, nextCount) {
    const workId = feedbackList._assignmentWork ? feedbackList._assignmentWork._id : undefined;
    if (angular.isUndefined(workId)) { return; }

    // have we tracked this work before?
    if (angular.isDefined(this.feedbackCounts[workId])) {

      const previousCount = this.feedbackCounts[workId];

      if (nextCount > previousCount) {
        // the number went up : new feedback availability is displayed to the student
        this._analyticsService.sendEvent({
          eventTag: 'feedback:student_notified',
          properties: { assignmentWorkId: workId }
          });
      }
      if (nextCount < previousCount) {
        // the number went down : student has viewed feedback
        this._analyticsService.sendEvent({
          eventTag: 'feedback:student_viewed',
          properties: { assignmentWorkId: workId }
        });
      }
    }

    this.feedbackCounts[workId] = nextCount;
  }

  /**
   * Builds feedback common to all `feedback:${feedbackType}_added` events
   * @returns analytics event custom props
   */
  buildFeedbackAddedMeta() {
    return {
      teacherId: this._authService.currentUserId,
      assignmentId: this.$stateParams.assignmentId,
      rosterId: this.$stateParams.rosterId,
      assignmentWorkId: this.$stateParams.assignmentWorkId,
      questionId: this.$stateParams.questionId
    };
  }

  /**
   * helps identify feedback-indicator location for `feedback:student_navigated` events
   * @returns true if on the pro student dashboard, false otherwise
   */
  isStudentDashboard() {
    const url = this.$location.absUrl();
    return url.includes('/student-assignments');
  }

  /**
   * Builds props for `feedback:student_navigated` events
   * @param {AssignmentWork} assignmentWork
   * @returns analytics event custom props
   */
  buildFeedbackNavigatedMeta(assignmentWork) {
    return {
      teacherId: assignmentWork.assignment.ownerId,
      assignmentId: assignmentWork.assignmentId,
      rosterId: assignmentWork.rosterId,
      assignmentWorkId: assignmentWork.id,
      location: this.isStudentDashboard() ? 'Student Dashboard' : 'Within Assignment'
    };
  }
}
