'use strict';

import moment from 'moment';
import FirebaseElementSavingIndicator from '../../components/saving-indicator/firebase-element-saving-indicator';
import StudentSessionData from './student-session-data';
import { AssignmentToolbarConfig } from '../../components/assignment-toolbar/assignment-toolbar.directive';
import { AssignmentQuestionMetadata } from '../../components/assignment-question/assignment-question.directive';
import AssignmentExportDialogController
  from '../../components/assignment-export-dialog/assignment-export-dialog.controller';
import {ElementIntents} from '../../model/domain/element-metadata';
import {UserRoles} from '../../model/domain/user';

/**
 * Parent class with business logic for student work controllers
 */
export default class StudentAssignmentWork {

  constructor($timeout, $state, $mdDialog, StudentCacheService, AssignmentTrackingService, CacheService, AnalyticsService,
              AssignmentWorkService, ExportService, FeedbackService, PlatformHeaderService, ClassCodeService, helpInboxType, pager) {

    this.$timeout = $timeout;
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {AssignmentTrackingService} */
    this._assignmentTrackingService = AssignmentTrackingService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {ExportService} */
    this._exportService = ExportService;
    /** @type {FeedbackService} */
    this._feedbackService = FeedbackService;
    /** @type {PlatformHeaderService} */
    this._platformHeaderService = PlatformHeaderService;
    /** @type {ClassCodeService} */
    this._classCodeService = ClassCodeService;

    this._subscriptionData = undefined;
    this._coreData = undefined;
    this._assignmentQuestionConfig = undefined;
    this._savingIndicator = new FirebaseElementSavingIndicator(this.$timeout, this._assignmentTrackingService);
    this._helpInboxType = helpInboxType;
    this._pager = pager;
    this._calledOnPreviewModified = false;

    this._showExportAssignmentDialog = AssignmentExportDialogController.show;

    this._assignmentTrackingService.previewModified.subscribe(this._onPreviewModified, this);
  }

  /**
   * @return {string}
   */
  get saveState() {
    return this._savingIndicator && this._savingIndicator.saveState;
  }

  /**
   * @return {boolean}
   */
  get allowPeerHelp() {
    return this.roster && this.roster.allowPeerHelp;
  }

  /**
   * @return {Roster}
   */
  get roster() {
    return this.subscriptionData && this.subscriptionData.roster;
  }

  /**
   * A full StudentSessionData, complete with firebase subscriptions (listens)
   * @return {StudentSessionData}
   */
  get subscriptionData() {
    return this._subscriptionData;
  }

  /**
   * The core StudentSessionData we need to initialize the canvas
   * @return {StudentSessionData}
   */
  get coreData() {
    // if we have more complete (subscribed) data, use that
    if (this._subscriptionData) {
      return this._subscriptionData;
    }

    return this._coreData;
  }

  /**
   * @return {AssignmentQuestionMetadata}
   */
  get assignmentQuestionConfig() {
    return this._assignmentQuestionConfig;
  }

  /**
   * @param assignmentWork {AssignmentWork}
   * @param questionId {string}
   * @return {Promise<AssignmentWork>}
   *
   * note:
   * initCoreData will allow assignmentWork page to load
   * without loading all the data the view requires.
   *
   * this is to speed up assignment loading for students
   * related JIRA: https://getclasskick.atlassian.net/browse/ENG-197
   *
   */
  initCoreData(assignmentWork, questionId) {
    this.setupCanvasDisplay(assignmentWork, questionId);

    let assignmentId = assignmentWork.assignment.id;
    let rosterId = assignmentWork.rosterId;
    let userId = assignmentWork.ownerId;

    this._platformHeaderService.setAssignmentId(assignmentId);
    this._platformHeaderService.setRosterId(rosterId);

    let questionFeedbackList = this._feedbackService.getQuestionFeedbackList(userId, assignmentWork);
    return this._studentCacheService.getClassCodeForAssignmentAndRoster(assignmentId, rosterId, true)
      .then((classCode) => {
        let coreStudentSessionData = new StudentSessionData(
          assignmentWork,
          questionFeedbackList,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          classCode,
          null);
        this._coreData = coreStudentSessionData;

        let config =  new AssignmentQuestionMetadata(
          assignmentWork,
          this.questionId,
          this.lockStudentWork,
          false,
          userId,
          UserRoles.STUDENT,
          ElementIntents.WORK,
          this._pager,
          null,
          questionFeedbackList
        );

        this._assignmentQuestionConfig = config;

        return assignmentWork;
      });
  }

  setupCanvasDisplay(assignmentWork, questionId) {
    this.assignmentWorkId = assignmentWork.id;
    this.questionId = questionId;

    if (!this.questionId) {
      this.questionId = assignmentWork.assignment.questionIdForIndex(0);
    }

    this.toolbarOptions = new AssignmentToolbarConfig();
    this.toolbarOptions.requestHelp = true;
    this.toolbarOptions.toggleFeedback = true;
    this.toolbarOptions.viewScore = true;
  }

  /**
   * @param assignmentWork {AssignmentWork}
   * @param questionId {string}
   * @return {Promise<StudentSessionData>}
   */
  initSubscriptionData(assignmentWork, questionId) {
    return this._studentCacheService
      .getSessionData(
        assignmentWork,
        this._cacheService
      )
      .then((sessionData) => {
        this._subscriptionData = sessionData;

        this._assignmentQuestionConfig = this.subscriptionData.createQuestionConfig(
          this.questionId,
          this.lockStudentWork,
          this._pager
        );

        this.subscriptionData.assignmentNotification.questionAdded.subscribe(this._handleAssignmentUpdated, this);
        this.subscriptionData.assignmentNotification.questionRemoved.subscribe(this._handleAssignmentUpdated, this);
        this.subscriptionData.assignmentNotification.questionUpdated.subscribe(this._handleQuestionUpdated, this);
        this.subscriptionData.questionFeedbackList.updated.subscribe(this._handleFeedbackGivenUpdate, this);
        this.subscriptionData.classCode.lockStudentWorkUpdated.subscribe(this._handleLockStudentWork, this);

        if (this.questionId) {
          this.subscriptionData.setUserStatus(this.questionId);
          this._feedbackService.view(this.subscriptionData.userId, this.subscriptionData.assignmentWork, this.questionId);

          let questionWork = this.subscriptionData.assignmentWork.questionForId(this.questionId);
          if (!questionWork.startedAt) {
            questionWork.startedAt = moment();
            this._assignmentWorkService.updateQuestion(this.subscriptionData.assignment.id, this.subscriptionData.assignmentWork.id, questionWork);
          }
        }
      });
  }

  destroy() {
    this._platformHeaderService.setAssignmentId(undefined);
    this._platformHeaderService.setRosterId(undefined);

    if (this.subscriptionData && this.subscriptionData.assignmentNotification) {
      this.subscriptionData.assignmentNotification.questionAdded.unsubscribe(this._handleAssignmentUpdated, this);
      this.subscriptionData.assignmentNotification.questionRemoved.unsubscribe(this._handleAssignmentUpdated, this);
      this.subscriptionData.assignmentNotification.questionUpdated.unsubscribe(this._handleQuestionUpdated, this);
    }

    if (this.subscriptionData && this.subscriptionData.questionFeedbackList) {
      this.subscriptionData.questionFeedbackList.updated.unsubscribe(this._handleFeedbackGivenUpdate, this);
    }

    if (this.subscriptionData && this.subscriptionData.classCode && this.subscriptionData.classCode.lockStudentWorkUpdated) {
      this.subscriptionData.classCode.lockStudentWorkUpdated.unsubscribe(this._handleLockStudentWork, this);
    }

    this._assignmentTrackingService.previewModified.unsubscribe(this._onPreviewModified, this);

    if (this._savingIndicator) {
      this._savingIndicator.destroy();
    }
  }

  _handleQuestionUpdated(change) {
    let questionIndex = this.subscriptionData.assignment.indexForQuestionId(change.questionId);
    let nextIndex = this.subscriptionData.assignment.indexForQuestionId(change.beforeQuestionId);

    if (questionIndex !== nextIndex - 1) {
      this._handleAssignmentUpdated();
    }
  }

  _handleAssignmentUpdated() {
    StudentSessionData.launchAssignmentUpdatedDialog(this.$mdDialog)
      .then(() => {
        // Calling reset refreshes the student cache so it has the right questions on reload
        this._studentCacheService.reset();
        this.$state.reload();
      });
  }

  _handleLockStudentWork() {
    this._assignmentQuestionConfig = this.subscriptionData.createQuestionConfig(
      this.questionId,
      this.lockStudentWork,
      this._pager
    );
  }

  /**
   * @return {boolean}
   */
  get lockStudentWork() {
    if (this.coreData && this.coreData.classCode && angular.isDefined(this.coreData.classCode.lockStudentWork)) {
      return this.coreData.classCode.lockStudentWork;
    }
    return false;
  }

  /**
   * @return {boolean}
   */
  get showStudentScore() {
    if (this.coreData) {
      return this.coreData.showStudentScore(this.questionId);
    }
    return false;
  }

  /** Help Inbox and Request functionality **/

  /**
   * @return {HelpInboxMetadata}
   */
  get helpInboxMetadata() {
    return this.subscriptionData && this.subscriptionData.helpInboxMetadata(this._helpInboxType);
  }

  /** Unread Feedback Inbox Functionality **/

  /**
   * @param value {{id: string, t:string}}
   */
  _handleFeedbackGivenUpdate(value) {
    if (this.questionId === value.id) {
      this._feedbackService.view(this.subscriptionData.userId, this.subscriptionData.assignmentWork, this.questionId);
    }
    this.$timeout(() => {});
  }

  /**
   * @param data {{type:string, element:Element}}
   */
  _onPreviewModified(data) {
    if (!this._calledOnPreviewModified) {
      this._calledOnPreviewModified = true;
    }
  }

  /**
   * @returns {string}
   */
  get assignmentTitle() {
    // Note: this is using subscription data in order to force the title to say "Loading..."
    // until the full set of subscriptionData is fetched, even though we have the assignment name in coreData
    if (this.subscriptionData && this.subscriptionData.assignment) {
      return this.subscriptionData.assignment.name;
    }
    return 'Loading...';
  }

  /**
   * @return {boolean}
   */
  get disablePDFExport() {
    return !this.subscriptionData || this.lockStudentWork;
  }

  exportToPdf() {
    let works = new Map().set(this.subscriptionData.userId, this.subscriptionData.assignmentWork);
    let students = [{ name: this.subscriptionData.user.name, id: this.subscriptionData.userId }];
    let assignmentWorks = this._exportService.createAssignmentExports(works, students, this.subscriptionData.classCode, this.subscriptionData.assignment, this.subscriptionData.classCode.showStudentScores);

    this._showExportAssignmentDialog(this.$mdDialog, assignmentWorks, ElementIntents.WORK, UserRoles.STUDENT, true);
  }

}
