
'use strict';

import CkRedirect from '../../model/domain/ck-redirect';

export default class AccountController {
  constructor($state, $stateParams, $location, BootstrapService, AuthService, StorageService) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$location = $location;
    /** @type {BootstrapService} */
    this._bootstrapService = BootstrapService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    if (this._bootstrapService.ready) {
      // check if user is authenticated
      if (!this._authService.isLoggedIn) {
        this._storageService.ckRedirect = new CkRedirect(null, null, this.$location.url());
        this.$state.go('root.account-login');
      }
    }
  }
}
