
'use strict';

/**
 * Enables filtering objects in ng-repeat
 *
 * @returns {Function}
 */
export default function orderObjectByFilter() {
  return function(items, compare, reverse) {
    var filtered = [];
    angular.forEach(items, (item) => {
      filtered.push(item);
    });
    filtered.sort((a, b) => {
      return (compare(a) > compare(b) ? 1 : -1);
    });
    if (reverse) {
      filtered.reverse();
    }
    return filtered;
  };
}

