'use strict';

import FolderNameDialogTemplate from './folder-name-dialog.html';

export default class FolderNameDialogController {

  /**
   * @ngInject
   */
  constructor($mdDialog, name, title) {
    this.$mdDialog = $mdDialog;
    this._name = name;
    this._title = title;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get title() {
    return this._title;
  }

  static show($mdDialog, name = '', title = 'Create New Folder') {
    return $mdDialog.show({
      controller: FolderNameDialogController,
      template: FolderNameDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      locals: {
        name: name,
        title: title
      }
    });
  }

  submitName() {
    this.$mdDialog.hide(this.name);
  }

  cancel() {
    this.$mdDialog.cancel();
  }

}
