
'use strict';

import LazyVar from '../../model/util/lazy-var';
import JSEvent from '../../model/util/js-event';
import StudentSessionData from '../../model/domain/student-session-data';

/**
 * Cache service for student accounts
 */
export default class StudentCacheService {
  /**
   * @param $q
   * @param AuthService {AuthService}
   * @param UserService {UserService}
   * @param RosterService {RosterService}
   * @param AssignmentService {AssignmentService}
   * @param AssignmentWorkService {AssignmentWorkService}
   * @param NotificationService {NotificationService}
   * @param HelpRequestService {HelpRequestService}
   * @param ClassCodeService {ClassCodeService}
   * @param FeedbackService {FeedbackService}
   *
   * @ngInject
   */
  constructor($q, AuthService, UserService, RosterService, AssignmentService, AssignmentWorkService, NotificationService,
              HelpRequestService, ClassCodeService, FeedbackService) {

    this.$q = $q;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {UserService} */
    this._userService = UserService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;
    /** @type {HelpRequestService} */
    this._helpRequestService = HelpRequestService;
    /** @type {ClassCodeService} */
    this._classCodeService = ClassCodeService;
    /** @type {FeedbackService} */
    this._feedbackService = FeedbackService;

    this.reset();

    this._authService.dataCleared.subscribe(this.reset, this);
  }

  /**
   * Initializes / resets the service
   */
  reset() {
    /** @type {LazyVar.<Promise.<{rosters: Map<string, Roster>, rosterOwners: Map<string, User>}>>} */
    this._rostersAndOwners = new LazyVar();
    /** @type {LazyVar.<Promise.<{assignments: Map<string, Assignment>, works: Map<string, AssignmentWork>}>>} */
    this._assignmentsAndWorks = new LazyVar();
    /** @type {LazyVar.<Promise.<Map<string, AssignmentWork>>>} */
    this._works = new LazyVar();
    /** @type {LazyVar.<Promise.<ClassCode>>} */
    this._classCode = new LazyVar();
    /** @type {LazyVar.<Promise.<AssignmentWork>>} */
    this._assignmentWork = new LazyVar();


    if (this._userRosterNotification && this._userRosterNotification.isSet) {
      let notif = this._initUserRosterNotification();
      notif.updated.unsubscribe(this._onUserRosterUpdate, this);
      notif.stop();
    }
    /** @type {LazyVar.<UserRosterNotification>} */
    this._userRosterNotification = new LazyVar();

    if (this._userRosterUpdated) {
      this._userRosterUpdated.clear();
    }
    /** @type {JSEvent.<{rosterId: string, change: string, t: moment}>} */
    this._userRosterUpdated = new JSEvent(this);

    this._clearStudentSessionData();
    /** @type {LazyVar.<Promise.<StudentSessionData>>} */
    this._studentSessionData = new LazyVar();
  }

  /**
   * @param [getFresh] {boolean}
   * @returns {Promise.<{rosters: Map<string, Roster>, rosterOwners: Map<string, User>}>}
   */
  getUserRostersAndOwners(getFresh) {

    if (getFresh) {
      this._rostersAndOwners.clear();
    }

    this._initUserRosterNotification();

    return this._rostersAndOwners.value(() =>
      this._rosterService.getUserMemberRosters()
        .then((result) => {
          return {
            rosterOwners: new Map(result.rosterOwners.map((user) => [user.id, user])),
            rosters: new Map(result.rosters.map((roster) => [roster.id, roster]))
          };
        })
        .catch((err) => {
          this._rostersAndOwners.clear();
          throw err;
        })
    );
  }

  getClassCodeForAssignmentAndRoster(assignmentId, rosterId, getFresh){
    if (getFresh || (assignmentId !== this._classCode.assignmentId) || (rosterId !== this._classCode.rosterId)) {
      this._classCode.clear();
    }

    return this._classCode.value(() => {
      return this._classCodeService.getForAssignmentAndRoster(assignmentId, rosterId)
        .catch((err) => {
          this._classCode.clear();
          throw err;
        });
    });
  }

  /**
   * @param [getFresh] {boolean}
   * @returns {Promise.<{assignments: Map<string, Assignment>, works: Map<string, AssignmentWork>, assignmentRosters: AssignmentRoster[]}>}
   */
  getUserAssignmentsAndWorks(getFresh) {

    if (getFresh) {
      this._assignmentsAndWorks.clear();
    }

    return this._assignmentsAndWorks.value(() =>
      this._assignmentWorkService.getForCurrentUser()
        .then(({works, assignments, assignmentRosters}) => {
          return {
            works: new Map(works.map((w) => [w.id, w])),
            assignments,
            assignmentRosters
          };
        })
        .catch((err) => {
          this._assignmentsAndWorks.clear();
          throw err;
        })
    );
  }

  getUserWork(assignmentWorkId, getFresh) {
    return this.getUserAssignmentsAndWorks(false)
      .then((result) => result.works)
      .then((works) => {
        if (getFresh || !works.has(assignmentWorkId)) {
          return this._assignmentWorkService.getAssignment(assignmentWorkId)
            .then((assignment) => {
              return this._assignmentWorkService.get(assignment, assignmentWorkId);
            })
            .then((work) => {
              works.set(assignmentWorkId, work);
              return work;
            })
            .catch((err) => {
              works.delete(assignmentWorkId);
              throw err;
            });
        }
        else {
          return works.get(assignmentWorkId);
        }
      });
  }

  /**
   * Create a work, add it to the cache
   *
   * @param assignment {Assignment}
   * @param roster {Roster}
   * @returns {Promise.<AssignmentWork>}
   */
  createWork(assignment, roster) {
    return this.$q.all({
        cache: this.getUserAssignmentsAndWorks(),
        work: this._assignmentWorkService.getOrCreateForSelfWithAssignment(assignment, roster.id)
      })
      .then((result) => {
        if (result.cache.works.has(result.work.id)) {
          return result.cache.works.get(result.work.id);
        }
        else {
          result.cache.works.set(result.work.id, result.work);
          return result.work;
        }
      });
  }

  /**
   * @returns {UserRosterNotification}
   * @private
   */
  _initUserRosterNotification() {
    return this._userRosterNotification.value(() => {
      const notif = this._notificationService.getUserRosterNotification(this._authService.authData.id);
      notif.start();
      notif.updated.subscribe(this._onUserRosterUpdate, this);
      return notif;
    });
  }

  /**
   * @param update {{rosterId: string, change: string, t: moment}}
   * @private
   */
  _onUserRosterUpdate(update) {
    if (update.assignmentId) {
      this.userRosterUpdated.raise(update);
    } else {
      // Quick and dirty, CJ has optimized code if needed
      this.getUserRostersAndOwners(true)
        .then(() => this.getUserAssignmentsAndWorks(true))
        .then(() => this.userRosterUpdated.raise(update));
    }
  }

  /**
   * @returns {JSEvent.<{rosterId: string, change: string, t: moment}>}
   */
  get userRosterUpdated() {
    return this._userRosterUpdated;
  }

  /**
   * @param assignmentWork {AssignmentWork}
   * @param cacheService {CacheService}
   * @param [getFresh] {boolean}
   * @return {Promise.<StudentSessionData>}
   */
  getSessionData(assignmentWork, cacheService, getFresh) {

    if (getFresh || this._studentSessionData.assignmentWorkId !== assignmentWork.id) {
      this._clearStudentSessionData();
    }

    this._studentSessionData.assignmentWorkId = assignmentWork.id;

    return this._studentSessionData.value(() => {
      return StudentSessionData
        .fetch(
          assignmentWork,
          this.$q,
          this,
          this._notificationService,
          this._assignmentWorkService,
          this._helpRequestService,
          this._feedbackService,
          cacheService
        )
        .then((session) => {
          return this.$q.all({
            check: session.validate(),
            session
          });
        })
        .then((result) => {
          return result.session;
        })
        .catch((err) => {
          this._clearStudentSessionData();
          throw err;
        });
    });
  }

  _clearStudentSessionData() {
    if (this._studentSessionData) {
      this._studentSessionData.assignmentWorkId = null;
      this._studentSessionData.clear((value) => value.then((studentSessionData) => studentSessionData.destroy()));
    }
  }

  /**
   * @param assignmentId {string}
   * @param rosterId {string}
   * @return {Promise<AssignmentWork>}
   */
  getOrCreateWorkForSelf(assignmentId, rosterId) {
    if (this._assignmentWork.assignmentId !== assignmentId || this._assignmentWork.rosterId !== rosterId) {
      this._assignmentWork.clear();

      this._assignmentWork.assignmentId = assignmentId;
      this._assignmentWork.rosterId = rosterId;
    }

    return this._assignmentWork.value(() => {
      return this._assignmentWorkService.getOrCreateForSelf(assignmentId, rosterId);
    });
  }
}
