'use strict';

import moment from 'moment';

/**
 * Contains information needed for public assignments in the library
 */
export default class LibraryAssignment {
  /**
   * @param id {string} the assignment id
   * @param name {string} the title of the assignment
   * @param ownerId {string} the id of the owner
   * @param brief {string}
   * @param access {string}
   * @param lastModified {string}
   * @param questions {Array.<AssignmentQuestion>}
   * @param ownerName {object}
   * @param grades {string}
   * @param subjects {string}
   * @param questionCount {number}
   * @param tags {Array.<AssignmentTag>}
   */
  constructor(
    id,
    name,
    ownerId,
    brief,
    access,
    lastModified,
    questions,
    ownerName,
    subjects,
    grades,
    questionCount,
    tags
  ) {
    this._id = id;
    this._name = name;
    this._ownerId = ownerId;
    this._brief = brief;
    this._access = access;
    this._lastModified = lastModified ? moment(lastModified) : undefined;
    this._questions = questions;
    this._ownerName = ownerName ? ownerName : undefined;
    this._subjects = subjects ? subjects.split(','): [];
    this._grades =  grades ? grades.split(','): [];
    this._questionCount = questionCount;
    this._tags =  tags ? tags: [];
  }

  /**
   * @returns {string} assignment id
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {string} assignment name
   */
  get name() {
    return this._name;
  }

  /**
   * @param value {string}
   */
  set name(value) {
    this._name = value;
  }

  /**
   * @returns {string} id of owner
   */
  get ownerId() {
    return this._ownerId;
  }

  /**
   * @returns {Array.<AssignmentQuestion>}
     */
  get questions() {
    return this._questions;
  }

  /**
   * @returns {string}
     */
  get brief() {
    return this._brief;
  }

  /**
   * @param value {string}
   */
  set brief(value) {
    this._brief = value;
  }

  /**
   * @returns {string}
   */
  get access() {
    return this._access;
  }

  /**
   * @param value {string}
   */
  set access(value) {
    this._access = value;
  }

  /**
   * @returns {moment}
   */
  get lastModified() {
    return this._lastModified;
  }

  /**
   * formats the lastModified property as date
   *
   * @returns {string}
   */
  get lastModifiedAsDate() {
    return this._lastModified.format('MM/DD/YYYY');
  }

  /**
   * @return {string[]}
   */
  get subjects() {
    return this._subjects;
  }

  /**
   * @param value {string[]}
   */
  set subjects(value) {
    this._subjects = value;
  }

  get displaySubjects() {
    return this.subjects && this.subjects.join(', ');
  }

  /**
   * @return {string[]}
   */
  get grades() {
    return this._grades;
  }

  /**
   * @param value {string[]}
   */
  set grades(value) {
    this._grades = value;
  }

  get displayGrades() {
    return this.grades && this.grades.join(', ');
  }

  get tags() {
    return this._tags;
  }

  /**
   * @returns {object}
   */
  get ownerName(){
    return this._ownerName;
  }

  /**
   * @returns {string}
   */
  get displayName(){
    return this._ownerName && this._ownerName.display_name;
  }

  /**
   * @returns {string}
   */
  get firstName(){
    return this._ownerName && this._ownerName.first_name;
  }

  /**
   * @returns {string}
   */
  get lastName(){
    return this._ownerName && this._ownerName.last_name;
  }

  displayAuthorName(){
    if (this.displayName) {
      return this.displayName;
    } else if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    } else if (this.lastName) {
      return this.lastName;
    } else if (this.firstName) {
      return this.firstName;
    } else {
      return '';
    }
  }

  /**
   * @returns {string}
   */
  get questionCount(){
    return this._questionCount;
  }
}
