'use strict';

import Codec from './codec';
import MdrOrganizationAdmin from '../domain/mdr-organization-admin';

export default class MdrOrganizationAdminCodec extends Codec {
  constructor() {
    super();
  }

  encode(value) {
    return value;
  }

  /**
   * Decodes the response from /v1/mdr-organizations/{organizationId}/admin
   *
   * The response formats are:
   * - {}, if no data is returned
   * - { admin: <object>, parent_admin: <object>}, if one value is returned for admin or parent_admin
   * - { admin: [<object>], parent_admin: [<object>]}, if more values return for admin or parent_admin
   *
   * @param value
   * @returns {{parent_admins: *[MdrOrganizationAdmin], admins: *[MdrOrganizationAdmin]}}
   */
  decode(value = {}) {
    let admins = [];
    let parent_admins = [];

    if (value.admin) {
      const respAdmin = value.admin;
      if (angular.isDefined(respAdmin.email)) {
        admins.push(new MdrOrganizationAdmin(
          respAdmin.email,
          respAdmin.first_name,
          respAdmin.last_name,
          respAdmin.pref_title
        ));
      } else {
        respAdmin.forEach((admin) => {
          admins.push(new MdrOrganizationAdmin(
            admin.email,
            admin.first_name,
            admin.last_name,
            admin.pref_title
          ));
        });
      }
    }

    if (value.parent_admin) {
      const respParentAdmin = value.parent_admin;
      if (angular.isDefined(respParentAdmin.email)) {
        parent_admins.push(new MdrOrganizationAdmin(
          respParentAdmin.email,
          respParentAdmin.first_name,
          respParentAdmin.last_name,
          respParentAdmin.pref_title
        ));
      } else {
        respParentAdmin.forEach((admin) => {
          parent_admins.push(new MdrOrganizationAdmin(
            admin.email,
            admin.first_name,
            admin.last_name,
            admin.pref_title
          ));
        });
      }
    }

    return {
      admins: admins,
      parent_admins: parent_admins
    };
  }
}
