'use strict';

import Point from './../point';
import Size from './../size';
import Element from './element';
import ResizeHandle from '../resize-handle';
import RemoveHandle from '../remove-handle';
import TextToSpeechHandle from '../text-to-speech-handle';
import TextToSpeech from './text-to-speech';
import GestureManager from '../gesture-manager';
import TextboxArranger from './textbox-arranger';
import TextboxCursor from './textbox-cursor';
import TextboxSelection from './textbox-selection';
import StaticService from '../../../services/static/static.service';
import JSEvent from '../../util/js-event';
import HexColors from '../../../css-constants';
import ColorSpanArranger from './textbox-color-span-arranger';
import { FitbAnswerTypes } from '../../../components/assignment-toolbar/assignment-toolbar.directive';

export class BorderStyle {

  static get DASHED() {
    return 'dashed';
  }

  static get SOLID() {
    return 'solid';
  }

  static get DEFAULT_DASH_SPACING() {
    return '5,5';
  }

}

export default class TextboxBase extends Element {

  /**
   * @param id {string}
   * @param type {string}
   * @param metadata {ElementMetadata}
   */
  constructor(id, type, metadata, format) {
    super(id, type, metadata);

    this._id = id;
    this._format = format;
    this._location = new Point(100, 50);
    // Replace empty text with an empty string rather than a placeholder to avoid frustrating new text for empty textboxes on the way back from Firebase.
    this._text = '';
    this._fontSize = TextboxBase.DEFAULT_FONT_SIZE;
    this._fontFamily = TextboxBase.DEFAULT_TEXT_FONT_FAMILY;
    this._size = new Size(300, 200);
    this._padding = TextboxBase.DEFAULT_PADDING;
    this._minSize = new Size(40, 40);
    this._colorSpans = [];
    this._activeColor = TextboxBase.DEFAULT_TEXT_COLOR;
    this._backgroundColor = TextboxBase.DEFAULT_BACKGROUND_COLOR;
    this._borderColor = TextboxBase.DEFAULT_BORDER_COLOR;
    this._borderStyle = BorderStyle.SOLID;
    this._placeholderText = TextboxBase.DEFAULT_PLACEHOLDER_TEXT;
    this._editBoxBorderColor = TextboxBase.DEFAULT_EDIT_BOX_BORDER_COLOR;

    this._selectionChanged = new JSEvent(this);
    // Raised each time something is updated in textbox that should be reflected in toolbar (e.g. text color)
    this._activeStateChanged = new JSEvent(this);

    this._enableResize = true;
    this._enableRemove = true;
    this._enableReposition = true;

    this._background = undefined;
    this._content = undefined;
    this._interactive = undefined;

    this._arranger = new TextboxArranger();
    this._arranger.lineHeightScale = StaticService.get.isWindows ? 1 : 1.2;
    this._cursor = new TextboxCursor(this, undefined, this._activeColor);
    this._selection = new TextboxSelection(this);
    this._colorSpanArranger = new ColorSpanArranger();

    this._previousStartIndex = undefined;
    this._previousEndIndex = undefined;

    this._anonSetTouched = () => {this.untouched = false;};
    this.previewChanged.once(() => this._anonSetTouched(), this);

    this._textToSpeech = TextToSpeech.instance;
  }

  static get DEFAULT_FONT_SIZE() {
    return 25;
  }

  static get DEFAULT_TEXT_FONT_FAMILY() {
    return 'GothamRoundedMedium';
  }

  static get DEFAULT_TEXT_COLOR() {
    return HexColors.CK_ELEMENT_BLACK;
  }

  static get DEFAULT_BACKGROUND_COLOR() {
    return HexColors.CK_TRANSPARENT;
  }

  static get DEFAULT_BORDER_COLOR() {
    return HexColors.CK_TRANSPARENT;
  }

  static get DEFAULT_EDIT_BOX_BORDER_COLOR() {
    return HexColors.CK_GREEN;
  }

  static get FONT_INCREMENT() {
    return 5;
  }

  static get FONT_SIZE_MAX() {
    return 60;
  }

  static get FONT_SIZE_MIN() {
    return 10;
  }

  /**
   * @returns {Size} The padding between the textbox edge and the rendered text
   */
  static get DEFAULT_PADDING() {
    return new Size(5, 5);
  }

  static get DEFAULT_PLACEHOLDER_TEXT() {
    return 'Click here to type';
  }

  /**
   * @returns {string}
   */
  get text() {
    return this._text;
  }

  /**
   * @returns {Array.<ColorSpan>}
   */
  get colorSpans() {
    return this._colorSpans;
  }

  /**
   * @param text {string}
   * @param startIndex {string}
   * @param endIndex {string}
   */
  setText(text, startIndex, endIndex) {
    this._trackChange(() => {

      let previousStartIndex = this.previousStartIndex;
      let previousEndIndex = this.previousEndIndex;

      if (angular.isUndefined(previousStartIndex) || angular.isUndefined(previousEndIndex)) {
        previousStartIndex = startIndex - 1;
        previousEndIndex = endIndex - 1;
      }

      this._colorSpans = this._colorSpanArranger.updateColorSpans(
        this._colorSpans,
        this._text,
        text,
        previousStartIndex,
        previousEndIndex,
        startIndex,
        endIndex,
        this._activeColor
      );

      const oldText = this._text;
      this._text = text;

      if (this._shouldResetActiveColor(previousStartIndex, previousEndIndex, startIndex, text, oldText)) {
        this.setActiveColor(startIndex);
      }
    });
  }

  /**
   * @param previousStartIndex {number}
   * @param previousEndIndex {number}
   * @param startIndex {number}
   * @param newText {string}
   * @param oldText {string}
   * @returns {boolean}
   */
  _shouldResetActiveColor(previousStartIndex, previousEndIndex, startIndex, newText, oldText) {
    let previousCharactersSelected = previousEndIndex - previousStartIndex;
    let textChange = newText.length - oldText.length;
    return this.activeColorForCurrentIndex(startIndex) !== this._activeColor && previousCharactersSelected === 0 && textChange < 0;
  }

  /**
   * Sets the selection range and cursor position
   * @param startIndex {int}
   * @param endIndex {int}
   */
  setSelection(startIndex, endIndex) {
    this._previousStartIndex = startIndex;
    this._previousEndIndex = endIndex;
    this._selection.info = this._arranger.selectionForIndices(
      this.isCursorStarting ? endIndex : startIndex,
      true,
      this.isCursorStarting ? startIndex : endIndex,
      true
    );
    this.tryUpdate();
  }

  get isCursorStarting() {
    return this._selection.info.cursor.index === this._selection.info.start.index;
  }

  /**
   * {SelectionInfo}
   */
  get selectionInfo() {
    return this._selection && this._selection._info;
  }

  /**
   * @returns {number}
   */
  get startIndex() {
    return this.selectionInfo.startIndex;
  }

  /**
   * @returns {number}
   */
  get endIndex() {
    return this.selectionInfo.endIndex;
  }

  /**
   * @returns {number}
   */
  get previousStartIndex() {
    return this._previousStartIndex;
  }

  /**
   * @returns {number}
   */
  get previousEndIndex() {
    return this._previousEndIndex;
  }

  get cursor() {
    return this._cursor;
  }

  set textColor(color) {
    this.activeColor = color;

    if (this.isTextSelected) {
      this._trackChange(() => {
        this._colorSpans = this._colorSpanArranger.updateSelectedTextColor(this._colorSpans, this.startIndex, this.endIndex - 1, this._text, this._activeColor);
      });
    }
  }

  /**
   * @return {string}
   */
  get backgroundColor() {
    return this._backgroundColor;
  }

  /**
   * @param color {string}
   */
  set backgroundColor(color) {
    this._trackChange(() => {
      this._backgroundColor = color;
      this.raiseActiveStateChanged();
    });
  }

  /**
   * @return {string}
   */
  get fontFamily() {
    return this._fontFamily;
  }

  /**
   * @param color {string}
   */
  set fontFamily(font) {
    this._trackChange(() => {
      this._fontFamily = font;
      this.raiseActiveStateChanged();
    });
  }

  /**
   * @return {string}
   */
  get borderColor() {
    return this._borderColor;
  }

  /**
   * @param value {string}
   */
  set borderColor(value) {
    this._trackChange(() => {
      this._borderColor = value;
      this.raiseActiveStateChanged();
    });
  }

  get isTextSelected() {
    return this._selection.info.startIndex < this._selection.info.endIndex;
  }

  /**
   * @returns {number}
   */
  get fontSize() {
    return this._fontSize;
  }

  /**
   * @param value {number}
   */
  set fontSize(value) {
    let newValue = value;
    if (newValue > TextboxBase.FONT_SIZE_MAX) {
      newValue = TextboxBase.FONT_SIZE_MAX;
    }
    else if (newValue < TextboxBase.FONT_SIZE_MIN) {
      newValue = TextboxBase.FONT_SIZE_MIN;
    }

    if (newValue !== this.fontSize) {
      this._trackChange(() => {
        this._fontSize = newValue;
      });
    }
  }

  /**
   * @returns {boolean}
   */
  get isFontSizeAtMax() {
    return this.fontSize >= TextboxBase.FONT_SIZE_MAX;
  }

  /**
   * @returns {boolean}
   */
  get isFontSizeAtMin() {
    return this.fontSize <= TextboxBase.FONT_SIZE_MIN;
  }

  /**
   * @returns {string}
   */
  get activeColor() {
    return this._activeColor;
  }

  /**
   * @param value {string}
   */
  set activeColor(value) {
    this._activeColor = value;

    if (this._cursor) {
      this._cursor.color = this._activeColor;
    }

    this.raiseActiveStateChanged();
  }

  /**
   * Called when the active text, border, or background color is updated and when the element is focused to update
   * the respective states in the toolbar.
   */
  raiseActiveStateChanged() {
    this._activeStateChanged.raise({
      newColor: this._activeColor,
      newBackgroundColor: this._backgroundColor,
      newBorderColor: this._borderColor,
      newFontFamily: this._fontFamily
    });
  }

  setActiveColor(currentIndex) {
    this.activeColor = this.activeColorForCurrentIndex(currentIndex);
  }

  activeColorForCurrentIndex(currentIndex) {
    // If current index is at 0, there is not color span to the left of the cursor
    let indexLeftOfCursor = currentIndex === 0 ? 0 : currentIndex - 1;
    return this.activeColorForIndex(indexLeftOfCursor);
  }

  /**
   * @param startIndex {number}
   * @returns {string}
   */
  activeColorForIndex(startIndex) {

    if (this.text.length > 0) {
      let activeSpan = this._findActiveColorSpan(startIndex);
      if (activeSpan) {
        return activeSpan.color;
      }
    }
    return this._activeColor;
  }

  /**
   * @param startIndex {number}
   * @returns {ColorSpan}
   */
  _findActiveColorSpan(startIndex) {
    return ColorSpanArranger
      .arrangeColors(this._colorSpans, this._text)
      .find((currentColorSpan, index, colorSpans) => {
        let nextSpan = colorSpans[index + 1];
        return !nextSpan || (currentColorSpan.start <= startIndex && startIndex < nextSpan.start);
      });
  }

  remove() {
    this._stopEvents();
    super.remove();
  }

  _stopEvents() {
    if (this._drag) {
      this._drag.stop();
    }
    if (this._selectedDrag) {
      this._selectedDrag.stop();
    }
    if (this._resizers) {
      this._resizers.topLeft.remove();
      this._resizers.bottomLeft.remove();
      this._resizers.bottomRight.remove();
    }
    if (this._removeHandle) {
      this._removeHandle.remove();
    }
  }

  /**
   * @returns {Point}
   */
  get offset() {
    return new Point(this.offsetX, this.offsetY);
  }

  /**
   * @returns {number}
   */
  get offsetX() {
    return this.location.x + this._padding.width;
  }

  /**
   * @returns {number}
   */
  get offsetY() {
    return this.location.y + this._padding.height;
  }

  /**
   * @returns {Boolean}
   */
  get hovering() {
    return this._drag.hovering || (this._removeHandle && this._removeHandle.hovering);
  }

  /**
   * @return {boolean}
   */
  get dragging() {
    return this._drag && this._drag.dragging;
  }

  /**
   * Renders the textbox as a SVG element
   *
   * @param root {Snap} The snap context
   * @param editable {boolean}
   */
  createElement(root, editable) {
    this._previousStartIndex = undefined;
    this._previousEndIndex = undefined;

    this._arranger.invalidateLayout();

    // Create background layer
    this._background = root.group().addClass('background');
    this._backgroundRect = this._background.rect(0, 0, 0, 0);
    this._editBox = this._background.rect(0, 0, 0, 0);

    // Create content layer
    this._content = root.group().addClass('content');
    this._textbox = this._content.text(0, 0, '').addClass('textbox');
    this._textbox.node.setAttributeNS('http://www.w3.org/XML/1998/namespace', 'xml:space', 'preserve');

    if (editable) {
      this._selection.render(this._content);
      this._cursor.render(this._content);
    }

    // Create interactive layer
    this._interactive = root.group().addClass('interactive');

    if (editable) {
      this._interactRect = this._interactive.rect(0, 0, 0, 0).addClass('touch-foreground');
      this._drag = new GestureManager(this, this.canvas);
      this._drag.start(this._interactRect.node);
      this._drag.click.subscribe(this._click, this);
      this._drag.mouseEnter.subscribe(this.hoverIn, this);
      this._drag.mouseLeave.subscribe(this.hoverOut, this);
      this._drag.dragStart.subscribe(this._dragStart, this);
      this._drag.dragMove.subscribe(this._dragMove, this);
      this._drag.dragEnd.subscribe(this._dragEnd, this);
      this._drag.doubleclick.subscribe(this._doubleclick, this);
      this._drag.tripleclick.subscribe(this._tripleclick, this);

      if (this._enableReposition) {
        this._selectedDragRect = this._interactive.rect(0, 0, 0, 0).addClass('touch-foreground');
        this._selectedDrag = new GestureManager(this, this.canvas);
        this._selectedDrag.start(this._selectedDragRect.node);
        this._selectedDrag.dragStart.subscribe(this._repositionStart, this);
        this._selectedDrag.dragMove.subscribe(this._repositionMove, this);
        this._selectedDrag.dragEnd.subscribe(this._repositionEnd, this);
      }

      if (this._enableResize) {
        this._resizers = {
          topLeft: this.createHandle(this._interactive, 1, 1, -1, -1),
          bottomLeft: this.createHandle(this._interactive, 1, 0, -1, 1),
          bottomRight: this.createHandle(this._interactive, 0, 0, 1, 1)
        };
      }

      if (this._enableRemove) {
        this._removeHandle = new RemoveHandle(this);
        this._removeHandle.render(this._interactive);
        this._removeHandle.mouseEnter.subscribe(this.hoverIn, this);
        this._removeHandle.mouseLeave.subscribe(this.hoverOut, this);
      }
    }

    this._textToSpeechHandle = new TextToSpeechHandle(this);
    this._textToSpeechHandle.render(this._interactive);
  }

  activateTextToSpeech() {
    if (this._textToSpeech.speaking() && this._textToSpeech.current === this.id) {
      return this._textToSpeech.cancel();
    }
    else {
      this._textToSpeech.cancel();
      this._textToSpeech.current = this.id;
      this._textToSpeech.speak(this._text);
    }
  }

  createHandle(layer, scaleX, scaleY, scaleWidth, scaleHeight) {
    let result = new ResizeHandle(this, scaleX, scaleY, scaleWidth, scaleHeight);
    result.resizeStarted.subscribe(this._resizeStarted, this);
    result.resizeComplete.subscribe(this._resizeComplete, this);
    result.size = new Size(14, 14);
    result.render(layer);

    return result;
  }

  /**
   * Updates rendered elements
   * @param root {Snap} The snap context
   * @param editable {boolean}
   */
  update(root, editable) {

    // Start update background layer
    this._backgroundRect.attr({
      x: this.location.x,
      y: this.location.y,
      width: this.width,
      height: this.height,
      fill: this._backgroundColor === TextboxBase.DEFAULT_BACKGROUND_COLOR ? 'transparent' : this._backgroundColor,
      stroke: this._borderColor === TextboxBase.DEFAULT_BORDER_COLOR ? 'transparent' : this._borderColor,
      strokeWidth: 2,
      strokeDasharray: this._borderStyle === BorderStyle.DASHED ? BorderStyle.DEFAULT_DASH_SPACING : 'inherit'
    });

    if (editable) {
      this._editBox.attr({
        x: this.location.x,
        y: this.location.y,
        width: this.width,
        height: this.height,
        fill: this.editBoxFill,
        stroke: this._editBoxBorderColor,
        strokeWidth: 2,
        visibility: ((this.hasFocus && this._format !== FitbAnswerTypes.SCIENTIFIC.value) || this.hovering || this.dragging) ? 'inherit' : 'hidden'
      });
    }

    // Update content layer
    this._textbox.attr({
      dominantBaseline: 'alphabetical',
      fontFamily: this._fontFamily,
      fontSize: `${this._fontSize}px`
    });

    let showPlaceholder = editable && !this.hasText && !this.hasFocus;
    let localText = showPlaceholder ? this._placeholderText : this.text;
    this._arranger.renderText(
      this.offset,
      this._textbox,
      localText,
      (this.width - 2 * this._padding.width),
      this.colorSpans,
      this._fontSize
    );

    this.resizeTextboxHeight();

    if (editable) {

      if (this.hasFocus) {
        this._cursor.show();
      }
      else {
        this._cursor.hide();
      }

      this._selection.visibility = this.hasFocus ? 'inherit' : 'hidden';

      if (this._selection.info && this._selection.visibility === 'inherit') {

        let start = this._selection.info.start;
        let end = this._selection.info.end;

        if (this.isCursorStarting) {
          start = this._selection.info.end;
          end = this._selection.info.start;
        }

        this._selection.info = this._arranger.selectionForIndices(start.index, start.before, end.index, end.before);
        this._cursor.info = this._selection.info.cursor;
      }
    }

    // Update interactive layer
    if (editable) {
      this._interactRect.attr({
        x: this.location.x,
        y: this.location.y,
        width: this.width,
        height: this.height,
        fill: 'transparent',
        cursor: !editable || !this._enableReposition ? 'inherit' : this.hasFocus ? 'text' : 'move',
        visibility: this._format === FitbAnswerTypes.SCIENTIFIC.value ? 'hidden' : 'inherit'
      });

      if (this._enableReposition) {
        this._selectedDragRect.attr({
          x: this.location.x - 5,
          y: this.location.y - 5,
          height: this.height + 10,
          width: this.width + 10,
          cursor: 'move',
          fill: 'none',
          stroke: 'transparent',
          strokeWidth: (editable && this.hasFocus) ? '15px' : '0px'
        });
      }

      if (this._resizers) {

        if (this.hasFocus) {
          this._resizers.topLeft.location = this.location;
          this._resizers.bottomLeft.location = this.location.plus(new Point(0, this.height));
          this._resizers.bottomRight.location = this.location.plus(new Point(this.width, this.height));
        }

        this._resizers.topLeft.visibility = this.hasFocus ? 'inherit' : 'hidden';
        this._resizers.bottomLeft.visibility = this.hasFocus ? 'inherit' : 'hidden';
        this._resizers.bottomRight.visibility = this.hasFocus ? 'inherit' : 'hidden';
        this._resizers.topLeft.tryUpdate();
        this._resizers.bottomLeft.tryUpdate();
        this._resizers.bottomRight.tryUpdate();
      }

      if (this._removeHandle) {
        this._removeHandle.location = this.location.plus(new Point(this.width, 0));
        this._removeHandle.visibility = (this.hasFocus || this.hovering) ? 'visible' : 'hidden';
      }
    }

    //hides the actual text box component
    this._backgroundRect.attr({
      visibility: this._format === FitbAnswerTypes.SCIENTIFIC.value ? 'hidden': 'inherit'
    });
    this._textbox.attr({
      visibility: this._format === FitbAnswerTypes.SCIENTIFIC.value ? 'hidden': 'inherit'
    });

    this._textToSpeechHandle.location = this.location.plus(new Point(-17, this.height / 2));
    this._textToSpeechHandle.visibility = this._textToSpeech.isAvailable && this._textToSpeech._isSupported && this.text.length ? 'visible' : 'hidden';

  }

  /**
   * @return {string}
   */
  get editBoxFill() {
    let editBoxOpacity = 0;
    if (this.backgroundColor !== TextboxBase.DEFAULT_BACKGROUND_COLOR) {
      editBoxOpacity = 0;
    }
    else if (this.hasFocus) {
      editBoxOpacity = .9;
    } else if (this.dragging) {
      editBoxOpacity = .25;
    } else if (this.hovering) {
      editBoxOpacity = .5;
    }
    return `rgba(255, 255, 255, ${editBoxOpacity})`;
  }

  setNewTextboxAttributes() {
    if (this._resizers) {
      this._resizers.topLeft.visibility = this.hasFocus ? 'inherit' : 'hidden';
      this._resizers.bottomLeft.visibility = this.hasFocus ? 'inherit' : 'hidden';
      this._resizers.bottomRight.visibility = this.hasFocus ? 'inherit' : 'hidden';
    }
  }

  warnBeforeDeletion() {
    this._editBox.attr({
      fill: 'rgba(255, 255, 255, .9)',
      stroke: HexColors.CK_WARN,
      strokeWidth: 2
    });

    if (this._resizers) {
      this._resizers.topLeft.warnBeforeDeletion();
      this._resizers.bottomLeft.warnBeforeDeletion();
      this._resizers.bottomRight.warnBeforeDeletion();
    }
  }

  resizeTextboxHeight() {
    this.size = new Size(this.width, this.newHeight || this.height);
  }

  get newHeight() {
    return this._arranger._cachedLineHeight * this._arranger.lineData.length + this._padding.height * 2;
  }

  /**
   * @returns {boolean}
   */
  get hasText() {
    return angular.isDefined(this.text) && this.text.trim().length > 0;
  }

  arrowPressed(arrowCode) {
    switch (arrowCode) {
      case 38:
        this._upArrowPressed();
        break;
      case 39:
        this._rightArrowPressed();
        break;
      case 40:
        this._downArrowPressed();
        break;
      case 37:
        this._leftArrowPressed();
        break;
    }
  }

  _upArrowPressed() {
    let newCursor = this._cursor.info.location.minus(new Point(0, this._cursor.info.height - 1));
    this._selection.info = this._arranger.selectionForPoints(newCursor, newCursor);
    this._cursor.info = this._selection.info.cursor;
  }

  _leftArrowPressed() {
    let newIndex = Math.max(0, this._selection.info.endIndex - 1);
    this._selection.info = this._arranger.selectionForIndices(newIndex, true, newIndex, true);
    this._cursor.info = this._selection.info.cursor;
  }

  _downArrowPressed() {
    let newCursor = this._cursor.info.location.plus(new Point(0, this._cursor.info.height + 1));
    this._selection.info = this._arranger.selectionForPoints(newCursor, newCursor);
    this._cursor.info = this._selection.info.cursor;
  }

  _rightArrowPressed() {
    let newIndex = Math.min(this.text.length, this._selection.info.endIndex + 1);
    this._selection.info = this._arranger.selectionForIndices(newIndex, true, newIndex, true);
    this._cursor.info = this._selection.info.cursor;
  }

  shiftArrowPressed(arrowCode) {
    switch (arrowCode) {
      case 38:
        this._shiftUpArrowPressed();
        break;
      case 39:
        this._shiftRightArrowPressed();
        break;
      case 40:
        this._shiftDownArrowPressed();
        break;
      case 37:
        this._shiftLeftArrowPressed();
        break;
    }
  }

  _shiftUpArrowPressed() {
    let newStartCursorPoint = this._selection.info.cursor.location.minus(new Point(0, this._cursor.info.height - 1));
    let newEndCursor = this.isCursorStarting ? this._selection.info.end : this._selection.info.start;
    let newStartCursor = this._arranger.cursorForPoint(newStartCursorPoint);

    if (this.aboveBounds(newStartCursorPoint)) {
      newStartCursor = this._arranger.cursorForIndex(0, true);
    }

    this._selection.info = this._arranger.selectionForCursors(
      newEndCursor,
      newStartCursor
    );
  }

  _shiftRightArrowPressed() {
    let newIndex = Math.min(this.text.length, this._selection.info.cursor.index + 1);
    let newEndCursor = this.isCursorStarting ? this._selection.info.end : this._selection.info.start;
    this._selection.info = this._arranger.selectionForIndices(newEndCursor.index, newEndCursor.before, newIndex, true);
  }

  _shiftDownArrowPressed() {
    let newStartCursorPoint = this._selection.info.cursor.location.plus(new Point(0, this._selection.info.cursor.height + 1));
    let newStartCursor = this._arranger.cursorForPoint(newStartCursorPoint);
    let newEndCursor = this.isCursorStarting ? this._selection.info.end : this._selection.info.start;
    this._selection.info = this._arranger.selectionForCursors(newEndCursor, newStartCursor);
  }

  _shiftLeftArrowPressed() {
    let newIndex = Math.max(0, this._selection.info.cursor.index - 1);
    let newEndCursor = this.isCursorStarting ? this._selection.info.end : this._selection.info.start;
    this._selection.info = this._arranger.selectionForIndices(newEndCursor.index, newEndCursor.before, newIndex, true);
  }

  aboveBounds(point) {
    return point.y + this.location.y + this._padding.width < this.location.y;
  }

  hoverIn() {
    this.tryUpdate();
  }

  hoverOut() {
    this.tryUpdate();
  }

  /**
   * Click handler
   * @param data {object} mouse data
   */
  _click(data) {
    let location = data.relativeLocation.minus(new Point(this._padding.width, this._padding.height));
    this._selection.info = this._arranger.selectionForPoints(location, location);
    this.focus();
    this._selectionChanged.raise();
  }

  _doubleclick() {
    if (this.hasFocus) {
      this._selection.info = this._arranger.selectionForWordAtCursor(this._selection.info.cursor);
      this._selectionChanged.raise();
      this.tryUpdate();
    }
  }

  _tripleclick() {
    if (this.hasFocus) {
      this._selection.info = this._arranger.selectionForIndices(0, true, this.text.length - 1, false);
      this._selectionChanged.raise();
      this.tryUpdate();
    }
  }

  _repositionStart() {
    this._dragStartState = this.snapshot();
  }

  _repositionMove(data) {
    this.location = data.controlStart.plus(data.delta);
  }

  _repositionEnd() {
    this._onChanged(this._dragStartState);
  }

  _dragStart(data) {
    if (this._enableReposition) {
      this._repositionStart(data);
    }

    if (this.hasFocus) {
      this._textSelectionStart = data.relativeLocation.minus(new Point(this._padding.width, this._padding.height));
    }
  }

  _dragMove(data) {
    if (this.hasFocus) {
      this._textSelectionEnd = new Point(
        Math.min(Math.max(this._textSelectionStart.x + data.delta.x, 0), this.width),
        Math.min(Math.max(this._textSelectionStart.y + data.delta.y, 0), this.height)
      );
      this._selection.info = this._arranger.selectionForPoints(this._textSelectionStart, this._textSelectionEnd);
      this._cursor.info = this._selection.info.cursor;
    }
    else if (this._enableReposition) {
      this._repositionMove(data);
    }
  }

  _dragEnd() {
    if (this.hasFocus) {
      this._selectionChanged.raise();
    }
    else if (this._enableReposition) {
      this._repositionEnd();
    }
  }

  /**
   * @returns {JSEvent}
   */
  get selectionChanged() {
    return this._selectionChanged;
  }

  /**
   * @returns {JSEvent}
   */
  get activeStateChanged() {
    return this._activeStateChanged;
  }

  _resizeStarted() {
    this._dragStartState = this.snapshot();
  }

  _resizeComplete() {
    this._onChanged(this._dragStartState);
  }

  focus() {
    if (!this._selection.info) {
      this._selection.info = this._arranger.selectionForIndices(this.text.length, true, this.text.length, true);
    }
    super.focus();
  }

  /**
   * @return {Point}
   */
  get cursorLocation() {
    return this.canvas.scale(this.cursor.info.location.plus(this.location));
  }

 }
