'use strict';

import moment from 'moment';

export default class OrderTracking {

  /**
   * @param id {string}
   * @param userId {string}
   * @param orderId {string}
   * @param created {string}
   * @param trackingId {string}
   * @param source {string}
   */
  constructor(
    id,
    userId,
    orderId,
    created,
    trackingId,
    source,
    ) {
    this._id = id;
    this._userId = userId;
    this._orderId = orderId;
    this._created = moment(created);
    this._trackingId = trackingId;
    this._source = source;
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {string}
   */
  get userId() {
    return this._userId;
  }

  /**
   * @returns {string}
   */
  get orderId() {
    return this._orderId;
  }

  /**
   * @returns {moment.Moment}
   */
  get created() {
    return this._created;
  }

  /**
   * @returns {string}
   */
  get trackingId() {
    return this._trackingId;
  }

  /**
   * @returns {string}
   */
  get source() {
    return this._source;
  }
}
