
'use strict';

import Codec from './codec';
import { CleverInfo } from '../domain/clever-info';

/**
 * Decodes data our web services gathers about a user in Clever
 */
export default class CleverInfoCodec extends Codec {

  encode(value) {}

  /**
   * @param value {object}
   * @returns {CleverInfo|null}
   */
  decode(value) {
    if (value) {
      return new CleverInfo(
        value.access_token,
        value.email,
        value.clever_username,
        value.first_name,
        value.last_name,
        value.user_type,
        value.exists
      );
    }

    return null;
  }

}
