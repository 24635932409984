'use strict';

import Codec from './codec';
import CkRedirect from '../domain/ck-redirect';

export default class CkRedirectCodec extends Codec {

  constructor() {
    super();
  }

  /**
   * @param value {CkRedirect}
   * @returns {{to: String, params: Object}}
   */
  encode(value) {
    return {
      to: value.to,
      params: value.params,
      url: value.url
    };
  }

  /**
   * @param value {object}
   * @returns {CkRedirect}
   */
  decode(value) {
    if (value) {
      return new CkRedirect(
        value.to,
        value.params,
        value.url
      );
    }
    return null;
  }
}
