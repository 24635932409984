'use strict';

import SelectAdminEmailsTemplate from './select-admin-emails.component.html';
import Validation from '../../model/util/validation';

const AVAILABLE_EMAIL_SLOTS = 10;
const MAXIMUM_EMAIL_CHECKBOXES = 2;

class SelectAdminEmailsController {

  constructor() {
    /** @type {string} */
    this.to = undefined;
    /** @type {MdrOrganizationAdminCodec} */
    this.mdrAdmins = undefined;
    /** @type {Contract} **/
    this.checkContract = undefined;

    this.errors = {};
    this.additionalTo = '';
    this.invalidEmails = false;
    this.emailRegex = Validation.EmailRegex;
    this._selectableAdminEmails = [];
    this._selectableParentAdminEmails = [];
    this._required = false;
    this.availableEmailSlots = AVAILABLE_EMAIL_SLOTS;
  }

  /**
   * Called after the bindings and component have been initialized
   * https://docs.angularjs.org/guide/component
   *
   * Sets up the selectable emails used for the md-checkboxes in component
   */
  $onInit() {

    if (this.checkContract && this.checkContract.isProClassroom) {
      // Not a pro school or district
      return;
    }

    let selectedEmails = [];
    if (this.checkContract && this.checkContract.isProDistrict) {
      if (this.mdrAdmins && this.mdrAdmins.parent_admins) {
        this.mdrAdmins.parent_admins.forEach((admin) => {
          if (this._selectableParentAdminEmails.length < MAXIMUM_EMAIL_CHECKBOXES) {
            this._selectableParentAdminEmails.unshift({selected: true, district: true, user: admin});
            selectedEmails.push(admin.email);
          }
        });
      }
    }

    if (this.mdrAdmins && this.mdrAdmins.admins) {
      this.mdrAdmins.admins.forEach((admin) => {
        if (this._selectableParentAdminEmails.length + this._selectableAdminEmails.length < MAXIMUM_EMAIL_CHECKBOXES) {
          this._selectableAdminEmails.unshift({selected: true, district: false, user: admin});
          selectedEmails.push(admin.email);
        }
      });
    }

    this.to = selectedEmails.join(',');
  }

  setEmailListValidity() {
    let selectedEmails = [];
    this._selectableParentAdminEmails.forEach((result) => {
      if (result.selected) {
        selectedEmails.push(result.user.email);
      }
    });
    this._selectableAdminEmails.forEach((result) => {
      if (result.selected) {
        selectedEmails.push(result.user.email);
      }
    });
    const additionalEmails = this.additionalTo || '';
    const emailList = selectedEmails.concat(additionalEmails.split(/[,;]/));
    const emailsValid = (selectedEmails.length > 0 && additionalEmails.length === 0) || this.isEmailListValid(emailList);

    this.errors = {
      'pattern': !emailsValid,
      'maximum': emailList.length > AVAILABLE_EMAIL_SLOTS
    };
    this.invalidEmails = this.errors.pattern || this.errors.maximum;

    this.to = (this.invalidEmails) ? '' : emailList.join(',');
  }

  isEmailListValid(emails) {
    if (emails.length > AVAILABLE_EMAIL_SLOTS) {
      return false;
    }

    let isValid;

    for (let email of emails) {
      email = email.trim();
      isValid = this.emailRegex.test(email);
      if (!isValid) {
        return false;
      }
    }

    return true;
  }

  displayContactInformation(item) {
    if (item.user.firstName) {
      return `${item.user.firstName} ${item.user.lastName}, ${item.user.prefTitle}`;
    } else {
      return `${item.user.email}`;
    }
  }
}

/**
 *
 * More information about the bindings:
 * https://docs.angularjs.org/api/ng/service/$compile#-scope-
 * https://docs.angularjs.org/guide/component
 */
export default {
  bindings: {
    to: '=', // {string} two-way binding
    checkContract: '<', // {Contract} to check if it needs to display district and/or school admins
    mdrAdmins: '<' // {MdrOrganizationAdminCodec} decoded not editable, must come from the page controller
  },
  template: SelectAdminEmailsTemplate,
  controller: SelectAdminEmailsController,
  controllerAs: 'ctrl'
};
