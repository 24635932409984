import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ImportGoogleRostersDialogController
  from '../../components/import-google-rosters-dialog/import-google-rosters-dialog.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import ErrorCodes from '../../model/domain/error-codes';

/**
 * Imports Google Courses as Classkick Rosters
 */
export class GoogleClassroom {

  constructor($log, $q, $mdDialog, $mdToast, AuthService, GoogleClassroomService) {
    this.$log = $log;
    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {GoogleClassroomService} */
    this._googleClassroomService = GoogleClassroomService;

    this._confirmDialog = ConfirmDialogController.show;
    this._loadingDialog = LoadingDialogController.show;
    this._importGoogleRostersDialog = ImportGoogleRostersDialogController.show;
    this._errorDialog = ErrorDialogController.show;
  }

  import() {
    return this.getCourses()
      .then((courses) => {
        return this.selectCourses(courses);
      })
      .then((courses) => {
        let promise = this.importCourses(courses);
        this._loadingDialog(this.$mdDialog, promise);
        return promise;
      })
      .then(() => {
        this._showToast('Imported courses from Google Classroom');
      })
      .catch(() => {
        this._showError('importing your students from Google Classroom');
      });
  }

  getCourses() {
    return this._checkAuth()
      .then(() => {
        return this._googleClassroomService.getCourses();
      });
  }

  selectCourses(courses) {
    return this._importGoogleRostersDialog(this.$mdDialog, courses);
  }

  importCourses(courses) {
    return courses ? this._googleClassroomService.importGoogleCourses(courses) : this.$q.resolve();
  }

  sync(roster) {
    return this._checkAuth()
      .then(() => {
        let promise = this._googleClassroomService.syncGoogleRoster(roster);
        this._loadingDialog(this.$mdDialog, promise);
        return promise;
      })
      .then(() => {
        this._showToast(`Synced ${roster.name}`);
      })
      .catch((error) => {
        if (error.code === ErrorCodes.ROSTER_LOCKED) {
          this._errorDialog(
            this.$mdDialog,
            `Whoops! Your ${roster.name} roster is locked `,
            'Please unlock your roster and try again'
          );
        }
        else {
          this.$log.error('google classroom api sync error:', error);
          this._showError('syncing your students from Google Classroom');
        }
      });
  }

  _checkAuth() {
    if (!this._googleClassroomService.hasCourseAndStudentPermissions) {
      let promise = this._googleClassroomService.grantCourseAndStudentPermissions();
      this._loadingDialog(this.$mdDialog, promise);
      return promise;
    }
    else {
      return this.$q.resolve();
    }
  }

  get isGoogleAuthed() {
    return this._authService.authData.isGoogleAuthed;
  }

  _showError(action) {
    this._errorDialog(
      this.$mdDialog,
      `Whoops! There was an error ${action}`,
      'Please try again and if the issue persists send us a message at support@classkick.com'
    );
  }

  showNoGoogleAuthError() {
    this._confirmDialog(
      this.$mdDialog,
      'Uh oh! It doesn\'t look like you are logged in with a Google Account',
      'To use this feature, make sure you click the "Log in with Google" button when you log in',
      true
    );
  }

  /**
   * @param message {string}
   */
  _showToast(message) {
    this.$mdToast.show(
      this.$mdToast.simple()
        .textContent(message)
        .position('bottom right')
    );
  }

}
