
'use strict';

import {
  HelpArticleCategories,
  HelpArticleCategoriesDisplayMap,
  HelpArticleSections
} from '../../model/domain/help-article';
import { TeacherHelpCenterViews } from '../../services/mixpanel/mixpanel.service';
import Sorts from '../../model/domain/sorts';

export default class HelpCategoryController {

  /**
   * @ngInject
   */
  constructor($state, $stateParams, StaticService, CacheService, AuthService, BreadcrumbService, AnalyticsService) {

    this.$state = $state;
    this.$stateParams = $stateParams;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._displayingInIpadApp = StaticService.isClasskickIPadApp;

    this._categoryId = this.$stateParams.categoryId;
    this._articles = [];

    this.init();
  }

  init() {
    if (!this._categoryId || !HelpArticleCategories.isValid(this._categoryId)) {
      this.$state.go('root.help');
    }

    this._cacheService.getArticles(false)
      .then((articles) => {
        this._articles = articles.sort((a, b) => Sorts.NUMERIC_UNDEFINED_ASC(a.priority, b.priority));
        this._analyticsService.teacherHelpCenter(TeacherHelpCenterViews.Category, this._categoryId);
      });
  }

  createNewArticle(categoryId, sectionId) {
    if (this._authService.authData && this._authService.authData.isAdmin) {
      this._cacheService.createArticle(categoryId, sectionId)
        .then((article) => {
          this.$state.go('root.help-article', {
            articleId: article.id
          });
        });
    }
  }

  /**
   * @return {string}
   */
  get categoryId() {
    return this._categoryId;
  }

  /**
   * @return {HelpArticle[]}
   */
  get articles() {
    return this._articles;
  }

  filterBySection(sectionId) {
    return (article) => {
      return article.sectionId === sectionId;
    };
  }

  goToSection(sectionId) {
    this.$state.go('root.help-section', {
      sectionId
    });
  }

  goToArticle(articleId) {
    this.$state.go('root.help-article', {
      articleId
    });
  }

  goToHelpCenter() {
    this.$state.go('root.help');
  }

  goToCoaches(sectionId) {
    this.$state.go('root.help-coaches', {
      sectionId
    });
  }

  returnToClasskick() {
    this.$state.go('root.account.home');
  }

  get categoryDisplay() {
    return this._categoryId && HelpArticleCategoriesDisplayMap[this._categoryId];
  }

  get WhatIsClasskick() {
    return HelpArticleCategories.WhatIsClasskick;
  }

  get WhatIsClasskick_Default() {
    return HelpArticleSections.WhatIsClasskick_Default;
  }

  get WhatIsClasskick_Secondary() {
    return HelpArticleSections.WhatIsClasskick_Secondary;
  }

  get GettingStarted() {
    return HelpArticleCategories.GettingStarted;
  }

  get GettingStarted_Edit() {
    return HelpArticleSections.GettingStarted_Edit;
  }

  get GettingStarted_Assign() {
    return HelpArticleSections.GettingStarted_Assign;
  }

  get GettingStarted_View() {
    return HelpArticleSections.GettingStarted_View;
  }

  get BeyondTheBasics() {
    return HelpArticleCategories.BeyondTheBasics;
  }

  get BeyondTheBasics_DeeperFeedback() {
    return HelpArticleSections.BeyondTheBasics_DeeperFeedback;
  }

  get BeyondTheBasics_MeaningfulAssignments() {
    return HelpArticleSections.BeyondTheBasics_MeaningfulAssignments;
  }

  get BeyondTheBasics_AnalyzeData() {
    return HelpArticleSections.BeyondTheBasics_AnalyzeData;
  }

  get BeyondTheBasics_Collaboration() {
    return HelpArticleSections.BeyondTheBasics_Collaboration;
  }

  get BeyondTheBasics_Grading() {
    return HelpArticleSections.BeyondTheBasics_Grading;
  }

  get Troubleshooting() {
    return HelpArticleCategories.Troubleshooting;
  }

  get Troubleshooting_Default() {
    return HelpArticleSections.Troubleshooting_Default;
  }

  get Troubleshooting_Secondary() {
    return HelpArticleSections.Troubleshooting_Secondary;
  }

  get ConnectWithTeacher() {
    return HelpArticleCategories.ConnectWithTeacher;
  }

  get ConnectWithTeacher_K_2() {
    return HelpArticleSections.ConnectWithTeacher_K_2;
  }

  get ConnectWithTeacher_3_5() {
    return HelpArticleSections.ConnectWithTeacher_3_5;
  }

  get ConnectWithTeacher_6_8() {
    return HelpArticleSections.ConnectWithTeacher_6_8;
  }

  get ConnectWithTeacher_9_12() {
    return HelpArticleSections.ConnectWithTeacher_9_12;
  }

  get ProfessionalDevelopment() {
    return HelpArticleCategories.ProfessionalDevelopment;
  }

  get ProfessionalDevelopment_PresentationMaterials() {
    return HelpArticleSections.ProfessionalDevelopment_PresentationMaterials;
  }

  get ProfessionalDevelopment_BrandAssets() {
    return HelpArticleSections.ProfessionalDevelopment_BrandAssets;
  }

  get LearnAboutFeatures() {
    return HelpArticleCategories.LearnAboutFeatures;
  }

  get LearnAboutFeatures_Toolbar() {
    return HelpArticleSections.LearnAboutFeatures_Toolbar;
  }

  get LearnAboutFeatures_GradingAndFeedback() {
    return HelpArticleSections.LearnAboutFeatures_GradingAndFeedback;
  }

  get LearnAboutFeatures_Filters() {
    return HelpArticleSections.LearnAboutFeatures_Filters;
  }

  get LearnAboutFeatures_Export() {
    return HelpArticleSections.LearnAboutFeatures_Export;
  }

  get LearnAboutFeatures_More() {
    return HelpArticleSections.LearnAboutFeatures_More;
  }

  get ClasskickProUsers() {
    return HelpArticleCategories.ClasskickProUsers;
  }

  get ClasskickProUsers_WhatIsPro() {
    return HelpArticleSections.ClasskickProUsers_WhatIsPro;
  }

  get ClasskickProUsers_Admins() {
    return HelpArticleSections.ClasskickProUsers_Admins;
  }

  get ClasskickProUsers_Teachers() {
    return HelpArticleSections.ClasskickProUsers_Teachers;
  }

  get ClasskickProUsers_Students() {
    return HelpArticleSections.ClasskickProUsers_Students;
  }

  get hideOutsideLinks() {
    return this._displayingInIpadApp;
  }

}
