export default class GradeRange {

    /**
     * @param name {string} the name of the grade range to display to the user (e.g. 3rd-5th)
     * @param range {string[]} the grades within this range used to check if grade is within
     */
    constructor(name, range) {
      this.name = name;
      this.range = range;
    }
  
    /**
     * @param grade {string}
     * @return {boolean}
     */
    within(grade) {
      return this.range.some((g) => g === grade);
    }
  }