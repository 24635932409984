'use strict';
import Codec from './codec';
import GradebookStudentsIntegrationStatus from '../domain/gradebook-students-integration-status';
import GradebookStudentCodec from './gradebook-student-codec';
import RosterStudentCodec from './roster-student-codec';

/**
 * Encodes/decodes Gradebook integration status information for students in a roster
 */
export default class GradebookStudentsIntegrationStatusCodec extends Codec {
  constructor() {
    super();

    this._gradebookStudentCodec = new GradebookStudentCodec();
    this._rosterStudentsCodec = new RosterStudentCodec();
  }

  /**
   * @param value {GradebookStudentsIntegrationStatus}
   * @returns {object}
   */
  encode(value) {
    if (value) {
      return {
        roster_students: value.rosterStudents,
        lms_students: value.lmsStudents,

      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {GradebookStudentsIntegrationStatus}
   */
  decode(value) {
    const rosterStudents = value.roster_students.map((student) => this._rosterStudentsCodec.decode(student));
    const lmsStudents = value.lms_students.map((student) => this._gradebookStudentCodec.decode(student));
    if (value) {
      return new GradebookStudentsIntegrationStatus(
        rosterStudents,
        lmsStudents
      );
    }
    return null;
  }

}
