'use strict';

import ErrorDialogController from '../error-dialog/error-dialog.controller';
import {ShareWorkflowSteps} from '../../services/mixpanel/mixpanel.service';
import Validation from '../../model/util/validation';
import { AssignmentAccess } from '../../model/domain/assignment';
import ShareDialogTemplate from './share-dialog.html';
import { Locations } from '../../services/mixpanel/mixpanel.service';

export class ShareDialogStates {

  static get TEACHER_OR_STUDENT() {
    return 'teacher-or-student';
  }

  static get TEACHER() {
    return 'teacher';
  }

  static get EMAIL_SUCCESS_ASSIGNMENT() {
    return 'email-success-assignment';
  }

  static get EMAIL_SUCCESS_ASSIGNMENT_WORK() {
    return 'email-success-assignment-work';
  }

  static get LOADING() {
    return 'loading';
  }

  static get SHARE_STUDENT_WORK() {
    return 'share-student-work';
  }

}

export default class ShareDialogController {

  constructor($mdDialog, $mdToast, $location, $window, clipboard, AssignmentService, AuthService, $state,
              assignment, location, studentName, assignmentWorkId, AnalyticsService, BreadcrumbService,
              CacheService, SharedWorksService) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$location = $location;
    this.$window = $window;
    this._clipboard = clipboard;

    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {SharedWorksService} */
    this._sharedWorksService = SharedWorksService;

    this._errorDialog = ErrorDialogController.show;

    this.$state = $state;
    this.clipboardIsSupported = clipboard.supported;
    this.assignment = assignment;
    this.shareWithTeacher = true;
    this._location = location;

    this.studentName = studentName;
    this.assignmentWorkId = assignmentWorkId;

    this.emailRegex = Validation.EmailRegex;

    this.emails = '';
    this.error = false;
    this.state = ShareDialogStates.TEACHER;

    if (this._authService.isLoggedIn) {
      if (this._location === Locations.ASSIGNMENT_WORK_QUESTION) {
        this.state = ShareDialogStates.SHARE_STUDENT_WORK;
      } else {
        this.state = ShareDialogStates.TEACHER_OR_STUDENT;
      }
    }

    let host = $location.host();
    let port = '';
    // If we're not on the default http or https ports, we'll include the port in the link
    if (!($location.port() === 80 || $location.port() === 443)) {
      port = `:${$location.port()}`;
    }

    let path = `/public/assignments/${this.assignment.id}`;
    if (this.assignment.isFolder) {
      path = `/public/folder/${this.assignment.id}`;
    }
    this._link = `${host}${port}${path}`;

    if (this._location === Locations.ASSIGNMENT_WORK_QUESTION){
      this._analyticsService.shareWorkDialogOpened(Locations.ASSIGNMENT_WORK_QUESTION);
    } else {
      this._analyticsService.shareAssignmentWorkflow(this.assignment.id, this.assignment.isFolder, this._location, ShareWorkflowSteps.STARTED);
    }
  }

  /**
   * show the dialog
   * @param  {$mdDialog}  $mdDialog
   * @param  {assignment} assignment
   * @param  {string} location
   * @return {Promise}
   */
  static show($mdDialog, assignment, location, studentName = null, assignmentWorkId = null) {

    let config = {
      controller: ShareDialogController,
      template: ShareDialogTemplate,
      controllerAs: 'ctrl',
      autoWrap: false,
      clickOutsideToClose: true,
      focusOnOpen: false,
      locals: {
        assignment,
        location,
        studentName,
        assignmentWorkId,
      }
    };

    return $mdDialog.show(config);
  }

  get currentUserIsAssignmentOwner() {
    return this._authService.currentUserId === this.assignment.ownerId;
  }

  /**
   * close the dialog. rejects the promise returned from the show method
   */
  cancel() {
    this.$mdDialog.cancel();
  }

  /**
   * transition to the Share with Colleagues views. Also sets the assignment
   * to public.
   */
  chooseTeacher() {
    if (this.assignment.access !== 'public') {
      this.setAssignmentToPublic()
      .then(() => {
        this.state = ShareDialogStates.TEACHER;
      })
      .catch(() => {
        this.cancel();
        this._errorDialog(this.$mdDialog, 'Uh oh! Something went wrong setting your assignment to public', 'Check your connection and try again');
      });
    }
    else {
      this.state = ShareDialogStates.TEACHER;
    }
    this._analyticsService.shareAssignmentWorkflow(this.assignment.id, this.assignment.isFolder, this._location, ShareWorkflowSteps.WITH_COLLEAGUES);
  }

  /**
   * set the assignment ot public.
   * * @return {Promise}
   */
  setAssignmentToPublic() {
    this.state = ShareDialogStates.LOADING;
    this.assignment.access = AssignmentAccess.PUBLIC;

    if (this.assignment.isFolder) {
      return this._assignmentService.makePublic(this.assignment);
    } else {
      return this._assignmentService.update(this.assignment);
    }
  }

  /**
   * choose the Share with Student option. links to the Edit Assignment view,
   * scrolled to the roster list.
   */
  chooseStudent() {
    this.cancel();
    this._breadcrumbService.go('root.account.assignment-rosters', { assignmentId: this.assignment.id }, true);
    this._analyticsService.shareAssignmentWorkflow(this.assignment.id, this.assignment.isFolder, this._location, ShareWorkflowSteps.WITH_STUDENTS);
  }

  /**
   * open public assignment view in a new tab
   */
  preview() {
    this.$window.open(`https://${this.link}`, '_blank');
  }

  /**
   * tests list of emails against the list of emails regex.
   * @return {Boolean} whether the email list is valid or not
   */
  get isEmailListValid() {
    let emails = this.emails.split(/[,;]/);
    if (emails.length > 10) {
      return false;
    }
    let isValid;
    for (let email of emails) {
      email = email.trim();
      isValid = this.emailRegex.test(email);
      if (!isValid) {
        return false;
      }
    }

    return true;
  }

  /**
   * after one last validation, split the emails in the list into an array
   * and hit the web services endpoint to send emails. Shows a toast if that
   * call fails.
   */
  sendEmails() {
    if (this.isEmailListValid) {
      this.state = ShareDialogStates.LOADING;
      this._analyticsService.shareAssignmentWorkflow(this.assignment.id, this.assignment.isFolder, this._location, ShareWorkflowSteps.VIA_EMAIL);
      let emailList = this.emails.split(/[,;]/);
      this._assignmentService.shareAssignment(this.assignment.id, emailList)
        .then(() => {
          this.state = ShareDialogStates.EMAIL_SUCCESS_ASSIGNMENT;
        })
        .catch(() => {
          this._errorDialog(
            this.$mdDialog,
            'Whoops! There was an error sharing this assignment.',
            'Please try again and if you continue to have issues send us an email at support@classkick.com.'
          );
        });
    }
  }

  setEmailValidity(scope) {
    scope.emailShareForm.emails.$setValidity('pattern', this.isEmailListValid);
  }

  setEmailValidityForSharedWork(scope) {
    scope.emailShareWorkForm.emails.$setValidity('pattern', this.isEmailListValid);
  }

  /**
   * selects the text in the input
   */
  select() {
    angular.element('#link-input').select();
  }

  /**
   * copies the link to the users clipboard
   */
  copy() {
    if (this.clipboardIsSupported) {
      this._clipboard.copyText(this.fullLinkUrl);
      this.$mdToast.show(
        this.$mdToast.simple()
          .textContent('Link copied to clipboard')
          .position('bottom right')
      );
    }
    this.select();
    this._analyticsService.shareAssignmentWorkflow(this.assignment.id, this.assignment.isFolder, this._location, ShareWorkflowSteps.VIA_LINK);
  }

  get isTeacherOrStudent() {
    return this.state === ShareDialogStates.TEACHER_OR_STUDENT;
  }

  get isTeacherSharingStudentWork() {
    return this.state === ShareDialogStates.SHARE_STUDENT_WORK;
  }

  get isTeacher() {
    return this.state === ShareDialogStates.TEACHER;
  }

  get isEmailSuccessAssignment() {
    return this.state === ShareDialogStates.EMAIL_SUCCESS_ASSIGNMENT;
  }

  get isEmailSuccessAssignmentWork() {
    return this.state === ShareDialogStates.EMAIL_SUCCESS_ASSIGNMENT_WORK;
  }


  get isLoading() {
    return this.state === ShareDialogStates.LOADING;
  }

  get link() {
    return this._link;
  }

  get fullLinkUrl() {
    return `https://${this.link}`;
  }

  get socialMediaTextPreview() {
    return `I created an assignment called "${this.assignment.name}" with Classkick! Check it out here:`;
  }

  /* TODO: These socials are broken after the upgrade to React. */
  addSocialMediaButtons() {
    angular.element('.share-dialog .social-media').jsSocials({
      url: this.fullLinkUrl,
      showLabel: false,
      showCount: false,
      shares: ['facebook', 'twitter', 'pinterest'],
      text: this.socialMediaTextPreview,
      on: {
        click: this._handleSocialClick.bind(this)
      }
    });
  }

  _handleSocialClick(event) {
    let target = event.target;
    let className = target.className;

    let match = className.match(/(google|pinterest|facebook|twitter)/);
    let socialMediaPlatform = match && match[0];
    let step;

    if (socialMediaPlatform === 'google') {
      step = ShareWorkflowSteps.VIA_GOOGLE_PLUS;
    }
    else if (socialMediaPlatform === 'pinterest') {
      step = ShareWorkflowSteps.VIA_PINTEREST;
    }
    else if (socialMediaPlatform === 'facebook') {
      step = ShareWorkflowSteps.VIA_FACEBOOK;
    }
    else if (socialMediaPlatform === 'twitter') {
      step = ShareWorkflowSteps.VIA_TWITTER;
    }

    if (step) {
      this._analyticsService.shareAssignmentWorkflow(this.assignment.id, this.assignment.isFolder, this._location, step);
    }
  }

  addToSharedAssignmentsPage() {
    this.setAssignmentToPublic()
      .then(() => {
        this.state = ShareDialogStates.TEACHER;
        this._analyticsService.shareAssignmentWorkflow(
          this.assignment.id,
          this.assignment.isFolder,
          this._location,
          ShareWorkflowSteps.PUBLIC_PAGE
        );
        return this._cacheService.getUser();
      })
      .then((user) => {
        this.cancel();
        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent(`${this.assignment.name} has been added to your Shared Assignments Page`)
            .position('bottom right')
        );
        this._breadcrumbService.go('root.account.nav.public-assignments-list', {
          userId: user.email || user.username
        });
      });
  }

  shareAssignmentWork() {
    if (this.isEmailListValid) {
      this.state = ShareDialogStates.LOADING;
      let emailList = this.emails.replace(/\s+/g, '').split(/[,;]/);
      this._sharedWorksService.shareAssignmentWork(this.assignmentWorkId, emailList)
          .then(() => {
            this.state = ShareDialogStates.EMAIL_SUCCESS_ASSIGNMENT_WORK;
          })
          .catch(() => {
            this._errorDialog(
                this.$mdDialog,
                'Whoops! There was an error sharing this student\'s assignment.',
                'Please try again and if you continue to have issues send us an email at support@classkick.com.'
            );
          });
    }
  }
}
