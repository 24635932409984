'use strict';

import Codec from './codec';
import CopyAssignmentRequest from '../domain/copy-assignment-request';

export default class CopyAssignmentRequestCodec extends Codec {

  constructor() {
    super();
  }

  /**
   * @param value {CopyAssignmentRequest}
   * @returns {{id: String, name: String, t: String}}
   */
  encode(value) {
    return {
      id: value.id,
      name: value.name,
      t: value.t && value.t.toISOString()
    };
  }

  /**
   * @param value {object}
   * @returns {CopyAssignmentRequest}
   */
  decode(value) {
    if (value) {
      return new CopyAssignmentRequest(
        value.id,
        value.name,
        value.t
      );
    }
    return null;
  }
}
