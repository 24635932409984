
'use strict';

import Codec from './codec';
import HelpArticle from '../domain/help-article';

/**
 * Encodes/Decodes a help article
 */
export default class HelpArticleCodec extends Codec {

  constructor() {
    super();
  }

  /**
   * @param value {HelpArticle}
   * @return {{id: string, category_id: string, section_id: string, title: string, body: string, priority: number, owner_id: string}}
   */
  encode(value) {
    return {
      id: value.id,
      category_id: value.categoryId,
      section_id: value.sectionId,
      title: value.title,
      body: value.body,
      priority: value.priority,
      owner_id: value.ownerId
    };
  }

  /**
   * @param value {Object}
   * @return {Organization|null}
   */
  decode(value) {
    if (value) {
      return new HelpArticle(
        value.id,
        value.category_id,
        value.section_id,
        value.title,
        value.body,
        value.priority,
        value.owner_id,
        value.created,
        value.modified
      );
    }
    else {
      return null;
    }
  }

}
