'use strict';

import Validation from './validation';

/**
 * Email domains not allowed for use by Classkick
 *
 * @type {string[]}
 */
const INVALID_EMAIL_DOMAINS = [
  'aim.com',
  'alice.it',
  'aliceadsl.fr',
  'aol.com',
  'arcor.de',
  'att.net',
  'bellsouth.net',
  'bigpond.com',
  'bigpond.net.au',
  'bluewin.ch',
  'blueyonder.co.uk',
  'bol.com.br',
  'centurytel.net',
  'charter.net',
  'chello.nl',
  'club-internet.fr',
  'comcast.net',
  'cox.net',
  'earthlink.net',
  'facebook.com',
  'free.fr',
  'freenet.de',
  'frontiernet.net',
  'gmail.com',
  'gmx.de',
  'gmx.net',
  'googlemail.com',
  'hetnet.nl',
  'home.nl',
  'hotmail.co.uk',
  'hotmail.com',
  'hotmail.de',
  'hotmail.es',
  'hotmail.fr',
  'hotmail.it',
  'ig.com.br',
  'juno.com',
  'laposte.net',
  'libero.it',
  'live.ca',
  'live.co.uk',
  'live.com.au',
  'live.com',
  'live.fr',
  'live.it',
  'live.nl',
  'mac.com',
  'mail.com',
  'mail.ru',
  'me.com',
  'msn.com',
  'neuf.fr',
  'ntlworld.com',
  'optonline.net',
  'optusnet.com.au',
  'orange.fr',
  'outlook.com',
  'planet.nl',
  'qq.com',
  'rambler.ru',
  'rediffmail.com',
  'rocketmail.com',
  'sbcglobal.net',
  'sfr.fr',
  'shaw.ca',
  'sky.com',
  'skynet.be',
  'sympatico.ca',
  't-online.de',
  'telenet.be',
  'terra.com.br',
  'tin.it',
  'tiscali.co.uk',
  'tiscali.it',
  'uol.com.br',
  'verizon.net',
  'virgilio.it',
  'voila.fr',
  'wanadoo.fr',
  'web.de',
  'windstream.net',
  'yahoo.ca',
  'yahoo.co.id',
  'yahoo.co.in',
  'yahoo.co.jp',
  'yahoo.co.uk',
  'yahoo.com.ar',
  'yahoo.com.au',
  'yahoo.com.br',
  'yahoo.com.mx',
  'yahoo.com.sg',
  'yahoo.com',
  'yahoo.de',
  'yahoo.es',
  'yahoo.fr',
  'yahoo.in',
  'yahoo.it',
  'yandex.ru',
  'ymail.com',
  'zonnet.nl'
];


export default class ValidationDomainEmailUtils {

  /**
   * Checks if any email in list of emails against email valid format and allowed domain for Classkick.
   *
   * @param emailArr {Array<String>} array of emails
   * @param isSingleElemArray {boolean}
   * @returns {{isValidEmailDomain: boolean, isValidFormat: boolean}}
   */
  static isEmailListValid(emailArr, isSingleElemArray=true) {
    let isValidFormat = true,
        isValidEmailDomain = true;

    if (emailArr.length > 1 && isSingleElemArray) {
      return { isValidFormat: false, isValidEmailDomain: false };
    }

    for (let email of emailArr) {
      email = email.trim();

      isValidFormat = Validation.isValidEmailFormat(email);
      if (!isValidFormat) {
        break;
      }

      isValidEmailDomain = this.isValidEmailDomain(email);
      if (!isValidEmailDomain) {
        break;
      }
    }

    return { isValidFormat: isValidFormat, isValidEmailDomain: isValidEmailDomain };
  }

  /**
   *
   * @param email {String} for domain validation
   * @returns {boolean}
   */
  static isValidEmailDomain(email) {
    if (angular.isUndefined(email)) {
      return false;
    }

    const domain = email.split('@')[1];
    return !INVALID_EMAIL_DOMAINS.includes(domain);
  }

  /**
   * Checks if email domain exact matches an array of email domains
   *
   * @param email {String} for domain validation
   * @param emailArrToVerifyAgainst {Array<String>} array of emails
   * @returns {boolean}
   */
  static isEmailDomainMatchingListOfDomains(email, emailArrToVerifyAgainst) {
    if (angular.isUndefined(email)) {
        return false;
    }
    const domainsToVerifyAgainst = [];
    emailArrToVerifyAgainst.forEach((email_item) => {
      domainsToVerifyAgainst.push(email_item.split('@')[1]);
    });
    const domain = email.split('@')[1];

    return domainsToVerifyAgainst.every((item) => domain === item);
  }
}
