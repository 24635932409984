export default class SaveStates {
  static get SAVED() {
    return 'saved';
  }

  static get UNSAVED() {
    return 'unsaved';
  }

  static get SAVE_ERROR() {
    return 'save-error';
  }

  static get SAVE_ERROR_STUDENT_DUPLICATE(){
    return 'student-duplicate';
  }

  static get ANSWER_SAVED() {
    return 'answer saved';
  }
}
