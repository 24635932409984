
'use strict';

import Control from './control';
import GestureManager from './gesture-manager';
import JSEvent from './../util/js-event';
import Trig from '../util/trig';
import Size from './size';
import HexColors from '../../css-constants';

export default class ResizeImageHandle extends Control {

  constructor(target, positionName) {
    super(`${target.id}-${positionName}-handle`);

    this._target = target;
    this._size = new Size(18, 18);

    this._gestureManager = new GestureManager(undefined, target.canvas);
    this._gestureManager._dragThreshold = 0;

    this._resizeStarted = new JSEvent(this);
    this._resizeComplete = new JSEvent(this);
  }

  createElement(root) {
    this._circle = root.circle(0, 0, 1);
    this._interactive = root.circle(0, 0, 0);
    this._gestureManager.start(this._interactive.node);
    this._gestureManager.dragStart.subscribe(this._dragStartHandler, this);
    this._gestureManager.dragMove.subscribe(this._dragMoveHandler, this);
    this._gestureManager.dragEnd.subscribe(this._dragEndHandler, this);
  }

  update() {
    this._circle.attr({
      fill: HexColors.CK_GREEN,
      cursor: 'pointer',
      visibility: this._visibility,
      cx: this.location.x,
      cy: this.location.y,
      r: 7,
      'pointer-events': 'none'
    });

    this._interactive.attr({
      fill: 'transparent',
      cursor: 'pointer',
      visibility: this._visibility,
      cx: this.location.x,
      cy: this.location.y,
      r: 20
    });
  }

  warnBeforeDeletion() {
    this._circle.attr({
      fill: HexColors.CK_WARN,
      visibility: this._visibility
    });
  }

  remove() {
    this._gestureManager.stop();
    this.resizeStarted.clear();
    this.resizeComplete.clear();
    super.remove();
  }

  _dragStartHandler(data) {
    this._resizeStarted.raise();

    this._startScale = this._target.scale;
    this._startHypotenuse = Math.sqrt(this._target.width * this._target.width + this._target.height * this._target.height);
    this._startTargetLocation = this._target.location;
    this._startResizeLocation = data.relativeLocation;

    this._startSlope = Trig.findSlope(this._startResizeLocation, this._startTargetLocation);
    this._startYIntercept = Trig.findYIntercept(this._startSlope, this._startResizeLocation);
    this._perpendicularSlope = Trig.findPerpendicularSlope(this._startResizeLocation, this._startTargetLocation);
  }

  _dragMoveHandler(data) {
    let currentLocation = this._startResizeLocation.plus(data.delta);
    let closestPoint = Trig.closestPoint(currentLocation, this._startSlope, this._startYIntercept, this._perpendicularSlope);

    let vectorChange = closestPoint.minus(this._startResizeLocation);
    let vectorChangeLength = Math.sqrt(vectorChange.x * vectorChange.x + vectorChange.y * vectorChange.y);

    let percentageChange = vectorChangeLength / this._startHypotenuse;

    // this is what determines which image corner will stay in place while resizing
    if (vectorChange.y < 0) {
      percentageChange = this._startResizeLocation.y < this._startTargetLocation.y ? percentageChange + 1 : 1 - percentageChange;
    }
    else if (vectorChange.y > 0) {
      percentageChange = this._startResizeLocation.y < this._startTargetLocation.y ? 1 - percentageChange : percentageChange + 1;
    }
    else {
      percentageChange = 1;
    }

    if (percentageChange >= 1 || this._target.width > 50) {
      this._target.scale = this._startScale * percentageChange;
      this._target.location = this._startTargetLocation.plus(vectorChange.times(0.5));
    }
  }

  _dragEndHandler() {
    this._resizeComplete.raise();
  }

  get resizeStarted() {
    return this._resizeStarted;
  }

  get resizeComplete() {
    return this._resizeComplete;
  }
}
