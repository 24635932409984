'use strict';

import IncentiveAssignmentLimitV1Dialog from './incentive-assignment-limit-v1-dialog.html';
import {Locations} from '../../services/mixpanel/mixpanel.service';
import {DEFAULT_ASSIGNMENT_LIMIT} from '../../model/domain/app-configuration';
import VirtualCollection from '../../model/domain/virtual-collection';
import ValidationDomainEmailUtils from '../../model/util/validation-domain-email-utils';

const MINIMUM_EMAIL_ADDRESSES = 1;

export default class IncentiveAssignmentLimitV1DialogController {

  /**
   * @ngInject
   */

  constructor($scope, $log, $mdDialog, $mdToast, $location,
              OrderService, CacheService, AuthService,
              AnalyticsService, IncentiveService, OrganizationService,
              StaticContentService, clickedFrom, user, userSchools) {

    this.$log = $log;
    this.$scope = $scope;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$location = $location;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {IncentiveService} */
    this._incentiveService = IncentiveService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;

    this._incentiveId = 'assignment-limit-v2';
    this._clickedFrom = clickedFrom;
    this._state = this.Loading;
    this._toastMessage = '';
    /** @type {boolean} */
    this._isValidEmailFormat = false;
    /** @type {boolean} */
    this._isValidEmailDomain = false;

    /** @type { User } */
    this._user = user;  // incentive user

    /** @type { Array[Organization] } */
    this._userSchools = userSchools; // organizations the user is a member of

    this._peers = [];
    this._virtualPeers = undefined;
    this._selectedListObj = {};

    /** @type { String } */
    this._organizationId = '';

    this._analyticsService.sendEvent({
      eventTag: 'incentiveDialog_viewed',
      properties: {
        location: this._clickedFrom
      }
    });
    if (this._userSchools.length > 0) {
      // Pre-select the first organization
      this._organizationId = this._userSchools[0].id;
      this.getMdrTeachers();
    }

    this.toggleSwitch = true;

    this.isProUser = false;
    if (this._authService.authData) {
      /** @type {boolean} */
      this.isProUser = this._authService.authData.isPro;
      if (this.isProUser) {
        this._incentiveId = 'assignment-limit-pro-v1';
      }
    }

    /** @type { AppConfiguration } */
    this._appConfig = {};

    // Array of existing emails with accounts
    this._existingUsers = [];
    // Array of emails already sent, when on send returns existing emails with accounts, don't resend
    this._sentInvite = [];

    // The model used for the email inputs
    this.to = [
      {
        id: 0,
        label: 'email*',
        value: '',
        placeholder: 'teacher@school.edu*'
      },
      {
        id: 1,
        label: 'email 2',
        value: '',
        placeholder: 'teacher1@school.edu'
      },
      {
        id: 2,
        label: 'email 3',
        value: '',
        placeholder: 'teacher2@school.edu'
      },
      {
        id: 3,
        label: 'email 4',
        value: '',
        placeholder: 'teacher3@school.edu'
      }
    ];

    this.mdrViewCustomTo = {
      id: '00',
      label: 'email',
      value: '',
      placeholder: 'teacher@school.edu, teacher1@school.edu, teacher2@school.edu'
    };

    this._defaultBody = 'Hi there,\n\nI just tried Classkick because I can see my students\' thinking instantly, save hours of time, and ensure learners are engaged getting help and feedback. Here\'s a 1 min video - https://www.youtube.com/watch?v=l5vza3HTW5I of the magic.';
    if (!this.isProUser) {
      this._defaultBody +=  ' If you sign up in the next seven days, I will be able to GIFT you 5 more assignments to help you get started!';
    }
    this._body = this._defaultBody;
    this._cacheService.getAppConfig().then((config) => this._appConfig = config);
    this._analyticsService.incentiveSend(`${clickedFrom}:incentiveDialog_opened`, this._incentiveId);
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @param value {string}
   */
  set state(value) {
    this._state = value;
  }

  get Loading() {
    return 'loading';
  }

  get body() {
    return this._body;
  }

  set body(value) {
    this._body = value;
  }

  get nav() {
    return Locations.NAV;
  }

  get header() {
    return Locations.HEADER;
  }

  get DraftEmail() {
    return 'draft_email';
  }

  get EmailError() {
    return 'email_error';
  }

  get buttonText() {
    return 'SEND';
  }

  get virtualPeers() {
    return this._virtualPeers;
  }

  /**
   * Sets the error message for minimum
   *
   * @returns {string}
   */
  get emailAddressMinCountText() {
    if (MINIMUM_EMAIL_ADDRESSES === 1) {
      return '1 email address';
    } else {
      return `${MINIMUM_EMAIL_ADDRESSES} email addresses`;
    }
  }

  formatBody() {
    return this.body.split('\n').join('<br>');
  }

  canUserViewMdrData() {
    return ValidationDomainEmailUtils.isEmailDomainMatchingListOfDomains(this._user.email, this.recipientList());
  }

  dontShowMdrData() {
    this._virtualPeers = undefined;
    this._selectedListObj = {};
  }


  toggleMdrEmails() {
    if (this.toggleSwitch) {
      this.virtualPeers._fullCollection.forEach((peer) => {
        this._selectedListObj[peer] = true;
      });
      this.displayToastMessage('Selected all emails');
    } else {
      this.virtualPeers._fullCollection.forEach((peer) => {
        this._selectedListObj[peer] = false;
      });
      this.displayToastMessage('Unselected all emails');
    }
    return this._selectedListObj;
  }


  getMdrTeachers() {
    this._organizationService.getMdrPeersNotInOrg(this._organizationId)
      .then((peers) => {
        if (peers.length) {
          this._peers = peers.map((v) => v.toLowerCase());
          this._virtualPeers = new VirtualCollection(this._peers, 10);
          this._virtualPeers._fullCollection.forEach((peer) => {
            this._selectedListObj[peer] = true;
          });
          if (!this.canUserViewMdrData()) {
            this.dontShowMdrData();
          }
          if (this._virtualPeers && this._virtualPeers._fullCollection.length > 0) {
            this._analyticsService.sendEvent({
              eventTag: 'incentiveDialog_peersShown',
              properties: {
                peersCount: this._virtualPeers._fullCollection.length,
                location: this._clickedFrom
              }
            });
          } else {
            this._analyticsService.sendEvent({
              eventTag: 'incentiveDialog_noPeersShown',
              properties: {
                peersCount: 0,
                location: this._clickedFrom
              }
            });
          }
        } else {
            this._analyticsService.sendEvent({
              eventTag: 'incentiveDialog_noPeersShown',
              properties: {
                peersCount: 0,
                location: this._clickedFrom
              }
            });
        }
        this._state = this.DraftEmail;
      })
      .catch((error) => {
        this.state = this.EmailError;
        this.displayToastMessage('Sorry there was an error. Please try again later.');
      });
    }

  recipientList() {
    return Object.keys(this._selectedListObj).reduce((list, email) => {
      if (this._selectedListObj[email]) {
        list.push(email.trim());
      }
      return list;
    }, []);
  }

  /**
   * Checks if user has entered the minimum number of email address
   *
   * @returns {boolean}
   */
  hasMinimumEmailAddresses() {
    return this.to
      .map((to) => to.value)
      .filter((val) => val !== '')
      .length >= MINIMUM_EMAIL_ADDRESSES;
  }

  hasMinimumCustomMdrEmailAddresses() {
    let customEmails = this.mdrViewCustomTo.value ? this.mdrViewCustomTo.value.split(','): [];
    let mdrEmailsSelected = this.recipientList();
    let filteredCustomEmails = customEmails.filter((val) => val !== '').length >= MINIMUM_EMAIL_ADDRESSES;
    let filteredMdrEmailsSelected = mdrEmailsSelected.length >= MINIMUM_EMAIL_ADDRESSES;

    return (filteredMdrEmailsSelected || filteredCustomEmails);
  }

  /**
   * Evaluates the to input fields on the form
   *
   * @param scope
   */
  setEmailValidity(scope) {
    const formElement = scope.incentiveForm[`toInput[${scope.emailTo.id}]`];
    const emails = (scope.emailTo.value || '').split(',');

    ({ isValidFormat: this._isValidEmailFormat, isValidEmailDomain: this._isValidEmailDomain} = ValidationDomainEmailUtils.isEmailListValid(emails));
    let currentId = scope.emailTo.id;
    let isUnique = true;
    let isPossibleNewUser = true;
    for (let idx in this.to) {
      const otherEmail = this.to[idx];
      if (!isUnique) {
        continue;
      }
      if (otherEmail.id === currentId) {
        continue;
      }
      if (otherEmail.value !== '' && emails.includes(otherEmail.value)) {
        isUnique = false;
      }
    }

    const hasMinimumEmailAddresses = this.hasMinimumEmailAddresses();

    formElement.$setValidity('newuser', isPossibleNewUser);
    formElement.$setValidity('unique', isUnique);
    formElement.$setValidity('pattern', this._isValidEmailFormat);
    formElement.$setValidity('domain', this._isValidEmailDomain);
    formElement.$setValidity('minimum', hasMinimumEmailAddresses);
  }

  setEmailValidityMdr(scope) {
    const formElement = scope.incentiveForm[`toInput[${scope.ctrl.mdrViewCustomTo.id}]`];
    const emails = (formElement.$viewValue || '').split(',');

    ({ isValidFormat: this._isValidEmailFormat, isValidEmailDomain: this._isValidEmailDomain } = ValidationDomainEmailUtils.isEmailListValid(emails, false));

    let isUnique = true;
    let isPossibleNewUser = true;

    formElement.$setValidity('newuser', isPossibleNewUser);
    formElement.$setValidity('unique', isUnique);
    formElement.$setValidity('pattern', this._isValidEmailFormat);
    formElement.$setValidity('domain', this._isValidEmailDomain);
  }

  /**
   * Evaluates the response from the backend
   *
   * @param scope
   */
  hasExistingUsersErrors(scope) {
    let isPossibleNewUser = true;
    for (let idx in this.to) {
      const otherEmail = this.to[idx];
      if (otherEmail.value !== '' && this._existingUsers.includes(otherEmail.value)) {
        isPossibleNewUser = false;
        scope.incentiveForm[`toInput[${otherEmail.id}]`].$setValidity('newuser', false);
      } else {
        this._sentInvite.push(otherEmail.value);
      }

    }
    this._existingUsers = []; // reset existing users
  }

  /**
   * Checks if the input field has an invalid state
   *
   * @param scope
   * @returns {boolean|*}
   */
  isError(scope) {
    return scope.incentiveForm[`toInput[${scope.emailTo.id}]`].$invalid;
  }

  isErrorMdr(scope) {
    return scope.incentiveForm[`toInput[${scope.ctrl.mdrViewCustomTo.id}]`].$invalid;
  }

  /**
   * Checks if the user already received the incentive-id
   * @return Boolean
   */
  get receivedIncentive() {
    // Hacky hack check if the users assignment limit is greater than the default
    return this._appConfig.assignmentLimit > DEFAULT_ASSIGNMENT_LIMIT;
  }

  validForm(scope) {
    if (this._existingUsers.length > 0) {
      this.hasExistingUsersErrors(scope);
    }

    const hasMinimumEmailAddresses = this.hasMinimumEmailAddresses();
    const hasMinimumCustomMdrEmailAddresses = this.hasMinimumCustomMdrEmailAddresses();
    if (hasMinimumEmailAddresses) {
      // Clears up minimum error if user adds email in a different text box
      for (let idx in this.to) {
        const otherEmail = this.to[idx];
        scope.incentiveForm[`toInput[${otherEmail.id}]`].$setValidity('minimum', true);
      }
    }

    return scope.incentiveForm.$valid && (hasMinimumEmailAddresses || hasMinimumCustomMdrEmailAddresses);
  }

  formatMdrFormSubmissionIfExists() {
    let mdrEmailsSelected = this.recipientList();
    let customEmails = this.mdrViewCustomTo.value ? this.mdrViewCustomTo.value.split(','): [];

    let concatMdrAndCustomEmails = mdrEmailsSelected.concat(customEmails);

    let mdrCustomEmailToArr = [];
    if (concatMdrAndCustomEmails.length) {
      for (let idx in concatMdrAndCustomEmails) {
        let mdrCustomEmailToObj = {};
        mdrCustomEmailToObj['value'] = concatMdrAndCustomEmails[idx];
        mdrCustomEmailToObj['id'] = this.mdrViewCustomTo.id;
        mdrCustomEmailToArr.push(mdrCustomEmailToObj);
      }
    }

    if (mdrCustomEmailToArr.length) {
      this.to = mdrCustomEmailToArr;
    }

  }

  submitForm(scope) {
    if (this.validForm(scope)) {
      this.formatMdrFormSubmissionIfExists();

      this._state = this.Loading;
      let emails = [];
      for (let index in this.to) {
        const toSend = this.to[index].value;
        if (!this._sentInvite.includes(toSend)) {
          emails.push(this.to[index].value);
        }
      }
      const postBody = {
        organizationId: this._organizationId,
        emails: emails
      };

      // sendgrid template adds body if field is missing or same as default
      if (this.body !== '' && this.body !== this._defaultBody) {
        postBody['custom'] = this.formatBody();
      }

      this._incentiveService
          .save(this._incentiveId, postBody)
          .then((resp) => {
            this._analyticsService.incentiveSend(
                `${this._clickedFrom}:incentiveDialog_started`,
                this._incentiveId
            );
            if (angular.isDefined(resp.existingUsers)) {
              this.state = this.DraftEmail;
              if (resp.existingUsers.length === 0) {
                let nonEmptyEmails = emails.filter((email) => email !== '');
                this.displayToastMessage('Emails sent');

                this._analyticsService.sendEvent({
                  eventTag: 'incentiveDialog_sent',
                  properties: {
                    emailsSentCount: nonEmptyEmails.length,
                    location: this._clickedFrom
                  }
                });

                this.$mdDialog.cancel();
              } else {
                this._existingUsers = this._existingUsers.concat(resp.existingUsers);
              }
            }
          })
          .catch((err) => {
            this.state = this.EmailError;
            this.$log.warn(err);
          });
    }
  }

  cancel() {
    this._analyticsService.sendEvent({
      eventTag: 'incentiveDialog_canceled',
      properties: {
         location: this._clickedFrom
      }
    });

    this.$mdDialog.cancel();
  }

  displayToastMessage(message,) {
    this._toastMessage = message;
    let toastClassName = 'referral-toast';
    const config = this.$mdToast.simple(this._toastMessage).action(' ').actionKey('x').position('top right').toastClass(toastClassName).hideDelay(4000);
    this.$mdToast.show(config);
  }

  clickOrderLink() {
    this._analyticsService.goToOrderPage('incentiveDialog', this._organizationId);

    const isReferPage = window.location.pathname === '/refer';
    if (!isReferPage) {
      this.$mdDialog.cancel();
    }

    this._staticContentService.goToOrdersPage();
  }

  /**
   * Launches share order dialog
   * @param clickedFrom
   * @param $mdDialog
   * @param user
   * @param userSchools user is a member of these schools
   * @returns {*}
   */
  static show(clickedFrom, $mdDialog, user, userSchools) {
    const isReferPage = window.location.pathname === '/refer';

    return $mdDialog.show({
      controller: IncentiveAssignmentLimitV1DialogController,
      template: IncentiveAssignmentLimitV1Dialog,
      controllerAs: 'ctrl',
      clickOutsideToClose: !isReferPage,
      multiple: true,
      locals: {
        clickedFrom,
        user,
        userSchools,
      }
    });
  }
}
