'use strict';

import moment from 'moment';

/**
 * A request to copy a specific assignment
 */
export default class CopyAssignmentRequest {

  /**
   * @param id {string} the assignment id
   * @param name {string} the assignment name
   * @param [t] {string} the time when the request was created
   */
  constructor(id, name, t) {
    this.id = id;
    this.name = name;
    this.t = angular.isDefined(t) ? moment(t) : moment();
  }

  get isValid() {
    let diff = moment().diff(this.t, 'minutes');
    return diff < 1;
  }

}
