'use strict';

import Codec from './codec';
import QuestionFeedback from '../domain/question-feedback';

export default class QuestionFeedbackCodec extends Codec {

  constructor() {
    super();
  }

  /**
   * @param value {object}
   * @returns {object}
   */
  encode(value) {}

  /**
   * @param value {object}
   * @param key {string}
   * @returns {object}
   */
  decode(value, key) {
    if (value) {
      return new QuestionFeedback(
        key,
        value.givers,
        value.t
      );
    }
    return null;
  }

}
