export class MatchState {
  static get MATCHED() {
    return 'matched';
  }

  static get INACTIVE() {
    return 'inactive';
  }

  static get UNMATCHED() {
    return 'unmatched';
  }
}

export default class GradebookStudentExport {
  constructor(
    id,
    name,
    login,
    lmsStudent,
    selectedLmsStudentDisplayName,
    matchState,
    selected = false
  ) {
    this._id = id;
    this._name = name;
    this._login = login;
    this._lmsStudent = lmsStudent;
    this._selectedLmsStudentDisplayName = selectedLmsStudentDisplayName;
    this._matchState = matchState;
    this._selected = selected;
  }

  static from(rosterStudent, lmsStudent) {
    const instance = new GradebookStudentExport(
      rosterStudent.id,
      rosterStudent.name,
      rosterStudent.login,
      null,
      '',
      MatchState.UNMATCHED,
      false
    );

    instance.lmsStudent = lmsStudent; // This will set the correct match state and selected values

    return instance;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get login() {
    return this._login;
  }

  get lmsStudent() {
    return this._lmsStudent;
  }

  set lmsStudent(lmsStudent) {
    this._lmsStudent = lmsStudent;

    this._selected = false;
    if (lmsStudent) {
      this._selectedLmsStudentDisplayName = lmsStudent.email ? lmsStudent.email : lmsStudent.name;
      if (lmsStudent.isLinkActive) {
        this.matchState = MatchState.MATCHED;
        this._selected = true;
      } else {
        this._matchState = MatchState.INACTIVE;
      }
    } else {
      this._matchState = MatchState.UNMATCHED;
    }
  }

  get selectedLmsStudentDisplayName() {
    return this._selectedLmsStudentDisplayName;
  }

  set selectedLmsStudentDisplayName(selectedLmsStudentDisplayName) {
    this._selectedLmsStudentDisplayName = selectedLmsStudentDisplayName;
  }

  get matchState() {
    return this._matchState;
  }

  set matchState(matchState) {
    this._matchState = matchState;
  }

  get selected() {
    return this._selected;
  }

  set selected(value) {
    this._selected = value;
  }
}
