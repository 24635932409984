
'use strict';

let limit = 25;

export default class UndoStack {
  /**
   * @param id {string}
   * @param [undoCreate] {Function}
   * @param [undoRemove] {Function}
   * @param [undoChange] {Function}
   */
  constructor(id, undoCreate, undoRemove, undoChange) {
    this._id = id;
    this._undoCreate = undoCreate;
    this._undoRemove = undoRemove;
    this._undoChange = undoChange;

    this._undo = new Map();
    this._redo = new Map();
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @param change {Change | MultiChange}
   * @param question {AssignmentQuestion}
   */
  push(change, question) {
    if (!this._undo.has(question.id)) {
      this._undo.set(question.id, []);
    }

    this._undo.get(question.id).push(change);
    this._redo.set(question.id, []);

    if (this._undo.get(question.id).length > limit) {
      this._undo.get(question.id).splice(0, 1);
    }
  }

  /**
   * @param question {AssignmentQuestion}
   */
  undo(question) {
    if (this.isUndoEmpty(question)) {
      return;
    }

    /** @type {Change} */
    let change = this._undo.get(question.id).pop();

    let result = change.undo(
      question,
      this._undoCreate,
      this._undoRemove,
      this._undoChange
    );
    this._redo.get(question.id).push(result);
    return result;
  }

  /**
   * @param question {AssignmentQuestion}
   */
  redo(question) {
    if (this.isRedoEmpty(question)) {
      return;
    }

    /** @type {Change} */
    let change = this._redo.get(question.id).pop();

    let result = change.undo(
      question,
      this._undoCreate,
      this._undoRemove,
      this._undoChange
    );
    this._undo.get(question.id).push(result);
    return result;
  }

  /**
   * @param question {AssignmentQuestion}
   * @returns {boolean}
   */
  isUndoEmpty(question) {
    return !this._undo.has(question.id) || this._undo.get(question.id).length === 0;
  }

  /**
   * @param question {AssignmentQuestion}
   * @returns {boolean}
   */
  isRedoEmpty(question) {
    return !this._redo.has(question.id) || this._redo.get(question.id).length === 0;
  }
}
