'use strict';

import moment from 'moment';
import FirebaseObject from '../firebase-mapping/firebase-object';
import ModelMapping from '../firebase-mapping/model-mapping';
import FirebasePropertyMapping from '../firebase-mapping/firebase-property-mapping';
import SimpleCodec from '../codec/simple-codec';
import NotificationShardKey from './notification-shard-key.js';

export class StatusActivities {
  static get WORKING() {
    return 'working';
  }
  static get HELPING() {
    return 'helping';
  }
}

export class StatusPlatforms {
  static get WEB() {
    return 'web';
  }

  static get IPAD() {
    return 'iPad';
  }
}

export class StatusIdleTimes {

  static get UNIT_OF_TIME() {
    return 'hours';
  }

  static get DURATION() {
    return 2;
  }

}

let mapping = new ModelMapping('/notifications/v3/users/{userId}/status',
  {
    online: new FirebasePropertyMapping(
      '/online',
      FirebaseObject.defaultListenIndicator,
      (instance) => { return instance._online; },
      (instance, value) => {instance._online = value; },
      new SimpleCodec()
    ),
    assignment_id: new FirebasePropertyMapping(
      '/assignment_id',
      FirebaseObject.defaultListenIndicator,
      (instance) => { return instance._assignment_id; },
      (instance, value) => {instance._assignment_id = value; },
      new SimpleCodec()
    ),
    question_id: new FirebasePropertyMapping(
      '/question_id',
      FirebaseObject.defaultListenIndicator,
      (instance) => { return instance._question_id; },
      (instance, value) => {instance._question_id = value; },
      new SimpleCodec()
    ),
    activity: new FirebasePropertyMapping(
      '/activity',
      FirebaseObject.defaultListenIndicator,
      (instance) => { return instance._activity; },
      (instance, value) => {instance._activity = value; },
      new SimpleCodec()
    ),
    platform: new FirebasePropertyMapping(
      '/platform',
      FirebaseObject.defaultListenIndicator,
      (instance) => { return instance._platform; },
      (instance, value) => {instance._platform = value; },
      new SimpleCodec()
    ),
    helpee_id: new FirebasePropertyMapping(
      '/helpee_id',
      FirebaseObject.defaultListenIndicator,
      (instance) => { return instance._helpee_id; },
      (instance, value) => {instance._helpee_id = value; },
      new SimpleCodec()
    ),
    t: new FirebasePropertyMapping(
      '/t',
      FirebaseObject.defaultListenIndicator,
      (instance) => { return instance._t; },
      (instance, value) => {instance._t = value; },
      new SimpleCodec()
    )
  }
);

export default class UserStatusEditor extends FirebaseObject {
  /**
   * @param notificationShardKey {NotificationShardKey}
   * @param userId {string}
   * @param FirebaseService {FirebaseService}
   */
  constructor(notificationShardKey, userId, FirebaseService) {
    let firebaseInstanceId = FirebaseService.firebaseNotificationInstanceIdForShardKey(notificationShardKey);
    let traceTag = `UserStatusEditor[instance=${firebaseInstanceId}, ${notificationShardKey}]`;

    super(
      new Map()
        .set('userId', userId),
      mapping,
      firebaseInstanceId,
      FirebaseService,
      undefined,
      true,
      traceTag
    );

    this._userId = userId;
    this._online = false;
    this._assignment_id = null;
    this._question_id = null;
    this._activity = null;
    this._platform = null;
    this._helpee_id = null;
    this._t = null;
    this._firebaseService = FirebaseService;

    this._logTrace('cstr complete');
  }

  start() {
    this._logTrace('start');
    super.start();
    this.setupOnConnected();
    this.setupOnDisconnect();
    return this;
  }

  stop() {
    this._logTrace('stop');
    this.setStatusOffline();
    this._firebaseService.connectedEvent.unsubscribe(this.onConnected, this);
    super.stop();
    return this;
  }

  /**
   * callback for subscribing to FirebaseService's JSEvent.
   * @param connected {boolean}
   */
  onConnected(connected) {
    if (connected) {
      this._logTrace('onConnected');
      this.setupOnDisconnect();
      this.setStatus(true, this.assignmentId, this.questionId, this.activity, this.helpeeId);
    }
  }

  /**
   * setup the callback for reconnecting to firebase
   */
  setupOnConnected() {
    this._firebaseService.connectedEvent.subscribe(this.onConnected, this);
  }

  /**
   * setup onDisconnect functions to remove the student's status from Firebase
   * whenever they disconnect.
   */
  setupOnDisconnect() {
    this.firebase.onDisconnectSet(mapping.properties.online, false);
  }

  /**
   * Set the student's status in Firebase.
   * @param online {boolean|null}
   * @param assignment_id {string|null}
   * @param question_id {string|null}
   * @param activity {string|null}
   * @param [helpeeId] {string}
   */
  setStatus(online, assignment_id, question_id, activity, helpeeId) {
    this.online = !!online;
    this.assignmentId = assignment_id;
    this.questionId = question_id;
    this.activity = activity;
    this.helpeeId = helpeeId || null;
    this.platform = StatusPlatforms.WEB;
    this._setT();
  }

  /**
   * convenience method for going offline.
   */
  setStatusOffline() {
    this.online = false;
    this._setT();
  }

  /**
   * @param value {boolean}
   */
  set online(value) {
    this._online = value;
    this.firebase.setValue(mapping.properties.online, value);
  }

  /**
   * @param value {string|null}
   */
  set assignmentId(value) {
    this._assignment_id = value;
    this.firebase.setValue(mapping.properties.assignment_id, value);
  }

  /**
   * @param value {string|null}
   */
  set questionId(value) {
    this._question_id = value;
    this.firebase.setValue(mapping.properties.question_id, value);
  }

  /**
   * @param value {string|null}
   */
  set activity(value) {
    this._activity = value;
    this.firebase.setValue(mapping.properties.activity, value);
  }

  /**
   * @param value {string|null}
   */
  set platform(value) {
    this._platform = value;
    this.firebase.setValue(mapping.properties.platform, value);
  }

  /**
   * @param value {string|null}
   */
  set helpeeId(value) {
    this._helpee_id = value;
    this.firebase.setValue(mapping.properties.helpee_id, value);
  }

  /**
   * sets the student's timestamp to now and posts it to firebase
   * @private
   */
  _setT() {
    this._t = moment();
    this.firebase.setValue(mapping.properties.t, this._t.toISOString());
  }

  /**
   * @returns {string}
   */
  get userId() {
    return this._userId;
  }

  /**
   * @returns {boolean}
   */
  get online() {
    return this._online;
  }

  /**
   * @returns {string|null}
   */
  get assignmentId() {
    return this._assignment_id;
  }

  /**
   * @returns {string|null}
   */
  get questionId() {
    return this._question_id;
  }

  /**
   * @returns {string|null}
   */
  get activity() {
    return this._activity;
  }

  /**
   * @returns {string|null}
   */
  get platform() {
    return this._platform;
  }

  /**
   * @returns {string|null}
   */
  get helpeeId() {
    return this._helpee_id;
  }

  /**
   * @returns {moment}
   */
  get t() {
    return this._t;
  }
}
