'use strict';

import { FillInTheBlankAnswer } from '../../model/ui/elements/fill-in-the-blank-parent';
import ScoreUtils from '../../model/util/score-utils';
import Debouncer from '../../model/util/debouncer';

export default class FillInTheBlankManager {

  constructor(AssignmentTrackingService, CacheService, $rootScope) {
    /** @type {AssignmentTrackingService} */
    this._assignmentTrackingService = AssignmentTrackingService;
    /** @type {CacheService} */
    this._cacheService = CacheService;

    this.$rootScope = $rootScope;
    this._selected = undefined;
    this._copiedSelectedAnswers = undefined;
    this._user = undefined;
    this._showHelp = false;
    this._changeDebouncer = new Debouncer(2000, 3000, () => this.$rootScope.$emit('save-answer'));
  }

  init() {
    this._cacheService.getUser(false).then((user) => {
      this._user = user;
      this._showHelp = this._user.showFillInTheBlankIntro;
    });
  }

  /**
   * @param value {FillInTheBlankParent}
   */
  set selected(value) {
    this._copiedSelectedAnswers = undefined;
    this._selected = value;
  }

  /**
   * @return value {FillInTheBlankParent}
   */
  get selected() {
    return this._selected;
  }

  get selectedAnswers() {
    if (this._selected && !this._copiedSelectedAnswers) {
      this._copiedSelectedAnswers = this._selected.answers.map((answer) => answer.clone());
    }

    return this._copiedSelectedAnswers || [];
  }

  get hasSelectedAnswers() {
    return this.selectedAnswers.length > 0;
  }

  get selectedPoints() {
    if (this.hasSelectedAnswers) {
      return this.selectedAnswers[0].points;
    } else {
      return null;
    }
  }

  set selectedPoints(value) {
    if (!ScoreUtils.scoreIsValid(value) && angular.isNumber(value)) {
      return;
    }

    let formattedValue = ScoreUtils.formatScore(value);

    this.selectedAnswers.forEach((answer) => {
      answer.points = formattedValue;
    });

    this.saveAnswers();
  }

  incrementPoints() {
    if (this.selectedAnswers) {
      this.selectedPoints = this.selectedPoints + 1;
    }
  }

  decrementPoints() {
    if (this.selectedPoints >= 1) {
      this.selectedPoints = this.selectedPoints - 1;
    }
  }

  addAnswer() {
    if (this._selected) {
      this._copiedSelectedAnswers = [
        ...this._copiedSelectedAnswers,
        new FillInTheBlankAnswer(
          '',
          angular.isDefined(this.selectedPoints) ? this.selectedPoints : null,
          true
        )
      ];
      this.saveAnswers();
    }
  }

  deleteAnswer(answer) {
    if (this._selected) {
      this._copiedSelectedAnswers = this._copiedSelectedAnswers.filter((value) => value !== answer);
      this.saveAnswers();
    }
  }

  saveAnswers() {
    if (this._copiedSelectedAnswers) {
      this._selected.answers = this._copiedSelectedAnswers;
    }
  }

  handleChange(){
    this._changeDebouncer.tick();
  }

  /**
   * @return {boolean}
   */
  get showHelp() {
    return this._showHelp;
  }

  /**
   * @param value {boolean}
   */
  set showHelp(value) {
    this._showHelp = value;

    if (this._user && this._user.showFillInTheBlankIntro && !value) {
      this._user.showFillInTheBlankIntro = value;
      this._cacheService.updateUser(this._user);
    }
  }

}
