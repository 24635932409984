'use strict';

import ElementCodec from '../../model/codec/element-codec';
import { HttpRetry } from '../http/http.service';

export default class ManipulativeElementService {
  constructor(HttpService, environment) {
    'ngInject';

    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {ElementCodec} */
    this._elementCodec = new ElementCodec();

    this._httpRetry = new HttpRetry(undefined, undefined, undefined, (error) => {
      if (!(error.status >= 500 && error.status < 600)) {
        throw error;
      }
    });
  }

  /**
   * @param assignmentId {string}
   * @param questionId {string}
   * @param element {Element}
   * @return {Promise.<Element>}
   */
  createParent(assignmentId, questionId, element) {
    return this._httpService
      .authPost(
        this._uri(`/v1/assignments/${assignmentId}/questions/${questionId}/manipulatives`),
        this._elementCodec.encode(element),
        undefined,
        this._httpRetry
      )
      .then((result) => {
        let elementId = Object.keys(result)[0];
        return this._elementCodec.decode(result[elementId], elementId);
      });
  }

  /**
   * @param assignmentId {string}
   * @param questionId {string}
   * @param element {Element}
   * @return {Promise.<object>}
   */
  updateParent(assignmentId, questionId, element) {
    return this._httpService.authPut(
      this._uri(`/v1/assignments/${assignmentId}/questions/${questionId}/manipulatives/${element.id}`),
      this._elementCodec.encode(element),
      undefined,
      this._httpRetry
    );
  }

  /**
   * @param assignmentId {string}
   * @param questionId {string}
   * @param elementId {string}
   * @return {Promise.<object>}
   */
  deleteParent(assignmentId, questionId, elementId) {
    return this._httpService.authDelete(
      this._uri(`/v1/assignments/${assignmentId}/questions/${questionId}/manipulatives/${elementId}`)
    );
  }

  /**
   * @param assignmentId {string}
   * @param questionId {string}
   * @param elementId {string}
   * @return {Promise.<object>}
   */
  restoreParent(assignmentId, questionId, elementId) {
    return this._httpService.authPost(
      this._uri(`/v1/assignments/${assignmentId}/questions/${questionId}/manipulatives/${elementId}/restore`),
      {}
    );
  }

  /**
   * @param assignmentWorkId {string}
   * @param questionId {string}
   * @param element {Element}
   * @return {Promise.<object>}
   */
  updateChild(assignmentWorkId, questionId, element) {
    return this._httpService.authPut(
      this._uri(`/v1/assignment-works/${assignmentWorkId}/questions/${questionId}/manipulatives/${element.id}`),
      this._elementCodec.encode(element)
    );
  }

  /**
   * @param elementId {String}
   * @return {Promise.<object>}
   */
  getChildData(assignmentId, elementId) {
    return this._httpService.authPost(
      this._uri(`/v1/assignments/${assignmentId}/manipulatives/child/${elementId}`),
      {}
    );
  }

  /**
   * Creates a URL from a path
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }
}
