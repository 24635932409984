'use strict';

import Codec from './codec';
import MdrTeacher from '../domain/mdr-teacher';

/**
 * Encodes/Decodes an MDR teacher
 */
export default class MdrTeacherCodec extends Codec {

  constructor() {
    super();
  }

  /**
   * @param value {MdrTeacher}
   * @return {{ email_address: string, first_name: string, last_name: string }}
   */
  encode(value) {
    return {
      email_address: value.emailAddress,
      first_name: value.firstName,
      last_name: value.lastName
    };
  }

  /**
   * @param value {Object}
   * @return {MdrTeacher|null}
   */
  decode(value) {
    if (value) {
      return new MdrTeacher(
        value.email_address,
        value.first_name,
        value.last_name
      );
    }
    else {
      return null;
    }
  }
}
