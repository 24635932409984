
'use strict';

/**
 * Allows a <input type="file"> element to be bound with the ng-model attribute.
 * This is not supported in vanilla Angular
 */
export default function FileInputDirective() {
  return {
    require:'ngModel',
    restrict: 'A',
    link: function(scope, element, attrs, ngModel){

      element.bind('change', function(event){
        var files = event.target.files;
        var file = files[0];

        ngModel.$setViewValue(file);
      });
    }
  };
}
