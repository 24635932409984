
'use strict';

import CollectionObserver from '../firebase-mapping/collection-observer';
import JSEvent from '../util/js-event';

class Observer extends CollectionObserver {
  constructor(ctrl) {
    super();
    this.ctrl = ctrl;
  }

  onAdded(value) {
    this.ctrl.helperAdded(value);
  }

  onRemoved(value) {
    this.ctrl.helperRemoved(value);
  }

  onChanged(value) {
    this.ctrl.helperAdded(value);
  }
}

export class Helpers {

  /**
   * @param helpers {Array.<Helper>}
   */
  constructor(helpers) {
    this._list = helpers;
  }

  /**
   * @returns {Array.<Helper>}
   */
  get list() {
    return this._list;
  }

  /**
   * @param value {Array.<Helper>}
   */
  set list(value) {
    this._list = value;
  }

  get helpingMessage() {
    return Helpers.formatHelpingMessage(this._list);
  }

  /**
   * @param helpers {Array.<Helper>}
   * @returns {string}
   */
  static formatHelpingMessage(helpers) {
    let messageConjugation = Helpers.formatHelpersConjugation(helpers);
    let studentHelperMessage = Helpers.formatHelpersList(helpers.map((x) => x.name));
    return `${studentHelperMessage} ${messageConjugation}`;
  }

  /**
   * @param helpers {Array}
   * @returns {string}
   */
  static formatHelpersConjugation(helpers) {
    return helpers.length > 1 ? 'are helping' : 'is helping';
  }

  /**
   * @param helpers {Array.<string>}
   * @returns {string}
   */
  static formatHelpersList(helpers) {
    if (helpers.length > 1) {
      let lastHelper = helpers[helpers.length - 1];//.pop();
      return `${helpers.slice(0, -1).join(', ')} and ${lastHelper} `;
    }
    else if (helpers.length === 1) {
      return `${helpers[0]} `;
    }
    else {
      return null;
    }
  }
}

/**
 * Maintains a real-time object with references to all the current helpers for a given user
 */
export default class QuestionHelpers {

  /**
   * @param helpersNotification {HelpersNotification}
   * @param [filterFunc] {Function}
   */
  constructor(helpersNotification, filterFunc) {
    this._helpersNotification = helpersNotification;
    this._filterFunc = filterFunc || this.DEFAULT_FILTER_FUNC;
    this._helpers = {};

    /** @type {Helper} */
    this._selfHelper = null;

    this._updated = new JSEvent(this);
  }

  get DEFAULT_FILTER_FUNC() {
    return () => {
      return true;
    };
  }

  /**
   * @returns {HelpersNotification}
   */
  get notification() {
    return this._helpersNotification;
  }

  start() {
    this._helpersObserver = new Observer(this);
    this._helpersNotification.helpers.subscribe(this._helpersObserver);
    this._helpersNotification.start();
  }

  stop() {
    this._helpersNotification.helpers.unsubscribe(this._helpersObserver);
    this._helpersNotification.stop();
  }

  helperAdded(value) {
    if (!this._filterFunc(value)) {
      return;
    }

    this._remove(value);
    this._add(value);
  }

  helperRemoved(value) {
    if (!this._filterFunc(value)) {
      return;
    }

    this._remove(value);
  }

  /**
   * Maps questionId to Helpers
   * @returns {Object.<string, Helpers>}
   */
  get helpers() {
    return this._helpers;
  }

  /**
   * @returns {boolean}
   */
  get hasHelpers() {
    return this.size > 0;
  }

  /**
   * @returns {number}
   */
  get helpersHelpCount() {
    let count = 0;
    angular.forEach(this.helpers, (helper) => {
      count += helper.list.length;
    });
    return count;
  }

  /**
   * @returns {Number}
   */
  get size() {
    return Object.keys(this._helpers).length;
  }

  /**
   * @param value {Helper}
   * @private
   */
  _add(value) {
    let helpersForQuestion = this._helpers[value.questionId] || new Helpers([]);

    if (!helpersForQuestion.list.some((helper) => helper.id === value.id)) {
      helpersForQuestion.list.push(value);
    }

    this._helpers[value.questionId] = helpersForQuestion;
    this._raiseUpdated();
  }

  /**
   * Ensures helper is removed from each question
   * @param value {Helper}
   * @private
   */
  _remove(value) {
    angular.forEach(this._helpers, (helpersForQuestion, questionId) => {
      helpersForQuestion.list = helpersForQuestion.list.filter((helper) => helper.id !== value.id);

      if (helpersForQuestion.list.length === 0) {
        delete this._helpers[questionId];
      }
    });
    this._raiseUpdated();
  }

  /**
   * @returns {JSEvent}
   */
  get updated() {
    return this._updated;
  }

  /**
   * Raises the updated event
   * @private
   */
  _raiseUpdated() {
    this.updated.raise();
  }

  /**
   * @returns {Helper}
   */
  get selfHelper() {
    return this._selfHelper;
  }

  /**
   * @param value {Helper}
   */
  set selfHelper(value) {
    if (angular.equals(value, this.selfHelper)) {
      return;
    }

    if (this._selfHelper) {
      this._remove(this._selfHelper);
    }
    this._selfHelper = value;
    if (this._selfHelper) {
      this._add(this._selfHelper);
    }
  }
}

