
'use strict';

import FirebaseConnection from './firebase-connection';

/**
 * Represents an object whose properties are mapped to firebase
 */
export default class FirebaseObject {
  /**
   * @param ids {Map.<string>} of string identifier to id
   * @param mapping {ModelMapping} indicates how the firebase schema maps to instances of this object
   * @param firebaseInstanceId {string} the id of the firebase instance for this object's data
   * @param FirebaseService {FirebaseService} the instance of the firebase service
   * @param [listen] {boolean} default listen indicator (property-mappings can specify custom logic)
   * @param [triggerApply] {boolean}
   * @param [traceTag] {string} Trace logging tag for firebase activity
   */
  constructor(ids, mapping, firebaseInstanceId, FirebaseService, listen = true, triggerApply = false, traceTag = '') {
    this._ids = ids;
    this._mapping = mapping;
    this._firebaseInstanceId = firebaseInstanceId;
    this._firebaseService = FirebaseService;
    this._listen = listen;
    this._triggerApply = triggerApply;
    this._started = false;
    this._traceTag = traceTag;

    this._firebase = null;
  }

  /**
   * Subscribe to firebase notifications for this object
   * @returns {this}
   */
  start() {
    if (!this._started) {
      this.firebase.start();
      this._started = true;
    }
    return this;
  }

  /**
   * Unsubscribe to firebase notifications for this object
   * @returns {this}
   */
  stop() {
    this.firebase.stop();
    this._started = false;
    return this;
  }

  /**
   * Promise resolves when data has been loaded once (if not listening)
   * @returns {Promise}
   */
  loadOnce() {
    this.start();
    return this.firebase.loadOnce();
  }

  /**
   * @returns {ModelMapping}
   */
  get mapping() {
    return this.firebase.mapping;
  }

  /**
   * @returns {Map.<string, string>}
   */
  get ids() {
    return this._ids;
  }

  /**
   * @return {string}
   */
  get firebaseInstanceId() {
    return this._firebaseInstanceId;
  }

  /**
   * @returns {FirebaseConnection}
   */
  get firebase() {
    this._firebase = this._firebase || new FirebaseConnection(this._firebaseInstanceId, this._firebaseService, this, this._mapping, this._triggerApply, this._traceTag);
    return this._firebase;
  }

  /**
   * @return {FirebaseService}
   */
  get firebaseService() {
    return this._firebaseService;
  }

  /**
   * Trace firebase activity
   * @param message {string}
   */
  _logTrace(message) {
    this._firebaseService && this._firebaseService.logTrace(this._traceTag, message);
  }

  /**
   * @param instance {FirebaseObject}
   * @returns {boolean}
   */
  static defaultListenIndicator(instance) {
    return instance._listen;
  }
}
