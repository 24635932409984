import ToolbarFontFamilyMenuTemplate from './toolbar-font-family-menu.component.html';

class ToolbarFontFamilyMenuController {
  /**
   * @ngInject
   */
  constructor(AnalyticsService) {
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
  }

  select(font) { 
    this._analyticsService.sendEvent({
      eventTag: 'asmt:fontFamily_selectedFont',
      properties: {
        panel: this.menuType,
        name: font.value
      },
    count: true
    });
    this.onSelect({font});
  }
}

export default {
  bindings: {
    fonts: '<',
    menuType: '@',
    onSelect: '&',
    chosenFont: '<'
  },
  template: ToolbarFontFamilyMenuTemplate,
  controller: ToolbarFontFamilyMenuController,
  controllerAs: 'ctrl'
};