'use strict';

import Codec from './codec';
import MdrOrganization from '../domain/mdr-organization';

/**
 * Encodes/Decodes an MDR organization
 */
export default class MdrOrganizationCodec extends Codec {

  constructor() {
    super();
  }

  /**
   * @param value {MdrOrganization}
   * @return {{ pid: number, institution_name: string, address: string, city: string,state: string, zipcode: string, zip4 = string,
   * country: string, institution_type: string, enrollment: number, geo_latitude: number, geo_longitude: number }}
   */
  encode(value) {
    return {
      pid: value.pid,
      institution_name: value.institutionName,
      address: value.address,
      city: value.city,
      state: value.state,
      zipcode: value.zipcode,
      zip4: value.zip4,
      country: value.country,
      institution_type: value.institutionType,
      enrollment: value.enrollment,
      geo_latitude: value.latitude,
      geo_longitude: value.longitude
    };
  }

  /**
   * @param value {Object}
   * @return {MdrOrganization|null}
   */
  decode(value) {
    if (value) {
      return new MdrOrganization(
        value.pid,
        value.institution_name,
        value.address,
        value.city,
        value.state,
        value.zipcode,
        value.zip4,
        value.country,
        value.institution_type,
        value.enrollment,
        value.geo_latitude,
        value.geo_longitude
      );
    }
    else {
      return null;
    }
  }
}
