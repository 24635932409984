'use strict';

import Codec from './codec';
import NewContractStudent from '../domain/new-contract-student';

export default class NewContractStudentCodec extends Codec {

  constructor() {
    super();
  }

  encode(value) {
    if (value) {
      return {
        first_name: value.firstName,
        last_name: value.lastName,
        id: value.id,
        password: value.password
      };
    }

    return null;
  }

  decode(value) {
    if (value) {
      return new NewContractStudent(
        value.id,
        value.first_name,
        value.last_name,
        value.password
      );
    }

    return null;
  }

}
