export default class GradebookAssignmentCategory {

  /**
   * @param id {string}
   * @param title {string}
   */
  constructor(
    id,
    title
  ) {
    this._id = id;
    this._title = title;
  }

  get id (){
    return this._id;
  }
  get title (){
    return this._title;
  }
}

