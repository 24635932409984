'use strict';
/*global YT:false */
import VideoPlayerDialogTemplate from './video-player-dialog.html';

export default class VideoPlayerDialogController {

  constructor($mdDialog, $document, $window, videoUrl, isClasskickIntroVideo, AnalyticsService) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.$document = $document;
    this.$window = $window;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._stopped = false;
    this._videoUrl = videoUrl;
    this._isClasskickIntroVideo = isClasskickIntroVideo;

    this._init();
  }

  _init() {

    if (this.$window.innerWidth > this.$window.innerHeight) {
      this._height = this.$window.innerHeight - 200;
      this._width = this._height * 1.76;
    }
    else {
      this._width = this.$window.innerWidth - 0;
      this._height = this._width * 0.56;
    }

    this._loadScript('https://www.youtube.com/iframe_api').then(() => {
      YT.ready(() => {
        this.player = new YT.Player('player', {
          height: this._height,
          width: this._width,
          videoId: this.youtubeParser(this._videoUrl),
          events: {
            onReady: this.onPlayerReady.bind(this),
            onStateChange: this.onStateChanging.bind(this)
          }
        });
      });
    });


  }

  onPlayerReady(event) {
    if (!this._stopped) {
      event.target.playVideo();
    }
  }

  onStateChanging(event){
    if (this._isClasskickIntroVideo) {
      //Refer to onStateChange data for states here https://developers.google.com/youtube/iframe_api_reference#Events
      if (event.data === 1 && !this._stopped) {
        this._analyticsService.startClasskickIntroVideo();
      }
      if (event.data === 2) {
        this._stopped = true;
      }
    }
  }

  /**
   * Helper function to load any additional scripts
   * @param src {string}
   * @return {Promise<any>}
   */
  _loadScript(src) {
    return new Promise((resolve, reject) => {
      let script = this.$document[0].createElement('script');
      script.setAttribute('src', src);
      script.onload = () => resolve();
      script.onerror = () => reject();

      let body = angular.element('body');

      if (body[0]) {
        body[0].appendChild(script);
      }
      else {
        reject();
      }
    });
  }

  youtubeParser(url){
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  get height() {
    return this._height;
  }

  get width() {
    return this._width;
  }

  get videoUrl() {
    return this._videoUrl;
  }

  get isClasskickIntroVideo() {
    return this._isClasskickIntroVideo;
  }


  /**
   * Shows the video player dialog
   *
   * @param $mdDialog
   * @param videoUrl {string}
   * @returns {Promise}
   */
  static show($mdDialog, videoUrl, isClasskickIntroVideo = false) {
    return $mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: true,
      template: VideoPlayerDialogTemplate,
      controller: VideoPlayerDialogController,
      controllerAs: 'ctrl',
      multiple: true,
      locals: {
        videoUrl,
        isClasskickIntroVideo,
      }
    });
  }

  openInNewTab() {
    this._stopped = true;

    if (this.player && angular.isFunction(this.player.pauseVideo)) {
      this.player.pauseVideo();
    }

    this.$window.open(this._videoUrl);
  }

  cancel() {
    if (this._isClasskickIntroVideo) {
      this._analyticsService.closeClasskickIntroVideoDialog();
    }
    this.$mdDialog.cancel();
  }
}

