
'use strict';

import JSBI from 'jsbi';

export default class CryptoUtils {

  /**
   * Scala implementation of the djb2 hashing algorithm
   * http://www.cse.yorku.ca/~oz/hash.html
   *
   * @param string
   * @returns {bigint}
   */
  static djb2Hash(string) {
    let hash = JSBI.BigInt(5381);
    for (let i = 0; i < string.length; i++) {
      let characterNum = JSBI.BigInt(string.charCodeAt(i));
      let hashMult = JSBI.asUintN(64, JSBI.multiply(hash, JSBI.BigInt(33)));
      hash = JSBI.asUintN(64,  JSBI.add(hashMult, characterNum));
    }
    return JSBI.signedRightShift(hash, JSBI.BigInt(1));
  }
}
