
'use strict';

import Codec from './codec';
import ElementCodec from './element-codec';
import ArchivedData from '../ui/elements/archived-data';

export default class ArchivedDataCodec extends Codec {
  constructor() {
    super();
  }


  /**
   * @param value {Archived}
   * @returns {object}
   */
  encode(value) {
    throw new Error('Encoding of ArchivedData is not supported');
  }

  /**
   * @param value {object}
   * @param key {string}
   * @returns {Object}
   */
  decode(value, key) {
    return new ArchivedData('student', 'work');
  }
}
