'use strict';

import Snap from 'snapsvg-cjs';
import Control from '../../model/ui/control';
import SvgCanvas from '../../model/ui/svg-canvas';
import GestureManager from '../../model/ui/gesture-manager';
import { ToolbarModes } from '../../services/toolbar/toolbar.service';
import Point from '../../model/ui/point';
import Size from '../../model/ui/size';

export class PlacementConfig {

  /**
   * @param element {Element}
   * @param placementFunc {Function}
   * @param [cancelFunc] {Function}
   */
  constructor(element, placementFunc, cancelFunc = () => {}) {
    this._element = element;
    this._placementFunc = placementFunc;
    this._cancelFunc = cancelFunc;
    this._initialPosition = undefined;
    this._currentPosition = undefined;
  }

  get element() {
    return this._element;
  }

  get placementFunc() {
    return this._placementFunc;
  }

  get cancelFunc() {
    return this._cancelFunc;
  }

  get initialPosition() {
    return this._initialPosition;
  }

  set initialPosition(value) {
    this._initialPosition = value;
  }

  get currentPosition() {
    return this._currentPosition;
  }

  set currentPosition(value) {
    this._currentPosition = value;
  }

}

export default class PlacementCapture extends Control {

  constructor(canvas, ToolbarService) {
    super('placementCapture', undefined);

    this._placementCaptureSnap = canvas.layers.placementCapture;

    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;

    this._gesture = new GestureManager(undefined, canvas);
    this._gesture._dragThreshold = 0;
    this._gesture.start(this._placementCaptureSnap.node);
    this._gesture.click.subscribe(this._handleClick, this);
    this._gesture.dragStart.subscribe(this._dragStart, this);
    this._gesture.dragMove.subscribe(this._dragMove, this);
    this._gesture.dragEnd.subscribe(this._dragEnd, this);
  }

  /**
   * @param root {Snap} The snap context
   */
  createElement(root) {
    this._root = root.group();

    this._rect = this._root
      .rect(0, 0, SvgCanvas.INITIAL_WIDTH, SvgCanvas.INITIAL_HEIGHT)
      .addClass(this.id)
      .attr({
        fill: 'transparent',
        visibility: 'hidden'
      });

    this.visibility = 'hidden';
  }

  update() {
    this._rect.attr({
      visibility: this.visibility,
      cursor: this.cursor
    });
  }

  /**
   * @param value {PlacementConfig}
   */
  set config(value) {
    this._config = value;
    this.visibility = 'visible';
  }

  get cursor() {
    return this._config && this._config.element.placement.cursor;
  }

  _handleClick(ev) {
    this._dragStart(ev);
    this._dragEnd(ev);
  }

  _dragStart(ev) {
    if (!this._config) {
      return;
    }

    this._config.initialPosition = ev.offsetLocation;
    this._config.currentPosition = this._config.initialPosition;
    this._config.element.placement.create(this._root, this._config.initialPosition, this._config.currentPosition);
  }

  _dragMove(ev) {
    if (!this._config) {
      return;
    }

    this._config.currentPosition = ev.offsetLocation;
    this._config.element.placement.update(this._root, this._config.initialPosition, this._config.currentPosition);
  }

  _dragEnd(ev) {
    if (!this._config) {
      return;
    }

    this._config.currentPosition = ev.offsetLocation;
    this._config.element.placement.remove();
    this._toolbarService.updateMode(ToolbarModes.Selector);
    this.visibility = 'hidden';

    let initial = this._config.initialPosition;
    let last = this._config.currentPosition;

    let location = new Point(
      initial.x < last.x ? initial.x : last.x,
      initial.y < last.y ? initial.y : last.y
    );

    let width = Math.abs(initial.x - last.x);
    let height = Math.abs(initial.y - last.y);
    let size = new Size(width, height);

    this._config.placementFunc(location, size, initial, last);
    this._config = undefined;
  }

  clear() {
    if (this._config) {
      this._config.cancelFunc();
      this._config = undefined;
      this.visibility = 'hidden';
    }
  }

}
