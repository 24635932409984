
'use strict';

import Codec from './codec';
import ElementMetadata from '../domain/element-metadata';

export default class ElementMetadataCodec extends Codec {

  /**
   * @param value {ElementMetadata}
   * @returns {object}
   */
  encode(value) {
    // Firebase doesn't like object properties which are set to `undefined`
    let result = value.value;
    angular.forEach(result, (value, key) => {
      if (angular.isUndefined(value)) {
        delete result[key];
      }
    });
    return result;
  }

  /**
   * @param value {object}
   * @returns {ElementMetadata}
   */
  decode(value) {
    return ElementMetadata.fromObject(value);
  }
}
