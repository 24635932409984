
'use strict';

import Codec from './codec';
import HelpRequest from '../domain/help-request';

export default class HelpRequestCodec extends Codec {

  constructor() {
    super();
  }

  encode(value) {
    if (value) {
      return {
        id: value.id,
        helpee_id: value.helpeeId,
        roster_id: value.rosterId,
        assignment_id: value.assignmentId,
        question_id: value.questionId,
        request_type: value.requestType,
        created: value.created && value.created.toISOString(),
        resolved: value.resolved && value.resolved.toISOString(),
        helper_id: value.helperId,
        helper_role: value.helperRole
      };
    }

    return null;
  }

  decode(value) {
    if (value) {
      return new HelpRequest(
        value.id,
        value.helpee_id,
        value.roster_id,
        value.assignment_id,
        value.question_id,
        value.request_type,
        value.created,
        value.resolved,
        value.helper_id,
        value.helper_role
      );
    }

    return null;
  }

}
