'use strict';

import { AssignmentQuestionMetadata } from '../../components/assignment-question/assignment-question.directive';
import { UserRoles } from '../../model/domain/user';
import { ElementIntents } from '../../model/domain/element-metadata';
import SignUpOrLoginDialogController from '../../components/sign-up-or-login-dialog/sign-up-or-login-dialog.controller';
import CkRedirect from '../../model/domain/ck-redirect';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';

export default class SharedWorkController {
  constructor($scope, $q, $location, $state, $stateParams, $mdDialog, $window, AuthService,
              CacheService, StorageService, BreadcrumbService, AnalyticsService) {

    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$location = $location;
    this.$stateParams = $stateParams;
    this.$mdDialog = $mdDialog;
    this.$window = $window;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._assignmentWork = undefined;
    this._sharedWorkSessionData = undefined;
    this._assignmentQuestionConfig = undefined;
    this._signUpOrLoginDialog = SignUpOrLoginDialogController.show;
    this._errorDialog = ErrorDialogController.show;

    if (!this._authService.authData) {
      this._storageService.ckRedirect = new CkRedirect('root.shared-work', {
        sharedWorkId: this.$stateParams.sharedWorkId
      }, this.$location.$$path);
      this._storageService.sharedWorkPath = this.$location.$$path;
      this._signUpOrLoginDialog(this.$mdDialog,
          this.closeDialogAndReload.bind(this), this.handleError.bind(this)
      );
    }

    if (this._authService.authData) {

      if (this._authService.authData.isTeacher) {
        this._init();
        if (this._storageService.sharedWorkSignUpAuthType) {
          this._analyticsService.accountCreatedFromSharedWork(this._storageService.sharedWorkSignUpAuthType);
        }
        this._analyticsService.sharedWorkOpened();
      } else {
        this._errorDialog(this.$mdDialog, 'Uh oh! An error occurred', 'Sorry you cannot access this page as a student.')
            .then(() => {
              this._breadcrumbService.go('root.account.home');
            });
      }
      this._storageService.sharedWorkSignUpAuthType = '';
    }
  }

  /**
   * @return {AssignmentQuestionMetadata}
   */
  get assignmentQuestionConfig() {
    return this._assignmentQuestionConfig;
  }

  /**
   * @return {SharedWorkUser}
   */
  get assignmentOwner() {
    return this._assignmentOwner;
  }

  /**
   * @return {AssignmentWork}
   */
  get assignmentWork() {
    return this._assignmentWork;
  }

  /**
   * @return {SharedWorkSessionData}
   */
  get data() {
    return this._sharedWorkSessionData;
  }

  /**
   * @returns {string}
   */
  get helperId() {
    return this._authService.authData ? this._authService.authData.id : '';
  }

  /**
   * @return {string}
   */
  get helpeeId() {
    return this.assignmentWork && this.assignmentWork.ownerId;
  }

  /**
   * @return {string}
   */
  get error() {
    return this._error;
  }

  /**
   * @return {string | null}
   */
  get questionIdForFirstPage() {
    const questionItem = this.data.questions.find((questionObj) => questionObj.number === +1);
    if (questionItem) {
      return questionItem.question.id;
    }
    return null;
  }

  /**
   * @return {string | null}
   */
  get questionId() {
    if (this.$stateParams.questionId && this.$stateParams.questionId !== '1') {
      return this.$stateParams.questionId;
    }
    return this.questionIdForFirstPage;
  }

  /**
   * @return {SharedWorkUser}
   */
  get student() {
    return this._student;
  }

  _init() {
    this._getSharedWork(this.$stateParams.sharedWorkId)
      .then((sharedWork) => {
        this._assignmentWork = sharedWork.assignmentWork;
        this._assignmentOwner = sharedWork.sharedByUser;
        this._student = sharedWork.student;

        this._cacheService.getSharedWorkSessionData(
          this.assignmentOwner,
          this.student,
          this.assignmentWork,
          this.assignmentWork.rosterId,
          false
        ).then((sharedWorkSessionData) => {
          this._sharedWorkSessionData = sharedWorkSessionData;

          this._assignmentQuestionConfig = new AssignmentQuestionMetadata(
            this.assignmentWork,
            this.questionId,
            true,
            false,
            this.helperId,
            UserRoles.TEACHER,
            ElementIntents.VIEW,
            undefined,
            undefined,
            undefined,
            this.data.realOrAnonNameForStudent.bind(this.data),
            this.assignmentOwner,
            this.student
          );
        });
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Ensures the shared work information is the most updated version
   * @param sharedWorkId {string}
   * @return {Promise<SharedWork>}
   */
  _getSharedWork(sharedWorkId) {
    return this._cacheService.getSharedAssignmentWork(sharedWorkId, false)
      .then((sharedWork) => {
        // if the assignment work doesn't have a corresponding question for each question in the assignment, refresh the work
        if (sharedWork.assignmentWork.assignment.questions.some((question) => !sharedWork.assignmentWork.questionForId(question.id))) {
          return this._cacheService.getSharedAssignmentWork(sharedWorkId, true);
        } else {
          return sharedWork;
        }
      });
  }

  closeDialogAndReload(){
    this.$mdDialog.cancel();
    this.$window.location.reload();
  }

  isPermissionError (error) {
    return error.data && error.data.message === 'Invalid Authorization';
  }

  handleError(error) {
    this._error = error ? error : 'Sorry there was an error, please try again. If you continue to have issues send us an email at support@classkick.com.';

    if (this.isPermissionError(error)) {
      this._error = 'Sorry, it looks like you don’t have permission to view this work. Please make sure the email for your account <br> matches the email address in which you received the shared invite link or contact the teacher to request access.';
    }

    this._errorDialog(this.$mdDialog, 'Uh oh! An error occurred', this.error)
      .then(() => {
        if (!this.isPermissionError(error)) {
          this._signUpOrLoginDialog(this.$mdDialog, this.closeDialogAndReload.bind(this), this.handleError.bind(this));
        } else {
          this._breadcrumbService.go('root.account.home');
        }
      });
  }
}
