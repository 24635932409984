import moment from 'moment';

export class HelpArticleCategories {

  static get WhatIsClasskick() {
    return 'what-is-classkick';
  }

  static get GettingStarted() {
    return 'getting-started';
  }

  static get BeyondTheBasics() {
    return 'beyond-the-basics';
  }

  static get Troubleshooting() {
    return 'troubleshooting';
  }

  static get ConnectWithTeacher() {
    return 'connect-with-teacher';
  }

  static get ProfessionalDevelopment() {
    return 'professional-development';
  }

  static get LearnAboutFeatures() {
    return 'learn-about-features';
  }

  static get ClasskickProUsers() {
    return 'classkick-pro-users';
  }

  /**
   * @return {string[]}
   */
  static get All() {
    return [
      HelpArticleCategories.WhatIsClasskick,
      HelpArticleCategories.GettingStarted,
      HelpArticleCategories.BeyondTheBasics,
      HelpArticleCategories.Troubleshooting,
      HelpArticleCategories.ConnectWithTeacher,
      HelpArticleCategories.ProfessionalDevelopment,
      HelpArticleCategories.LearnAboutFeatures,
      HelpArticleCategories.ClasskickProUsers
    ];
  }

  /**
   * @param categoryId
   * @return {boolean}
   */
  static isValid(categoryId) {
    return HelpArticleCategories.All.some((id) => id === categoryId);
  }

}

export const HelpArticleCategoriesDisplayMap = {};

HelpArticleCategoriesDisplayMap[HelpArticleCategories.WhatIsClasskick] = 'What is Classkick?';
HelpArticleCategoriesDisplayMap[HelpArticleCategories.GettingStarted] = 'Getting Started';
HelpArticleCategoriesDisplayMap[HelpArticleCategories.BeyondTheBasics] = 'Beyond the Basics';
HelpArticleCategoriesDisplayMap[HelpArticleCategories.Troubleshooting] = 'Troubleshooting';
HelpArticleCategoriesDisplayMap[HelpArticleCategories.ConnectWithTeacher] = 'Connect with a Classkick Teacher';
HelpArticleCategoriesDisplayMap[HelpArticleCategories.ProfessionalDevelopment] = 'Professional Development Resources';
HelpArticleCategoriesDisplayMap[HelpArticleCategories.LearnAboutFeatures] = 'Learn about Features';
HelpArticleCategoriesDisplayMap[HelpArticleCategories.ClasskickProUsers] = 'Classkick Pro';

export class HelpArticleSections {

  static get WhatIsClasskick_Default() {
    return 'what-is-classkick-default';
  }

  static get WhatIsClasskick_Secondary() {
    return 'what-is-classkick-secondary';
  }

  static get GettingStarted_Edit() {
    return 'getting-started-edit';
  }

  static get GettingStarted_Assign() {
    return 'getting-started-assign';
  }

  static get GettingStarted_View() {
    return 'getting-started-view';
  }

  static get BeyondTheBasics_DeeperFeedback() {
    return 'beyond-the-basics-deeper-feedback';
  }

  static get BeyondTheBasics_MeaningfulAssignments() {
    return 'beyond-the-basics-meaningful-assignments';
  }

  static get BeyondTheBasics_AnalyzeData() {
    return 'beyond-the-basics-analyze-data';
  }

  static get BeyondTheBasics_Collaboration() {
    return 'beyond-the-basics-collaboration';
  }

  static get BeyondTheBasics_Grading() {
    return 'beyond-the-basics-grading';
  }

  static get Troubleshooting_Default() {
    return 'troubleshooting-default';
  }

  static get Troubleshooting_Secondary() {
    return 'troubleshooting-secondary';
  }

  static get ConnectWithTeacher_K_2() {
    return 'k-2';
  }

  static get ConnectWithTeacher_3_5() {
    return '3-5';
  }

  static get ConnectWithTeacher_6_8() {
    return '6-8';
  }

  static get ConnectWithTeacher_9_12() {
    return '9-12';
  }

  static get ProfessionalDevelopment_PresentationMaterials() {
    return 'professional-development-presentation-materials';
  }

  static get ProfessionalDevelopment_BrandAssets() {
    return 'professional-development-brand-assets';
  }

  static get LearnAboutFeatures_Toolbar() {
    return 'learn-about-features-toolbar';
  }

  static get LearnAboutFeatures_GradingAndFeedback() {
    return 'learn-about-features-grading-and-feedback';
  }

  static get LearnAboutFeatures_Filters() {
    return 'learn-about-features-filters';
  }

  static get LearnAboutFeatures_Export() {
    return 'learn-about-features-export';
  }

  static get LearnAboutFeatures_More() {
    return 'learn-about-features-more';
  }

  static get ClasskickProUsers_WhatIsPro() {
    return 'classkick-pro-users-what-is-pro';
  }

  static get ClasskickProUsers_Admins() {
    return 'classkick-pro-users-admins';
  }

  static get ClasskickProUsers_Teachers() {
    return 'classkick-pro-users-teachers';
  }

  static get ClasskickProUsers_Students() {
    return 'classkick-pro-users-students';
  }

  /**
   * @return {string[]}
   */
  static get All() {
    return [
      HelpArticleSections.WhatIsClasskick_Default,
      HelpArticleSections.WhatIsClasskick_Secondary,
      HelpArticleSections.GettingStarted_Edit,
      HelpArticleSections.GettingStarted_Assign,
      HelpArticleSections.GettingStarted_View,
      HelpArticleSections.BeyondTheBasics_DeeperFeedback,
      HelpArticleSections.BeyondTheBasics_MeaningfulAssignments,
      HelpArticleSections.BeyondTheBasics_AnalyzeData,
      HelpArticleSections.BeyondTheBasics_Collaboration,
      HelpArticleSections.BeyondTheBasics_Grading,
      HelpArticleSections.Troubleshooting_Default,
      HelpArticleSections.Troubleshooting_Secondary,
      HelpArticleSections.ConnectWithTeacher_K_2,
      HelpArticleSections.ConnectWithTeacher_3_5,
      HelpArticleSections.ConnectWithTeacher_6_8,
      HelpArticleSections.ConnectWithTeacher_9_12,
      HelpArticleSections.ProfessionalDevelopment_PresentationMaterials,
      HelpArticleSections.ProfessionalDevelopment_BrandAssets,
      HelpArticleSections.LearnAboutFeatures_Toolbar,
      HelpArticleSections.LearnAboutFeatures_GradingAndFeedback,
      HelpArticleSections.LearnAboutFeatures_Filters,
      HelpArticleSections.LearnAboutFeatures_Export,
      HelpArticleSections.LearnAboutFeatures_More,
      HelpArticleSections.ClasskickProUsers_WhatIsPro,
      HelpArticleSections.ClasskickProUsers_Admins,
      HelpArticleSections.ClasskickProUsers_Teachers,
      HelpArticleSections.ClasskickProUsers_Students
    ];
  }

  /**
   * @param sectionId
   * @return {boolean}
   */
  static isValid(sectionId) {
    return HelpArticleSections.All.some((id) => id === sectionId);
  }

}

export const HelpArticleSectionsDisplayMap = {};

HelpArticleSectionsDisplayMap[HelpArticleSections.WhatIsClasskick_Default] = '';
HelpArticleSectionsDisplayMap[HelpArticleSections.WhatIsClasskick_Secondary] = '';
HelpArticleSectionsDisplayMap[HelpArticleSections.GettingStarted_Edit] = 'Create and edit your Assignment';
HelpArticleSectionsDisplayMap[HelpArticleSections.GettingStarted_Assign] = 'Assign a roster to get your students working';
HelpArticleSectionsDisplayMap[HelpArticleSections.GettingStarted_View] = 'See students work and provide real-time feedback';
HelpArticleSectionsDisplayMap[HelpArticleSections.BeyondTheBasics_DeeperFeedback] = 'Deeper Feedback';
HelpArticleSectionsDisplayMap[HelpArticleSections.BeyondTheBasics_MeaningfulAssignments] = 'Create more meaningful assignments';
HelpArticleSectionsDisplayMap[HelpArticleSections.BeyondTheBasics_AnalyzeData] = 'Analyze data in-the-moment';
HelpArticleSectionsDisplayMap[HelpArticleSections.BeyondTheBasics_Collaboration] = 'Collaboration';
HelpArticleSectionsDisplayMap[HelpArticleSections.BeyondTheBasics_Grading] = 'Grade Faster';
HelpArticleSectionsDisplayMap[HelpArticleSections.Troubleshooting_Default] = '';
HelpArticleSectionsDisplayMap[HelpArticleSections.Troubleshooting_Secondary] = '';
HelpArticleSectionsDisplayMap[HelpArticleSections.ConnectWithTeacher_K_2] = 'K-2';
HelpArticleSectionsDisplayMap[HelpArticleSections.ConnectWithTeacher_3_5] = '3-5';
HelpArticleSectionsDisplayMap[HelpArticleSections.ConnectWithTeacher_6_8] = '6-8';
HelpArticleSectionsDisplayMap[HelpArticleSections.ConnectWithTeacher_9_12] = '9-12';
HelpArticleSectionsDisplayMap[HelpArticleSections.ProfessionalDevelopment_PresentationMaterials] = 'Presentation Materials';
HelpArticleSectionsDisplayMap[HelpArticleSections.ProfessionalDevelopment_BrandAssets] = 'Brand Assets';
HelpArticleSectionsDisplayMap[HelpArticleSections.LearnAboutFeatures_Toolbar] = 'Toolbar';
HelpArticleSectionsDisplayMap[HelpArticleSections.LearnAboutFeatures_GradingAndFeedback] = 'Grading & Feedback Tools';
HelpArticleSectionsDisplayMap[HelpArticleSections.LearnAboutFeatures_Filters] = 'Filters';
HelpArticleSectionsDisplayMap[HelpArticleSections.LearnAboutFeatures_Export] = 'Export Tools';
HelpArticleSectionsDisplayMap[HelpArticleSections.LearnAboutFeatures_More] = 'More Features';
HelpArticleSectionsDisplayMap[HelpArticleSections.ClasskickProUsers_WhatIsPro] = 'What is Pro?';
HelpArticleSectionsDisplayMap[HelpArticleSections.ClasskickProUsers_Admins] = 'School and District Admin';
HelpArticleSectionsDisplayMap[HelpArticleSections.ClasskickProUsers_Teachers] = 'Teachers';
HelpArticleSectionsDisplayMap[HelpArticleSections.ClasskickProUsers_Students] = 'Students';


export default class HelpArticle {

  constructor(id, categoryId, sectionId, title, body, priority, ownerId, created, modified) {
    this._id = id;
    this._categoryId = categoryId;
    this._sectionId = sectionId;
    this._title = title;
    this._body = body;
    this._priority = priority;
    this._ownerId = ownerId;
    this._created = created ? moment(created) : undefined;
    this._modified = modified ? moment(modified) : undefined;
  }

  /**
   * @return {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @return {string}
   */
  get categoryId() {
    return this._categoryId;
  }

  /**
   * @return {string}
   */
  get sectionId() {
    return this._sectionId;
  }

  /**
   * @return {string}
   */
  get title() {
    return this._title;
  }

  /**
   * @param value {string}
   */
  set title(value) {
    this._title = value;
  }

  /**
   * @return {string}
   */
  get body() {
    return this._body;
  }

  /**
   * @param value {string}
   */
  set body(value) {
    this._body = value;
  }

  /**
   * @return {number}
   */
  get priority() {
    return this._priority;
  }

  /**
   * @param value {number}
   */
  set priority(value) {
    this._priority = value;
  }

  /**
   * @return {string}
   */
  get ownerId() {
    return this._ownerId;
  }

  /**
   * @return {moment}
   */
  get created() {
    return this._created;
  }

  /**
   * @return {moment}
   */
  get modified() {
    return this._modified;
  }

  /**
   * @return {string}
   */
  get categoryDisplay() {
    return HelpArticleCategoriesDisplayMap[this._categoryId];
  }

}
