'use strict';

import moment from 'moment';

export class ChatMessageTypes {

  static get HELP() {
    return 'help';
  }

  static get CHECK() {
    return 'check';
  }

  static get HAND_DOWN() {
    return 'hand_down';
  }

  static get MESSAGE() {
    return 'message';
  }

  static get ALL() {
    return [
      ChatMessageTypes.HELP,
      ChatMessageTypes.CHECK,
      ChatMessageTypes.HAND_DOWN,
      ChatMessageTypes.MESSAGE
    ];
  }

}

export default class ChatMessage {

  /**
   * @param id {string}
   * @param type {string}
   * @param userId {string}
   * @param displayName {string}
   * @param text {string}
   * @param timestamp {string}
   */
  constructor(id, type, userId, displayName, text, timestamp) {
    this._id = id;
    this._type = type;
    this._userId = userId;
    this._displayName = displayName;
    this._text = text;
    this._timestamp = timestamp ? moment(timestamp) : undefined;
  }

  get id() {
    return this._id;
  }

  get type() {
    return this._type;
  }

  get userId() {
    return this._userId;
  }

  get displayName() {
    return this._displayName;
  }

  get text() {
    return this._text;
  }

  get timestamp() {
    return this._timestamp;
  }

  get isCheck() {
    return this.type === ChatMessageTypes.CHECK;
  }

  get isHelp() {
    return this.type === ChatMessageTypes.HELP;
  }

  get isHandDown() {
    return this.type === ChatMessageTypes.HAND_DOWN;
  }

  get isMessage() {
    return this.type === ChatMessageTypes.MESSAGE;
  }

}
