'use strict';

import UserSticker from '../../model/domain/user-sticker';
import SelectFeedbackApproachTemplate from './select-bulk-update-menu.html';
import { ViewHelps } from '../view-help-dialog/view-help-dialog.controller';
import ViewHelpDialogController from '../view-help-dialog/view-help-dialog.controller';
import {Locations} from '../../services/mixpanel/mixpanel.service';

export class BulkMenuOptions {
  static get ALL_STUDENTS_ONE_QUESTION() {
    return {
      COPY: 'Apply to column',
      TYPE: 'all_students_one_question'
    };
  }

  static get ONE_STUDENT_ALL_QUESTIONS() {
    return {
      COPY: 'Apply to row',
      TYPE: 'one_student_all_questions'
    };
  }

  static get CHOOSE_STUDENTS_AND_QUESTIONS() {
    return {
      COPY: 'Apply to selected students and questions',
      TYPE: 'selected_students_and_questions'
    };
  }

  static get CLEAR_BULK_UPDATE_SELECTION() {
    return {
      COPY: 'Clear bulk update selection',
      TYPE: 'clear_bulk_update_selection'
    };
  }
}

export class SelectBulkUpdateMenuController {
  /**
   * @ngInject
   */
  constructor($mdDialog, $location, AnalyticsService, mdPanelRef, deferred, selectedBulkUpdate) {
    this.$location = $location;
    this.$mdDialog = $mdDialog;

    this._analyticsService = AnalyticsService;

    this._mdPanelRef = mdPanelRef;
    this._deferred = deferred;
    this._selectedBulkUpdate = selectedBulkUpdate;
    this._bulkUpdateOptions = this.$location.$$url.includes('work')
      ? [BulkMenuOptions.ALL_STUDENTS_ONE_QUESTION, BulkMenuOptions.ONE_STUDENT_ALL_QUESTIONS, BulkMenuOptions.CHOOSE_STUDENTS_AND_QUESTIONS, BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION]
      : [BulkMenuOptions.ALL_STUDENTS_ONE_QUESTION, BulkMenuOptions.CHOOSE_STUDENTS_AND_QUESTIONS, BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION];
    this._analyticsService.bulkUpdateOpened(this.viewType);

    this._helpDialog = ViewHelpDialogController.show;
  }


  /**
   * @return {boolean}
   */
  get bulkUpdateOptions() {
    return this._bulkUpdateOptions;
  }

  /**
   * @return {boolean}
   */
  get selectedBulkUpdate() {
    return this._selectedBulkUpdate;
  }

  /**
   * @param value {boolean}
   */
  set selectedBulkUpdate(value) {
    this._selectedBulkUpdate = value;
  }

  get viewType() {
    return this.$location.$$url.includes('work') ? 'wcv':'sqv';
  }

  select(bulkUpdateOption) {
    this._analyticsService.bulkUpdateSelected(bulkUpdateOption, this.viewType);
    this.selectedBulkUpdate = bulkUpdateOption;
    this._deferred.resolve({
      bulkUpdateOption
    });
    this._mdPanelRef.hide();
  }

  cancel() {
    this._deferred.reject();
    this._mdPanelRef.hide();
  }

  showSelected(option){
    return (option.TYPE === this.selectedBulkUpdate)
      && ((this.selectedBulkUpdate !== BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION.TYPE)
      && (this.selectedBulkUpdate !== undefined));
  }

  showBulkUpdateHelp() {
    this.cancel();
    this._analyticsService.infoIconClicked(Locations.ASSIGNMENT_VIEW_WORK, 'bulkUpdate');
    this._helpDialog(this.$mdDialog, ViewHelps.BulkUpdate);
  }


  /**
   * @param $mdPanel
   * @param $q
   * @param $event
   * @param stickers {UserSticker[]}
   * @param [source] {string} where the sidenav is being opened from - session_question
   * @return {Promise}
   */
  static show($mdPanel, $q, $event, selectedBulkUpdate) {

    const position = $mdPanel.newPanelPosition()
      .relativeTo($event.currentTarget)
      .addPanelPosition(
        $mdPanel.xPosition.ALIGN_START,
        $mdPanel.yPosition.ALIGN_TOPS
      );

    const deferred = $q.defer();

    $mdPanel.open({
      position,
      animation: $mdPanel.newPanelAnimation()
        .openFrom($event.currentTarget)
        .duration(100)
        .withAnimation($mdPanel.animation.SCALE),
      template: SelectFeedbackApproachTemplate,
      controller: SelectBulkUpdateMenuController,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      panelClass: 'select-bulk-update-menu',
      locals: {
        deferred,
        selectedBulkUpdate
      },
      onRemoving: () => {
        deferred.reject();
      }
    });

    return deferred.promise;
  }
}
