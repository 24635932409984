'use strict';

import { LoginSources } from '../login/login.controller';
import { AccountCreationStep } from '../../services/mixpanel/mixpanel.service';
import AccountNotification from '../../model/domain/account-notification';
import Organization, { OrganizationTypes } from '../../model/domain/organization';

export default class SignupSchoolController {

  constructor($q, $timeout, $log, BreadcrumbService, AuthService, AnalyticsService, CacheService, StorageService,
              NotificationService, ToolbarService, IPStackService, OrganizationService, GooglePlacesService, environment) {
    'ngInject';

    this.$q = $q;
    this.$timeout = $timeout;
    this.$log = $log;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {IPStackService} */
    this._ipStackService = IPStackService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {GooglePlacesService} */
    this._googlePlacesService = GooglePlacesService;

    this._environment = environment;

    this._isLoading = false;
    this._state = this.JoinSchool;

    this._schoolName = '';
    this._school = undefined;

    this._newSchoolLocationDisplay = '';
    this._newSchoolLocation = undefined;

    this.cleverSelected = false;

    this._ckRedirect = this._storageService.ckRedirect;
    this._accountInitPromise = undefined;
    this._tokenDeferred = this.$q.defer();
    this._token = this._tokenDeferred.promise;
    this._hasSentAnalyticsSchool = false;
    this.organizationType = OrganizationTypes.UserDefinedOrganizationTypes[0];

    if (!this._authService.isLoggedIn) {
      this._breadcrumbService.goBack('root.signup-teacher');
    }

    this._analyticsService.sendEvent({
      eventTag: 'page_viewed',
      properties: {
          location: 'teacherSignUpSchool'
      }
    });
  }

  /**
   * @return {Array}
   */
  get organizationOptions() {
    return OrganizationTypes.UserDefinedOrganizationTypes;
  }

  /**
   * @return {boolean}
   */
  get isLoading() {
    return this._isLoading;
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @param value {string}
   */
  set state(value) {
    this._state = value;
  }

  get JoinSchool() {
    return 'join_school';
  }

  get AddSchool() {
    return 'add_school';
  }

  /**
   * @return {string}
   */
  get schoolName() {
    return this._schoolName;
  }

  /**
   * @param value {string}
   */
  set schoolName(value) {
    this._schoolName = value;
  }

  /**
   * @return {undefined|Organization}
   */
  get school() {
    return this._school;
  }

  /**
   * @param value {undefined|Organization}
   */
  set school(value) {
    this._school = value;
  }

  /**
   * @return {string}
   */
  get newSchoolLocationDisplay() {
    return this._newSchoolLocationDisplay;
  }

  /**
   * @param value {string}
   */
  set newSchoolLocationDisplay(value) {
    this._newSchoolLocationDisplay = value;
  }

  /**
   * @return {undefined|GooglePlacePrediction}
   */
  get newSchoolLocation() {
    return this._newSchoolLocation;
  }

  /**
   * @param value {undefined|GooglePlacePrediction}
   */
  set newSchoolLocation(value) {
    this._newSchoolLocation = value;
  }

  /**
   * fieldUpdate to perform extra validation checks
   *
   * @param field - information about the field
   * @param form - the form
   * @param skip
   */
  fieldUpdated(field, form, skip) {
    if (skip) {
      return;
    }

    field.$touched = true;

    if (!this._hasSentAnalyticsSchool) {
      this._analyticsService.sendEvent({
        eventTag: 'teacherSignUpSchool:organizationName_typed'
      });
      this._hasSentAnalyticsSchool = true;
    }

    this.cleverSelected = (this.school || {}).hasCleverProvider === true;
    if (this.cleverSelected) {
      form.schoolNameInput.$setValidity('md-clever-org', false);
    } else {
      form.schoolNameInput.$setValidity('md-clever-org', true);
    }

    if (form.$valid) {
      return true;
    } else {
      return false;
    }
  }

  joinSchool(scope) {
    this._analyticsService.sendEvent({
      eventTag: 'teacherSignUpSchool:joinOrganization_clicked'
    });

    if (this.cleverSelected || scope.joinSchoolForm.$invalid) {
      return;
    }

    this._isLoading = true;

    this._joinSchool(this.school.id);
  }

  addSchool(scope) {

    // Check that the input form is valid
    if (scope.enterSchoolDetailsForm.$invalid) {
      return;
    }

    this._isLoading = true;

    this._createSchool(this.newSchoolLocation.placeId, this.schoolName)
      .then((organization) => {
        this._joinSchool(organization.id);
      });
  }

  /**
   * Adds the current user to an organization
   * @param organizationId {string}
   */
  _joinSchool(organizationId) {
    this._organizationService.addSelf(organizationId)
      .then((token) => {
        this._tokenDeferred.resolve(token);

        this._accountInitPromise = this.$timeout(() => {
          this.go();
        }, this._environment.accountInitTimeout);

        // register for account init notification
        this._accountNotification = this._notificationService.getAccountNotification(this._authService.currentUserId);
        this._accountNotification.accountInitialized.subscribe(this._onAccountInit, this);
        this._accountNotification.start();

        this._cacheService.reset();

        this._analyticsService.createAccountProgress(AccountCreationStep.SCHOOL_NAME);
        this._analyticsService.createAccountProgress(AccountCreationStep.COMPLETE);
      })
      .catch((err) => {
        this._tokenDeferred.reject();

        if (!this._accountInitPromise) {
          this.go();
        }
        this.$log.error(err);
      });
  }

  /**
   * @param placeId {string}
   * @param schoolName {string}
   * @return {Promise<Organization>}
   */
  _createSchool(placeId, schoolName) {
    return this._googlePlacesService.getPlaceDetails(placeId).then((place) => {
      return this._organizationService.create(
        OrganizationTypes.School,
        schoolName,
        place.lat,
        place.lng,
        place.zip,
        place.city,
        place.state,
        place.country,
        this.organizationType.value
      );
    });
  }

  go() {
    this._token
      .then((token) => {
        return this._authService.processTokenResult(token, this._authService.rememberMe);
      })
      .then(() => {
        this._onSuccess();
      })
      .catch(() => {
        this._onSuccess();
      });
  }

  _onSuccess() {
    this._toolbarService.reset();
    this._breadcrumbService.clear();

    if (this._ckRedirect) {
      this._ckRedirect.redirect(this._breadcrumbService);
      this._storageService.ckRedirect = null;
    }
    else {
      this._breadcrumbService.go('root.refer');
    }
  }

  goBack() {
    this._breadcrumbService.goBack('root.signup-name');
  }

  /**
   * Stop listening for account notifications
   */
  _destroy() {
    if (this._accountNotification) {
      this._accountNotification.updated.unsubscribe(this._onAccountInit, this);
      this._accountNotification.stop();
    }
  }

  /**
   * @param search {string}
   * @return {Array|Promise<GooglePlacePrediction[]>}
   */
  autocompleteLocation(search) {
    if (!search || search.length < 2) {
      return [];
    }
    else {
      this._loadingAutocompleteLocation = true;
      return this._googlePlacesService.getPredictions(search)
        .then((results) => {
          this._loadingAutocompleteLocation = false;
          return results;
        });
    }
  }

  get loadingAutocompleteLocation() {
    return this._loadingAutocompleteLocation;
  }

  /**
   * @param search {string}
   * @return {Promise<Organization[]>}
   */
  autocompleteSchool(search) {
    this._loadingAutocompleteSchool = true;
    return this._ipStackService.getLocation()
      .then((location) => {
        return this._organizationService.search(OrganizationTypes.School, location.lat, location.lng, search);
      })
      .then((organizations) => {
        this._loadingAutocompleteSchool = false;
        // Hack: Adds an extract empty object for the 'add new school' button
        return [...organizations, {}];
      });
  }

  get loadingAutocompleteSchool() {
    return this._loadingAutocompleteSchool;
  }

  isOrganization(obj) {
    return obj instanceof Organization;
  }

  /**
   * Callback for a Firebase listener that listens for when the account is initialized.
   * @param value
   * @private
   */
  _onAccountInit(value) {
    if (value.change === AccountNotification.ACCOUNT_INITIALIZED) {
      this.$timeout.cancel(this._accountInitPromise);
      this._accountInitPromise = null;
      this.go();
    }
  }
}
