'use strict';

import ArticleAutocompleteTemplate from './article-autocomplete.component.html';

class ArticleAutocompleteController {

  /* @ngInject */
  constructor($state, HelpArticleService) {
    this.$state = $state;

    /** @type {HelpArticleService} */
    this._helpArticleService = HelpArticleService;

    this._query = '';
    this._article = undefined;
    this._loadingAutocompleteHelpArticles = false;
  }

  get loadingAutocompleteHelpArticles() {
    return this._loadingAutocompleteHelpArticles;
  }

  get query() {
    return this._query;
  }

  set query(value) {
    this._query = value;
  }

  get article() {
    return this._article;
  }

  set article(value) {
    if (value) {
      this.goToArticle(value.id);
    }
  }

  goToArticle(articleId) {
    this.$state.go('root.help-article', {
      articleId
    });
  }

  autocompleteHelpArticles(query) {
    if (!query || query.length < 2) {
      return [];
    }
    else {
      this._loadingAutocompleteHelpArticles = true;
      return this._helpArticleService.search(query)
        .then((results) => {
          this._loadingAutocompleteHelpArticles = false;
          return results;
        });
    }
  }

}

export default {
  template: ArticleAutocompleteTemplate,
  controller: ArticleAutocompleteController,
  controllerAs: 'ctrl'
};


