'use strict';

import FeedbackMenuTemplate from './feedback-menu.html';

export class FeedbackMenuController {
  /**
   * @ngInject
   */
  constructor($timeout, mdPanelRef, AnalyticsService, AnalyticsMetaService, deferred, feedbackList, assignmentWork) {
    this.$timeout = $timeout;
    this._mdPanelRef = mdPanelRef;
    this._deferred = deferred;
    this._feedbackList = feedbackList;
    this._assignmentWork = assignmentWork;


    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {AnalyticsMetaService} */
    this._analyticsMetaService = AnalyticsMetaService;

    this._questionFeedbacks = [];

    this.init();
  }

  init() {
    this._onUpdate();
    this._feedbackList.updated.subscribe(this._onUpdate, this);
  }

  _onUpdate() {
    let validFeedbacks = this._feedbackList.validFeedbacks;
    if (this._questionFeedbacks.length !== validFeedbacks.length) {
      this._questionFeedbacks = validFeedbacks.sort((a, b) => this._sortFeedbacksByQuestionNumber(a, b));
      this.$timeout(() => {});
    }
  }

  /**
   * @return {QuestionFeedback[]}
   */
  get questionFeedbacks() {
    return this._questionFeedbacks;
  }

  /**
   * @param a {QuestionFeedback}
   * @param b {QuestionFeedback}
   * @return {number}
   */
  _sortFeedbacksByQuestionNumber(a, b) {
    let aQuestionNumber = this.questionNumber(a.id);
    let bQuestionNumber = this.questionNumber(b.id);

    if (aQuestionNumber < bQuestionNumber) {
      return -1;
    }
    else if (aQuestionNumber > bQuestionNumber) {
      return 1;
    }
    else {
      return 0;
    }
  }

  /**
   * @return {boolean}
   */
  get hasFeedback() {
    return this.questionFeedbacks && this.questionFeedbacks.length > 0;
  }

  /**
   * @param questionId {string}
   * @return {number}
   */
  questionNumber(questionId) {
    return this._assignmentWork.questionNumberForId(questionId);
  }

  /**
   * @param questionId {string}
   */
  goToQuestion(questionId) {
    this._deferred.resolve({
      questionId
    });
    this._mdPanelRef.hide();

    this._analyticsService.sendEvent({
      eventTag: 'feedback:student_navigated',
      properties: this._analyticsMetaService.buildFeedbackNavigatedMeta(this._assignmentWork)
    });
  }

  /**
   * @param $mdPanel
   * @param $q
   * @param $event
   * @param feedbackList {QuestionFeedbackList}
   * @param assignmentWork {AssignmentWork}
   *
   * @return {Promise.<object>}
   */
  static show($mdPanel, $q, $event, feedbackList, assignmentWork) {

    const position = $mdPanel.newPanelPosition()
      .relativeTo($event.currentTarget)
      .addPanelPosition(
        $mdPanel.xPosition.ALIGN_START,
        $mdPanel.yPosition.ALIGN_BOTTOMS
      );

    const animation = $mdPanel.newPanelAnimation()
      .openFrom($event.currentTarget)
      .duration(100)
      .withAnimation($mdPanel.animation.SCALE);

    const deferred = $q.defer();

    $mdPanel.open({
      position,
      animation,
      template: FeedbackMenuTemplate,
      controller: FeedbackMenuController,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      panelClass: 'feedback-menu md-whiteframe-2dp',
      locals: {
        deferred,
        feedbackList,
        assignmentWork
      },
      onRemoving: () => {
        deferred.reject();
      }
    });

    return deferred.promise;
  }
}
