import ImportImageDialogController from '../../components/import-image-dialog/import-image-dialog.controller';
import MimeTypes from '../../model/domain/mime-types';
'use strict';
export default class GooglePickerService {
    constructor($window, $q, environment, $mdDialog, $log, $rootScope, AnalyticsService) {
        'ngInject';
        this.$window = $window;
        this.$q = $q;
        this.$mdDialog = $mdDialog;
        this.$log = $log;
        this.$rootScope = $rootScope;
        this._environment = environment;


        /** @type {AnalyticsService} */
        this._analyticsService = AnalyticsService;

        this.developerKey = this._environment.google.auth.apiKey;
        this.clientId = this._environment.google.auth.clientId;
        this.scope = [
            'https://www.googleapis.com/auth/drive.file'
        ];
        this.pickerApiLoaded = false;
        this.oauthToken = null;
        this.authInstance = null;
        this.$rootScope.importAssignment = false;
        this.$rootScope.importError = false;

        this.$window.addEventListener('message', this.handleMessage.bind(this), false);
    }

    init(importResultCallback) {
        const deferred = this.$q.defer();
        this.loadPicker(deferred);
        this.importResultCallback = importResultCallback;
        return deferred.promise;
    }

    loadPicker(deferred) {
        this.$window.gapi.load('auth2', { 'callback': () => this.onAuthApiLoad(deferred) });
        this.$window.gapi.load('picker', { 'callback': () => this.onPickerApiLoad() });
    }

    onAuthApiLoad(deferred) {
        try {
            this.authInstance = this.$window.gapi.auth2.getAuthInstance();
        } catch (e) {
            this.$log.error('Auth2 instance not found. Initializing auth2...');
        }

        if (!this.authInstance) {
            this.authInstance = this.$window.gapi.auth2.init({
                client_id: this.clientId,
                scope: this.scope.join(' '),
            });
        }

        this.authInstance.signIn({
            prompt: 'select_account',
            scope: this.scope.join(' ')
        }).then((user) => {
            const authResult = user.getAuthResponse();
            if (authResult && authResult.access_token) {
                this.oauthToken = authResult.access_token;
                this.createPicker(deferred);
                deferred.resolve(this.oauthToken);
            } else {
                this.$log.error('Authorization failed: No access token received.');
                deferred.reject('Authorization failed');
            }
        }).catch((error) => {
            this.$log.error('Error during sign-in:', error);
            deferred.reject(error);
        });
    }

    onPickerApiLoad() {
        this.pickerApiLoaded = true;
    }

    createPicker(deferred) {
        this.authInstance.isSignedIn.listen(this.handleAuthChange.bind(this));

        if (this.pickerApiLoaded && this.oauthToken) {
            const picker = new this.$window.google.picker.PickerBuilder()
                .addView(
                    new this.$window.google.picker.DocsView(this.$window.google.picker.ViewId.DOCS)
                        .setIncludeFolders(true)
                        .setMimeTypes('application/pdf,image/png,image/jpeg,image/jpg,image/gif,application/vnd.google-apps.presentation')
                )
                .addView(
                    new this.$window.google.picker.DocsView(this.$window.google.picker.ViewId.PRESENTATIONS)
                        .setIncludeFolders(true)
                )
                .setOAuthToken(this.oauthToken)
                .setDeveloperKey(this.developerKey)
                .setCallback((data) => this.pickerCallback(deferred, data))
                .build();

            picker.setVisible(true);
            this.$window.parent.postMessage({ action: 'picker_loaded' }, '*');
        } else {
            this.$log.error('Picker API not loaded or OAuth token missing');
            deferred.reject('Picker API not loaded or OAuth token missing');
        }
    }

    async fetchFileContent(fileId, mimeType) {
        let url;
        var mimeTypeRecord = '';
        if (mimeType === 'application/vnd.google-apps.presentation') {
            // Export Google Slides as PDF
            url = `https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=application/pdf`;
            mimeTypeRecord = 'Google Slides';
        } else {
            // Direct download for other files
            url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
            mimeTypeRecord = mimeType;
        }

        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${this.oauthToken}`,
            },
        });

        if (!response.ok) {
            this._analyticsService.errorImportingFileFromGoogleDrive(mimeType);        
            throw new Error(`Error downloading file content: ${response.statusText}`);
        }
        this._analyticsService.successImportingFileFromGoogleDrive(mimeType);
        return await response.blob();
    }

    async createFileObject(file) {
        try {
            const mimeType = file.mimeType || 'application/octet-stream';
            // Fetch the file content as a Blob
            const fileContent = await this.fetchFileContent(file.id, mimeType);

            // Ensure output is named appropriately
            const fileName = mimeType === 'application/vnd.google-apps.presentation'
                ? `${file.name}.pdf` // Add .pdf for Google Slides
                : file.name;

            // Create a new File object
            const fileObject = new File(
                [fileContent],
                fileName,
                {
                    type: 'application/pdf', // Enforce PDF output for Slides and PDFs
                    lastModified: new Date(file.modifiedTime).getTime()
                }
            );

            return fileObject;
        } catch (error) {
            this.$log.error('Error creating File object:', error);
            throw error;
        }
    }

    async pickerCallback(deferred, data) {
        if (data[this.$window.google.picker.Response.ACTION] === this.$window.google.picker.Action.PICKED) {
            const file = data[this.$window.google.picker.Response.DOCUMENTS][0];

            try {
                this.$rootScope.importAssignment = true;
                const fileObject = await this.createFileObject(file);
                this.$rootScope.importAssignment = false;

                this.$window.parent.postMessage({ action: 'picked', documents: [fileObject] }, '*');

                const importResult = ImportImageDialogController.show(
                    this.$q,
                    this.$mdDialog,
                    [],
                    true,
                    true,
                    undefined,
                    undefined,
                    MimeTypes.ImageSupport,
                    fileObject,
                );

                this.importResultCallback(importResult);
            } catch (error) {
                this.$log.error('Error creating File object:', error);
                this.$rootScope.importAssignment = false;
                this.$rootScope.importError = true;
                deferred.reject('Error creating File object');
            }
        } else if (data[this.$window.google.picker.Response.ACTION] === this.$window.google.picker.Action.CANCEL) {
            deferred.reject('Picker canceled');
        } else {
            this.$log.error('Unknown picker action:', data);
            deferred.reject('Unknown picker action');
        }
    }

    handleMessage(event) {
        if (event.origin !== window.location.origin) {
            return;
        }

        if (event.data) {
            if (event.data.action === 'picked') {
                const file = event.data.documents[0];
            }
        }
    }

    handleAuthChange(isSignedIn) {
        if (!isSignedIn) {
            this.oauthToken = null;
        } else {
            this.authInstance.currentUser.get().reloadAuthResponse().then((response) => {
                this.oauthToken = response.access_token;
            }).catch((error) => {
                this.$log.error('Error refreshing OAuth token:', error);
            });
        }
    }
}
