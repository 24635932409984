import emojiSelector from './panels/emoji-selection.html';
import fontSelector from './panels/font-family-selection.html';
import highlighterColor from './panels/highlighter-color.html';
import hightlighterWidth from './panels/highlighter-width.html';
import imageMenu from './panels/image-menu.html';
import penColor from './panels/pen-color.html';
import penWidth from './panels/pen-width.html';
import straightLineColor from './panels/straight-line-color.html';
import straightLineWidth from './panels/straight-line-width.html';
import textBackgroundColor from './panels/text-background-color.html';
import textBorderColor from './panels/text-border-color.html';
import textColor from './panels/text-color.html';

export default class ToolbarPanelManager {

  constructor($mdPanel, toolbarService) {
    this.$mdPanel = $mdPanel;
    this._toolbarService = toolbarService;

    this._openToolPanel = undefined;
    this._openMorePanel = undefined;
  }

  openToolPanel(ev, panelId, ctrl, config = {}) {
    const toolbarTemplates = {
      'emoji-selector': emojiSelector,
      'font-selector': fontSelector,
      'highlighter-color': highlighterColor,
      'highlighter-width': hightlighterWidth,
      'image-menu': imageMenu,
      'pen-color': penColor,
      'pen-width': penWidth,
      'straight-line-color': straightLineColor,
      'straight-line-width': straightLineWidth,
      'text-background-color': textBackgroundColor,
      'text-border-color': textBorderColor,
      'text-color': textColor
    };

    this._openToolPanel = this._openPanel(ev, panelId, {
      template: toolbarTemplates[panelId],
      controller: ($scope) => {
        'ngInject';
        $scope.parent = ctrl;
        $scope.penWidth = ctrl.penWidth;
        $scope.highlighterWidth = ctrl.highlighterWidth;
        $scope.straightLineWidth = ctrl.straightLineWidth;
      },
      panelClass: `assignment-toolbar tool-panel ${panelId}`,
      ...config
    });
  }

  closeToolPanel() {
    if (this._openToolPanel) {
      this._openToolPanel.then((mdPanelRef) => {
        mdPanelRef.close();
      });
      this._openToolPanel = null;
    }
  }

  openMorePanel(ev) {
    this._openMorePanel = this._openPanel(ev, 'more', {
      contentElement: '.assignment-toolbar .rendered-panels .more',
      panelClass: 'assignment-toolbar tool-panel'
    });
  }

  closeMorePanel() {
    if (this._openMorePanel) {
      this._openMorePanel.then((mdPanelRef) => {
        mdPanelRef.close();
      });
      this._openMorePanel = null;
    }
  }

  /**
   * @param ev {MouseEvent}
   * @param panelId {string}
   * @param config {object}
   */
  _openPanel(ev, panelId, config = {}) {
    ev.originalEvent.preventElementBlur = true;

    return this.$mdPanel.open({
      id: panelId,
      escapeToClose: true,
      clickOutsideToClose: true,
      focusOnOpen: true,
      position: this.$mdPanel.newPanelPosition()
        .relativeTo(ev.currentTarget)
        .addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.BELOW),
      animation: this.$mdPanel.newPanelAnimation()
        .openFrom(ev.currentTarget)
        .duration(100)
        .closeTo(ev.currentTarget)
        .withAnimation(this.$mdPanel.animation.SCALE),
      onRemoving: () => {
        // Reset the focus when the panel is closed
        if (this._toolbarService.focusedElement) {
          this._toolbarService.focusedElement.blur();
          this._toolbarService.focusedElement.focus();
        }
      },
      ...config
    });
  }

  //----------------------- Panel IDs -------------------------------

  get StraightLineColor() {
    return 'straight-line-color';
  }

  get StraightLineWidth() {
    return 'straight-line-width';
  }

  get EmojiSelection() {
    return 'emoji-selector';
  }

  get FontSelection() {
    return 'font-selector';
  }

  get PenColor() {
    return 'pen-color';
  }

  get PenWidth() {
    return 'pen-width';
  }

  get HighlighterColor() {
    return 'highlighter-color';
  }

  get HighlighterWidth() {
    return 'highlighter-width';
  }

  get TextColor() {
    return 'text-color';
  }

  get TextBackgroundColor() {
    return 'text-background-color';
  }

  get TextBorderColor() {
    return 'text-border-color';
  }

  get ImageMenu() {
    return 'image-menu';
  }
}
