
'use strict';


import Codec from './codec';
import ElementCodec from './element-codec';
import ColorCodec from './color-codec';
import SlideBackground from '../ui/elements/slide-background';

/**
 * Encodes/Decodes a SlideBackground
 */
export default class SlideBackgroundCodec extends Codec {
  constructor() {
    super();
    this._colorCodec = new ColorCodec();
  }

  /**
   * @param value {SlideBackground}
   * @returns {{data: Object, metadata: Object}}
   */
  encode(value) {
    let data = {
      type: value.type,
      color: angular.isDefined(value.hex) ? this._colorCodec.encode(value.hex) : null,
      url: angular.isDefined(value.url) ? value.url : null
    };
    return ElementCodec.firebaseElement(data, value.metadata);
  }

  /**
   * @param value {object}
   * @param key {string}
   * @returns {SlideBackground}
   */
  decode(value, key) {
    let metadata = ElementCodec.extractMetadata(value);
    return new SlideBackground(
      key,
      metadata,
      value.data.color && this._colorCodec.decode(value.data.color),
      value.data.url
    );
  }
}
