import React from 'react';
import ReactDOM from 'react-dom';
import 'mathlive/dist/mathlive-fonts.css';

/* eslint-disable no-restricted-imports */
import './index.scss';
import { App } from './App';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { bootAngular } from './app/index.module';
import { reportWebVitals } from './reportWebVitals';
import 'jquery';
import 'jquery-ui'; // eslint-disable-line import/no-duplicates
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widget'; // eslint-disable-line import/no-duplicates
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/sortable';
import 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-clipboard';
import 'angular-cookies';
import 'angular-hotkeys';
import 'angular-material';
import 'angular-messages';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-ui-router';
import 'angular-ui-sortable';
import 'angularjs-scroll-glue';


ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root'));

bootAngular();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
