'use strict';

import StaticService from '../../../services/static/static.service';

export default class TextToSpeech {

    constructor() {
        this._isAvailable = false;
        this._isSupported = true;
        this._synth = window.speechSynthesis;
        if (!this._synth || !navigator.language.includes('en')) {
            let message;
            if (!this._synth) {
                message = 'Speech synthesis is not supported in this browser: ' + navigator.userAgent;
            }
            else if (!navigator.language.includes('en')) {
                message = 'Classkick currently only supports text to speech for English. To activate, change your preferred browser language.';
            }
            StaticService.get.$log.warn(message);
            this._isSupported = false;
            return;
        }
        this._synth.addEventListener('voiceschanged', () => {
            this._init();
        });

        this._allVoices = null;
        this._bestVoice = null;
        this._utterance = null;
        this._rate = .8;
    }

    _init() {
        this._allVoices = this._synth.getVoices();
        if (this._allVoices.length === 0) {
            StaticService.get.$log.error('No voices found, text to speech unavailable');
            this._isSupported = false;
        }
        else {
            this.setVoice();
        }
    }

    setVoice(){
        // this sorting ensures [more] deterministic behavior. localService property ensures the voice will be able to play/pause
        const sortedVoices = this.sortVoices(this._allVoices).filter((voice) => (voice.lang === 'en-US') && voice.localService === true);
        this._bestVoice = sortedVoices.find((voice) => voice.default === true) || sortedVoices[0];

        // Check for chromebook voices first
        const chromeOsVoices = sortedVoices.filter((voice) => voice.name.toLowerCase().startsWith('chrome'));

        if (chromeOsVoices.length > 0) {
            const firstChoiceVoice = chromeOsVoices.filter((voice) => voice.name.startsWith('chrome os us english 1 (en-US)'));

            if (firstChoiceVoice.length === 1) {
                this._bestVoice = firstChoiceVoice[0];
            } else {
                this._bestVoice = chromeOsVoices[0];
            }
        }
        else {
            // If we are not on chromebook, pick one of the other preferred voices
            let altVoiceOptions = sortedVoices.filter((voice) => this.preferredVoice(voice.name.toLowerCase()));
            if (altVoiceOptions.length > 0) {
                this._bestVoice = altVoiceOptions[0];
            }
        }
    }

    sortVoices(rawVoices) {
        return rawVoices.sort(function (a, b) {
            const aname = a.name.toUpperCase();
            const bname = b.name.toUpperCase();

            if (aname < bname) {
                return -1;
            } else if (aname === bname) {
                return 0;
            } else {
                return 1;
            }
        });
    }

    preferredVoice(name) {
        if (name.startsWith('sylvester') || name.startsWith('superstar') || name.startsWith('samantha')) {
            return true;
        }
    }

    get current() {
        return this._synth.current;
    }

    set current(value) {
        this._synth.current = value;
    }

    get isAvailable() {
        return this._isAvailable;
    }

    set isAvailable(value) {
        this._isAvailable = value;
    }

    speak(text) {
        this._utterance = new SpeechSynthesisUtterance(text);
        this._utterance.voice = this._bestVoice;
        this._utterance.rate = this._rate;
        this._synth.speak(this._utterance);
    }

    speaking() {
        return this._synth.speaking;
    }

    cancel(){
        this._synth.cancel();
    }

    static get instance() {
      if (angular.isDefined(this._textToSpeechInstance)) {
        return this._textToSpeechInstance;
      }
      return this._textToSpeechInstance = new TextToSpeech();
    }
}
