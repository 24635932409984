'use strict';

import moment from 'moment';
import { ViewHelps } from '../../components/view-help-dialog/view-help-dialog.controller';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import { SubscriptionFunnel } from '../../services/mixpanel/mixpanel.service';
import scrollIntoView from 'scroll-into-view-if-needed';
import FeatureDialogController from '../../components/feature-dialog/feature-dialog.controller';
import { OrderPurchasePeriod } from '../../model/domain/order-purchase-period';
import { OrderManager } from '../../model/domain/order-manager';
import LogRocketService from '../../services/log-rocket/log-rocket.service';
import AuthService from '../../services/auth/auth.service';
import manipulativesExampleImage from '../../../assets/gifs/student-manipulatives-example.gif';
import fillInTheBlankExampleImage from '../../../assets/gifs/student-enter-answer-fitb.gif';
import Contract from '../../model/domain/contract';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import UserProfileController from '../user-profile/user-profile.controller';
import {Locations} from '../../services/mixpanel/mixpanel.service';
import Order from '../../model/domain/order';

class FeatureItem {

  /**
   * @param text {string}
   * @param url {string}
   * @param standard {boolean|string}
   * @param pro {boolean|string}
   */
  constructor(text, url, standard, pro) {
    this.text = text;
    this.url = url;
    this.standard = standard;
    this.pro = pro;
  }

}

const StandardFeatures = [
  {
    text: 'Manual Rostering',
    standard: 'Unlimited',
    pro: 'Unlimited'
  },
  {
    text: 'Assignments per Teacher',
    standard: '20',
    pro: 'Unlimited'
  },
  {
    text: 'Whole Class View',
    url: 'https://assets.classkick.com/view-helps/wcv-hide-unstarted-thumbnails.gif'
  },
  {
    text: 'Peer Helpers',
    url: 'https://assets.classkick.com/view-helps/allow-peer-helpers.gif'
  },
  {
    text: 'Full suite of instructional exercise formats',
    tooltip: 'Manipulatives, Autograded Fill-in-the-Blank, Multiple Choice, Pen Tool'
  },
  {
    text: 'Teacher & Peer feedback',
    tooltip: 'Handwriting, Grading and Stickers'
  }
];

const ProFeatures = [
  {
    text: 'Customer Support',
    standard: 'Email only',
    pro: 'Priority email + video call support'
  },
  {
    text: 'Google Classroom Integration',
  },
  {
    text: 'Clever Integration',
  },
  {
    text: 'One Click Rostering',
  },
  {
    text: 'Student Accounts',
    url: 'https://assets.classkick.com/view-helps/portfolio-student-login-full.gif'
  },
  {
    text: 'Export Student Grades And Answers',
    url: 'https://assets.classkick.com/view-helps/export-grades-paywall.gif'
  },
  {
    text: 'Export All Student Work to PDF',
  }
];


export default class OrderController {

  /**
   * @ngInject
   */
  constructor($q, $log, $filter, $location, $mdDialog, $window, $state, $stateParams, BreadcrumbService, AuthService, CacheService,
              AnalyticsService, LogRocketService, StaticContentService, OrderService, OrganizationService, ContractService) {
    this.$q = $q;
    this.$log = $log;
    this.$filter = $filter;
    this.$location = $location;
    this.$mdDialog = $mdDialog;
    this.$window = $window;
    this.$state = $state;
    this.$stateParams = $stateParams;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {LogRocketService} */
    this._logRocketService = LogRocketService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {ContractService} */
    this._contractService = ContractService;

    this._state = this.$stateParams.tab || OrderController.SchoolTab;
    this._user = undefined;
    this._school = undefined;
    this._orders = undefined;
    this._schoolsInDistrict = 2;
    this._standardFeatures = StandardFeatures;
    this._proFeatures = ProFeatures;
    this._userDataLoaded = false;
    this._priceMap = {
      '5': 250,
      '6': 250,
      '7': 250,
      '8': 250,
      '9': 250,
      '10': 250,
      '11': 250,
      '0' : 250,
      '1': 250,
      '2': 250,
      '3': 250,
      '4': 250
    };
    this._currentMonth = moment().month().toString();
    this._firstYearPrice = this._priceMap[this._currentMonth];

    this._loadingDialog = LoadingDialogController.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._featureDialog = FeatureDialogController.show;

    this._orderManager = new OrderManager($q, $log, $filter, $mdDialog, $location, AuthService, OrderService,
      OrganizationService, BreadcrumbService, CacheService, ContractService);

    this._analyticsService.subscriptionFunnel(SubscriptionFunnel.ORDER_PRO);

    if (this.isLoggedIn) {
      this._logRocketService.identify(this.userInfo.id, this.userInfo.name, this.userInfo.email, this.userInfo.username, this.userInfo.isPro);
      this.init();
    } else {
      this._logRocketService.identify('','','','','',true);
    }
  }

  init() {
    return this.$q
      .all({
        user: this._cacheService.getUser(true),
        schools: this._cacheService.getSchools(true),
        cachedOrganizations: this._authService.isLoggedIn && this._cacheService.getOrganizations()
      })
      .then(({user, schools, cachedOrganizations}) => {
        this._user = user;
        this._school = schools[0];

        if (this._school) {
          if (cachedOrganizations) {
            const matchingOrg = cachedOrganizations.find((org) => org.id === this._school.id);
            this._contract = matchingOrg && matchingOrg.contract;
          }

          return this._orderService.getForOrganization(this._school.id);
        }
        else {
          return [];
        }
      })
      .then((orders) => {
        this._orders = orders;
        this._userDataLoaded = true;
        this.activeOrder = Order.findActiveOrder(orders);
      });
  }

  //---------------------- Navigation ------------------------------

  get state() {
    return this._state;
  }

  set state(value) {
    this._state = value;
  }

  static get TeacherTab() {
    return 'teacher';
  }

  static get SchoolTab() {
    return 'school';
  }

  static get DistrictTab() {
    return 'district';
  }

  get viewingTeacherTab() {
    return this._state === OrderController.TeacherTab;
  }

  get viewingSchoolTab() {
    return this._state === OrderController.SchoolTab;
  }

  get viewingDistrictTab() {
    return this._state === OrderController.DistrictTab;
  }

  goToTeacherTab() {
    this.$state.go(this.$state.current.name, {
      tab: OrderController.TeacherTab
    });
    this._state = OrderController.TeacherTab;
  }

  goToSchoolTab() {
    this.$state.go(this.$state.current.name, {
      tab: OrderController.SchoolTab
    });
    this._state = OrderController.SchoolTab;
  }

  goToDistrictTab() {
    this.$state.go(this.$state.current.name, {
      tab: OrderController.DistrictTab
    });
    this._state = OrderController.DistrictTab;
  }

  goToFaq(){
    this.$window.open('https://classkick.zendesk.com/hc/en-us/categories/360005756031-Billing-and-Payments');
  }

  goToHomepage() {
    this._staticContentService.goToHomePage();
  }

  goToLogin() {
    this._breadcrumbService.go('root.account-login', {});
  }

  goToProfile() {
    this._breadcrumbService.go('root.account.nav.profile', {});
  }

  goToPlan() {
    this._breadcrumbService.go('root.account.nav.profile', { tab: UserProfileController.PlanTab });
  }

  goToProTeacherPayment() {
    let promise = this.$q.resolve();

    if (!this.isLoggedIn) {
      promise = this._confirmDialog(this.$mdDialog, 'You must be logged in to your account to pay for Pro Teacher', 'Would you like to log in now?', false);
    }

    promise.then(() => {
      this._breadcrumbService.go('root.account.create-payment');
    });
  }

  openTeacherPO() {
    this._staticContentService.goToTeacherPO();
  }

  goToTeacherHelpCenter() {
    this._staticContentService.goToTeacherHelpCenter(false);
  }

  openPricingFAQs() {
    this.$window.open('https://classkick.zendesk.com/hc/en-us/articles/360058606251-School-and-District-Pricing-FAQs', '_blank');
  }

  shareSchoolOrder() {
    this._analyticsService.shareOrderDialogOpened(Locations.ORDER_PAGE);
    this._orderManager.shareSchoolOrder(this._user, this._school, this._orders,
      undefined, undefined, Locations.ORDER_PAGE, this.isActiveFreeTrial);
  }

  shareFeatureChart() {
    this._analyticsService.shareOrderDialogOpened(Locations.FEATURE_CHART);
    let activeOrder = this._orders ? Order.findActiveOrder(this._orders):undefined;
    this._orderManager.shareFeatures(activeOrder ? activeOrder.id:undefined, undefined, Locations.FEATURE_CHART);
  }

  startSchoolOrder() {
    if (this.isLoggedIn) {
      this.getOrCreateRenewalOrder();
    } else {
      this._orderManager.startSchoolOrder(this._user, this._school, this._orders, this.activeContract,  false);
    }
  }

  startDistrictOrder() {
    this._orderManager.startDistrictOrder(this._user, this._schoolsInDistrict);
  }

  //---------------------- Data Properties ------------------------------

  get isLoggedIn() {
    return this._authService.isLoggedIn;
  }

  get loggedInAndLoadingData() {
    return this.isLoggedIn && !this._userDataLoaded;
  }

  get userInfo() {
    return this._authService.authData;
  }

  get contract() {
    return this._contract;
  }

  get isActiveFreeTrial() {
    return !!(this.contract && this.contract.isTrial && !this.contract.isExpired);
  }
  //---------------------- Products Display ------------------------------

  get firstYearPrice() {
    return this._firstYearPrice;
  }

  get schoolsInDistrict() {
    return this._schoolsInDistrict;
  }

  set schoolsInDistrict(value) {
    if (value === null || angular.isUndefined(value)) {
      value = '';
    }

    this._schoolsInDistrict = angular.isNumber(value) ? Math.abs(parseInt(value)) : value;
  }

  incrementSchoolsInDistrict() {
    this.schoolsInDistrict = this._schoolsInDistrict + 1;
  }

  decrementSchoolsInDistrict() {
    if (this._schoolsInDistrict === 2) {
      return;
    }
    this.schoolsInDistrict = this._schoolsInDistrict - 1;
  }

  get districtPrice() {
    if (angular.isNumber(this._schoolsInDistrict) && this._schoolsInDistrict > 0) {
      return this._firstYearPrice * this._schoolsInDistrict;
    }
    else {
      return '--';
    }
  }

  get retailDistrictPrice() {
    if (angular.isNumber(this._schoolsInDistrict) && this._schoolsInDistrict > 0) {
      return 700 * this._schoolsInDistrict;
    }
    else {
      return '--';
    }
  }

  //---------------------- Compare Features ------------------------------

  scrollToFeatures() {
    scrollIntoView(angular.element('.order .features')[0], {
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest'
    });
  }

  /**
   * @param value {boolean|string}
   * @return {boolean}
   */
  showCheckForPlanValue(value) {
    return !value;
  }

  showTextForPlanValue(value) {
    return angular.isString(value);
  }

  openFeatureDialog(ev, feature) {
    this._featureDialog(this.$mdDialog, feature.text, feature.url);
  }

  /**
   * @return {FeatureItem[]}
   */
  get standardFeatures() {
    return this._standardFeatures;
  }

  /**
   * @return {FeatureItem[]}
   */
  get proFeatures() {
    return this._proFeatures;
  }


  //old SB1 code brought back to check for existing contracts
  getOrCreateRenewalOrder() {
    let promise = this.$q.all({
      contracts: this._cacheService.getContracts(false, true, true),
      schools: this._cacheService.getSchools(false)
    }).then(({schools, contracts}) => {
      this.activeContract = Contract.ActiveContract(contracts);
      let school = schools.find((school) => school.contractId === this.activeContract.id);
      return this.$q.all({
        school: school,
        orders: this._orderService.getForOrganization(school.id),
        renewal: !!school
      });
    }).catch((err) => {
      return this.$q.all({
        school: this._school,
        orders: this._orders,
        renewal: false
      });
    });

    this._loadingDialog(this.$mdDialog, promise);

    promise.then((result) => {
      let contractHasPricePaid = false;
      if (this.activeContract && this.activeContract.pricePaid && this.activeContract.pricePaid > 0) {
        contractHasPricePaid = true;
      }
      return this._orderManager.startSchoolOrder(
        this._user,
        result.school,
        result.orders,
        contractHasPricePaid ? this.activeContract : null,
        result.renewal);
    });
  }

  getInstantQuote(){
    if (!this.loggedInAndLoadingData) {
      if (this.viewingDistrictTab) {
        return this.startDistrictOrder();
      } else {
        return this.startSchoolOrder();
      }
    }
  }
}
