
'use strict';

import Codec from './codec';
import Size from '../ui/size';

export default class SizeCodec extends Codec {

  /**
   * Encodes a point to a string value
   *
   * @param value {Size}
   * @returns {string}
   */
  encode(value) {
    return angular.toJson([Math.round(value.width), Math.round(value.height)]);
  }

  /**
   * Decodes a point from the given input, either JSON string or array of numbers
   *
   * @param value {string|Array.<number>}
   * @returns {Size}
   */
  decode(value) {
    var encoded = value;
    if (angular.isString(encoded)) {
      encoded = angular.fromJson(encoded);
    }

    return new Size(encoded[0], encoded[1]);
  }
}
