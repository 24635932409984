'use strict';

import SubscriptionCodec from '../../model/codec/subscription-codec';
import ContractCodec from '../../model/codec/contract-codec';

export default class SubscriptionService {

  /**
   * @ngInject
   */
  constructor(environment, HttpService) {
    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {SubscriptionCodec} */
    this._subscriptionCodec = new SubscriptionCodec();
    /** @type {ContractCodec} */
    this._contractCodec = new ContractCodec();
  }

  /**
   * @param subscriptionId {string}
   * @return {Promise.<Subscription>}
   */
  get(subscriptionId) {
    return this._httpService
      .authGet(this._uri(`/v1/subscriptions/${subscriptionId}`))
      .then((result) => this._subscriptionCodec.decode(result));
  }

  /**
   * @param userId {string}
   * @return {Promise.<Subscription[]>}
   */
  getSubscriptions(userId) {
    return this._httpService
      .authGet(this._uri(`/v1/users/${userId}/subscriptions`))
      .then(({subscriptions}) => {
        return subscriptions.map((subscription) => this._subscriptionCodec.decode(subscription));
      });
  }

  /**
   * @param subscriptionPlan {string} type of subscription
   * @param contractId {string}
   * @return {Promise.<{subscription: Subscription, contract: Contract}>}
   */
  create(subscriptionPlan, contractId) {
    return this._httpService
      .authPost(
        this._uri('/v1/subscriptions'),
        {
          subscription_plan: subscriptionPlan,
          contract_id: contractId
        }
      )
      .then(({subscription, contract}) => {
        return {
          subscription: this._subscriptionCodec.decode(subscription),
          contract: this._contractCodec.decode(contract)
        };
      });
  }

  /**
   * @param subscriptionId {string}
   * @param billingEmail {string}
   * @param stripeToken {string}
   * @return {Promise.<{subscription: Subscription, contract: Contract}>}
   */
  activate(subscriptionId, billingEmail, stripeToken) {
    return this._httpService
      .authPost(
        this._uri(`/v1/subscriptions/${subscriptionId}`),
        {
          billing_email: billingEmail,
          stripe_token: stripeToken
        }
      )
      .then(({subscription, contract, token}) => {
        return {
          subscription: this._subscriptionCodec.decode(subscription),
          contract: this._contractCodec.decode(contract),
          token
        };
      });
  }

  /**
   * @param subscriptionId {string}
   * @return {Promise.<{subscription: Subscription, contract: Contract}>}
   */
  cancel(subscriptionId) {
    return this._httpService
      .authPost(
        this._uri(`/v1/subscriptions/${subscriptionId}/cancel`),
        {}
      )
      .then(({subscription, contract}) => {
        return {
          subscription: this._subscriptionCodec.decode(subscription),
          contract: this._contractCodec.decode(contract)
        };
      });
  }

  /**
   * Creates a URL from a path
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }
}
