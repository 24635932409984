import OrganizationCodec from '../../model/codec/organization-codec';
import UserCodec from '../../model/codec/user-codec';
import MdrOrganizationCodec from '../../model/codec/mdr-organization-codec';
import MdrTeacherCodec from '../../model/codec/mdr-teacher-codec';
import OrganizationUsageStatsCodec from '../../model/codec/organization-usage-stats-codec';
import MdrOrganizationAdminCodec from '../../model/codec/mdr-organization-admin-codec';
import ChildOrganizationCountCodec from '../../model/codec/child-organizations-count-codec';

export default class OrganizationService {

  /**
   * @ngInject
   */
  constructor($log, HttpService, environment) {
    /** @type {HttpService} */
    this._httpService = HttpService;
    this.$log = $log;

    this._environment = environment;

    this._organizationCodec = new OrganizationCodec();
    this._mdrOrganizationCodec = new MdrOrganizationCodec();
    this._userCodec = new UserCodec();
    this._organizationUsageStatsCodec = new OrganizationUsageStatsCodec();
    this._mdrTeacherCodec = new MdrTeacherCodec();
    this._mdrOrganizationAdminCodec = new MdrOrganizationAdminCodec();
    this._childOrganizationCountCodec = new ChildOrganizationCountCodec();
  }

  getAll() {
    return this._httpService.authGet(this._uri('/v1/organizations'))
      .then((data) => {
        return data.organizations.map((organization) => this._organizationCodec.decode(organization));
      });
  }

  get(organizationId) {
    return this._httpService.authGet(this._uri(`/v1/organizations/${organizationId}`))
      .then((data) => {
        return this._organizationCodec.decode(data);
      });
  }

  /**
   * Creates a new organization.
   * @param type {string}
   * @param name {string}
   * @param lat {number}
   * @param lng {number}
   * @param zip {string}
   * @param city {string}
   * @param state {string}
   * @param country {string}
   * @param {string|null} userDefinedOrgType
   * @param {Object} properties
   * @return {Promise<Organization|never>}
   */
  create(type, name, lat, lng, zip, city, state, country, userDefinedOrgType, properties = {}) {

    return this._httpService
      .authPost(
        this._uri('/v1/organizations'),
        {
          org_type: type,
          name,
          lat,
          lng,
          properties: {
            zip,
            city,
            state,
            country,
            user_defined_org_type: userDefinedOrgType,
            ...properties
          }
        }
      )
      .then((data) => {
        return this._organizationCodec.decode(data);
      });
  }

  /**
   * @param organization {Organization}
   */
  update(organization) {
    return this._httpService.put(this._uri(`/v1/organizations/${organization.id}`), this._organizationCodec.encode(organization));
  }

  getUsageStatsWithoutAuth(organizationId) {
    return this._httpService.get(this._uri(`/v1/organizations/${organizationId}/usage-stats`)).then((response) => this._organizationUsageStatsCodec.decode(response));
  }

  /**
   * Adds the current user to an organization
   * @param organizationId {string}
   * @returns {Promise}
   */
  addSelf(organizationId) {
    return this._httpService.authPost(this._uri(`/v1/organizations/${organizationId}/users/self`), {})
      .then((result) => result.token);
  }

  /**
   * Marks a user as a "verified" or "unverified" member of the specified organization
   * @param organizationId {string}
   * @param userId {string}
   * @param verified {boolean}
   * @return {Promise}
   */
  verifyMember(organizationId, userId, verified) {
    return this._httpService.authPost(
      this._uri(`/v1/organizations/${organizationId}/users/${userId}/verify`),
      verified
    );
  }

  /**
   * Marks a list of users as "verified" or "unverified" members of the specified organization
   * @param organizationId {string}
   * @param userIds {array<string>}
   * @return {Promise}
   */
  bulkVerifyMembers(organizationId, userIds) {
    return this._httpService.authPost(
      this._uri(`/v1/organizations/${organizationId}/users/verify`),
      {
        ids: userIds
      }
    );
  }

  /**
   * Removes a user from an organization
   * @param organizationId {string}
   * @param userId {string}
   * @return {Promise}
   */
  removeMember(organizationId, userId) {
    return this._httpService.authDelete(this._uri(`/v1/organizations/${organizationId}/users/${userId}`));
  }

  /**
   * Removes a list of users from an organization
   * @param organizationId {string}
   * @param userIds {array<string>}
   * @return {Promise}
   */
  bulkRemoveMembers(organizationId, userIds) {
    return this._httpService.authDelete(
      this._uri(`/v1/organizations/${organizationId}/users/delete`),
      {
        data: {
          ids: userIds
        }
      }
    );
  }

  /**
   * Removes the current user from an organization
   * @param organizationId
   * @return {Promise}
   */
  removeSelf(organizationId) {
    return this._httpService.authDelete(this._uri(`/v1/organizations/${organizationId}/users/self`));
  }

  /**
   * Add an organization admin
   * @param organizationId {string}
   * @param name {string}
   * @param email {string}
   * @param role {string}
   * @return {Promise}
   */
  addAdmin(organizationId, name, email, role) {
    return this._httpService.authPost(this._uri(`/v1/organizations/${organizationId}/admins`), {
      name,
      email,
      role
    });
  }


  /**
   * Searches existing organizations by type, location, name, and optional google_place_id
   * @param orgType {string}
   * @param lat {number}
   * @param lng {number}
   * @param query {string}
   * @param {string|null} googlePlaceId
   * @return {Promise<Organization[]>}
   */

  search(orgType, lat, lng, query = '', googlePlaceId = null) {
    const params = {
      org_type: orgType,
      lat,
      lng,
      query: query || '', // Ensure 'query' parameter is always present
    };
    if (googlePlaceId) {
      params.google_place_id = googlePlaceId;
    }
    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
    return this._httpService.get(this._uri(`/v1/organizations/search?${queryString}`))
      .then((data) => {
        return data.organizations.map((organization) => this._organizationCodec.decode(organization));
      })
      .catch((error) => {
        this.$log.error('Organization search failed:', error);

        // Check if error is due to missing 'google_place_id' support
        if (googlePlaceId && error.status === 400) {
          // Retry without googlePlaceId
          return this.search(orgType, lat, lng, query, null);
        } else {
          // Propagate other errors
          return this.$q.reject(error);
        }
      });
  }

  /**
   * Gets all organizations a user is a member of
   * @param userId
   * @return {Promise<T | never>}
   */
  getForUser(userId) {
    return this._httpService.authGet(this._uri(`/v1/users/${userId}/organizations`))
      .then((data) => {
        return data.organizations.map((organization) => this._organizationCodec.decode(organization));
      });
  }

  /**
   * Gets all districts a user is a member of
   * @param userId
   * @return {Promise<T | never>}
   */
  getDistrictsForUser(userId) {
    return this._httpService.authGet(this._uri(`/v1/users/${userId}/districts`))
      .then((data) => {
        return data.districts.map((organization) => this._organizationCodec.decode(organization));
      });
  }

  /**
   * Gets all users for an organization
   *
   * @param organizationId
   * @returns {Promise.<Array.<User>>}
   */
  getUsers(organizationId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/organizations/${organizationId}/users`)
      .then((response) => response.users.map((user) => this._userCodec.decode(user)));
  }

  /**
   * Get the overview of this organization.
   * @param organizationId
   * @returns {Promise<OrganizationUsageStats>}
   */
  getUsageStatsWithAuth(organizationId) {
    return this._httpService
        .authGet(`${this._environment.serverUrlBase}/v1/organizations/${organizationId}/usage-stats`)
        .then((response) => this._organizationUsageStatsCodec.decode(response));
  }

  /**
   * Get MDR schools by location
   * @param city {string}
   * @param state {string}
   * @param name {string}
   * @returns {Promise<MdrOrganization[]>}
   */
  getMdrOrgsByLocation(city, state, name) {
    return this._httpService.get(this._v2uri(`/v2/salesbuddy/organizations/location-search?city=${city}&state=${state}&name=${name}&institution_type=school`))
      .then((response) => {
        return response.data.map((organization) => this._mdrOrganizationCodec.decode(organization));
      });
  }

  /**
   * Creates a URL from a path
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }

  _v2uri(path) {
    return `${this._environment.serverv2UrlBase}${path}`;
  }

  /**
 * Gets all unverified organizations for a user
 *
 * @param userId
 * @returns {Promise.<Array.<Organization>>}
 */
  getUnverifiedOrganizations(userId) {
    return this._httpService.authGet(this._uri(`/v1/users/${userId}/unverified-organizations`))
      .then((data) => {
        return data['unverified-organizations'].map((organization) => this._organizationCodec.decode(organization));
      });
  }

  /**
   * Save a user's MDR selection for a given organization
   *
   * @param organizationId {string}
   * @param userId {string}
   * @param mdrPid {string}
   * @returns {Promise}
   */
  saveMdrSelection(organizationId, userId, mdrPid) {
    return this._httpService.authPut(this._uri(`/v1/organizations/${organizationId}/users/${userId}/mdr-verify`), {
      mdr_pid: mdrPid
    });
  }

  /**
   * Gets all peer teacher emails for an MDR organization
   *
   * @param organizationId
   * @returns {Promise.<Array.<String>>}
   */
  getMdrPeers(organizationId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/mdr-organizations/${organizationId}/teachers`)
      .then((response) => response.mdr_teachers.map((teacher) => this._mdrTeacherCodec.decode(teacher)));
  }

  /**
   * Gets all peer teacher emails for an MDR organization who are not an organization_member in Classkick
   *
   * @param organizationId
   * @returns {Promise.<Array.<String>>}
   */
  getMdrPeersNotInOrg(organizationId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/mdr-organizations/${organizationId}/teachers-not-in-org`)
      .then((response) => response.mdr_teachers)
      .catch((err) => {
        this.$log.warn('Problem receiving mdr teachers', err);
        return [];
      });
  }

  getMdrAdmins(organizationId) {
    return this._httpService
      .optionalAuthGet(`${this._environment.serverUrlBase}/v1/mdr-organizations/${organizationId}/admin`)
      .then((response) => this._mdrOrganizationAdminCodec.decode(response))
      .catch((err) => {
        this.$log.warn('Problem receiving mdr admins', err);
        this._mdrOrganizationAdminCodec.decode();
      });
  }

  /**
   * Get total count of child organizations associated with district
   * @param organizationId
   * @returns {Promise<ChildOrganizationCount>}
   */
  getChildOrganizationCount(organizationId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/organizations/${organizationId}/child-organizations/count`)
      .then((response) => this._childOrganizationCountCodec.decode(response));
  }
}

