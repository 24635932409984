export class OrganizationProperties {

  static get Street() {
    return 'street';
  }

  static get City() {
    return 'city';
  }

  static get State() {
    return 'state';
  }

  static get Country() {
    return 'country';
  }

  static get Zip() {
    return 'zip';
  }

  static get Virtual() {
    return 'virtual';
  }

  static get ClosedEnd() {
    return 'closed_end';
  }

  static get ClosurePointEmail() {
    return 'closure_point_email';
  }

  static get Provider() {
    return 'provider';
  }
}

let userDefinedOrganizationTypes = [
  {
    value: 'school',
    option: 'School'
  },
  {
    value: 'university',
    option: 'University'
  },
  {
    value: 'large-enterprise',
    option: 'Large Enterprise'
  },
  {
    value: 'small-business',
    option: 'Small/Medium Sized Business'
  },
  {
    value: 'religious',
    option: 'Religious Organization'
  },
  {
    value: 'nonprofit',
    option: 'Other Nonprofit'
  },
  {
    value: 'other',
    option: 'Other'
  }
];

export class OrganizationTypes {

  static get School() {
    return 'school';
  }

  static get District() {
    return 'district';
  }

  /**
   * @return {Array}
   */
  static get UserDefinedOrganizationTypes() {
    return userDefinedOrganizationTypes;
  }

}

export default class Organization {

  constructor(id, type, name, lat, lng, contractId, properties, members, unverifiedMembers, adminMembers = [], mdrPid = undefined, parentOrganizationId = undefined) {
    this._id = id;
    this._type = type;
    this._name = name;
    this._lat = lat;
    this._lng = lng;
    this._contractId = contractId;
    this._properties = properties || {};
    this._members = members || [];
    this._unverifiedMembers = unverifiedMembers || [];
    this._contract = undefined;
    this._admin = undefined;
    this._adminMembers = adminMembers || [];
    this._mdrPid = mdrPid;
    this._parentOrganizationId = parentOrganizationId;
  }

  /**
   * @return {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @return {string}
   */
  get type() {
    return this._type;
  }

  /**
   * @return {boolean}
   */
  get isDistrict() {
    return this._type === OrganizationTypes.District;
  }

  /**
   * @return {string}
   */
  get name() {
    return this._name;
  }

  /**
   * @return {number}
   */
  get lat() {
    return this._lat;
  }

  /**
   * @return {number}
   */
  get lng() {
    return this._lng;
  }

  /**
   * @return {string|undefined}
   */
  get contractId() {
    return this._contractId;
  }

  /**
   * @return {string|undefined}
   */
  get parentOrganizationId() {
    return this._parentOrganizationId;
  }

  /**
   * @return {Object}
   */
  get properties() {
    return this._properties;
  }

  /**
   * @return {string[]}
   */
  get members() {
    return this._members;
  }

  /**
   * @return {number}
   */
  get memberCount() {
    return this.members && this.members.length;
  }

  get unverifiedMembers() {
    return this._unverifiedMembers;
  }

  /**
   * @return {number}
   */
  get mdrPid() {
    return this._mdrPid;
  }

  /**
   * Check that user is a member of the organization and is not in the unverified list
   * @param userId {string}
   * @return {boolean}
   */
  isVerifiedFor(userId) {
    return this._members.some((memberId) => memberId === userId) && !this._unverifiedMembers.some((memberId) => memberId === userId);
  }

  /**
   * Marks a user as verified
   * @param userId {string}
   */
  markVerifiedFor(userId) {
    this._unverifiedMembers = this._unverifiedMembers.filter((memberId) => memberId !== userId);
  }

  //--------------------- Property Getters / Setters --------------------------//

  /**
   * @return {string}
   */
  get street() {
    return this._properties[OrganizationProperties.Street];
  }

  /**
   * @param value {string}
   */
  set street(value) {
    this._setProperty(OrganizationProperties.Street, value);
  }

  /**
   * @return {string}
   */
  get city() {
    return this._properties[OrganizationProperties.City];
  }

  /**
   * @param value {string}
   */
  set city(value) {
    this._setProperty(OrganizationProperties.City, value);
  }

  /**
   * @return {string}
   */
  get state() {
    return this._properties[OrganizationProperties.State];
  }

  /**
   * @param value {string}
   */
  set state(value) {
    this._setProperty(OrganizationProperties.State, value);
  }

  /**
   * @return {string}
   */
  get country() {
    return this._properties[OrganizationProperties.Country];
  }

  /**
   * @param value {string}
   */
  set country(value) {
    this._setProperty(OrganizationProperties.Country, value);
  }

  /**
   * @return {string}
   */
  get zip() {
    return this._properties[OrganizationProperties.Zip];
  }

  /**
   * @param value {string}
   */
  set zip(value) {
    this._setProperty(OrganizationProperties.Zip, value);
  }

  /**
   * @return {string|undefined}
   */
  get closedEnd() {
    return this._properties[OrganizationProperties.ClosedEnd];
  }

  /**
   * @return {string}
   */
  get closurePointEmail() {
    return this._properties[OrganizationProperties.ClosurePointEmail];
  }

  /**
   * Indicates if the organization is a virtual school or district
   * @return {boolean}
   */
  get virtual() {
    return !!this._properties[OrganizationProperties.Virtual];
  }

  /**
   * @param value {boolean}
   */
  set virtual(value) {
    this._setProperty(OrganizationProperties.Virtual, value);
  }

  /**
   * Checks if provider is Clever Imported
   * @returns {boolean}
   */
  get hasCleverProvider() {
    return (this._properties[OrganizationProperties.Provider] === 'clever-import');
  }

  /**
   * @param key {string}
   * @param value {string}
   * @private
   */
  _setProperty(key, value) {
    this._properties[key] = value || null;
  }

  // Helper properties

  get displayLocation() {
    return `${this.city}, ${this.state}, ${this.country}`;
  }

  //--------------------- Contract Getters/Setters --------------------------//

  /**
   * @return {undefined|Contract}
   */
  get contract() {
    return this._contract;
  }

  /**
   * @param value {undefined|Contract}
   */
  set contract(value) {
    this._contract = value;
  }

  /**
   * @return {undefined|Boolean}
   */
  get admin() {
    return this._admin;
  }

  /**
   * @param value {undefined|Boolean}
   */
  set admin(value) {
    this._admin = value;
  }

  /**
   * @return {Users[]}
   */
  get adminMembers() {
    return this._adminMembers;
  }

  /**
   * @param value {Users[]}
   */
  set adminMembers(value) {
    this._adminMembers = value;
  }

}
