
'use strict';

export class ABTest {
  // Ab test keys should be added to this object as static getters

  static get ConfirmSchool() {
    return 'confirm-school';
  }

  static get MultipleChoice(){
    return 'multiple-choice';
  }

  static get ReferralButtonColor(){
    return 'referral-button-color';
  }

  static get StickerReferrals(){
    return 'sticker-referrals';
  }

  static get StudentPreviewNav(){
    return 'student-preview-nav';
  }

  static get TeacherAssignmentAIAssistant() {
    return 'teacher-assignment-ai-assistant';
  }

  static get AssignmentLimit20() {
    return 'assignment-limit-20';
  }

}

export class ABTestVariable {

  static get A() {
    return 'A';
  }

  static get B() {
    return 'B';
  }
}

export default class ABTestService {

  constructor(HttpService, environment) {
    'ngInject';

    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {environment} */
    this._environment = environment;
  }

  /**
   * @param userId {String}
   * @return {Promise.<Map.<String, String>>}
   */
  getTestSegmentsForUser(userId) {
    return this._httpService.authGet(`${this._environment.serverUrlBase}/v1/users/${userId}/ab-test`)
      .then((object) => {
        return new Map(
          Object.keys(object).map((key) => [key, object[key]])
        );
      });
  }

}
