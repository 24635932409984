
'use strict';

import Codec from './codec';
import Roster from '../domain/roster';

/**
 * Encodes/Decodes a roster
 */
export default class RosterCodec extends Codec {

  constructor() {
    super();
  }

  encode(value) {
    return {
      id: value.id,
      owner_id: value.ownerId,
      name: value.name,
      color: value.color,
      allow_peer_help: value.allowPeerHelp,
      allow_new_members: value.allowNewMembers,
      allow_multi_login: value.allowMultiLogin,
      properties: value.properties,
      coteacher_access: value.coteacherAccess
    };
  }

  decode(value) {
    return new Roster(
      value.id,
      value.owner_id,
      value.name,
      value.color,
      value.allow_peer_help,
      value.allow_new_members,
      value.allow_multi_login,
      value.properties,
      value.coteacher_access
    );
  }

}

