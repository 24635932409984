'use strict';
/*global Stripe:false */

export default class StripeService {

  /**
   * @ngInject
   */
  constructor(environment) {
    this._stripe = new Stripe(environment.stripeKey);
  }

  /**
   * @returns {Stripe}
   */
  get stripe() {
    return this._stripe;
  }

  /**
   * @param card {Stripe.Element}
   * @param details {{name: string, address_zip: string}}
   * @returns {Promise.<{token: string}>}
   */
  createToken(card, details) {
    return this._stripe.createToken(card, details);
  }

}
