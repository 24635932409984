
'use strict';

import Codec from './codec';
import { ColorSpan } from '../ui/elements/textbox-color-span-arranger';
import ColorCodec from './color-codec';

export default class TextColorArrayCodec extends Codec {
  constructor() {
    super();
    this._colorCodec = new ColorCodec();
  }

  /**
   * @param value {Array.<ColorSpan>}
   * @returns {string}
   */
  encode(value) {
    return angular.toJson(
      value.map((x) => {
        return {
          start: x.start,
          color: this._colorCodec.encode(x.color)
        };
      })
    );
  }

  /**
   * @param value {string}
   * @returns {Array.<ColorSpan>}
   */
  decode(value) {
    return angular.fromJson(value)
      .map((x) =>
        new ColorSpan(
          x.start,
          -1,
          this._colorCodec.decode(x.color)
        )
      );
  }
}
