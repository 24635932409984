
'use strict';

import CollectionObserver from '../../model/firebase-mapping/collection-observer';

/**
 * Observer for the element change service
 */
export default class QuestionObserver extends CollectionObserver {
  /**
   * @param service {AssignmentTrackingService}
   * @param index {number}
   * @param isWork {boolean}
   */
  constructor(service, index, isWork) {
    super();
    this._service = service;
    this._index = index;
    this._isWork = isWork;
  }

  /**
   * @param value {Element}
   */
  onAdded(value) {
    value.deleted.subscribe(this._elementChanged, this);
    value.changed.subscribe(this._elementChanged, this);
    value.previewChanged.subscribe(this._previewElementChanged, this);
  }

  /**
   * @param value {Element}
   */
  onRemoved(value) {
    value.deleted.unsubscribe(this._elementChanged, this);
    value.changed.unsubscribe(this._elementChanged, this);
    value.previewChanged.unsubscribe(this._previewElementChanged, this);
  }

  /**
   * @param value {Element}
   * @param oldValue {element}
   */
  onChanged(value, oldValue) {
    this.onRemoved(oldValue);
    this.onAdded(value);
  }

  /**
   * @param change {Change}
   * @private
   */
  _elementChanged(change) {
    this._service._elementChanged(change, this._index, this._isWork);
  }

  /**
   * @param element {Element}
   * @private
   */
  _previewElementChanged(element) {
    this._service._previewElementChanged(element);
  }

  start(q) {
    q.elements.subscribe(this);
  }

  stop(q) {
    q.elements.unsubscribe(this);
    q.elements.forEach((e) => {
      this.onRemoved(e);
    });
    this._service = null;
  }
}
