
'use strict';

import Codec from './codec';
import JsonCodec from './json-codec';

/**
 * Encodes/Decodes User Preferences
 */
export default class UserPreferencesCodec extends Codec {
    constructor() {
        super();
        this._jsonCodec = new JsonCodec();
      }

    encode(value) {
        let preferences = {};
        for (const key in value.preferences) {
            const newKey = this.camelToSnake(key);
            preferences[newKey] = value.preferences[key];
        }

        return {
            user_id: value.userId,
            preferences: this._jsonCodec.encode(preferences)
        };
    }

    decode(value) {
        let preferences = {};
        for (const key in value.preferences) {
            const newKey = this.snakeToCamel(key);
            preferences[newKey] = value.preferences[key];
        }
        return {
            userId: value.user_id,
            preferences: this._jsonCodec.decode(preferences)
        };
    }

    snakeToCamel(str) {
        return str.replace(/[^a-zA-Z0-9]+(.)/g, (underscore, letter) => letter.toUpperCase());
    }

    camelToSnake(str) {
        return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
    }
}
