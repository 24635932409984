import StaticService from './services/static/static.service';

export default function $exceptionHandler($delegate) {
  'ngInject';


  return (exception, cause) => {
    $delegate(exception, cause);

    /** @type {StaticService} */
    let staticService = StaticService.get;
    /** @type {LogRocketService|boolean} */
    let logRocketService = angular.isDefined(staticService) && staticService.LogRocketService;
    /** @type {AnalyticsService} */
    let AnalyticsService = angular.isDefined(staticService) && staticService.AnalyticsService;

    if (angular.isDefined(logRocketService)) {
      logRocketService.handleError(exception);
    }

    if (angular.isDefined(AnalyticsService)) {
      AnalyticsService.sendEvent(
        {
          eventTag: 'error:ng',
          properties: {
            message: exception.message,
            cause
          }
        }
      );
    }
  };
}
$exceptionHandler.$inject = ['$delegate'];
