
'use strict';

import NotificationShardKey from './notification-shard-key.js';

export default class NotificationShardKeyFactory {

  constructor(FirebaseService) {
    'ngInject';

    this._firebaseService = FirebaseService;
  }

  /**
   * Get notification shard for an assignment
   * @param assignment {Assignment}
   * @returns {NotificationShardKey}
   */
  forAssignment(assignment) {
    return new NotificationShardKey('assignmentOwnerId', assignment.ownerId);
  }

  /**
   * Get notification shard for an assignment work
   * @param assignmentWork {AssignmentWork}
   * @returns {NotificationShardKey}
   */
  forAssignmentWork(assignmentWork) {
    return new NotificationShardKey('assignmentOwnerId', assignmentWork.assignment.ownerId);
  }

  /**
   * Get notification shard for an assignment roster
   * @param assignment {Assignment}
   * @returns {NotificationShardKey}
   */
  forAssignmentRoster(assignment) {
    return new NotificationShardKey('assignmentOwnerId', assignment.ownerId);
  }

  /**
   * Get notification shard for Account changes
   * @param userId {string}
   * @returns {NotificationShardKey}
   */
  forAccount(userId) {
    // shard key unchanged
    return new NotificationShardKey('userId', userId);
  }

  /**
   * Get notification shard for a User Roster changes
   * @param userId {string}
   * @returns {NotificationShardKey}
   */
  forUserRoster(userId) {
    // shard key unchanged
    return new NotificationShardKey('userId', userId);
  }

  /**
   * Get notification shard for a Roster changes
   * @param roster {Roster}
   * @returns {NotificationShardKey}
   */
  forRoster(roster) {
    return new NotificationShardKey('rosterOwnerId', roster.ownerId);
  }

  /**
   * Get notification shard for a User new messages
   * @param userId {string}
   * @returns {NotificationShardKey}
   */
  forUserNewMessage(userId) {
    // shard key staying the same
    return new NotificationShardKey('userId', userId);
  }

  /**
   * Get notification shard for User status
   * @param userId {string}
   * @returns {NotificationShardKey}
   */
  forUserStatus(userId) {
    // shard key staying the same
    return new NotificationShardKey('userId', userId);
  }

  /**
   * Get notification shard for Question Feedback List
   * @param work {AssignmentWork}
   * @returns {NotificationShardKey}
   */
  forQuestionFeedbackList(work) {
    return new NotificationShardKey('assignmentOwnerId', work.assignment.ownerId);
  }

  /**
   * Get notification shard for Help Request for Assignment
   * @param assignmentOwnerId {string}
   * @returns {NotificationShardKey}
   */
  forHelpRequestForAssignment(assignmentOwnerId) {
    return new NotificationShardKey('assignmentOwnerId', assignmentOwnerId);
  }

  /**
   * Get notification shard for Help Request for Roster
   * @param rosterOwnerId {string}
   * @returns {NotificationShardKey}
   */
  forHelpRequestForRoster(rosterOwnerId) {
    return new NotificationShardKey('rosterOwnerId', rosterOwnerId);
  }

  /**
   * Get notification shard for Help Request for Peers
   * @param assignmentOwnerId {string}
   * @returns {NotificationShardKey}
   */
  forHelpRequestForPeers(assignmentOwnerId) {
    return new NotificationShardKey('assignmentOwnerId', assignmentOwnerId);
  }

  /**
   * Get notification shard for Question Helpers
   * @param assignment {Assignment}
   * @returns {NotificationShardKey}
   */
  forQuestionHelpers(assignment) {
    return new NotificationShardKey('assignmentOwnerId', assignment.ownerId);
  }
}
