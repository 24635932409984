const elementEmojis = [
  {
    name: 'plus',
    value: '➕',
    unicode: '&#x2795;'
  },
  {
    name: 'multiply',
    value: '✖',
    unicode: '&#x2716;'
  },
  {
    name: 'minus',
    value: '➖',
    unicode: '&#x2796;'
  },
  {
    name: 'divide',
    value: '➗',
    unicode: '&#x2797;'
  },
  {
    name: 'equal sign',
    value: '🟰',
    unicode: '&#x1F7F0;'
  },
  {
    name: 'infinity',
    value: '∞',
    unicode: '&#x221E;'
  },
  {
    name: 'integral',
    value: '∫',
    unicode: '&#x222B;'
  },
  {
    name: 'partial differential',
    value: '∂',
    unicode: '&#x2202;'
  },
  {
    name: 'element of math',
    value: '∈',
    unicode: '&#x2208;'
  },
  {
    name: 'not element of math',
    value: '∉',
    unicode: '&#x2209;'
  },
  {
    name: 'contains as member',
    value: '∋',
    unicode: '&#x220B;'
  },
  {
    name: 'not contains as member',
    value: '∌',
    unicode: '&#x220C;'
  },
  {
    name: 'summation',
    value: '∑',
    unicode: '&#x2211;'
  },
  {
    name: 'square root',
    value: '√',
    unicode: '&#x221A;'
  },
  {
    name: 'cube root',
    value: '∛',
    unicode: '&#x221B;'
  },
  {
    name: 'fourth root',
    value: '∜',
    unicode: '&#x221C;'
  },
  {
    name: 'intersection',
    value: '⨅',
    unicode: '&#x2A05;'
  },
  {
    name: 'pi',
    value: 'π',
    unicode: '&#x03C0;'
  },
  {
    name: 'delta',
    value: 'δ',
    unicode: '&#x03B4;'
  },
  {
    name: 'percent',
    value: '%',
    unicode: '&#x0025;'
  },
  {
    name: 'fraction quarter',
    value: '¼',
    unicode: '&#x00BC;'
  },
  {
    name: 'fraction half',
    value: '½',
    unicode: '&#x00BD;'
  },
  {
    name: 'fraction three fourth',
    value: '¾',
    unicode: '&#x00BE;'
  },
  {
    name: 'fraction slash',
    value: '⁄',
    unicode: '&#x2044;'
  },
  {
    name: 'angle',
    value: '∠',
    unicode: '&#x2220;'
  },
  {
    name: 'measured angle',
    value: '∡',
    unicode: '&#x2221;'
  },
  {
    name: 'tilde',
    value: '∼',
    unicode: '&#x223C;'
  },
  {
    name: 'not tilde',
    value: '≁',
    unicode: '&#x2241;'
  },
  {
    name: 'asymptote equal',
    value: '≃',
    unicode: '&#x2243;'
  },
  {
    name: 'asymptote not equal',
    value: '≄',
    unicode: '&#x221C;'
  },
  {
    name: 'less than equal to',
    value: '≤',
    unicode: '&#x2264;'
  },
  {
    name: 'greater than equal to',
    value: '≥',
    unicode: '&#x2265;'
  },
  {
    name: 'much less than',
    value: '≪',
    unicode: '&#x226A;'
  },
  {
    name: 'much greater than',
    value: '≫',
    unicode: '&#x226B;'
  },
  {
    name: 'circle',
    value: '〇',
    unicode: '&#x3007;'
  },
  {
    name: 'verticle rectangle',
    value: '▯',
    unicode: '&#x25AF;'
  },
  {
    name: 'horizontal rectangle',
    value: '▭',
    unicode: '&#x25AD;'
  },
  {
    name: 'up triangle',
    value: '△',
    unicode: '&#x25B3;'
  },
  {
    name: 'down triangle',
    value: '▽',
    unicode: '&#x25BD;'
  },
  {
    name: 'right triangle',
    value: '▷',
    unicode: '&#x25B7;'
  },
  {
    name: 'left triangle',
    value: '◅',
    unicode: '&#x25C5;'
  },
  {
    name: 'hexagon',
    value: '⬡',
    unicode: '&#x2B21;'
  },
  {
    name: 'pentagon',
    value: '⬠',
    unicode: '&#x2B20;'
  },
  {
    name: 'diamond',
    value: '◇',
    unicode: '&#x25C7;'
  },
  {
    name: 'ellipsis',
    value: '⬭',
    unicode: '&#x2B2D;'
  },
  {
    name: 'parallelogram',
    value: '▱',
    unicode: '&#x25B1;'
  },
  {
    name: 'dollar',
    value: '$',
    unicode: '&#x0024;'
  },
  {
    name: 'euro',
    value: '€',
    unicode: '&#x20AC;'
  },
  {
    name: 'yen',
    value: '¥',
    unicode: '&#x00A5;'
  },
  {
    name: 'pound',
    value: '£',
    unicode: '&#x00A3;'
  },
  {
    name: 'grinning face',
    value: '😀',
    unicode: '&#x1F600;'
  },
  {
    name: 'rofl',
    value: 	'🤣',
    unicode: '&#x1F923;'
  },
  {
    name: 'upside down face',
    value: '🙃',
    unicode: '&#x1F643;'
  },
  {
    name: 'smiling face with hearts',
    value: '🥰',
    unicode: '&#x1F970;'
  },
  {
    name: 'smiling face with heart-eyes',
    value: 	'😍',
    unicode: '&#x1F60D;'
  },
  {
    name: 'star-struck',
    value: '🤩',
    unicode: '&#x1F929;'
  },
  {
    name: 'thinking face',
    value: '🤔',
    unicode: '&#x1F914;'
  },
  {
    name: 'expressionless face',
    value: 	'😑',
    unicode: '&#x1F611;'
  },
  {
    name: 'partying face',
    value: '🥳',
    unicode: '&#x1F973;'
  },
  {
    name: 'grinning face',
    value: '😀',
    unicode: '&#x1F600;'
  },
  {
    name: 'nerd face',
    value: 	'🤓',
    unicode: '&#x1F913;'
  },
  {
    name: 'smiling face with sunglasses',
    value: '😎',
    unicode: '&#x1F60E;'
  },
  {
    name: 'slightly frowning face',
    value: '🙁',
    unicode: '&#x1F641;'
  },
  {
    name: 'loudly crying face',
    value: '😭',
    unicode: '&#x1F62D;'
  },
  {
    name: 'enraged face',
    value: '😡',
    unicode: '&#x1F621;'
  },
  {
    name: 'robot',
    value: '🤖',
    unicode: '&#x1F916;'
  },
  {
    name: 'see-no-evil monkey',
    value: '🙈',
    unicode: '&#x1F648;'
  },
  {
    name: 'sparkling heart',
    value: '💖',
    unicode: '&#x1F496;'
  },
  {
    name: 'waving hand',
    value: 	'👋',
    unicode: '&#x1F44B;'
  },
  {
    name: 'thumbsup',
    value: '👍',
    unicode: '&#x1F44D;'
  },
  {
    name: 'flexed biceps',
    value: '💪',
    unicode: '&#x1F4AA;'
  },
  {
    name: 'person raising hand',
    value: 	'🙋',
    unicode: '&#x1F64B;'
  },
  {
    name: 'stop',
    value: '🛑',
    unicode: '&#x1F6D1;'
  },
  {
    name: 'alarm clock',
    value: '⏰',
    unicode: '&#x23F0;'
  },
  {
    name: 'birthday cake',
    value: 	'🎂',
    unicode: '&#x1F382;'
  },
  {
    name: 'eyes',
    value: '👀',
    unicode: '&#x1F440;'
  },
  {
    name: 'ghost',
    value: 	'👻',
    unicode: '&#x1F47B;'
  },
  {
    name: 'red heart',
    value: '❤',
    unicode: '&#x2764;'
  },
  {
    name: 'thermometer',
    value: 	'🌡',
    unicode: '&#x1F321;'
  },
  {
    name: 'exclamation',
    value: '❗',
    unicode: '&#x02757;'
  },
  {
    name: 'white star',
    value: '⭐',
    unicode: '&#x2B50;'
  }
];

export class Emojis {

  /**
   * @returns {{name: string, value: string, unicode: string}[]}
   */
  static get ELEMENT_EMOJIS() {
    return elementEmojis;
  }

}

