
'use strict';

import LoadingDialog from '../../components/loading-dialog/loading-dialog.controller';
import { SessionDataCodes, StudentSortOptions } from '../../model/domain/session-data';
import Debouncer from '../../model/util/debouncer';
import AssignmentWorkQuestion from '../../model/domain/assignment-work-question';
import { PeerHelpInboxController } from '../../components/peer-help-inbox/peer-help-inbox.controller';
import StudentAccountHelpDialogController
  from '../../components/student-account-help-dialog/student-account-help-dialog.controller';
import { StudentInfoMenuController } from '../../components/student-info-menu/student-info-menu.controller';
import ViewHelpDialogController, { ViewHelps } from '../../components/view-help-dialog/view-help-dialog.controller';
import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import {BulkMenuOptions} from '../../components/select-bulk-update-menu/select-bulk-update-menu.controller';
import SlideForeground from '../../model/ui/elements/slide-foreground';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import { Locations } from '../../services/mixpanel/mixpanel.service';

class SessionQuestionThumbnail {

  constructor(student, work, assignment, index, handleScoreUpdated) {
    this._student = student;
    this._work = work;
    this._assignment = assignment;
    this._index = index;

    this._question = (work ? work : assignment).questionForIndex(index);
    this._contentQuestion = assignment.questionForIndex(index);

    this._trackingId = `${student.id}${index}`;

    this._handleScoreUpdated = handleScoreUpdated;
  }

  /**
   * Unique identifier for rendering in an ng-repeat to improve layout performance
   * @return {string}
   */
  get trackingId() {
    return this._trackingId;
  }

  /**
   * @return {boolean}
   */
  get isWork() {
    return !!this._work;
  }

  /**
   * @return {number}
   */
  get index() {
    return this._index;
  }

  /**
   * @return {User}
   */
  get student() {
    return this._student;
  }

  /**
   * @return {AssignmentWorkQuestion|AssignmentQuestion}
   */
  get question() {
    return this._question;
  }

  get points() {
    return this._contentQuestion.points;
  }

  set points(value) {
    this._contentQuestion.points = value;
  }

  get score() {
    return this.isWork ? this._question.points : AssignmentWorkQuestion.UNGRADED;
  }

  set score(value) {
    this._handleScoreUpdated(this._student.id, this._question.id, value);
  }

}

export default class SessionQuestionController {
  constructor($q, $scope, $state, $stateParams, $document, $location, $timeout, $mdToast, $mdDialog, $mdPanel, $window,
              $log, BreadcrumbService, CacheService, AnalyticsService, BulkUpdateService) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$document = $document;
    this.$location = $location;
    this.$timeout = $timeout;
    this.$mdToast = $mdToast;
    this.$mdDialog = $mdDialog;
    this.$mdPanel = $mdPanel;
    this.$log = $log;
    this._window = angular.element($window);

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BulkUpdateService} */
    this._bulkUpdateService = BulkUpdateService;

    this._assignmentId = $stateParams.assignmentId;
    this._rosterId = $stateParams.rosterId;

    this._error = null;

    /** @type {SessionData} */
    this._sessionData = null;

    this._selectedStudentsAndQuestions = {};
    this._selectedStudents = {};
    this._hoverStudentsAndQuestions = {};
    this._coverSlideStatus = 0;

    this._loadingDialog = LoadingDialog.show;
    this._showPeerHelpInbox = PeerHelpInboxController.show;
    this._showStudentInfoMenu = StudentInfoMenuController.show;
    this._showStudentAccountHelpDialog = StudentAccountHelpDialogController.show;
    this._helpDialog = ViewHelpDialogController.show;
    this._confirmDialog = ConfirmDialogController.show;
    this._errorDialog = ErrorDialogController.show;

    this._columnCount = 3;

    this._resetLayoutDebounce = new Debouncer(1000, 5000, this._resetLayout.bind(this));
    this._sortAndResetLayoutDebounce = new Debouncer(1000, 5000, () => {
      this.data.sortStudents();
      this._resetLayout();
    });
    this._anonResetLayoutDebounce = () => this._resetLayoutDebounce.tick();

    $scope.$on('$destroy', () => this._destroy());

    this._init();
  }

  _init() {
    this._cacheService.getSessionData(this._assignmentId, this._rosterId, false, true)
      .then((data) => {
        this._sessionData = data;

        if (!this.data.isComplete) {
          if (!this.data.hasRosters) {
            this._error = new Error('There are no rosters associated with this assignment.');
            this._error.noRosters = true;
            return undefined;
          }
          else {
            this.goToNewRoster(this.data.assignmentRosters[0].id);
            return undefined;
          }
        }

        this.data.updated.subscribe(this._onSessionDataUpdated, this);
        this.data.start();

        this.data.configureSortFromStorage();

        this._initLayout();

        this._analyticsService.sessionQuestionViewed(this.questionNumber);
      })
      .catch((err) => {
        this._error = err;
        throw err;
      });
  }

  //--------------- Layout methods -------------------------

  /**
   * @return {number}
   */
  get questionNumber() {
    return this.$stateParams.questionNumber;
  }

  /**
   * @return {number}
   */
  get questionIndex() {
    return this.questionNumber - 1;
  }

  _initLayout() {
    this._window.on('resize', this._anonResetLayoutDebounce);

    this.$timeout(() => {
      this._bodyContent = angular.element('.session-question .body-content');
      this._cellWidth = angular.element('.session-question .body-head .question-cell-header').outerWidth(true);
      this._resetLayout();
    }, 0, true);
  }

  _resetLayout() {
    this._padding = 15;
    this._bodyWidth = this._bodyContent.width() - this._padding;
    this._thumbnailWidth = this._bodyWidth / this._columnCount;
    this._thumbnailHeight = this._thumbnailWidth * 0.85;
    this._answerMaxWidth = this._thumbnailWidth - this._padding * 3;

    this._thumbnails = this._filterForSelectedQuestion();
  }

  /**
   * @return {SessionQuestionThumbnail[]}
   */
  get thumbnails() {
    return this._thumbnails;
  }

  /**
   * @return {SessionQuestionThumbnail[]}
   */
  _filterForSelectedQuestion() {
    return this._sortStudents(this.data.sortedStudents.slice()).map((student) => {
      return new SessionQuestionThumbnail(
        student,
        this.data.worksByStudent.get(student.id),
        this.data.assignment,
        this.$stateParams.questionNumber - 1,
        this._handleScoreUpdated.bind(this)
      );
    });
  }

  /**
   * If sorting by grades, sort by current question's grade
   * @param users {User[]}
   * @return {User[]}
   */
  _sortStudents(users) {
    const currentSort = this.data.studentSort;

    if (currentSort.name === StudentSortOptions.GradesAsc) {
      let questionIndex = this.questionIndex;
      return users.sort((a, b) => {
        return this.studentQuestionScore(a.id, questionIndex) < this.studentQuestionScore(b.id, questionIndex) ? -1 : 1;
      });
    }
    else if (currentSort.name === StudentSortOptions.GradesDesc) {
      let questionIndex = this.questionIndex;
      return users.sort((a, b) => {
        return this.studentQuestionScore(a.id, questionIndex) < this.studentQuestionScore(b.id, questionIndex) ? 1 : -1;
      });
    }
    else {
      return users;
    }
  }

  /**
   * @param userId {string}
   * @param index {number}
   */
  studentQuestionScore(userId, index) {
    let work = this.data.worksByStudent.get(userId);
    let question = work ? work.questionForIndex(index) : undefined;
    return (question && angular.isNumber(question.points)) ? question.points : -1;
  }

  get thumbnailStyle() {
    return {
      height: this._thumbnailHeight,
      paddingBottom: this._padding * 2
    };
  }

  get answerStyle() {
    return {
      maxWidth: this._answerMaxWidth
    };
  }

  _debounceApply() {
    if (this._debounceApplyPromise) {
      this.$timeout.cancel(this._debounceApplyPromise);
    }
    this._debounceApplyPromise = this.$timeout(() => {}, 200);
  }

  _destroy() {
    this._window.off('resize', this._anonResetLayoutDebounce);
    this.data.updated.unsubscribe(this._onSessionDataUpdated, this);
  }

  //--------------- Data methods -------------------------

  /**
   * @returns {SessionData}
   */
  get data() {
    return this._sessionData;
  }

  /**
   * @param event {{code: string}}
   * @private
   */
  _onSessionDataUpdated(event) {

    const { code, userIsNowOnline, userIsLoggingOnForFirstTime, type } = event;

    if (code === SessionDataCodes.ROSTER_MEMBER) {
      this._sortAndResetLayoutDebounce.tick();
    }
    else if (code === SessionDataCodes.STUDENT_SORT ||
      code === SessionDataCodes.STUDENT_HIDE_OFFLINE ||
      code === SessionDataCodes.STUDENT_HIDE_UNSTARTED) {
      this._resetLayoutDebounce.tick();
    }
    else if (code === SessionDataCodes.STUDENT_STATUS && (userIsNowOnline || userIsLoggingOnForFirstTime)) {
      this._sortAndResetLayoutDebounce.tick();
    }
    else if (code === SessionDataCodes.BULK_UPDATE
      && (type === BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION.TYPE || type === undefined)) {
      this._selectedStudentsAndQuestions = {};
      this._selectedStudents = {};
    }

    this._debounceApply();
  }

  get error() {
    return this._error;
  }

  get loaded() {
    return !this.error && !this.loading;
  }

  get loading() {
    return !(this.data) && !this.error;
  }

  assignmentSheetConfig(userId, questionId) {
    if (!this.loaded) {
      return null;
    }

    return this.data.assignmentSheetConfig(userId, questionId);
  }

  //-------------------- Navigate Methods ------------------------------

  get viewType() {
    return this.$location.$$url.includes('work') ? 'wcv':'sqv';
  }

  get feedbackType(){
    return this.data.placingSticker ? 'sticker' : 'coverSlides';
  }

  goToViewAllQuestions() {
    this.data.sessionQuestionNumber = this.data.ViewAllSlides;
    this.goToSessionWork();
  }

  goToSessionWork() {
    this._breadcrumbService.go(
      'root.account.session.work',
      {
        ...this.$stateParams,
        assignmentId: this._assignmentId,
        rosterId: this._rosterId
      },
      true
    );
  }

  goToNewRoster(rosterId) {
    this._breadcrumbService.go(
      this.$state.current.name,
      {
        ...this.$stateParams,
        assignmentId: this._assignmentId,
        rosterId: rosterId
      },
      true
    );
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   */
  navToFeedbackQuestion(userId, questionId) {
    this._breadcrumbService.go(
      'root.account.session.question.feedback',
      {
        assignmentId: this._assignmentId,
        questionId: questionId,
        assignmentWorkId: this.data.workForStudent(userId) && this.data.workForStudent(userId).id
      }
    );
  }

  openPeerHelpInbox($event, user) {
    let helpHistory = this.data.studentHelpHistorySortedChronologically(user.id);
    let helpers = this.data.studentActiveHelpers(user.id);

    this._showPeerHelpInbox(this.$mdPanel, this.$q, $event, user, helpHistory, helpers, this.data.students, this.data.assignment)
      .then(({userId, questionId, event}) => {
        this.navToFeedbackQuestion(userId, questionId, event);
      });
  }

  showHiddenStudents() {
    this.data.showHiddenStudents();
    this._resetLayout();
  }

  //----------------- Grade and Sticker Logic ---------------------------

  /**
   * If the user presses the 'tab' key, we determine the next input to go to and focus it
   * @param event
   * @param index
   */
  handleGradeKeydown(event, index) {
    if (event.which === 9) {
      let nextThumbnail = this._thumbnails[index + 1] || this._thumbnails[0];

      if (event.shiftKey) {
        nextThumbnail = this._thumbnails[index - 1] || this._thumbnails[this._thumbnails.length -1];
      }

      this.focusGradeInput(nextThumbnail.student.id, true);
      event.preventDefault();
    }
  }

  /**
   * @param userId {string}
   * @param [select] {boolean}
   */
  focusGradeInput(userId, select = false) {
    let nextElement = angular.element(`#${userId} grade-input form input`);
    nextElement.focus();
    if (select) {
      nextElement.select();
    }
  }

  /**
   * Called when the value of grade input is changed
   * @param studentId {string}
   * @param questionId {string}
   * @param newScore {number|string} a number representing the new score or an "ungraded" string
   */
  _handleScoreUpdated(studentId, questionId, newScore) {
    this.getOrCreateWork(studentId).then((work) => {
      this.focusGradeInput(studentId);
      return this.data.updateScore(work, studentId, questionId, newScore);
    });
  }

  /**
   * @param studentId {string}
   * @param questionId {string}
   * @param event {jQuery.Event}
   */
  handleThumbnailClicked(userId, questionId, event) {
    event.stopPropagation();

    if (this.data.bulkUpdateOption && (this.data.placingSticker || this.data.placingStop)) {
      if (!Object.keys(this._selectedStudentsAndQuestions).length){
        this.data.placingSticker = undefined;
        this.data.placingStop = undefined;
        return this._errorDialog(this.$mdDialog, 'Before applying changes, please ensure you have selected<br> slides by checking for a green outline around them', '');
      }

      return this.applyBulkUpdate();
    }

    else if (this.data.bulkUpdateOption && (!this.data.placingSticker && !this.data.placingStop)) {
      return this.selectThumbnails(userId, questionId);
    }

    else if (this.data.placingSticker) {
      return this.placeSticker(userId, questionId, this.data.placingSticker);
    }

    else if (this.data.placingStop) {
      return this.placeStop(userId, questionId, this.data.placingStop);
    }

    else {
      return this.navToFeedbackQuestion(userId, questionId);
    }
  }

  applyBulkUpdate() {
    this._analyticsService.bulkUpdateApplied({
      viewType: this.viewType,
      feedbackType: this.feedbackType,
      selectedBulkUpdateOption: this.data.bulkUpdateOption,
      feedbackTotal: Object.keys(this._selectedStudentsAndQuestions).length,
      studentTotal:  Object.keys(this._selectedStudents).length,
      sticker:this.feedbackType === 'sticker' ? this.data.placingSticker : null
    });

    let promises;
    //this is to avoid calling assignment-works endpoint too many times,
    // need to wait for promise to finish for getOrCreateWork before applying bulk updates
    if (this.data.bulkUpdateOption === BulkMenuOptions.ONE_STUDENT_ALL_QUESTIONS.TYPE
      || this.data.bulkUpdateOption === BulkMenuOptions.CHOOSE_STUDENTS_AND_QUESTIONS.TYPE) {
      promises = Object.keys(this._selectedStudents).map((studentId) => {
        return this.getOrCreateWork(studentId)
          .then(() => {
            return Object.values(this._selectedStudentsAndQuestions).forEach((studentAndQuestion) => {
              if (studentAndQuestion.userId === studentId) {
                return this.tallySlidesCoveredAndApplyChanges(studentAndQuestion);
              }
            });
          });
      });
    } else {
      promises = Object.values(this._selectedStudentsAndQuestions).map((studentAndQuestion) => {
        this.tallySlidesCoveredAndApplyChanges(studentAndQuestion);
      });
    }

    const promise = Promise.all(promises)
      .catch((error) => {
        this.$log.error(error);
        return this._errorDialog(this.$mdDialog, 'Sorry there was an issue with bulk updates, please try again', '');
      });

    this._loadingDialog(this.$mdDialog, promise);

    return promise
      .then(() => {
        this.data.placingSticker = undefined;
        this.data.placingStop = undefined;
        this._coverSlideStatus = 0;
        let config = this.$mdToast.simple().textContent('Bulk update completed').position('bottom right');
        this.$mdToast.show(config);
      })
      .catch((error) => {
        this.$log.error(error);
        return this._errorDialog(this.$mdDialog, 'Sorry there was an issue with bulk updates, please try again', '');
      });
  }


  /**
   * @param studentAndQuestion {object}
   */
  tallySlidesCoveredAndApplyChanges(studentAndQuestion){
    this.getOrCreateWork(studentAndQuestion.userId)
      .then((work) => {
        let workQuestion = work.questionForId(studentAndQuestion.questionId);
        let elements = workQuestion.elements;
        let currentSlideForeground = elements.find((element) => element.type === SlideForeground.type);
        if (currentSlideForeground) {
          this._coverSlideStatus++;
        } else {
          this._coverSlideStatus--;
        }
        return this.placeStickerOrHideSlide(studentAndQuestion.userId, studentAndQuestion.questionId);
      });
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   */
  selectThumbnails(userId, questionId) {
    if (this.data.bulkUpdateOption === BulkMenuOptions.CHOOSE_STUDENTS_AND_QUESTIONS.TYPE) {
      if (this._selectedStudentsAndQuestions[`${userId}-${questionId}`]) {
        delete this._selectedStudentsAndQuestions[`${userId}-${questionId}`];
      } else {
        this._selectedStudentsAndQuestions[`${userId}-${questionId}`] = { userId, questionId };
        this._selectedStudents[userId] = true;
      }
    }

    if (this.data.bulkUpdateOption === BulkMenuOptions.ALL_STUDENTS_ONE_QUESTION.TYPE) {
      const allSelected = this.data.virtualRepeatSortedStudents._sortedStudents.every((student) => {
        return !!this._selectedStudentsAndQuestions[`${student.id}-${questionId}`];
      });

      this.data.virtualRepeatSortedStudents._sortedStudents.map((student) => {
        if (this._selectedStudentsAndQuestions[`${student.id}-${questionId}`] && allSelected) {
          delete this._selectedStudentsAndQuestions[`${student.id}-${questionId}`];
        } else {
          this._selectedStudentsAndQuestions[`${student.id}-${questionId}`] = {
            userId: student.id,
            questionId
          };
          this._selectedStudents[student.id] = true;
        }
      });
    }

    if (this.data.bulkUpdateOption === BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION.TYPE) {
      this._selectedStudentsAndQuestions = {};
      this._selectedStudents = {};
      this.data.bulkUpdateOption = undefined;
      this._bulkUpdateService.bulkUpdateChanged({
        option: this.data.bulkUpdateOption,
        thumbnailsSelected: Object.keys(this._selectedStudentsAndQuestions).length
      });
      return this.navToFeedbackQuestion(userId, questionId);
    }

    this._bulkUpdateService.bulkUpdateChanged({
      option: this.data.bulkUpdateOption,
      thumbnailsSelected: Object.keys(this._selectedStudentsAndQuestions).length
    });
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   */
  placeStickerOrHideSlide(userId, questionId) {
    return this.data.placingSticker
      ? this.placeSticker(userId, questionId, this.data.placingSticker)
      : this.placeStop(userId, questionId, this.data.placingStop);
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @param stop {SlideForeground}
   */
  placeStop(userId, questionId, stop) {
    return this.getOrCreateWork(userId)
      .then((work) => {
        // check if there is an existing SlideForeground
        // if so, remove it, otherwise, add one
        let workQuestion = work.questionForId(questionId);
        let elements = workQuestion.elements;
        let currentSlideForeground = elements.find((element) => element.type === SlideForeground.type);

        if (this.data.bulkUpdateOption) {
          //if there are more cover slides than uncovered slides, un-hide them
          if (this._coverSlideStatus > 0) {
            if (currentSlideForeground) {
              this._analyticsService.coverSlideActivity(Locations.ASSIGNMENT_VIEW_WORK, questionId, 'removed');
              this.data.removeStop(work, questionId, currentSlideForeground);
            } else {
              return;
            }
          } else {
            // if there are less cover slides than uncovered slides
            // or there is an equal amount of covered and uncovered slides, hide them
            if (currentSlideForeground) {
              return;
            } else {
              this._analyticsService.coverSlideActivity(Locations.ASSIGNMENT_VIEW_WORK, questionId, 'added');
              this.data.placeStop(work, userId, questionId, stop);
            }
          }
        } else {
          if (currentSlideForeground) {
            this._analyticsService.coverSlideActivity(Locations.ASSIGNMENT_VIEW_WORK, questionId, 'removed');
            this.data.removeStop(work, questionId, currentSlideForeground);
          } else {
            this._analyticsService.coverSlideActivity(Locations.ASSIGNMENT_VIEW_WORK, questionId, 'added');
            this.data.placeStop(work, userId, questionId, stop);
          }
        }
      });
  }

  /**
   * @param userId {string}
   * @param questionId {string}
   * @param sticker {UserSticker}
   */
  placeSticker(userId, questionId, sticker) {
    return this.getOrCreateWork(userId).then((work) => {
      this.data.placeSticker(work, userId, questionId, sticker);
    });
  }

  /**
   * @param studentId
   * @return {Promise<AssignmentWork>}
   */
  getOrCreateWork(studentId) {
    let work = this.data.workForStudent(studentId);
    return work
      ? this.$q.resolve(work)
      : this.fetchDataForStudent(studentId)
        .catch((error) => {
          throw error;
      });
  }

  /**
   * @param userId {string}
   * @return {Promise<AssignmentWork>}
   */
  fetchDataForStudent(userId) {
    let promise = this.data.fetchDataForStudent(userId)
      .then((value) => {
        if (value instanceof Error) {
          throw value;
        } else {
          return this.data.workForStudent(userId);
        }
      })
      .catch((error) => {
        throw error;
      });

    if (!this.data.bulkUpdateOption) {
      this._loadingDialog(this.$mdDialog, promise, this.$document, '.session-question .body-content');
    }

    return promise;
  }

  showStudentInfoMenu(event, user) {
    this._showStudentInfoMenu(this.$mdPanel, this.$q, event, user, this.data.studentLastSeen(user.id), this.data.pro, this._rosterId);
  }

  openStudentAccountHelpDialog() {
    this._showStudentAccountHelpDialog(this.$mdDialog, this.data.pro);
  }

  openGivingFeedbackHelp() {
    this._helpDialog(this.$mdDialog, ViewHelps.GivingFeedback);
  }

  isSelectedCell(userId, questionId){
    return !!this._selectedStudentsAndQuestions[`${userId}-${questionId}`];
  }

  canHover(){
    if (this.data.bulkUpdateOption !== BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION.TYPE
      && this.data.bulkUpdateOption !== undefined) {
      if (!this.data.placingStop && !this.data.placingSticker) {
        return true;
      }
    }
    return false;
  }

  isHovering(userId, questionId){
    return !!this._hoverStudentsAndQuestions[`${userId}-${questionId}`]
      && !this.isSelectedCell(userId, questionId) && this.canHover();
  }

  highlightCells(userId, questionId) {
    if (this.data.bulkUpdateOption === undefined
      || this.data.bulkUpdateOption === BulkMenuOptions.CLEAR_BULK_UPDATE_SELECTION.TYPE) {
      return;
    }

    if (this.data.bulkUpdateOption === BulkMenuOptions.ALL_STUDENTS_ONE_QUESTION.TYPE) {
      this._hoverStudentsAndQuestions = {};
      this.data.virtualRepeatSortedStudents._sortedStudents.map((student) => {
        this._hoverStudentsAndQuestions[`${student.id}-${questionId}`] = {
          userId: student.id,
          questionId
        };
      });
    }

    if (this.data.bulkUpdateOption === BulkMenuOptions.CHOOSE_STUDENTS_AND_QUESTIONS.TYPE) {
      this._hoverStudentsAndQuestions = {};
      this._hoverStudentsAndQuestions[`${userId}-${questionId}`] = {
        userId,
        questionId
      };
    }
  }

  clearHighlightedCells(){
    this._hoverStudentsAndQuestions = {};
  }
}
