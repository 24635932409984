'use strict';

import { HelpArticleCategories, HelpArticleSections } from '../../model/domain/help-article';
import { ViewHelps } from '../../components/view-help-dialog/view-help-dialog.controller';
import { TeacherHelpCenterViews } from '../../services/mixpanel/mixpanel.service';

export default class HelpController {

  /**
   * @ngInject
   */
  constructor($q, $state, $mdDialog, $stateParams, StaticService, CacheService, AuthService, BreadcrumbService, HelpArticleService, AnalyticsService) {

    this.$q = $q;
    this.$state = $state;
    this.$mdDialog = $mdDialog;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {HelpArticleService} */
    this._helpArticleService = HelpArticleService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this._displayingInIpadApp = StaticService.isClasskickIPadApp;
    this._userEmail = $stateParams.userEmail;

    // When a user lands on help center, we don't need articles but we want to start loading them now so it's fast
    // when they visit one of the other pages
    this._cacheService.getArticles(false);

    this._analyticsService.teacherHelpCenter(TeacherHelpCenterViews.Main);
  }

  returnToClasskick() {
    this.$state.go('root.account.home');
  }

  goTo(categoryId) {
    this._breadcrumbService.go('root.help-category', {
      categoryId
    });
  }

  get WhatIsClasskick() {
    return HelpArticleCategories.WhatIsClasskick;
  }

  get GettingStarted() {
    return HelpArticleCategories.GettingStarted;
  }

  get BeyondTheBasics() {
    return HelpArticleCategories.BeyondTheBasics;
  }

  get BeyondTheBasics_Collaboration() {
    return HelpArticleSections.BeyondTheBasics_Collaboration;
  }

  get Troubleshooting() {
    return HelpArticleCategories.Troubleshooting;
  }

  get ConnectWithTeacher() {
    return HelpArticleCategories.ConnectWithTeacher;
  }

  get ProfessionalDevelopment() {
    return HelpArticleCategories.ProfessionalDevelopment;
  }

  get LearnAboutFeatures() {
    return HelpArticleCategories.LearnAboutFeatures;
  }

  get ClasskickProUsers() {
    return HelpArticleCategories.ClasskickProUsers;
  }

  goToSection(sectionId) {
    this.$state.go('root.help-section', {
      sectionId
    });
  }

  goToArticle(articleId) {
    this.$state.go('root.help-article', {
      articleId
    });
  }

  goToHowToGenerateAClassCode() {
    this._helpArticleService.search('How to generate a class code?').then((results) => {
      let articleId = results[0] && results[0].id;
      this.goToArticle(articleId);
    });
  }

  get hidePro() {
    return this._displayingInIpadApp;
  }

  get hideOutsideLinks() {
    return this._displayingInIpadApp;
  }
}
