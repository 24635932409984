'use strict';

import SharedWorkCodec from '../../model/codec/shared-work.codec';

export default class SharedWorksService {
  constructor(environment, HttpService, FirebaseService, $q) {
    'ngInject';

    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    this.$q = $q;

    this._sharedWorkCodec = new SharedWorkCodec(this._firebaseService);
  }

  /**
   * Shares assignment work
   *
   * @param assignmentWorkId {string}
   * @param emails {string[]}
   * @returns {Promise.<*>}
   */
  shareAssignmentWork(assignmentWorkId, emails) {
    return this._httpService
      .authPost(this._uri('/v1/shared-works'),
        {
          'work_id': assignmentWorkId,
          emails
        }
      ).then((data) => {
        return data;
      });
  }

  /**
   * Gets an assignment work by shared work id
   *
   * @param sharedWorkId {string}
   * @returns {Promise.<*>}
   */
  getSharedAssignmentWork(sharedWorkId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/shared-works/${sharedWorkId}`)
      .then((sharedAssignmentWorkInfo) => {
        return this._sharedWorkCodec.decode(sharedAssignmentWorkInfo);
      });
  }

  /**
   * Returns a string that specifies a uri given a path.
   *
   * @param path {string}
   * @returns {string}
   * @private
   */
  _uri(path) {
    return `${this._environment.serverUrlBase}${path}`;
  }
}
