'use strict';

import Control from './control';
import Size from './size';
import GestureManager from './gesture-manager';
import HexColors from '../../css-constants';
import moreOptionsIcon from '../../../assets/colored-icons/moreoptions-white.svg';

export default class MoreOptionsHandle extends Control {

  constructor(target) {
    super(target.id, 'more-options');

    if (angular.isUndefined(target.handleMoreOptionsClicked)) {
      throw new Error('onMoreOptionsClicked method needs to be defined on the target object');
    }

    this._size = new Size(32, 32);
    this._iconDiameter = 14;
    this._target = target;
    this._gestureManager = new GestureManager(this._target, this._target.canvas);
  }

  createElement(root) {
    this._base = root.group();
    this._background = this._base.circle(0, 0, 0);
    this._trashIcon = this._base.image(moreOptionsIcon, 0, 0, 0, 0);

    this._interactive = root.group();
    this._touch = this._interactive.circle(0, 0, 0).addClass('touch-foreground');

    this._gestureManager.start(this._interactive.node);
    this._gestureManager.click.subscribe(this._target.handleMoreOptionsClicked, this._target);
  }

  update() {

    this._base.attr({
      visibility: this._visibility
    });

    this._background.attr({
      cx: this.location.x,
      cy: this.location.y,
      r: this.width / 2,
      fill: HexColors.CK_GREEN
    });

    if (angular.isNumber(this._target.rotation)) {
      this._trashIcon.node.setAttribute('transform', `rotate(${360 - this._target.rotation }, ${this.location.x}, ${this.location.y})`);
    }

    this._trashIcon.attr({
      x: this.location.x - (this._iconDiameter / 2),
      y: this.location.y - (this._iconDiameter / 2),
      width: this._iconDiameter,
      height: this._iconDiameter,
      preserveAspectRatio: 'xMidYMid meet',
      style: 'pointer-events:none;'
    });

    this._touch.attr({
      cx: this.location.x,
      cy: this.location.y,
      r: this.width / 2,
      fill: 'transparent',
      cursor: 'pointer'
    });
  }

  remove() {
    this._gestureManager.stop();
    this._gestureManager.click.unsubscribe(this._target.handleMoreOptionsClicked, this._target);
    super.remove();
  }

  get hovering() {
    return this._gestureManager.hovering;
  }

  get mouseEnter() {
    return this._gestureManager.mouseEnter;
  }

  get mouseLeave() {
    return this._gestureManager.mouseLeave;
  }
}
