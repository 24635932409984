
'use strict';

import Control from '../control';
import StaticService from '../../../services/static/static.service.js';
import Point from '../point';
import Size from '../size';
import Textbox from './textbox';

/**
 * Manages a blinking cursor
 */
export default class TextboxCursor extends Control {
  /**
   * @param target {TextboxBase}
   * @param [info] {CursorInfo}
   * @param [color] {string}
   */
  constructor(target, info, color) {
    super('cursor', 'cursor');
    this._info = info;
    this._size = new Size(1.5, 10);
    this._visible = true;
    this._target = target;
    this._color = color || Textbox.DEFAULT_TEXT_COLOR;
  }

  createElement(root) {
    this._rect = root.rect(0,0,0,0);
  }

  update() {
    if (this._info) {
      this._size._height = this._info.height;
      this._location = new Point(this._info.location.x + this._target.offset.x, this._info.location.y + this._target.offset.y);
    }

    this._rect.attr({
      x: this._location.x,
      y: this._location.y,
      width: this.width,
      height: this.height,
      fill: this._color
    });
  }

  /**
   * @returns {CursorInfo}
   */
  get info() {
    return this._info;
  }

  /**
   * @param value {CursorInfo}
   */
  set info(value) {
    this._info = value;
    this.show();
  }

  /**
   * @returns {boolean}
   */
  get visible() {
    return this._visible;
  }

  /**
   * @returns {string}
   */
  get color() {
    return this._color;
  }

  /**
   * @param value {string}
   */
  set color(value) {
    this._color = value;
  }

  /**
   * @param [info] {CursorInfo}
   */
  show(info) {
    if (info) {
      this.info = info;
    }

    this.hide();

    this._visible = true;
    this.visibility = 'inherit';

    this._timer = StaticService.get.$window.setInterval(() => {
      this.toggleVisibility();
    }, 500);
  }

  hide() {
    StaticService.get.$window.clearInterval(this._timer);
    this.visibility = 'hidden';
    this._visible = false;
  }

  /**
   * Toggles the cursor on or off, opposite the current visible state
   */
  toggleVisibility() {
    this.visibility = this.visibility === 'inherit' ? 'hidden' : 'inherit';
  }
}

export class CursorInfo {

  /**
   * @param location {Point}
   * @param index {number}
   * @param before {boolean}
   * @param height {number}
   * @param target {number|Point}
   */
  constructor(location, index, before, height, target) {
    this.location = location;
    this.index = index;
    this.before = before;
    this.height = height;
    this.target = target;
  }

  get targetIsIndex() {
    return angular.isNumber(this.target);
  }

  get targetIsPoint() {
    return !this.targetIsIndex;
  }

  get textIndex() {
    return this.before ? this.index : this.index - 1;
  }
}
