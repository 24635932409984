'use strict';

import Codec from './codec';
import OrganizationUsageStats from '../domain/organization-usage-stats';

/**

 Encodes/Decodes organization usage stats
 */
export default class OrganizationUsageStatsCodec extends Codec {
    constructor() {
        super();
    }

  /**

   @param value {OrganizationUsageStats}
   @return {
    {total_teachers: number,
    total_assignments: number,
    total_students: number,
    total_help_requests_resolved: number,
    active_teachers: object[],
    teachers_active_this_year: number,
    assignments_created_this_year: number,
    assignments_worked_on_this_year: number,
    assignment_questions_worked_on_this_year: number,
    rosters_created_this_year: number,
    help_requests_resolved_this_year: number
    }}
   */
    encode(value) {
        return {
            total_teachers: value.totalTeachers,
            total_assignments: value.totalAssignments,
            total_students: value.totalStudents,
            total_help_requests_resolved: value.totalHelpRequestsResolved,
            active_teachers: value.activeTeachers,
            teachers_active_this_year: value.teachersActiveThisYear,
            students_active_this_year: value.studentsActiveThisYear,
            assignments_created_this_year: value.assignmentsCreatedThisYear,
            assignments_worked_on_this_year: value.assignmentsWorkedOnThisYear,
            assignment_questions_worked_on_this_year: value.assignmentQuestionsWorkedOnThisYear,
            rosters_created_this_year: value.rostersCreatedThisYear,
            help_requests_resolved_this_year: value.helpRequestsResolvedThisYear,
        };
    }

    /**

     @param value {Object}
     @return {OrganizationUsageStats|null}
     */
    decode(value) {
        if (value) {
            return new OrganizationUsageStats(
                value.organization_stats.total_teachers,
                value.organization_stats.total_assignments,
                value.organization_stats.total_students,
                value.organization_stats.total_help_requests_resolved,
                value.organization_stats.active_teachers,
                value.organization_stats.teachers_active_this_year,
                value.organization_stats.students_active_this_year,
                value.organization_stats.assignments_created_this_year,
                value.organization_stats.assignments_worked_on_this_year,
                value.organization_stats.assignment_questions_worked_on_this_year,
                value.organization_stats.rosters_created_this_year,
                value.organization_stats.help_requests_resolved_this_year,
            );
        } else {
            return null;
        }
    }
}
