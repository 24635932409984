/*global DD_RUM:false */
'use strict';

import { datadogRum } from '@datadog/browser-rum';

/**
 * Contains datadog library interactions
 * Ref: https://www.npmjs.com/package/@datadog/browser-rum
 */
export default class DatadogRumService {

  constructor($log, environment, constants) {
    'ngInject';

    this.$log = $log;
    this._environment = environment;
    this._constants = constants;
    this._initialized = false;
    this._userId = '';

    if (!this._environment.dataDog.enabled) {
      this.logTrace('disabled');
      return;
    }

    this.init();
  }

  init() {
    if (this._initialized) {
      return;
    }

    datadogRum.init({
      beforeSend: (event) => {
        event.view.url = event.view.url.replace('/#', '');
      },
      clientToken: this._environment.dataDog.clientToken,
      applicationId: this._environment.dataDog.applicationId,
      site: this._environment.dataDog.site,
      service: this._environment.dataDog.service,
      env: this._environment.dataDog.env,
      version: this._constants.version,
      sampleRate: this._environment.dataDog.sampleRate,
      trackInteractions: this._environment.dataDog.trackInteractions,
      silentMultipleInit: true
    });

    this._initialized = true;
    this.logTrace('initialized');
  }

  /**
   * send metric to DataDog's RUM addTiming
   * @param name timing metric to be sent to DataDog
   * @param time custom date timestamp, without the param, it uses DataDog's timestamp
   */
  addTiming(name, time = undefined) {
    if (angular.isUndefined(DD_RUM)) {
      return;
    }

    if (angular.isUndefined(time)) {
      DD_RUM.addTiming(name);
    } else {
      DD_RUM.addTiming(name, time);
    }
  }

  logTrace(...args) {
    if (this._environment.dataDog.traceLogging === 'true') {
      this.$log.debug('DataDog:', ...args);
    }
  }

  setUserId (id) {
    this._userId = id;
  }

  addUserAndClassInfo(assignmentId, rosterId) {
    if (angular.isObject(assignmentId)){
      datadogRum.setUser({
        id: this._userId,
        assignmentId: null,
        rosterId: null
      });
    } else {
      datadogRum.setUser({
        id: this._userId,
        assignmentId,
        rosterId
      });
    }
  }
}
