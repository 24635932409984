'use strict';

import moment from 'moment';
import Organization, { OrganizationTypes } from '../../model/domain/organization';
import Order from '../../model/domain/order';
import Validation from '../../model/util/validation';
import CreateOrderDialogTemplate from './create-order-dialog.html';
import ErrorDialogController from '../error-dialog/error-dialog.controller';
import ErrorCodes from '../../model/domain/error-codes';

export default class CreateOrderDialogController {

  /**
   * @ngInject
   */
  constructor($log, $mdDialog, GooglePlacesService, OrganizationService, BreadcrumbService, IPStackService, OrderService, orgType, quantity, name, email) {
    this.$log = $log;
    this.$mdDialog = $mdDialog;

    /** @type {GooglePlacesService} */
    this._googlePlacesService = GooglePlacesService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {IPStackService} */
    this._ipStackService = IPStackService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;

    this._state = this.IdentifyOrganization;

    this._orgType = orgType;
    this._organization = undefined;
    this._organizationName = '';
    this._quantity = quantity || 1;
    this._positiveIntegerRegEx = /^[0-9]*[1-9]+$|^[1-9]+[0-9]*$/;
    this._name = name || '';
    this._email = email || '';
    this._hideThisYear = undefined;
    this._hideFirstYearCheckbox = undefined;
    this._renewal = false;
    this._prices = undefined;
    this._selectedStart = undefined;
    this._selectedEnd = undefined;

    this._isValidPurchasePeriod = undefined;

    this._newOrganizationName = '';
    this._newOrganizationLocationDisplay = '';
    this._newOrganizationLocation = undefined;
    this._errorDialog = ErrorDialogController.show;
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @param value {string}
   */
  set state(value) {
    this._state = value;
  }

  get Loading() {
    return 'loading';
  }

  get IdentifyOrganization() {
    return 'identify_organization';
  }

  get IdentifyPerson() {
    return 'identify_person';
  }

  get IdentifyPurchasePeriod() {
    return 'identify_purchase_period';
  }

  get AddOrganization() {
    return 'add_organization';
  }

  get orgType() {
    return this._orgType;
  }

  get School() {
    return OrganizationTypes.School;
  }

  get District() {
    return OrganizationTypes.District;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get email() {
    return this._email;
  }

  set email(value) {
    this._email = value;
  }

  get emailPattern() {
    return Validation.EmailPattern;
  }

  get renewal() {
    return this._renewal;
  }

  get virtual() {
    return this._organization && this._organization.virtual;
  }

  set virtual(value) {
    if (this._organization) {
      this._organization.virtual = value;
    }
  }

  get prices() {
    return this._prices;
  }

  /**
   * @return {string}
   */
  get organizationName() {
    return this._organizationName;
  }

  /**
   * @param value {string}
   */
  set organizationName(value) {
    this._organizationName = value;
  }

  /**
   * @return {undefined|Organization}
   */
  get organization() {
    return this._organization;
  }

  /**
   * @param value {undefined|Organization}
   */
  set organization(value) {
    this._organization = value;
  }

  /**
   * @return {number|null}
   */
  get quantity() {
    return this._quantity;
  }

  /**
   * @param value {number|null}
   */
  set quantity(value) {
    if (value === null || angular.isUndefined(value)) {
      value = '';
    }

    this._quantity = angular.isNumber(value) ? Math.abs(parseInt(value)) : value;
  }

  incrementQuantity() {
    this.quantity = this._quantity + 1;
  }

  decrementQuantity() {
    this.quantity = this._quantity - 1;
  }

  /**
   * @return {RegExp}
   */
  get positiveIntegerRegEx() {
    return this._positiveIntegerRegEx;
  }

  // ------------- Add / Identify Organization Logic ------------------

  /**
   * @return {string}
   */
  get newOrganizationName() {
    return this._newOrganizationName;
  }

  /**
   * @param value {string}
   */
  set newOrganizationName(value) {
    this._newOrganizationName = value;
  }

  /**
   * @return {string}
   */
  get newOrganizationLocationDisplay() {
    return this._newOrganizationLocationDisplay;
  }

  /**
   * @param value {string}
   */
  set newOrganizationLocationDisplay(value) {
    this._newOrganizationLocationDisplay = value;
  }

  /**
   * @return {undefined|GooglePlacePrediction}
   */
  get newOrganizationLocation() {
    return this._newOrganizationLocation;
  }

  /**
   * @param value {undefined|GooglePlacePrediction}
   */
  set newOrganizationLocation(value) {
    this._newOrganizationLocation = value;
  }

  addOrganization(scope) {

    // Check that the input form is valid
    if (scope.enterOrganizationDetailsForm.$invalid) {
      return;
    }

    this.state = this.Loading;

    this._createOrganization(this.newOrganizationLocation.placeId, this.newOrganizationName)
      .then((organization) => {
        this._organization = organization;
        this._organizationName = organization.name;

        this._hideThisYear = false;
        this._hideFirstYearCheckbox = true;
        this._renewal = false;
        this._selectedStart = Order.getDefaultStart();
        this._selectedEnd = Order.calculateContractEndDate(this._selectedStart);

        this._state = this.IdentifyPerson;
      });
  }

  identifyOrganization(scope) {
    if (scope.identifyOrganizationForm.$invalid) {
      return;
    }

    this.state = this.Loading;

    this._organization = this.organization;

    this._orderService.getForOrganization(this._organization.id)
      .then((orders) => {
        const activeOrder = Order.findActiveOrder(orders);
        const alreadyPurchasedForThisYear = Order.hasAlreadyPurchasedThisYear(orders);
        const contractStartDate = Order.getDefaultStart(orders);

        if (activeOrder) {
          this.$mdDialog.hide({
            organization: this._organization,
            quantity: activeOrder.quantity,
            name: activeOrder.creatorName,
            email: activeOrder.creatorEmail,
            start: contractStartDate,
            end: Order.calculateContractEndDate(contractStartDate),
            renewal: alreadyPurchasedForThisYear || !!this.organization.contractId,
            virtual: false
          });
        }
        else {
          this._hideThisYear = alreadyPurchasedForThisYear;
          this._hideFirstYearCheckbox = alreadyPurchasedForThisYear;
          this._renewal = alreadyPurchasedForThisYear || !!this.organization.contractId;
          this._selectedStart = contractStartDate;
          this._selectedEnd = Order.calculateContractEndDate(contractStartDate);

          this._state = this.IdentifyPerson;
        }
      }).catch((error) => {
        this.error = error;
        this.$log.error(error);
        if (this.error.code === ErrorCodes.NOT_FOUND){
          this._errorDialog(this.$mdDialog,
            'Whoops! We couldn\'t find the contract associated with your organization',
            'Please send us an email at <b>support@classkick.com</b>');
        }
    });
  }

  /**
   * @param placeId {string}
   * @param organizationName {string}
   * @return {Promise<Organization>}
   */
  _createOrganization(placeId, organizationName) {
    return this._googlePlacesService.getPlaceDetails(placeId).then((place) => {
      return this._organizationService.create(
        this._orgType,
        organizationName,
        place.lat,
        place.lng,
        place.zip,
        place.city,
        place.state,
        place.country
      );
    });
  }

  /**
   * @param search {string}
   * @return {Array|Promise<GooglePlacePrediction[]>}
   */
  autocompleteLocation(search) {
    if (!search || search.length < 2) {
      return [];
    }
    else {
      this._loadingAutocompleteLocation = true;
      return this._googlePlacesService.getPredictions(search)
        .then((result) => {
          this._loadingAutocompleteLocation = false;
          return result;
        });
    }
  }

  get loadingAutocompleteLocation() {
    return this._loadingAutocompleteLocation;
  }

  /**
   * @param search {string}
   * @return {Promise<Organization[]>}
   */
  autocompleteOrganization(search) {
    this._loadingAutocompleteOrganization = true;
    return this._ipStackService.getLocation()
      .then((location) => {
        return this._organizationService.search(this._orgType, location.lat, location.lng, search);
      })
      .then((organizations) => {
        this._loadingAutocompleteOrganization = false;
        // Hack: Adds an extract empty object for the 'add new school/district' button
        return [...organizations, {}];
      });
  }

  get loadingAutocompleteOrganization() {
    return this._loadingAutocompleteOrganization;
  }

  isOrganization(obj) {
    return obj instanceof Organization;
  }

  // -------------- Identify Person Logic ------------------

  identifyPerson(scope) {
    if (scope.identifyPersonForm.$invalid) {
      return;
    }

    this.state = this.Loading;

    if (this._renewal) {
      this.$mdDialog.hide({
        organization: this._organization,
        quantity: this._quantity,
        name: this._name,
        email: this._email,
        start: this.renewal ? Order.getDefaultStart([], this._renewal) : this.selectedStart,
        end: this.selectedEnd,
        renewal: this.renewal,
        virtual: this.virtual
      });
    }

    this._orderService.getPrices(this.orgType, this.renewal, this.virtual, moment())
      .then((prices) => {
        this._prices = prices;
        this.state = this.IdentifyPurchasePeriod;
      });

    this._organizationService.update(this.organization);
  }

  // -------------- Select Purchase Period ------------------

  get hideThisYear() {
    return this._hideThisYear;
  }

  get hideFirstYearCheckbox() {
    return this._hideFirstYearCheckbox;
  }

  get isRenewal() {
    return this._renewal;
  }

  get firstYear() {
    return !this._renewal;
  }

  set firstYear(value) {
    this._renewal = !value;
  }

  get selectedStart() {
    return this._selectedStart;
  }

  set selectedStart(value) {
    this._selectedStart = value;
  }

  get selectedEnd() {
    return this._selectedEnd;
  }

  set selectedEnd(value) {
    this._selectedEnd = value;
  }

  get isValidPurchasePeriod() {
    return this._isValidPurchasePeriod;
  }

  set isValidPurchasePeriod(value) {
    this._errorMessage = '';
    this._isValidPurchasePeriod = value;
  }

  get errorMessage() {
    return this._errorMessage;
  }

  submitPurchasePeriod(scope) {
    if (scope.selectPurchasePeriodForm.$invalid) {
      return;
    }

    if (!this.isValidPurchasePeriod) {
      this._errorMessage = 'Invalid purchase period. Please select one or more consecutive years.';
      return;
    }

    this.$mdDialog.hide({
      organization: this._organization,
      quantity: this._quantity,
      name: this._name,
      email: this._email,
      start: this.renewal ? Order.getDefaultStart([], this._renewal) : this.selectedStart,
      end: moment.utc(this.selectedEnd).startOf('day'),
      renewal: this.renewal,
      virtual: this.virtual
    });
  }

  /**
   * Launches create order dialog
   * @param $mdDialog
   * @param orgType {string}
   * @param [quantity] {number}
   * @param [name] {string}
   * @param [email] {string}
   */
  static show($mdDialog, orgType, quantity, name, email) {
    return $mdDialog.show({
      controller: CreateOrderDialogController,
      template: CreateOrderDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      locals: {
        orgType,
        quantity,
        name,
        email
      }
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
