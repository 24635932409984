
'use strict';

import UserNotificationListItem from '../../model/domain/user-notification-list-item';
import {MessageActions} from '../../model/domain/message';
import UserNotificationDialogController from '../../components/user-notification-dialog/user-notification-dialog.controller';

export class UserNotificationsTableColumns {

  static get NOTIFICATION_TEXT() {
    return 'notification_text';
  }

  static get RECEIVED() {
    return 'received';
  }

}

export default class UserNotificationsListController {

  constructor($scope, $q, $state, $mdDialog, $mdSidenav, CacheService, AssignmentService, AuthService, BreadcrumbService) {
    'ngInject';

    /** @type {$scope} */
    this.$scope = $scope;
    /** @type {$state} */
    this.$state = $state;
    /** @type {$q} */
    this.$q = $q;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {$mdDialog} */
    this.$mdDialog = $mdDialog;
    /** @type {$mdSidenav} */
    this.$mdSidenav = $mdSidenav;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;

    // Clean up after ourselves
    $scope.$on('$destroy', () => this._destroy());

    this._loading = true;
    this._error = undefined;
    /** @type {UserNotificationListItem[]} */
    this._list = [];
    /** @type {MessageSet} */
    this._messageSet = null;

    // Configure column names
    this._showNotificationTextColumn = true;
    this._showReceivedColumn = true;

    // configures the initial behavior of the table
    this._orderBy = UserNotificationsTableColumns.RECEIVED;
    this._isAscending = true;

    this.init();
  }

  init() {
    this._cacheService.getMessagesForUser()
      .then((messages) => {
        this._messageSet = messages;
        this._messageSet.updated.subscribe(this._onMessagesUpdated, this);

        this._list = this._messageSet.messages.map((x) => new UserNotificationListItem(x));
        this._loading = false;
      })
      .catch((error) => {
        this._error = error;
      });
  }

  get listIsEmpty() {
    return this._list ? this._list.length === 0 : true;
  }

  /**
   * Removes the user notification list item.
   * @param notificationListItem
   */
  removeUserNotificationListItem(notificationListItem) {
    this._messageSet.delete(notificationListItem.message.id);
  }

  /**
   * Calls the appropriate method when a user clicks the call to action button on a list item.
   * @param notificationListItem
   */
  callToAction(notificationListItem) {
    for (let action of notificationListItem.message.actions) {
      if (action[MessageActions.TYPE] === MessageActions.ACCEPT_ASSIGNMENT_SHARE) {
        this.previewAssignment(action[MessageActions.ASSIGNMENT_ID]);
        return;
      }
    }
  }

  /**
   * Previews an assignment using the public assignment page.
   * @param assignmentId
   */
  previewAssignment(assignmentId) {
    this._assignmentService.get(assignmentId)
    .then((assignment) => {
      if (assignment.isFolder) {
        const path = this._authService.isLoggedIn ? 'root.account.nav.public-folder': 'root.public-folder';
        this._breadcrumbService.go(path, { folderId: assignmentId });
      } else {
        const path = this._authService.isLoggedIn ? 'root.account.nav.public-assignment': 'root.public-assignment';
        this._breadcrumbService.go(path, { assignmentId: assignmentId });
      }
    });
  }

  /**
   * Shows an individual notification item.
   * @param userNotificationListItem
   */
  showNotification(userNotificationListItem) {
    UserNotificationDialogController.show(this.$mdDialog, userNotificationListItem, this);

    if (!userNotificationListItem.message.seenDate) {
      this._messageSet.markAsSeen(userNotificationListItem.message.id);
    }
  }

  get list() {
    return this._list;
  }

  get loading() {
    return this._loading;
  }

  get error() {
    return this._error;
  }

  get showNotificationTextColumn() {
    return this._showNotificationTextColumn;
  }

  get showReceivedColumn() {
    return this._showReceivedColumn;
  }

  get showNotificationTextCarrot() {
    return this._orderBy === UserNotificationsTableColumns.NOTIFICATION_TEXT;
  }

  get showReceivedCarrot() {
    return this._orderBy === UserNotificationsTableColumns.RECEIVED;
  }

  get isAscending() {
    return this._isAscending;
  }

  setOrToggle(colName, shouldAscend) {
    if (this._orderBy !== colName) {
      this._orderBy = colName;
      this._isAscending = shouldAscend;
    }
    else {
      this._isAscending = !this._isAscending;
    }
  }

  orderBy() {
    return (item) => {
      if (this._orderBy === UserNotificationsTableColumns.NOTIFICATION_TEXT) {
        return item.message.text;
      }
      else if (this._orderBy === UserNotificationsTableColumns.RECEIVED) {
        return item.message.createdDate.valueOf();
      }
    };
  }

  /**
   * sets the list order to the notification text column and/or toggles ascending/descending
   */
  setOrToggleNotificationText() {
    this.setOrToggle(UserNotificationsTableColumns.NOTIFICATION_TEXT, true);
  }

  /**
   * sets the list order to the received column and/or toggles ascending/descending
   */
  setOrToggleReceived() {
    this.setOrToggle(UserNotificationsTableColumns.RECEIVED, true);
  }

  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }

  _onMessagesUpdated(args) {
    if (args.change === this._messageSet.ADD) {
      this._list.push(new UserNotificationListItem(args.message));
    }
    else if (args.change === this._messageSet.DELETE) {
      let notificationIndex = this._list.findIndex((x) => x.message.id === args.message.id);
      this._list.splice(notificationIndex, 1);
    }
  }

  _destroy() {
    this._messageSet.updated.unsubscribe(this._onMessagesUpdated, this);
  }

}
