import Session from '../domain/session';
import Class from '../domain/class';

export default class GradebookRosterLink {

  /**
   * @param selectedLink {GradebookRoster}
   * @param sessions {Session[]}
   * @param courses {Course[]}
   * @param classes {Class[]}
   *
   */
  constructor(
    selectedLink,
    sessions,
    courses,
    classes
  ) {

    this._selectedLink = selectedLink;
    this._sessions = sessions;
    this._courses = courses;
    this._classes= classes;
  }

  get selectedLink (){
    return this._selectedLink;
  }

  get sessions (){
    return this._sessions;
  }

  get courses (){
    return this._courses;
  }

  get classes (){
    return this._classes;
  }

}
