import { Colors } from '../../model/domain/colors';
import HexColors from '../../css-constants';
import ToolbarColorMenuTemplate from './toolbar-color-menu.component.html';

class ToolbarColorMenuController {
  /**
   * @ngInject
   */
  constructor(StorageService, AnalyticsService) {
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    this.selectedColor = '';
    this.customColors = this._storageService.customColors ? this._storageService.customColors : [];

    //storageService keeps returning the set as an array so this converts it back to a set
    this.colorSet = this._storageService.customColorSet ? new Set(this._storageService.customColorSet) : new Set();
  }

  select(color) {
    if (this.colorSet.has(color.hex)) {
      this._analyticsService.sendEvent({
        eventTag: `asmt:${this.menuType}colorPicker_selectedCustomColor`,
        properties: {
          panel: this.menuType,
          hexColor: color.hex
        },
        count: true
      });
    }

    this.onSelect({color});
  }

  selectCustomColor(){
    if (!this.selectedColor) {
      return;
    }

    this._analyticsService.sendEvent({
      eventTag:`asmt:${this.menuType}colorPicker_addedColor`,
      properties: {
        panel: this.menuType,
        hexColor: this.selectedColor
      },
      count: true
    });

    const color = { hex: this.selectedColor };

    if (!this.colorSet.has(this.selectedColor)) {

      this.colorSet.add(this.selectedColor);

      if (this._storageService.customColors) {
        this._storageService.customColors = [...this._storageService.customColors, color];
        this.customColors = this._storageService.customColors;
      } else {
        this._storageService.customColors = [color];
        this.customColors = this._storageService.customColors;
      }

      this._storageService.customColorSet = [...this.colorSet];
    }
  }

  openColorPicker(){
    angular.element('.custom-color-picker').focus();
    angular.element('.custom-color-picker').click();
  }

  makeTransparent() {
    this.select({hex: HexColors.CK_TRANSPARENT, name: 'transparent'});
  }

  /**
   * @param color {{hex: string, name: string}}
   */
  borderColor(color) {
    return Colors.borderColor(color);
  }

}

export default {
  bindings: {
    colors: '<',
    menuType: '@',
    onSelect: '&',
    transparentButtonText: '@'
  },
  template: ToolbarColorMenuTemplate,
  controller: ToolbarColorMenuController,
  controllerAs: 'ctrl'
};
