
'use strict';


export default class RosterListItem {

  constructor(roster, helpRequestView) {
    this._helpRequestView = helpRequestView;
    this._roster = roster;
  }

  get roster() {
    return this._roster;
  }

  get helpRequests() {
    return this._helpRequestView;
  }
}

