'use strict';

import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { ChatMessageConfig } from '../chat-message/chat-message.component';
import Validation from '../../model/util/validation';
import AnimalNames from '../../model/domain/animal-names';
import HelpCenterTemplate from './help-center.component.html';

class HelpCenterController {

  /* @ngInject */
  constructor($scope, $timeout, $mdDialog, AuthService, ToolbarService) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$mdDialog = $mdDialog;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;

    this._chatMessageConfigs = new Map();
    this.newMessageText = '';
    this.loading = true;
  }

  $postLink() {
    this.resizeTextarea();
    this.markAsRead();
  }

  markAsRead() {
    this._markAsReadTimeout = this.$timeout(() => {
      if (this.helpCenter.helpRequestManager.currentUserId !== this.helpCenter.helpRequestManager.helpeeId &&
          this.helpCenter.helpRequestManager.isHelpRequestChat) {
        this.helpCenter.helpRequestManager.resolve();
      }
      this.helpCenter.chatMessageManager.markRead();
    }, 1000);
  }

  $onDestroy() {
    if (this._markAsReadTimeout) {
      this.$timeout.cancel(this._markAsReadTimeout);
    }
  }

  resizeTextarea() {
    this.$timeout(() => {
      this.$scope.$broadcast('md-resize-textarea');
      this.loading = false;
    }, 400);
  }

  sendMessage() {
    if (!Validation.validateChatMessage(this.newMessageText)) {
      return;
    }

    let newMessageText = this.newMessageText;
    this.newMessageText = '';
    this.helpCenter.sendMessage(newMessageText).then(() => {
      this.scrollToBottomOfChatMessages();
    });
  }

  scrollToBottomOfChatMessages() {
    let chatMessage = angular.element('chat-message:last-child')[0];

    if (chatMessage) {
      scrollIntoViewIfNeeded(chatMessage, false);
    }
  }

  cancelSidenav() {
    this._toolbarService.sidenavManager.closeState();
  }

  /**
   * @param currentMessage {ChatMessage}
   * @param index {number}
   * @returns {ChatMessageConfig}
   */
  chatMessageConfig(currentMessage) {
    if (!this._chatMessageConfigs.has(currentMessage.id) && this._toolbarService.hasTargetIndex) {
      this._chatMessageConfigs.set(
        currentMessage.id,
        new ChatMessageConfig(
          currentMessage,
          this._authService.currentUserId,
          this._authService.authData.roles[0],
          this._toolbarService.target.ownerId,
          this.helpCenter.helpRequestManager.anonAnimalNameForHelpee,
          this.anonAnimalNameForHelper(currentMessage.userId),
          this._toolbarService.target.assignment.ownerId
        )
      );
    }

    return this._chatMessageConfigs.get(currentMessage.id);
  }

  /**
   * @param currentMessageUserId {string}
   * @return {string|undefined}
   */
  anonAnimalNameForHelper(currentMessageUserId) {
    let questionId = this._toolbarService.contentQuestion.id;
    let animalName = AnimalNames.generate(`${questionId}${currentMessageUserId}`);
    return animalName ? animalName.display : '';
  }

  focusOnMessageInput(){
    this.isGlowing = true;
    return angular.element('textarea')[1].focus();
  }

  stopGlowAnimation(){
    this.isGlowing = false;
  }

  requestForHelp(){
    this.stopGlowAnimation();
    this.helpCenter.newHelpRequest_Help();
  }

  requestForCheck(){
    this.stopGlowAnimation();
    this.helpCenter.newHelpRequest_Check();
  }

  /**
   * @returns {HelpCenterManager}
   */
  get helpCenter() {
    return this._toolbarService.helpCenter;
  }

  /**
   * @returns {boolean}
   */
  get requestHelp() {
    return this.options && this.options.requestHelp;
  }

  /**
   * @returns {boolean}
   */
  get resolveHelp() {
    return this.options && this.options.resolveHelp;
  }

  get showHandLoading() {
    return this.helpCenter.helpRequestManager.isHelpRequestLoading;
  }

  get showRaiseHand() {
    return this.requestHelp &&
      !this.helpCenter.helpRequestManager.isHelpRequestLoading &&
      !this.helpCenter.helpRequestManager.isHelpRequestCheck &&
      !this.helpCenter.helpRequestManager.isHelpRequestHelp;
  }

  get showLowerHand() {
    return (this.resolveHelp || this.requestHelp) &&
      !this.helpCenter.helpRequestManager.isHelpRequestLoading &&
      (this.helpCenter.helpRequestManager.isHelpRequestHelp || this.helpCenter.helpRequestManager.isHelpRequestCheck);
  }

  get showHandInfo() {
    return !this.showHandLoading &&
      !this.showLowerHand &&
      !this.showRaiseHand;
  }

  get handInfoMessage() {
    if (this.helpCenter.helpRequestManager.isHelpRequestCheck || this.helpCenter.helpRequestManager.isHelpRequestHelp) {
      return 'There\'s a hand up!';
    }
    else {
      return 'No hand raised on this question';
    }
  }

  get noChatsMessage() {
    if (this.isTeacher) {
      return 'Your student\'s hands will appear here!';
    }
    else {
      return 'Your messages will appear here!';
    }
  }

  get isTeacher() {
    return this._authService.authData.isTeacher;
  }

  get chatMessageMaxSize() {
    return Validation.ChatMessageMaxSize;
  }

}

export default {
  bindings: {
    options: '<'
  },
  template: HelpCenterTemplate,
  controller: HelpCenterController,
  controllerAs: 'ctrl'
};


