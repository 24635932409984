'use strict';

import Assignment from '../../model/domain/assignment';
import Organization, { OrganizationTypes } from '../../model/domain/organization';
import { UserProperties, UserPreferences } from '../../model/domain/user';
import { AccountCreationStep } from '../../services/mixpanel/mixpanel.service';
import AccountNotification from '../../model/domain/account-notification';
import { LoginSources } from '../login/login.controller';

export default class SignupNameController {

  constructor($q, $stateParams, $timeout, $log, $window, $rootScope, BreadcrumbService, AnalyticsService, CacheService, AuthService, StaticContentService,
    RosterService, AssignmentService, ToolbarService, NotificationService, ContractService, StorageService, IPStackService, OrganizationService, UserService, environment) {
    'ngInject';

    this.$q = $q;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$log = $log;
    this.$window = $window;
    this.$rootScope = $rootScope;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {ContractService} */
    this._contractService = ContractService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {IPStackService} */
    this._ipStackService = IPStackService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;
    /** @type {UserService} */
    this._userService = UserService;

    this._isLoading = false;
    this.prefix = null;
    this._displayName = '';
    this._firstName = this.$stateParams.firstName || '';
    this._lastName = this.$stateParams.lastName || '';
    this._schoolName = '';
    this._school = undefined;
    this._selectedSchool = null;
    this._loadingAutocompleteSchool = false;
    this.prefixOptions = UserProperties.PREFIX_OPTIONS;
    this._onboardingTest = undefined;
    this._ckRedirect = this._storageService.ckRedirect;
    this._hasSentAnalyticsPrefix = false;
    this._hasSentAnalyticsFirstName = false;
    this._hasSentAnalyticsLastName = false;
    this._lat = undefined;
    this._lng = undefined;
    this._grade = undefined;
    this._subject = undefined;
    this._gradeRanges = UserPreferences.GRADE_OPTIONS;

    this._environment = environment;
    this._tokenDeferred = this.$q.defer();
    this._token = this._tokenDeferred.promise;
    this._accountInitPromise = undefined;

    if (!this._authService.isLoggedIn) {
     this.goBack();
    }

    this._analyticsService.sendEvent({
      eventTag: 'page_viewed',
      properties: {
          location: 'teacherSignUpName'
      }
    });
  }

  /**
   * @return {boolean}
   */
  get isStudent() {
    return this.$stateParams.isStudent;
  }

  /**
   * @return {boolean}
   */
  get isTeacher() {
    return this.$stateParams.isTeacher;
  }

  /**
   * @return {boolean}
   */
  get isLoading() {
    return this._isLoading;
  }

  isOrganization(obj) {
    return obj instanceof Organization;
  }

  /**
   * @return {string}
   */
  get firstName() {
    return this._firstName;
  }

  /**
   * @param value {string}
   */
  set firstName(value) {
    this._firstName = value;
  }

  /**
   * @return {string}
   */
  get lastName() {
    return this._lastName;
  }

  /**
   * @param value {string}
   */
  set lastName(value) {
    this._lastName = value;
  }

  /**
   * @return {string}
   */
  get displayName() {
    return this._displayName;
  }

  /**
   * @param value {string}
   */
  set displayName(value) {
    this._displayName = value;
  }

  /**
   * @return {string}
   */
  get schoolName() {
    return this._schoolName;
  }

  /**
   * @param value {string}
   */
  set schoolName(value) {
    this._schoolName = value;
  }

  /**
   * @return {undefined|Organization}
   */
  get school() {
    return this._school;
  }

  /**
   * @param value {undefined|Organization}
   */
  set school(value) {
    this._school = value;
  }

  /**
   * @return {Object}
   */
  get selectedSchool() {
    return this._selectedSchool;
  }

  /**
   * @param {Object} value
   */
  set selectedSchool(value) {
    this._selectedSchool = value;
  }

  /**
   * @returns {string[]}
   */
  get gradeRanges() {
    return this._gradeRanges;
  }

  /**
   * @return {Array}
   */
  get grade() {
    return this._grade;
  }

  /**
   * @param value {string}
   */
  set grade(value) {
    this._grade = value;
  }

  /**
   * @return {string[]}
   */
  get subjectOptions() {
    return Assignment.Subjects;
  }

  /**
   * @return {string}
   */
  get subject() {
    return this._subject;
  }

  /**
   * @param value {string}
   */
  set subject(value) {
    this._subject = value;
  }

  /**
   * @param search {string} the search term
   * @param array {string[]} the original array of options
   * @returns {string[]}
   */
  autocompleteFilter(search, array) {
    if (!search) {
      return array;
    }
    return array.filter((value) => value.toLowerCase().includes(search.toLowerCase()));
  }

  /**
   * @param search {string}
   * @return {Promise<Array>}
   */
  autocompleteSchool(search) {
    this._loadingAutocompleteSchool = true;

    if (!search) {
      this._loadingAutocompleteSchool = false;
      return [];
    }

    return this._ipStackService.getLocation()
      .then((location) => {
        this._lat = location.lat;
        this._lng = location.lng;

        const googlePlacesPromise = this._getGooglePlacesSuggestions(search);

        return googlePlacesPromise;
      })
      .then((places) => {
        if (places === undefined || places.length === 0) {
          return this._backfillCkPlacesSuggestions(search);
        }
        this._loadingAutocompleteSchool = false;

        // Map Google Places predictions
        const placeOrganizations = places.map((place) => {
          return {
            id: null,
            name: place.name,
            formatted_address: place.formatted_address,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            googlePlaceId: place.place_id,
            address_components: place.address_components,
            place_id: place.place_id,
            isGooglePlace: true
          };
        });

        // Return Google Places
        return placeOrganizations;
      })
      .catch((err) => {
        this.$log.error(err);
        this._loadingAutocompleteSchool = false;
        return [];
      });
  }

  _backfillCkPlacesSuggestions(search) {
    return this._organizationService
      .search(OrganizationTypes.School, this._lat, this._lng, search)
      .then((response) => {
        const filteredCkPlaces = response.filter((item) => item.mdrPid !== 0 || item.memberCount >= 3);
        return filteredCkPlaces;
      });
  }

  _getGooglePlacesSuggestions(searchText) {
    const deferred = this.$q.defer();

    if (!this._googlePlacesService) {
      this._googlePlacesService = new this.$window.google.maps.places.AutocompleteService();
    }

    this._googlePlacesService.getPlacePredictions({
      input: searchText,
      types: ['school'],
    }, (predictions, status) => {
      if (status !== this.$window.google.maps.places.PlacesServiceStatus.OK) {
        deferred.resolve([]);
      } else {
        const placesService = new this.$window.google.maps.places.PlacesService(document.createElement('div'));
        const detailPromises = predictions.map((prediction) => {
          const detailDeferred = this.$q.defer();
          placesService.getDetails({
            placeId: prediction.place_id,
            fields: ['name', 'formatted_address', 'geometry', 'place_id', 'types', 'address_components'],
          }, (place, status) => {
            if (status === this.$window.google.maps.places.PlacesServiceStatus.OK && place.types.includes('school')) {
              detailDeferred.resolve(place);
            } else {
              detailDeferred.resolve(null);
            }
          });
          return detailDeferred.promise;
        });
        this.$q.all(detailPromises).then((places) => {
          const validPlaces = places.filter((place) => place !== null);
          deferred.resolve(validPlaces);
        });
      }
    });

    return deferred.promise;
  }

  get loadingAutocompleteSchool() {
    return this._loadingAutocompleteSchool;
  }

  onSelectedSchoolChange(item) {
    if (item === null || typeof item === 'undefined' || typeof item._id === 'string') {
      return;
    }

    if (item && item.isGooglePlace) {
      this._searchOrCreateOrganization(item)
        .then((organization) => {
          this.selectedSchool = organization;
        })
        .catch((error) => {
          this.$log.error('Error searching or creating organization:', error);
        });
    } else {
      this.selectedSchool = null;
    }
  }

  _searchOrCreateOrganization(selectedSchool) {
    return this._organizationService.search(
      OrganizationTypes.School,
      selectedSchool.lat,
      selectedSchool.lng,
      null,
      selectedSchool.googlePlaceId
    )
    .then((organizations) => {
      if (organizations.length > 0) {
        return organizations[0];
      } else {
        return this._createOrganizationFromGooglePlace(selectedSchool);
      }
    });
  }

  onSelectPrefixChange() {
    if (!this._hasSentAnalyticsPrefix) {
      this._analyticsService.sendEvent({
        eventTag: 'teacherSignUpName:prefix_added'
      });
      this._hasSentAnalyticsPrefix = true;
    }
  }

  firstNameUpdated() {
    if (!this._hasSentAnalyticsFirstName) {
      if (this.isTeacher) {
        this._analyticsService.sendEvent({
          eventTag: 'teacherSignUpName:firstName_typed'
        });
        this._hasSentAnalyticsFirstName = true;
      } else {
        this._analyticsService.sendEvent({
          eventTag: 'studentSignUpName:firstName_typed'
        });
        this._hasSentAnalyticsFirstName = true;
      }
    }
  }

  lastNameUpdated() {
    if (!this._hasSentAnalyticsLastName) {
      if (this.isTeacher) {
        this._analyticsService.sendEvent({
          eventTag: 'teacherSignUpName:lastName_typed'
        });
        this._hasSentAnalyticsLastName = true;
      } else {
        this._analyticsService.sendEvent({
          eventTag: 'studentSignUpName:lastName_typed'
        });
        this._hasSentAnalyticsLastName = true;
      }
    }
  }

  saveStudentName() {
    this._analyticsService.sendEvent({
      eventTag: 'studentSignUpName:getStarted_clicked'
    });

    this._isLoading = true;
    this._analyticsService.createAccountProgress(AccountCreationStep.NAME);

    this._cacheService.getUser(false)
      .then((user) => {
        user.firstName = this._firstName;
        user.lastName = this._lastName;
        return this._cacheService.updateUser(user);
      })
      .then(() => {
        this.go();
      })
      .catch((err) => {
        this.$log.error(err);
        this.go();
      });
  }

  saveTeacher() {
    this._isLoading = true;

    let user;
    this._cacheService.getUser(false)
      .then((fetchedUser) => {
        user = fetchedUser;
        user.displayName = this.displayName;
        user.subject = this.subject;
        user.studentAge = this.convertGradeToStudentAge();

        let schoolPromise;
        if (this.selectedSchool && this.selectedSchool.id) {
          schoolPromise = this.$q.resolve(this.selectedSchool);
        } else {
          schoolPromise = this._createOrganization();
        }

        return this.$q.all({
          school: schoolPromise,
          userUpdate: this._cacheService.updateUser(user)
        });
      })
      .then(({ school }) => {
        const userId = user.id;
        this.$q.all({
          token: this._organizationService.addSelf(school.id),
          preferences: this._userService.updateUserPreferences(userId, {
            subject: this.subject,
            grade: this.grade,
            myStudentsCallMe: this.displayName
          }),
        }).then(({ token }) => {
          this._onSuccessfulToken(token);
        }).catch((err) => {
          this._handleError(err);
        });
      })
      .catch((err) => {
        this.$log.error(err);
      });
  }

  // Helper method to handle Google Place selection
  _handleGooglePlaceSelection() {
    return this._organizationService.search(OrganizationTypes.School, this.selectedSchool.lat, this.selectedSchool.lng, null, this.selectedSchool.googlePlaceId)
      .then((organizations) => {
        if (organizations.length > 0) {
          return organizations[0];
        } else {
          // Fallback to search by name and location
          return this._organizationService.search(OrganizationTypes.School, this.selectedSchool.lat, this.selectedSchool.lng, this.selectedSchool.name)
            .then((orgs) => {
              if (orgs.length > 0) {
                return orgs[0];
              } else {
                // Create new organization
                return this._createOrganizationFromGooglePlace(this.selectedSchool)
                  .then(() => {
                    // After creating, search again using google_place_id
                    return this._organizationService.search(OrganizationTypes.School, this.selectedSchool.lat, this.selectedSchool.lng, null, this.selectedSchool.googlePlaceId)
                      .then((newOrgs) => {
                        if (newOrgs.length > 0) {
                          return newOrgs[0];
                        } else {
                          // Handle the case where the organization was not found after creation
                          throw new Error('Organization was created but not found on subsequent search');
                        }
                      });
                  });
              }
            });
        }
      });
  }

  // Helper method to handle successful token reception
  _onSuccessfulToken(token) {
    this._tokenDeferred.resolve(token);

    this._accountInitPromise = this.$timeout(() => {
      this.goTeacher();
    }, this._environment.accountInitTimeout);

    // Register for account init notification
    this._accountNotification = this._notificationService.getAccountNotification(this._authService.currentUserId);
    this._accountNotification.accountInitialized.subscribe(this._onAccountInit, this);
    this._accountNotification.start();

    this._cacheService.reset();
    this._analyticsService.createAccountProgress(AccountCreationStep.SCHOOL_NAME);
    this._analyticsService.createAccountProgress(AccountCreationStep.COMPLETE);
  }

  // Helper method to handle errors
  _handleError(err) {
    this._tokenDeferred.reject();
    if (!this._accountInitPromise) {
      this.go();
    }
    this.$log.error(err);
  }

  _createOrganizationFromGooglePlace(place) {
    const addressComponents = place.address_components;

    const getComponent = (types) => {
      const component = addressComponents.find((comp) => types.some((type) => comp.types.includes(type)));
      return component ? component.long_name : null;
    };

    // The format of the Response from Google Place API is shown here:
    // https://developers.google.com/maps/documentation/places/web-service/details#PlaceDetailsResponses
    const zip = getComponent(['postal_code']);
    const city = getComponent(['locality', 'administrative_area_level_2', 'sublocality']);
    const state = getComponent(['administrative_area_level_1']);
    const country = getComponent(['country']);

    const organizationData = {
      orgType: 'school',
      name: place.name,
      lat: place.lat,
      lng: place.lng,
      properties: {
        google_place_id: place.place_id,
        formatted_address: place.formatted_address,
        place_name: place.name
      }
    };
    return this._organizationService.create(
      organizationData.orgType,
      organizationData.name,
      organizationData.lat,
      organizationData.lng,
      zip,
      city,
      state,
      country,
      null, // userDefinedOrgType
      organizationData.properties
    );
  }


  _createOrganization() {
    return this._organizationService.create('school', this.schoolName, this._lat, this._lng);
  }

  convertGradeToStudentAge() {
    switch (this.grade) {
      case UserPreferences.GRADE_OPTIONS[0]:
      case UserPreferences.GRADE_OPTIONS[1]:
        return UserProperties.STUDENT_AGE_OPTIONS[0];
      case UserPreferences.GRADE_OPTIONS[2]:
      case UserPreferences.GRADE_OPTIONS[3]:
      case UserPreferences.GRADE_OPTIONS[4]:
        return UserProperties.STUDENT_AGE_OPTIONS[1];
      case UserPreferences.GRADE_OPTIONS[5]:
      case UserPreferences.GRADE_OPTIONS[6]:
      case UserPreferences.GRADE_OPTIONS[7]:
        return UserProperties.STUDENT_AGE_OPTIONS[2];
      case UserPreferences.GRADE_OPTIONS[8]:
      case UserPreferences.GRADE_OPTIONS[9]:
      case UserPreferences.GRADE_OPTIONS[10]:
        return UserProperties.STUDENT_AGE_OPTIONS[3];
      case UserPreferences.GRADE_OPTIONS[11]:
      case UserPreferences.GRADE_OPTIONS[12]:
      case UserPreferences.GRADE_OPTIONS[13]:
        return UserProperties.STUDENT_AGE_OPTIONS[4];
      case UserPreferences.GRADE_OPTIONS[14]:
        return UserProperties.STUDENT_AGE_OPTIONS[5];
      default:
        return '';
    }
  }

  /**
 * Callback for a Firebase listener that listens for when the account is initialized.
 * @param value
 * @private
 */
  _onAccountInit(value) {
    if (value.change === AccountNotification.ACCOUNT_INITIALIZED) {
      this.$timeout.cancel(this._accountInitPromise);
      this._accountInitPromise = null;
      this.goTeacher();
    }
  }

  go() {
    if (this.isStudent) {
      this._analyticsService.createAccountProgress(AccountCreationStep.COMPLETE);
      this._breadcrumbService.clear();

      if (this._ckRedirect) {
        this._ckRedirect.redirect(this._breadcrumbService);
        this._storageService.ckRedirect = null;
      }
      else {
        this._breadcrumbService.go('root.account.home', {}, true);
      }
    }
    else {
      this._breadcrumbService.go('root.signup-school', {}, this.isStudent);
    }
  }

  goTeacher() {
    this._token
    .then((token) => {
      return this._authService.processTokenResult(token, this._authService.rememberMe);
    })
    .then(() => {
      this._onSuccess();
    })
    .catch(() => {
      this._onSuccess();
    });
  }

  async handleAssignmentAndRoster() {
    // Wait for both promises to resolve
    const assignments = await this._assignmentService.getForUser(this._authService.currentUserId);
    const currentAssignmentId = this._assignmentService.getAssignmentId(assignments, 0);
    this.$rootScope.showDialog = true;
    // After assignment is available, navigate
    this._breadcrumbService.go('root.account.session.work', {
      assignmentId: currentAssignmentId,
      rosterId: '',
      sort: ''
    }, {
      source: LoginSources.SIGN_UP
    }, true);
  }

  _onSuccess() {
    this._toolbarService.reset();
    this._breadcrumbService.clear();
    this._isLoading = false;

    if (this._ckRedirect) {
      this._ckRedirect.redirect(this._breadcrumbService);
      this._storageService.ckRedirect = null;
    }
    else {
      this._breadcrumbService.go('root.refer', {}, true);
    }
  }

  goBack() {
    let route = this.isStudent ? 'root.signup-student' : 'root.signup-teacher';
    this._breadcrumbService.goBack(route);
  }

}
