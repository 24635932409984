
'use strict';

export default class ArchiveService {

  constructor(environment, $log, $q, HttpService) {
    'ngInject';

    this._environment = environment;

    this.$log = $log;
    /** @type {$q} */
    this.$q = $q;
    /** @type {HttpService} */
    this._httpService = HttpService;
  }

  /**
   * Unarchive assignment work question data
   *
   * @param assignmentWorkId {string}
   * @param questionId {string}
   * @returns {Promise.<???>}
   */
  unarchiveAssignmentWorkQuestion(assignmentWorkId, questionId) {
    this.$log.debug(`HADES ArchiveService unarchiveAssignmentWorkQuestion: Unarchiving data: assignmentWorkId=${assignmentWorkId}, questionId=${questionId}`);
    return this._httpService.authPut(
      `${this._environment.serverUrlBase}/v1/assignment-works/${assignmentWorkId}/questions/${questionId}/unarchive`)
      .then((result) => {
        this.$log.debug('HADES ArchiveService unarchiveAssignmentWorkQuestion Unarchive complete.', result);
    });
  }

}

