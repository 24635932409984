'use strict';

import Codec from './codec';
import AssignmentRoster from '../domain/assignment-roster';

/**
 * Encodes/Decodes an assignment roster relationship
 */
export default class AssignmentRosterCodec extends Codec {

  /**
   * @param value {AssignmentRoster}
   * @returns {*}
   */
  encode(value) {
    if (value) {
      return {
        roster_id: value.rosterId,
        assignment_id: value.assignmentId,
        show_student_scores: value.showStudentScores,
        lock_student_work: value.lockStudentWork,
        hide_student_work: value.hideStudentWork
      };
    }

    return null;
  }

  /**
   * @param value {object}
   * @returns {AssignmentRoster|null}
   */
  decode(value) {
    if (value) {
      return new AssignmentRoster(
        value.assignment_id,
        value.roster_id,
        value.show_student_scores,
        value.lock_student_work,
        value.hide_student_work
      );
    }

    return null;
  }
}
