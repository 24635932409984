
import BaseImage from './base-image';

export default class ManipulativeImageChild extends BaseImage {

  /**
   * @param id {string}
   * @param metadata {ElementMetadata}
   * @param parentCenter {Point} the center of the parent image manipulative
   * @param imageSize {Size} the size of the image on the canvas before scaling and rotation
   * @param parentScale {number} the relative scale of the parent image manipulative
   * @param parentRotation {number} the rotation of the parent image manipulative
   * @param url {string}
   * @param childCenter {Point|undefined} the center of the child image manipulative
   * @param childScale {number|undefined} the relative scale of the child image manipulative
   * @param childRotation {number|undefined} the rotation of the child image manipulative
   */
  constructor(id, metadata, parentCenter, imageSize, parentScale, parentRotation, url, childCenter, childScale, childRotation) {

    super(
      id,
      ManipulativeImageChild.type,
      metadata,
      childCenter || parentCenter,
      imageSize,
      angular.isNumber(childScale) ? childScale : parentScale,
      angular.isNumber(childRotation) ? childRotation : parentRotation,
      url
    );

    this._parentCenter = parentCenter;
    this._parentScale = parentScale;
    this._parentRotation = parentRotation;

    this._childCenter = childCenter;
    this._childScale = childScale;
    this._childRotation = childRotation;

    this._modified = !!childCenter || !!childRotation || !!childScale;
  }

  /**
   * @return {string}
   */
  static get type() {
    return 'mimage_child';
  }

  /**
   * @return {boolean}
   */
  get isChildManipulative() {
    return true;
  }

  /**
   * @return {Point}
   */
  get parentCenter() {
    return this._parentCenter;
  }

  /**
   * @return {number}
   */
  get parentScale() {
    return this._parentScale;
  }

  /**
   * @return {number}
   */
  get parentRotation() {
    return this._parentRotation;
  }

  /**
   * @return {Point}
   */
  get childCenter() {
    return this._childCenter;
  }

  /**
   * @return {number}
   */
  get childScale() {
    return this._childScale;
  }

  /**
   * @return {number}
   */
  get childRotation() {
    return this._childRotation;
  }

  /**
   * Returns a boolean indicating if this element has been updated by a student
   * @return {boolean}
   */
  get modified() {
    return this._modified;
  }

  /**
   * Merges properties from another instance of the same class into this object
   * @param other {ManipulativeImageChild}
   */
  merge(other) {
    this._metadata = other._metadata || this._metadata;
    this._imageSize = other._imageSize || this._imageSize;

    this._parentCenter = other._parentCenter || this._parentCenter;
    this._parentScale = other._parentScale || this._parentScale;
    this._parentRotation = other._parentRotation === 0 ? 0 : other._parentRotation || this._parentRotation;

    this._childCenter = other._childCenter || this._childCenter;
    this._childScale = other._childScale || this._childScale;
    this._childRotation = other._childRotation === 0 ? 0 : other._childRotation || this._childRotation;

    this._location = this._childCenter ? this._childCenter : this._parentCenter;
    this._scale = angular.isNumber(this._childScale) ? this._childScale : this._parentScale;
    this._rotation = angular.isNumber(this._childRotation) ? this._childRotation : this._parentRotation;

    this._modified = !!this._childCenter || !!this._childRotation || !!this._childScale;

    const oldUrl = this._url;
    this._url = other._url || this._url;

    if (this._url !== oldUrl) {
      if (this._image) {
        this._image.attr({href: this.url});
      }
      this._preload();
    }

    this._size = this._imageSize.times(this._scale);
    this.tryUpdate();
  }

  /**
   * Extracts the persisted values from this entity into something compatible with the merge function
   * @returns {object}
   */
  snapshot() {
    return {
      _metadata: this._metadata,
      _parentCenter: this._parentCenter,
      _imageSize: this._imageSize,
      _parentScale: this._parentScale,
      _parentRotation: this._parentRotation,
      _url: this._url,
      _childCenter: this._location,
      _childScale: this._scale,
      _childRotation: this._rotation
    };
  }

  /**
   * Creates a new element from a snapshot
   * @param id {string}
   * @param snapshot {object}
   * @returns {ManipulativeImageChild}
   */
  fromSnapshot(id, snapshot) {
    return new ManipulativeImageChild(
      id,
      snapshot._metadata,
      snapshot._parentCenter,
      snapshot._imageSize,
      snapshot._parentScale,
      snapshot._parentRotation,
      snapshot._url,
      snapshot._childCenter,
      snapshot._childScale,
      snapshot._childRotation
    );
  }

}
