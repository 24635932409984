
'use strict';

import Element from './element';
import Point from '../point';
import Size from '../size';
import GestureManager from '../gesture-manager';
import RemoveHandle from '../remove-handle';
import ResizeImageHandle from '../resize-image-handle';
import RotateImageHandle from '../rotate-image-handle';
import HexColors from '../../../css-constants';
import StaticService from '../../../services/static/static.service';
import CdnUtils from '../../util/cdn-utils';
import manipulativeOverlay from '../../../../assets/images/item_manipulative_overlay.png';

/**
 * Parent class for all image elements
 */
export default class BaseImage extends Element {

  /**
   * @param id {string}
   * @param type {string} the type of image element - image | mimage_parent | mimage_child
   * @param metadata {ElementMetadata}
   * @param center {Point} The center of the image
   * @param imageSize {Size} the size of the image on the canvas before scaling and rotation
   * @param scale {number} the relative scale of the image
   * @param rotation {number}
   * @param url {string}
   */
  constructor(id, type, metadata, center, imageSize, scale, rotation, url) {
    super(id, type, metadata);

    this._location = center || new Point(100, 200);
    this._imageSize = imageSize;
    this._url = CdnUtils.urlTransform(url);
    this._scale = scale;
    this._size = this._imageSize.times(scale);
    this._rotation = rotation;
    this._orientation = 0;

    this.loaded = this._preload();
  }

  /**
   * @returns {string}
   */
  get url() {
    return this._url;
  }

  /**
   * @param value {string}
   */
  set url(value) {
    this._url = value;
  }

  /**
   * @returns {number}
   */
  get scale() {
    return this._scale;
  }

  /**
   * @returns {Point}
   */
  get center() {
    return this.location;
  }

  /**
   * @returns {Size}
   */
  get imageSize() {
    return this._imageSize;
  }

  /**
   * @param value {number}
   */
  set scale(value) {
    this._scale = value;
    this.size = this._imageSize.times(this.scale);
  }

  /**
   * @returns {number}
   */
  get rotation() {
    return this._rotation;
  }

  /**
   * @param value {number}
   */
  set rotation(value) {
    this._rotation = value;
    this.tryUpdate();
  }

  /**
   * @param value {number}
   */
  set orientation(value) {
    this._orientation = value;
  }

  /**
   * @returns {number}
   */
  get orientation() {
    return this._orientation;
  }

  /**
   * Creates an svg transform string to correct for exif orientation
   * @returns {string}
   * @private
   */
  _exifTransform() {
    if (this.orientation === 8) {
      return 'rotate(-90)';
    }
    else if (this.orientation === 7) {
      return 'rotate(-90) scale(-1, 1)';
    }
    else if (this.orientation === 6) {
      return 'rotate(90)';
    }
    else if (this.orientation === 5) {
      return 'rotate(90) scale(-1, 1)';
    }
    else if (this.orientation === 4) {
      return 'rotate(180) scale(-1, 1)';
    }
    else if (this.orientation === 3) {
      return 'rotate(180)';
    }
    else if (this.orientation === 2) {
      return 'scale(-1, 1)';
    }
    else {
      return 'scale(1)';
    }
  }

  /**
   * The database stores .size post exif orientation transformation.
   * If the orientation indicates a 90 deg rotation, flip width and height to compensate
   * @returns {Size}
   * @private
   */
  get _exifSize() {
    if (!this._postprocess && this.orientation >= 5) {
      return new Size(this.height, this.width);
    }

    return this.size;
  }

  createElement(root, editable) {
    this._transform = root.group();
    this._exif = this._transform.group().addClass('exif');
    this._handlesRect = this._transform.group();
    this._image = this._exif.image(this.url, 0, 0, 0, 0);
    this._interactRect = this._exif.rect(0, 0, 0, 0).addClass('touch-foreground');
    this._editBox = this._exif.rect(0, 0, 0, 0).addClass('touch-foreground');

    if (editable) {
      this._removeHandle = new RemoveHandle(this);
      this._removeHandle.render(this._handlesRect);
      this._removeHandle.mouseEnter.subscribe(this._hoverIn, this);
      this._removeHandle.mouseLeave.subscribe(this._hoverOut, this);

      this._resizers = {
        topLeft: this._createResizeHandle('topLeft'),
        bottomLeft: this._createResizeHandle('bottomLeft'),
        bottomRight: this._createResizeHandle('bottomRight')
      };

      this._rotateHandle = new RotateImageHandle(this);
      this._rotateHandle.rotationStarted.subscribe(this._rotationStarted, this);
      this._rotateHandle.rotationComplete.subscribe(this._rotationComplete, this);
      this._rotateHandle.render(this._handlesRect);

      this._drag = new GestureManager(this, this.canvas);
      this._drag.start(this._interactRect.node);
      this._drag.click.subscribe(this._click, this);
      this._drag.mouseEnter.subscribe(this._hoverIn, this);
      this._drag.mouseLeave.subscribe(this._hoverOut, this);
      this._drag.dragStart.subscribe(this._dragStart, this);
      this._drag.dragMove.subscribe(this._dragMove, this);
      this._drag.dragEnd.subscribe(this._dragEnd, this);

      this._manipulativeIcon = this._exif.image(manipulativeOverlay, 0, 0, 0, 0);
    }
  }

  /**
   * @param positionName {string}
   * @param resizeToRight {boolean}
   * @returns {ResizeImageHandle}
   * @private
   */
  _createResizeHandle(positionName) {
    let resizeImageHandle = new ResizeImageHandle(this, positionName);
    resizeImageHandle.resizeStarted.subscribe(this._resizeStarted, this);
    resizeImageHandle.resizeComplete.subscribe(this._resizeComplete, this);
    resizeImageHandle.render(this._handlesRect);
    return resizeImageHandle;
  }

  update(root, editable) {

    // The image is rotated, and then translated to be centered at the given location
    this._transform.node.setAttribute('transform', 'translate('+this.location.x+','+this.location.y+') rotate('+this.rotation+')');

    // The exif transformation is applied
    this._exif.node.setAttribute('transform', this._exifTransform());

    let renderSize = this._exifSize;

    // Image is rendered centered at the origin, scaled to the appropriate size
    this._image.attr({
      x: -renderSize.width * .5,
      y: -renderSize.height * .5,
      width: renderSize.width,
      height: renderSize.height
    });

    if (editable) {

      this._editBox.attr({
        x: -renderSize.width * .5,
        y: -renderSize.height * .5,
        width: renderSize.width,
        height: renderSize.height,
        fill: 'transparent',
        stroke: HexColors.CK_GREEN,
        'stroke-width': '2px',
        'pointer-events': 'none'
      });

      this._interactRect.attr({
        x: -renderSize.width * .5,
        y: -renderSize.height * .5,
        width: renderSize.width,
        height: renderSize.height,
        fill: 'transparent',
        cursor: 'move'
      });

      this._editBox.attr({visibility: (this.hasFocus || this.hovering || this._drag.dragging) ? 'inherit' : 'hidden'});

      this._removeHandle.location = this._determineLocation(renderSize, 2, 0);
      this._removeHandle.visibility = (!this.isChildManipulative && (this.hasFocus || this.hovering)) ? 'visible' : 'hidden';
      this._removeHandle.tryUpdate();

      this._rotateHandle.location = this._determineLocation(renderSize, 1, 0);
      this._rotateHandle.visibility = this.hasFocus ? 'visible' : 'hidden';
      this._rotateHandle.tryUpdate();

      this._resizers.topLeft.location = this._determineLocation(renderSize, 0, 0);
      this._resizers.bottomLeft.location = this._determineLocation(renderSize, 0, 2);
      this._resizers.bottomRight.location = this._determineLocation(renderSize, 2, 2);

      this._resizers.topLeft.visibility = (!this.isChildManipulative && this.hasFocus) ? 'inherit' : 'hidden';
      this._resizers.bottomRight.visibility = (!this.isChildManipulative && this.hasFocus) ? 'inherit' : 'hidden';
      this._resizers.bottomLeft.visibility = (!this.isChildManipulative && this.hasFocus) ? 'inherit' : 'hidden';

      this._resizers.topLeft.tryUpdate();
      this._resizers.bottomRight.tryUpdate();
      this._resizers.bottomLeft.tryUpdate();

      this._manipulativeIcon.attr({
        visibility: (this.isParentManipulative && (this.hasFocus || this.hovering)) ? 'visible' : 'hidden',
        width: 99,
        height: 66,
        x: this.width * 0.5 - 99,
        y: this.height * 0.5 - 66,
        'pointer-events': 'none'
      });
    }
  }

  warnBeforeDeletion() {
    this._editBox.attr({
      fill: 'rgba(255, 255, 255, .5)',
      stroke: HexColors.CK_WARN,
      'stroke-width': '2px'
    });
    this._resizers.topLeft.warnBeforeDeletion();
    this._resizers.bottomLeft.warnBeforeDeletion();
    this._resizers.bottomRight.warnBeforeDeletion();
    this._rotateHandle.warnBeforeDeletion();
  }

  /**
   * @param renderSize {Size}
   * @param xPos {number}
   * @param yPos {number}
   * @returns {Point}
   * @private
   */
  _determineLocation(renderSize, xPos, yPos) {
    let width;
    let height;

    let renderSizeWidth = this.orientation > 4 ? renderSize.height : renderSize.width;
    let renderSizeHeight = this.orientation > 4 ? renderSize.width : renderSize.height;

    if (xPos === 0) {
      width = -renderSizeWidth;
    }
    else if (xPos === 1) {
      width = -renderSizeWidth * 0.5;
    }
    else if (xPos === 2) {
      width = 0;
    }

    if (yPos === 0) {
      height = -renderSizeHeight;
    }
    else if (yPos === 1) {
      height = -renderSizeHeight * 0.5;
    }
    else if (yPos === 2) {
      height = 0;
    }

    let position = new Point((-renderSizeWidth * .5) + renderSizeWidth, (-renderSizeHeight * .5) + renderSizeHeight);
    return position.plus(new Point(width, height));
  }

  /**
   * @returns {Promise.<Image>}
   * @private
   */
  _preload() {
    if (this.url) {
      return StaticService.get.ImageEditService.loadImage(this.url)
        .then((result) => {
          this.orientation = result.orientation;
          this.tryUpdate();
          return result;
        });
    }
    return Promise.reject();
  }

  _click() {
    this.focus();
  }

  _hoverIn() {
    this.tryUpdate();
  }

  _hoverOut() {
    this.tryUpdate();
  }

  _dragStart() {
    this._dragStartState = this.snapshot();
  }

  _dragMove(data) {
    this.location = data.controlStart.plus(data.delta);
  }

  _dragEnd() {
    this._onChanged(this._dragStartState);
  }

  _resizeStarted() {
    this._dragStartState = this.snapshot();
  }

  _resizeComplete() {
    this._onChanged(this._dragStartState);
  }

  _rotationStarted() {
    this._dragStartState = this.snapshot();
  }

  _rotationComplete() {
    this._onChanged(this._dragStartState);
  }

  /**
   * @returns {Boolean}
   */
  get hovering() {
    return this._drag.hovering || this._removeHandle.hovering;
  }

  remove() {
    this._stopEvents();
    super.remove();
  }

  _stopEvents() {
    if (this._removeHandle) {
      this._removeHandle.remove();
    }
    if (this._drag) {
      this._drag.stop();
    }
    if (this._resizers) {
      this._resizers.topLeft.remove();
      this._resizers.bottomLeft.remove();
      this._resizers.bottomRight.remove();
    }
  }

  get image() {
    return this._image;
  }
}
