
'use strict';

import Codec from './codec';
import Organization from '../domain/organization';

/**
 * Encodes/Decodes an organization
 */
export default class OrganizationCodec extends Codec {

  constructor() {
    super();
  }

  /**
   * @param value {Organization}
   * @return {{lng: number, contract_id: *, member_ids: (string[]|any[]), org_type: *, name: *, id: *, lat: number, properties: *, unverified_member_ids: *, mdrPid: *}}
   */
  encode(value) {
    return {
      id: value.id,
      org_type: value.type,
      name: value.name,
      lat: value.lat,
      lng: value.lng,
      contract_id: value.contractId,
      properties: value.properties,
      member_ids: value.members,
      unverified_member_ids: value.unverifiedMembers,
      mdr_pid: value.mdrPid,
      parent_organization_id: value.parentOrganizationId
    };
  }

  /**
   * @param value {Object}
   * @return {Organization|null}
   */
  decode(value) {
    if (value) {
      return new Organization(
        value.id,
        value.org_type,
        value.name,
        value.lat,
        value.lng,
        value.contract_id,
        value.properties,
        value.member_ids,
        value.unverified_member_ids,
        value.admin_members || [],
        value.mdr_pid,
        value.parent_organization_id
      );
    }
    else {
      return null;
    }
  }

}
