/*global google:false*/

import LazyVar from '../../model/util/lazy-var';

class GoogleResponseStatuses {

  /**
   * This request was invalid
   * @constructor
   */
  static get INVALID_REQUEST() {
    return 'INVALID_REQUEST';
  }

  /**
   * The place referenced was not found
   * @constructor
   */
  static get NOT_FOUND() {
    return 'NOT_FOUND';
  }

  /**
   * The response contains a valid result
   * @constructor
   */
  static get OK() {
    return 'OK';
  }

  /**
   * The application has gone over its request quota
   * @constructor
   */
  static get OVER_QUERY_LIMIT() {
    return 'OVER_QUERY_LIMIT';
  }

  /**
   * The application is not allowed to use the PlacesService
   * @constructor
   */
  static get REQUEST_DENIED() {
    return 'REQUEST_DENIED';
  }

  /**
   * The PlacesService request could not be processed due to a server error. The request may succeed if you try again
   * @constructor
   */
  static get UNKNOWN_ERROR() {
    return 'UNKNOWN_ERROR';
  }

  /**
   * No result was found for this request
   * @constructor
   */
  static get ZERO_RESULTS() {
    return 'ZERO_RESULTS';
  }

}

export default class GooglePlacesService {

  /**
   * @ngInject
   */
  constructor($q, $log, $document, environment) {
    this.$q = $q;
    this.$log = $log;
    this.$document = $document;
    this._environment = environment;

    this._load = new LazyVar();
    this._places = new LazyVar();
    this._autocomplete = new LazyVar();
    this._sessionToken = new LazyVar();
  }

  getPlaceDetails(placeId) {
    return this.$q
      .all({
        service: this.places,
        sessionToken: this.sessionToken
      })
      .then(({service, sessionToken}) => {
        let deferred = this.$q.defer();

        service.getDetails(
          {
            placeId,
            sessionToken,
            fields: ['address_component', 'geometry', 'name', 'place_id', 'types']
          },
          this._logResponseStatus((place) => deferred.resolve(place))
        );

        return deferred.promise;
      })
      .then((place) => {
        this._sessionToken.clear();
        return GooglePlace.decode(placeId, place);
      });
  }

  /**
   * Gets nearby schools that match the entered criteria
   *
   * @param query {string}
   * @param location {{lat: number, lng: number}}
   * @param magnitude {number}
   * @param exclude {RegExp}
   * @return {Promise<GooglePlace[]>}
   */
  searchNearby(query, location, magnitude, exclude) {
    return this.places
      .then((service) => {
        let deferred = this.$q.defer();

        service.nearbySearch(
          {
            keyword: query,
            // word that must be included in name of place
            name: 'school',
            // The type of place in GooglePlacesAPI
            type: 'school',
            bounds: {
              east: location.lng + magnitude,
              west: location.lng - magnitude,
              north: location.lat + magnitude,
              south: location.lat - magnitude
            }
          },
          this._logResponseStatus((places) => deferred.resolve(places))
        );

        return deferred.promise;
      })
      .then((places) => {
        return places.map((value) => GooglePlace.decode(value.place_id, value))
          .filter((school) => !school.name.match(exclude));
      });
  }

  /**
   * Returns a list of query predictions for the input text
   * @param text {string} the existing text to predict the ending for
   * @param [config] the config of places to return
   * @return {Promise<GooglePlacePrediction[]>}
   */
  getPredictions(text, config = {}) {
    return this.$q
      .all({
        service: this.autocomplete,
        sessionToken: this.sessionToken
      })
      .then(({service, sessionToken}) => {
        let deferred = this.$q.defer();

        service.getPlacePredictions(
          {
            input: text,
            sessionToken,
            types: ['(cities)'],
            ...config
          },
          this._logResponseStatus((predictions) => deferred.resolve(predictions || []))
        );

        return deferred.promise;
      })
      .then((predictions) => {
        return predictions.map((rawPrediction) => GooglePlacePrediction.decode(rawPrediction));
      });
  }

  /**
   * Returns a list of query predictions for the input text
   * @param text {string} the existing text to predict the ending for
   * @param [config] the config of places to return
   * @return {Promise<GooglePlacePrediction[]>}
   */
  getCityPredictions(text, config = {}) {
    return this.$q
      .all({
        service: this.autocomplete,
        sessionToken: this.sessionToken
      })
      .then(({service, sessionToken}) => {
        let deferred = this.$q.defer();

        service.getPlacePredictions(
          {
            input: text,
            sessionToken,
            types: ['(cities)'],
            ...config
          },
          this._logResponseStatus((predictions) => deferred.resolve(predictions || []))
        );

        return deferred.promise;
      })
      .then((predictions) => {
        let citySet = new Set();
        predictions.map((rawPrediction) => GooglePlacePrediction.decode(rawPrediction))
          .map((location) => citySet.add(location.city));
        return [...citySet];
      });
  }

  _logResponseStatus(cb) {
    return (results, status) => {

      if (status === GoogleResponseStatuses.OVER_QUERY_LIMIT ||
        status === GoogleResponseStatuses.INVALID_REQUEST ||
        status === GoogleResponseStatuses.REQUEST_DENIED ||
        status === GoogleResponseStatuses.UNKNOWN_ERROR) {
        this.$log.error(`Google Places API responded with the following status: ${status}`);
        cb([]);
      }
      else {
        cb(results);
      }

    };
  }

  get load() {
    return this._load.value(() => {
      return this._loadScript(`https://maps.googleapis.com/maps/api/js?key=${this._environment.google.auth.apiKey}&libraries=places`)
        .catch((error) => {
          this.$log.error(error);
          this._load.clear();
        });
    });
  }

  get places() {
    return this._places.value(() => {
      return this.load.then(() => {
        let dummyElement = angular.element('<div>test</div>')[0];
        let dummyMap = new google.maps.Map(dummyElement);
        return new google.maps.places.PlacesService(dummyMap);
      });
    });
  }

  get autocomplete() {
    return this._autocomplete.value(() => {
      return this.load.then(() => {
        return new google.maps.places.AutocompleteService();
      });
    });
  }

  /**
   * Creates a session token to use for the autocomplete api make sure autocomplete isn't billing us for each
   * prediction
   * @return {Promise<string>}
   */
  get sessionToken() {
    return this._sessionToken.value(() => {
      return this.load.then(() => {
        return new google.maps.places.AutocompleteSessionToken();
      });
    });
  }

  /**
   * Helper function to load any additional scripts
   * @param src {string}
   * @return {Promise<any>}
   */
  _loadScript(src) {
    return new Promise((resolve, reject) => {
      let script = this.$document[0].createElement('script');
      script.setAttribute('src', src);
      script.onload = () => resolve();
      script.onerror = () => reject();

      let body = angular.element('body');

      if (body[0]) {
        body[0].appendChild(script);
      }
      else {
        reject();
      }
    });
  }

}

class GooglePlacePrediction {

  constructor(placeId, description) {
    this._placeId = placeId;
    this._description = description;
  }

  /**
   * @return {string}
   */
  get placeId() {
    return this._placeId;
  }

  /**
   * @return {string}
   */
  get description() {
    return this._description;
  }

  get city(){
    const endOfCityName = this._description.indexOf(',');
    return this._description.slice(0, endOfCityName);
  }

  static decode(value) {
    return new GooglePlacePrediction(value.place_id, value.description);
  }

}

export class GooglePlace {

  constructor(id, name, lat, lng, street, zip, city, state, country, vicinity) {
    this._id = id;
    this._name = name;
    this._lat = lat;
    this._lng = lng;
    this._street = street;
    this._zip = zip;
    this._city = city;
    this._state = state;
    this._country = country;
    this._vicinity = vicinity;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get lat() {
    return this._lat;
  }

  get lng() {
    return this._lng;
  }

  get street() {
    return this._street;
  }

  get zip() {
    return this._zip;
  }

  get city() {
    return this._city;
  }

  get state() {
    return this._state;
  }

  get country() {
    return this._country;
  }

  get vicinity() {
    return this._vicinity;
  }

  static decode(id, value) {
    return new GooglePlace(
      id,
      value.name,
      value.geometry.location.lat(),
      value.geometry.location.lng(),
      GooglePlace.extractAddressComponent(value.address_components, GooglePlace.StreetNumber) + ' ' + GooglePlace.extractAddressComponent(value.address_components, GooglePlace.Route),
      GooglePlace.extractAddressComponent(value.address_components, GooglePlace.PostalCode),
      GooglePlace.extractAddressComponent(value.address_components, GooglePlace.Locality),
      GooglePlace.extractAddressComponent(value.address_components, GooglePlace.AdministrativeAreaLevel1),
      GooglePlace.extractAddressComponent(value.address_components, GooglePlace.Country),
      value.vicinity
    );
  }

  static extractAddressComponent(components, type) {
    if (angular.isDefined(components)) {
      let component = components.find((component) => component.types.some((t) => t === type));
      return component && component.short_name;
    }
  }

  // All of the Address Types and Address Component Types from google
  // https://developers.google.com/maps/documentation/geocoding/intro

  static get StreetAddress() {
    return 'street_address';
  }

  static get StreetNumber() {
    return 'street_number';
  }

  static get Route() {
    return 'route';
  }

  static get PostalCode() {
    return 'postal_code';
  }

  /**
   * A town or city
   */
  static get Locality() {
    return 'locality';
  }

  /**
   * A state or region
   */
  static get AdministrativeAreaLevel1() {
    return 'administrative_area_level_1';
  }

  static get Country() {
    return 'country';
  }

}
