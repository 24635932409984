/*global MathJax:false*/

'use strict';
import { saveAs } from 'file-saver';
import { AssignmentSheetMetadata } from '../assignment-sheet/assignment-sheet.directive';
import ErrorDialogController from '../error-dialog/error-dialog.controller';
import AssignmentExportDialogTemplate from './assignment-export-dialog.html';

export default class AssignmentExportDialogController {

  /**
   * @ngInject
   */
  constructor($q, $timeout, $scope, $document, $mdDialog, ExportService, assignmentExports, skipStudentSelector, intent, userRole) {
    this.$q = $q;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$document = $document;
    this.$mdDialog = $mdDialog;

    /** @type {ExportService} */
    this._exportService = ExportService;
    /** @type {AssignmentExport[]} */
    this._assignmentExports = assignmentExports;

    this._skipStudentSelector = skipStudentSelector;
    this._intent = intent;
    this._userRole = userRole;
    this._state = this.LOADING;
    this._exportingMessage = '';
    this._assignmentsToExport = [];
    this._exportingCount = undefined;
    this._exportingCountTotal = assignmentExports.length;
    this._configMessage = 'Include extra information (name, assignment name, slide number and classcode)';
    this._zipFileName = this._assignmentExports[0].zipFileName;
    this._currentConfig = undefined;
    this._cancelRemaining = false;
    this._showMetadata = true;

    this._errorDialog = ErrorDialogController.show;
  }

  get LOADING() {
    return 'loading';
  }

  get EXPORTING() {
    return 'exporting';
  }

  get SELECT() {
    return 'select';
  }

  get CONFIG() {
    return 'config';
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @return {string}
   */
  get exportingMessage() {
    return this._exportingMessage;
  }

  /**
   * @return {string}
   */
  get exportingCountMessage() {
    if (angular.isDefined(this._exportingCount) && this._assignmentExports && this._assignmentExports.length > 1) {
      return `${this._exportingCount} / ${this._exportingCountTotal} student works exported`;
    }
    else {
      return '';
    }
  }

  /**
   * @return {string}
   */
  get configMessage() {
    return this._configMessage;
  }

  /**
   * @return {AssignmentExport[]}
   */
  get assignmentExports() {
    return this._assignmentExports;
  }

  /**
   * @return {boolean}
   */
  get skipStudentSelector() {
    return this._skipStudentSelector;
  }

  /**
   * @return {HTMLElement}
   */
  get element() {
    return this._element;
  }

  /**
   * @param value {HTMLElement}
   */
  set element(value) {
    this._element = value;
  }

  /**
   * @return {boolean}
   */
  get showMetadata() {
    return this._showMetadata;
  }

  /**
   * @param value {boolean}
   */
  set showMetadata(value) {
    this._showMetadata = value;
  }

  /**
   * @return {AssignmentSheetMetadata}
   */
  get currentConfig() {
    return this._currentConfig;
  }

  /**
   * @return {boolean}
   */

  get skipSelect() {
    return (this.assignmentExports.length === 1 || this.skipStudentSelector);
  }

  /**
   * Launches export assignment dialog
   * @param $mdDialog
   * @param assignmentExports {AssignmentExport[]}
   * @param intent {ElementIntents}
   * @param userRole {UserRole}
   * @param [skipStudentSelector] {boolean}
   */
  static show($mdDialog, assignmentExports, intent, userRole, skipStudentSelector = false) {
    return $mdDialog.show({
      controller: AssignmentExportDialogController,
      template: AssignmentExportDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: true,
      locals: {
        assignmentExports: assignmentExports,
        skipStudentSelector: skipStudentSelector,
        intent: intent,
        userRole: userRole
      },
      onComplete: AssignmentExportDialogController.onComplete
    });
  }

  static onComplete(scope, element) {
    let ctrl = scope.ctrl;
    ctrl.element = element;
    ctrl.hidden = element.find('.assignment-export-hidden');
    ctrl.loadCanvg()
      .then(() => {
        ctrl.init();
      })
      .catch(() => {
        ctrl.cancelExport();
        ctrl.showErrorDialog();
      });
  }

  loadCanvg() {
    return this.$q.all([
      this._addScript('https://cdnjs.cloudflare.com/ajax/libs/canvg/1.4/rgbcolor.min.js'),
      this._addScript('https://cdnjs.cloudflare.com/ajax/libs/stackblur-canvas/1.4.1/stackblur.min.js'),
      this._addScript('https://cdn.jsdelivr.net/npm/canvg/dist/browser/canvg.min.js')
    ]);
  }

  _addScript(src) {
    return new Promise((resolve, reject) => {
      let script = this.$document[0].createElement('script');
      script.setAttribute('src', src);
      script.onload = () => resolve();
      script.onerror = () => reject();

      let dialog = angular.element('.assignment-export-dialog');

      if (dialog[0]) {
        dialog[0].appendChild(script);
      }
      else {
        reject();
      }
    });
  }

  init() {
    if (this.skipSelect) {
      this.selectAll();
    }
    else {
      this._state = this.SELECT;
    }
  }

  configureSelect() {
    this._state = this.SELECT;
  }

  /**
   * @param assignmentExports {AssignmentExport[]}
   * @param [files] {File[]}
   */
  exportAssignments(assignmentExports, files = []) {
    this._state = this.EXPORTING;

    if (this._cancelRemaining) {
      this.$mdDialog.cancel();
    }
    else if (assignmentExports.length > 0) {
      var assignmentExport = assignmentExports.pop();
      assignmentExport.showMetadata = this.showMetadata;

      this.renderAssignment(assignmentExport)
        .then((dataURLs) => {
          assignmentExport.questionDataURLs = dataURLs;
          return this._exportService.workToPDF(assignmentExport);
        })
        .then((file) => {
          this._exportingCount += 1;
          this.exportAssignments(assignmentExports, [...files, file]);
        })
        .catch(() => {
          this.$mdDialog.cancel();
        });
    }
    else if (files.length > 1) {
      this.saveAsZip(files);
    }
    else {
      this.saveAsFile(files[0]);
    }
  }

  /**
   * @param files {File[]}
   */
  saveAsZip(files) {
    this._exportService
      .toZip(files)
      .then((zipFile) => {
        saveAs(zipFile, this._zipFileName);
        this.$mdDialog.hide();
      });
  }

  /**
   * @param file {File}
   */
  saveAsFile(file) {
    saveAs(file, file.name);
    this.$mdDialog.hide();
  }

  /**
   * @return {Promise.<File>}
   */
  _loadMathJaxScript(){
    return new Promise((resolve, reject) => {
      const mathJaxScript = this.$document[0].getElementById('mathjax-script');
      if (!mathJaxScript) {
        resolve(this._loadMathJaxScriptToBody('https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js'));
      }
      resolve();
    });
  }

  /**
   * @param assignmentExport {AssignmentExport}
   * @return {Promise.<File>}
   */
  renderAssignment(assignmentExport) {
    this._exportingMessage = assignmentExport.exportingMessage;
    return this._loadMathJaxScript().then(() => {
      return assignmentExport.questions.reduce((promise, question) => {
        return promise.then(() => {
            return this.renderQuestion(assignmentExport.assignment, question.id);
          })
          .then(() => this.checkCancel())
          .then(() => this.extractSVGString())
          .then((svgString) => this._exportService.svgStringToDataURL(svgString))
          .then((dataUrl) => Promise.all([promise, dataUrl]))
          .then((accum) => {
            return [...accum[0], accum[1]];
          });
      }, Promise.resolve([]));
    });
  }

  /**
   * @param assignment {Assignment}
   * @param questionId {string}
   */
  renderQuestion(assignment, questionId) {
    return new Promise((resolve) => {
      let config = this.assignmentSheetConfig(assignment, questionId, () => {
        resolve();
      });
      this._currentConfig = config;
      this.$scope.$apply();
    });
  }

  /**
   * @returns {boolean}
   */
  get isOverview() {
    return this._intent === 'work';
  }

  /**
   * @param src {string}
   * @return {Promise.<File>}
   */
  _loadMathJaxScriptToBody(src) {
    return new Promise((resolve, reject) => {
      let script = this.$document[0].createElement('script');
      script.setAttribute('id', 'mathjax-script');
      script.setAttribute('src', src);
      script.onload = () => resolve();
      script.onerror = () => reject();

      let body = angular.element('body');

      if (body[0]) {
        body[0].appendChild(script);
      }
      else {
        reject();
      }
    });
  }

  _convertMathAnswersToSVGs(){
    const mathFieldElements = Array.from(this.$document[0].getElementsByTagName('math-field'));

    mathFieldElements.forEach((mathFieldElement, index) => {
      const fos = Array.from(this.$document[0].getElementsByTagName('foreignObject'));
      const nodeToAttachTo = fos[index];
      const existingMathSVG = nodeToAttachTo.getElementsByClassName('math-jax');

      //if no existing math answer svg exists, create mathSVG using Math Jax and set all SVG attributes
      if (!existingMathSVG.length) {
        const options = MathJax.getMetricsFor(nodeToAttachTo, true);
        const mathJaxElement = MathJax.tex2svg(mathFieldElement.value, options);

        const mathSVG = mathJaxElement.getElementsByTagName('svg')[0];
        mathSVG.setAttribute('x', nodeToAttachTo.getAttribute('x'));
        mathSVG.setAttribute('y', nodeToAttachTo.getAttribute('y'));
        mathSVG.classList.add('math-jax');

        const heightString = mathSVG.getAttribute('height');
        const height = Number(heightString.slice(0, heightString.length - 2)) + 5;

        const widthString = mathSVG.getAttribute('width');
        const width =  Number(widthString.slice(0, widthString.length - 2)) + 5;

        mathSVG.setAttribute('height', `${height}ex`);
        mathSVG.setAttribute('width', `${width}ex`);

        //attach te new math answer svg to foreign object element
        if (mathSVG) {
          nodeToAttachTo.append(mathSVG);
        }

        const questionElementGroups = Array.from(this.$document[0].getElementsByClassName(nodeToAttachTo.getAttribute('class').slice(3)));
        if (questionElementGroups.length) {
          questionElementGroups.forEach((question) => {
            //un-hide the svg border in the math answer fitbs and add class name
            const background = question.getElementsByClassName('background')[0];
            if (question.getElementsByClassName('background')[0]) {
              background.children[0].style.visibility = 'visible';
              background.children[0].classList.add('pdf-visible');
            }
          });
        }

        //when exporting the math field element becomes read-only so this is to convert it back
        mathFieldElement.removeAttribute('read-only');
      }

      //hide the add acceptable answer icon
      const addFitbAnswerIcons = Array.from(this.$document[0].getElementsByClassName('add-fitb-answer'));
      if (addFitbAnswerIcons.length) {
        addFitbAnswerIcons.map((element) => element.parentElement.previousSibling.style.visibility = 'hidden');
      }
    });
  }
  extractSVGString() {
    this._convertMathAnswersToSVGs();
    let selector = '.assignment-export-hidden assignment-sheet svg';
    let svgDOM = angular.element(selector)[0].cloneNode(true);

    //hide the background border for the math fitbs again otherwise we have double borders in the canvas
    Array.from(this.$document[0].getElementsByClassName('pdf-visible')).forEach((rect) => {
      rect.style.visibility = 'hidden';
    });

    return this._exportService.svgDOMtoString(svgDOM);
  }

  /**
   * @param assignment {Assignment|AssignmentWork}
   * @param questionId {string}
   * @param callbackOnInit {Function}
   * @return {AssignmentSheetMetadata}
   */
  assignmentSheetConfig(assignment, questionId, callbackOnInit) {
    return new AssignmentSheetMetadata(assignment, questionId, true, true, null, this._userRole, this._intent, null, 1, callbackOnInit);
  }

  selectAll() {
    this._exportingCount = 0;
    this._assignmentsToExport = this.assignmentExports;
    this._state = this.CONFIG;
  }

  /**
   * @param assignmentExport {AssignmentExport}
   */
  selectStudent(assignmentExport) {
    this._exportingCount = 0;
    this._exportingCountTotal = 1;
    this._assignmentsToExport = [assignmentExport];
    this._state = this.CONFIG;
  }

  performExport() {
    this.exportAssignments(this._assignmentsToExport, []);
  }

  /**
   * @param value
   * @return {Promise}
   */
  checkCancel(value) {
    if (this._cancelRemaining) {
      return Promise.reject();
    }
    else {
      return Promise.resolve(value);
    }
  }

  cancelExport() {
    this._cancelRemaining = true;
    this.$mdDialog.cancel();
  }

  showErrorDialog() {
    this._errorDialog(this.$mdDialog, 'Uh oh! An error occurred', 'Please send an email to support@classkick.com');
  }
}
