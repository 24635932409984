'use strict';

import FreeTrialService from '../../services/free-trial/free-trial.service';
import FreeTrialDialogTemplate from './free-trial-dialog.html';

export default class FreeTrialDialogController {

  /**
   * @ngInject
   */
  constructor($mdDialog, $state, FreeTrialService, CacheService, AuthService, teacherName, teacherEmail, schools) {
    this.$mdDialog = $mdDialog;
    this.$state = $state;

    /** @type {FreeTrialService} */
    this._freeTrialService = FreeTrialService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AuthService} */
    this._authService = AuthService;

    this.teacherName = teacherName;
    this.teacherEmail = teacherEmail;
    this.schools = schools;
    this.school = this._freeTrialService._selectOrganization(schools);

    this.state = 'summary';
    /*
    * summary - default, shows info on free trial, and who's signing up
    * loading - after clicking "start" button, displays spinner while waiting for response
    * congrats - shown when the trial sign-up succeeds
    * error - shown if there's something wrong (see comments below for forcing an error state)
    */

    this.errorMsg = '';
    // this.state = 'error';
    // this.errorMsg = 'This school already has a paid contract.';
    // this.errorMsg = 'This school has already completed a trial.';
  }

  addContract() {
    this.state = 'loading';
    this._freeTrialService.createFreeTrial(
      this.school._id
    ).then(
      (data) => { this.addContractSuccess(data); },
      (error) => { this.addContractError(error); }
    );
  }

  addContractSuccess(data) {
    this.state = 'congrats';

    // force the user info to update (and show the badge!)
    this._cacheService.reset();
    this._authService.processTokenResult(data.token, this._authService.rememberMe)
    .then(() => {
      this.$state.reload();
    });
  }

  addContractError(error) {
    this.errorMsg = error.message || '';
    this.state = 'error';
  }

  static show($mdDialog, source) {
    return $mdDialog.show({
      controller: FreeTrialDialogController,
      template: FreeTrialDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      locals: {
        teacherName: source.teacherName,
        teacherEmail: source.teacherEmail,
        schools: source.schools
      }
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
