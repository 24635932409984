
'use strict';

import Point from './point';
import Size from './size';

export default class Rect {
  constructor(x, y, width, height) {
    this.location = new Point(x, y);
    this.size = new Size(width, height);
  }

  get width() {
    return this.size.width;
  }

  get height() {
    return this.size.height;
  }
}
