
'use strict';

/**
 * Defines the
 */
export default class MimeTypes {
  /**
   * @returns {string}
   */
  static get IMAGE_JPEG() {
    return 'image/jpeg';
  }

  /**
   * @returns {string}
   */
  static get IMAGE_PNG() {
    return 'image/png';
  }

  /**
   * G pronounced like in gravy. This is not peanut butter, you sons of a silly person
   * @returns {string}
   */
  static get IMAGE_GIF() {
    return 'image/gif';
  }

  /**
   * @returns {string}
   */
  static get IMAGE_BMP() {
    return 'image/bmp';
  }

  /**
   * @returns {string}
   */
  static get APPLICATION_PDF() {
    return 'application/pdf';
  }

  /**
   * @returns {string}
   */
  static get AUDIO_WAV() {
    return 'audio/wav';
  }

  /**
   * @returns {string}
   */
  static get AUDIO_AAC() {
    return 'audio/aac';
  }

  /**
   * @returns {string}
   */
  static get AUDIO_MP3() {
    return 'audio/mp3';
  }

  /**
   * @returns {string}
   */
  static get AUDIO_WEBM() {
    return 'audio/webm';
  }

  /**
   * @return {string}
   */
  static get AUDIO_OGG() {
    return 'audio/ogg';
  }

  /**
   * @return {string}
   */
  static get TEXT_CSV() {
    return 'text/csv';
  }

  /**
   * @returns {string[]}
   */
  static get ImageSupport() {
    return [
      MimeTypes.IMAGE_BMP,
      MimeTypes.IMAGE_GIF,
      MimeTypes.IMAGE_PNG,
      MimeTypes.IMAGE_JPEG,
      MimeTypes.APPLICATION_PDF
    ];
  }

  /**
   * @returns {string[]}
   */
  static get BackgroundImageSupport() {
    return [
      MimeTypes.IMAGE_BMP,
      MimeTypes.IMAGE_PNG,
      MimeTypes.IMAGE_JPEG,
      MimeTypes.APPLICATION_PDF
    ];
  }

  /**
   * @returns {string[]}
   */
  static AudioSupport() {
    return [
      MimeTypes.AUDIO_WAV,
      MimeTypes.AUDIO_AAC,
      MimeTypes.AUDIO_MP3,
      MimeTypes.AUDIO_WEBM,
      MimeTypes.AUDIO_OGG
    ];
  }
}
