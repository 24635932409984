
'use strict';

import PropertyMapping from './property-mapping';

/**
 * Represents the firebase location of a non-collection property within a FirebaseObject
 */
export default class FirebasePropertyMapping extends PropertyMapping {

  /**
   * @param uriTemplate {string} relative uri snippet, may include curly brace delineated replacements for {id} values
   * @param connectIndicator {function(instance)} callback used to indicate whether to subscribe to this property of the given instance
   * @param getter {function(instance)} callback used to retrieve the property value given an instance of the mapped object
   * @param setter {function(instance, value)} callback used to set the property value on the instance of the mapped object
   * @param [codec] {codec} used to encode/decode the values between the client and firebase
   * @param [onChange] {function(instance, value)} callback when the value is set
   */
  constructor(uriTemplate, connectIndicator, getter, setter, codec, onChange) {
    super(uriTemplate, connectIndicator, codec);

    this._getter = getter;
    this._setter = setter;
    this._onChange = onChange;
  }

  /**
   * Retrieves the value represented by this property mapping in the firebase object
   * @param instance {FirebaseObject}
   * @returns {*}
   */
  get(instance) {
    return this._getter(instance);
  }

  /**
   * Sets the value represented by this property mapping in the provided firebase object
   * @param instance {FirebaseObject}
   * @param value {*}
   * @private
   */
  _set(instance, value) {
    this._setter(instance, value);
    if (this._onChange) {
      this._onChange(instance, value);
    }
  }
}
