import SignUpOrLoginDialog from './sign-up-or-login-dialog.html';
import ErrorDialogController from '../error-dialog/error-dialog.controller';

class SignUpOrLoginDialogActions {

  static get LOGIN(){
    return 'login';
  }

  static get SIGNUP(){
    return 'sign-up';
  }
}

export default class SignUpOrLoginDialogController {
  /**
   * @ngInject
   */
  constructor($mdDialog, $scope, AnalyticsService, BreadcrumbService, closeDialogAndReload, handleError, action) {
    this.$mdDialog = $mdDialog;
    this.$scope = $scope;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    this._action = action;
    this.closeDialogAndReload = closeDialogAndReload;
    this.handleError = handleError;

    this._errorDialog = ErrorDialogController.show;

    this._analyticsService.sendEvent({
      eventTag: 'page_viewed',
      properties: {
         location: 'signUpOrLoginDialog'
      }
    });
  }

  get action () {
    return this._action;
  }

  get isLogin(){
    return this._action === SignUpOrLoginDialogActions.LOGIN;
  }

  get isSignUp(){
    return this._action === SignUpOrLoginDialogActions.SIGNUP;
  }

  get error(){
    return this._error;
  }

  close() {
    this.$mdDialog.hide();
  }

  goToLoginView (){
    if (this.action !== SignUpOrLoginDialogActions.LOGIN) {
      return this._action = SignUpOrLoginDialogActions.LOGIN;
    }
  }

  goToSignUpView (){
    if (this.action !== SignUpOrLoginDialogActions.SIGNUP) {
      return this._action = SignUpOrLoginDialogActions.SIGNUP;
    }
  }

  handleErrorMessage(error){
    this.handleError(error);
  }
  static show($mdDialog, closeDialogAndReload, handleError, action = SignUpOrLoginDialogActions.SIGNUP) {
    return $mdDialog.show({
      controller: SignUpOrLoginDialogController,
      template: SignUpOrLoginDialog,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: false,
      locals: {
        closeDialogAndReload,
        handleError,
        action
      }
    });
  }
}
