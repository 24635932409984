
'use strict';

const types = {
  ICON_BUTTON: 'icon-button',
  TEXT_BUTTON: 'text-button',
  RENDERED_BUTTON: 'rendered-button',
  DIVIDER: 'divider',
  SELECT: 'select',
  POINTS: 'points-field',
  FLEX_SPACE: 'flex-space'
};

/**
 * Base class for toolbar things
 */
export class ToolbarItem {
  /**
   * @param type {string}
   */
  constructor(type) {
    this._type = type;
    /** @type {boolean} */
    this._visible = true;
  }

  /**
   * @returns {string}
   */
  get type() {
    return this._type;
  }

  get collapsible() {
    return true;
  }

  /**
   * @return {boolean}
   */
  get visible() {
    return this._visible;
  }

  /**
   * @param value {boolean}
   */
  set visible(value) {
    this._visible = value;
  }

  /**
   * @returns {int}
   */
  get width() {
    throw new Error('not implemented');
  }

  /**
   * @returns {{ICON_BUTTON: string, TEXT_BUTTON: string, RENDERED_BUTTON: string, DIVIDER: string, SELECT: string, POINTS: string, FLEX_SPACE: string}}
   */
  static get TYPES() {
    return types;
  }

  get flexClass() {
    return 'flex-initial';
  }
}

//-----------------------------------------------------

/**
 * A superclass for a button or toggle button
 */
export class Button extends ToolbarItem {
  /**
   * @param type {string}
   * @param tooltip {string}
   * @param onClick {function(MouseEvent, Button)},
   * @param onMouseEnter {function(MouseEvent, Button)}
   * @param onMouseLeave {function(MouseEvent, Button)}
   * @param [collapsible] {boolean} default true
   * @param [width] {number} default 44
   */
  constructor(type, tooltip, onClick, onMouseEnter = null, onMouseLeave = null, collapsible = true, width = 44) {
    super(type);
    this._tooltip = tooltip;
    this._onClick = onClick;
    this._onMouseEnter = onMouseEnter;
    this._onMouseLeave = onMouseLeave;
    this._collapsible = collapsible;
    this._toggled = false;
    this._width = width;
    this._disabled = false;
  }

  /**
   * @returns {string}
   */
  get tooltip() {
    return this._tooltip;
  }

  /**
   * @param [ev] {MouseEvent}
   */
  click(ev) {
    if (this._onClick) {
      this._onClick(ev, this);
    }
  }

  /**
   * @param [ev] {MouseEvent}
   */
  mouseEnter(ev) {
    if (this._onMouseEnter) {
      this._onMouseEnter(ev, this);
    }
  }

  /**
   * @param [ev] {MouseEvent}
   */
  mouseLeave(ev) {
    if (this._onMouseLeave) {
      this._onMouseLeave(ev, this);
    }
  }

  /**
   * @returns {boolean}
   */
  get collapsible() {
    return this._collapsible;
  }

  toggle() {
    this.toggled = !this.toggled;
  }

  /**
   * @returns {boolean}
   */
  get toggled() {
    return this._toggled;
  }

  /**
   * @param value {boolean}
   */
  set toggled(value) {
    this._toggled = value;
  }

  /**
   * @return {boolean}
   */
  get disabled() {
    return this._disabled;
  }

  /**
   * @param value {boolean}
   */
  set disabled(value) {
    this._disabled = value;
  }

  get width() {
    return this._width;
  }
}

//-----------------------------------------------------

export class IconButton extends Button {
  /**
   * @param iconClass {string|string[]}
   * @param subIconClass {boolean}
   * @param tooltip {string}
   * @param onClick {function(MouseEvent, Button)}
   * @param [onMouseEnter] {function(MouseEvent, Button)}
   * @param [onMouseLeave] {function(MouseEvent, Button)}
   * @param [collapsible] {boolean} default true
   * @param [width] {number} default 44
   */
  constructor(iconClass, subIconClass = null, tooltip, onClick, onMouseEnter, onMouseLeave, collapsible, width) {
    super(ToolbarItem.TYPES.ICON_BUTTON, tooltip, onClick, onMouseEnter, onMouseLeave, collapsible, width);

    this._icon = iconClass;
    this._subIcon = subIconClass;
  }

  get icon() {
    return this._icon;
  }

  get subIcon() {
    return this._subIcon;
  }
}

//-----------------------------------------------------

export class TextButton extends Button {
  /**
   * @param text {string}
   * @param subIconClass {string}
   * @param tooltip {string}
   * @param onClick {function(MouseEvent, Button)}
   * @param [onMouseEnter] {function(MouseEvent, Button)}
   * @param [onMouseLeave] {function(MouseEvent, Button)}
   * @param [collapsible] {boolean} default true
   * @param [width] {number} default 44
   */
  constructor(text, subIconClass = null, tooltip, onClick, onMouseEnter, onMouseLeave, collapsible, width) {
    super(ToolbarItem.TYPES.TEXT_BUTTON, tooltip, onClick, onMouseEnter, onMouseLeave, collapsible, width);

    this._text = text;
    this._subIcon = subIconClass;
  }

  get subIcon() {
    return this._subIcon;
  }

  get text() {
    return this._text;
  }
}

//-----------------------------------------------------

export class RenderedButton extends Button {
  /**
   * @param selector {string}
   * @param tooltip {string}
   * @param onClick {function(MouseEvent, Button)}
   * @param [onMouseEnter] {function(MouseEvent, Button)}
   * @param [onMouseLeave] {function(MouseEvent, Button)}
   * @param [collapsible] {boolean} default true
   * @param [width] {number} default 44
   */
  constructor(selector, tooltip, onClick, onMouseEnter, onMouseLeave, collapsible, width) {
    super(ToolbarItem.TYPES.RENDERED_BUTTON, tooltip, onClick, onMouseEnter, onMouseLeave, collapsible, width);

    this._selector = selector;
  }

  get selector() {
    return this._selector;
  }

  attach() {

  }
}

//-----------------------------------------------------

/**
 * A Select button (single choice)
 */
export class Select extends ToolbarItem {
  /**
   * @param values {{value: string, icon: string}[]}
   * @param onUpdate {function({value: string, icon: string}, Select)}
   * @param initialValue {{value: string, icon: string}}
   * @param tooltip {string}
   * @param [containerClass] {string}
   */
  constructor(values, onUpdate, initialValue = null, tooltip = null, containerClass = '') {
    super(ToolbarItem.TYPES.SELECT);
    this._values = values;
    this._currentValue = initialValue;
    this._onUpdate = onUpdate;
    this._tooltip = tooltip;
    this._containerClass = containerClass;
  }

  get width() {
    return 60;
  }

  get containerClass() {
    return this._containerClass;
  }

  /**
   * @returns {{value: string, icon: string}}
   */
  get currentValue() {
    return this._currentValue;
  }

  /**
   * @param value {{value: string, icon: string}}
   */
  set currentValue(value) {
    if (this._currentValue !== value) {
      this._currentValue = value;
      this.onUpdate();
    }
  }

  onUpdate() {
    return this._onUpdate(this._currentValue, this);
  }

  /**
   * @returns {{value: string, icon: string}[]}
   */
  get values() {
    return this._values;
  }

  get tooltip() {
    return this._tooltip;
  }
}

//-----------------------------------------------------

/**
 * Represents the Points text field
 */
export class PointsField extends ToolbarItem {
  constructor(width = 100) {
    super(ToolbarItem.TYPES.POINTS);

    this._width = width;
  }

  get width() {
    return this._width;
  }
}

//-----------------------------------------------------

/**
 * Represents where extra filler space should be used in the toolbar
 */
export class FlexSpace extends ToolbarItem {
  constructor() {
    super(ToolbarItem.TYPES.FLEX_SPACE);
  }

  get width() {
    return 0;
  }

  get flexClass() {
    return 'flex-grow';
  }
}

//-----------------------------------------------------

/**
 * Represents a section divider
 */
export class Divider extends ToolbarItem {
  constructor() {
    super(ToolbarItem.TYPES.DIVIDER);
  }

  get width() {
    return 5;
  }
}
