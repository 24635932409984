import QuoteIframeDialog from './quote-iframe-dialog.html';

export default class QuoteIframeDialogController {
  /**
   * @ngInject
   */
  constructor($mdDialog, $scope, AnalyticsService, BreadcrumbService, orderUrl, orderId) {
    this.$mdDialog = $mdDialog;
    this.$scope = $scope;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;

    this._orderUrl = orderUrl;
    this._orderId = orderId;
  }

  static show($mdDialog, orderUrl, orderId) {
    return $mdDialog.show({
      controller: QuoteIframeDialogController,
      template: QuoteIframeDialog,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: false,
      locals: {
        orderUrl,
        orderId
      }
    });
  }

  get orderUrl() {
    return `${this._orderUrl}?lock_in_price=null&source=null&uid=null&isIframe=true`;
  }

  close() {
    this._analyticsService.closedQuotePageIframe(this._orderId);
    this.$mdDialog.hide();
  }

  goToOrder() {
    this._analyticsService.goToQuotePageFromIframe(this._orderId);
    this._breadcrumbService.go('root.order-detail', {
      orderId: this._orderId
    });
    this.$mdDialog.hide();
  }
}
