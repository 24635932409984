export default function CkSelectDirective() {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      'ckSelect': '='
    },
    link: ($scope, $element) => {
      $scope.$watch('ckSelect', () => {
        let mdSelectIcon = $element.find('.md-select-icon');
        let ckSelectIcon = angular.element('<span class="ck-select-icon"><i class="ck fa ck-carrot"></i></span>');
        mdSelectIcon.replaceWith(ckSelectIcon);
      });
    }
  };
}
