
'use strict';

import Codec from './codec';
import ElementCodec from './element-codec';

import AudioClip from '../ui/elements/audio-clip';
/**
 * Encodes/Decodes an audio chip
 */
export default class AudioClipCodec extends Codec {
  constructor() {
    super();
  }

  /**
   * @param value {AudioClip}
   * @returns {{data: Object, metadata: Object}}
   */
  encode(value) {
    return ElementCodec.preEncode(value, (size, center) => {

      return {
        type: value.type,
        audio_url: value.url,
        size: size,
        center: center
      };
    });
  }

  /**
   * @param value {object}
   * @param key {string}
   * @returns {AudioClip}
   */
  decode(value, key) {
    return ElementCodec.preDecode(value, (metadata, size, location) => {

      return new AudioClip(
        key,
        metadata,
        location,
        size,
        value.data.audio_url
      );
    });
  }
}
