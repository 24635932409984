import moment from 'moment';

import Order from '../../model/domain/order';

'use-strict';

import {OrderManager} from '../../model/domain/order-manager';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';

export default class MetricListController {
  constructor($q, $filter, $location, $stateParams, $state, $mdSidenav, $log, $mdDialog, AuthService, CacheService, ContractService,
    BreadcrumbService, OrganizationService, AnalyticsService, OrderService, StaticContentService) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;
    this.$filter = $filter;
    this.$location = $location;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$mdSidenav = $mdSidenav;
    this.$log = $log;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;

    /** @type {OrganizationUsageStats} */
    this._organizationUsageStats = undefined;
    this._schoolId = $stateParams.schoolId;
    this._analyticsService = AnalyticsService;
    this._orderManager = new OrderManager($q, $log, $filter, $mdDialog, $location, AuthService, OrderService, OrganizationService,
      BreadcrumbService, CacheService, ContractService);
    this._schools = [];
    this._selectedSchool = null;
    this._orderService = OrderService;
    this._loading = true;
    this._error = undefined;
    this._errorDialog = ErrorDialogController.show;

    this._init();
  }

  _init() {
    this._loading = true;

    this.$q
      .all({
        schools: this._cacheService.getSchools(),
      })
      .then(({ schools }) => {
        this._schools = schools;
        if (!this._schoolId) {
          this._schoolId = this._schools[0].id;
        }
        this._selectedSchool = schools.find((school) => school.id === this._schoolId);

        this._loading = false;
        if (this._selectedSchool) {
          return this._organizationService.getUsageStatsWithoutAuth(this._selectedSchool.id);
        }
      })
      .then((organizationUsageStats) => {
        this._organizationUsageStats = organizationUsageStats;
        return this._orderService.getForOrganization(this._schoolId);

      }).then((orders) => {
        this._order = Order.findActiveOrder(orders);
    })
      .catch((err) => {
        this.$log.error('#init', err);
        this._error = err;
        this._loading = false;
      });
  }

  get errorMessage() {
    return 'Sorry, there was a problem loading the metrics for this organization';
  }

  get currentUserIsNotAdminError() {
    return 'currentUserIsNotAdminError';
  }

  /**
   * @returns {OrganizationUsageStats}
   */
  get organizationUsageStats() {
    return this._organizationUsageStats;
  }

  /**
   * Toggles the side menu.
   */
  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }

  shareMetrics() {
    this._analyticsService.emailMetrics(this._schoolId, this._order && this._order.id);
    this._orderManager.shareMetrics(
      this._order,
      undefined,
      undefined,
      'metrics',
      false,
      this.organizationUsageStats,
    );
  }

  selectSchool(school) {
    if (school.id !== this._schoolId) {
      this.$state.go(
        '^.metrics', { schoolId: school.id }
      );
    }
  }

  getBarWidth(schoolTotal, approxAvg) {
    if (schoolTotal < approxAvg) {
      let calculatedWidth = Math.round(((schoolTotal / approxAvg) * 100) * 10) / 10;
      return calculatedWidth < 7 ? '7%' : `${calculatedWidth}%`;
    }
    else {
      return '100%';
    }
  }

  yearlySavings() {
    return parseInt(this.organizationUsageStats.approximateSavings).toLocaleString('en-US');
  }
  /**
   * Indicates if initial load has completed
   * @returns {boolean}
   */
  get loading() {
    return this._loading;
  }

  get error() {
    return this._error;
  }

  get currentUserId() {
    return this._authService && this._authService.currentUserId;
  }

  get statsTimePeriod() {
    // stats data received from the data warehouse resets every July 1 <current_year>

    let academicMonth = '07';
    let academicDay = '01';
    let thisYear = moment().year();
    let previousYear = moment().subtract(1, 'years').year();

    if (moment().isAfter(`${thisYear}-${academicMonth}-${academicDay}`)) {
      return moment(`${thisYear}-${academicMonth}-${academicDay}`).format('MMM Do YYYY');
    } else {
      return moment(`${previousYear}-${academicMonth}-${academicDay}`).format('MMM Do YYYY');
    }
  }

  hasOrganizationUsageStats() {
    return !!(this.organizationUsageStats && (this.organizationUsageStats.teachersActiveThisYear || this.organizationUsageStats.studentsActiveThisYear || this.organizationUsageStats.assignmentsWorkedOnThisYear || this.organizationUsageStats.pagesWorkedOnThisYear || this.organizationUsageStats.approximateSavings));
  }

  goToWatchTutorialVideo() {
    this._staticContentService.goToGettingStartedVideoTwo();
  }
}
