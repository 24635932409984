
'use strict';

import UserCodec from '../../model/codec/user-codec';
import {UserRoles} from '../../model/domain/user';
import QuestionHelpers from '../../model/domain/question-helpers';
import LibraryAssignmentCodec from '../../model/codec/library-assignment-codec';
import UserPreferencesCodec from '../../model/codec/user-preferences-codec';
import BuyerStateCodec from '../../model/codec/buyer-state-codec';

export default class UserService {

  constructor($q, $state, environment, HttpService, AuthService, StorageService, NotificationService, FirebaseService) {
    'ngInject';

    /** @type {$q} */
    this.$q = $q;
    /** @type {$state} */
    this.$state = $state;
    /** @type {environment} */
    this._environment = environment;
    /** @type {HttpService} */
    this._httpService = HttpService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;
    /** @type {NotificationService} */
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    this._userCodec = new UserCodec();
    this._buyerStateCodec = new BuyerStateCodec();
    this._userPreferencesCodec = new UserPreferencesCodec();
    /** @type {LibraryAssignmentCodec} */
    this._libraryAssignmentCodec = new LibraryAssignmentCodec(this._firebaseService);
  }

  /**
   * Gets a user
   *
   * @param userId
   * @returns {*|Promise.<User>}
   */
  getUser(userId) {
    if (!userId && this._authService.authData) {
      userId = this._authService.authData.id;
    }
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/users/${userId}`)
      .then((userData) => this._userCodec.decode(userData));
  }

  /**
   * Gets a user from contract
   *
   * @param contractId
   * @param userId
   * @returns {*|Promise.<User>}
   */
  getUserForContract(contractId, userId) {
    return this._httpService
      .authGet(`${this._environment.serverUrlBase}/v1/contracts/${contractId}/users/${userId}`)
      .then((userData) => this._userCodec.decode(userData));
  }

  /**
   * Updates a user
   *
   * @param id
   * @param User
   * @returns {*}
   */
  updateUser(user) {
    return this._httpService.authPut(`${this._environment.serverUrlBase}/v1/users/${user.id}`, this._userCodec.encode(user));
  }

  /**
   * Updates user preferences
   *
   * @param userId
   * @returns {Promise}
   */
  updateUserPreferences(userId, preferences) {
    const body = this._userPreferencesCodec.encode({ userId, preferences });

    return this._httpService.authPost(`${this._environment.serverUrlBase}/v1/users/${userId}/preferences`, body);
  }

  /**
   * Updates an anon student's name
   *
   * @param rosterId
   * @param userId
   * @param newName
   * @returns {Promise}
   */
  updateAnonStudent(rosterId, userId, newName) {
    return this._httpService.authPost(
      `${this._environment.serverUrlBase}/v1/rosters/${rosterId}/users/${userId}/update-name`,
      {
        name: newName
      }
    );
  }

  /**
   * @param user
   * @param contractId
   * @returns {Promise.<T>}
   */
  updateContractUser(user, contractId) {
    return this._httpService.authPut(
      `${this._environment.serverUrlBase}/v1/contracts/${contractId}/users/${user.id}/`, this._userCodec.encode(user)
    );
  }

  /**
   * @param userId {string}
   * @param contractId {string}
   * @param newPassword {string}
   * @returns {Promise.<T>}
   */
  updateContractUserPassword(userId, contractId, newPassword) {
    return this._httpService.authPut(
      `${this._environment.serverUrlBase}/v1/contracts/${contractId}/users/${userId}/password`,
      {
        password: newPassword
      }
    );
  }

  /**
   * @param rosterId
   * @param userId
   * @returns {Array<User>}
   */
  getStudentOnRoster(rosterId, userId) {
    return this._httpService.authGet(`${this._environment.serverUrlBase}/v1/rosters/${rosterId}/users/${userId}`)
      .then((userData) => this._userCodec.decode(userData));
  }

  /**
   * @param assignment {Assignment}
   * @param userId {string}
   * @returns {QuestionHelpers}
   */
  getQuestionHelpers(assignment, userId) {
    let helpersNotification = this._notificationService.getHelpersNotification(assignment, userId);
    return new QuestionHelpers(helpersNotification);
  }

  /**
   * @param assignment {Assignment}
   * @param userId {string}
   * @returns {QuestionHelpers}
   */
  getPeerQuestionHelpers(assignment, userId) {
    let helpersNotification = this._notificationService.getHelpersNotification(assignment, userId);
    return new QuestionHelpers(helpersNotification, (helper) => helper.role !== UserRoles.TEACHER);
  }

  /**
   * @param contractId {string}
   * @returns {Promise.<User[]>}
   */
  getContractUsers(contractId) {
    return this._httpService.authGet(`${this._environment.serverUrlBase}/v1/contracts/${contractId}/users`)
      .then((result) => result.users.map((x) => this._userCodec.decode(x)));
  }

  /**
   * @param userId {string}
   * @return {Promise.<{username:string, assignments: Array}>}
   */
  getPublicAssignments(userId) {
    return this._httpService.authGet(`${this._environment.serverUrlBase}/v1/public-assignments/${userId}?isLibraryAssignment=true`)
      .then((response) => {
        return {
          username: response.name,
          assignments: response.assignments.map((a) => this._libraryAssignmentCodec.decode(a))
        };
      });
  }

  getPublicAssignmentsWithFolderAndTags(userId) {
    return this._httpService.authGet(`${this._environment.serverUrlBase}/v1/public-assignments/${userId}`)
      .then((response) => {
        return {
          username: response.name,
          assignments: response.assignments.map((a) => this._libraryAssignmentCodec.decode(a))
        };
      });
  }

  /**
   * @param id {string} email, phone number, or username of user
   */
  find(id) {
    return this._httpService.authPost(`${this._environment.serverUrlBase}/v1/users/find`, { id })
      .then((response) => this._userCodec.decode(response));
  }

  /**
   * @param id {string} email, phone number, or username of user
   * @param contract {string} contractId
   */
  findStudentAsContractTeacher(id, contractId) {
    return this._httpService.authPost(`${this._environment.serverUrlBase}/v1/users/find/${contractId}`, { id })
      .then((response) => this._userCodec.decode(response));
  }

  /**
   * Deletes a user from our database (use with caution)
   * @param userId
   * @return {Promise}
   */
  delete(userId) {
    return this._httpService.authDelete(`${this._environment.serverUrlBase}/v1/users/${userId}`);
  }

  /**
   * @param oldEmail {string}
   * @param password {string}
   * @param [newEmail] {string}
   * @return {Promise}
   */
  changeAuthEmail(oldEmail, password, newEmail) {
    let data = {
      old_email: oldEmail,
      new_password: password
    };

    if (newEmail) {
      data['new_email'] = newEmail;
    }

    return this._httpService.authPost(`${this._environment.serverUrlBase}/v1/users/change-auth-email`, data);
  }

  /**
   * @param email {string}
   * @param password {string}
   * @return {Promise}
   */
  setPassword(email, password) {
    return this._httpService.authPost(
      `${this._environment.serverUrlBase}/v1/users/set-password`,
      {
        id: email,
        password
      }
    );
  }

  /**
   * @param userId {string}
   * @return {Promise}
   */
  getBuyerState(userId) {
    return this._httpService.authGet(`${this._environment.serverUrlBase}/v1/users/${userId}/buyer-state`)
      .then((response) => this._buyerStateCodec.decode(response));
  }
}
