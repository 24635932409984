
'use strict';

import FirebaseObject from '../firebase-mapping/firebase-object';
import ModelMapping from '../firebase-mapping/model-mapping';
import FirebasePropertyMapping from '../firebase-mapping/firebase-property-mapping';
import SimpleCodec from '../codec/simple-codec';

let v1Mapping = new ModelMapping('/notifications/v1/{url}',
  {
    update: new FirebasePropertyMapping(
      '',
      () => { return true; },
      (instance) => { return instance._value; },
      (instance, value) => { instance._value = value; },
      new SimpleCodec(),
      (instance, value) => { instance._onChanged(value); }
    )
  }
);

let v2Mapping = new ModelMapping('/notifications/v2/{url}',
  {
    update: new FirebasePropertyMapping(
      '',
      () => { return true; },
      (instance) => { return instance._value; },
      (instance, value) => { instance._value = value; },
      new SimpleCodec(),
      (instance, value) => { instance._onChanged(value); }
    )
  }
);

let v3Mapping = new ModelMapping('/notifications/v3/{url}',
  {
    update: new FirebasePropertyMapping(
      '',
      () => { return true; },
      (instance) => { return instance._value; },
      (instance, value) => { instance._value = value; },
      new SimpleCodec(),
      (instance, value) => { instance._onChanged(value); }
    )
  }
);

export default class Notification extends FirebaseObject {

  /**
   * @param apiVersion {string} v1 | v2 | v3
   * @param notificationShardKey {NotificationShardKey} The notification shard key
   * @param url
   * @param FirebaseService
   * @param [showInitialNotification] {boolean}
   * @param [traceTag] {string} Trace logging tag for firebase activity
   */
  constructor(apiVersion, notificationShardKey, url, FirebaseService, showInitialNotification = true, traceTag = '') {
    let firebaseInstanceId = FirebaseService.firebaseNotificationInstanceIdForShardKey(notificationShardKey);

    super(
      new Map().set('url', url),
      Notification.getMapping(apiVersion),
      firebaseInstanceId,
      FirebaseService,
      undefined,
      true,
      `${traceTag}[instance=${firebaseInstanceId}, ${notificationShardKey}, url=${url}]`
    );

    this._showNotification = showInitialNotification;
    this._initialValue = undefined;
  }

  static get V1() {
    return 'v1';
  }

  static get V2() {
    return 'v2';
  }

  static get V3() {
    return 'v3';
  }

  /**
   * Separate model mappings are required to fix incorrect mappings with small token values
   * TODO revert to single model mapping after ckw-537 model mapping must handle all token values
   * @param apiVersion {string} v1 | v2
   * @returns {ModelMapping}
   */
  static getMapping(apiVersion) {
    if (apiVersion === Notification.V1) {
      return v1Mapping;
    }
    else if (apiVersion === Notification.V2) {
      return v2Mapping;
    }
    else if (apiVersion === Notification.V3) {
      return v3Mapping;
    }
    else {
      throw new Error(`${apiVersion} is an invalid api version`);
    }
  }

  start() {
    this._logTrace('start');
    this.firebase.loadOnce()
      .then(() => {
        super.start();
      });
  }

  _onChanged(value) {
    if (this._raiseNotification(value)) {
      this._changed(value);
    }
  }

  _raiseNotification(value) {
    if (angular.isUndefined(this._initialValue)) {
      // We get here during `this.firebase.loadOnce()`
      this._initialValue = value;
      return false;
    }
    if (this._showNotification || !angular.equals(value, this._initialValue)) {
      this._showNotification = true;
    }
    return this._showNotification;
  }

}

