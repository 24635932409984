'use strict';

import lodash from 'lodash';
import LazyVar from '../../model/util/lazy-var';
import MessageSet from '../../model/domain/message-set';
import SessionData from '../../model/domain/session-data';
import Helper from '../../model/domain/helper';
import { ABTest, ABTestVariable } from '../ab-test/ab-test-service';
import ProInfo from '../../model/domain/pro-info';
import { AppConfiguration } from '../../model/domain/app-configuration';
import { OrganizationTypes } from '../../model/domain/organization';
import SharedWorkSessionData from '../../model/domain/shared-work-session-data';

export default class CacheService {

  /**
   * @param $q
   * @param $timeout
   * @param BootstrapService {BootstrapService}
   * @param AssignmentService {AssignmentService}
   * @param AssignmentWorkService {AssignmentWorkService}
   * @param RosterService {RosterService}
   * @param ClassCodeService {ClassCodeService}
   * @param UserService {UserService}
   * @param AuthService {AuthService}
   * @param MessageService {MessageService}
   * @param NotificationService {NotificationService}
   * @param HelpRequestService {HelpRequestService}
   * @param StickerService {StickerService}
   * @param ContractService {ContractService}
   * @param StudentCacheService {StudentCacheService}
   * @param SubscriptionService {SubscriptionService}
   * @param ABTestService {ABTestService}
   * @param StorageService {StorageService}
   * @param FeedbackService {FeedbackService}
   * @param FirebaseService {FirebaseService}
   * @param OrganizationService {OrganizationService}
   * @param HelpArticleService {HelpArticleService}
   * @param AnalyticsService {AnalyticsService}
   * @param SharedWorksService {SharedWorksService}
   */
  constructor($q, $log, $timeout, BootstrapService, AssignmentService, AssignmentWorkService,
              RosterService, ClassCodeService, UserService, AuthService,
              MessageService, NotificationService, HelpRequestService,
              StickerService, ContractService, StudentCacheService,
              SubscriptionService, ABTestService, StorageService,
              FeedbackService, FirebaseService, OrganizationService,
              HelpArticleService, AnalyticsService, SharedWorksService,
              CoTeacherService) {
    'ngInject';

    this.$q = $q;
    this.$log = $log;
    this.$timeout = $timeout;

    /** @type {BootstrapService} */
    this._bootstrapService = BootstrapService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {ClassCodeService} */
    this._classCodeService = ClassCodeService;
    /** @type {UserService} */
    this._userService = UserService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {MessageService} */
    this._messageService = MessageService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;
    /** @type {HelpRequestService} */
    this._helpRequestService = HelpRequestService;
    /** @type {StickerService} */
    this._stickerService = StickerService;
    /** @type {ContractService} */
    this._contractService = ContractService;
    /** @type {StudentCacheService} */
    this._studentCacheService = StudentCacheService;
    /** @type {SubscriptionService} */
    this._subscriptionService = SubscriptionService;
    /** @type {ABTestService} */
    this._abTestService = ABTestService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {FeedbackService} */
    this._feedbackService = FeedbackService;
    /** @type {FirebaseService} */
    this._firebaseService = FirebaseService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {HelpArticleService} */
    this._helpArticleService = HelpArticleService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {SharedWorksService} */
    this._sharedWorksService = SharedWorksService;
    /** @type {CoTeacherService} */
    this._coTeacherService = CoTeacherService;

    this.reset();

    this._authService.userAuthenticated.subscribe(this._registerUser, this);
    this._authService.dataCleared.subscribe(this.reset, this);
  }

  /**
   * @param info {UserTokenInfo}
   * @private
   */
  _registerUser(info) {
    if (info.isTeacher) {
      this._bootstrapService.whenReady
        .then(() => this.getTestSegmentsForUser())
        .then((segments) => this._analyticsService.identifyUserAsLoggedIn(info, segments));
    }
  }

  /**
   * @param [getFresh] {boolean}
   * @returns {Promise.<User>}
   */
  getUser(getFresh) {
    if (getFresh) {
      this._user.clear();
    }

    return this._user.value(() => {
      return this._userService.getUser()
        .catch((err) => {
          this._user.clear();
          throw new Error(err);
        });
    });
  }

  /**
   * @param updatedUser {User}
   * @returns {Promise}
   */
  updateUser(updatedUser) {
    return this._userService.updateUser(updatedUser)
      .then((tokenResponse) => {
        if (tokenResponse.token) {
          return this._authService.processTokenResult(tokenResponse.token, this._authService.rememberMe)
            .then(() => {
              this.reset();
            })
            .then(() => this._user.set(this.$q.resolve(updatedUser)));
        }
      });
  }

  /**
   * @param [getFresh] {boolean}
   * @returns {Promise.<Map.<string, Assignment>>} The promise of the full map of assignments
   */
  getAssignmentsForUser(getFresh) {
    if (getFresh) {
      this._assignments.clear();
    }

    return this._assignments.value(() => {
      return this._assignmentService.getForCurrentUser()
        .then((array) => {
          return new Map(
            array.map((assignment) => [assignment.id, assignment])
          );
        })
        .catch((err) => {
          this._assignments.clear();
          throw err;
        });
    });
  }

  /**
   * @param assignmentId {string}
   * @param [getFresh] {boolean}
   * @returns {Promise.<Assignment>}
   */
  getAssignmentForUser(assignmentId, getFresh) {
    return this.getAssignmentsForUser()
      .then((map) => {
        if (getFresh || !map.has(assignmentId)) {
          return this._assignmentService.get(assignmentId)
            .then((assignment) => {
              if (assignment.ownerId === this._authService.currentUserId) {
                map.set(assignmentId, assignment);
                return assignment;
              }
              else {
                throw new Error(`Assignment ${assignmentId} is not available`);
              }
            })
            .catch((err) => {
              map.delete(assignmentId);
              throw err;
            });
        }
        else {
          return map.get(assignmentId);
        }
      });
  }

  /**
   * @param [getFresh] {boolean}
   * @param [studentId] {string}
   * @returns {Promise.<{ works: AssignmentWork[], assignments: Map<string, Assignment>, assignmentRosters: Map<string, assignmentRosters>}>}
   */
   getStudentAssignmentsAndWorks(studentId, rosterId, getFresh) {

    if (getFresh) {
      this._studentAssignmentsAndWorks.clear();
    }

    return this._studentAssignmentsAndWorks.value(() =>
      this._assignmentWorkService.getAllForStudent(studentId, rosterId)
        .then(({works, assignments, assignmentRosters}) => {
          return {
            works: new Map(works.map((w) => [w.id, w])),
            assignments,
            assignmentRosters
          };
        })
        .catch((err) => {
          this._studentAssignmentsAndWorks.clear();
          throw err;
        })
    );
  }

  /**
   * @param assignment {Assignment}
   * @returns {Promise.<Assignment>}
   */
  addAssignmentForUser(assignment) {
    return this.getAssignmentsForUser()
      .then((map) => {
        map.set(assignment.id, assignment);
        return assignment;
      });
  }

  /**
   * @param assignment {Assignment}
   */
  updateAssignmentForUser(assignment) {
    return this._assignmentService.update(assignment)
      .then(() => {
        this.getAssignmentsForUser()
          .then((map) => {
            map.set(assignment.id, assignment);
          });
      });
  }

  updateAssignmentModifiedDateForUser(assignment) {
    return this._assignmentService.updateAssignmentModifiedDate(assignment.id)
     .then(() => {
       this.getAssignmentsForUser()
         .then((map) => {
              map.set(assignment.id, assignment);
          });
      });
  }

    /**
   * Deletes an assignment from database and cache
   *
   * @param assignmentId
   * @returns {Promise}
   */
  deleteAssignment(assignmentId) {
    return this._assignmentService.delete(assignmentId)
      .then(() => {
        return this.getAssignmentsForUser()
          .then((assignments) => {
            assignments.delete(assignmentId);
          });
      });
  }

  /**
   * Deletes a folder from database and cache
   *
   * @param folderId
   * @returns {Promise}
   */
  deleteFolder(folderId) {
    return this._assignmentService.deleteFolder(folderId)
      .then(() => {
        this.deleteFolderFromCache(folderId);
      });
  }

  /**
   * Deletes a folder and it's contained items from the cache
   * @param folderId
   * @returns {*}
   */
  deleteFolderFromCache(folderId) {
    return this.getAssignmentsForUser()
      .then((assignments) => {
        assignments.delete(folderId);

        assignments.forEach((assignment) => {
          if (assignment.folder === folderId) {
            if (assignment.isFolder) {
              this.deleteFolderFromCache(assignment.id);
            } else {
              assignments.delete(assignment.id);
            }
          }
        });
      });
  }

  /**
   * @param assignmentId {string}
   * @param getFresh {boolean}
   * @returns {Promise.<Map.<string, Assignment>>}
   */
  getAssignment(assignmentId, getFresh) {
    if (getFresh || assignmentId !== this._cachedAssignmentId) {
      this._assignment.clear();
    }

    this._cachedAssignmentId = assignmentId;

    return this._assignment.value(() => {
      return this._assignmentService.get(assignmentId)
        .catch((err) => {
          this._assignment.clear();
          this._cachedAssignmentId = null;
          throw err;
        });
    });
  }

  /**
   *
   * @param assignment {Assignment}
   * @param assignmentWorkId {string}
   * @param [getFresh] {boolean}
   * @returns {Promise.<AssignmentWork>}
   */
  getAssignmentWork(assignment, assignmentWorkId, getFresh) {
    let newAssignmentWorkId = `${assignment.id}|${assignmentWorkId}`;

    if (getFresh || newAssignmentWorkId !== this._cachedAssignmentWorkId) {
      this._assignmentWork.clear();
    }

    this._cachedAssignmentWorkId = newAssignmentWorkId;

    return this._assignmentWork.value(() => {
      return this._assignmentWorkService.getForAssignment(assignment, assignmentWorkId)
        .catch((err) => {
          this._assignmentWork.clear();
          this._cachedAssignmentWorkId = null;
          throw err;
        });
    });
  }

  /**
   * Gets the works for an assignment and roster
   *
   * @param assignment
   * @param rosterId
   * @param getFresh
   * @returns {Promise.<Map.<string, AssignmentWork>>}
   */
  getRosterWorks(assignment, rosterId, getFresh) {
    let newAssignmentRosterId = `${assignment.id|rosterId}`;

    if (getFresh || newAssignmentRosterId !== this._cachedAssignmentRosterId) {
      this._assignmentWorks.clear();
    }

    this._cachedAssignmentRosterId = newAssignmentRosterId;

    return this._assignmentWorks.value(() => {
      return this._assignmentWorkService.getAllForAssignmentRoster(assignment, rosterId)
        .then((result) => {
          const map = new Map();
          const owners = new Set();

          // In case of duplicates for an owner, always prefer the first one found
          result.forEach((work) => {
            if (!owners.has(work.ownerId)) {
              map.set(work.id, work);
              owners.add(work.ownerId);
            }
          });

          return map;
        })
        .catch((err) => {
          this._assignmentWorks.clear();
          this._cachedAssignmentRosterId = null;
          throw err;
        });
    });
  }

  /**
   * @param [getFresh] {boolean}
   * @returns {Promise.<Map.<string, Roster>>}
   */
  getRostersForUser(getFresh) {
    if (getFresh) {
      this._rosters.clear();
    }

    return this._rosters.value(() => {
      return this._rosterService.getUserRosters()
        .then((array) => {
          return new Map(array.map((roster) => [roster.id, roster]));
        })
        .catch((err) => {
          this._rosters.clear();
          throw err;
        });
    });
  }

  /**
   * @param [getFresh] {boolean}
   * @param [studentId] {string}
   * @returns {Promise.<{rosters: Map<string, Roster>}>}
   */
   getStudentRosters(studentId, getFresh) {

    if (getFresh) {
      this._studentRosters.clear();
    }

    return this._studentRosters.value(() =>
      this._rosterService.getStudentRosters(studentId)
        .then((rosters) => {
          return new Map(rosters.map((roster) => [roster.id, roster]));
        })
        .catch((err) => {
          this._studentRosters.clear();
          throw err;
        })
    );
  }

  /**
   * @param roster {Roster}
   * @returns {Promise.<Roster>}
   */
  addRosterForUser(roster) {
    return this.getRostersForUser()
      .then((map) => {
        map.set(roster.id, roster);
        return roster;
      });
  }

  /**
   * @param roster {Roster}
   */
  updateRosterForUser(roster) {
    this.getRostersForUser()
      .then((rosters) => {
        if (rosters.has(roster.id)) {
          rosters.set(roster.id, roster);
        }
      });
  }

  /**
   * Deletes a roster from database and cache
   *
   * @param [rosterId] {boolean}
   * @returns {Promise}
   */
  deleteRoster(rosterId) {
    return this._rosterService.delete(rosterId)
      .then(() => {
        return this.getRostersForUser()
          .then((rosters) => {
            rosters.delete(rosterId);
          });
      });
  }



  /**
   * @param [getFresh] {boolean}
   * @returns {Promise.<Array.<ClassCode>>}
   */
  getClassCodesForUserAssignment(assignmentId, getFresh) {
    if (getFresh) {
      this._classCodes.delete(assignmentId);
    } else if (this._classCodes.has(assignmentId)) {
      return this._classCodes.get(assignmentId);
    }
    return this._classCodeService.getForCurrentUserAssignment(assignmentId)
      .then((classCodes) => {
        this._classCodes.set(assignmentId, classCodes);
        return this._classCodes.get(assignmentId);
      })
      .catch((err) => {
        this._classCodes = new Map();
        throw err;
      });
  }

  /**
   *
   * @param rosterId {string}
   * @param [getFresh] {boolean}
   * @returns {Promise.<Map.<String, User>>}
   */
  getRosterUsers(rosterId, getFresh) {
    if (getFresh || this._rosterUsers.rosterId !== rosterId) {
      this._rosterUsers.clear();
    }

    this._rosterUsers.rosterId = rosterId;
    return this._rosterUsers.value(() => {
      return this._rosterService.getUsersForRoster(rosterId)
        .then((result) => {
          return new Map(result.map((user) => [user.id, user]));
        })
        .catch((err) => {
          this._rosterUsers.rosterId = null;
          this._rosterUsers.clear();
          throw err;
        });
    });
  }

  /**
   *
   * @param rosterId {string}
   * @param userId {string}
   * @param [getFresh] {boolean}
   * @returns {Promise.<User>}
   */
  getRosterUser(rosterId, userId, getFresh) {
    return this.getRosterUsers(rosterId)
      .then((map) => {
        if (getFresh) {
          return this._rosterService.getUserForRoster(rosterId, userId)
            .then((user) => {
              map.set(user.id, user);
              return user;
            })
            .catch((err) => {
              map.delete(userId);
              throw err;
            });
        }
        else {
          return map.get(userId);
        }
      });
  }

  /**
   * @param rosterId {string}
   * @param user {User}
   */
  updateUserForRosterUsers(rosterId, user) {
    if (this._rosterUsers.rosterId === rosterId) {
      this.getRosterUsers(rosterId)
        .then((result) => {
          if (result.has(user.id)) {
            result.set(user.id, user);
          }
        });
    }
  }

  /**
   * @param rosterId {string}
   * @param user {User}
   */
  removeUserForRosterUsers(rosterId, user) {
    if (this._rosterUsers.rosterId === rosterId) {
      this.getRosterUsers(rosterId)
        .then((result) => {
          result.delete(user.id);
        });
    }
  }

  /**
   * @returns {Promise.<MessageSet>}
   */
  getMessagesForUser() {
    return this._messages.value(() => {
      return this._messageService.getReceivedMessagesForCurrentUser()
        .then((result) => {
          const value = new MessageSet(
            result,
            this._notificationService.getNewMessageNotificationForCurrentUser(),
            this._messageService
          );

          value.start();
          return value;
        })
        .catch((err) => {
          this._clearMessages();
          throw err;
        });
    });
  }

  /**
   * @private
   */
  _clearMessages() {
    if (this._messages) {
      this._messages.clear((value) => value.then((messageSet) => messageSet.destroy()));
    }
  }

  /**
   * @param assignmentId {string}
   * @param rosterId {string}
   * @param [getFresh] {boolean}
   * @param [skipRefresh] {boolean}
   */
  getSessionData(assignmentId, rosterId, getFresh, skipRefresh) {
    if (getFresh ||
        this._sessionData.assignmentId !== assignmentId ||
        this._sessionData.rosterId !== rosterId) {

      this.clearSessionData();
    }

    // If we already have an instance of the correct session data, simply refresh the data
    if (this._sessionData.isSet) {
      return this._sessionData.value()
        .then((sessionData) => {
          return skipRefresh ? sessionData : sessionData.refreshData();
        });
    }
    // Otherwise build one anew
    else {
      this._sessionData.assignmentId = assignmentId;
      this._sessionData.rosterId = rosterId;

      return this._sessionData.value(() => {

        return SessionData.fetch(
            assignmentId,
            rosterId,
            this.$q,
            this.$timeout,
            this,
            this._helpRequestService,
            this._assignmentWorkService,
            this._userService,
            this._notificationService,
            this._assignmentService,
            this._storageService,
            this._feedbackService,
            this._firebaseService,
            this._analyticsService
          )
          .then((sessionData) => {
            if (!sessionData.isComplete) {
              this.clearSessionData();
            }
            return sessionData;
          })
          .catch((err) => {
            this.clearSessionData();
            throw err;
          });
      });
    }
  }

  //-------------- Student helpers and student status

  /**
   * Sets the current user's status
   * @param assignmentId {string}
   * @param questionId {string}
   * @param activity {string}
   * @param [helpeeId] {string}
   */
  setUserStatus(assignmentId, questionId, activity, helpeeId) {
    if (this._userStatus && this._userStatus.userId !== this._authService.currentUserId) {
      this.clearUserStatus();
    }

    if (!this._userStatus) {
      this._userStatus = this._notificationService.getUserStatusEditor(this._authService.currentUserId).start();
    }

    this._userStatus.setStatus(true, assignmentId, questionId, activity, helpeeId);
  }

  /**
   * Sets the user status to offline
   */
  clearUserStatus() {
    if (this._userStatus) {
      this._userStatus.stop();
      this._userStatus = null;
    }
  }

  /**
   * Removes the user from
   */
  clearUserStatusDetails() {
    if (this._userStatus) {
      this._userStatus.assignmentId = null;
      this._userStatus.questionId = null;
      this._userStatus.activity = null;
    }
  }

  /**
   * @param assignment {Assignment}
   * @param helperId {string}
   * @param helperName {string}
   * @param helperRole {string}
   * @param helpeeId {string}
   * @param assignmentId {string}
   * @param questionId {string}
   */
  startHelping(assignment, helperId, helperName, helperRole, helpeeId, assignmentId, questionId) {
    this.stopHelping();
    let helper = new Helper(helperId, helperName, helperRole, helpeeId, assignmentId, questionId);
    this._helper = this._notificationService.getActiveHelper(assignment, helper).start();
    this._helper.setHelper();
  }

  /**
   *
   */
  stopHelping() {
    if (this._helper) {
      this._helper.stop();
      this._helper = null;
    }
  }

  /**
   * Returns the user's current collection of stickers
   *
   * @param getFresh {boolean}
   * @returns {Promise.<UserSticker[]>}
   */
  getStickersForUser(getFresh) {

    if (getFresh) {
      this._stickers.clear();
    }

    return this._stickers.value(() => {
      return this._stickerService.getForUser(this._authService.currentUserId)
        .catch((err) => {
          this._stickers.clear();
          throw err;
        });
    });

  }

  /**
   * Saves a new sticker and ensures that the cached collection reflects this new version
   *
   * @param imageUrl {string}
   * @param text {string}
   * @param tags {Array}
   * @returns {Promise.<UserSticker>}
   */
  createSticker(imageUrl, text, tags) {
    return this._stickerService.create(imageUrl, text, tags)
      .then((sticker) => {
        this.getStickersForUser(false)
          .then((array) => {
            array.push(sticker);
          });

        return sticker;
      });
  }

  /**
   * Saves an existing sticker and ensures the the cached collection reflects this new version
   *
   * @param sticker {UserSticker}
   * @param [beforeStickerId] {string}
   * @returns {Promise.<UserSticker>}
   */
  updateSticker(sticker, beforeStickerId) {
    return this._stickerService.update(sticker, beforeStickerId);
  }

  /**
   * Deletes an existing sticker and removes it from the cached collection
   *
   * @param stickerId {string}
   * @returns {Promise}
   */
  deleteSticker(stickerId) {
    return this._stickerService.delete(stickerId)
      .then(() => {
        return this.getStickersForUser(false)
          .then((stickers) => {
            let index = stickers.map((sticker) => sticker.id).indexOf(stickerId);
            index > -1 && stickers.splice(index, 1);
          });
      });
  }

  /**
   * @returns {Promise.<Contract[]>}
   */
  getAdminContracts() {
    return this._adminContracts.value(() => {
      const contracts = this._authService.authData.contracts;
      return this.$q.all(
          Object.keys(contracts)
            .filter((key) => contracts[key].admin === true)
            .map((key) => this._contractService.get(key))
        )
        .catch((err) => {
          this._adminContracts.clear();
          throw err;
        });
    });
  }


  /**
   * @param [getFresh] {boolean}
   * @param [includeExpired] {boolean}
   * @param [excludeTrials] {boolean}
   * @returns {Promise.<Contract[]>}
   */
  getContracts(getFresh, includeExpired, excludeTrials = false) {
    if (getFresh) {
      this._allContracts.clear();
      this._filteredActiveContracts.clear();
      this._filteredNonTrialContracts.clear();
      this._filteredActiveNonTrialContracts.clear();
    }

    let property = this._allContracts;
    if (includeExpired) {
      if (excludeTrials) {
        property = this._filteredNonTrialContracts;
      } else {
        property = this._allContracts;
      }
    } else {
      if (excludeTrials) {
        property = this._filteredActiveNonTrialContracts;
      } else {
        property = this._filteredActiveContracts;
      }
    }

    return property.value(() => {
      return this._contractService
        .getContractsForUser(this._authService.currentUserId, includeExpired, excludeTrials)
        .catch((err) => {
          property.clear();
          throw err;
        });
    });
  }

  /**
   * Gets the specific app configuration from features
   *
   * @param getFresh
   * @return {Promise<AppConfiguration>}
   */
  getAppConfig(getFresh = false) {
      return this.getUserFeatures(getFresh)
          .then((features) => {

              return new AppConfiguration(features);
          })
          .catch((err) => {
              this.$log.error('Unable to get configuration');
              throw err;
          });
  }


  /**
   * @param getFresh
   * @return {Promise<ProInfo>}
   */
  getProInfo(getFresh) {
    if (getFresh) {
      this._proInfo.clear();
    }

    return this._proInfo.value(() => {
      return this.$q
        .all({
          features: this.getFeatures(getFresh),
          contracts: this.getContracts(getFresh)
        })
        .then(({features, contracts}) => {
          return new ProInfo(features, contracts);
        })
        .catch((err) => {
          this._proInfo.clear();
          throw err;
        });
    });
  }

  /**
   * Gets and caches the users for a contract
   *
   * @param contractId {string}
   * @param [getFresh] {boolean}
   * @returns {Promise.<Map.<String, User>>}
   */
  getUsersForContract(contractId, getFresh) {
    if (!this._contractIdToUsers[contractId]) {
      this._contractIdToUsers[contractId] = new LazyVar();
    }
    /** @type {LazyVar.<Promise.<Map.<String, User>>>} */
    const container = this._contractIdToUsers[contractId];

    if (getFresh) {
      container.clear();
    }

    return container.value(() => {
      return this._contractService.getUsers(contractId)
        .then((array) => new Map(array.map((x) => [x.id, x])))
        .catch((err) => {
          container.clear();
          throw err;
        });
    });
  }

  /**
   * Gets and caches the inactive users for a contract
   *
   * Returns a Map of contract ids to Map of inactive users within that contract. The
   * User map is keyed by user id.
   *
   * @param contractId {string}
   * @param monthsInactive {string}
   * @param [getFresh] {boolean}
   * @returns {Promise.<Map.<String, User>>}
   */

  getInactiveUsersForContract(contractId, monthsInactive, getFresh=false) {
    if (!this._contractIdToUsers[contractId]) {
      this._contractIdToUsers[contractId] = new LazyVar();
    }
    /** @type {LazyVar.<Promise.<Map.<String, User>>>} */
    const inactiveUsersInContract = this._contractIdToUsers[contractId];

    if (getFresh) {
      inactiveUsersInContract.clear();
    }

    return inactiveUsersInContract.value(() => {
      return this._contractService.getInactiveUsers(contractId, monthsInactive)
        .then((array) => new Map(array.map((x) => [x.id, x])))
        .catch((err) => {
            inactiveUsersInContract.clear();
          throw err;
        });
    });
  }

  /**
   * Finds all users for all contracts of the currently logged in user.
   *
   * Returns a Map of contract ids to Map of users within that contract. The
   * User map is keyed by user id.
   *
   * @param [getFresh] {boolean}
   * @returns {Promise.<Map.<String, Map.<String, User>>>}
   */
  getUsersForContracts(getFresh) {

    const contractIds = this._authService.authData.contractIds;
    let promises = contractIds.map((id) => this.getUsersForContract(id, getFresh));
    return this.$q.all(promises)
      .then((contractUserArray) => {
        return new Map(contractIds.map((contractId, index) => [contractId, contractUserArray[index]]));
      });
  }

  /**
   * Finds all inactive users over monthsInactive for all contracts of the currently logged in user.
   *
   * Returns a Map of contract ids to Map of users within that contract. The
   * User map is keyed by user id.
   *
   * @param [getFresh] {boolean}
   * @param [monthsInactive] {string}
   * @returns {Promise.<Map.<String, Map.<String, User>>>}
   */
  getInactiveUsersForContracts(monthsInactive, getFresh=false) {

    const contractIds = this._authService.authData.contractIds;
    let promises = contractIds.map((id) => this.getInactiveUsersForContract(id, monthsInactive, getFresh));
    return this.$q.all(promises)
      .then((contractUserArray) => {
        return new Map(contractIds.map((contractId, index) => [contractId, contractUserArray[index]]));
      });
  }

  /**
   * @param [getFresh] {boolean}
   * @returns {Promise.<Set<string>>}
   */
  getFeatures(getFresh) {
    if (getFresh) {
      this._features.clear();
    }

    return this._features.value(() => {
      return this.getUserFeatures(getFresh)
        .then((data) => new Set(data.features))
        .catch((err) => {
          this._features.clear();
          throw err;
        });
    });
  }

  /**
   * User Features
   *
   * @param getFresh
   * @returns {Promise.<Object>} raw data from contract service get features
   */
  getUserFeatures(getFresh) {
      if (getFresh) {
          this._contract_features.clear();
      }

      return this._contract_features.value(() => {
          return this._contractService.getFeatures()
              .then((data) => {
                  return data;
              })
              .catch((err) => {
                  this._contract_features.clear();
                  throw err;
              });
      });
  }

  /**
   * Returns a map of test segment ids to test variables
   * @returns {Promise.<Map.<String, String>>}
   */
  getTestSegmentsForUser() {
    if (this._testSegments.userId !== this._authService.currentUserId) {
      this._testSegments.clear();
    }

    this._testSegments.userId = this._authService.currentUserId;

    return this._testSegments.value(() => {
      return this._abTestService.getTestSegmentsForUser(this._authService.currentUserId)
        .catch((err) => {
          this._testSegments.clear();
          throw err;
        });
    });
  }

  /**
   * Indicates whether a user is part of a test segment. If they are 'A', returns true.
   * If they are any other segment, returns false.
   *
   * @param testSegmentId
   * @return {Promise.<boolean>}
   */
  getTestSegment(testSegmentId) {
    return this.getTestSegmentsForUser()
      .then((testSegments) => {
        return testSegments.get(testSegmentId) === ABTestVariable.A;
      })
      .catch((err) => {
        this._testSegments.clear();
        throw err;
      });
  }

  clearSessionData() {
    if (this._sessionData) {
      this._sessionData.assignmentId = null;
      this._sessionData.rosterId = null;
      this._sessionData.clear((value) => value.then((sessionData) => sessionData.destroy()));
    }
  }

  clearSharedWorkSessionData() {
    if (this._sharedWorkSessionData) {
      this._sharedWorkSessionData.assignmentId = null;
      this._sharedWorkSessionData.rosterId = null;
      this._sharedWorkSessionData.clear((value) => value.then((sessionData) => sessionData.destroy()));
    }
  }

  /**
   * @param [getFresh] {boolean}
   * @returns {Promise.<Subscription[]>}
   */
  getSubscriptions(getFresh) {
    if (getFresh) {
      this._subscriptions.clear();
    }

    return this._subscriptions.value(() => {
      let userId = this._authService.currentUserId;
      return this._subscriptionService.getSubscriptions(userId)
        .catch((err) => {
          this._subscriptions.clear();
          throw err;
        });
    });
  }

  /**
   * @param [getFresh] {boolean}
   * @returns {Promise.<Organization[]>}
   */
  getOrganizations(getFresh, getDistricts = false) {
    if (getFresh) {
      this._organizations.clear();
    }

    return this._organizations.value(() => {
      return this.$q
        .all({
          organizations: this._organizationService.getForUser(this._authService.currentUserId),
          contracts: this.getContracts(getFresh),
          districts: getDistricts && this._organizationService.getDistrictsForUser(this._authService.currentUserId)
        })
        .then(({organizations, contracts, districts}) => {
          const organizationMap = new Map();

          let allOrganizations =  organizations.map((organization) => {
            organization.contract = contracts.find((contract) => organization.contractId && contract.id === organization.contractId);
            organization.admin = this._authService.authData && this._authService.authData.isContractAdmin(organization.contractId);
            organizationMap.set(organization.id, organization);
            return organization;
          });

          if (districts && districts.length) {
            districts.forEach((district) => {
              if (!organizationMap.has(district.id)) {
                allOrganizations.push(district);
              }
            });
          }
          return allOrganizations;
        })
        .catch((err) => {
          this._organizations.clear();
          throw err;
        });
    });
  }

  /**
   * @param [getFresh] {boolean}
   * @returns {Promise.<Organization[]>}
   */
  getUnverifiedOrganizations(getFresh) {
    if (getFresh) {
      this._unverifiedOrganizations.clear();
    }

    return this._unverifiedOrganizations.value(() => {
      return this._organizationService.getUnverifiedOrganizations(this._authService.currentUserId)
        .catch((err) => {
          this._unverifiedOrganizations.clear();
          throw err;
        });
    });
  }

  /**
   * @param [getFresh] {boolean}
   * @return {Promise<Organization[]>}
   */
  getSchools(getFresh) {
    return this.getOrganizations(getFresh).then((organizations) => {
      return organizations.filter((org) => org.type === OrganizationTypes.School);
    });
  }

  /**
   * @param [getFresh] {boolean}
   * @return {Promise<HelpArticle[]>}
   */
  getArticles(getFresh) {
    if (getFresh) {
      this._helpArticles.clear();
    }

    return this._helpArticles.value(() => {
      return this._helpArticleService.getAll()
        .catch((err) => {
          this._helpArticles.clear();
          throw err;
        });
    });
  }

  /**
   * @param articleId {string}
   * @param [getFresh] {boolean}
   * @return {Promise<HelpArticle[]>}
   */
  getArticle(articleId, getFresh = false) {
    return this.getArticles(getFresh)
      .then((articles) => {
        let article = articles.find((article) => article.id === articleId);
        if (article) {
          return article;
        }
        else {
          return this.getArticles(true)
            .then((articles) => articles.find((article) => article.id === articleId));
        }
      });
  }

  /**
   * @param categoryId {string}
   * @param sectionId {string}
   * @param [title] {string}
   * @param [body] {string}
   * @param [priority] {number}
   *
   * @return {Promise<HelpArticle>}
   */
  createArticle(categoryId, sectionId, title, body, priority) {
    return this._helpArticleService.create(categoryId, sectionId, title, body, priority)
      .then((result) => {
        this._helpArticles.clear();
        return result;
      });
  }

  /**
   * Clears all data contained in the service
   */
  reset() {
    /** @type {LazyVar.<Promise.<Assignment>>} */
    this._assignment = new LazyVar();
    /** @type {LazyVar.<Promise.<Map.<string, AssignmentWork>>>} */
    this._assignmentWork = new LazyVar();
    /** @type {LazyVar.<Promise.<Map.<string, AssignmentWork>>>} */
    this._assignmentWorks = new LazyVar();
    /** @type {LazyVar.<Promise.<Map.<string, Assignment>>>} */
    this._assignments = new LazyVar();
    /** @type {LazyVar.<Promise.<{assignments: Map<string, Assignment>, works: Map<string, AssignmentWork>}>>} */
    this._studentAssignmentsAndWorks = new LazyVar();
    /** @type {LazyVar.<Promise.<Map.<string, Roster>>>} */
    this._rosters = new LazyVar();
    /** @type {LazyVar.<Promise.<{rosters: Map<string, Roster>}>>} */
    this._studentRosters = new LazyVar();
    /** @type {LazyVar.<Promise.<{work: Map<string, Roster>}>>} */
    this._sharedAssignmentWorks = new LazyVar();
    /** @type Map () */
    this._classCodes = new Map();
    /** @type {LazyVar.<Promise.<User>>} */
    this._user = new LazyVar();
    this._cachedAssignmentId = null;
    this._cachedAssignmentWorkId = null;
    this._cachedSharedAssignmentWorkId = null;
    this._cachedRosterId = null;
    /** @type {LazyVar.<Promise.<Map.<String, User>>>} Map of UserId to User */
    this._rosterUsers = new LazyVar();
    /** @type {LazyVar.<Promise.<Map.<String, Assignment[]>>>} Map of Assignments */
    this._assignmentsForRoster = new LazyVar();
    /** @type {LazyVar.<Promise.<CoTeachers[]>>} */
    this._coTeachersForRoster = new LazyVar();

    this.clearSessionData();
    /** @type {LazyVar.<Promise.<SessionData>>} */
    this._sessionData = new LazyVar();

    /** @type {LazyVar.<Promise.<SharedWorkSessionData>>} */
    this._sharedWorkSessionData = new LazyVar();

    this._clearMessages();
    /** @type {LazyVar.<Promise.<MessageSet>>} */
    this._messages = new LazyVar();

    /** @type {LazyVar.<Promise.UserSticker[]>} */
    this._stickers = new LazyVar();

    /** @type {LazyVar.<Promise.<Contract[]>>} */
    this._adminContracts = new LazyVar();

    /** @type {LazyVar.<Promise.<Contract[]>>} */
    this._allContracts = new LazyVar();

    /** @type {LazyVar.<Promise.<Contract[]>>} */
    this._filteredActiveContracts = new LazyVar();

    /** @type {LazyVar.<Promise.<Contract[]>>} */
    this._filteredNonTrialContracts = new LazyVar();

    /** @type {LazyVar.<Promise.<Contract[]>>} */
    this._filteredActiveNonTrialContracts = new LazyVar();

    /** @type {LazyVar.<Promise.<ProInfo>>} */
    this._proInfo = new LazyVar();

    /** @type {Object} */
    this._contractIdToUsers = {};

    /** @type {LazyVar.<Promise.<Map <String, String>>>} */
    this._testSegments = new LazyVar();

    /** @type {LazyVar.<Promise.<Set<string>>>} */
    this._features = new LazyVar();

      /** @type {LazyVar.<Promise.<Object>>} */
      this._contract_features = new LazyVar();

    /** @type {LazyVar.<Promise.<User>>} */
    this._childOrganizationCount = new LazyVar();

    this.stopHelping();
    /** @type {ActiveHelper} */
    this._helper = null;

    this.clearUserStatus();
    /** @type {getUserStatusEditor} */
    this._userStatus = null;
    /** @type {LazyVar.<Promise.<Subscription[]>>} */
    this._subscriptions = new LazyVar();
    /** @type {LazyVar.<Promise.<Organization[]>>} */
    this._organizations = new LazyVar();
    /** @type {LazyVar.<Promise.<Organization[]>>} */
    this._unverifiedOrganizations = new LazyVar();

    /** @type {LazyVar.<Promise.<HelpArticle[]>>} */
    this._helpArticles = new LazyVar();

    this._studentCacheService.reset();
  }

  /**
   * @param [getFresh] {boolean}
   * @returns {Promise.<ChildOrganizationCount>}
   */
  getChildOrganizationCount(organizationId, getFresh) {
    if (getFresh) {
      this._childOrganizationCount.clear();
    }

    return this._childOrganizationCount.value(() => {
      return this._organizationService.getChildOrganizationCount(organizationId)
        .catch((err) => {
          this._childOrganizationCount.clear();
          throw new Error(err);
        });
    });
  }
  getAssignmentsByRoster(rosterId, getFresh) {
    if (getFresh || this._cachedRosterId !== rosterId) {
      this._assignmentsForRoster.clear();
      this._cachedRosterId = rosterId;
    }

    return this._assignmentsForRoster.value(() => {
      return this._assignmentService.getRosterAssignments(rosterId)
        .then((assignments) => {
          return new Map(assignments.map((assignment) => [assignment.id, assignment]));
        })
        .catch((err) => {
          this._assignmentsForRoster.clear();
          throw err;
        });
    });
  }

  removeAssignmentFromRoster(assignmentId, rosterId) {
    if (this._cachedRosterId !== rosterId) {
      this._assignmentsForRoster.clear();
      this._cachedRosterId = rosterId;
    }
    return this._assignmentService.removeRoster(assignmentId, rosterId)
      .then(() => {
        return this.getAssignmentsByRoster(rosterId)
          .then((assignmentsByRoster) => {
            assignmentsByRoster.delete(assignmentId);
          });
      })
      .catch((err) => {
        this._assignmentsForRoster.clear();
        throw err;
      });
  }

  getActiveAssignmentsForBasicUsers(getFresh = false) {
    if (this._authService.authData.isFree) {
      return this.getTestSegment(ABTest.AssignmentLimit20).then((segment) => {
        if (segment === true) {
          return this.getAssignmentsForUser(getFresh)
            .then((assignmentsMap) => {
              this.assignments = lodash.orderBy([...assignmentsMap.values()],
                (assignment) => {
                  return assignment.lastModified;
                }, ['desc']).slice(0, 20);
              return new Map(this.assignments.map(
                (assignment) => [assignment.id, assignment]));
            });
        }
      });
    }
    return null;
  }

  /**
   *
   * @param assignment {Assignment}
   * @param assignmentWorkId {string}
   * @param [getFresh] {boolean}
   * @returns {Promise.<SharedWork>}
   */
  getSharedAssignmentWork(sharedWorkId, getFresh) {

    if (getFresh || sharedWorkId !== this._cachedSharedAssignmentWorkId) {
      this._sharedAssignmentWorks.clear();
    }
    this._cachedSharedAssignmentWorkId = sharedWorkId;

    return this._sharedAssignmentWorks.value(() => {
      return this._sharedWorksService.getSharedAssignmentWork(sharedWorkId)
        .catch((err) => {
          this._sharedAssignmentWorks.clear();
          this._cachedAssignmentWorkId = null;
          throw err;
        });
    });
  }

  /**
   * @param assignmentId {string}
   * @param rosterId {string}
   * @param [getFresh] {boolean}
   * @param [skipRefresh] {boolean}
   * @returns {Promise.<SharedWorkSessionData>}
   */
  getSharedWorkSessionData(
    assignmentOwner,
    student,
    assignmentWork,
    rosterId,
    getFresh,
  ) {
    if (getFresh ||
      this._sharedWorkSessionData.assignmentId !== assignmentWork.assignment.id ||
      this._sharedWorkSessionData.rosterId !== rosterId) {

      this.clearSharedWorkSessionData();
    }

    this._sharedWorkSessionData.assignmentId = assignmentWork.assignment.id;
    this._sharedWorkSessionData.rosterId = rosterId;

    return this._sharedWorkSessionData.value(() => {
      return SharedWorkSessionData.fetch(
        assignmentOwner,
        student,
        assignmentWork,
        this.$q,
      ).catch((err) => {
        this.clearSharedWorkSessionData();
        throw err;
      });
    });
  }


  /**
   * @param rosterId {string}
   * @param [getFresh] {boolean}
   * @returns {Promise.<CoTeachers[]>}
   */
  getCoTeachersByRoster(rosterId, getFresh) {
    if (getFresh || this._cachedRosterId !== rosterId) {
      this._coTeachersForRoster.clear();
      this._cachedRosterId = rosterId;
    }

    return this._coTeachersForRoster.value(() => {
      return this._coTeacherService.getInvitedCoTeachersForRosters(rosterId)
        .then((coTeachers) => {
          return coTeachers;
        })
        .catch((err) => {
          this._coTeachersForRoster.clear();
          throw err;
        });
    });
  }
}
