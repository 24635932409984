
'use strict';

export default class InputUtils {

  /**
   * @param elem {object} the selected input element
   * @param start {int} the starting index of the selection
   * @param [end] {int} the ending index
   */
  static selectRange(elem, start, end) {

    if (angular.isUndefined(end)) {
      end = start;
    }

    if ('selectionStart' in elem) {
      elem.selectionStart = start;
      elem.selectionEnd = end;
    }
    else if (elem.setSelectionRange) {
      elem.setSelectionRange(start, end);
    }
    else if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.collapse(true);
      range.moveEnd('character', end);
      range.moveStart('character', start);
      range.select();
    }
  }
}
