export default class GradeExportService {

  /**
   * @ngInject
   */
  constructor($q, CacheService, AssignmentWorkService, CsvService) {
    this.$q = $q;

    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AssignmentWorkService} */
    this._assignmentWorkService = AssignmentWorkService;
    /** @type {CsvService} */
    this._csvService = CsvService;
  }

  /**
   * @param rosters {Roster[]}
   * @param rosterIdsToExport {string[]}
   * @param assignment {Assignment}
   */
  byAssignment(rosters, rosterIdsToExport, assignment) {

    let deferred = this.$q.defer();

    let rostersToExport = this._filterByIds(rosters, rosterIdsToExport);

    let promises = rostersToExport.map((roster) => {
      return this.$q.all({ members: this._cacheService.getRosterUsers(roster.id, true), roster });
    });

    this.$q
      .all({
        userRosterMaps: this.$q.all(promises),
        works: this._assignmentWorkService.getAllForAssignment(assignment)
      })
      .then(({userRosterMaps, works}) => {
        this._csvService.exportGradesByAssignment(assignment, userRosterMaps, works);
        deferred.resolve();
      })
      .catch((error) => {
        deferred.reject(error);
      });

    return deferred.promise;
  }

  /**
   * @param assignmentIdsToExport {string[]}
   * @param studentAssignmentOverviewItemsMap {Map<string, StudentOverviewAssignmentItem>}
   * @param roster {Roster}
   * @param user {User}
   */
  byStudent(assignmentIdsToExport, studentAssignmentOverviewItemsMap, roster, user) {
    let assignmentsToExport = this._filterBySelectedAssignments(assignmentIdsToExport, studentAssignmentOverviewItemsMap);
    this._csvService.exportGradesByStudent(assignmentsToExport, roster, user);
  }

  /**
   * @param assignmentIdsToExport {string[]}
   * @param studentAssignmentOverviewItemsMap {Map<string, StudentOverviewAssignmentItem>}
   */
  _filterBySelectedAssignments(assignmentIdsToExport, studentAssignmentOverviewItemsMap){
   return assignmentIdsToExport.map((assignmentId) => studentAssignmentOverviewItemsMap.get(assignmentId));
  }

  /**
   * @param rosters {Roster[]}
   * @param ids {string[]}
   * @returns {Roster[]}
   *
   */
  _filterByIds(rosters, ids) {
    return rosters.filter((roster) => {
      return ids.some((rosterId) => rosterId === roster.id);
    });
  }

}
